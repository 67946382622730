import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { FeedService } from '../feed.service';

@Component({
    selector: 'app-feed-tags',
    templateUrl: './feed-tags.component.html',
    styleUrls: ['./feed-tags.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'feed' }],
})
export class FeedTagsComponent {

    mockTags: { [tagId: string]: string } = { 'one': 'Example tag', 'two': 'Kunnan tiedote', 'three': 'Ollin tiedote' };

    constructor(
        private feedService: FeedService
    ) {}

    searchTag(tag: { key: string; value: string }): void {
        console.log('tag', tag)
        // this.feedService.feedSearch.next(tag.value);
    }
}
