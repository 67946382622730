import { useEffect, useRef } from 'react';

export default function useClickOutside(callbackFn) {
    let domNodeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let handler = (e) => {
            if (!domNodeRef.current?.contains(e.target)) {
            callbackFn();
            }
        }
        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, []);

    return domNodeRef;
}