/** @format */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { Company, CountrySelectOptions, PropertyField } from '@app/models';
import { CoreService } from 'app/_services/core.service';
import { TeamUserSelectorOptions } from 'app/_models/teamUserSelectorOptions.model';
import { DropdownSelectedItems } from 'app/_models/dropdown-selector.model';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    selector: 'app-network-settings-input-fields',
    templateUrl: './network-settings-input-fields.component.html',
    styleUrls: ['./network-settings-input-fields.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'settings', alias: 'settings' },
        },
    ],
})
export class NetworkSettingsInputFieldsComponent implements OnInit {
    @Input() fieldId: string;
    @Input() userId?: string;

    @Output() emitSaved = new EventEmitter<boolean>(false);

    teamSelectorOptions = new BehaviorSubject<TeamUserSelectorOptions>(new TeamUserSelectorOptions());
    teamSelectorPredefinedItems = new BehaviorSubject<string[]>([]);
    countrySelectorOptions: CountrySelectOptions;
    dateRangeValue: Date[] = [];
    fieldsFormGroup = new UntypedFormGroup({
        text: new UntypedFormControl(),
        team: new UntypedFormControl(),
        date: new UntypedFormControl(),
        phone: new UntypedFormControl(),
        email: new UntypedFormControl(),
        url: new UntypedFormControl(),
        optionlist: new UntypedFormControl(),
        country: new UntypedFormControl(),
    });
    network: BehaviorSubject<Company | null>;
    field?: PropertyField;
    fieldValues: any;

    constructor(private rpc: RPCService, private core: CoreService, private translocoService: TranslocoService) {}

    ngOnInit() {
        this.network = this.core.network;
        if (!this.network.value || !this.userId) {
            return;
        }

        this.field = this.network.value.propertyFields.users[this.fieldId];
        if (!this.field) {
            return;
        }

        this.fieldValues = this.propertyFieldValue(this.userId, this.fieldId) || '';

        switch (this.field.type) {
            case 'text':
                this.setTextFormControls();
                break;
            case 'date':
                this.setDateFormControls();
                break;
            case 'phone':
                this.setPhoneFormControls();
                break;
            case 'email':
                this.setEmailFormControls();
                break;
            case 'url':
                this.setUrlFormControls();
                break;
            case 'team':
                this.setTeamSelectorOptions();
                break;
            case 'country':
                this.setCountryFormControls();
                break;
            case 'optionlist':
                this.setOptionListFormControls();
                break;
            default:
                break;
        }
    }

    propertyFieldValue(userId: string, fieldId: string) {
        const member = this.network.value?.members?.find(({ uid }) => uid === userId);
        return member?.fields?.[fieldId];
    }

    saveField() {
        let dataArray: string[] = [];
        switch (this.field.type) {
            case 'team':
                if (this.fieldsFormGroup.controls.team.value) {
                    this.fieldsFormGroup.controls.team.value.forEach(_id => {
                        dataArray.push(_id);
                    });
                } else {
                    dataArray = [];
                }
                break;
            case 'country':
                if (this.fieldsFormGroup.controls.country.value && this.field.enableMultiple) {
                    this.fieldsFormGroup.controls.country.value.forEach(v => {
                        dataArray.push(v.name);
                    });
                } else if (this.fieldsFormGroup.controls.country.value && !this.field.enableMultiple) {
                    dataArray.push(this.fieldsFormGroup.controls.country.value.name);
                } else {
                    dataArray = [];
                }
                break;
            case 'optionlist':
                if (this.fieldsFormGroup.controls[this.field.type].value) {
                    dataArray = this.fieldsFormGroup.controls[this.field.type].value;
                } else {
                    dataArray = [];
                }
                break;
            default:
                if (this.fieldsFormGroup.controls[this.field.type].value) {
                    dataArray.push(this.fieldsFormGroup.controls[this.field.type].value);
                } else {
                    dataArray = [];
                }
                break;
        }

        this.rpc
            .request('company.set_property_value', [this.field._id, 'users', { data: dataArray, uid: this.userId }])
            .pipe(take(1))
            .subscribe({
                next: () => this.emitSaved.emit(true),
                error: error => {
                    console.error('error happened while saving user field: ', error);
                },
            });
    }

    clearField() {
        this.emitSaved.emit(true);
    }

    selectCountry(country: any) {
        this.fieldsFormGroup.controls.country.setValue(country);
    }

    selectTeams(event: DropdownSelectedItems) {
        this.fieldsFormGroup.controls.team.setValue(event?.team || []);
    }

    private setTextFormControls() {
        if (this.fieldValues) {
            this.fieldsFormGroup.controls.text.setValue(this.fieldValues);
        }
    }

    private setDateFormControls() {
        if (this.fieldValues && this.fieldValues[0]) {
            this.fieldsFormGroup.controls.date.setValue(this.fieldValues[0]);
        }
    }

    private setPhoneFormControls() {
        this.fieldsFormGroup.controls.phone.setValue(this.fieldValues);
    }

    private setEmailFormControls() {
        if (this.fieldValues) {
            this.fieldsFormGroup.controls.email.setValue(this.fieldValues);
        }
    }

    private setUrlFormControls() {
        if (this.fieldValues) {
            this.fieldsFormGroup.controls.url.setValue(this.fieldValues);
        }
    }

    private setTeamSelectorOptions() {
        const currentOptions = this.teamSelectorOptions.value;
        currentOptions.appearance = 'fill';
        currentOptions.required = false;
        currentOptions.multiple = this.field.enableMultiple || false;
        currentOptions.placeholder = this.translocoService.translate('settings.team-settings.ts_select_teams_placeholder');
        currentOptions.showTeams = 'all';
        this.teamSelectorOptions.next(currentOptions);
        this.teamSelectorPredefinedItems.next(Array.isArray(this.fieldValues) ? this.fieldValues : []);
    }

    private setCountryFormControls() {
        const initialCountries: string[] = [];
        if (this.field.enableMultiple && this.fieldValues) {
            this.fieldValues.forEach(value => {
                initialCountries.push(value);
            });
        } else if (this.fieldValues && !this.field.enableMultiple) {
            initialCountries.push(this.fieldValues);
        }
        this.countrySelectorOptions = {
            noEntriesFoundLabel: this.translocoService.translate('settings.team-settings.ts_no_countries_found_label'),
            placeholderLabel: this.translocoService.translate('settings.team-settings.search'),
            showResetButton: false,
            multiple: this.field.enableMultiple,
            initValueArray: initialCountries,
        };
    }

    private setOptionListFormControls() {
        this.fieldsFormGroup.controls.optionlist.setValue(this.fieldValues);
    }
}
