/** @format */

import { Activity, ProcessFieldType, WorkflowFieldModifier } from '.';

export type ActivityOptionFields = 'createdDate' | 'name' | 'ownerTeam' | 'ownerUser';

interface SetInfoArgs {
    /** Workflow id is required in all cases */
    workflowId?: string;

    /** Mode of the sidenav */
    action: ActivitySidenavActions;

    /** Initial field values,
     *  fields gets filled with these values if activity field value is not defined
     * */
    initFieldValues?: ActivityInitFieldValues;
    /** Shows only details of the activity */
    overviewPeek?: boolean;
}

export interface SetEditMultipleArguments extends SetInfoArgs {
    /** Used when editing multiple activities */
    activities?: { _id: string; name: string }[];
    phaseId?: string;
}

export interface SetViewArguments extends SetInfoArgs {
    /** Loads activity if defined */
    activityId?: string;
    /** Opens sidenav in edit mode if enabled */
    editing?: boolean;
    nextPhaseId?: string;
}

export interface SetCreateArguments extends SetInfoArgs {
    /** Defaults to the first phase of the workflow if not defined,
     *  if activity is defined phaseId defaults to activity.currentPhase
     */
    phaseId?: string;
    discussionInfo?: UpgradeDiscussionInfo;
}

export interface UpgradeDiscussionInfo {
    id: string;
    name: string;
    followers: string[];
}

export interface ActivityInitFieldValues {
    [fieldId: string]: {
        value: any;
        type?: string;
    };
}

export interface ActivityTemplate {
    name: ActivityTemplateName;
    team: ActivityTemplateOwnerTeam;
    fields: ActivityTemplateField[];
    translations?: ActivityTemplateTranslations;
}

export interface ActivityTemplateOwnerTeam {
    label: string;
    id?: string;
    selectableTeams?: {
        _id: string;
        name: string;
        memberCount: number;
    }[];
}

export interface ActivityTemplateName {
    label: string;
    type: string;
    required: boolean;
    placeholder: string;
    functionEnabled?: boolean;
}

export interface ActivityTemplateField {
    id: ActivityOptionFields | string;
    label: string;
    type?: string;
    required?: boolean;
    placeholder?: string;
    subtype?: ProcessFieldType;
    data?: any;
    description?: string;
    unit?: string;
    defaultTo?: boolean;
    defaultValue?: string;
    functionEnabled?: boolean;
    editable?: boolean;
    collapsedByDefault?: boolean;
    modifier?: WorkflowFieldModifier;
}

export interface ActivityTemplateTranslations {
    name?: {
        label?: string;
        placeholder?: string;
    };
    team?: {
        label?: string;
    };
    fields?: ActivityTemplateFieldTraslationsMap;
}
export interface ActivityTemplateFieldTranslations {
    label?: string;
    placeholder?: string;
    description?: string;
    unit?: string;
    defaultValue?: string;
}

export interface ActivityTemplateFieldTraslationsMap {
    [fieldId: string]: ActivityTemplateFieldTranslations;
}

export interface ActivityLink {
    name: string;
    _id: string;
}

export interface LinkedFromGroupMap {
    [workflowId: string]: LinkedFromGroup;
}

export interface LinkedFromGroupPhaseMap {
    [phaseId: string]: LinkedFromGroupPhase;
}

export interface LinkedFromGroup {
    _id: string;
    name: string;
    linkableFields: string[];
    activityCount: number;
    phases?: LinkedFromGroupPhaseMap;
    dataset?: boolean;
}

export interface LinkedFromPhaseLimits {
    [phaseId: string]: {
        limit: number;
        skip: number;
    };
}

export interface LinkedFromGroupPhase {
    _id: string;
    name: string;
    activityCount: number;
    activities: LinkedFromGroupActivity[];
    primaryNumericTotal?: number;
    primaryNumericUnit?: string;
}

export interface LinkedFromGroupActivity {
    _id: string;
    name: string;
    currentPhase: string;
    index: number;
    primaryNumericField?: number;
    primaryDateField?: number;
}

export type ActivitySidenavActions = 'create' | 'editMultiple' | 'view';

export interface ToSave {
    /** Used for creating and editing a single activity */
    activity: ActivityTemplateActivity;
    /** Used for editing multiple activities */
    activities: {
        _id: string;
        name: string;
    }[];
    /** Options for editing and creating activities **/
    options: ActivityTemplateOptions;
}

export interface ActivityTemplateActivity {
    name?: string;
    /** Id is not required when creating activity */
    _id?: string;
    /** Used for only creating activities */
    fileIds?: string[];
    location?: ActivityLocation | null;
    /** Edited activity fields, used when creating an activity */
    fields?: { [fieldId: string]: ActivityFieldValue };
}

export interface ActivityTemplateOptions {
    /** Required when moving activity or creating one in a dataset */
    phaseId?: string;
    /** Used when creating activity */
    followerIds?: string[];
    /** Owner team id */
    teamId?: string;
    /** Owner user id */
    userId?: string;
    /** Used when upgrading discussion */
    discussionId?: string;
    /** Edited activity fields */
    fields?: { [fieldId: string]: ActivityFieldValue };
    /** Used when editing activities */
    files?: { [fileId: string]: boolean | null };
    /** Map of activity followers to be saved,
     *  set as null to remove a follower
     **/
    followers?: { [followerId: string]: boolean };
    location?: ActivityLocation | null;
    /** Activity creation date */
    created?: number;
}

export interface ActivityLinkField {
    _id: string;
    name: string;
}

export interface ActivityFieldRange {
    start: number;
    end: number;
}

export interface ActivityFieldError {
    error?: string
}

export interface ActivityFieldCountry {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'country-code': string;
    code: string;
    name: string;
}

export type NumericFieldType = 'date' | 'datetime' | 'numeric' | 'numericunit' | 'time';

export type RangeFieldType = 'daterange' | 'datetimerange' | 'timerange';

export type FieldType =
    | NumericFieldType
    | RangeFieldType
    | 'activitylink'
    | 'country'
    | 'teams'
    | 'text'
    | 'textarea'
    | 'textpredefinedoptions'
    | 'textunit'
    | 'users';

export interface ActivityField {
    /** FieldId */
    id: string;
    /** Field value */
    value: ActivityFieldValue;
    /** Type of field,  */
    type: FieldType;
    /** Evaluation error */
    error?: string;
}

export type ActivityFieldValue = boolean | number | string | (ActivityFieldCountry & ActivityFieldError & ActivityFieldRange);

export interface ActivityLocation {
    type?: ActivityLocationType;
    label?: string;
    data?: ActivityLocationData[];
}

export type ActivityLocationType = 'area' | 'point' | 'polyline';

export interface ActivityLocationData {
    lat: number;
    lng: number;
}

export interface ActivitiesRemovedResponse {
    success: string[];
    failed: string[];
}

export interface ActivityCopyOptions {
    isLinked?: boolean;
    fieldMap?: {
        _id: string;
        value: {
            _id: string;
            name: string;
        };
        type: ProcessFieldType;
    };
    workspaceId?: string;
    advanced?: boolean;
    rename?: { [activityId: string]: string };
}

export type ActivityTabTypes = 'detail' | 'discussion' | 'files' | 'linkedFrom' | 'location' | 'options';

export interface ActivityExcelSchema {
    column: string;
    value: any;
    width: number;
    type?: any;
    format?: string;
    height?: number;
}

export interface V3ImportEditData {
    activities: Activity[];
    workflowId: string;
    phaseId: string;
    errors: ActivityImportErrorsMap;
}

export const WorkflowStaticFields: ProcessFieldType[] = ['linkedfrom', 'subheader'];

export interface ActivityImportErrorsMap {
    [activityId: string]: {
        [fieldId: string]: boolean;
    };
}

export interface LinkedFromOverviewOptions {
    /** Determines how many activities can be loaded per phase */
    activityLimit?: number;
    /** Returns workflows with no activities */
    includeEmptyWorkflows?: boolean;
    language?: string;
}

export interface LinkedFromNextOptions {
    /** Determines how many activities can be loaded per phase */
    activityLimit?: number;
}

export interface LinkedFromSearchOptions {
    /** Determines how many activities can be loaded per phase */
    activityLimit?: number;
}

export interface LinkedFromRefreshOptions {
    /** Determines how many activities can be loaded per phase */
    activityIds?: string[];
    searchString?: string;
}

export interface ActivitiesUpdatedSignal {
    activity_id: string[];
    phase?: string;
    processId?: string;
    removed?: boolean;
}

export interface SubheaderFieldGroupMap {
    [subheaderFieldId: string]: string[];
}
