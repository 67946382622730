<!-- @format -->

<div class="dialog-header">
    <h1 mat-dialog-title>{{'feed.visibility-dialog.header' | transloco}}</h1>
    <app-button
        type="icon"
        hailerIconName="hailer-x"
        color="icon-white-all-themes"
        tippyText="{{ 'shared.close-popup' | transloco }}"
        ariaLabel="{{ 'shared.close-popup' | transloco }}"
        (clickEvent)="close()"></app-button>
</div>

<h2 class="dialog-second-header second-header">{{'feed.visibility-dialog.title' | transloco}}</h2>
<p class="dialog-body-text">{{'feed.visibility-dialog.description' | transloco}}</p>

<div>
    <!-- TODO: only users that have more than 1 workspace need the workspace selector -->
    <p class="selector-label flex-align-center"><mat-icon svgIcon="hailer-workspace" class="margin-right-small"></mat-icon>{{'feed.visibility-dialog.workspace' | transloco}}</p>
    <app-dropdown-selector *ngIf="!data?.post"
        [options]="workspaceSelectorOptions"
        [selectedItems]="workspaceSelectorSelectedItems"
        [items]="workspaceSelectorItems">
    </app-dropdown-selector>
    <p class="selector-label flex-align-center"><mat-icon svgIcon="hailer-team" class="margin-right-small"></mat-icon>{{'feed.visibility-dialog.recipients' | transloco}}</p>
    <app-team-user-selector
        *ngIf="recipientSelectorReady"
        data-cy="post-recipient-selector"
        [resetSelection]="resetRecipientSelector"
        [options]="userSelectorOptions"
        [hideItems]="userSelectorHideItems"
        [predefinedItems]="userSelectorPredefinedItems"
        (results)="recipientChange($event)">
    </app-team-user-selector>
</div>

<div class="bottom-buttons-wrapper">
    <app-button type="cancel" size="large" width="popup-two-buttons" (clickEvent)="close()"></app-button>
    <app-button
        [dataCy]="'save-visibility-settings-button'"
        type="save"
        size="large"
        width="popup-two-buttons"
        (clickEvent)="confirm()"></app-button>
</div>
