/** @format */

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, filter, takeUntil } from 'rxjs';

import { FeedService } from '../feed.service';
import { CoreService } from 'app/_services/core.service';
import { FeedPost } from '@app/models';
import { feedPostLikeButtonAnimations } from './feed-post-like-button.animations';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    selector: 'app-feed-post-like-button',
    templateUrl: './feed-post-like-button.component.html',
    styleUrls: ['./feed-post-like-button.component.scss'],
    animations: feedPostLikeButtonAnimations,
})
export class FeedPostLikeButtonComponent implements OnInit, OnDestroy {
    @Input() feedPost: FeedPost;

    likeStatus = false;

    private onDestroy = new Subject<void>();

    constructor(
        public core: CoreService,
        private feedService: FeedService,
        private cdr: ChangeDetectorRef,
        private rpc: RPCService,
    ) { }

    ngOnInit() {
        this.core.permission.changed
            .pipe(
                takeUntil(this.onDestroy),
                filter(workspaceIds => workspaceIds.includes(this.feedPost.cid))
            )
            .subscribe(() => this.cdr.detectChanges());
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    stateName(): string {
        return this.getLikeStatus() ? 'liked' : 'not-liked';
    }

    async likePost(): Promise<void> {
        const newLikeStatus = !this.getLikeStatus();
        try {
            await this.rpc.requestAsync('wall2.like_post', [this.feedPost._id, newLikeStatus ? 'like' : 'unlike']);
        } catch (error) {
            console.log('Failed to like post', error);
        }
    }

    private getLikeStatus(): boolean {
        const me = this.core.user.value;
        if (me && me.id) {
            return this.feedPost.likes?.includes(me.id) || false;
        }
        return false;
    }
}
