/** @format */

import { createReducer, on } from '@ngrx/store';

import { UserMap } from '@app/models';
import { setUsers } from '../actions/users.actions';

export const initialState: UserMap = {};

const reducer = createReducer(
    initialState,
    on(setUsers, (state, { users }) => ({ ...users }))
);

export const usersReducer = (state, action) => reducer(state, action);
