<ng-template #unsetField let-text="text">
    <span class="unset-field">{{text ? text : 'discussion.message_activity_update.empty' | transloco}}</span>
</ng-template>

<div *ngIf="message.meta.name">
    {{ 'discussion.message_activity_update.name' | transloco }}{{message.meta.name.old}}
    <mat-icon inline="true">chevron_right</mat-icon>
    {{message.meta.name.new}}
</div>

<div class="update-container" *ngIf="message.meta.phase && process">
    <p class="update-title two-line-ellipsis">{{ 'discussion.message_activity_update.moved' | transloco }}</p>
    <div class="new-value-field" *ngIf="!!process.phases[message.meta.phase.new]; else unsetField">
        {{process.phases[message.meta.phase.new]?.name}}
    </div>
    <div *ngIf="hasValue(update?.old)" class="old-value-field">
        <div class="was-text">{{ 'discussion.message_activity_update.was' | transloco }}</div>
        <ng-container *ngIf="!!process.phases[message.meta.phase.old]; else unsetField">
            {{process.phases[message.meta.phase.old]?.name}}
        </ng-container>
    </div>
</div>

<div *ngFor="let update of updates" class="update-container" [class]="update.type + '-field'">
    <p class="update-title two-line-ellipsis">{{update.field.label}}: </p>

    <div class="new-value-field" *ngIf="hasValue(update?.new); else unsetField">
        <ng-container [ngTemplateOutlet]="updateField"
            [ngTemplateOutletContext]="{type: update.type, updateValue: update.new, modifier: update.field.modifier, fieldUnit: update.field.unit}">
        </ng-container>
    </div>
    <div *ngIf="hasValue(update?.old)" class="old-value-field">
        <div class="was-text">{{ 'discussion.message_activity_update.was' | transloco }}</div>
        <ng-container [ngTemplateOutlet]="updateField"
            [ngTemplateOutletContext]="{type: update.type, updateValue: update.old, modifier: update.field.modifier, fieldUnit: update.field.unit}">
        </ng-container>
    </div>
</div>

<ng-template #updateField let-type="type" let-modifier="modifier" let-updateValue="updateValue" let-fieldUnit="fieldUnit">
    <ng-container class="update-field" [ngSwitch]="type">
        <ng-container *ngSwitchCase="'date'">
            {{updateValue | date:'MMMM d, y': '+0000': ('misc.date.locale' | transloco)}}
        </ng-container>
        <ng-container *ngSwitchCase="'datetime'">
            {{updateValue | date:'MMMM d, HH:mm': undefined: ('misc.date.locale' | transloco)}}
        </ng-container>
        <ng-container *ngSwitchCase="'daterange'">
            {{updateValue.start | date:'MMMM d, y': '+0000': ('misc.date.locale' | transloco)}}
            -
            {{updateValue.end | date:'MMMM d, y': '+0000': ('misc.date.locale' | transloco)}}
        </ng-container>
        <ng-container *ngSwitchCase="'datetimerange'">
            {{updateValue.start | date:'MMMM d, HH:mm': undefined: ('misc.date.locale' | transloco)}}
            -
            {{updateValue.end | date:'MMMM d, HH:mm': undefined: ('misc.date.locale' | transloco)}}
        </ng-container>
        <ng-container *ngSwitchCase="'time'">
            {{updateValue | date:'HH:mm'}}
        </ng-container>
        <ng-container *ngSwitchCase="'timerange'">
            {{updateValue.start | date:'HH:mm'}}
            -
            {{updateValue.end | date:'HH:mm'}}
        </ng-container>
        <ng-container *ngSwitchCase="'users'" class="white-space-fix">
            {{getUserName(updateValue)}}
        </ng-container>
        <ng-container *ngSwitchCase="'activitylink'">
            {{updateValue.name}}
        </ng-container>
        <ng-container *ngSwitchCase="'teams'">
            {{teamMap[updateValue]?.name}}
        </ng-container>
        <ng-container *ngSwitchCase="'country'">
            <span class="fi" [ngClass]="flagClass(updateValue.code)"></span>
            {{updateValue.name}}
        </ng-container>
        <ng-container *ngSwitchCase="'textarea'">
            <div [innerHTML]="updateValue | htmlEscape | hashtag | urlify | nl2br | safe:'html'"></div>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
            <div *ngIf="modifier?.file">{{getFieldFileCount(updateValue)}} <mat-icon class="fileinfo" >insert_drive_file</mat-icon></div>
            <div  *ngIf="!modifier?.file" class="two-line-ellipsis" [innerHTML]="updateValue | htmlEscape | hashtag | urlify | nl2br | safe:'html'"></div>
        </ng-container>
        <ng-container *ngSwitchCase="'textpredefinedoptions'">
            {{updateValue}}
        </ng-container>
        <ng-container *ngSwitchCase="'numeric'">
        <ng-container *ngIf="!modifier?.checkbox">
            {{updateValue | thousandSeparator}}
        </ng-container>
        <div class="some-class-container" *ngIf="modifier?.checkbox">
            <mat-checkbox [checked]="!!updateValue" (click)="$event.preventDefault()"></mat-checkbox>
        </div>
        </ng-container>
        <ng-container *ngSwitchCase="'numericunit'">
            {{updateValue | thousandSeparator}} {{fieldUnit || ''}}
        </ng-container>
        <ng-container *ngSwitchDefault>
            n/a
        </ng-container>
    </ng-container>
</ng-template>