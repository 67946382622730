import { useState } from 'react';
import { useInsight } from './use-insight';
import useClickOutside from './useClickOutside';
import Tooltip from './tooltip';

export default function FilterMenu() {
    const { api, data, filters } = useInsight();
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useClickOutside(() => setIsOpen(false));

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            setIsOpen(false);
        }
    }

    const handleExportButtonClick = () => {
        setIsOpen(!isOpen);
    }

    const setTableColumnFilter = (index: number, filter: any) => {
        api.filters.setColumns(index, filter || null);
    };

    const filterActive = (): boolean => {
        return Object.keys(filters.columns).length > 0;
    }

    return (
        <div role="menu" className="insight-export-menu-container" aria-expanded={isOpen} onKeyDown={handleKeyDown} ref={menuRef}>
            <Tooltip text='Filter data' position='bottom'>
            <button className="insight-button insight-icon-button" onClick={handleExportButtonClick} aria-haspopup="true" tabIndex={0}>
                <span className={filterActive() ? "material-symbols-outlined bubble": "material-symbols-outlined"}>filter_alt</span>
            </button>
            </Tooltip>
            {isOpen && (
                <div className='insight-filter-menu-container' tabIndex={-1}>
                    <div className="insight-filter-menu">
                        <form style={{ display: 'flex', flexDirection: 'column' }}>

                            {data.data.headers.map((header, index) => (
                                <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <input className="insight-input wide-insight-input" style={{ float: 'left' }} type="text" onChange={(event: any) => setTableColumnFilter(index, event.target.value)} placeholder={header} value={filters.columns?.[index] || ''} />
                                </div>
                            ))}

                            <button className="insight-button insight-button-with-icon" type="button" onClick={api.filters.clearSortingAndFilters}>
                                <span className="material-symbols-outlined icon">close</span>
                                Clear Filters
                            </button>
                        </form>
                    </div>

                </div>
            )}
        </div>
    );
};

