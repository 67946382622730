/** @format */

import { Injectable } from '@angular/core';

import { File } from '@app/models';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    constructor() {}

    checkMatch(search: string, input: string): boolean {
        return input.toLowerCase().replace(' ', '').indexOf(search.toLowerCase().replace(' ', '')) > -1;
    }

    sortAlphabetically(prop: string) {
        return (a: any, b: any) => {
            const nameA = a[prop].toLowerCase();
            const nameB = b[prop].toLowerCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        };
    }

    isImage(attachment: File): boolean {
        if (
            attachment.type === 'image/png' ||
            attachment.type === 'image/jpeg' ||
            attachment.type === 'image/jpg' ||
            attachment.type === 'image/gif'
        ) {
            return true;
        }
        return false;
    }

    getLocalStorageSettings(key: string): any {
        if (!key) {
            return null;
        }
        const storageItem = localStorage.getItem(key);

        if (storageItem) {
            let settings;

            try {
                settings = JSON.parse(storageItem);
            } catch (error) {
                // Invalid JSON
                return null;
            }

            return settings;
        }
        return null;
    }
}
