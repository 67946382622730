<div *ngIf="!link.data.code; else linkError" (click)="onLinkClick()" [ngSwitch]="link.targetType" class="message-link-container">
    <div class="flex-column feed-reply" *ngSwitchCase="'feedPost'">
        <span class="flex-row--centered feed-reply-header">
            <mat-icon svgIcon="hailer-reply-fill" class="reply-icon"></mat-icon>
            <p data-cy="feedPost-data-subject" class="feed-post-subject">{{link.data.subject}}</p>
        </span>
        <div data-cy="feedPost-data-text" [innerHTML]="link.data.text | markdown : link?.data?.linkedData | async"></div>
    </div>
    <div *ngSwitchCase="'poll'">
        <app-poll [sourceId]="messageId" [inputPoll]="link.data"></app-poll>
    </div>
    <div *ngSwitchDefault></div>
</div>

<ng-template #linkError>
    <div class="flex-align-center message-link-error-message">
        <p>{{link.data.message}}</p>
    </div>
</ng-template>
