/** @format */

import { Deserializable } from './deserializable.model';

export class RegistrationData implements Deserializable {
    email: string;
    firstname: string;
    lastname: string;
    password: string;
    password_confirm: string;
    token?: string;
    check?: number;
    invite_code?: string;
    team?: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
