/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingProgressService {
    status = new BehaviorSubject<LoadingStatus>({
        percentage: 10,
        label: 'Starting Hailer',
    });
    loaded = new BehaviorSubject<boolean>(false);

    hideLoadingScreen(delay = 200): void {
        this.status.next({ percentage: 100, label: "We're in!" });
        this.loaded.next(true);
    }
}

export interface LoadingStatus {
    percentage: number;
    label: string;
}
