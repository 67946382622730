/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

// For size comparison
enum sizeBreakpoint {
    mobile = 0,
    tablet = 600,
    desktop = 1000,
    unknown = -1,
}

@Injectable({
    providedIn: 'root',
})
export class WindowListenerService {
    orientationChange = new Subject<Event>();
    size = new BehaviorSubject<{ x: number; y: number }>({ x: 0, y: 0 });
    sizeBreakpoint = new BehaviorSubject<sizeBreakpoint>(sizeBreakpoint.tablet);
    width = window.innerWidth;
    height = window.innerHeight;

    updateDeviceType() {
        switch (true) {
            case this.width >= sizeBreakpoint.desktop:
                this.sizeBreakpoint.next(sizeBreakpoint.desktop);
                break;
            case this.width >= sizeBreakpoint.tablet:
                this.sizeBreakpoint.next(sizeBreakpoint.tablet);
                break;
            case this.width >= sizeBreakpoint.mobile:
                this.sizeBreakpoint.next(sizeBreakpoint.mobile);
                break;
            default:
                this.sizeBreakpoint.next(sizeBreakpoint.unknown);
        }
    }

    constructor() {
        window.addEventListener('orientationchange', event => {
            this.orientationChange.next(event);
        });

        // Creating initial window size values
        this.size.next({ x: this.width, y: this.height });
        this.updateDeviceType();

        // Changing the window size values when window size changes
        window.addEventListener('resize', () => {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            this.updateDeviceType();
            this.size.next({ x: this.width, y: this.height });
        });
    }
}
