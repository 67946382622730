/** @format */

import { Injectable } from '@angular/core';

import { Company, Process } from '@app/models';
import { CoreService } from './core.service';

@Injectable({
    providedIn: 'root',
})
export class StylingHelperService {
    constructor(private core: CoreService) {}

    getNetworkBackgroundColor(network: Company | null | undefined): string {
        if (!network || !network?.settings?.color) {
            return 'var(--container-background)';
        }
        const networkBackgroundColor = network.settings.color;
        return networkBackgroundColor;
    }

    getNetworkTextColor(network: Company | null | undefined): string {
        if (!network || !network?.settings?.color) {
            return 'var(--white)';
        }
        const networkTextColor = this.setTextColor(network.settings.color);
        return networkTextColor;
    }

    getWorkflowBackgroundColor(workflow: Process | null | undefined): string {
        if (!workflow || !workflow?.color) {
            return 'var(--workflow-base-theme)';
        }
        const workflowBackgroundColor = workflow.color;
        return workflowBackgroundColor;
    }

    getWorkflowTextColor(workflow: Process | null | undefined): string {
        if (!workflow || !workflow?.color) {
            return 'var(--white)';
        }
        const workflowTextColor = this.setTextColor(workflow.color);
        return workflowTextColor;
    }

    private setTextColor(hex): string {
        /* First set the hex from mcc colour picker to rgb */
        const regex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

        /* Make sure the hex value is full form */
        const hexFull = hex.replace(regex, (_m: string, r: string, g: string, b: string) => r + r + g + g + b + b);

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexFull);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [fullHex, redHex, greenHex, blueHex] = result || [];

        if (!redHex || !greenHex || !blueHex) {
            console.warn('Error converting hex to rgb for network background during text color setting. Regex result: ', result);
            return 'var(--white)';
        }

        /* RGB codes */
        const r: number = parseInt(redHex, 16);
        const g: number = parseInt(greenHex, 16);
        const b: number = parseInt(blueHex, 16);

        const colorContrast = (r * 299 + g * 587 + b * 114) / 1000;

        /* Range is from 0-255
          128 is halfway, but used 130 because of the in between colours which need white because black is just too dark
        */

        return colorContrast <= 130 ? 'var(--white)' : 'var(--black)';
    }
}
