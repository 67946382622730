/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { TranslateService } from 'app/_services/translate.service';

@Pipe({
    name: 'timeago',
})
export class TimeagoPipe implements PipeTransform {
    constructor(private translocoService: TranslocoService, private translateService: TranslateService) {}
    async transform(value: Date, format: string, pAllowFuture: any): Promise<any> {
        await this.translateService.translationLoaded('misc');
        const shortStrings2 = {
            prefixAgo: '',
            prefixFromNow: '',
            suffixAgo: '',
            suffixFromNow: '',
            seconds: this.translocoService.translate('misc.time_ago.short_strings_2.seconds'),
            minute: this.translocoService.translate('misc.time_ago.short_strings_2.minute'),
            minutes: `%d${this.translocoService.translate('misc.time_ago.short_strings_2.minutes')}`,
            hour: this.translocoService.translate('misc.time_ago.short_strings_2.hour'),
            hours: `%d${this.translocoService.translate('misc.time_ago.short_strings_2.hours')}`,
            day: this.translocoService.translate('misc.time_ago.short_strings_2.day'),
            days: `%d${this.translocoService.translate('misc.time_ago.short_strings_2.days')}`,
            month: this.translocoService.translate('misc.time_ago.short_strings_2.month'),
            months: `%d${this.translocoService.translate('misc.time_ago.short_strings_2.months')}`,
            year: this.translocoService.translate('misc.time_ago.short_strings_2.year'),
            years: `%d${this.translocoService.translate('misc.time_ago.short_strings_2.years')}`,
            wordSeparator: undefined,
        };
        const shortStrings = {
            prefixAgo: '',
            prefixFromNow: '',
            suffixAgo: '',
            suffixFromNow: '',
            seconds: this.translocoService.translate('misc.time_ago.short_strings.seconds'),
            minute: this.translocoService.translate('misc.time_ago.short_strings.minute'),
            minutes: `%d${this.translocoService.translate('misc.time_ago.short_strings.minutes')}`,
            hour: this.translocoService.translate('misc.time_ago.short_strings.hour'),
            hours: `%d${this.translocoService.translate('misc.time_ago.short_strings.hours')}`,
            day: this.translocoService.translate('misc.time_ago.short_strings.day'),
            days: `%d${this.translocoService.translate('misc.time_ago.short_strings.days')}`,
            month: this.translocoService.translate('misc.time_ago.short_strings.month'),
            months: `%d${this.translocoService.translate('misc.time_ago.short_strings.months')}`,
            year: this.translocoService.translate('misc.time_ago.short_strings.year'),
            years: `%d${this.translocoService.translate('misc.time_ago.short_strings.years')}`,
            wordSeparator: undefined,
        };
        const longStrings = {
            prefixAgo: '',
            prefixFromNow: '',
            suffixAgo: this.translocoService.translate('misc.time_ago.long_strings.suffix_ago'),
            suffixFromNow: this.translocoService.translate('misc.time_ago.long_strings.suffix_from_now'),
            seconds: this.translocoService.translate('misc.time_ago.long_strings.seconds'),
            minute: this.translocoService.translate('misc.time_ago.long_strings.minute'),
            minutes: `%d${this.translocoService.translate('misc.time_ago.long_strings.minutes')}`,
            hour: this.translocoService.translate('misc.time_ago.long_strings.hour'),
            hours: `${this.translocoService.translate('misc.time_ago.long_strings.hours.about')}%d${this.translocoService.translate(
                'misc.time_ago.long_strings.hours.hours'
            )}`,
            day: this.translocoService.translate('misc.time_ago.long_strings.day'),
            days: `%d${this.translocoService.translate('misc.time_ago.long_strings.days')}`,
            month: this.translocoService.translate('misc.time_ago.long_strings.month'),
            months: `%d${this.translocoService.translate('misc.time_ago.long_strings.months')}`,
            year: this.translocoService.translate('misc.time_ago.long_strings.year'),
            years: `%d${this.translocoService.translate('misc.time_ago.long_strings.years')}`,
            wordSeparator: undefined,
        };

        const nowTime = new Date().getTime();
        const date = new Date(value).getTime();
        const dateDifference = nowTime - date;
        const allowFuture = pAllowFuture || false;
        const strings = format === 'short' ? shortStrings : format === 'short2' ? shortStrings2 : longStrings;
        let words = '';
        const seconds = Math.abs(dateDifference) / 1000;
        const minutes = seconds / 60;
        const hours = minutes / 60;
        const days = hours / 24;
        const years = days / 365;
        const separator = strings.wordSeparator === undefined ? ' ' : strings.wordSeparator;
        let prefix = strings.prefixAgo;
        let suffix = strings.suffixAgo;

        const substitute = (stringOrFunction, numberToSubstitute: number, inputStrings) => {
            let newString = '';
            if (typeof stringOrFunction === 'function') {
                newString = stringOrFunction(numberToSubstitute, dateDifference);
            } else {
                newString = stringOrFunction;
            }
            const returnValue = (inputStrings.numbers && inputStrings.numbers[numberToSubstitute]) || numberToSubstitute;
            return newString.replace(/%d/i, returnValue);
        };

        if (allowFuture) {
            if (dateDifference < 0) {
                prefix = strings.prefixFromNow;
                suffix = strings.suffixFromNow;
            }
        }
        words =
            (seconds < 45 && substitute(strings.seconds, Math.round(seconds), strings)) ||
            (seconds < 90 && substitute(strings.minute, 1, strings)) ||
            (minutes < 45 && substitute(strings.minutes, Math.round(minutes), strings)) ||
            (minutes < 90 && substitute(strings.hour, 1, strings)) ||
            (hours < 24 && substitute(strings.hours, Math.round(hours), strings)) ||
            (hours < 42 && substitute(strings.day, 1, strings)) ||
            (days < 30 && substitute(strings.days, Math.round(days), strings)) ||
            (days < 45 && substitute(strings.month, 1, strings)) ||
            (days < 365 && substitute(strings.months, Math.round(days / 30), strings)) ||
            (years < 1.5 && substitute(strings.year, 1, strings)) ||
            substitute(strings.years, Math.round(years), strings);
        prefix.replace(/ /g, '');
        words.replace(/ /g, '');
        suffix.replace(/ /g, '');

        return `${prefix} ${words} ${suffix} ${separator}`;
    }
}
