<div class="copy-activity-window">
    <div class="modal-header">
        <h1 data-cy="copy-dialog-header">{{ 'dialogs.copy_activities_dialog.copy_activities_header' | transloco }}</h1>
        <app-button
            data-cy="close-button"
            type="icon"
            hailerIconName="hailer-x"
            color="icon-white-all-themes"
            size="medium"
            [tippyText]="'dialogs.close' | transloco"
            [ariaLabel]="'dialogs.close-dialog' | transloco"
            (clickEvent)="onCancel()"
        ></app-button>
    </div>
    <h2 data-cy="copy-dialog-second-header" class="dialog-second-header">{{ 'dialogs.copy_activities_dialog.copy_activities_header' | transloco }}</h2>
    <p class="warning-text">
        {{
            data.activities.length > 1
                ? data.isDataset
                    ? ('dialogs.copy_activities_dialog.warning-text-dataset' | transloco)
                    : ('dialogs.copy_activities_dialog.warning-text-phase' | transloco)
                : data.isDataset
                    ? ('dialogs.copy_activities_dialog.warning-text-dataset-single-activity' | transloco)
                    : ('dialogs.copy_activities_dialog.warning-text-phase-single-activity' | transloco)
        }}
    </p>
    <div class="content" *ngIf="!data.disableNameEditing">
        <form [formGroup]="copyableActivitiesForm">
            <div class="rename-item" *ngFor="let activity of data.activities; trackBy: trackById">
                <mat-icon class="activity-icon" svgIcon="hailer-activity"></mat-icon>
                <p data-cy="original-name" class="original-name">{{activity.name}}</p>
                <mat-icon svgIcon="hailer-chevron" class="hailer-chevron right"></mat-icon>
                <mat-form-field appearance="outline" class="renamed-name">
                    <textarea data-cy="renamed-name" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"
                        formControlName="{{activity._id}}"></textarea>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div class="copy-linked-activities-text">
        <mat-checkbox data-cy="copy-licked-activities-check-box" [disabled]="!data.linkedActivities" [checked]="advanced" (change)="advanced = !advanced" class="checkbox-thin"><span class="checkbox-text-margin">{{'dialogs.copy_activities_dialog.checkout_button' | transloco}}</span></mat-checkbox>
    </div>
    <div class="copy-activities-button-container flexbox-row--space-around-centered">
        <app-button data-cy="cancel-button" type="cancel" size="medium" (clickEvent)="onCancel()" width="half"></app-button>
        <app-button
            data-cy="copy-button"
            type="text"
            color="primary"
            size="medium"
            [label]="'dialogs.copy_activities_dialog.button_copy_text' | transloco"
            [ariaLabel]="'dialogs.copy_activities_dialog.button_copy_text' | transloco"
            (clickEvent)="copyActivities()"
            [isDisabled]="!copyableActivitiesForm.valid"
            width="half"
        ></app-button>
    </div>
</div>
