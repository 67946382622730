import { distinctValueLimit, useInsight } from "../use-insight";
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import "@szhsin/react-menu/dist/theme-dark.css";

export default function PivotValueSelector(props: {  }) {
    const { api, pivot, data, theme } = useInsight();

    return (
        <>
        <div className="pivot-value-wrapper">
            <Menu menuButton={
                <MenuButton className="insight-button insight-button-with-icon icon-on-right">
                    {pivot.value.formula || 'Select'} {pivot.value.column && '|'} {pivot.value.formula !== 'Count' && data.data.headers[pivot.value.column]}
                    <span className="material-symbols-outlined icon">expand_more</span>
                </MenuButton>}
                theming={theme === 'dark' ? 'dark' : undefined}
                menuClassName="custom-insight-react-menu"
                overflow="auto">
        
            <MenuItem value="Count" onClick={() => {api.pivot.setPivotValueFormula('Count'); api.pivot.setPivotSumColumn(undefined); }}>
                Count
            </MenuItem>
            <SubMenu label="Sum" overflow="auto" menuClassName="custom-insight-react-menu">
                {data.data.headers.map((header, index) => ({ header, index })).filter(({ index }) => data.type[index].number).map(({ header, index }) => (
                <MenuItem key={index} value={index} onClick={
                    () => {
                        api.pivot.setPivotValueFormula('Sum');
                        api.pivot.setPivotSumColumn(index);
                    }
                }>
                    {header}
                </MenuItem>
            ))}
            </SubMenu>
            <SubMenu label="Min" overflow="auto" menuClassName="custom-insight-react-menu">
                {data.data.headers.map((header, index) => ({ header, index })).filter(({ index }) => data.type[index].number).map(({ header, index }) => (
                <MenuItem key={index} value={index} onClick={
                    () => {
                        api.pivot.setPivotValueFormula('Min');
                        api.pivot.setPivotSumColumn(index);
                    }
                }>
                    {header}
                </MenuItem>
            ))}
            </SubMenu>
            <SubMenu label="Max" overflow="auto" menuClassName="custom-insight-react-menu">
                {data.data.headers.map((header, index) => ({ header, index })).filter(({ index }) => data.type[index].number).map(({ header, index }) => (
                <MenuItem key={index} value={index} onClick={
                    () => {
                        api.pivot.setPivotValueFormula('Max'); 
                        api.pivot.setPivotSumColumn(index); 
                    }
                }>
                    {header}
                </MenuItem>
            ))}
            </SubMenu>
            <SubMenu label="Avg" overflow="auto" menuClassName="custom-insight-react-menu">
                {data.data.headers.map((header, index) => ({ header, index })).filter(({ index }) => data.type[index].number).map(({ header, index }) => (
                <MenuItem key={index} value={index} onClick={
                    () => {
                        api.pivot.setPivotValueFormula('Avg'); 
                        api.pivot.setPivotSumColumn(index); 
                    }
                }>
                    {header}
                </MenuItem>
            ))}
            </SubMenu>
            </Menu>
        </div>
      </>
    );
}
