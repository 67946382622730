/** @format */

import { createReducer, on } from '@ngrx/store';
import * as moment from 'moment';

import { Message } from '@app/models';
import {
    clearMessages,
    getMessageSuccess,
    loadInitialMessagesSuccess,
    loadNextMessagesSuccess,
    loadPreviousMessagesSuccess,
    setScrollPosition,
} from '../actions/message.actions';
import { V3DiscussionMessageResponseMap } from 'app/_services/v3-messages.service';

export const initialState: any = {};
const maxMessageAmount = 180;
const consecutiveDiffMinutes = 5;

const reducer = createReducer(
    initialState,
    on(loadInitialMessagesSuccess, (state, data) => reduceMessages('initial', state, data)),
    on(loadPreviousMessagesSuccess, (state, data) => reduceMessages('previous', state, data)),
    on(loadNextMessagesSuccess, (state, data) => reduceMessages('next', state, data)),
    on(getMessageSuccess, (state, data) => updateStateMessages(state, data)),
    on(clearMessages, (state, { discussionId }) => {
        const { [discussionId]: _, ...newState } = state;
        return newState;
    }),
    on(setScrollPosition, (state, { discussionId, scrollTop }) => {
        const stateObj = { ...state[discussionId] };
        stateObj.scrollTop = scrollTop;

        const newObj = { [discussionId]: stateObj };

        return { ...state, ...newObj };
    })
);

type reducingMode = 'initial' | 'next' | 'previous';

const reduceMessages = (reducingMode: reducingMode, state, data): Message[] => {
    const discussionId = Object.keys(data.messages)[0];

    const initialMode = reducingMode === 'initial';
    const nextMode = reducingMode === 'next';

    if (!initialMode && !state[discussionId]) {
        return;
    }

    const messages: Message[] = data.messages[discussionId];

    const parsedInitialMessages: Message[] = [];
    const parsedNewerMessages: Message[] = [];
    const parsedOlderMessages: Message[] = [];

    let oldestLoaded = state[discussionId]?.oldestLoaded || false;
    let newestLoaded = state[discussionId]?.newestLoaded || false;

    const previousStateMessages = state[discussionId]?.messages || [];
    const stateMessages = [...previousStateMessages] || [];

    let previousMessage: Message;

    /*  If adding newer messages, set previousMessage to the newest message in the current state,
        so that the next message has a message to be compared with in the messages.forEach loop.
        This is crucial for new messages arriving in a discussion you're viewing to have date dividers
        and consecutive status set accordingly.
    */
    if (nextMode && stateMessages[0]) {
        previousMessage = stateMessages[0];
    }

    // Loop through the messages
    (nextMode ? [...messages].reverse() : messages).forEach((message, index) => {
        const msg = { ...message } as Message;
        const messageCreated = moment(msg.created);
        let previousMessageCreated = null;

        /* Compare the msg in current iteration to the previous,
               in order to set a date divider and consecutive status */
        if (previousMessage) {
            previousMessageCreated = moment(previousMessage.created);
            let insertDateDivider = false;

            if (messageCreated.format().split('T')[0] !== previousMessageCreated.format().split('T')[0]) {
                insertDateDivider = true;
                switch (reducingMode) {
                    case 'initial':
                        parsedInitialMessages.push(createDateDivider(previousMessage.created));
                        break;
                    case 'next':
                        parsedNewerMessages.unshift(createDateDivider(msg.created));
                        break;
                    case 'previous':
                        parsedOlderMessages.push(createDateDivider(previousMessage.created));
                        break;
                    default:
                        break;
                }
            }

            // Conditions for two messages to be consecutive
            const sameUserInPreviousMessage = previousMessage.uid === msg.uid;
            const userMessage = message.type === 'user' && previousMessage.type === 'user';
            const createdInCorrectTime = Math.abs(previousMessageCreated.diff(messageCreated, 'minutes')) < consecutiveDiffMinutes;
            const isConsecutive = sameUserInPreviousMessage && !insertDateDivider && userMessage && createdInCorrectTime;

            // Depending on the order of adding messages, set consecutive status to current or previous message
            if (nextMode) {
                msg.isConsecutive = isConsecutive;
            } else {
                previousMessage.isConsecutive = isConsecutive;
            }
        }

        switch (reducingMode) {
            case 'initial':
                parsedInitialMessages.push(msg);
                break;
            case 'next':
                parsedNewerMessages.unshift(msg);
                break;
            case 'previous':
                parsedOlderMessages.push(msg);
                break;
            default:
                break;
        }

        previousMessage = msg;
        let messageCount = 0;
        // Insert date divider over oldest loaded message
        if (index + 1 === messages.length) {
            switch (reducingMode) {
                case 'initial':
                    parsedInitialMessages.push(createDateDivider(previousMessage.created));
                    break;
                case 'next':
                    // If new total message amount exceeds maxMessageAmount, remove them from the top (aka oldest)
                    messageCount = stateMessages?.length + parsedNewerMessages.length;
                    const removeOldestMessages = messageCount > maxMessageAmount;

                    if (removeOldestMessages) {
                        const spliceStart = stateMessages.length - (messageCount - maxMessageAmount);
                        stateMessages.splice(spliceStart);
                        oldestLoaded = false;
                    }

                    const oldestIndex = stateMessages.length - 1;
                    const oldestMessage = stateMessages[oldestIndex];

                    // Insert date divider over oldest loaded message if there's none
                    if (!oldestMessage?.dateDivider) {
                        const topMsg = { ...oldestMessage };
                        delete topMsg.isConsecutive;
                        stateMessages[oldestIndex] = topMsg;
                        stateMessages.push(createDateDivider(oldestMessage.created));
                    }
                    break;
                case 'previous':
                    // If new total message amount exceeds maxMessageAmount, remove them from the bottom (aka newest)
                    messageCount = stateMessages?.length + parsedOlderMessages.length;
                    const removeNewestMessages = messageCount > maxMessageAmount;

                    if (removeNewestMessages) {
                        const deleteCount = messageCount - maxMessageAmount;
                        stateMessages.splice(0, deleteCount);
                        newestLoaded = false;
                    }

                    // Insert date divider over oldest loaded message
                    parsedOlderMessages.push(createDateDivider(previousMessage.created));

                    // Also we don't want a solitary date divider at the bottom if there's no message below it
                    if (stateMessages[0]?.dateDivider) {
                        stateMessages.splice(0, 1);
                    }
                    break;
                default:
                    break;
            }
        }
    });

    let messArr: Message[];
    let newObj: any;

    switch (reducingMode) {
        case 'initial':
            if (!parsedInitialMessages) {
                return;
            }

            return {
                ...state,
                ...{
                    [discussionId]: {
                        messages: parsedInitialMessages,
                        newestLoaded: data.newestLoaded,
                        oldestLoaded: data.oldestLoaded,
                    },
                },
            };
        case 'next':
            if (!parsedNewerMessages) {
                return;
            }

            messArr = [...parsedNewerMessages, ...stateMessages];
            newObj = {
                [discussionId]: {
                    messages: messArr,
                    newestLoaded: data.newestLoaded,
                    oldestLoaded,
                    scrollTop: state[discussionId].scrollTop,
                },
            };

            return { ...state, ...newObj };
        case 'previous':
            if (!parsedOlderMessages) {
                return;
            }

            const oldestLoadedMessage = stateMessages[stateMessages.length - 1];
            const newestParsedMessage = parsedOlderMessages[0];

            /* If the oldest message in state and the newest loaded message are from the same day,
               remove the topmost date divider from state */
            if (oldestLoadedMessage && newestParsedMessage) {
                const oldestMessageCreated = moment(oldestLoadedMessage.created).format().split('T')[0];
                const newestParsedMessageCreated = moment(parsedOlderMessages[0].created).format().split('T')[0];
                const messagesFromTheSameDay = newestParsedMessageCreated === oldestMessageCreated;

                if (oldestLoadedMessage.dateDivider && messagesFromTheSameDay) {
                    stateMessages.splice(stateMessages.length - 1, 1);
                }
            }

            messArr = [...stateMessages, ...parsedOlderMessages];
            newObj = {
                [discussionId]: {
                    messages: messArr,
                    oldestLoaded: data.oldestLoaded,
                    newestLoaded,
                    scrollTop: state[discussionId].scrollTop,
                },
            };

            return { ...state, ...newObj };
        default:
            break;
    }
};

const createDateDivider = (date: number): Message => {
    const dateDividerMessage = new Message();
    dateDividerMessage.dateDivider = true;
    dateDividerMessage.created = date;
    return dateDividerMessage;
};

const updateStateMessages = (state: any, data: any): any => {
    let newObj: V3DiscussionMessageResponseMap;

    for (const discussionId in data.messages) {
        if (!discussionId) {
            continue;
        }

        const messages = data.messages[discussionId];
        const previousStateMessages: Message[] = state[discussionId]?.messages;
        let stateMessages;
        let oldestLoadedMessageTimestamp = Infinity;
        let newestLoadedMessageTimestamp = 0;

        if (previousStateMessages) {
            stateMessages = [...previousStateMessages];

            if (previousStateMessages[previousStateMessages.length - 1]) {
                oldestLoadedMessageTimestamp = previousStateMessages[previousStateMessages.length - 1].created;
            }

            if (previousStateMessages[0]) {
                newestLoadedMessageTimestamp = previousStateMessages[0].created;
            }
        }

        messages.forEach(message => {
            if (message.created >= oldestLoadedMessageTimestamp && message.created <= newestLoadedMessageTimestamp) {
                const i = previousStateMessages.findIndex(({ _id }) => _id === message._id);
                delete message.isConsecutive;
                stateMessages[i] = { ...stateMessages[i], ...message };
            }
        });

        newObj = {
            [discussionId]: {
                messages: stateMessages,
                oldestLoaded: state[discussionId]?.oldestLoaded || false,
                newestLoaded: state[discussionId]?.newestLoaded || false,
            },
        };
    }

    return { ...state, ...newObj };
};

export const messageReducer = (state, action) => reducer(state, action);
