/** @format */

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Capacitor } from '@capacitor/core';

import { PersonalSettings } from '../../_models/personal-settings.model';
import { CoreService } from 'app/_services/core.service';
import { ImageViewerService, ViewerImage } from 'app/_services/image-viewer.service';
import { FeedService } from '../feed.service';
import { environment } from '@app/env';
import { SideNavService } from 'app/_services/side-nav.service';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { File } from 'app/_models';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';
import { FilesService } from 'app/_services/files.service';
import { FileViewComponent } from 'app/files-shared/file-view/file-view.component';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    selector: 'app-feed-post-comment-view',
    templateUrl: './feed-post-comment-view.component.html',
    styleUrls: ['./feed-post-comment-view.component.scss'],
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'feed', alias: 'feed' } }],
})
export class FeedPostCommentViewComponent implements OnInit, OnDestroy {
    @Input() comment;

    apiUrl = environment.wsUrl;
    editing = false;
    newCommentText = '';
    attachments: {
        supportedMedia: File[];
        other: File[];
    } = {
        supportedMedia: [],
        other: [],
    };
    user: BehaviorSubject<PersonalSettings>;

    isWeb = Capacitor.getPlatform() === 'web';

    private video: any = null;

    private onDestroy = new Subject<void>();

    constructor(
        public filesService: FilesService,
        public core: CoreService,
        private feedService: FeedService,
        private imageViewerService: ImageViewerService,
        private sideNavService: SideNavService,
        private dialogHelperService: DialogHelperService,
        private cdr: ChangeDetectorRef,
        private rpc: RPCService,
    ) {}

    ngOnInit() {
        this.user = this.core.user;
        this.video = document.createElement('video');

        this.core.permission.changed
            .pipe(
                takeUntil(this.onDestroy),
                filter(workspaceIds => workspaceIds.includes(this.comment.cid))
            )
            .subscribe(() => this.cdr.detectChanges());

        // Process attachments.
        const files = this.comment?.comment?.files;
        const fileMetas = this.comment?._metas?.files;

        if (!files || !fileMetas) {
            return;
        }

        files.forEach((fileId: string) => {
            const file = fileMetas[fileId];
            if (!file) {
                console.error('File without metadata in comment', this.comment);
                return;
            }
            if (this.isSupportedMedia(file.type)) {
                this.attachments.supportedMedia.push(file);
            } else {
                this.attachments.other.push(file);
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    onEditClick() {
        this.newCommentText = this.comment.comment.text || '';
        this.editing = true;
    }

    async saveEditedComment(): Promise<void> {
        try {
            const savedComment = await this.rpc.requestAsync('wall2.edit_comment', [this.comment.post_id, this.comment._id, this.newCommentText]);
            if (!savedComment?.comment?.text) {
                throw new Error('Comment is undefined');
            }

            this.comment.comment.text = savedComment.comment.text;
            this.editing = false;
        } catch (error) {
            console.error('Failed to edit comment', error);
            this.editing = false;
        }
    }

    async removeComment(): Promise<void> {
        const confirm = `Delete`;
        const content = `Are you sure you want to delete this comment?`;
        const title = `Delete comment?`;

        const confirmed = await this.dialogHelperService.showConfirmAsync(confirm, content, title);
        if (!confirmed) {
            return;
        }

        try {
            await this.rpc.requestAsync('wall2.remove_comment', [this.comment._id, this.comment.post_id]);
        } catch (error) {
            console.error('Failed to remove comment', error);
        }
    }

    viewImages(images: File[], index: number) {
        const preparedImages = images.map(image => {
            const isPicture = image.isPicture && image.type !== 'image/gif';
            const isVideo = image.isVideo;
            const preparedImage: ViewerImage = {
                url: isPicture ? `${environment.wsUrl}/image/hires/${image._id}` : `${this.apiUrl}/file/${image._id}`,
                original: isPicture ? `${environment.wsUrl}/image/original/${image._id}` : '',
                _id: image._id,
                type: image.type,
                isVideo,
                isPicture,
                name: image.name,
                downloadUrl: `${environment.wsUrl}/file/${image._id}?dl=1`,
            };

            return preparedImage;
        });

        this.imageViewerService.viewImages(preparedImages, index);
    }

    openUserSidenav(userId: string): void {
        this.sideNavService.create(UserDetailComponent, {
            userId,
        });
    }

    openFile(fileId: string) {
        if (!fileId) {
            console.warn('No file id to open the sidenav with!');
            return;
        }

        this.sideNavService.create(FileViewComponent, {
            fileId,
        });
    }

    getImageUrl(attachment: File) {
        const isGif = attachment.type === 'image/gif';
        return this.apiUrl + (isGif ? '/file/' : '/image/hires/') + attachment._id;
    }

    private isSupportedMedia(mimeType) {
        if (mimeType.split('/')[0] === 'image') {
            return true;
        } else if (this.video.canPlayType(mimeType)) {
            return true;
        }
        return false;
    }

    private showError(errorMsg: string) {
        const confirm = `Ok`;
        const content = errorMsg;
        const title = `Error`;

        this.dialogHelperService.showError(confirm, content, title).pipe(take(1)).subscribe();
    }
}
