/** @format */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nl2br' })
export class Nl2brPipe implements PipeTransform {
    transform(msg: string, is_xhtml?: boolean) {
        const breakTag = is_xhtml ? '<br />' : '<br>';
        const newMsg = `${msg}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
        return newMsg;
    }
}
