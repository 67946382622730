<!-- @format -->

<div class="main-body">
    <div class="inner-body">
        <p>Tags:</p>
        <ng-container *ngFor="let tag of mockTags | keyvalue">
            <span class="tag" (click)="searchTag(tag)">{{tag.value}}</span>
        </ng-container>
    </div>
</div>
