/** @format */

import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { CalendarOptions } from '@fullcalendar/core';
import moment from 'moment';

import { Event, EventUpdate } from '@app/models';

export enum AllowedCalendarViews {
    timeGridDay = 'timeGridDay',
    timeGridWeek = 'timeGridWeek',
    dayGridMonth = 'dayGridMonth',
    resourceTimelineMonth = 'resourceTimelineMonth',
}

export const globalCalendarOptions: CalendarOptions = {
    slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: false,
        hour12: false,
    },
    eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
        hour12: false,
    },
    views: {
        timeGrid: {
            dayHeaderFormat: {
                weekday: 'short',
                day: '2-digit',
            },
        },
        resourceTimelineMonth: {
            nowIndicator: true,
            slotLabelFormat: [{ weekday: 'short' }, { day: 'numeric' }],
        },
        resourceTimelineWeek: {
            dateAlignment: 'week',
            duration: { days: 7 },
            slotDuration: { days: 1 },
            slotLabelFormat: date => moment(date.date).format('ddd DD.MM'),
        },
        resourceTimelineYear: {
            nowIndicator: true,
            slotDuration: { days: 7 },
            slotLabelFormat: [{ month: 'short' }, { week: 'short' }],
            type: 'resourceTimeline',
        },
    },
    initialView: 'dayGridMonth',
    resourceAreaWidth: '10%',
    eventDisplay: 'block',
    firstDay: 1,
    weekNumbers: true,
    headerToolbar: false,
    schedulerLicenseKey: '0457674777-fcs-1643626360',
    filterResourcesWithEvents: true,
    slotMinWidth: 85,
    // "as any" due to some typing issue I did not resolve, which is missing resourceAreaWidth, schedulerLicenseKey and filterResourcesWithEvents keys when building the service worker.
} as any;

@Injectable({
    providedIn: 'root',
})
export class EventHelperService {
    constructor() {}

    // Convert incoming UTC timestamps to localized moment objects
    processIncomingDates(events: Event[]): Event[] {
        events.forEach((event: Event) => {
            if (event.allDay) {
                // ALL DAY EVENT
                let start: Moment = null;
                let end: Moment = null;

                start = moment.utc(event.start);

                // ONE DAY
                if (event.end === event.start) {
                    end = start.clone();
                } else {
                    // MULTIPLE DAYS
                    end = moment.utc(event.end);
                }

                const localEndAsString: string = moment.utc(end).add(1, 'days').format('YYYY-MM-DD');
                event.end = moment(`${localEndAsString}T00:00:00.000`).toISOString();

                const localStartAsString: string = moment.utc(start).format('YYYY-MM-DD');
                event.start = moment(`${localStartAsString}T00:00:00.000`).toISOString();
            } else {
                // NORMAL EVENT
                event.end = moment(event.end).toISOString();
                event.start = moment(event.start).toISOString();
            }
        });

        return events;
    }

    // Convert localized moment objects to UTC timestamps
    processOutgoingDates(events: Event[]): Event[] {
        events.forEach((event: Event) => {
            if (event.allDay) {
                // ALL DAY EVENT
                let start: Moment = null;
                let end: Moment = null;

                start = moment(event.start);

                // ONE DAY
                if (event.end === event.start) {
                    end = start.clone();
                } else {
                    // MULTIPLE DAYS
                    end = moment(event.end).subtract(1, 'days');
                }

                const endAsString: string = moment(end).format('YYYY-MM-DD');
                event.end = moment.utc(`${endAsString}T00:00:00.000`).valueOf();

                const startAsString: string = moment(start).format('YYYY-MM-DD');
                event.start = moment.utc(`${startAsString}T00:00:00.000`).valueOf();
            } else {
                // NORMAL EVENT
                event.end = moment(event.end).valueOf();
                event.start = moment(event.start).valueOf();
            }
        });

        return events;
    }

    cleanEvent(event: Event): EventUpdate {
        const eventUpdateData = new EventUpdate(
            event.allDay,
            event.attendees,
            event.calendar_id,
            moment.utc(event.end).valueOf(),
            event.location,
            event.notes,
            event.repeat_id,
            moment.utc(event.start).valueOf(),
            event.title,
            event._id
        );

        return eventUpdateData;
    }

    /* Sort attendees by 'attending' attribute.
       Those attending will be on top */
    sortUsers(prop: string) {
        return function (a: any, b: any) {
            const attendingA = a[prop];
            const attendingB = b[prop];
            if (attendingA > attendingB) {
                return -1;
            }
            if (attendingA < attendingB) {
                return 1;
            }

            return 0;
        };
    }

    getStartEndTime(date: any) {
        const oneDay = 86400;
        const startStr = date.startStr || date.dateStr;
        const endStr = date.endStr || date.dateStr;
        const monthView = date.view.type.includes('Month');
        const allDay = date.allDay;
        const userTimezoneOffset = new Date(date.date || date.startStr || date.start).getTimezoneOffset() / 60;
        const offsetHours = userTimezoneOffset < 0 ? userTimezoneOffset : -userTimezoneOffset;
        const startTime = new Date(startStr).getTime();
        let endTime = new Date(endStr).getTime();
        endTime = startStr === endStr ? endTime : endTime - oneDay;
        const startTimeDate = new Date(startTime).getUTCDate();
        const endTimeDate = new Date(endTime).getUTCDate();
        const startTimeHours = new Date(startTime).getUTCHours();
        const endTimeHours = new Date(endTime).getUTCHours();
        const startTimeMonth = new Date(startTime).getUTCMonth();
        const endTimeMonth = new Date(endTime).getUTCMonth();
        const startTimeYear = new Date(startTime).getUTCFullYear();
        const endTimeYear = new Date(endTime).getUTCFullYear();
        const startHours = (allDay && monthView) ? (monthView ? 12 : 0) : (allDay ? 0 : (startTimeHours - offsetHours));
        const endHours = (allDay && monthView) ? (monthView ? 12 : 24) : (allDay ? 24 : (endTimeHours - offsetHours + 1));
        const start = Date.UTC(startTimeYear, startTimeMonth, startTimeDate, (startHours + offsetHours));
        const end = Date.UTC(endTimeYear, endTimeMonth, endTimeDate, (endHours + offsetHours));
        return { start, end };
    }
}
