/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { CoreService, SessionState } from 'app/_services/core.service';
import { RPCService } from 'app/_services/rpc.service';
import { Company, PersonalSettings } from '@app/models';
import { Invitation } from 'app/_models/invitation.model';
import { Invite } from 'app/_models/invite.model';
import { PermissionService } from 'app/_services/permission.service';
import { stripUndefined } from 'app/_helpers/util';
import { TeamUserSelectorOptions } from 'app/_models/teamUserSelectorOptions.model';
import { DropdownSelectedItems } from 'app/_models/dropdown-selector.model';
import { RoleSettingsService } from 'app/settings/role-settings/role-settings.service';
import { WorkspaceRoleTypes } from '../../../../test/deps/hailer-api/src/api/v3/permissions/permission-models';
import { WorkspaceRoleSelection } from 'app/settings/workspace-permission-selector/workspace-permission-selector.component';

@Component({
    selector: 'app-invite-page',
    templateUrl: './invite-page.component.html',
    styleUrls: ['./invite-page.component.scss'],
    providers: [
        RoleSettingsService,
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'people/invite-page', alias: 'invitepage' },
        },
    ],
})
export class InvitePageComponent implements OnInit, OnDestroy {
    invite_key: string;
    invitation: any;
    page = 'invite';
    inviteForm = new FormGroup({
        role: new FormControl<WorkspaceRoleTypes | undefined>('user'),
        customRole: new FormControl<string | undefined>(undefined),
        teams: new FormControl<string[] | undefined>(undefined, { validators: Validators.required }),
        email: new FormControl<string | undefined>(undefined, { validators: [Validators.required, Validators.email] }),
        title: new FormControl<string | undefined>(undefined, { validators: Validators.maxLength(50) }),
        notes: new FormControl<string | undefined>(undefined),
    });
    state: BehaviorSubject<SessionState>;
    network: BehaviorSubject<Company>;
    invitations: Invitation[];
    user: BehaviorSubject<PersonalSettings>;
    userSelectorOptions = new BehaviorSubject<TeamUserSelectorOptions>(new TeamUserSelectorOptions());
    sendingResendEmail = new BehaviorSubject<boolean>(false);

    private onDestroy = new Subject<void>();

    constructor(
        private core: CoreService,
        private rpc: RPCService,
        private route: ActivatedRoute,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<InvitePageComponent>,
        public permissions: PermissionService,
        private translocoService: TranslocoService
    ) {}

    ngOnInit() {
        this.state = this.core.state;
        this.network = this.core.network;
        this.user = this.core.user;
        this.invitations = [];

        this.core.invitations.pipe(takeUntil(this.onDestroy)).subscribe({
            next: invitations => (this.invitations = invitations[this.network.value._id]),
        });

        this.setUserSelectorOptions();

        this.route.queryParams
            .pipe(
                filter(params => !!params.invite),
                takeUntil(this.onDestroy)
            )
            .subscribe({
                next: params => {
                    this.rpc.request('company.check_invite', [params.invite]).subscribe(invite => {
                        this.invitation = invite;
                    });

                    this.invite_key = params.invite;
                    this.page = 'review';
                },
            });
        this.translocoService.events$
            .pipe(
                filter(event => event.type === 'translationLoadSuccess' && event.payload.scope === 'people/invite-page'),
                take(1)
            )
            .subscribe(() => {
                this.setUserSelectorOptions();
            });
        this.setUserSelectorOptions();
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    async invite() {
        const invitation = stripUndefined({
            teams: this.inviteForm.value.role === 'guest' ? undefined : this.inviteForm.value.teams,
            email: this.inviteForm.value.email.toLowerCase(),
            title: this.inviteForm.value.title || undefined,
            notes: this.inviteForm.value.notes || undefined,
            role: this.inviteForm.value.customRole ? undefined : this.inviteForm.value.role || 'user',
            customRole: this.inviteForm.value.customRole || undefined,
        });

        try {
            await this.rpc.requestAsync('v2.network.invite.send', [invitation]);
            this.snackBar.open(
                this.translocoService.translate('invitepage.ts_snackbar.invitation_sent'),
                this.translocoService.translate('invitepage.ts_snackbar.close'),
                { duration: 2000 }
            );
            this.dialogRef.close();
        } catch (error) {
            this.dialogRef.close();
            if (error.msg === 'Managed user cannot be invited into network') {
                this.snackBar.open(
                    this.translocoService.translate('invitepage.ts_snackbar.managed_user_error'),
                    this.translocoService.translate('invitepage.ts_snackbar.close'),
                    { duration: 5000 }
                );
            }
            console.error(error);
        }
    }

    async removeInvitation(invite: Invite) {
        await this.rpc.requestAsync('v2.network.invite.remove', [invite.invite_key]);
    }

    parseUserSelector(results: DropdownSelectedItems) {
        const teamIds = results?.team?.map(team => team) || [];
        this.inviteForm.get('teams').setValue(teamIds);
    }

    selectInvitationRole(selection: WorkspaceRoleSelection): void {
        if (selection.roleType) {
            this.inviteForm.controls.role?.setValue(selection.roleType);
            this.inviteForm.controls.customRole?.setValue(undefined);
            return;
        }

        this.inviteForm.controls.customRole?.setValue(selection.customRoleId);
        this.inviteForm.controls.role?.setValue(undefined);
    }

    closeDialog() {
        this.dialogRef.close();
    }

    resendValidationEmail(): void {
        this.sendingResendEmail.next(true);

        this.rpc
            .request('v2.user.send_verification_email')
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: () => {
                    this.sendingResendEmail.next(false);
                    this.snackBar.open(
                        this.translocoService.translate('invitepage.ts_snackbar.email_sent'),
                        this.translocoService.translate('invitepage.ts_snackbar.close'),
                        { duration: 2000 }
                    );
                    this.closeDialog();
                },
                error: error => {
                    console.log('Error happened while sending out a validation email', error);
                    this.sendingResendEmail.next(false);
                    this.snackBar.open(
                        this.translocoService.translate('invitepage.ts_snackbar.error_happened'),
                        this.translocoService.translate('invitepage.ts_snackbar.close'),
                        { duration: 3000 }
                    );
                },
            });
    }

    private setUserSelectorOptions(): void {
        const isInviter = this.permissions.isNetworkInviter && !this.permissions.isNetworkAdmin;

        const currentOptions = this.userSelectorOptions.value;
        currentOptions.appearance = 'fill';
        currentOptions.multiple = true;
        currentOptions.placeholder = this.translocoService.translate('invitepage.select_teams');
        currentOptions.required = true;
        currentOptions.showTeams = isInviter ? this.user.value.id : 'all';
        this.userSelectorOptions.next(currentOptions);
    }
}
