<ng-container *ngIf="userIds | slice: 0:limit as sliced">
    <ng-container *ngFor="let userId of sliced; index as i; last as last">
        <app-user-link data-cy="user-link-list-item" [userId]="userId"></app-user-link>
        <ng-container style="font-weight: 600; color: lightgray" *ngIf="!last">{{ ((i === (sliced.length - 2)) && (sliced.length >= userIds.length)) ? ('shared.user-link-list.and' | transloco) : ", " }}</ng-container>
    </ng-container>
    <span *ngIf="sliced.length < userIds.length">
        {{'shared.user-link-list.and' | transloco}}<span class="more-users" [matMenuTriggerFor]="likeMenu">{{userIds.length - sliced.length}}{{'shared.user-link-list.more' | transloco}}</span>
    </span>
    <span *ngIf="userIds.length == 1">
        {{'shared.user-link-list.like_this' | transloco}}
    </span>
    <span *ngIf="userIds.length > 1">
        {{'shared.user-link-list.like_this' | transloco}}
    </span>

    <mat-menu #likeMenu="matMenu">
        <button mat-menu-item class="panel-item" *ngFor="let userId of userIds | slice: limit" (click)="openUserSidebar(userId)">
            <app-user-profile-picture [userId]="userId" size="32" style="margin-right: 8px"></app-user-profile-picture>
            <span>{{getDisplayName(userId)}}</span>
        </button>
    </mat-menu>
</ng-container>
