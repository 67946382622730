/** @format */

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { PollCreateOptions, PollDoc, PollVisibilityTypes } from '../../../../test/deps/hailer-api/shared/poll-types';
import { Subject, takeUntil } from 'rxjs';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { TranslateService } from 'app/_services/translate.service';
import { MatInput } from '@angular/material/input';
import { stripUndefined } from '../../../../test/deps/hailer-api/shared/util';

@Component({
    selector: 'app-poll-editor',
    templateUrl: './poll-editor.component.html',
    styleUrls: ['./poll-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'poll' }],
})
export class PollEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output() readonly pollChanged = new EventEmitter<PollCreateOptions>();
    @Output() readonly validityChanged = new EventEmitter<boolean>();
    /** Autofills the poll editor form with this poll data */
    @Input() poll?: PollDoc;

    @ViewChildren('pollOption') optionInputs: QueryList<ElementRef<MatInput>>;
    @ViewChild('pollName', { static: false }) pollName: ElementRef<MatInput>;

    pollFormGroup = new FormGroup({
        name: new FormControl('', { validators: [Validators.required] }),
        answerVisibility: new FormControl<PollVisibilityTypes>('none', { validators: [Validators.required] }),
        multipleAnswers: new FormControl<boolean>(false),
        lockedVotes: new FormControl<boolean>(false),
        options: new FormArray<FormGroup<{ name: FormControl<string | null>; _id: FormControl<string | undefined | null> }>>([]),
    });

    visibilityTypes: { value: PollVisibilityTypes; name: string }[] = [];

    private onDestroy = new Subject<void>();

    constructor(
        private transloco: TranslocoService,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.transloco.langChanges$.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.setVisibilityTypes(),
        });

        void this.setVisibilityTypes();
        this.pollFormGroup.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe({
            next: values => {
                this.validityChanged.emit(this.pollFormGroup.valid);
                if (!values.answerVisibility || !values.name || !values.options) {
                    return;
                }

                const options = values.options.map(option => stripUndefined({ _id: option._id || undefined, name: option.name || '' }));
                const output: PollCreateOptions = {
                    name: values.name,
                    answerVisibility: values.answerVisibility,
                    multipleAnswers: values.multipleAnswers || false,
                    lockedVotes: !values.lockedVotes || false,
                    options,
                };

                this.pollChanged.emit(output);
            },
        });

        this.checkValidity();
        this.initPollForm();
    }

    ngAfterViewInit(): void {
        this.pollName.nativeElement.focus();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    addOption(): void {
        this.pollFormGroup.controls.options.push(
            new FormGroup({
                _id: new FormControl<string | undefined>(undefined),
                name: new FormControl('', { validators: [Validators.required] }),
            })
        );
        this.cdr.detectChanges();
        this.checkValidity();
        this.optionInputs.last.nativeElement.focus();
    }

    removeOption(index: number): void {
        this.pollFormGroup.controls.options.removeAt(index);
        this.checkValidity();
    }

    get optionRemoveEnabled(): boolean {
        return Object.keys(this.pollFormGroup.value.options || {}).length > 1;
    }

    get disableAllFields(): boolean {
        return !!this.poll && !this.poll.draft && !this.poll.scheduled;
    }

    private async setVisibilityTypes(): Promise<void> {
        await this.translate.translationLoaded('poll');
        this.visibilityTypes = [
            { value: 'creator', name: await this.transloco.translate('poll.visibility.creator') },
            { value: 'none', name: await this.transloco.translate('poll.visibility.none') },
            { value: 'public', name: await this.transloco.translate('poll.visibility.public') },
        ];
        this.cdr.detectChanges();
    }

    private checkValidity(): void {
        this.validityChanged.emit(this.pollFormGroup.valid);
    }

    private initPollForm(): void {
        if (!this.poll) {
            this.pollFormGroup.controls.options.push(
                new FormGroup({
                    _id: new FormControl<string | undefined>(undefined),
                    name: new FormControl('', { validators: [Validators.required] }),
                })
            );
            this.pollFormGroup.controls.options.controls[0]?.markAllAsTouched();
            this.cdr.detectChanges();
            return;
        }

        this.disableAllFields ? this.pollFormGroup.disable() : this.pollFormGroup.enable();

        this.pollFormGroup.patchValue({
            answerVisibility: this.poll.answerVisibility,
            multipleAnswers: this.poll.multipleAnswers,
            lockedVotes: !this.poll.lockedVotes,
            name: this.poll.name,
        });

        for (const optionId in this.poll.options) {
            if (!optionId) {
                continue;
            }

            const option = this.poll.options[optionId];
            const nameForm = new FormControl(option?.name || '', { validators: [Validators.required] });

            if (this.disableAllFields) {
                nameForm.disable();
            }

            this.pollFormGroup.controls.options.push(
                new FormGroup({
                    _id: new FormControl<string | undefined>(optionId),
                    name: nameForm,
                })
            );
        }
    }
}
