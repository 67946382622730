/** @format */

import * as moment from 'moment';

import { Deserializable } from './deserializable.model';
import { EventRepeatModel } from './eventRepeat.model';
import { Calendar } from './calendar.model';

export class EventBase implements Deserializable {
    id: string;
    end: moment.Moment | number | string;
    start: moment.Moment | number | string;
    title: string;
    allDay: boolean;

    constructor(eventData: any) {
        if (eventData) {
            this.deserialize(eventData);
        }
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        this.id = input._id;
        return this;
    }
}

export class Event extends EventBase {
    attendees: {
        [key: string]: boolean;
    };
    calendar_id: string;
    location?: string;
    notes?: string;
    repeating?: boolean;
    repeat?: EventRepeatModel;
    uid?: string;
    calendar?: Calendar;
    cid?: string;
    discussion?: string;
    repeat_id?: string;
    type?: string;
    _id?: string;
    eventId?: string;
    color?: string;
    calendarName?: string;
    resourceId?: string;

    constructor(eventData: any) {
        super(eventData);
    }
}
