/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Subject, debounceTime, filter, take, takeUntil } from 'rxjs';

import { Phase } from '@app/models';
import { FunctionEditorDialogComponent } from 'app/activities/components/function-editor-dialog/function-editor-dialog.component';
import { V3ActivityViewContextService } from 'app/v3-activity/v3-activity-view-context.service';
import { DropdownItems, DropdownOptions, DropdownSelectedItems } from 'app/_models/dropdown-selector.model';
import { ActivityTemplate, ActivityTemplateField } from 'app/_models/v3-activity.model';
import { LicenseService } from 'app/_services/license.service';
import { TranslateService } from 'app/_services/translate.service';
import { PermissionService } from 'app/_services/permission.service';

@Component({
    selector: 'app-v3-activity-detail',
    templateUrl: './v3-activity-detail.component.html',
    styleUrls: ['./v3-activity-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'activity-sidenav', alias: 'activity-sidenav' },
        },
    ],
})
export class V3ActivityDetailComponent implements OnInit, OnDestroy {
    static redrawCount = 0;

    @ViewChild('nextPhaseMenuTrigger') nextPhaseMenuTrigger: MatMenuTrigger;

    fieldErrorMap: { [fieldId: string]: boolean } = {};

    nextPhaseTemplate: ActivityTemplate | undefined;
    functionFieldIds: string[];

    phaseSelectorOptions = new BehaviorSubject<DropdownOptions>(new DropdownOptions());

    phaseSelectorItems = new BehaviorSubject<DropdownItems>({});
    phaseSelectorSelectedItems = new BehaviorSubject<DropdownSelectedItems>({});

    nameField: ActivityTemplateField | null;
    ownerTeamField: {
        items: BehaviorSubject<DropdownItems>;
        options: BehaviorSubject<DropdownOptions>;
        selectedItems: BehaviorSubject<DropdownSelectedItems>;
        field: ActivityTemplateField;
    } | null;

    private onDestroy = new Subject<void>();

    private evaluateSubject = new Subject<void>();

    constructor(
        private zone: NgZone,
        public viewContext: V3ActivityViewContextService,
        public permission: PermissionService,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        private license: LicenseService,
        private transloco: TranslocoService,
        private translate: TranslateService
    ) {}

    redraw() {
        V3ActivityDetailComponent.redrawCount += 1;
        // Console.log('ActivityDetail view redrawed: ' + V3ActivityDetailComponent.redrawCount + ' times');
    }

    ngOnInit(): void {
        this.evaluateSubject.pipe(takeUntil(this.onDestroy), debounceTime(1000)).subscribe({
            next: () => this.evaluateActivity(),
        });

        this.viewContext.template
            .pipe(
                takeUntil(this.onDestroy),
                filter(template => !!template)
            )
            .subscribe({
                next: async () => {
                    this.nameField = await this.getNameField();
                    await this.getOwnerTeamField();
                    this.cdr.detectChanges();
                },
            });

        this.viewContext.loadingInfo
            .pipe(
                takeUntil(this.onDestroy),
                filter(loadingInfo => loadingInfo !== 'initial'),
                take(1)
            )
            .subscribe({
                next: async () => {
                    this.nameField = await this.getNameField();
                    await this.getOwnerTeamField();
                    if (this.viewContext.action === 'create') {
                        this.evaluateSubject.next();
                    }
                    this.cdr.detectChanges();
                },
            });

        this.viewContext.v3Permissions
            .pipe(
                takeUntil(this.onDestroy),
                filter(permissions => !!Object.values(permissions || {}).length)
            )
            .subscribe({
                next: () => this.cdr.detectChanges(),
            });

        this.viewContext.v3Permissions
            .pipe(
                takeUntil(this.onDestroy),
                filter(
                    permissions =>
                        !!Object.keys(permissions).length && (!this.viewContext.editing.value || this.viewContext.action !== 'view')
                )
            )
            .subscribe({
                next: () => {
                    this.definePhaseSelector();
                },
            });

        this.viewContext.loadingInfo
            .pipe(
                takeUntil(this.onDestroy),
                filter(loadingInfo => !loadingInfo && !!this.viewContext.activity.value && !this.viewContext.editing.value)
            )
            .subscribe({
                next: () => this.definePhaseSelector({ reset: true }),
            });

        this.viewContext.workflow
            .pipe(
                takeUntil(this.onDestroy),
                filter(workflow => !!workflow)
            )
            .subscribe({
                next: async () => {
                    this.functionFieldIds = Object.values(this.viewContext.workflow.value?.fields || {})
                        .filter(field => !!field?.functionEnabled)
                        .map(({ _id }) => _id)
                        .concat(this.viewContext.workflow.value?.nameFunctionEnabled ? ['data'] : []);

                    this.cdr.detectChanges();

                    if (this.viewContext.nextPhaseId.value) {
                        await this.getNextPhaseTemplate(this.viewContext.nextPhaseId.value);
                    }

                    this.definePhaseSelector();
                },
            });

        this.viewContext.editing
            .pipe(
                takeUntil(this.onDestroy),
                filter(editing => !editing)
            )
            .subscribe({
                next: async () => {
                    if (this.viewContext.nextPhaseId.value) {
                        this.clearNextPhase();
                    }

                    await this.getOwnerTeamField();
                },
            });

        this.phaseSelectorSelectedItems.pipe(takeUntil(this.onDestroy)).subscribe({
            next: async ({ other }) => {
                const selectedPhaseId = other?.[0];
                await this.getNextPhaseTemplate(selectedPhaseId);
            },
        });

        this.viewContext.unknownUsers.pipe(takeUntil(this.onDestroy), debounceTime(200)).subscribe({
            next: () => this.cdr.detectChanges(),
        });

        this.viewContext.setNextPhase
            .pipe(
                takeUntil(this.onDestroy),
                filter(phaseId => !!phaseId && phaseId !== this.viewContext.nextPhaseId.value)
            )
            .subscribe({
                next: phaseId => this.getNextPhaseTemplate(phaseId),
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    ownerTeamChange(teamId: string): void {
        this.onErrors('ownerTeam', !teamId);
        const teamNotChanged = this.viewContext.activity.value?.team_account?.team === teamId;
        const teamNotSetInCreateMode = this.inCreateMode && !teamId;
        const enablePreselectedTeam = this.viewContext.workflow.value?.enablePreselectedTeam;

        if (teamNotChanged || teamNotSetInCreateMode || enablePreselectedTeam) {
            delete this.viewContext.toSave.options.teamId;
            return;
        }

        this.viewContext.toSave.options.teamId = teamId;
        const workflowId = this.viewContext.workflow.value?._id;
        if (!workflowId) {
            return;
        }

        localStorage.setItem(`ownerTeam:${workflowId}`, teamId);
    }

    get hasPhaseSelectorItems(): boolean {
        return !!Object.values(this.phaseSelectorItems.value || {}).length;
    }

    fieldValueChange(fieldId: string, newValue: any) {
        if (!this.viewContext.editing.value) {
            return;
        }
        let currentErrors = this.viewContext.errorsInSidenav.value;
        currentErrors.detailsChanging = true;
        this.viewContext.errorsInSidenav.next(currentErrors);
        let valueChanged = false;

        switch (fieldId) {
            case 'nameField':
                this.nameFieldChange(newValue);
                break;
            case 'ownerTeam':
                this.ownerTeamChange(newValue);
                break;
            default:
                const activityOrOptions = this.viewContext.action !== 'editMultiple' ? 'activity' : 'options';
                valueChanged = this.fieldValueChanged(fieldId, newValue);

                if (!valueChanged || (this.inCreateMode && !newValue && newValue !== 0)) {
                    // Removes the field from to be saved if no value has been changed
                    const toBeSavedField = this.viewContext.toSave[activityOrOptions].fields?.[fieldId];

                    if (toBeSavedField || toBeSavedField === null || toBeSavedField === 0) {
                        delete this.viewContext.toSave?.[activityOrOptions]?.fields?.[fieldId];
                    }

                    if (!Object.keys(this.viewContext.toSave[activityOrOptions].fields || {}).length) {
                        delete this.viewContext.toSave[activityOrOptions].fields;
                    }

                    break;
                }

                const options = this.viewContext.toSave[activityOrOptions];
                if (!options.fields) {
                    options.fields = {};
                }

                options.fields[fieldId] = newValue;
                break;
        }

        if ((valueChanged || fieldId === 'nameField') && this.realTimeEvaluationNeeded(fieldId, newValue)) {
            this.evaluateSubject.next();
        }

        currentErrors = this.viewContext.errorsInSidenav.value;
        currentErrors.detailsChanging = false;
        this.viewContext.errorsInSidenav.next(currentErrors);
    }

    /**
     * Checks if changes to field necesitate a real time evaluation
     *
     * @param fieldId Changed field id
     * @param newValue New value of field
     * @returns True if evaluation is needed, false if not
     */
    realTimeEvaluationNeeded(fieldId: string, newValue: any): boolean {
        const process = this.viewContext.workflow.value;
        let continueWithEvaluation = false;
        let skipIfNotDependency = false;

        if (!process) {
            return false;
        }

        /* If changed field is editable function field and value was not reset, no need to real-time evaluate
           Unless it is also a dependency */
        if (process.fields[fieldId]?.functionEnabled && process.fields[fieldId]?.editable && newValue != null) {
            skipIfNotDependency = true;
        }

        // Check if title is a function field that was reset
        if (fieldId === 'nameField' && process.nameFunctionEnabled && newValue == null) {
            return true;
        }

        // Check if edited field is an editable function field that was reset
        if (process.fields[fieldId]?.functionEnabled && process.fields[fieldId]?.editable && newValue == null) {
            return true;
        }

        // Check if field is included in title function variables
        if (process?.nameFunctionEnabled && !continueWithEvaluation) {
            for (const depName of Object.keys(process.nameFunctionVariables || {})) {
                if (process.nameFunctionVariables?.[depName]?.data?.includes(fieldId)) {
                    continueWithEvaluation = true;
                    break;
                }
            }
        }

        let fieldIds = JSON.parse(JSON.stringify(this.viewContext.phaseId ? process.phases[this.viewContext.phaseId]?.fields : []));
        const nextPhaseId = this.viewContext.nextPhaseId.value;
        const originalActivity = this.viewContext.activity.value;
        const editedActivity = this.viewContext.toSave.activity;
        if (nextPhaseId) {
            fieldIds = Array.from(new Set(...fieldIds, ...(process.phases[nextPhaseId]?.fields || [])));
        }
        // Loop thru all fields in current phase of process
        for (const functionFieldId of fieldIds) {
            if (continueWithEvaluation) {
                break;
            }
            if (!process?.fields[functionFieldId]?.functionEnabled) {
                continue;
            }
            const editable = process?.fields[functionFieldId]?.editable;
            const hasValue = editedActivity?.fields ? editedActivity?.fields[functionFieldId] != null : false;
            const originalHasValue = originalActivity?.fields[functionFieldId]?.value != null;
            const fieldEdited = editedActivity?.fields ? Object.keys(editedActivity.fields).includes(functionFieldId) : false;
            // If function field is editable, 1) has been edited 2) not edited but has original value, we don't need to RTE
            if (editable && (hasValue || (originalHasValue && !fieldEdited))) {
                continue;
            }
            const field = process?.fields[functionFieldId];
            const variables = field?.functionVariables;
            // If field in process has functionVariables, check if those include current changed field
            if (variables && !continueWithEvaluation) {
                for (const depName of Object.keys(variables || {})) {
                    if (variables[depName]?.data?.includes(fieldId)) {
                        continueWithEvaluation = true;
                        break;
                    }
                }
            }
        }

        return continueWithEvaluation && !skipIfNotDependency;
    }

    nameFieldChange(value: string) {
        const currentErrors = this.viewContext.errorsInSidenav.value;

        if (currentErrors.duplicate) {
            currentErrors.duplicate = false;
            this.viewContext.errorsInSidenav.next(currentErrors);
        }

        this.onErrors('nameField', !(value || this.viewContext.workflow.value?.nameEditable));

        this.viewContext.toSave.activity.name = value;
    }

    onErrors(fieldId: string, errorState: boolean) {
        this.fieldErrorMap[fieldId] = errorState;
        const currentErrors = this.viewContext.errorsInSidenav.value;
        currentErrors.invalidDetails = Object.values(this.fieldErrorMap)?.includes(true);
        this.viewContext.errorsInSidenav.next(currentErrors);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    trackById(index: number, field: ActivityTemplateField) {
        return field?.id;
    }

    getActivityFieldValue(fieldId: string): any {
        const fieldValue = this.viewContext.activity.value?.fields?.[fieldId]?.value;

        if (fieldValue === 0 || fieldValue === false) {
            return fieldValue;
        }

        return this.viewContext.activity.value?.fields?.[fieldId]?.value || undefined;
    }

    showStaticError(field: ActivityTemplateField) {
        return field?.functionEnabled && this.fieldErrorMap[field?.id];
    }

    get phase(): Phase | undefined {
        if (!this.viewContext.phaseId) {
            return;
        }

        return this.viewContext.workflow.value?.phases?.[this.viewContext.phaseId];
    }

    get template(): ActivityTemplate | undefined {
        return this.isDataset && this.viewContext.nextPhaseId.value ? this.nextPhaseTemplate : this.viewContext.template.value || undefined;
    }

    get showMoveActivityButton(): boolean {
        const phase = this.phase;
        if (!phase) {
            return false;
        }

        const isEndpoint = phase.isEndpoint;
        const hasNextPhases = !!phase.possibleNextPhase.length;
        const canBeEdited = this.viewContext.canBeEdited && !this.viewContext.isWorkspaceGuest;

        return canBeEdited && !isEndpoint && hasNextPhases;
    }

    async getNextPhaseTemplate(phaseId: string | undefined) {
        if (this.viewContext.loadingInfo.value) {
            return;
        }

        if (!phaseId || phaseId === this.viewContext.phaseId) {
            return void this.clearNextPhase();
        }

        this.nextPhaseTemplate = undefined;
        this.viewContext.nextPhaseId.next(phaseId);

        if (!this.viewContext.nextPhaseId.value) {
            console.warn('Next phase id not defined!');
            return;
        }

        this.viewContext.toSave.options.phaseId = this.viewContext.nextPhaseId.value;

        try {
            const workflowId = this.viewContext.workflow.value?._id;
            if (!workflowId) {
                throw new Error('No workflowId!');
            }

            const template = await this.viewContext.getTemplate(workflowId, this.viewContext.nextPhaseId.value);

            if (!template) {
                throw new Error('No next phase template!');
            }

            if (!this.isDataset) {
                // Filter already existing fields
                const existingFields = this.viewContext.template.value?.fields || [];
                template.fields = template.fields.filter(
                    nextPhaseField => !existingFields.find(existingField => nextPhaseField.id === existingField.id)
                );
            }

            this.nextPhaseTemplate = template;
            this.viewContext.setSubheaderFieldGroups(this.nextPhaseTemplate.fields);
        } catch (error) {
            console.error('Failed to find template for next phase');
        }

        this.viewContext.editing.next(true);
        this.cdr.detectChanges();
    }

    clearNextPhase() {
        this.nextPhaseTemplate = undefined;
        this.cdr.detectChanges();

        if (this.isDataset) {
            this.viewContext.toSave.options.phaseId = this.viewContext.phaseId;
        } else {
            delete this.viewContext.toSave.options.phaseId;
        }

        this.definePhaseSelector({ reset: !!this.viewContext.nextPhaseId.value });
        this.viewContext.nextPhaseId.next(null);
        this.nextPhaseMenuTrigger?.closeMenu();
    }

    get isDataset(): boolean {
        return this.viewContext.workflow.value?.enableUnlinkedMode || false;
    }

    get allowMovingActivity(): boolean {
        const workflow = this.viewContext.workflow.value;
        if (!workflow) {
            return false;
        }

        if (!this.viewContext.canBeEdited) {
            return false;
        }

        const dataset = workflow.enableUnlinkedMode;
        const inCreateMode = this.viewContext.action === 'create';

        if (dataset) {
            return inCreateMode;
        }

        const moreThanOnePhases = this.possibleNextPhases?.length >= 1;
        return moreThanOnePhases && !inCreateMode;
    }

    get showPhaseName(): boolean {
        const workflow = this.viewContext.workflow.value;
        if (!workflow) {
            return false;
        }

        const dataset = workflow.enableUnlinkedMode;

        if (!dataset) {
            return true;
        }

        return workflow.phasesOrder.length > 1;
    }

    get possibleNextPhases(): string[] {
        const workflow = this.viewContext.workflow.value;
        if (!this.viewContext.phaseId) {
            return [];
        }

        const possibleNextPhases = workflow?.phases?.[this.viewContext.phaseId]?.possibleNextPhase || [];
        return possibleNextPhases;
    }

    get inCreateMode(): boolean {
        return this.viewContext.action === 'create';
    }

    showField(field: ActivityTemplateField): boolean {
        if (field?.functionEnabled && !field?.editable && this.viewContext.action === 'editMultiple') {
            return false;
        }

        if (this.viewContext.isWorkspaceGuest) {
            // We don't want to show linked from fields to guest user
            return field?.subtype !== 'linkedfrom';
        }

        return true;
    }

    isFieldCollapsed(field: ActivityTemplateField): boolean {
        const collapsedSubheaderIds = this.viewContext.collapsedSubheaderIds.value;
        const subheaderFieldGroups = this.viewContext.subheaderFieldGroups.value;

        if (!collapsedSubheaderIds?.length) {
            // Field is not collapsed if there are no collapsed subheaders
            return false;
        }

        const inViewMode = this.viewContext.action === 'view';
        const editingMode = this.viewContext.editing.value;

        if ((!inViewMode || editingMode) && field?.required) {
            // Do not collapse required fields if in editMultiple-, create- or editing mode.
            return false;
        }

        let collapsed = false;
        for (const collapsedHeaderId of collapsedSubheaderIds) {
            if (collapsed) {
                // For loop can be ended if collapsed field was found
                break;
            }

            collapsed = subheaderFieldGroups[collapsedHeaderId]?.includes(field?.id) || false;
        }

        return collapsed;
    }

    openFunctionFieldEditor(fieldId: string) {
        const processId = this.viewContext.workflow.value?._id;

        if (!this.canEditFunction || this.viewContext.overviewPeek) {
            return;
        }

        const activityId = this.viewContext.activity.value?._id;

        if (!fieldId || !processId) {
            console.error('Cannot open function field editor!');
            return;
        }

        if (this.viewContext.action !== 'create' && !activityId) {
            console.error('Cannot open function field editor! (activity not found)');
            return;
        }

        fieldId = fieldId === 'nameField' ? 'name' : fieldId;

        this.zone.run(() => {
            this.dialog.open(FunctionEditorDialogComponent, {
                width: '100vw',
                height: '95vh',
                data: { processId, fieldId, activityId },
            });
        });
    }

    disableNextPhaseOption(phaseId: string): boolean {
        const workflow = this.viewContext.workflow.value;
        if (!workflow) {
            return true;
        }

        if (this.isDataset) {
            const canEditPhase = this.viewContext.canPhaseBeEdited(workflow._id, phaseId);
            return !canEditPhase;
        }

        const canAccessPhase = this.viewContext.canAccessPhase(workflow?._id, phaseId);
        const cannotBeMovedTo = !this.possibleNextPhases.includes(phaseId) && phaseId !== this.viewContext.phaseId;
        return !canAccessPhase || cannotBeMovedTo;
    }

    editField(field: ActivityTemplateField): boolean {
        const editing = this.viewContext.editing.value;

        if (field?.functionEnabled && !field?.editable) {
            return false;
        }

        if (field?.id === 'nameField') {
            const prefixedName = this.viewContext.workflow.value?.enablePredefinedName;
            const functionName = this.viewContext.workflow.value?.nameFunctionEnabled;
            return !prefixedName && !(functionName && !field?.editable) && editing;
        }

        if (!field?.subtype) {
            return editing;
        }

        const isStatic = this.viewContext.isStaticField(field?.subtype);
        return editing && !isStatic;
    }

    async definePhaseSelector(options?: { reset?: boolean }) {
        if (!Object.keys(this.viewContext.v3Permissions.value).length) {
            return;
        }

        await this.translate.translationLoaded('activity-sidenav');
        const currentOptions = this.phaseSelectorOptions.value;
        currentOptions.appearance = 'standard';
        currentOptions.closeOnSelection = true;
        currentOptions.hideClearButton = true;
        currentOptions.multiple = false;
        currentOptions.openAutomatically = false;
        currentOptions.placeholder = this.transloco.translate('activity-sidenav.detail.phase_selector_placeholder');
        currentOptions.required = true;
        currentOptions.sortAlphabetically = false;
        currentOptions.customColors.selectedItems = '--text-high';

        this.phaseSelectorOptions.next(currentOptions);

        const workflow = this.viewContext.workflow.value;
        const items: DropdownItems = {};
        if (!workflow) {
            return;
        }

        for (const phaseId of workflow.phasesOrder) {
            const phase = workflow.phases[phaseId];
            if (!phase) {
                continue;
            }

            items[phaseId] = {
                _id: phaseId,
                type: 'other',
                title: phase.name,
                disabled: this.disableNextPhaseOption(phaseId),
            };
        }

        if (this.viewContext.phaseId && (options?.reset || !this.hasPhaseSelectorItems)) {
            this.phaseSelectorSelectedItems.next({ other: [this.viewContext.phaseId] });
        }

        this.phaseSelectorItems.next(items);
    }

    get canEditFunction(): boolean {
        const hasPermission = this.viewContext.isWorkflowAdmin || this.viewContext.isWorkspaceAdmin;
        return !this.viewContext.overviewPeek && hasPermission;
    }

    async evaluateActivity() {
        if (!['view', 'create'].includes(this.viewContext.action) || !this.functionFieldIds?.length) {
            return;
        }

        const activity = this.viewContext.toSave.activity;
        const options = this.viewContext.toSave.options;
        const workflow = this.viewContext.workflow.value;
        const actualActivity = this.viewContext.activity.value;
        const phaseId = this.viewContext.phaseId;
        if (!workflow || !phaseId) {
            return;
        }

        try {
            const evaluatedFields = await this.viewContext.evaluate(activity, options, workflow._id);

            const overrides = this.functionFieldIds.reduce<{ nameField?: string }>((acc, fieldId) => {
                const overwritten = activity.fields && activity.fields[fieldId] === null;
                const currentPhaseFields = workflow.phases[phaseId]?.fields || [];
                const nextPhaseId = this.viewContext.nextPhaseId.value;
                const nextPhaseFields = nextPhaseId ? workflow.phases[nextPhaseId]?.fields || [] : [];
                const currentPhaseHasField = currentPhaseFields.includes(fieldId);
                const nextPhaseHasField = nextPhaseId && nextPhaseFields.includes(fieldId);
                if (
                    (workflow?.fields?.[fieldId]?.editable && actualActivity?.fields[fieldId]?.value && !overwritten) ||
                    workflow?.phases[phaseId]?.isEndpoint ||
                    // Remove function field id from evalution if current phase or phase activity is being moved to doesn't include it
                    (!currentPhaseHasField && !nextPhaseHasField)
                ) {
                    delete acc[fieldId];
                    return acc;
                }
                acc[fieldId] = evaluatedFields ? evaluatedFields[fieldId] : null;
                const isRequired = workflow?.fields[fieldId]?.required;
                this.onErrors(fieldId, !!isRequired && evaluatedFields[fieldId] == null);
                return acc;
            }, {});

            if (evaluatedFields?.data) {
                // eslint-disable-next-line dot-notation
                overrides.nameField = evaluatedFields?.data;
            }

            this.viewContext.fieldOverrides.next(overrides);
            if (evaluatedFields?.data) {
                const viewOverrides = this.viewContext.fieldOverrides.value;
                this.onErrors('nameField', !this.viewContext.toSave.activity.name && !viewOverrides.nameField);
            }
            this.cdr.detectChanges();
        } catch (error) {
            console.error('Failed to evaluate an activity!', error);
            const currentErrors = this.viewContext.errorsInSidenav.value;
            currentErrors.duplicate = error.details?.[0]?.db;
            this.viewContext.errorsInSidenav.next(currentErrors);
        }
    }

    nextFieldIsSubheader(fieldId: string): boolean {
        const fields =
            (this.viewContext.nextPhaseId.value ? this.nextPhaseTemplate?.fields : this.viewContext.template.value?.fields) || [];

        const index = fields.findIndex(({ id }) => fieldId === id);
        return fields[index + 1]?.subtype === 'subheader';
    }

    /** Compares the new field value against a field value currently in activity
     *  and detects if there are any changes made
     **/
    private fieldValueChanged(fieldId: string, newValue: any): boolean {
        const activityFieldValue = this.getActivityFieldValue(fieldId);

        return this.viewContext.valueChanged(activityFieldValue, newValue, fieldId);
    }

    private async getNameField(): Promise<ActivityTemplateField | null> {
        const nameFieldTemplate = this.viewContext.template.value?.name;
        const workflow = this.viewContext.workflow.value;
        let showNameField = false;

        switch (this.viewContext.action) {
            case 'editMultiple':
                showNameField = false;
                break;
            case 'create':
                showNameField = !workflow?.enablePredefinedName;
                break;
            default:
                showNameField = !!nameFieldTemplate;
                break;
        }

        await this.translate.translationLoaded('activity-sidenav');
        return showNameField
            ? {
                  id: 'nameField',
                  label: nameFieldTemplate?.label || this.transloco.translate('activity-sidenav.detail.name-field.label'),
                  type: nameFieldTemplate?.type,
                  required: true,
                  placeholder: nameFieldTemplate?.placeholder || this.transloco.translate('activity-sidenav.detail.name-field.placeholder'),
                  subtype: 'text',
                  functionEnabled: nameFieldTemplate?.functionEnabled,
                  editable: workflow?.nameEditable,
              }
            : null;
    }

    private async getOwnerTeamField(): Promise<void> {
        if (!this.inCreateMode || this.viewContext.workflow.value?.enablePreselectedTeam) {
            this.ownerTeamField = null;
            return;
        }

        const ownerTeamTemplate = this.viewContext.template.value?.team;
        await this.translate.translationLoaded('activity-sidenav');

        if ((ownerTeamTemplate?.selectableTeams?.length || 0) <= 1) {
            const selectableTeam = ownerTeamTemplate?.selectableTeams?.[0];
            this.ownerTeamField = null;

            if (!selectableTeam) {
                console.warn('User is not part of any valid owner team');
                return;
            }

            this.viewContext.toSave.options.teamId = selectableTeam._id;
            return;
        }

        const ddItems: DropdownItems = {};
        for (const teamItem of ownerTeamTemplate?.selectableTeams || []) {
            ddItems[teamItem._id] = {
                _id: teamItem._id,
                type: 'team',
                title: teamItem.name,
                meta: {
                    memberCount: teamItem.memberCount,
                },
            };
        }

        const dropdownOptions = new DropdownOptions();
        dropdownOptions.appearance = 'fill';
        dropdownOptions.multiple = false;
        dropdownOptions.selectLastItem = true;
        dropdownOptions.required = true;
        dropdownOptions.hideClearButton = true;
        dropdownOptions.closeOnSelection = true;

        const field: ActivityTemplateField = {
            id: 'ownerTeam',
            label: ownerTeamTemplate?.label || this.transloco.translate('activity-sidenav.settings.field.owner_team.label'),
            type: 'string',
            required: true,
            placeholder: this.transloco.translate('activity-sidenav.settings.field.owner_team.placeholder'),
            subtype: 'teams',
        };

        if (this.ownerTeamField) {
            this.ownerTeamField.items.next(ddItems);
            this.ownerTeamField.field = field;
            this.cdr.detectChanges();
            return;
        }

        const localStorageTeamId = localStorage.getItem(`ownerTeam:${this.viewContext.workflow.value?._id}`);
        const predefinedTeamId = localStorageTeamId && ddItems[localStorageTeamId] ? localStorageTeamId : Object.keys(ddItems)[0];

        this.ownerTeamField = {
            items: new BehaviorSubject(ddItems),
            options: new BehaviorSubject(dropdownOptions),
            selectedItems: new BehaviorSubject(predefinedTeamId ? { team: [predefinedTeamId] } : {}),
            field,
        };

        this.cdr.detectChanges();
        this.ownerTeamField.selectedItems.pipe(takeUntil(this.onDestroy)).subscribe({
            next: ({ team }) => {
                const teamId = team?.[0];
                if (!teamId) {
                    console.warn('Selected owner team id not defined');
                    return;
                }

                this.ownerTeamChange(teamId);
            },
        });
    }
}
