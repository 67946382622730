const Tooltip = ({ text, children, position = 'left' }) => {
    const getPositionStyle = () => {
        switch (position) {
            case 'right':
                return { top: '100%', right: '0%', marginRight: '2px' };
            case 'top':
                return { top: '-50%', left: '50%', transform: 'translate(-50%, -50%)' };
            case 'bottom':
                return { top: '100%', left: '50%', transform: 'translateX(-50%)' };
            case 'right-center':
                return { top: '200%', left: 'calc(100% - 15px)', transform: 'translateY(-100%)'  };
            default:
                return { top: '100%', left: '0%', marginLeft: '-2px' };
        }
    };

    return (
        <div className="insight-tooltip">
            {children}
            <span className="insight-tooltip-text" style={getPositionStyle()}>
                {text}
            </span>
        </div>
    );
};

export default Tooltip;