<!-- @format -->

<app-feed-post-editor
    *ngIf="editingPost && !(feedPost.type === 'activity.created' || feedPost.type === 'activity.updated')"
    [post]="feedPost"></app-feed-post-editor>
<mat-card *ngIf="!editingPost || feedPost.type === 'activity.created' || feedPost.type === 'activity.updated'" class="feed-card mat-elevation-z0">
    <div
        *ngIf="!editingPost || feedPost.type === 'activity.created' || feedPost.type === 'activity.updated'"
        [class.non-interactable]="editingPost && (feedPost.type === 'activity.created' || feedPost.type === 'activity.updated')">
        <mat-card-header data-cy="feed-post-header" [style.margin-bottom.px]="isCollapsed ? 0 : 8">
            <app-user-image-link
                (click)="openUserSidenav(feedPost.uid)"
                [userId]="feedPost.uid"
                [size]="48"
                class="feed-post__avatar"></app-user-image-link>
            <div class="feed-post__header-text">
                <h2 data-cy="feed-post-title" class="feed-post__title">
                    <ng-container [ngSwitch]="feedPost.type">
                        <div data-cy="feed-post-subject" *ngSwitchCase="'activity.created'">
                            <ng-container *ngIf="feedPostActivity && feedPostProcess; else activityNotInPost">
                                {{ announcementInitMessage || feedPostActivity.name }}
                            </ng-container>
                            <ng-template #activityNotInPost>
                                {{ 'feed.feed_post.activity_created' | transloco }}
                            </ng-template>
                        </div>
                        <div data-cy="feed-post-subject" *ngSwitchCase="'activity.updated'">
                            <ng-container *ngIf="feedPostProcess && feedPostActivity; else activityUpdated">
                                <ng-container *ngIf="feedPostProcess?.phases && feedPostActivity; else activityUpdated">
                                    {{feedPostProcessPhase(feedPostProcess,
                                    feedPostActivity)?.possibleNextPhaseSettings?.[feedPostActivity.currentPhase]?.text || (
                                    'feed.feed_post.activity_updated!' | transloco)}}
                                </ng-container>
                            </ng-container>
                            <ng-template #activityUpdated>
                                {{ 'feed.feed_post.activity_updated' | transloco }}
                            </ng-template>
                        </div>
                        <div data-cy="feed-post-subject" *ngSwitchDefault>
                            {{ feedPost.initMessage.subject }}
                        </div>
                    </ng-container>
                </h2>
                <div class="workspace-tags-wrapper">
                    <app-user-link *ngIf="feedPost.type === 'user'" [userId]="feedPost.uid"></app-user-link>
                    <span *ngIf="workspacesLength > 1" class="workspace-tags one-line-ellipsis"
                        >{{ postWorkspaceName || ('feed.feed_post.unknown_workspace' | transloco) }}
                    </span>
                    <span *ngIf="workspacesLength > 1 && feedPostProcess">·</span>
                    <span *ngIf="feedPostProcess" class="workspace-tags one-line-ellipsis">{{ feedPostProcess.name }}</span>
                </div>
                <h3 class="feed-post__subtitle">
                    <ng-container *ngIf="feedPost.scheduled">
                        <span class="scheduled-tag">{{ 'feed.feed_post.scheduled-tag' | transloco }}</span>
                    </ng-container>
                    <span>{{ feedPostTimestamp }}</span>
                    <span *ngIf="feedPost.edited && !feedPost.scheduled && !feedPost.draft">
                        <mat-icon svgIcon="hailer-edit" class="edit-icon"></mat-icon>
                        {{ 'feed.feed_post.edited' | transloco }}
                        ({{ feedPost.edited | todayDate | async }})
                    </span>
                    <button
                    *ngIf="activityPostUsers.length > 0"
                    class="no-button restricted-btn"
                    data-cy="feed-post-restricted-button"
                    [matMenuTriggerFor]="participantsMenu"
                    tippy="{{ 'feed.feed_post.restricted' | transloco }}"
                    [attr.aria-label]="'feed.feed_post.restricted' | transloco">
                    <mat-icon svgIcon="hailer-lock"></mat-icon></button>
                </h3>
            </div>
            <div class="button-row">
                <img crossorigin class="pinned-post-icon" *ngIf="feedPost.pinned" [src]="'assets/img/hailer-pin.svg'" alt="Pin post" />
                <app-button
                    *ngIf="isHidden"
                    type="icon"
                    color="tertiary-default-background"
                    size="medium"
                    hailerIconName="hailer-chevron"
                    [matIconClass]="isCollapsed ? 'hailer-chevron' : 'hailer-chevron up'"
                    (clickEvent)="toggleMinimize()"
                    class="collapse-btn"
                    [class.show-btn-on-hover]="!isCollapsed"
                    [ariaLabel]="
                        isCollapsed ? ('feed.feed_post.aria.expand' | transloco) : ('feed.feed_post.aria.collapse' | transloco)
                    "></app-button>
                <ng-container>
                    <app-feed-post-options-button
                        data-cy="feed-post-options-button"
                        *ngIf="!isDialog"
                        (openEdit)="onEditClick()"
                        (postPinned)="pinPost()"
                        [feedPost]="feedPost">
                    </app-feed-post-options-button>
                </ng-container>
            </div>
            <mat-menu #participantsMenu="matMenu" [overlapTrigger]="false">
                <ng-template matMenuContent>
                    <app-user-menu [userIds]="people" [recipients]="feedPost.recipients" sortBy="firstname"></app-user-menu>
                </ng-template>
            </mat-menu>
        </mat-card-header>
        <mat-divider *ngIf="!isCollapsed"></mat-divider>
        <app-activity-data-render
            *ngIf="feedPost.type === 'activity.created' || feedPost.type === 'activity.updated'"
            [activity]="feedPostActivity"
            [process]="feedPostProcess"
            [isAnnouncement]="true"
            (click)="dataRenderClick()"
            style="cursor: pointer"></app-activity-data-render>
    </div>

    <mat-card-content class="feed-post__content" *ngIf="!editingPost && !isCollapsed">
        <div
            data-cy="feed-post-text"
            class="feed-post__text"
            *ngIf="feedPost.initMessage?.text"
            [innerHTML]="feedPost.initMessage!.text | markdown : feedPost.linkedData | async"></div>
        <div *ngIf="attachments.supportedMedia.length" class="attachments-media feed-post__image-container">
            <!-- Image gallery variations -->

            <table cellspacing="0px">
                <div *ngIf="attachments.supportedMedia.length === 1">
                    <tr>
                        <th class="full-width" style="font-size: 4rem">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 0 }">
                            </ng-container>
                        </th>
                    </tr>
                </div>

                <div *ngIf="attachments.supportedMedia.length === 2">
                    <tr>
                        <th class="table-two-images">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 0 }">
                            </ng-container>
                        </th>
                        <th class="table-two-images">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 1 }">
                            </ng-container>
                        </th>
                    </tr>
                </div>
                <div *ngIf="attachments.supportedMedia.length === 3">
                    <tr>
                        <th class="table-three-images-big" rowspan="2">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 0 }">
                            </ng-container>
                        </th>
                        <th class="table-three-images-small">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 1 }">
                            </ng-container>
                        </th>
                    </tr>
                    <tr>
                        <td class="table-three-images-small">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 2 }">
                            </ng-container>
                        </td>
                    </tr>
                </div>
                <div *ngIf="attachments.supportedMedia.length === 4" class="big-four-image-gallery">
                    <tr>
                        <th class="table-four-images-big" rowspan="3">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 0 }">
                            </ng-container>
                        </th>
                        <th class="table-four-images-small">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 1 }">
                            </ng-container>
                        </th>
                    </tr>
                    <tr>
                        <td class="table-four-images-small">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 2 }">
                            </ng-container>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-four-images-small">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 3 }">
                            </ng-container>
                        </td>
                    </tr>
                </div>
                <div *ngIf="attachments.supportedMedia.length === 4" class="small-four-image-gallery">
                    <tr>
                        <th class="table-four-images-small">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 0 }">
                            </ng-container>
                        </th>
                        <th class="table-four-images-small">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 1 }">
                            </ng-container>
                        </th>
                    </tr>
                    <tr>
                        <td class="table-four-images-small">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 2 }">
                            </ng-container>
                        </td>
                        <td class="table-four-images-small">
                            <ng-container
                                *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: 3 }">
                            </ng-container>
                        </td>
                    </tr>
                </div>
            </table>
        </div>

        <div *ngIf="attachments.supportedMedia.length > 4" class="attachments-media feed-post__image-container">
            <div *ngFor="let img of attachments.supportedMedia; let i = index" style="display: inline-block; position: relative">
                <ng-container *ngTemplateOutlet="supportedAttachment; context: { attachments: attachments.supportedMedia, index: i }">
                </ng-container>
            </div>
        </div>

        <div class="attachments-other">
            <button *ngFor="let attachment of attachments.other" (click)="openFile(attachment._id)" class="no-button other-files-attachments" attr.aria-label="{{ 'shared.file-viewer.attachment' | transloco }} {{ attachment.name }} {{
                'shared.file-viewer.open-info' | transloco
            }}">
                <app-button
                type="icon"
                size="small"
                color="tertiary-highlight-background"
                hailerIconName="hailer-download"
                (clickEvent)="filesService.download(attachment)"
                (click)="$event.stopPropagation()"
                tippyText="{{ 'shared.file-viewer.download' | transloco }}"
                ariaLabel="{{ 'shared.file-viewer.download' | transloco }} {{ attachment.name }}"></app-button>
                <h3 class="attachment-name">{{ attachment.name }}</h3>
            </button>
        </div>

        <div *ngIf="feedPost.linkedData">
            <ng-container *ngFor="let linkedData of feedPost.linkedData" [ngSwitch]="linkedData.targetType">
                <ng-container *ngSwitchCase="'poll'">
                    <app-poll [sourceId]="feedPost._id" [inputPoll]="linkedData.data"></app-poll>
                </ng-container>
            </ng-container>
        </div>

        <div class="feed-post-likes">
            <div style="display: flex; flex-direction: row; justify-content: space-between">
                <app-user-link-list [userIds]="feedPost.likes"></app-user-link-list>
                <app-feed-post-like-button [feedPost]="feedPost"></app-feed-post-like-button>
            </div>
        </div>
        <app-feed-post-comments
            *ngIf="!!feedPost.comments?.length && (core.permission.feed.comment.load(feedPost) | hasPermission)"
            [comments]="feedPost.comments || []"></app-feed-post-comments>
        <app-feed-post-comment-write
            *ngIf="core.permission.feed.comment.create(feedPost) | hasPermission"
            data-cy="post-comment-write"
            [feedPostId]="feedPost._id">
        </app-feed-post-comment-write>
    </mat-card-content>
    <app-feed-post-editor
        *ngIf="editingPost && (feedPost.type === 'activity.created' || feedPost.type === 'activity.updated')"
        [post]="feedPost">
    </app-feed-post-editor>
</mat-card>

<ng-template #supportedAttachment let-attachments="attachments" let-index="index">
    <img
        data-cy="feed-post-image"
        crossorigin="use-credentials"
        *ngIf="attachments[index].isPicture"
        [class]="attachments.length > 4 ? 'feed-post__image-square mat-elevation-z3' : 'feed-post__image'"
        [src]="getImageUrl(attachments[index])"
        (click)="viewImages(attachments, index)"
        alt="Attachment" />
    <ng-container *ngIf="attachments[index].isVideo">
        <video
            [class]="attachments.length > 4 ? 'feed-post__image-square mat-elevation-z3' : 'feed-post__image'"
            [src]="apiUrl + '/file/' + attachments[index]._id"
            (click)="viewImages(attachments, index)"
            controlsList="nodownload"></video>
        <mat-icon class="play_arrow">play_arrow</mat-icon>
    </ng-container>
</ng-template>
