/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { TippyModule } from '@ngneat/helipopper';

import { ButtonComponent } from './button/button.component';
import { MaterialModule } from 'app/material.module';

@NgModule({
    declarations: [ButtonComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        MaterialModule,
        TippyModule.forRoot({
            touch: 'hold',
            delay: 100,
            animation: 'scale',
            duration: 40,
        }),
    ],
    exports: [ButtonComponent],
})
export class ButtonsModule {}
