<!-- @format -->

<ng-container *ngIf="showDropDown; else listView">
	<div class="grid-container-input-with-clear">
		<mat-form-field appearance="fill" floatLabel="never">
			<mat-label>{{ options.fieldName || ('activities-shared.activity-selector.activity_selector_text' | transloco) }}</mat-label>
			<mat-select
				class="linked-activity-dropdown"
				[formControl]="activityGroupsCtrl"
				[placeholder]="options.placeholderLabel"
				[multiple]="options.multiple"
				(selectionChange)="emitResults($event.value)"
				[compareWith]="compareFn"
				#activitySelect>
				<mat-select-trigger>
					<span>{{ activityGroupsCtrl?.value?.name || '' }}</span>
				</mat-select-trigger>
				<mat-option>
					<ngx-mat-select-search
						class="activity-selector"
						[formControl]="activityGroupsFilterCtrl"
						[placeholderLabel]="options.placeholderLabel"
						[noEntriesFoundLabel]="options.noEntriesFoundLabel">
					</ngx-mat-select-search>
				</mat-option>
				<cdk-virtual-scroll-viewport
					[itemSize]="42"
					[minBufferPx]="42 * 5"
					[maxBufferPx]="42 * 8"
					[style.height.px]="activitySearchArray.length * 42"
					[style.max-height.px]="200">
					<ng-container *ngFor="let activity of activitySearchArray; trackBy: trackById">
						<span class="only-holder">
							<mat-option data-cy="drop-down-option" [value]="{ name: activity.name, _id: activity._id }">
								<div class="container" style="line-height: 1em; flex-direction: column" [tippy]="activity.name" placement="top-start">
									<span class="one-line-ellipsis">{{ activity.name }}</span>
									<span
										><small>{{ getProcessPhaseName(activity.currentPhase) }}</small></span
									>
								</div>
							</mat-option>
							<button
								*ngIf="options.onlySelector"
								color="accent"
								class="only-button"
								(click)="$event.stopPropagation(); onlyItem({ name: activity.name, _id: activity._id })"
								mat-button>
								{{ 'activities-shared.activity-selector.only' | transloco }}
							</button>
						</span>
					</ng-container>
				</cdk-virtual-scroll-viewport>
			</mat-select>
		</mat-form-field>

		<app-clear-x-button *ngIf="options.showResetButton" (clickEvent)="resetSelector()"></app-clear-x-button>
	</div>
</ng-container>

<ng-template #listView>
	<div [ngClass]="{ 'filter-options-scroll': activitySearchArray.length > 7 }" class="filter-options flexbox-column">
		<div class="search-for-activity-filter">
			<mat-form-field appearance="fill" class="activity-filter">
				<mat-icon class="figma-icons-fill-grey" svgIcon="hailer-search"></mat-icon>
				<input [formControl]="activityGroupsFilterCtrl" matInput placeholder="{{ options.placeholderLabel }}" />
			</mat-form-field>
		</div>

		<mat-list>
			<mat-selection-list [multiple]="true" (selectionChange)="selectActivity($event)">
				<ng-container *ngFor="let activity of activitySearchArray; trackBy: trackById">
					<span class="only-holder">
						<mat-list-option
							[selected]="activitySelected(activity._id)"
							[value]="{ name: activity.name, _id: activity._id }"
							checkboxPosition="before">
							<div class="only-holder-text">
								<div class="flexbox-column">
									<p>{{ activity.name }}</p>
									<span class="process-phase-name">{{ getProcessPhaseName(activity.currentPhase) }}</span>
								</div>

								<button
									*ngIf="options.onlySelector"
									color="accent"
									class="only-button"
									(click)="$event.stopPropagation(); onlyItem({ name: activity.name, _id: activity._id })"
									mat-button>
									Only
								</button>
							</div>
						</mat-list-option>
					</span>
				</ng-container>
			</mat-selection-list>
		</mat-list>
	</div>
</ng-template>
