/** @format */

import { createAction, props } from '@ngrx/store';

import { Discussion, DiscussionMap } from '@app/models';

export const setDiscussions = createAction(
    '[Discussions] Set discussions',
    props<{
        discussions: Discussion[];
    }>()
);

export const addDiscussion = createAction(
    '[Discussions] Add discussion',
    props<{
        discussion: Discussion;
    }>()
);

export const syncDiscussions = createAction('[Discussions] Sync Discussions');

export const syncDiscussionsSuccess = createAction(
    '[Discussions API] Sync Discussions Success',
    props<{
        discussions: DiscussionMap;
        removed: string[];
    }>()
);

export const reset = createAction('[Discussions] Reset');
