/** @format */

import { EventEmitter, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { Theme } from './types';
import { darkTheme } from './dark-theme';
import { lightTheme } from './light-theme';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    themeChange = new EventEmitter<Theme>();
    validThemes = [lightTheme, darkTheme];
    themeName = lightTheme.name;
    renderer: Renderer2;

    constructor(private rendererFactory: RendererFactory2) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    getTheme(name: string): Theme {
        const theme = this.validThemes.find(validTheme => validTheme.name === name);
        if (!theme) {
            const updatedTheme = this.setTheme(name === 'dracula' ? 'dark' : 'light');
            console.warn(`"${name}" theme was not found, defaulting to "${updatedTheme.name}"`);
            return updatedTheme;
        }

        return theme;
    }

    getActiveTheme(): Theme {
        return this.getTheme(this.themeName);
    }

    setTheme(name: string): Theme {
        this.themeName = name;
        const activeTheme = this.getActiveTheme();
        this.updateTheme(activeTheme);
        this.themeChange.emit(activeTheme);
        return activeTheme;
    }

    updateTheme(theme: Theme): void {
        // Project properties onto the element
        for (const key of Object.keys(theme.properties)) {
            document.body.style.setProperty(key, theme.properties[key] || null);
        }

        // Remove old theme
        for (const validTheme of this.validThemes) {
            document.body.classList.remove(`${validTheme.name}-theme`);
        }

        // Alias element with theme name
        document.body.classList.add(`${theme.name}-theme`);

        const themeNames = ['hailer-app-dark-theme', 'hailer-app-light-theme'];
        for (const name of themeNames) {
            this.renderer.removeClass(document.body, name);
        }

        this.renderer.addClass(document.body, `hailer-app-${theme.name}-theme`);
    }
}
