/** @format */

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
    selector: 'app-event-data',
    templateUrl: './event-data.component.html',
    styleUrls: ['./event-data.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'discussion', alias: 'discussion' } }],
})
export class EventDataComponent implements OnInit {
    @Input() event;

    constructor() {}

    ngOnInit() {}
}
