/** @format */

import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class DialogHelperService {
    constructor(private zone: NgZone, public matDialog: MatDialog, private translocoService: TranslocoService) {}

    showConfirmAsync(confirm: string, content: string, title: string, confirmColor?: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.zone.run(() => {
                const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
                    data: {
                        cancel: this.translocoService.translate('misc.dialog-helper-service.cancel_button'),
                        confirm,
                        content,
                        title,
                        confirmColor,
                    },
                    width: '400px',
                });

                dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                    resolve(confirmed);
                });
            });
        });
    }

    showConfirm(confirm: string, content: string, title: string, confirmColor?: string): Observable<boolean> {
        const confirmResult = new Subject<boolean>();

        this.zone.run(() => {
            const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
                data: {
                    cancel: this.translocoService.translate('misc.dialog-helper-service.cancel_button'),
                    confirm,
                    content,
                    title,
                    confirmColor,
                },
                width: '400px',
            });

            dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                confirmResult.next(confirmed);
                confirmResult.complete();
            });
        });

        return confirmResult.asObservable();
    }

    showErrorAsync(confirm: string, content: string, title: string, showTryAgainMessage = true): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.zone.run(() => {
                const dialogRef = this.matDialog.open(ErrorDialogComponent, {
                    data: {
                        confirm,
                        content,
                        title,
                        showTryAgainMessage,
                    },
                    width: '400px',
                });

                dialogRef.afterClosed().subscribe((confirmed: boolean) => resolve(confirmed));
            });
        });
    }

    showError(confirm: string, content: string, title: string, showTryAgainMessage = true): Observable<boolean> {
        const confirmResult = new Subject<boolean>();

        this.zone.run(() => {
            const dialogRef = this.matDialog.open(ErrorDialogComponent, {
                data: {
                    confirm,
                    content,
                    title,
                    showTryAgainMessage,
                },
                width: '400px',
            });

            dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                if (confirmed) {
                    confirmResult.next(true);
                    confirmResult.complete();
                } else {
                    confirmResult.next(false);
                    confirmResult.complete();
                }
            });
        });

        return confirmResult.asObservable();
    }
}
