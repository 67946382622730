/** @format */

import { TeamAccount } from './teamAccount.model';
import { Deserializable } from './deserializable.model';

type LocationType = 'area' | 'point' | 'polyline';
type LinkType = 'apple-id' | 'feed.lastseen' | 'google-folder-id' | 'reaction' | 'tag' | 'user-mention';

export interface LocationData {
    lat: number;
    lng: number;
}

export interface Location {
    data: LocationData[];
    label: string;
    type: LocationType;
}

export interface Link {
    id: string;
    linkId: string;
    type: LinkType;
    created: string;
}

export class Activity implements Deserializable {
    active: boolean;
    followers: string[];
    name: string;
    process: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    team_account: TeamAccount;
    location?: Location;
    fields?: any;
    uid?: string;
    cid: string;
    completePhases?: string[];
    completedOn?: string;
    created?: string;
    currentPhase?: string;
    discussion?: string;
    files?: any[];
    followersChanged?: string;
    updated?: string;
    updatedBy?: string;
    _id: string;
    _metas?: any;
    priority?: number;
    thumbnail?: string;
    primaryDateFieldValueStart?: string;
    links?: Link[];
    linkedFromCount?: number;

    constructor(activityData?: any) {
        if (activityData) {
            this.deserialize(activityData);
        }
    }

    deserialize(input: any): this {
        input.team_account = new TeamAccount(input.team_account);
        Object.assign(this, input);
        return this;
    }
}
