<mat-menu #printPdf="matMenu" [overlapTrigger]="false">
    <div class="document-generator">
        <table *ngIf="documentTemplates?.length" class="document-menu-table">
            <tr class="table-header table-row">
                <th colspan="2" class="document-header-column">
                    <p class="table-header-text">{{'activity-sidenav.header.print-menu.document' | transloco}}</p>
                </th>
                <th class="small-column">
                    <p class="table-header-text">{{'activity-sidenav.header.print-menu.multi_template' | transloco}}</p>
                </th>
                <th class="small-column">
                    <p class="table-header-text">{{'activity-sidenav.header.print-menu.attach_to_text' | transloco}}</p>
                </th>
            </tr>
            <tr class="table-row">
                <td class="print-cell">
                    <button mat-icon-button (click)="downloadDocument()">
                        <mat-icon svgIcon="hailer-print" class="print-icon"></mat-icon>
                    </button>
                </td>
                <td class="name-cell">
                    <p class="document-name">{{'activity-sidenav.header.print-menu.activity_detail' | transloco}}</p>
                </td>
                <td class="small-column">
                    <mat-checkbox [checked]="selected('0')" (click)="$event.stopPropagation()" (change)="selectTemplate('0')"> </mat-checkbox>
                </td>
                <td class="small-column">
                </td>
            </tr>
            <tr class="table-row" *ngFor="let documentTemplate of documentTemplates">
                <td class="print-cell">
                    <button mat-icon-button (click)="downloadDocument(documentTemplate.templateId, checkbox.checked)">
                        <mat-icon svgIcon="hailer-print" class="print-icon"></mat-icon>
                    </button>
                </td>
                <td class="name-cell">
                    <p class="document-name" (click)="downloadDocument(documentTemplate.templateId, checkbox.checked)">
                        {{documentTemplate.name}}</p>
                </td>
                <td class="small-column">
                    <mat-checkbox [checked]="selected(documentTemplate.templateId)" (change)="selectTemplate(documentTemplate.templateId)" (click)="$event.stopPropagation()">
                    </mat-checkbox>
                </td>
                <td class="small-column">
                    <mat-checkbox #checkbox [disabled]="selectedTemplates.length > 0" [checked]="false" (click)="$event.stopPropagation()">
                    </mat-checkbox>
                </td>
            </tr>
        </table>
        <button mat-button *ngIf="selectedTemplates.length > 0" (click)="downloadDocument()"><mat-icon svgIcon="hailer-print" class="print-icon"></mat-icon> {{'activity-sidenav.header.print-menu.print' | transloco}}</button>
    </div>
</mat-menu>

<mat-menu #documentPreview="matMenu" [overlapTrigger]="false">
    <div class="document-generator">
        <table *ngIf="documentTemplates?.length" class="document-menu-table">
            <tr class="table-row" *ngFor="let documentTemplate of documentTemplates">
                <td class="document-header-column-small name-cell">
                    <p class="document-name" (click)="showDocumentPreview(documentTemplate.name)">{{documentTemplate.name}}</p>
                </td>
            </tr>
        </table>
    </div>
</mat-menu>

{{redraw()}}

<div class="activity-sidenav-container">
    <div #activityTopbar class="activity-topbar">
        <div class="img-gradient">
            <img [src]="backgroundImage(true)" onError="this.src='/assets/img/pattern_fresh.svg';">
        </div>
        <div class="activity-close-buttons">
            <app-button *ngIf="showBackButton" type="icon" color="icon-default" size="small" matIconClass="back-arrow"
                [ariaLabel]="'activity-sidenav.header.go-back-aria' | transloco" hailerIconName="hailer-tailed-arrow"
                (clickEvent)="popSidenav({ confirmDialog: true })" [tippyText]="'activity-sidenav.header.go_back_tooltip' | transloco">
            </app-button>

            <app-button
                *ngIf="activeTab === 'detail' && documentTemplates?.length && screenWidth > documentPreviewMinWidth && (viewContext.fullscreen | async)"
                type="icon" color="icon-default" size="small" [ariaLabel]="
                (isDocumentPreview
                    ? 'activity-sidenav.header.hide_document_preview'
                    : 'activity-sidenav.header.show_document_preview'
                ) | transloco
            " [hailerIconName]="isDocumentPreview ? 'hailer-hide' : 'hailer-show'" (clickEvent)="toggleDocumentPreview(!isDocumentPreview)"
                [tippyText]="
                (isDocumentPreview
                    ? 'activity-sidenav.header.hide_document_preview'
                    : 'activity-sidenav.header.show_document_preview'
                ) | transloco
            "></app-button>

            <app-button type="icon" color="icon-default" size="small" [ariaLabel]="
                ((viewContext.fullscreen | async)
                    ? 'activity-sidenav.header.collapse_tooltip'
                    : 'activity-sidenav.header.expand_tooltip'
                ) | transloco
            " [hailerIconName]="(viewContext.fullscreen | async) ? 'hailer-collapse-view' : 'hailer-expand-view'"
                (clickEvent)="viewContext.toggleFullscreen(); toggleDocumentPreview(false)" [tippyText]="
                ((viewContext.fullscreen | async)
                    ? 'activity-sidenav.header.collapse_tooltip'
                    : 'activity-sidenav.header.expand_tooltip'
                ) | transloco
            "></app-button>

            <app-button dataCy="close-activity-button" type="icon" color="icon-default" size="small"
                [ariaLabel]="'activity-sidenav.header.close-aria' | transloco" hailerIconName="hailer-x" (clickEvent)="clearSidenav()"
                [tippyText]="'activity-sidenav.header.close_tooltip' | transloco"></app-button>
        </div>
        <div class="upper-header-inner">
            <div class="img-shadow"></div>
            <div class="img-circle">
                <img [src]="backgroundImage(true)" onError="this.src='/assets/img/pattern_fresh_cropped.svg';">
            </div>

            <div class="activity-name-tags-wrapper">
                <h3 *ngIf="!(viewContext.error | async)" class="activity-name two-line-ellipsis" [tippy]="activityName">
                    {{ activityName }}
                </h3>

                <div *ngIf="!(viewContext.error | async) && (viewContext.workflow | async)" class="info-tags-container">
                    <div *ngIf="viewContext.getNetwork(viewContext.workflow.value?.cid!)?.name" class="info-tag"
                        [style.background-color]="stylingHelperService.getNetworkBackgroundColor(network)"
                        [style.color]="stylingHelperService.getNetworkTextColor(network)">
                        <p class="info-tag-text">{{ viewContext.getNetwork(viewContext.workflow.value?.cid!)?.name }}</p>
                    </div>

                    <div *ngIf="(viewContext.workflow | async)?.name"
                        (click)="viewContext.redirectToActivity(viewContext.activity.value?._id!)" class="info-tag clickable"
                        [style.background-color]="stylingHelperService.getWorkflowBackgroundColor(workflow)"
                        [style.color]="stylingHelperService.getWorkflowTextColor(workflow)">
                        <p data-cy="sidenav-info-tag-text" class="info-tag-text">{{ (viewContext.workflow | async)?.name }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div #followersContainer class="followers-container" *ngIf="!(viewContext.error | async)">
        <div *ngIf="(viewContext.workflow | async)?.enableMessenger" class="follower-images-container">
            <app-user-profile-picture [attr.data-cy]="'activity-sidenav-header-profile-picture-' + userId"
                *ngFor="let userId of followers | slice: 0:4 | participants:followerPipeArgs" class="follower-image" [userId]="userId"
                (click)="openUser(userId)" [size]="32" tippy="{{viewContext.getUserName(userId)}}">
            </app-user-profile-picture>
            <div *ngIf="(followers?.length || 0) > 4">
                <button type="button" mat-icon-button [matMenuTriggerFor]="followersMenu" class="more-button mat-elevation-z0">
                    +{{followers.length - 4}}
                </button>
            </div>

            <button mat-icon-button class="invite-button mat-elevation-z0" data-cy="invite-to-activity-button"
                [disabled]="viewContext.isWorkspaceGuest" type="button" (click)="addFollowers()"
                tippy="{{'activity-sidenav.header.invite_people_tooltip' | transloco}}">
                <mat-icon svgIcon="hailer-add-user-inverted" class="smaller-icon"></mat-icon>
            </button>
        </div>

        <div class="follow-add-container">
            <!-- Finndent send data button -->
            <button color="primary" [disabled]="(viewContext.nextPhaseId | async) && viewContext.action === 'view'"
                *ngIf="showFinndentButton" mat-icon-button tippy="{{'activity-sidenav.header.finndent_tooltip' | transloco}}"
                (click)="sendDataToFinndent()" class="mat-elevation-z0 document-generator-button">
                <mat-icon svgIcon="hailer-shopping-cart" *ngIf="!isSendingData; else sendingData"></mat-icon>
                <ng-template #sendingData>
                    <mat-spinner color="accent" diameter="25" mode="indeterminate">
                    </mat-spinner>
                </ng-template>
            </button>

            <!-- Copy activity button -->
            <button data-cy="copy-activity-button" *ngIf="viewContext.action !== 'create'" color="primary"
                [disabled]="disableActivityCopyButton" tippy="{{'activity-sidenav.header.copy_tooltip' | transloco}}" mat-icon-button
                (click)="viewContext.copyActivities()" class="mat-elevation-z0">
                <mat-icon svgIcon="hailer-copy" aria-label="Copy"></mat-icon>
            </button>

            <!-- Document template button; No templates -->
            <button
                color="primary"
                [disabled]="viewContext.nextPhaseId | async"
                type="button"
                *ngIf="!documentTemplates?.length && viewContext.action !== 'create'; else multipleTemplates" mat-icon-button
                tippy="{{'activity-sidenav.header.print_tooltip' | transloco}}" (click)="downloadDocument()"
                class="mat-elevation-z0 document-generator-button">
                <ng-container [ngTemplateOutlet]="documentButtonContent"></ng-container>
            </button>

            <!-- Document template button; Multiple templates -->
            <ng-template #multipleTemplates>
                <button color="primary" type="button" mat-icon-button
                    *ngIf="viewContext.action !== 'create'"
                    tippy="{{'activity-sidenav.header.print_menu_tooltip' | transloco}}" [matMenuTriggerFor]="printPdf"
                    class="mat-elevation-z0 document-generator-button">
                    <ng-container [ngTemplateOutlet]="documentButtonContent"></ng-container>
                </button>
            </ng-template>

            <!-- Document template button icon -->
            <ng-template #documentButtonContent>
                <mat-icon svgIcon="hailer-print"
                    *ngIf="!isGeneratingDocument; else loadingDocument"></mat-icon>
                <ng-template #loadingDocument>
                    <mat-spinner color="accent" diameter="25" mode="indeterminate"></mat-spinner>
                </ng-template>
            </ng-template>

            <ng-container
                *ngIf="(viewContext.workflow | async)?.enableMessenger">
                <ng-container *ngIf="inDiscussion">
                    <button data-cy="leave-activity-button" class="leave-button mat-elevation-z0" type="button" mat-icon-button color="primary"
                        (click)="followActivity()" tippy="{{'activity-sidenav.header.leave_discussion_tooltip' | transloco}}">
                        <mat-icon aria-label="Unfollow activity" svgIcon="hailer-leave-chat"></mat-icon>
                    </button>
                </ng-container>

                <ng-container *ngIf="!inDiscussion">
                    <button data-cy="join-activity-button" class="join-button" mat-flat-button color="accent"
                        (click)="followActivity()">{{'activity-sidenav.header.join' | transloco}}
                    </button>
                </ng-container>
            </ng-container>
        </div>

        <mat-menu #followersMenu="matMenu">
            <button mat-menu-item *ngFor="let userId of (viewContext.activity | async)?.followers| slice: 4 | participants:followerPipeArgs"
                (click)="openUser(userId)" class="follower-item">
                <app-user-profile-picture class="user-picture" [userId]="userId" [size]="32">
                </app-user-profile-picture>
                <p class="user-name">{{viewContext.getUserName(userId)}}</p>
            </button>
        </mat-menu>
    </div>


    <mat-tab-group #activityTabs *ngIf="!(viewContext.error | async); else failedToLoad" class="activity-tabs"
        [style.height]="tabGroupHeight">

        <!-- Activity info tab -->
        <mat-tab label="detail">
            <ng-template mat-tab-label>
                <mat-icon id="act-tab-detail" [color]="(viewContext.errorsInSidenav | async)?.invalidDetails ? 'warn' : ''"
                    svgIcon="hailer-activity" tippy="{{'activity-sidenav.tab-label.overview_tooltip' | transloco}}"></mat-icon>
            </ng-template>
            <div (scroll)="onScroll($event)" class="activity-tab-background activity-tab-padding">
                <ng-container *ngTemplateOutlet="editMultipleNote"></ng-container>
                <div [ngClass]="isDocumentPreview ? 'doc-preview-container' : ''">
                    <div [ngClass]="isDocumentPreview ? 'doc-preview-flexbox-column' : ''">
                        <app-v3-activity-detail></app-v3-activity-detail>
                    </div>
                    <div *ngIf="isDocumentPreview && documentTemplates?.length && screenWidth > documentPreviewMinWidth && (viewContext.fullscreen | async)"
                        class="doc-preview-flexbox-column get-sticky" style="height: 100vh;">
                        <p *ngIf="platform.SAFARI">{{'activities.templates-component.safari-error' | transloco}}</p>
                        <ng-container *ngIf="sourceUrl && !platform.SAFARI"
                            style="max-width: 100%; max-height: 100%; height: 100%; min-width: 400px;">
                            <div class="template-header-wraper">
                                <h2 *ngIf="!loadingPreviewDoc" class="template-header one-line-ellipsis">{{templateName}}</h2>
                                <h2 *ngIf="loadingPreviewDoc" class="template-header one-line-ellipsis">
                                    {{'activities.templates-component.loading-document' |
                                    transloco}}</h2>
                                <app-button [isLoading]="loadingPreviewDoc" color="secondary" [menuTriggerFor]="documentPreview"
                                    label="{{'activities.templates-component.select-template' | transloco}}"
                                    ariaLabel="{{'activity-sidenav.header.print_menu_preview_tooltip' | transloco}}"></app-button>
                            </div>
                            <object *ngIf="applicationType !== 'text/csv'" [attr.data]="sourceUrl" type="{{applicationType}}" width="100%"
                                height="90%"></object>
                            <ngx-monaco-editor *ngIf="applicationType === 'text/csv'" [options]="editorOptions"
                                [(ngModel)]="csvData"></ngx-monaco-editor>
                        </ng-container>
                        <ng-container *ngIf="!sourceUrl && !platform.SAFARI">
                            <div class="loading-state">
                                <h2 class="template-header one-line-ellipsis">{{'activities.templates-component.loading-document' |
                                    transloco}}</h2>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </mat-tab>

        <!-- Discussion tab -->
        <mat-tab label="discussion" *ngIf="showDiscussionTab">
            <ng-template mat-tab-label>
                <mat-icon id="act-tab-discussion" svgIcon="hailer-discussion"
                    tippy="{{'activity-sidenav.tab-label.discussion_tooltip' | transloco}}"></mat-icon>
            </ng-template>
            <div class="activity-tab-background discussion-tab">
                <ng-container *ngTemplateOutlet="editMultipleNote"></ng-container>
                <app-v3-activity-discussion
                    *ngIf="meInDiscussion && activeTab === 'discussion'; else joinDiscussionText"></app-v3-activity-discussion>
            </div>
            <ng-template #joinDiscussionText>
                <div class="join-discussion">
                    <div class="empty-state-discussion"></div>
                    <h3 class="empty-state-text-discussion">
                        <span data-cy="join-discussion-text-button" class="discussion-link"
                            (click)="followActivity()">{{'activity-sidenav.discussion.join.title' | transloco}}</span>
                        {{'activity-sidenav.discussion.join.content' | transloco}}
                    </h3>
                </div>
            </ng-template>
        </mat-tab>

        <!-- File tab -->
        <mat-tab label="files" *ngIf="showFilesTab">
            <ng-template mat-tab-label>
                <mat-icon svgIcon="hailer-attach" id="act-tab-files" tippy="{{'activity-sidenav.tab-label.files_tooltip' | transloco}}"
                    *ngIf="!(viewContext.errorsInSidenav | async)?.filesUploading; else loadingSpinner">
                </mat-icon>
            </ng-template>
            <div class="activity-tab-background activity-tab-padding">
                <ng-container *ngTemplateOutlet="editMultipleNote"></ng-container>
                <app-v3-activity-files></app-v3-activity-files>
            </div>
        </mat-tab>

        <!-- Location tab -->
        <mat-tab label="location" *ngIf="(viewContext.workflow | async)?.enableMapLocation">
            <ng-template mat-tab-label>
                <mat-icon svgIcon="hailer-location" id="act-tab-location"
                    tippy="{{'activity-sidenav.tab-label.location_tooltip' | transloco}}"
                    [color]="(viewContext.errorsInSidenav | async)?.invalidMap ? 'warn' : ''">
                </mat-icon>
            </ng-template>
            <div class="activity-tab-background location-tab">
                <ng-container *ngTemplateOutlet="editMultipleNote"></ng-container>
                <app-v3-activity-map></app-v3-activity-map>
            </div>
        </mat-tab>

        <!-- Linked activities tab -->
        <mat-tab label="linkedFrom" [disabled]="viewContext.isWorkspaceGuest" *ngIf="viewContext.action === 'view'">
            <ng-template mat-tab-label>
                <mat-icon id="act-tab-linkedFrom" tippy="{{'activity-sidenav.tab-label.linked_from_tooltip' | transloco}}"
                    *ngIf="!(viewContext.loadingLinkedFrom | async); else loadingSpinner" svgIcon="hailer-linked-activity"></mat-icon>
            </ng-template>
            <div class="activity-tab-background activity-tab-padding">
                <ng-container *ngTemplateOutlet="editMultipleNote"></ng-container>
                <app-v3-activity-linked *ngIf="activeTab === 'linkedFrom'"></app-v3-activity-linked>
            </div>
        </mat-tab>

        <!-- Options/Settings tab -->
        <mat-tab label="options" *ngIf="showOptionsTab">
            <ng-template mat-tab-label>
                <mat-icon id="act-tab-options" [class.warn]="invalidOptions"
                    tippy="{{ 'activity-sidenav.tab-label.settings_tooltip' | transloco }}" svgIcon="hailer-settings"></mat-icon>
            </ng-template>
            <div class="activity-tab-background activity-tab-padding">
                <ng-container *ngTemplateOutlet="editMultipleNote"></ng-container>
                <app-v3-activity-options></app-v3-activity-options>
            </div>
        </mat-tab>
    </mat-tab-group>

    <div #controlButtons class="control-buttons"
        *ngIf="(viewContext.editing | async) || (viewContext.editingFiles | async) || (editingMapLocation || (viewContext.editingLocation | async))">
        <button data-cy="cancel-edit-button" class="cancel-button" mat-button (click)="cancel()">{{'activity-sidenav.cancel_button' |
            transloco}}</button>
        <div class="vertical-border"></div>
        <button data-cy="save-activity-button" class="save-button" mat-button [disabled]="disableSaveButton" (click)="saveActivity()">
            <mat-icon svgIcon="hailer-tick"></mat-icon>
            {{'activity-sidenav.save_button' | transloco}}
        </button>
    </div>
</div>



<ng-template #editMultipleNote>
    <div *ngIf="!hideEditMultipleNote" class="note-container">
        <div class="note-title-container flex-row--space-between">
            <p class="note-title">{{'activity-sidenav.edit-multiple-note.title' | transloco}}</p>
            <button (click)="hideEditMultipleNote = true" class="small-clear-button" mat-icon-button>
                <mat-icon svgIcon="hailer-x"></mat-icon>
            </button>
        </div>
        <p class="note-content">
            {{'activity-sidenav.edit-multiple-note.content' | transloco}}
        </p>
    </div>
</ng-template>

<ng-template #loadingSpinner>
    <mat-spinner diameter="24" tippy="{{'activity-sidenav.loading_tooltip' | transloco}}" color="accent"></mat-spinner>
</ng-template>

<ng-template #failedToLoad>
    <div class="error-happened-container">
        <img crossorigin class="error-img" src="../../../../assets/img/technical_issues.svg">
        <h3>{{'activity-sidenav.technical-issue.title' | transloco}}</h3>
        <p>{{'activity-sidenav.technical-issue.content' | transloco}}</p>
    </div>
</ng-template>