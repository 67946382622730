/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTabGroup } from '@angular/material/tabs';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { PersonalSettings } from '@app/models';
import { PeopleService } from 'app/people/people.service';
import { DropdownItem, DropdownItems, DropdownOptions, DropdownSelectedItems } from 'app/_models/dropdown-selector.model';
import { CoreService } from 'app/_services/core.service';
import { TranslateService } from 'app/_services/translate.service';

@Component({
    selector: 'app-dropdown-selector',
    templateUrl: './dropdown-selector.component.html',
    styleUrls: ['./dropdown-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'shared', alias: 'shared' } }],
})
export class DropdownSelectorComponent implements OnInit, OnDestroy {
    @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

    @Input() items = new BehaviorSubject<DropdownItems>({});
    @Input() options = new BehaviorSubject<DropdownOptions>(new DropdownOptions());
    @Input() selectedItems: BehaviorSubject<DropdownSelectedItems>;

    itemControl = new UntypedFormControl('', this.checkForErrors());
    filteredItemGroups = new BehaviorSubject<string[][]>([]);
    currentSearchString: string;

    private onDestroy = new Subject<void>();
    private me: PersonalSettings;

    /** Icon and title info for groups. This is also user for the order of the tabs */
    private itemGroupInfoMap: { [type: string]: { icon: string; title: string } } = {
        user: { icon: 'hailer-group', title: 'Users' },
        team: { icon: 'hailer-team', title: 'Teams' },
        group: { icon: 'hailer-groups', title: 'Groups' },
        activity: { icon: 'hailer-activity', title: 'Activities' },
        calendar: { icon: 'hailer-calendar', title: 'Calendars' },
        workspace: { icon: 'hailer-workspace', title: 'Workspaces' },
        other: { icon: 'hailer-list', title: 'Other' },
    };

    constructor(
        private cdr: ChangeDetectorRef,
        private core: CoreService,
        private people: PeopleService,
        private translocoService: TranslocoService,
        private translateService: TranslateService
    ) {
        this.translateService.translationLoaded('shared').then(() => {
            this.itemGroupInfoMap.user.title = this.translocoService.translate('shared.dropdown-selector.title.users');
            this.itemGroupInfoMap.team.title = this.translocoService.translate('shared.dropdown-selector.title.teams');
            this.itemGroupInfoMap.activity.title = this.translocoService.translate('shared.dropdown-selector.title.activities');
            this.itemGroupInfoMap.calendar.title = this.translocoService.translate('shared.dropdown-selector.title.calendar');
            this.itemGroupInfoMap.workspace.title = this.translocoService.translate('shared.dropdown-selector.title.workspaces');
            this.itemGroupInfoMap.other.title = this.translocoService.translate('shared.dropdown-selector.title.other');
        });
    }

    ngOnInit(): void {
        this.me = this.core.user.value;

        this.options.pipe(takeUntil(this.onDestroy)).subscribe({
            next: options => {
                this.cdr.detectChanges();
                if (options.customValidator) {
                    this.itemControl.clearValidators();
                    this.itemControl.addValidators(this.checkForErrors());
                }
                this.itemControl.markAsTouched();
                this.itemControl.updateValueAndValidity();
            },
        });

        this.items.pipe(takeUntil(this.onDestroy)).subscribe({
            next: items => {
                this.unselectRemovedItems();

                const sorted = this.sortItemsAlphabetically(Object.keys(items));
                const grouped = this.groupItemsByType(sorted);
                const orderedUserGroup = this.orderUserGroup(grouped);

                this.filteredItemGroups.next(orderedUserGroup);
                this.cdr.detectChanges();

                if (sorted.length === 1 && this.options.value.selectLastItem) {
                    const itemId = sorted[0];

                    this.selectItem(itemId);
                }
            },
        });

        if (this.selectedItems) {
            this.selectedItems.pipe(takeUntil(this.onDestroy)).subscribe({
                next: () => {
                    this.cdr.detectChanges();
                    this.itemControl.markAsTouched();
                    this.itemControl.updateValueAndValidity();
                },
            });
        }

        this.itemControl.valueChanges.pipe(debounceTime(100), takeUntil(this.onDestroy)).subscribe({
            next: searchString => {
                if ((searchString || undefined) === (this.currentSearchString || undefined)) {
                    return;
                }

                this.currentSearchString = searchString;
                const ids = searchString ? this.searchFilter(searchString) : Object.keys(this.items.value);
                const sorted = this.sortItemsAlphabetically(ids);
                const grouped = this.groupItemsByType(sorted);
                const orderedUserGroup = this.orderUserGroup(grouped);

                this.filteredItemGroups.next(orderedUserGroup);
            },
        });

        if (this.options?.value?.required) {
            this.itemControl.markAsTouched();
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    getTitle(id: string): string {
        return this.items.value[id]?.title;
    }

    getItem(id: string): DropdownItem {
        return this.items.value[id];
    }

    onlyItem(itemId: string) {
        const selected: DropdownSelectedItems = {};
        const type = this.items.value[itemId]?.type;
        selected[type] = [itemId];
        this.selectedItems.next(selected);
        this.cdr.detectChanges();
        this.itemControl.updateValueAndValidity();
    }

    checkboxSelectionChange(event: MatCheckboxChange, itemId: string) {
        if (event.checked) {
            this.selectItem(itemId);
        } else {
            this.removeSelection(itemId);
        }
    }

    /** Checks if the item is selected and removes or selects the item */
    toggleSelection(id: string) {
        if (!id) {
            console.warn('No id given');
            return;
        }

        const type = this.items.value[id]?.type;
        const isSelected = !!this.selectedItems.value[type]?.includes(id);

        if (isSelected) {
            this.removeSelection(id);
        } else {
            this.selectItem(id);
        }
    }

    removeSelection(id: string) {
        const filtered: DropdownSelectedItems = {};
        Object.values(this.selectedItems.value).forEach(selected => {
            selected.forEach(selectedId => {
                if (selectedId === id) {
                    return;
                }

                const type = this.items.value[selectedId]?.type;

                if (!filtered[type]?.length) {
                    filtered[type] = [];
                }

                filtered[type].push(selectedId);
            });
        });

        this.selectedItems.next(filtered);
        this.cdr.detectChanges();
        this.itemControl.updateValueAndValidity();

        if (this.options.value.closeOnSelection) {
            this.trigger.closePanel();
        }
    }

    selectItem(id: string) {
        let selected = this.selectedItems.value;
        const type = this.items.value[id]?.type;

        if (selected[type]?.includes(id)) {
            return;
        }

        if (this.itemDisabled(id)) {
            return;
        }

        if (!this.options.value.multiple) {
            selected = {};
            this.itemControl.setValue('');
        }

        if (!selected[type]?.length) {
            selected[type] = [];
        }

        selected[type].push(id);

        this.selectedItems.next(selected);

        this.cdr.detectChanges();
        this.itemControl.updateValueAndValidity();

        if (this.options.value.closeOnSelection) {
            this.trigger.closePanel();
        }
    }

    /** Checks if the item is selected */
    itemSelected(id: string): boolean {
        const type = this.items.value[id]?.type;
        return this.selectedItems.value[type]?.includes(id);
    }

    itemDisabled(id: string): boolean {
        return this.items.value[id]?.disabled;
    }

    /** Sorts items alphabetically */
    sortItemsAlphabetically(ids: string[]): string[] {
        if (!ids?.length) {
            return [];
        }

        if (!this.options.value.sortAlphabetically) {
            return ids;
        }

        return ids.sort((aId, bId) => {
            const a = this.items.value[aId]?.title;
            const b = this.items.value[bId]?.title;

            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }

            return 0;
        });
    }

    /** Returns icon name and the title of the group */
    getGroupInfo(group: string[]): { icon: string; title: string } {
        if (!group) {
            return;
        }

        const type = this.getItem(group[0])?.type;

        return this.itemGroupInfoMap[type] ? this.itemGroupInfoMap[type] : this.itemGroupInfoMap.other;
    }

    getVirtualScrollHeight(groupLength: number): number {
        const itemHeightPx = 40;
        const maxHeight = 6;

        return groupLength > maxHeight ? itemHeightPx * maxHeight : itemHeightPx * groupLength;
    }

    toggleAutocomplete() {
        const isOpen = this.trigger?.panelOpen;

        if (isOpen) {
            this.trigger?.closePanel();
            return;
        }

        this.trigger?.openPanel();
    }

    getDropdownTriggerOrientation(): string {
        const isOpen = this.trigger?.panelOpen;
        let amount = 0;
        if (isOpen) {
            amount = 180;
        }

        return `transform: rotate(${amount}deg);`;
    }

    realignInkbar() {
        this.tabGroup.realignInkBar();
    }

    get onlyTab(): boolean {
        return this.filteredItemGroups.value.length <= 1;
    }

    get selectedItemsLength(): number {
        let length = 0;

        if (!this.selectedItems) {
            return;
        }

        Object.values(this.selectedItems.value).forEach(itemIds => {
            length += itemIds?.length || 0;
        });

        return length;
    }

    get selectedItemsValues(): DropdownItem[] {
        if (!this.selectedItems.value) {
            return [];
        }

        const out: DropdownItem[] = [];
        Object.values(this.selectedItems.value).forEach(items => {
            items.forEach(itemId => {
                out.push(this.items.value[itemId]);
            });
        });

        return out;
    }

    private unselectRemovedItems() {
        const filteredSelectedItems: DropdownSelectedItems = {};
        let itemsWereRemoved: boolean;

        for (const key in this.selectedItems.value) {
            if (!this.selectedItems.value[key] || !Array.isArray(this.selectedItems.value[key])) {
                continue;
            }

            filteredSelectedItems[key] = this.selectedItems.value[key]?.filter(_id => {
                itemsWereRemoved = !this.items.value[_id];
                return !itemsWereRemoved;
            });
        }

        if (!itemsWereRemoved) {
            return;
        }

        this.selectedItems.next(filteredSelectedItems);
    }

    /** Sets current user as the first one in user arrays */
    private orderUserGroup(filteredItemGroups: string[][]): string[][] {
        filteredItemGroups.forEach(group => {
            const type = this.getItem(group[0])?.type;
            if (type === 'user') {
                group.forEach((userId, index) => {
                    if (userId === this.me._id) {
                        group.splice(index, 1);
                        group.unshift(userId);
                    }
                    if (userId === 'non-assigned') {
                        group.splice(index, 1);
                        group.splice(1, 0, userId);
                    }
                });
            }
        });

        return filteredItemGroups;
    }

    /** Groups items by type and sorts them alphabetically */
    private groupItemsByType(ids: string[]): string[][] {
        const groups: { [type: string]: string[] } = {};

        ids.forEach(id => {
            if (this.people.isMockUser(id)) {
                return;
            }

            const item = this.items.value[id];

            if (!groups[item.type]) {
                groups[item.type] = [];
            }

            groups[item.type].push(id);
        });

        const groupOrder = Object.keys(this.itemGroupInfoMap);
        const sortedGroups: string[][] = [];
        groupOrder.forEach(name => {
            if (!groups[name]) {
                return;
            }

            sortedGroups.push(groups[name]);
        });

        return sortedGroups;
    }

    /** Searches the items with specified search string */
    private searchFilter(searchString: string): string[] {
        if (!Object.keys(this.items.value || {}).length) {
            return [];
        }

        return Object.keys(this.items.value).filter(
            id => this.items.value[id]?.title?.toLocaleLowerCase()?.indexOf(searchString?.toLocaleLowerCase()) >= 0
        );
    }

    /** Checks if the form field has errors */
    private checkForErrors(): ValidatorFn {
        if (this.options.value.customValidator) {
            return this.options.value.customValidator;
        }
        return (): ValidationErrors => (this.options?.value?.required && !this.selectedItemsLength ? { required: true } : null);
    }
}
