
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-feed-post-schedule-dialog',
    templateUrl: './feed-post-schedule-dialog.component.html',
    styleUrls: ['./feed-post-schedule-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: TRANSLOCO_SCOPE, useValue: { scope: 'feed', alias: 'feed' } },
        { provide: TRANSLOCO_SCOPE, useValue: { scope: 'shared', alias: 'shared' } },
    ],
})
export class FeedPostScheduleDialogComponent implements OnInit {
    /** Minute from now */
    minimum = new Date(Date.now() + 60000);
    scheduleDateForm = new FormControl<Date | null | undefined>(undefined);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { value?: number },
        private dialogRef: MatDialogRef<FeedPostScheduleDialogComponent>,
    ) {}

    ngOnInit(): void {
        if (this.data.value) {
            this.scheduleDateForm.setValue(new Date(this.data.value));
        }

        // Update minimum time every minute
        setInterval(() => {
            this.minimum = new Date(Date.now() + 60000);
        }, 60000);
    }

    confirm(): void {
        this.dialogRef.close(this.scheduleDateForm.value ? this.scheduleDateForm.value.getTime() : null);
    }

    clear(): void {
        this.scheduleDateForm.setValue(null);
    }

    close(): void {
        if (!this.dialogRef) {
            return;
        }

        this.dialogRef.close(undefined);
    }
}