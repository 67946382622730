<!-- @format -->

<div class="container">
    <h1>{{ 'apps.permissions.title' | transloco }} {{ app.name }}</h1>

    <div class="process-permissions-container row-field" style="margin-top: 8px">
        <div data-cy="permissions-container" class="permission-container">
            <app-permission-selector
                data-cy="app-permission-selector"
                [members]="selectorMembers"
                (memberAdd)="memberAdd(app, $event)"
                (memberRemove)="memberRemove(app, $event)">
            </app-permission-selector>
        </div>
    </div>

    <div class="button">
        <app-button
            type="text"
            color="tertiary-default-background"
            size="medium"
            [label]="'apps.permissions.close' | transloco"
            [ariaLabel]="'apps.permissions.close' | transloco"
            (clickEvent)="close()">
        </app-button>
    </div>
</div>
