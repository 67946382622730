/** @format */

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { Message } from '@app/models';

@Component({
    selector: 'app-message-event-attend',
    templateUrl: './message-event-attend.component.html',
    styleUrls: ['./message-event-attend.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'discussion', alias: 'discussion' } }],
})
export class MessageEventAttendComponent {
    @Input() message: Message;
}
