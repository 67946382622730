/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as moment from 'moment';
import * as _moment from 'moment';

import { ActivityUpdateData } from './../_models/activityUpdateData.model';
import { RPCService } from 'app/_services/rpc.service';
import { ActivitiesHelperService } from 'app/_services/activities-helper.service';
import {
    Activity,
    ActivityCreateModel,
    ActivityFieldMap,
    CanBeLinkedFrom,
    CompleteAction,
    FieldTypeMap,
    Phase,
    Process,
    ProcessViewType,
} from '@app/models';
import { CoreService } from '../_services/core.service';
import { SETUpdateResult } from 'app/_models/activitySetUpdateResult';
import { ActivityTimelineArgument } from 'app/_models/activity-timeline-argument.model';
import { ActivityMetaData } from 'app/_models/activityMetadata.model';
import { ProcessDatasetPipe } from './pipes/process-dataset.pipe';
import { stripUndefined } from 'app/_helpers/util';
import { ActivityCopyOptions } from 'app/_models/v3-activity.model';
import { CoreSignal } from 'app/_models/coreSignal.enum';

export interface PhaseCounts {
    [key: string]: number;
}

export interface ActivitiesUpdatedSignal {
    phase?: string;
    activityIds?: string[] | string;
    removed?: boolean;
    processId?: string;
}

// TODO: make model for metadata
export interface ActivityResult {
    activities: Activity[];
    metadata: ActivityMetaData;
}

export interface CalendarActivity {
    active: boolean;
    end?: any;
    id: string;
    fields: {
        [fieldId: string]: any;
    };
    title: string;
    classNames?: string[];
    resourceId?: string;
    start?: any;
    allDay: boolean;
    borderColor?: string;
    backgroundColor?: string;
}

export interface ActivityTimelineResult {
    activities: CalendarActivity[];
    metadata: {
        activityLinks: any;
        behindSchedule: number;
        behindScheduleValue: number;
        dates: any;
        lessThanOneWeek: number;
        lessThanOneWeekValue: number;
        onSchedule: number;
        onScheduleValue: number;
        primaryNumericFieldUnit: string;
        resourceGroupField: string;
        teams: any;
        totalCount: number;
        totalPrimaryNumericValue: number;
        users: any;
        resources: any;
    };
}

export interface RenderedLinkedActivities {
    name: string;
    description: string;
    id: string;
    cid: string;
    phases: { name: string; activities: Activity[] }[];
}

@Injectable({
    providedIn: 'root',
})
export class ActivitiesService {
    activityFilter = new BehaviorSubject<any>(null);
    resetFilter = new Subject<boolean>();
    nameFilter = new BehaviorSubject<any>(null);
    userFilter = new BehaviorSubject<any>(null);
    unassignedFilter = new BehaviorSubject<boolean>(null);
    redirectOnNetwork = new BehaviorSubject(true);
    processCurrentView = new BehaviorSubject<ProcessViewType>('table');
    endDrag = new Subject<void>();
    activityCreated$: Observable<any>;
    activityFollow$: Observable<string>;
    followButton$: Observable<void>;
    followSignal$: Observable<string>;
    processActivities$: Observable<Activity[]>;
    reloadActivities$: Observable<ActivitiesUpdatedSignal>;
    metaDataObject: ActivityMetaData;

    phaseCounts: PhaseCounts = {};

    numericRulesSymbols = {
        equalTo: '=',
        notEqualTo: '!=',
        greaterThan: '>',
        greaterThanOrEqual: '>=',
        lessThan: '<',
        lessThanOrEqual: '<=',
        between: '-',
    };
    numericRules = ['equalTo', 'notEqualTo', 'greaterThan', 'greaterThanOrEqual', 'lessThan', 'lessThanOrEqual', 'between'];
    multiControlFields = ['text', 'textarea', 'numeric', 'numericunit', 'name'];
    intersectFields = ['timerange', 'datetimerange', 'daterange', 'date', 'time', 'datetime'];
    multiValueFields = ['country', 'activitylink', 'teams', 'textpredefinedoptions', 'users'];

    private activityCreatedSubject = new Subject<any>();
    private activityFollowSubject = new Subject<string>();
    private followButtonSubject = new Subject<void>();
    private followSignalSubject = new Subject<string>();
    private processActivitiesSubject = new Subject<Activity[]>();
    private reloadActivitiesSubject = new Subject<ActivitiesUpdatedSignal>();

    constructor(private core: CoreService, private rpc: RPCService, private activitiesHelperService: ActivitiesHelperService) {
        this.init();

        this.core.onAuthenticated(() => {
            this.subscribeActivitiesCreated();
            this.subscribeActivitiesFollow();
            this.subscribeActivitiesUpdated();
        });
    }

    set metaData(metadata: any) {
        this.metaDataObject = metadata;
    }

    setActivityFilter(newerFieldFilters: any, fieldIds: any) {
        const fieldFilters = [];
        const workflowFilters = [];
        Object.keys(newerFieldFilters).forEach(processId => {
            if (!processId) {
                return;
            }
            const workflowFilter = {
                name: null,
                active: true,
                created: Date.now(),
                updated: Date.now(),
                uid: this.core.user.value._id,
                workspaceId: this.core.network.value._id,
                workflowId: processId,
                view: ['calendar'],
                members: [],
                filters: {},
            };
            const fieldFilter: any = {
                and: [{ or: newerFieldFilters[processId].phases }],
            };
            const fields = [];
            for (const fieldId of Object.keys(newerFieldFilters[processId].fields)) {
                if (newerFieldFilters[processId].fields[fieldId].length > 0) {
                    if (Object.keys(newerFieldFilters[processId].fields[fieldId][0])[0] === 'name') {
                        if (newerFieldFilters[processId].fields[fieldId].length > 1) {
                            const nameFields = [...newerFieldFilters[processId].fields[fieldId]];
                            fields.push({ or: nameFields });
                            continue;
                        }
                        fields.push(newerFieldFilters[processId].fields[fieldId][0]);
                    } else {
                        const process = this.core.processes.value[this.core.network.value._id][processId];
                        if (this.multiValueFields.includes(process.fields[fieldId].type)) {
                            const fieldValues = [];
                            for (const field of newerFieldFilters[processId].fields[fieldId]) {
                                fieldValues.push(field[fieldId].equalTo);
                            }
                            fields.push({ [fieldId]: { in: fieldValues } });
                        } else {
                            fields.push({ or: newerFieldFilters[processId].fields[fieldId] });
                        }
                    }
                }
            }
            if (fields.length > 0) {
                fieldFilter.and.push(...fields);
            }
            workflowFilter.filters = fieldFilter;
            workflowFilters.push(workflowFilter);
            fieldFilters.push(fieldFilter);
        });

        localStorage.setItem(`workflowFilters${this.core.network.value._id}`, JSON.stringify([workflowFilters, fieldIds]));
        if (!workflowFilters.length && !fieldIds.length) {
            this.activityFilter.next(null);
            return;
        }
        this.activityFilter.next([workflowFilters, fieldIds]);
    }

    reloadActivities(data: { phase: string; activityIds: string[]; processId?: string }) {
        this.reloadActivitiesSubject.next(data);
    }

    setView(newView: ProcessViewType) {
        this.processCurrentView.next(newView);
    }

    getProcess(processId: string): Observable<Process[]> {
        const process = this.core.processById(processId);

        if (!process) {
            return new Observable(observer => {
                this.core.onAuthenticated(() => {
                    this.rpc
                        .request('process.list', [processId])
                        .pipe(take(1))
                        .subscribe({
                            next: data => {
                                observer.next(data);
                                observer.complete();
                            },
                            error: (error: any) => observer.error(error),
                        });
                });
            });
        }
        return of([process]);
    }

    async getProcessAsync(processId: string): Promise<Process> {
        return new Promise((resolve, reject) => {
            const foundProcess = this.core.processById(processId);

            if (!foundProcess) {
                this.core.onAuthenticated(() => {
                    this.rpc
                        .request('process.list', [processId])
                        .pipe(take(1))
                        .subscribe({
                            next: (data: Process[]) => {
                                const loadedProcess = data.find(process => process._id === processId);
                                resolve(loadedProcess);
                            },
                            error: (error: any) => reject(error),
                        });
                });
            } else {
                resolve(foundProcess);
            }
        });
    }

    async getActivity(activityId: string): Promise<Activity> {
        if (!activityId) {
            return null;
        }
        try {
            const activity = (await this.rpc.requestAsync('activities.load', [activityId])) as any;
            return activity;
        } catch (error) {
            console.error('Failed to fetch an activity', error);
            return null;
        }
    }

    getFieldMap(workflowId: string, workspaceId: string): FieldTypeMap {
        const fieldTypeMap: FieldTypeMap = {};
        const workflow = this.core.processes.value[workspaceId][workflowId];
        if (!workflow) {
            return fieldTypeMap;
        }
        for (const fieldId of workflow.fieldsOrder) {
            fieldTypeMap[fieldId] = workflow?.fields[fieldId]?.type;
        }
        return fieldTypeMap;
    }

    getProcesses(): Observable<Process[]> {
        return new Observable(observer => {
            const processMap = this.core.processes.value[this.core.network.value._id];
            const processArray: Process[] = [];

            if (processMap) {
                for (const processId of Object.keys(processMap)) {
                    processArray.push(processMap[processId]);
                }
            }

            observer.next(processArray);
            observer.complete();
        });
    }

    listActivitiesV2(args1: any, args2: any): Observable<ActivityResult> {
        const sendResult = new Subject<ActivityResult>();

        if (args2?.filter?.dates) {
            args2.filter.dates.start = _moment(args2.filter.dates?.start).valueOf();
            args2.filter.dates.end = _moment(args2.filter.dates?.end).valueOf();
        }

        this.rpc
            .request('v2.activities.list', [args1, args2])
            .pipe(take(1))
            .subscribe({
                next: (result: ActivityResult) => {
                    if (!result.activities) {
                        result.activities = [];
                    }

                    const castActivities: Activity[] = [];
                    // TODO: could this be done more elegantly? Maybe with a reduce?
                    result.activities.forEach(activity => castActivities.push(new Activity(activity)));
                    result.activities = castActivities;

                    const process = this.core.processes.value[this.core.network.value._id][args1.process];
                    result.activities = this.activitiesHelperService.processIncomingDates(result.activities, process);

                    sendResult.next(result);
                    sendResult.complete();
                },
                error: (error: any) => {
                    console.error('listActivitiesByProcessV2 failed: ', error);
                    sendResult.error(error);
                },
            });

        return sendResult.asObservable();
    }

    listTimelineActivities(timeLineArgs: ActivityTimelineArgument): Observable<any> {
        return new Observable<ActivityTimelineResult>(observer => {
            this.rpc
                .request('v2.activities.timeline', [timeLineArgs])
                .pipe(take(1))
                .subscribe({
                    next: (result: ActivityTimelineResult) => {
                        const process = this.core.processById(timeLineArgs.processId);
                        const activities = this.activitiesHelperService.processIncomingActivityEvents(
                            result.activities,
                            process,
                            timeLineArgs.dateFieldId
                        );

                        observer.next({
                            activities,
                            metadata: result.metadata,
                        });
                        observer.complete();
                    },
                    error: error => observer.error(error),
                });
        });
    }

    followActivity(activityId: string): Observable<any> {
        this.followButtonSubject.next();
        return this.rpc.request('activities.follow', [activityId]).pipe(
            map(data => {
                this.activityFollowSubject.next(activityId);
                return data;
            })
        );
    }

    loadActivity(activityId: string): Observable<Activity> {
        return new Observable(observer => {
            this.rpc
                .request('activities.load', [activityId])
                .pipe(take(1))
                .subscribe({
                    next: async (activity: any) => {
                        if (activity === undefined) {
                            observer.next(null);
                        } else {
                            activity = new Activity(activity);
                            let process;
                            try {
                                process = await this.getProcessAsync(activity.process);
                            } catch (err) {
                                return void observer.next(null);
                            }
                            this.activitiesHelperService.processIncomingDates([activity], process);
                            if (activity.files.length > 0 && typeof activity.files[0] === 'object') {
                                activity.files = activity.files.map(({ _id }) => _id);
                            }

                            observer.next(activity);
                        }

                        observer.complete();
                    },
                    error: error => {
                        console.error('Failed to fetch an activity', error);
                        observer.error(error);
                        observer.complete();
                    },
                });
        });
    }

    loadCalendarActivity(activityId: string, dateFieldId: string, processId: string): Observable<CalendarActivity> {
        return new Observable(observer => {
            this.rpc
                .request('activities.load', [activityId])
                .pipe(take(1))
                .subscribe({
                    next: (activity: Activity) => {
                        if (activity === undefined) {
                            observer.error('Activity not found.');
                            observer.complete();
                        } else {
                            const process = this.core.processById(processId);
                            const calendarActivity: CalendarActivity = {
                                active: activity.active,
                                title: activity.name,
                                fields: activity.fields,
                                id: activity._id,
                                allDay: false,
                                classNames: ['activity-event'],
                            };

                            const dates = activity.fields[dateFieldId];
                            if (!dates) {
                                observer.complete();
                            }

                            if (dates?.value) {
                                switch (process.fields[dateFieldId].type) {
                                    case 'daterange':
                                    case 'datetimerange':
                                        calendarActivity.start = dates.value.start;
                                        calendarActivity.end = dates.value.end;
                                        break;
                                    default:
                                        calendarActivity.start = dates.value;
                                        calendarActivity.end = false;
                                        break;
                                }
                            }

                            const activities = this.activitiesHelperService.processIncomingActivityEvents(
                                [calendarActivity],
                                process,
                                dateFieldId
                            );

                            observer.next(activities[0]);
                        }

                        observer.complete();
                    },
                    error: error => {
                        observer.error(error);
                        observer.complete();
                    },
                });
        });
    }

    // TODO: create a model for this return data!
    updateActivities(activityFormData: any[], completeAction: CompleteAction | boolean, process: Process): Observable<any> {
        this.activitiesHelperService.processOutgoingDates(activityFormData, process);
        const updateData = this.activitiesHelperService.constructActivityUpdateData(activityFormData, completeAction);

        return this.rpc.request('activities.update', [updateData]);
    }

    async updatePhasesAndActivityCounts(processId?: string): Promise<PhaseCounts> {
        return new Promise((resolve, reject) => {
            this.rpc
                .request('activities.phase_count', processId ? [processId] : [])
                .pipe(
                    map((data: PhaseCounts) => (data ? data : {})),
                    take(1)
                )
                .subscribe({
                    next: (data: PhaseCounts) => {
                        if (processId) {
                            const process = this.core.processes.value[this.core.network.value._id][processId];
                            if (process) {
                                const phaseActivityCounts = this.phaseCounts;
                                for (const phaseId in process.phases) {
                                    if (!phaseId) {
                                        continue;
                                    }

                                    phaseActivityCounts[phaseId] = data[phaseId] || 0;
                                }

                                this.phaseCounts = phaseActivityCounts;
                            }
                        } else {
                            this.phaseCounts = data;
                        }

                        resolve(this.phaseCounts);
                    },
                    error: (error: any) => {
                        reject(error);
                    },
                });
        });
    }

    // TODO: send here activities
    importActivities(activities: any[], process: Process): Observable<Activity> {
        this.activitiesHelperService.processOutgoingDates(activities, process);
        const createData: ActivityCreateModel[] = [];

        activities.forEach(activityForm => {
            createData.push(this.activitiesHelperService.constructActivityCreateData(activityForm, process));
        });

        return new Observable(observer => {
            this.rpc
                .request('activities.create', [createData])
                .pipe(take(1))
                .subscribe({
                    next: data => {
                        data.saved.forEach(activity => {
                            this.activityCreatedSubject.next(activity);
                        });
                        observer.next(data);
                        observer.complete();
                    },
                    error: err => {
                        observer.error(err);
                    },
                });
        });
    }

    readonly defaultActivityImage = '/assets/img/hailer_corner4.svg';

    /**
     * Get Linkable Processes by process id
     *
     * Returns all processes to which process can be linked from its initial phase.
     * Add { onlyInitialPhase: true } to only get linkable processes for initial phase. Defaults to all linkable processes.
     */
    getLinkableProcesses(
        processToLinkToId: string,
        options: { onlyInitialPhase: boolean } = { onlyInitialPhase: false }
    ): Observable<CanBeLinkedFrom[]> {
        return new Observable(observer => {
            this.core.processes
                .pipe(
                    map(processMap => processMap[this.core.network.value._id]),
                    take(1)
                )
                .subscribe({
                    next: processesMap => {
                        const canBeLinkedFromProcesses: any[] = [];
                        const processDatasetPipe = new ProcessDatasetPipe();
                        let processes: Process[] = [];

                        if (processesMap) {
                            Object.keys(processesMap).forEach(id => [processes.push(processesMap[id])]);
                        }

                        // Arrange workflows and datasets to the process list order
                        let workflows = [];
                        let datasets = [];
                        workflows = processDatasetPipe.transform(processes, { datasetOnly: false });
                        datasets = processDatasetPipe.transform(processes, { datasetOnly: true });
                        processes = [];

                        workflows.forEach(workflow => {
                            processes.push(workflow);
                        });

                        datasets.forEach(dataset => {
                            processes.push(dataset);
                        });

                        if (processes) {
                            processes.forEach(process => {
                                Array.from(Object.values(process.fields)).forEach(field => {
                                    if (
                                        field.type === 'activitylink' &&
                                        Array.isArray(field.data) &&
                                        field.data.find(_id => _id === processToLinkToId)
                                    ) {
                                        if (
                                            !options.onlyInitialPhase ||
                                            process.enableUnlinkedMode ||
                                            process.phases[process.phasesOrder[0]].fields.includes(field._id)
                                        ) {
                                            canBeLinkedFromProcesses.push({ process, field });
                                        }
                                    }
                                });
                            });
                        }

                        observer.next(canBeLinkedFromProcesses);
                        observer.complete();
                    },
                    error: (error: any) => observer.error(error),
                });
        });
    }

    loadActivityEvents(options: any): Observable<any[]> {
        const results = new Subject<any[]>();
        const request = 'v3.activity.events';

        this.rpc.request(request, options).subscribe({
            next: (_events: any[]) => {
                const events: any[] = [];
                for (const event of _events) {
                    event.start = this.activitiesHelperService.translateMillisecondsToLocalDate(event.start, event.allDay);

                    if (event.allDay && event.end) {
                        event.end = this.activitiesHelperService.translateMillisecondsToLocalDate(event.end, true);
                        event.end = moment(event.end).add(1, 'days').toISOString();
                    }

                    event.resourceId = event.id;

                    const activityEvent: any = {
                        classNames: ['activity-event'],
                        editable: false,
                    };

                    Object.assign(activityEvent, event);
                    events.push(activityEvent);
                }
                results.next(events);
                results.complete();
            },
            error: err => {
                results.next([]);
                results.complete();
            },
        });

        return results.asObservable();
    }

    calendarEnabled(process: Process, phaseId: string) {
        return (
            !!process.phases[phaseId]?.primaryDateField &&
            Object.keys(process.fields).find(objectId => objectId === process.phases[phaseId]?.primaryDateField)
        );
    }

    fetchFilter() {
        const loadedFilter = localStorage.getItem(`workflowFilters${this.core.network.value._id}`);
        if (loadedFilter) {
            this.activityFilter.next(JSON.parse(loadedFilter));
        }
    }

    private init() {
        this.activityCreated$ = this.activityCreatedSubject.asObservable();
        this.activityFollow$ = this.activityFollowSubject.asObservable();
        this.followButton$ = this.followButtonSubject.asObservable();
        this.followSignal$ = this.followSignalSubject.asObservable();
        this.processActivities$ = this.processActivitiesSubject.asObservable();
        this.reloadActivities$ = this.reloadActivitiesSubject.asObservable();
        this.fetchFilter();
    }

    private subscribeActivitiesCreated() {
        this.rpc.subscribe(CoreSignal.ActivitiesCreated, async (meta: any) => {
            if (!meta) {
                console.error('No meta provided with the activities.created signal');
                return;
            }

            const workflow = meta.process ? this.core.processById(meta.process) : this.core.processFromPhaseId(meta.phase);

            if (!workflow) {
                console.warn('Unable to determine workflow from activities.created signal meta');
                return;
            }

            if (workflow.cid === this.core.network.value._id) {
                const activityIds = Array.isArray(meta.activity_id) ? meta.activity_id : [meta.activity_id];
                this.reloadActivitiesSubject.next({ phase: meta.phase, activityIds, processId: workflow._id });
            }
        });
    }

    private subscribeActivitiesFollow() {
        this.rpc.subscribe('activities.follow', (meta: any) => {
            if (meta) {
                this.activityFollowSubject.next(meta.activity_id);
                this.followSignalSubject.next(meta);
            }
        });
    }

    private subscribeActivitiesUpdated() {
        this.rpc.subscribe(CoreSignal.ActivitiesUpdated, async (meta: any) => {
            if (!meta) {
                console.error('No meta provided with the activities.updated signal');
                return;
            }

            const currentNetworkId = this.core.network.value._id;

            const workflow = meta.processId ? this.core.processById(meta.processId) : this.core.processFromPhaseId(meta.phase);
            if (!workflow) {
                console.warn('Unable to determine workflow from activities.updated signal meta');
                return;
            }

            const activityFromCurrentNetwork = workflow.cid === currentNetworkId;
            if (activityFromCurrentNetwork) {
                const activityIds = Array.isArray(meta.activity_id) ? meta.activity_id : [meta.activity_id];

                this.reloadActivitiesSubject.next({
                    phase: meta.phase,
                    activityIds,
                    removed: meta.removed || false,
                    processId: workflow._id,
                });
            }
        });
    }
}
