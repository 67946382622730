/** @format */

import { Phase } from './phase.model';
import { Deserializable } from './deserializable.model';
import { TeamAccount } from './teamAccount.model';
import { Member } from './member.model';

export type ProcessFieldType =
    | 'activitylink'
    | 'contacts'
    | 'country'
    | 'date'
    | 'daterange'
    | 'datetime'
    | 'datetimerange'
    | 'linkedfrom'
    | 'numeric'
    | 'numericunit'
    | 'subheader'
    | 'teams'
    | 'text'
    | 'textarea'
    | 'textpredefinedoptions'
    | 'textunit'
    | 'time'
    | 'timerange'
    | 'users';
export interface ProcessDefaultView {
    name: string;
    value: ProcessViewType;
}

export type ProcessViewType = 'calendar' | 'kanban' | 'map' | 'table' | 'timeline';

export class ProcessFieldMap {
    [fieldId: string]: ProcessField;
}

export class PhaseMap {
    [phaseId: string]: Phase;
}

export interface WorkflowFieldModifier {
    checkbox?: boolean;
    file?: boolean;
}
export class ProcessField {
    data: string[];
    label: string;
    placeholder: string;
    required: boolean;
    type: ProcessFieldType;
    unit: string;
    validate: boolean;
    defaultTo?: boolean;
    defaultValue?: any;
    created?: string;
    updated?: string;
    uid?: string;
    _id: string;
    functionEnabled?: boolean;
    function?: string;
    functionVariables?: any;
    description?: string;
    inviteToDiscussionOnChange: boolean;
    editable: boolean;
    collapsedByDefault: boolean;
    reminderEnabled?: boolean;
    reminderSettings?: reminderSettings;
    modifier?: WorkflowFieldModifier;
    /** User given name for field, used for integrations etc, in place of globally unique field id. */
    key?: string;
}

export interface reminderSettings {
    users: string[];
    teams: string[];
    fields: string[];
    phases: string[];
    activityOwner: boolean;
    activityOwnerTeam: boolean;
    activityParticipants: boolean;
    reminderText: string;
    fieldOffset: reminderFieldOffset;
}

export interface reminderFieldOffset {
    milliseconds: number;
    days: number;
    months: number;
    beforeOrAfter: 'after' | 'before';
    startOrEnd: 'end' | 'start';
}

export interface ActivityAdd {
    action: string;
    processId: string;
    createdFromDiscussions: boolean;
    createdFromEvents: boolean;
    initFieldValues?: any;
    phaseId?: string;
    discussionInfo?: DiscussionInfo;
    upgrade?: boolean;
}

export class DiscussionInfo {
    id: string;
    name: string;
    followers: string[];
}

export interface PublicForm {
    active: boolean;
    creator: string;
    followers: string;
    team_account: TeamAccount;
    key?: string;
    allowRead?: boolean;
    enableMessaging?: boolean;
    theme?: {
        foregroundColor: string;
        backgroundColor: string;
        complementaryColor: string;
        textColor: string;
        logoId: string;
        headerColor: string;
    };
}

export interface ProcessTranslations {
    [languages: string]: {
        name?: string;
        fields?: FieldTranslations;
        nameFieldPlaceHolderText?: string;
        phases?: PhaseTranslations;
        description?: string;
        nameColumnText?: string;
        predefinedNamePrefix?: string;
        createNewLabel?: string;
        personInChargeLabel?: string;
        initPhaseDescription?: string;
    };
}

export interface FieldTranslations {
    [fieldId: string]: {
        label?: string;
        unit?: string;
        placeholder?: string;
        description?: string;
    };
}

export interface PhaseTranslations {
    [phaseId: string]: {
        name?: string;
        description?: string;
    };
}

export class Process implements Deserializable {
    cid: string;
    defaultView: ProcessViewType;
    description: string;
    enableAddedField: boolean;
    enableAttachments: boolean;
    fields: {
        [fieldId: string]: ProcessField;
    };
    fieldsOrder: string[];
    members: Member[];
    name: string;
    nameFieldPlaceHolderText: string;
    phases: PhaseMap;
    phasesOrder: string[];
    phasesRemoved: string[];
    updated: number;
    _id: string;

    allowCustomTags?: boolean;
    allowMultipleTags?: boolean;
    coverImage?: string;
    createNewLabel?: string;
    documentTemplates?: DocumentTemplate[];
    enableLinkedAnnouncements?: boolean;
    enableMapLocation?: boolean;
    enableMessenger?: boolean;
    enableModifiedField?: boolean;
    enableOwnerField?: boolean;
    enableOwnerTeamField?: boolean;
    ownerTeamFieldTitle?: string;
    enablePredefinedName?: boolean;
    enablePreselectedTeam?: boolean;
    enableUniqueName?: boolean;
    /** True when process is a dataset */
    enableUnlinkedMode?: boolean;
    initPhaseDescription?: string;
    locationRequired?: boolean;
    nameColumnText?: string;
    nameFunction?: string;
    nameFunctionEnabled?: boolean;
    nameFunctionVariables?: boolean;
    nameEditable?: boolean;
    order?: number;
    personInCharge?: string;
    personInChargeLabel?: string;
    predefinedNamePrefix?: string;
    preselectedTeam?: TeamAccount;
    publicForm?: PublicForm;
    requireFileTag?: boolean;
    defaultGroupByField?: string;
    googleDriveId?: string;
    googleFolderId?: string;
    color?: string;
    allowGuests?: boolean;
    enableGuestEditing?: boolean;
    isStarred?: boolean;
    translations: ProcessTranslations;
    inviteActivityCreator?: boolean;
    productInstalledId?: string;
    groupNames?: string[];

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export interface DocumentTemplate {
    name: string;
    templateId: string;
    order: number;
    fieldMap: {
        images: any;
        fields: any;
        staticActivityIds: any;
        activityLinks: any;
    };
    opts: any;
}

export interface ProcessMap {
    [cid: string]: {
        [id: string]: Process;
    };
}

export interface ProcessIdMap {
    [cid: string]: {
        [id: string]: string;
    };
}

export interface CanBeLinkedFrom {
    field: ProcessField;
    process: Process;
}

export type ProcessEditorViews = 'fields' | 'files' | 'general' | 'permissions' | 'phases' | 'social' | 'templates' | 'visuals';
