<mat-form-field class="dropdown-form-field" [appearance]="(options | async)?.appearance">
    <mat-label *ngIf="selectedItemsLength">
        <ng-container *ngFor="let item of selectedItemsValues; let i = index">
            <span [style.color]="(options | async)?.customColors?.selectedItems | hailerColor" data-cy="dropdown-selected-label"
                *ngIf="item">
                {{ item?.title }}{{ i + 1 === selectedItemsLength ? '' : ',' }}
            </span>
        </ng-container>
    </mat-label>
    <input *ngIf="(options | async)?.openAutomatically" cdkFocusInitial matInput type="search" #itemInput
        [placeholder]="(options | async)?.placeholder" [formControl]="itemControl" [matAutocomplete]="itemAutocomplete" />
    <input *ngIf="!(options | async)?.openAutomatically" matInput type="search" #itemInput [placeholder]="(options | async)?.placeholder"
        [formControl]="itemControl" [matAutocomplete]="itemAutocomplete" />
    <mat-error *ngIf="itemControl.invalid && (options | async)?.errorMessage">{{
        'shared.dropdown-selector.field_required_error' | transloco
        }}</mat-error>
    <button mat-icon-button matSuffix (click)="toggleAutocomplete(); $event.stopPropagation()"
        class="dropdown-arrow dropdown-trigger-transition" [style]="getDropdownTriggerOrientation()">
        <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-autocomplete #itemAutocomplete class="dropdown-autocomplete" (optionSelected)="toggleSelection($event.option.id)"
        (opened)="realignInkbar()">
        <mat-tab-group #tabGroup class="dropdown-tab-group" [ngClass]="onlyTab ? 'hide-tab-group-header' : ''" [animationDuration]="0">
            <mat-tab *ngFor="let group of filteredItemGroups | async">
                <ng-template mat-tab-label>
                    <ng-container *ngIf="!getGroupInfo(group)?.icon?.includes('hailer-'); else svgIcon">
                        <mat-icon class="dropdown-tab-icon" [matBadge]="group?.length" matBadgeSize="small" matBadgeColor="primary"
                            [tippy]="getGroupInfo(group)?.title">{{ getGroupInfo(group)?.icon }}</mat-icon>
                    </ng-container>
                    <ng-template #svgIcon>
                        <mat-icon class="dropdown-tab-icon-line" [svgIcon]="getGroupInfo(group)?.icon" [matBadge]="group?.length"
                            matBadgeSize="small" matBadgeColor="primary" [tippy]="getGroupInfo(group)?.title"></mat-icon>
                    </ng-template>
                </ng-template>
                <cdk-virtual-scroll-viewport [itemSize]="40" [minBufferPx]="40 * 6" [maxBufferPx]="40 * 12"
                    [style.height.px]="getVirtualScrollHeight(group?.length)" class="dropdown-virtual-scroll">
                    <ng-container *cdkVirtualFor="let itemId of group">
                        <mat-option [id]="itemId" [value]="getTitle(itemId)" class="dropdown-option" [disabled]="itemDisabled(itemId)"
                            [ngClass]="itemSelected(itemId) && !(options | async)?.multiple ? 'selected-item-background' : ''"
                            appDropdownPreventClosing>
                            <div data-cy="dropdown-option-container" class="dropdown-opt-container" (appDropdownPreventClosing)="(true)">
                                <ng-container *ngIf="(options | async)?.multiple; else singleOption">
                                    <mat-checkbox class="dropdown-checkbox full-width checkbox-thin" [checked]="itemSelected(itemId)"
                                        (change)="checkboxSelectionChange($event, itemId)">
                                        <app-dropdown-option-content [item]="getItem(itemId)" [onlySelector]="options.value.onlySelector"
                                            (onlyItem)="onlyItem(itemId)" class="dropdown-content"></app-dropdown-option-content>
                                    </mat-checkbox>
                                </ng-container>
                                <ng-template #singleOption>
                                    <div class="single-option-container">
                                        <app-dropdown-option-content [item]="getItem(itemId)" class="dropdown-content"
                                            (click)="selectItem(itemId)">
                                        </app-dropdown-option-content>
                                        <button data-cy="remove-selection-button" *ngIf="itemSelected(itemId) && !(options | async)?.hideClearButton"
                                            class="remove-selection-button no-button for-icon" (click)="removeSelection(itemId)">
                                            <mat-icon svgIcon="hailer-x"></mat-icon>
                                        </button>
                                    </div>
                                </ng-template>
                            </div>
                        </mat-option>
                    </ng-container>
                </cdk-virtual-scroll-viewport>
            </mat-tab>
        </mat-tab-group>
    </mat-autocomplete>
</mat-form-field>