<div class="topbar-container" data-cy="function-field-editor" >
    <h3 mat-dialog-title>{{ process?.name}} / {{fieldName}} <i>({{field?.type}})</i></h3>
</div>

<div class="content-container">
    <div *ngIf="page === 'saving'" class="flexbox-column">
        <mat-spinner style="align-self: center; margin: 16px;" [diameter]="40"></mat-spinner>
        <h4>{{'activities.function-editor-dialog.save_and_refresh' | transloco}}</h4>

        <p>{{'activities.function-editor-dialog.time_notice' | transloco}}</p>

        <p>{{'activities.function-editor-dialog.wait_or_close' | transloco}}</p>
    </div>

    <div mat-dialog-content *ngIf="error" class="flexbox-column" data-cy="function-editor-error-content">
        {{ error }}
    </div>

    <div *ngIf="page !== 'saving'" class="flexbox-column">

        <div mat-dialog-content *ngIf="!error && field">
            <div class="container" style="height: 100%;">
                <div class="flexbox-column" style="overflow: auto; padding-right: 16px; margin-right: 16px;">

                    <mat-accordion>
                        <mat-expansion-panel expanded="true" #depExpansion="matExpansionPanel" class="dependencies-panel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="accordion-title">
                                        <p style="font-size: 16px;"><b>{{'activities.function-editor-dialog.dependencies' | transloco}}</b></p>
                                        <button data-cy="new-dependency-button" mat-button color="accent" (click)="$event.stopPropagation()" [matMenuTriggerFor]="newDepMenu">
                                            <mat-icon style="margin-right: 4px;">add</mat-icon>{{'activities.function-editor-dialog.new_dependency' | transloco}}<mat-icon>arrow_drop_down</mat-icon>
                                        </button>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div cdkDropList class="depencency-list" (cdkDropListDropped)="drop($event)">
                                <div class="dependency-row fill-field-container" *ngFor="let dep of dependencies" cdkDrag>
                                    <div>
                                        <mat-form-field appearance="fill">
                                            <input
                                                data-cy="dependency-input"
                                                matInput
                                                type="text"
                                                value="{{ dep.label }}"
                                                (blur)="setVariable(dep, $event.target.value)"
                                                (keyup.enter)="setVariable(dep, $event.target.value)" />
                                        </mat-form-field>
                                        <p *ngIf="!isBadDependency(dep.value)">{{getDependencyLabels(dep.value)}}</p>
                                        <mat-icon
                                            *ngIf="isBadDependency(dep.value)"
                                            svgIcon="hailer-warning-fill"
                                            class="warning-icon"></mat-icon>
                                        <p *ngIf="isBadDependency(dep.value)" class="bold" [tippy]="getDependencyLabels(dep.value)">
                                            {{ 'activities.function-editor-dialog.bad_dependency' | transloco }}
                                        </p>
                                    </div>
                                    <button mat-icon-button (click)="removeDependency(dep)">
                                        <mat-icon svgIcon="hailer-x"></mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <div class="container align-items-center flex-row--space-between">
                        <h4>{{'activities.function-editor-dialog.function' | transloco}}</h4>
                        <app-button
                            type="text-with-icon"
                            hailerIconName="hailer-video-fill"
                            (clickEvent)="evaluate(activityId, fieldId, code)"
                            [isDisabled]="!code"
                            [label]="'activities.function-editor-dialog.run' | transloco"
                            [ariaLabel]="'activities.function-editor-dialog.run' | transloco"></app-button>
                    </div>
                    <div style="height: 100%; max-height: 60%; margin-bottom: 8px;">
                        <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="code"></ngx-monaco-editor>
                    </div>

                    <div>
                        <div *ngIf="result && templateField">
                            <h4>{{'activities.function-editor-dialog.output' | transloco}}</h4>
                            <app-v3-activity-field [field]="templateField"></app-v3-activity-field>
                        </div>
                    </div>
                </div>

                <div style="width: 40%; padding-right: 16px; overflow: auto;">
                    <mat-tab-group>
                        <mat-tab label="{{'activities.function-editor-dialog.evaluation_context' | transloco}}">
                            <ng-container *ngIf="result">
                                <br>
                                <div class="container align-items-center flex-row--space-between">
                                    {{'activities.function-editor-dialog.dependencies' | transloco}}
                                    <button mat-icon-button (click)="copyToClipboard(evaluationDependencies)" tippy="{{'activities.function-editor-dialog.copy_dependency_json' | transloco}}">
                                        <mat-icon svgIcon="hailer-copy"></mat-icon>
                                    </button>
                                </div>
                                <ngx-json-viewer [expanded]="true" [json]="evaluationDependencies"></ngx-json-viewer>
                                {{'activities.function-editor-dialog.additional_context' | transloco}}
                                <ngx-json-viewer [expanded]="false" [json]="additionalEvaluationContext"></ngx-json-viewer>
                            </ng-container>

                            <span *ngIf="!result" class="run-function-text">{{'activities.function-editor-dialog.run_function_text' | transloco}}</span>
                        </mat-tab>

                        <mat-tab *ngIf="activity && process" label="{{'activities.function-editor-dialog.activity_overview' | transloco}}">
                            <app-v3-activity-detail></app-v3-activity-detail>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>

        <div *ngIf="!field">{{'activities.function-editor-dialog.no_field_set' | transloco}}</div>
        <div mat-dialog-actions class="popup-two-buttons-wrapper">
            <app-button
                dataCy="cancel-button"
                color="tertiary-default-background"
                width="popup-two-buttons"
                [mat-dialog-close]="{ saved: false }"
                [label]="'activities.function-editor-dialog.cancel' | transloco"
                [ariaLabel]="'activities.function-editor-dialog.cancel' | transloco"></app-button>
            <app-button
                *ngIf="!error"
                dataCy="save-changes-button"
                width="popup-two-buttons"
                [isDisabled]="!code"
                (clickEvent)="saveFunction(code, dependencies)"
                cdkFocusInitial
                [label]="'activities.function-editor-dialog.save_changes' | transloco"
                [ariaLabel]="'activities.function-editor-dialog.save_changes' | transloco"></app-button>
            <app-button
                *ngIf="page === 'error' && error"
                dataCy="error-ok-button"
                color="secondary"
                width="popup-two-buttons"
                (clickEvent)="error = ''; page = 'edit';"
                [label]="'activities.function-editor-dialog.ok' | transloco"
                [ariaLabel]="'activities.function-editor-dialog.ok' | transloco"></app-button>
        </div>
    </div>

    <div *ngIf="page === 'saving'" class="flexbox-column">
        <div mat-dialog-actions class="popup-single-button-wrapper">
            <app-button
                dataCy="continue-working-button"
                color="secondary"
                width="popup-single-button"
                [mat-dialog-close]="{ saved: false }"
                [label]="'activities.function-editor-dialog.continue_working' | transloco"
                [ariaLabel]="'activities.function-editor-dialog.continue_working' | transloco"></app-button>
        </div>
    </div>

    <mat-menu #newDepMenu="matMenu">
        <button data-cy="activity-field-option" mat-menu-item [matMenuTriggerFor]="activityMenu">
            <span>{{'activities.function-editor-dialog.activity_field' | transloco}}</span>
        </button>

        <button *ngIf="linkedToFields.length > 0" mat-menu-item [matMenuTriggerFor]="linkedToMenu">
            <span>{{'activities.function-editor-dialog.linkedto_field' | transloco}}</span>
        </button>

        <button *ngIf="linkedFromFields.length > 0" mat-menu-item [matMenuTriggerFor]="linkedFromMenu">
            <span>{{'activities.function-editor-dialog.linkedfrom_field' | transloco}}</span>
        </button>

        <button *ngIf="staticIds.length > 0" mat-menu-item [matMenuTriggerFor]="staticMenu">
            <span>{{'activities.function-editor-dialog.static_ids' | transloco}}</span>
        </button>

        <mat-menu #activityMenu="matMenu">
            <button *ngFor="let field of activityFields" mat-menu-item (click)="addDependency(field.label, field.value)">
                <span data-cy="field-label">{{field.label}}</span>
            </button>
        </mat-menu>

        <mat-menu #linkedToMenu="matMenu">
            <ng-container *ngFor="let linkedfield of linkedToFields">
                <button *ngIf="isString(linkedfield.value)" mat-menu-item (click)="addDependency(linkedfield.label, linkedfield.value)">
                    <span>{{linkedfield.label}}</span>
                </button>
                <button *ngIf="!isString(linkedfield.value)" mat-menu-item [matMenuTriggerFor]="linkedToSubMenu">
                    <span>{{linkedfield.label}}</span>
                </button>
                <mat-menu #linkedToSubMenu="matMenu">
                    <button *ngFor="let field of linkedfield.value" mat-menu-item (click)="addDependency(field.label, field.value)">
                        <span>{{field.label}}</span>
                    </button>
                </mat-menu>
            </ng-container>
        </mat-menu>

        <mat-menu #linkedFromMenu="matMenu">
            <ng-container *ngFor="let linkedfield of linkedFromFields">
                <button mat-menu-item [matMenuTriggerFor]="linkedFromSubMenu">
                    <span>{{linkedfield.label}}</span>
                </button>
                <mat-menu #linkedFromSubMenu="matMenu">
                    <button *ngFor="let field of linkedfield.value" mat-menu-item (click)="addDependency(field.label, field.value)">
                        <span>{{field.label}}</span>
                    </button>
                </mat-menu>
            </ng-container>
        </mat-menu>

        <mat-menu #staticMenu="matMenu">
            <ng-container *ngFor="let staticId of staticIds">
                <button mat-menu-item [matMenuTriggerFor]="linkedFromSubMenu">
                    <span>{{staticId.label}}</span>
                </button>
                <mat-menu #linkedFromSubMenu="matMenu">
                    <button *ngFor="let field of staticId.value" mat-menu-item (click)="addDependency(field.label, field.value)">
                        <span>{{field.label}}</span>
                    </button>
                </mat-menu>
            </ng-container>
        </mat-menu>
    </mat-menu>
</div>
