/** @format */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { environment } from '@app/env';
import { DiscussionMap, File, Message, ReactionTypes } from '@app/models';
import { PeopleService } from 'app/people/people.service';
import { SelectedDiscussionService } from 'app/_services/selected-discussion.service';
import { HtmlUnescapePipe } from 'app/pipes/src/html-unescape.pipe';
import { WindowListenerService } from '../../_services/window-listener.service';
import { CoreService, CoreStore } from 'app/_services/core.service';
import { Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import emojiRegex from 'emoji-regex';
import { RPCService } from 'app/_services/rpc.service';
import { ThemeService } from 'app/theme/theme.service';

@Component({
    selector: 'app-message-user',
    templateUrl: './message-user.component.html',
    styleUrls: ['./message-user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'discussion', alias: 'discussion' } }],
})
export class MessageUserComponent implements OnInit, OnDestroy {
    @Input() message: Message;
    @Output() reactToMessage = new EventEmitter<{ message: Message; reaction: ReactionTypes }>();
    @Output() starMessage = new EventEmitter<{ message: Message }>();
    @ViewChild('editTextarea') editTextarea: ElementRef;

    editingMessage = this.selectedDiscussion.editingMessage;
    participants = this.selectedDiscussion.participants;
    participantColorMap = this.selectedDiscussion.participantColorMap;
    discussion = this.selectedDiscussion.discussion;
    me = this.people.user;
    screenWidth = window.innerWidth;
    discussions: Observable<DiscussionMap> = this.store.select(state => state.discussions);

    private onDestroy = new Subject<void>();

    constructor(
        public theme: ThemeService,
        public selectedDiscussion: SelectedDiscussionService,
        private people: PeopleService,
        private rpc: RPCService,
        private cdr: ChangeDetectorRef,
        private windowListener: WindowListenerService,
        private core: CoreService,
        private store: Store<CoreStore>,
        private snackbar: MatSnackBar
    ) {}

    ngOnInit(): void {
        combineLatest([this.editingMessage, this.discussion, this.me, this.core.users])
            .pipe(takeUntil(this.onDestroy), debounceTime(100))
            .subscribe({
                next: () => this.cdr.detectChanges(),
            });

        this.core.state
            .pipe(
                takeUntil(this.onDestroy),
                filter(state => state === 'authenticated')
            )
            .subscribe({
                next: () => this.cdr.detectChanges(),
            });

        this.windowListener.size.pipe(takeUntil(this.onDestroy), debounceTime(100)).subscribe({
            next: ({ x }) => {
                this.screenWidth = x;
                this.cdr.detectChanges();
            },
        });

        this.selectedDiscussion.focusEditTextarea.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            if (this.editTextarea?.nativeElement) {
                this.editTextarea.nativeElement.focus();
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    /** Check if message is one emoji and make it big */
    checkForEmoji(message: Message) {
        const regex = emojiRegex();

        if (message.msg) {
            const msg = message.msg;
            return this.countCharacters(msg) && msg.match(regex);
        }
    }

    getProfilePictureURL(uid: string): string {
        const baseUrl = `${environment.wsUrl}/image/square100/`;

        if (this.participants.value[uid]) {
            return baseUrl + this.participants.value[uid].default_profilepic;
        }

        return baseUrl + this.people.getUser(uid).default_profilepic;
    }

    focusToMessage(message: Message) {
        /* If message is forwarded we check if the discussion is in user's discussions
         ** and if it is we focus to that message or send and error */
        if (message.forwardMessage && !message.replyMessage) {
            this.discussions.pipe(take(1)).subscribe({
                next: discussions => {
                    if (discussions[message.forwardMessage.discussion]) {
                        this.selectedDiscussion.focusToMessage(message.forwardMessage, false, this.selectedDiscussion.discussion.value._id);
                        return;
                    }
                    this.snackbar.open('This message is from a discussion you are not in', 'Close', { duration: 3000 });
                },
                error: error => console.error('Could not check for discussions:', error),
            });
            return;
        }
        // If message is a reply we focus to that message
        this.selectedDiscussion.focusToMessage(message.replyMessage);
    }

    saveEditsDisabled(message: Message): boolean {
        const editedMessage = this.editingMessage.value[message._id].editMessageForm.value;
        return !editedMessage || message.msg === editedMessage;
    }

    stopEditing() {
        this.editingMessage.next({});
    }

    saveEdits(message: Message) {
        if (!this.editingMessage.value) {
            return;
        }

        let msg = new HtmlUnescapePipe().transform(this.editingMessage.value[message._id].editMessageForm.value);
        msg = msg.replace(/<br\s*\/?>/gm, '\n');

        this.rpc.request('v2.discussion.message.edit', [message.discussion, message._id, msg])
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                error: error => console.error('Failed to edit a message: ', error),
            });
        this.editingMessage.next({});
    }

    getUsernames(userIds: string[]): string {
        return userIds.map(uid => this.participants[uid]?.display_name || this.people.getUser(uid)?.display_name).join(', ');
    }

    openUser(userId: string) {
        this.selectedDiscussion.openUser(userId);
    }

    hasReactions() {
        if (this.message.reactions) {
            let hasReactions = false;
            for (const reactionType of Object.keys(this.message.reactions)) {
                if (this.message.reactions[reactionType].length > 0) {
                    hasReactions = true;
                    break;
                }
            }
            return hasReactions;
        }
        return false;
    }

    // Setting the cursor at the end of text
    onEditTextareaFocus(): void {
        const editTextareaElement = this.editTextarea.nativeElement;

        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(editTextareaElement);
        // Collapse the range to its end, placing the cursor at the end
        range.collapse(false);
        // Clear any existing user selection
        selection?.removeAllRanges();
        // Add the range to the selection
        selection?.addRange(range);
    }

    get canShowProfilePicture(): boolean {
        const notMe = this.message.uid !== this.me.value?.id;
        const privateDiscussion = this.discussion.value?.private;
        const isConsecutive = this.message.isConsecutive;
        const inMobileView = this.screenWidth <= 600;

        return isConsecutive ? false : (inMobileView && notMe && !privateDiscussion) || !inMobileView;
    }

    get inMobileDiscussionView(): boolean {
        return window.innerWidth <= 600 && !this.sidenavMode;
    }

    get sidenavMode(): boolean {
        return this.selectedDiscussion.sidenavMode;
    }

    filesMap(fileArray: File[]): { [fileId: string]: File } {
        const output: { [fileId: string]: File } = {};

        for (const file of fileArray) {
            if (!file._id) {
                continue;
            }

            output[file._id] = file;
        }

        return output;
    }

    private countCharacters(str: string) {
        const joiner = '\u{200D}';
        const split = str.split(joiner);
        let count = 0;

        for (const s of split) {
            const num = Array.from(s.split(/[\ufe00-\ufe0f]/).join('')).length;
            count += num;
        }

        if (count / split.length <= 2 && count / split.length >= 0) {
            return true;
        }
        return false;
    }
}
