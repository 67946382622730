/** @format */

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root',
})
export class SnackBarService {
    constructor(private snackBar: MatSnackBar, private translocoService: TranslocoService) {}

    openActivityCopySnackBar(activityCount: number, isDataset?: boolean) {
        const prefix =
            activityCount > 1
                ? activityCount + this.translocoService.translate('misc.services.snack-bar.activities')
                : this.translocoService.translate('misc.services.snack-bar.activity');

        const suffix = isDataset
            ? this.translocoService.translate('misc.services.snack-bar.copied_into_current_phase')
            : this.translocoService.translate('misc.services.snack-bar.copied_into_initial_phase');

        this.snackBar.open(`${prefix} ${suffix}`, this.translocoService.translate('misc.services.snack-bar.ok'), { duration: 2500 });
    }
}
