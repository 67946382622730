/** @format */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FeedPostDetailResolverService } from 'app/_services/feed-post-detail-resolver.service';
import { FeedComponent } from './feed/feed.component';

const routes: Routes = [
    {
        path: '',
        component: FeedComponent,
        children: [
            {
                path: ':postId',
                component: FeedComponent,
                resolve: {
                    post: FeedPostDetailResolverService,
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FeedRoutingModule {}
