/** @format */

import { ElementRef, Injectable, NgZone, QueryList } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, lastValueFrom } from 'rxjs';
import { take, timeout } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

import { Activity, ActivityField, CanBeLinkedFrom, Discussion, Event, Message, Process, ProcessField, UserMap } from '@app/models';
import { UserInviteDialogComponent } from '@app/shared/user-invite-dialog/user-invite-dialog.component';
import { ActivitiesService } from 'app/activities/activities.service';
import { GroupDiscussionDetailSidenavComponent } from 'app/discussion/group-discussion-detail-sidenav/group-discussion-detail-sidenav.component';
import { EventSidenavComponent } from 'app/events-shared/event-sidenav/event-sidenav.component';
import { EventsService } from 'app/events/events.service';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';
import { PeopleService } from 'app/people/people.service';
import { loadLatestMessages, loadNextMessages, loadPreviousMessages } from 'app/redux/actions/message.actions';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { V3DiscussionService } from './v3-discussion.service';
import { CoreService, CoreStore } from './core.service';
import { PermissionService } from './permission.service';
import { RoutingService } from './routing.service';
import { SideNavService } from './side-nav.service';
import { V3ActivitySidenavComponent } from 'app/v3-activity/v3-activity-sidenav/v3-activity-sidenav.component';
import { ThemeService } from 'app/theme/theme.service';
import { RPCService } from './rpc.service';
import { LinkCreatedSignalItem, LinkDataMap } from '../../../test/deps/hailer-api/shared/link-types';

@Injectable()
export class SelectedDiscussionService {
    messages = new BehaviorSubject<Message[]>([]);
    participants = new BehaviorSubject<UserMap>({});
    discussion = new BehaviorSubject<Discussion>(null);

    canBeLinkedFromProcesses = new BehaviorSubject<CanBeLinkedFrom[]>([]);

    dragging = new BehaviorSubject<boolean>(false);
    participantColorMap = new BehaviorSubject<ParticipantColorMap>({});
    scrollTo = new Subject<'jumpToBottom' | 'jumpToSentMessage' | 'scrollToBottom'>();
    focusMessage = new Subject<{ message: Message; skipAnimation?: boolean }>();
    discussionChanged = new Subject<string>();
    openContextMenu = new Subject<{ event: MouseEvent; message: Message }>();
    closeContextMenu = new Subject<void>();
    saveScrollPosition = new Subject<void>();
    focusing = new BehaviorSubject<boolean>(false);
    sidenavMode = false;
    newContact = new BehaviorSubject<string>(null);
    newestLoaded = new BehaviorSubject<boolean>(false);
    oldestLoaded = new BehaviorSubject<boolean>(false);
    loadCount: number;
    contextMenuMessage = new BehaviorSubject<Message>(null);
    scrolling = new BehaviorSubject<boolean>(false);
    scrollIsAtBottom: boolean;
    loadingMessages: boolean;
    messagesMap: Observable<any> = this.store.select(state => state.messages);
    scrollToMessage: HTMLElement;
    scrollToPreviousPosition: number;
    editingMessage = new BehaviorSubject<{
        [messageId: string]: {
            editing: boolean;
            editMessageForm: UntypedFormControl;
        };
    }>({});
    focusMessageInput = new Subject<void>();
    focusEditTextarea = new Subject<void>();

    private newestLoadedMessage: Message;
    private oldestLoadedMessage: Message;

    constructor(
        private zone: NgZone,
        private v3discussion: V3DiscussionService,
        private sideNav: SideNavService,
        private core: CoreService,
        private people: PeopleService,
        private theme: ThemeService,
        private activities: ActivitiesService,
        private permission: PermissionService,
        private events: EventsService,
        private matDialog: MatDialog,
        private store: Store<CoreStore>,
        private dialogHelper: DialogHelperService,
        private routerService: RoutingService,
        private translocoService: TranslocoService,
        private rpc: RPCService,
    ) {
        this.theme.themeChange.subscribe({
            next: () => {
                this.generateUserLinkColors();
            },
        });

        this.v3discussion.v3Message.newMessages.subscribe({
            next: newMessages => {
                const discussionNewMessages = newMessages.filter(({ discussion }) => discussion === this.discussion.value?._id);

                if (discussionNewMessages?.length && this.newestLoaded.value) {
                    this.resetUnreadCounts(this.discussion.value._id);
                }
            },
        });
    }

    async parseLoadedMessages(data: any, container?: ElementRef, messageElements?: QueryList<ElementRef>) {
        if (data === undefined || !data?.messages?.length) {
            const discussion = await lastValueFrom(this.v3discussion.get(this.discussion.value?._id));

            if (!this.focusing.value && !this.loadingMessages && discussion) {
                this.loadingMessages = true;
                this.store.dispatch(loadLatestMessages({ options: { discussionId: this.discussion.value._id, limit: 50 } }));
                return;
            }

            this.loadingMessages = false;
            return;
        }

        const stickToBottom = container && this.isScrollAtBottom(container) && this.newestLoaded.value && data.newestLoaded;
        const messages: any[] = data.messages;

        this.oldestLoaded.next(data.oldestLoaded || false);
        this.newestLoaded.next(data.newestLoaded || false);

        const reversedMessages = [...messages];
        reversedMessages.reverse();
        reversedMessages.sort((a, b) => a.created - b.created);
        this.messages.next(reversedMessages);

        // Scroll position handling is fun... :-)
        if (!this.focusing.value) {
            const firstLoad = !this.loadCount;
            const savedScrollPosition = data.scrollTop;

            if (messageElements && container && !firstLoad && !stickToBottom && this.scrollToMessage) {
                // If you can get this working differently/better, please do update
                const fakeMessage = { _id: this.scrollToMessage.id, discussion: this.discussion.value._id } as Message;
                this.focusToMessage(fakeMessage, true);
                const focus = messageElements.find(element => element.nativeElement.getAttribute('id') === fakeMessage._id);
                const offsetTop = focus?.nativeElement?.offsetTop;
                focus?.nativeElement.scrollIntoView();
                container.nativeElement.scrollTop = offsetTop - 200;
            } else if ((savedScrollPosition || savedScrollPosition === 0) && firstLoad && !stickToBottom) {
                this.scrollToPreviousPosition = savedScrollPosition;
            } else if ((savedScrollPosition === undefined && firstLoad) || (stickToBottom && !firstLoad)) {
                this.scrollTo.next('scrollToBottom');
            }
        }

        this.loadCount += 1;
        this.oldestLoadedMessage = messages[messages.length - 2]; // Date divider is at the top but it has no id so therefore "length - 2"
        this.newestLoadedMessage = messages[0];
        this.loadingMessages = false;
        this.scrollToMessage = null;
    }

    clearDiscussionVariables(changeDiscussion?: boolean) {
        this.loadCount = 0;
        this.oldestLoadedMessage = null;
        this.newestLoadedMessage = null;
        this.oldestLoaded.next(false);
        this.newestLoaded.next(false);
        this.contextMenuMessage.next(null);
        this.loadingMessages = false;
        this.scrollToMessage = null;
        this.dragging.next(false);
        this.scrolling.next(false);
        this.scrollToPreviousPosition = undefined;
        this.editingMessage.next({});

        if (changeDiscussion) {
            this.scrollIsAtBottom = false;
            this.discussion.next(null);
            this.focusing.next(false);
            this.canBeLinkedFromProcesses.next([]);
            this.messages.next(null);
            this.participants.next(null);
            this.participantColorMap.next(null);
        }
    }

    leaveDiscussion() {
        if (this.discussion.value?.private) {
            return;
        }

        const confirm = this.translocoService.translate('misc.services.selected-discussion.leave_congfirm');
        const content = this.translocoService.translate('misc.services.selected-discussion.leave_content');
        const title = this.translocoService.translate('misc.services.selected-discussion.leave_title');

        this.dialogHelper.showConfirm(confirm, content, title).subscribe({
            next: confirmed => {
                if (confirmed) {
                    this.v3discussion.leave(this.discussion.value._id).subscribe({
                        error: err => console.error('Unable to leave discussion: ', err),
                    });
                }
            },
        });
    }

    async addParticipants() {
        if (this.isUserGuest) {
            return;
        }

        const allowGuests = await this.permission.guestsAllowedInDiscussion(this.discussion.value);

        this.zone.run(() => {
            const dialogRef = this.matDialog.open(UserInviteDialogComponent, {
                height: '500px',
                width: '500px',
                data: {
                    allowAllUsers: this.discussionType === 'group',
                    hiddenParticipants: this.discussion.value.participants,
                    networkId: this.discussion.value.cid,
                    discussionId: this.discussion.value._id,
                    hideGuests: !this.discussion.value.linked_activity,
                    allowGuests,
                },
            });

            dialogRef.afterClosed().subscribe((userIds: string[]) => {
                if (userIds && userIds.length > 0) {
                    this.v3discussion.invite(this.discussion.value._id, userIds).subscribe({
                        error: (error: any) => console.error('error: ', error),
                    });
                    Array.prototype.push.apply(this.discussion.value.participants, userIds);
                    this.updateParticipants();
                }
            });
        });
    }

    get isUserGuest(): boolean {
        return this.permission.isNetworkGuest;
    }

    get discussionType(): string {
        if (this.newContact.value) {
            return 'user';
        }

        return this.v3discussion.getDiscussionType(this.discussion.value);
    }

    async selectDiscussion(discussionId: string, sidenavMode?: boolean) {
        let discussion: Discussion;
        try {
            discussion = await lastValueFrom(this.v3discussion.get(discussionId));
        } catch (error) {
            console.warn(`Failed to fetch discussion: ${error}`);
        }

        if (!discussion) {
            return;
        }

        const discussionChanged = this.discussion.value?._id !== discussion._id;
        const eventChanged = this.discussion.value?.linked_event !== discussion.linked_event;
        this.discussion.next(discussion);
        this.sidenavMode = sidenavMode || false;

        if (discussionChanged || eventChanged) {
            this.updateSidenav();
        }

        void this.getLinkableWorkflows();
        this.updateParticipants();
        this.generateUserLinkColors();
        this.resetUnreadCounts(this.discussion.value._id);

        if (discussionChanged) {
            this.discussionChanged.next(this.discussion.value._id);
        }
    }

    async resetUnreadCounts(discussionId: string) {
        if (!this.v3discussion.unreadDiscussions.value[discussionId]) {
            return;
        }

        this.v3discussion.setLastSeen([discussionId]).subscribe({
            error: error => console.error('Error happened', error),
        });
    }

    async getLinkableWorkflows(): Promise<void> {
        const activityId = this.discussion.value?.linked_activity;
        if (!activityId) {
            return;
        }

        const activity = await this.activities.getActivity(activityId);
        if (!activity) {
            return;
        }

        this.activities
            .getLinkableProcesses(activity.process, { onlyInitialPhase: true })
            .pipe(take(1))
            .subscribe({
                next: canBeLinkedFrom => {
                    this.canBeLinkedFromProcesses.next(this.firstPhasePermissionCheck(canBeLinkedFrom));
                },
                error: error => {
                    console.error('Error happened while fetching linkable processes', error);
                    this.canBeLinkedFromProcesses.next([]);
                },
            });
    }

    generateUserLinkColors() {
        if (!this.discussion.value?._id || this.discussion.value?.private) {
            this.participantColorMap.next({});
            return;
        }

        const add = (input: string): number => {
            const splitInput = input.split('');
            let sum = 0;
            for (const split of splitInput) {
                sum += parseInt(split, 10);
            }
            return sum;
        };

        const idRandomizer = add(this.discussion.value._id.match(/\d+/g).join(''));
        const linkColors = this.theme.getActiveTheme().linkColors;

        const updatedColorMap: ParticipantColorMap = {};

        this.discussion.value?.participants.forEach((participant, index) => {
            updatedColorMap[participant] = linkColors[(index + idRandomizer) % linkColors.length];
        });

        this.participantColorMap.next(updatedColorMap);
    }

    updateParticipants() {
        const updatedParticipants: UserMap = {};

        this.discussion.value?.participants.forEach(participant => {
            updatedParticipants[participant] = this.people.getUser(participant);
        });

        this.participants.next(updatedParticipants);
    }

    async focusToMessage(message: Message, skipAnimation?: boolean, currentDiscussionId?: string) {
        // If message is not in current discussion, switch to the original discussion and navigate to the the message
        if (currentDiscussionId && currentDiscussionId !== message.discussion) {
            await this.routerService.navigate(['discussions', message.discussion]);
            // Waiting for discussion to load
            this.discussionChanged.pipe(take(1), timeout(1000)).subscribe({
                next: () => {
                    this.focusMessage.next({ message, skipAnimation });
                },
            });
        }
        // If message is a reply message navigate to the replied message straight away
        this.focusMessage.next({ message, skipAnimation });
    }

    openActivity() {
        if (!this.allowActivitySidenav) {
            return;
        }

        this.sideNav.create(V3ActivitySidenavComponent, {
            activityId: this.discussion.value.linked_activity,
        });
    }

    openEvent() {
        if (!this.allowEventSidenav) {
            return;
        }

        this.sideNav.create(EventSidenavComponent, {
            eventId: this.discussion.value.linked_event,
        });
    }

    openGroup() {
        this.sideNav.create(GroupDiscussionDetailSidenavComponent, {
            discussion: this.discussion.value,
        });
    }

    openUser(userId: string) {
        this.sideNav.create(UserDetailComponent, {
            userId,
        });
    }

    get participantsWithoutMe(): string[] {
        if (this.newContact.value) {
            return [this.newContact.value];
        }

        return this.discussion.value.participants.filter(uid => uid !== this.core.user.value._id);
    }

    get allowActivitySidenav(): boolean {
        const activityDiscussion = !!this.discussion.value?.linked_activity;
        return activityDiscussion;
    }

    get allowEventSidenav(): boolean {
        const eventDiscussion = !!this.discussion.value?.linked_event;
        const inCorrectNetwork = this.discussion.value?.cid === this.core.network.value?._id;

        return eventDiscussion && inCorrectNetwork;
    }

    /**
     * Updates open sidenav if discussion was changed
     */
    updateSidenav() {
        const fromDifferentNetwork = this.discussion.value.cid !== this.core.network.value._id;

        if ((this.discussion.value?.linked_activity || this.discussion.value?.linked_event) && fromDifferentNetwork) {
            this.sideNav.clear();
            return;
        }

        if (this.sidenavMode || !this.sideNav.stackSize$.value) {
            return;
        }

        const activityId = this.discussion.value?.linked_activity;
        const eventId = this.discussion.value.linked_event;
        const group = !activityId && !eventId && !this.discussion.value.private;

        this.sideNav.clear();

        if (activityId && this.allowActivitySidenav) {
            this.openActivity();
        }

        if (eventId && this.allowEventSidenav) {
            this.openEvent();
        }

        if (this.discussion.value.private) {
            const userId = this.participantsWithoutMe[0];
            this.openUser(userId);
        }

        if (group) {
            this.openGroup();
        }
    }

    onScroll(container: ElementRef, messageElements: QueryList<ElementRef>) {
        this.scrolling.next(false);
        this.scrollIsAtBottom = this.isScrollAtBottom(container);

        if (this.focusing.value || !this.discussion.value?._id || !this.messages.value || this.loadingMessages) {
            return;
        }

        // Load older messages when scroll is at top
        if (this.isScrollAtTop(container) && !this.oldestLoaded.value) {
            this.scrollToMessage = messageElements?.first?.nativeElement.nextElementSibling;
            this.loadingMessages = true;

            return void this.store.dispatch(
                loadPreviousMessages({
                    options: {
                        messageId: this.oldestLoadedMessage?._id,
                        discussionId: this.discussion.value._id,
                        limit: 50,
                    },
                })
            );
        }

        // Load newer messages when scroll is at bottom
        if (this.scrollIsAtBottom && !this.newestLoaded.value) {
            this.scrollToMessage = messageElements?.last?.nativeElement;
            this.loadingMessages = true;

            return void this.store.dispatch(
                loadNextMessages({
                    options: {
                        messageId: this.newestLoadedMessage?._id,
                        discussionId: this.discussion.value._id,
                        limit: 50,
                    },
                })
            );
        }
    }

    getScrollDiff(container: ElementRef): number {
        return this.scrollToMessage?.offsetTop - container?.nativeElement.scrollTop;
    }

    isScrollAtTop(container: ElementRef): boolean {
        const element = container?.nativeElement;
        if (!element) {
            return false;
        }

        return element.scrollTop <= 3800;
    }

    isScrollAtBottom(container: ElementRef): boolean {
        const element = container?.nativeElement;
        if (!element) {
            return false;
        }

        if (!this.newestLoaded.value) {
            return Math.ceil(element.clientHeight + element.scrollTop) >= element.scrollHeight - 3800;
        }

        return Math.ceil(element.clientHeight + element.scrollTop) >= element.scrollHeight;
    }

    private firstPhasePermissionCheck(canBeLinkedFrom: CanBeLinkedFrom[]): CanBeLinkedFrom[] {
        const filtered = canBeLinkedFrom.filter(canBeLinked => {
            const processId = canBeLinked.process._id;
            return this.permission.viewOnlyPhase(this.core.user.value?.id, processId);
        });

        return filtered;
    }
}

export interface ParticipantColorMap {
    [userId: string]: string;
}
