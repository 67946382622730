
export class LicenseLimits {
    constructor(
        public free: LicenseLimit = {
            users: 100,
            calendars: 10,
            modules: 100,
            activities: 100,
            public: false,
            tableEdit: false,
            filesTagging: true,
            restricted: false,
            isolatedTeams: false,
            insight: false,
            groups: false,
            customRoles: false,
        },
        public freeTrial: LicenseLimit = {
            users: 10000,
            calendars: 10000,
            modules: 10000,
            activities: 10000,
            public: false,
            tableEdit: false,
            filesTagging: true,
            restricted: false,
            isolatedTeams: false,
            insight: false,
            groups: false,
            customRoles: false,
        },
        public premium: LicenseLimit = {
            users: 10000,
            calendars: 10000,
            modules: 10000,
            activities: 1000000,
            public: false,
            tableEdit: false,
            filesTagging: true,
            restricted: false,
            isolatedTeams: false,
            insight: false,
            groups: false,
            customRoles: false,
        },
        public legacy: LicenseLimit = {
            users: 10000,
            calendars: 10000,
            modules: 10000,
            activities: 1000000,
            public: false,
            tableEdit: false,
            filesTagging: true,
            restricted: false,
            isolatedTeams: false,
            insight: false,
            groups: false,
            customRoles: false,
        },
        public enterprise: LicenseLimit = {
            users: 10000,
            calendars: 10000,
            modules: 10000,
            activities: 10000000,
            public: false,
            tableEdit: false,
            filesTagging: true,
            restricted: false,
            isolatedTeams: false,
            insight: false,
            groups: false,
            customRoles: false,
        },
    ) { }
}

export interface LicenseLimit {
    users: number;
    calendars: number;

    /** Workflows and datasets in total */
    modules: number;
    activities: number;
    public: boolean;
    tableEdit: boolean;
    filesTagging: boolean;
    restricted: boolean;
    isolatedTeams: boolean;
    insight: boolean;
    groups: boolean;
    customRoles: boolean;
}