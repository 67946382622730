<!-- @format -->

<!-- Note when updating styles on this page: this page should always look as a light theme page even when user accesses this page when logged in and using a dark theme -->

<div class="login-background">
    <div class="bubbles"></div>
    <div class="language-change-container">
        <button
            class="no-button for-text-and-icon"
            [ngClass]="languageMenuOpen ? 'menu-open' : 'menu-closed'"
            (menuOpened)="languageMenuOpen = true"
            (menuClosed)="languageMenuOpen = false"
            [matMenuTriggerFor]="menu"
            data-cy="language-change-button">
            <span class="language-button-text">{{ selectedLanguage }}</span>
            <mat-icon svgIcon="hailer-chevron"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" aria-selected="selectedLanguage" class="multilanguage-menu">
            <button mat-menu-item (click)="changeLanguage('en')">English</button>
            <button mat-menu-item (click)="changeLanguage('fi')">Suomi</button>
            <button mat-menu-item (click)="changeLanguage('sv')">Svenska</button>
        </mat-menu>
    </div>
    <mat-card class="login-card">
        <mat-card-content>
            <app-hailer-logo></app-hailer-logo>
            <h1 class="login-header">{{ 'public.forgot-password-page.password_recovery_header' | transloco }}</h1>
            <div *ngIf="!ticketId && !status.emailSent">
                <form [formGroup]="emailForm" (ngSubmit)="preparePasswordRecovery()">
                    <div class="flex-column--centered">
                        <p class="password-recovery-text" *ngIf="status.errorInEmail === null">
                            {{ 'public.forgot-password-page.email_for_reset' | transloco }}
                        </p>
                        <p
                            data-cy="unable-send-email-message"
                            class="password-recovery-text-error"
                            *ngIf="status.errorInEmail !== null && !status.saving">
                            {{ 'public.forgot-password-page.error_email_for_reset' | transloco }}
                        </p>
                        <mat-form-field
                            floatLabel="always"
                            appearance="outline"
                            class="form-field public-forms"
                            [ngClass]="{
                                'mat-form-field-invalid':
                                    status.errorInEmail && emailForm.controls.email.untouched && emailForm.controls.email.pristine
                            }">
                            <mat-label attr.aria-label="{{ 'public.forgot-password-page.invalid_email_label' | transloco }}">
                                {{ 'public.forgot-password-page.invalid_email_label' | transloco }}
                            </mat-label>
                            <input
                                data-cy="forgot-password-email-input"
                                matInput
                                [appAutofocus]="true"
                                formControlName="email"
                                type="email"
                                name="email"
                                placeholder="{{ 'public.forgot-password-page.invalid_email_placeholder' | transloco }}"
                                class="form-control" />
                            <mat-error *ngIf="emailForm?.controls?.email?.errors && emailForm?.controls?.email?.value">{{
                                'public.register-form.email_invalid_hint' | transloco
                            }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="login-button-container-two-buttons-column password-recovery-button-container">
                        <button class="primary-public-pages" mat-flat-button *ngIf="status.saving" [disabled]="status.saving">
                            {{ 'public.forgot-password-page.button_sending' | transloco }}
                        </button>
                        <button
                            class="primary-public-pages"
                            mat-flat-button
                            *ngIf="!status.saving"
                            type="submit"
                            [disabled]="!emailForm.valid"
                            data-cy="send-link-button">
                            {{ 'public.forgot-password-page.button_email_form' | transloco }}
                        </button>
                        <button
                            mat-flat-button
                            class="tertiary-highlight-public-pages"
                            *ngIf="!status.saving"
                            [routerLink]="['/login']"
                            data-cy="back-to-login-button">
                            <mat-icon svgIcon="hailer-chevron" class="hailer-chevron left"></mat-icon>
                            <span>{{ 'public.forgot-password-page.button_back_to_login' | transloco }}</span>
                        </button>
                    </div>
                </form>
            </div>
            <div *ngIf="!ticketId && status.emailSent">
                <div class="flex-column--centered">
                    <p class="password-recovery-text" data-cy="email-has-been-sent-message">
                        {{ 'public.forgot-password-page.email_sent_text' | transloco }}
                        <br /><strong class="dark-text">{{ triedEmail }}</strong>
                    </p>
                </div>
                <div class="flex-column--centered">
                    <button mat-flat-button class="tertiary-highlight-public-pages" [routerLink]="['/login']">
                        <mat-icon svgIcon="hailer-chevron" class="hailer-chevron left"></mat-icon>
                        <span>{{ 'public.forgot-password-page.button_back_to_login' | transloco }}</span>
                    </button>
                </div>
            </div>
            <div *ngIf="ticketId">
                <form class="form-container" [formGroup]="passwordForm" (ngSubmit)="resetPassword()">
                    <div class="flex-column--centered">
                        <p class="password-recovery-text">{{ 'public.forgot-password-page.new_password_text' | transloco }}</p>
                        <mat-form-field floatLabel="always" appearance="outline" class="form-field public-forms">
                            <mat-label attr.aria-label="{{ 'public.forgot-password-page.new_password_label' | transloco }}">{{
                                'public.forgot-password-page.new_password_label' | transloco
                            }}</mat-label>
                            <app-password-strength-meter
                                [passwordScore]="(passwordScore | async) ?? 0"
                                [passwordInputFocused]="passwordInputFocused"
                                id="password-strength-meter-tooltip"
                                aria-hidden="“false”"></app-password-strength-meter>
                            <input
                                data-cy="forgot-password-password-input"
                                matInput
                                formControlName="password"
                                name="password"
                                placeholder="{{ 'public.forgot-password-page.new_password_placeholder' | transloco }}"
                                (focus)="setPasswordInputFocus(true)"
                                (focusout)="setPasswordInputFocus(false, $event)"
                                [type]="visiblePasswords.new ? 'text' : 'password'"
                                aria-describedby="password-strength-meter-tooltip" />
                            <mat-icon
                                matSuffix
                                id="newPasswordInputVisibilityIcon"
                                tabindex="0"
                                class="password-visibility-button"
                                [svgIcon]="visiblePasswords.new ? 'hailer-show' : 'hailer-hide'"
                                (click)="visiblePasswords.new = !visiblePasswords.new"></mat-icon>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                        <mat-form-field
                            floatLabel="always"
                            appearance="outline"
                            class="form-field public-forms"
                            [ngClass]="{
                                'mat-form-field-invalid':
                                    passwordForm.controls.passwordConfirm.invalid &&
                                    !passwordForm.controls.passwordConfirm.pristine &&
                                    !passwordForm.controls.passwordConfirm.touched
                            }">
                            <mat-label attr.aria-label="{{ 'public.forgot-password-page.password_repeat_label' | transloco }}">{{
                                'public.forgot-password-page.password_repeat_label' | transloco
                            }}</mat-label>
                            <input
                                data-cy="forgot-password-confirm-password-input"
                                matInput
                                formControlName="passwordConfirm"
                                [type]="visiblePasswords.newConfirm ? 'text' : 'password'"
                                class="form-control"
                                name="passwordConfirm"
                                placeholder="{{ 'public.forgot-password-page.password_repeat_placeholder' | transloco }}"
                                (focus)="passwordConfirmDirty = true" />
                            <mat-icon
                                matSuffix
                                class="password-visibility-button"
                                [svgIcon]="this.visiblePasswords.newConfirm ? 'hailer-show' : 'hailer-hide'"
                                (click)="visiblePasswords.newConfirm = !visiblePasswords.newConfirm"></mat-icon>

                            <mat-hint
                                *ngIf="
                                    passwordForm.controls.passwordConfirm.errors &&
                                    passwordForm.controls.passwordConfirm.errors.passwordsDoNotMatch
                                "
                                class="red-hint">
                                {{ 'public.forgot-password-page.passwords_not_matching' | transloco }}</mat-hint
                            >
                        </mat-form-field>
                    </div>
                    <div class="login-button-container-two-buttons-column">
                        <button
                            mat-flat-button
                            data-cy="confirm-password-reset-button"
                            class="primary-public-pages"
                            [disabled]="status.saving || passwordForm.invalid"
                            type="submit">
                            {{
                                (status.saving
                                    ? 'public.forgot-password-page.button_sending'
                                    : 'public.forgot-password-page.button_set_password'
                                ) | transloco
                            }}
                        </button>
                        <button mat-flat-button class="tertiary-highlight-public-pages" *ngIf="!status.saving" [routerLink]="['/login']">
                            <mat-icon svgIcon="hailer-chevron" class="hailer-chevron left"></mat-icon>
                            <span>{{ 'public.forgot-password-page.button_back_to_login' | transloco }}</span>
                        </button>
                    </div>
                </form>
            </div>
        </mat-card-content>
    </mat-card>
</div>
