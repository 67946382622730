import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from '@app/env';

export const authConfigMap: { [provider: string]: AuthConfig } = {
    digione: {
        // Url of the Identity Provider
        issuer: environment.sso.digione.issuer,
    
        // URL of the SPA to redirect the user to after login
        redirectUri: environment.sso.digione.redirectUri,
    
        // The SPA's id. The SPA is registered with this id at the auth-server
        clientId: environment.sso.digione.clientId,
    
        // Set the scope for the permissions the client should request
        // This is not in use at the moment
        scope: environment.sso.digione.scope
    },
    // Add more providers here...
    /* lidl: {
        // Url of the Identity Provider
        issuer: environment.sso.lidl.issuer,
    
        // URL of the SPA to redirect the user to after login
        redirectUri: environment.sso.lidl.redirectUri,
    
        // The SPA's id. The SPA is registered with this id at the auth-server
        clientId: environment.sso.lidl.clientId,
    
        // Set the scope for the permissions the client should request
        // This is not in use at the moment
        scope: environment.sso.lidl.scope
    } */
}
