<div class="fill-field-container" style="font-size: 14px;">
    <ng-container *ngIf="permissionTypes.length === 1">
        <mat-checkbox data-cy="permission-type-selector-checkbox" [checked]="permissionType === permissionTypes[0].value" (change)="setPermission($event.checked, permissionTypes[0].value)">{{permissionTypes[0].label}}</mat-checkbox>
    </ng-container>
    <!-- <pre>{{permissionTypes | json}}</pre> -->
    <ng-container *ngIf="permissionTypes.length > 1">
        <mat-form-field appearance="outline" class="full-width">
            <mat-select (selectionChange)="changePermission($event)" [value]="permissionType">
                <mat-option *ngFor="let type of permissionTypes" [value]="type.value">
                    {{type.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
</div>