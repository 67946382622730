/** @format */

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { DiscussionMap } from '@app/models';
import { CoreStore } from 'app/_services/core.service';
import { ConfirmDialogComponent } from '../../_dialogs/confirm-dialog/confirm-dialog.component';
import { RoutingService } from 'app/_services/routing.service';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';
import { SelectedDiscussionService } from 'app/_services/selected-discussion.service';

@Component({
    selector: 'app-forward-to-discussions-dialog',
    templateUrl: './forward-to-discussions-dialog.component.html',
    styleUrls: ['./forward-to-discussions-dialog.component.scss'],
    providers: [
        SelectedDiscussionService,
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'dialogs', alias: 'dialogs' },
        },
    ],
})
export class ForwardToDiscussionsDialogComponent {
    discussions: Observable<DiscussionMap> = this.store.select(state => state.discussions);
    discussion: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        private store: Store<CoreStore>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private routerService: RoutingService,
        private v3Discussion: V3DiscussionService,
        private selectedDiscussion: SelectedDiscussionService
    ) {}

    async onForward(discussionId: string) {
        try {
            await this.routerService.navigate(['discussions', discussionId]);
            await this.selectedDiscussion.selectDiscussion(discussionId);

            /* Copy the message object so that it can be edited in the forward function in v3-discussion.service.ts
               MAT_DIALOG_DATA injection makes the data non editable for an unknown reason */
            const messageCopy: any = {};
            Object.assign(messageCopy, this.data.message);
            this.v3Discussion.forward(messageCopy, discussionId, false);
        } catch (err) {
            console.error('Error forwarding message: ', err);
        }
        this.dialogRef.close();
    }

    onCancel() {
        this.dialogRef.close();
    }
}
