/** @format */

import { Pipe, PipeTransform } from '@angular/core';

import { CoreService } from 'app/_services/core.service';
import { PeopleService } from 'app/people/people.service';

@Pipe({
    name: 'participants',
})
export class ParticipantsPipe implements PipeTransform {
    constructor(private core: CoreService, private peopleService: PeopleService) {}

    transform(participantIds: any, args?: any): any {
        if (!participantIds?.length) {
            return [];
        }

        const participants: any[] = [];
        const me = this.core.user.value;
        participantIds.forEach(id => {
            if (id !== me._id) {
                const participant = this.peopleService.getUser(id);
                participants.push(args.returnId ? participant._id : participant);
            }
        });

        if (args.currentUserFirst && participantIds.includes(me._id)) {
            if (args.returnId) {
                participants.unshift(me._id);
            } else {
                const currentUser = this.peopleService.getUser(me._id);
                participants.unshift(currentUser);
            }
        }

        return args.returnAll ? participants : participants[0];
    }
}
