<!-- @format -->
<div
    class="login-background"
    *ngIf="(state | async) !== 'authenticated'"
    [ngStyle]="{ 'visibility': (loadingProgress.loaded | async) ? 'visible' : 'hidden' }">
    <div class="bubbles"></div>
    <div class="language-change-container">
        <button
            *ngIf="miscellaneousTranslationsLoaded"
            data-cy="language-change-button"
            class="no-button for-text-and-icon"
            [ngClass]="languageMenuOpen ? 'menu-open' : 'menu-closed'"
            (menuOpened)="languageMenuOpen = true"
            (menuClosed)="languageMenuOpen = false"
            [matMenuTriggerFor]="menu">
            <span class="language-button-text">{{ selectedLanguage }}</span>
            <mat-icon svgIcon="hailer-chevron"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" aria-selected="selectedLanguage" class="multilanguage-menu">
            <button mat-menu-item (click)="changeLanguage('en')">English</button>
            <button mat-menu-item (click)="changeLanguage('fi')">Suomi</button>
            <button mat-menu-item (click)="changeLanguage('sv')">Svenska</button>
        </mat-menu>
    </div>
    <mat-card *ngIf="getState() === 'login'" class="login-card">
        <mat-card-content>
            <div>
                <app-hailer-logo></app-hailer-logo>
                <div class="flex-column--centered">
                    <h1 class="login-header">{{ 'public.login-page.log-in' | transloco }}</h1>
                </div>
                <div *ngIf="getState() === 'login'" class="flex-column--centered">
                    <p class="login-text">
                        {{ 'public.login-page.no_account' | transloco }}
                        <a data-cy="register-account-link" class="login-link" [routerLink]="['/register']">
                            {{ 'public.login-page.register_acc' | transloco }}
                        </a>
                    </p>
                </div>
                <app-login-form
                    [hasForgotPasswordLink]="true"
                    [hasRememberMeButton]="true"
                    [presetUserName]="presetUserName"
                    (emailChanged)="getEmail($event)"
                    (submitClick)="login($event)"></app-login-form>
            </div>

            <div *ngIf="getState() === 'login'" class="flex-column--centered devtools-button">
                <button *ngIf="showDevTools" mat-flat-button [routerLink]="['/devtools']">
                    {{ 'public.login-page.dev_tools' | transloco }}
                </button>
            </div>

            <div  class="hailer-help-form" [class.devtools]="showDevTools">
                <p class="login-text">
                    <span> {{ 'public.need-help' | transloco }} </span>
                    <!-- hailer help forms with the email field id from birds eye view -->
                    <a
                        class="login-link"
                        [href]="
                            'https://form.hailer.com/eb945c01280f16d32b26b619161b8497/?language=' +
                            getLanguage() +
                            (this.email ? '&62d7d5c71438bb0840e97b02=' + this.email : '')
                        "
                        target="_blank"
                        referrerpolicy="no-referrer"
                        rel="nofollow help">
                        {{ 'public.contact-form' | transloco }}
                    </a>
                </p>
            </div>
        </mat-card-content>
    </mat-card>
</div>
