export class HailerApiErrors {
    // RpcReserved errors 0-99
    static Failed = 127;
    static LoginLimit = 128;
    static InvalidArguments = 191;
    static RpcPermissionDenied = 302;
    static GuestPermissionDenied = 426;
    static PermissionDenied = 403;
    static Internal = 500;
    static LicenseExceeded = 402;
    static LicenseExceededTrial = 418;
    static PublicCopyDenied = 501;
    static AlreadyExists = 409;
    /** Feature is no longer available */
    static EndOfLife = 410;
    static NotFound = 404;
}

class HailerErrorCode {
    static PublicCopyNotAllowedCode = 1;
}

export class HailerError extends Error {
    static get PublicCopyNotAllowed(): HailerError { return new HailerError(HailerErrorCode.PublicCopyNotAllowedCode, 'Public copy of network is not allowed'); }

    code: number;

    private constructor(statusCode: number, message?: string) {
        super(message);
        this.code = statusCode;
    }
}
