<!-- @format -->

<div class="dialog-header">
    <h1 mat-dialog-title>{{ 'feed.schedule-dialog.header' | transloco }}</h1>
    <app-button
        type="icon"
        hailerIconName="hailer-x"
        color="icon-white-all-themes"
        tippyText="{{ 'shared.close-popup' | transloco }}"
        ariaLabel="{{ 'shared.close-popup' | transloco }}"
        (clickEvent)="close()"></app-button>
</div>

<h2 class="dialog-second-header">{{'feed.schedule-dialog.title' | transloco}}</h2>
<p class="dialog-body-text">{{'feed.schedule-dialog.description' | transloco}}</p>

<div class="schedule-input-wrapper">
    <mat-form-field color="accent" floatLabel="always" appearance="outline" class="form-field highlight-bg full-width">
        <mat-label>{{ 'feed.posting-time-label' | transloco }}</mat-label>
        <app-button
            matPrefix
            [owlDateTimeTrigger]="startPicker"
            type="icon"
            color="icon-default"
            size="small"
            hailerIconName="hailer-calendar"
            tippyText="{{ 'feed.open-calendar' | transloco }}"
            ariaLabel="{{ 'feed.open-calendar' | transloco }}">
        </app-button>
        <!-- [placeholder]="'feed.draft-timestamp' | transloco"  -->
        <input
            matInput
            [min]="minimum"
            [formControl]="scheduleDateForm"
            [owlDateTimeTrigger]="startPicker"
            [owlDateTime]="startPicker"
            placeholder="mm/dd/yy, hh:mm"
            (focus)="startPicker.open()" />
        <app-button
            *ngIf="scheduleDateForm.value"
            matSuffix
            (clickEvent)="clear(); $event.stopPropagation()"
            type="icon"
            size="small"
            color="icon-default"
            hailerIconName="hailer-x"
            tippyText="{{ 'shared.clear-button.clear' | transloco }}"
            ariaLabel="{{ 'shared.clear-button.clear-aria' | transloco }}"></app-button>
    </mat-form-field>
</div>
<owl-date-time [pickerType]="'both'" [firstDayOfWeek]="1" #startPicker></owl-date-time>
<div class="schedule-buttons-wrapper">
    <app-button type="cancel" size="large" width="popup-two-buttons" (clickEvent)="close()"></app-button>
    <app-button type="save" size="large" width="popup-two-buttons" (clickEvent)="confirm()"></app-button>
</div>
