/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MentionDirective } from './mention.directive';
import { MentionListComponent } from './mention-list.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [MentionDirective, MentionListComponent],
    imports: [CommonModule, SharedModule],
    exports: [MentionDirective],
})
export class MentionModule {}
