<div class="network-item-container">
    <div
        matListAvatar
        class="network-icon"
        [style.background-color]="stylingHelperService.getNetworkBackgroundColor(core.networks.value[item._id])">
        <p [style.color]="stylingHelperService.getNetworkTextColor(core.networks.value[item._id])">{{ networkLogo }}</p>
    </div>
    <p class="dropdown-item-title">
        {{ item?.title }}
    </p>
</div>
