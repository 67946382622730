/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatCardModule } from '@angular/material/card';
import { TranslocoModule } from '@ngneat/transloco';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { CropImageDialogComponent } from './crop-image-dialog/crop-image-dialog.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { RemoveDialogComponent } from './remove-dialog/remove-dialog.component';
import { CopyActivitiesDialogComponent } from './copy-activities-dialog/copy-activities-dialog.component';
import { RemoveDialogDependenciesComponent } from './remove-dialog-dependencies/remove-dialog-dependencies.component';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { DiscussionSharedModule } from 'app/discussion-shared/discussion-shared.module';
import { ButtonsModule } from 'app/buttons/buttons.module';
import { FeedPostVisibilityDialogComponent } from './feed-post-visibility-dialog/feed-post-visibility-dialog.component';
import { SharedModule } from '@app/shared/shared.module';


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatSliderModule,
        MatCardModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatRadioModule,
        MatCheckboxModule,
        ImageCropperModule,
        ScrollingModule,
        DiscussionSharedModule,
        TranslocoRootModule,
        TranslocoModule,
        ButtonsModule,
        SharedModule,
    ],
    declarations: [
        NotificationDialogComponent,
        CropImageDialogComponent,
        ConfirmDialogComponent,
        ErrorDialogComponent,
        RemoveDialogComponent,
        CopyActivitiesDialogComponent,
        RemoveDialogDependenciesComponent,
        FeedPostVisibilityDialogComponent,
    ],
})
export class DialogModule {}
