/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

import { ActivityCalendarFields } from './../_models/settings/activityCalendarFields.model';
import { PersonalSettings, User, UserSettings, UserSettingKey } from '@app/models';
import { CoreService } from './core.service';
import { RPCService } from './rpc.service';
import { AllowedCalendarViews } from './event-helper.service';
import { PermissionService } from './permission.service';
import { UserThemes } from 'app/_models/user-theme.type';
import { TranslateService } from './translate.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    static unknownUser = new User({
        firstname: 'Unknown',
        lastname: 'User',
    });

    usersByNetwork: Observable<User[]>;
    me: BehaviorSubject<PersonalSettings>;
    unknownUsers: any;

    constructor(
        private core: CoreService,
        private rpcService: RPCService,
        private permissions: PermissionService,
        private translocoService: TranslocoService,
        private translateService: TranslateService
    ) {
        this.translateService.translationLoaded('misc').then(() => {
            UserService.unknownUser.firstname = this.translocoService.translate('misc.services.user.firstname');
            UserService.unknownUser.lastname = this.translocoService.translate('misc.services.user.lastname');
        });
        this.me = this.core.user;
        // Here we create a list of all users in current network
        this.getUsersByNetwork();
    }

    selectedCalendars(): string[] {
        return this.getNetworkSetting('calendarsSelected') || [];
    }

    selectedCalendarFields(): string[] {
        return this.getNetworkSetting('calendarFields') || [];
    }

    selectedCalendarUsers(): string[] {
        return this.getNetworkSetting('calendarUsers') || [];
    }

    selectedCalendarUnassigned(): boolean {
        return this.getNetworkSetting('calendarHideUnassigned') || false;
    }

    selectedCalendarResources(): ActivityCalendarFields {
        return this.getNetworkSetting('activityCalendarFields') || {};
    }

    filterActivityCalendarResources(): boolean {
        return this.getNetworkSetting('filterResources') || false;
    }

    starredProcessesOnly(): boolean {
        return this.getNetworkSetting('starredProcessesOnly') || false;
    }

    allCalendarUsers(): boolean {
        return this.getNetworkSetting('allCalendarUsers') || false;
    }

    selectedCalendarView(): string | null {
        const view = this.getNetworkSetting('calendarView') || null;

        // TEMP FIX
        if (view === 'month' || view === 'agendaWeek' || view === 'agendaDay') {
            /* Month is not a valid FullCalendarView so convert it to dayGridMonth
               remove this if branch when we run a script which converts month to dayGridMonth
               in the database. */
            this.rpcService.request('user.save_settings', ['calendarView', AllowedCalendarViews.dayGridMonth]).subscribe({
                next: data => {
                    const personalSettings = this.core.user.value;
                    const workspaceId = this.core.network.value?._id;
                    if (!workspaceId || !personalSettings) {
                        return;
                    }

                    const workspaceKey = `cid_${workspaceId}`;
                    personalSettings.settings = personalSettings.settings || {};
                    personalSettings.settings[workspaceKey] = personalSettings.settings[workspaceKey] || {};
                    // I fucking hate this but it works correctly
                    (personalSettings.settings[workspaceKey]! as UserSettings).calendarView = data;

                    this.core.user.next(personalSettings);
                },
            });
            return AllowedCalendarViews.dayGridMonth;
        }
        return view;
    }

    saveSettings(key: UserSettingKey, value: any): Observable<PersonalSettings> {
        return this.rpcService.request('user.save_settings', [key, value]);
    }

    async saveSettingsAsync(key: UserSettingKey, value: any): Promise<PersonalSettings> {
        return this.rpcService.requestAsync('user.save_settings', [key, value]);
    }

    toggleDiscussionStarred(discussionId: string) {
        this.rpcService.request('v2.discussion.star', [discussionId]).subscribe();
    }

    setFunctionEditorTheme(theme: string) {
        this.rpcService.request('v2.user.settings.global.set', [{ functionEditorTheme: theme }]).subscribe();
    }

    acceptInvitation(inviteKey: string): Observable<any> {
        return this.rpcService.request('v2.user.invite.accept', [inviteKey]);
    }

    declineInvitation(inviteKey: string): Observable<any> {
        return this.rpcService.request('v2.user.invite.decline', [inviteKey]);
    }

    getUser(userId: string): User {
        if (userId.startsWith('user_')) {
            userId = userId.slice(5);
        }

        return this.core.getUser(userId);
    }

    setUserTheme(theme: UserThemes) {
        return this.rpcService.request('v2.user.settings.global.set', [{ theme }]);
    }

    setNotifications(state: { feedPostEmail: boolean; discussionMessageEmail: boolean }) {
        this.rpcService.request('v2.user.settings.global.set', [state]).subscribe();
    }

    deleteUser() {
        return this.rpcService.request('v3.user.remove');
    }

    private getUsersByNetwork(cid?: string) {
        this.usersByNetwork = merge(this.core.users, this.core.network, this.core.state).pipe(
            filter(it => it !== null && it !== undefined),
            debounceTime(1000),
            map(() => {
                const users = this.core.users.getValue();
                const networkId = cid ? cid : this.core.network.value._id;
                const state = this.core.state.getValue();

                // If we want to sort this array, it should be done here
                if (!(users && networkId) || state !== 'authenticated') {
                    return [];
                }
                const networkUsers: User[] = [];

                Object.values(users).forEach(user => {
                    if (!user.companies.includes(networkId)) {
                        return;
                    }

                    const castUser = new User({
                        ...user,
                        admin: this.permissions.checkIfNetworkAdmin(user._id),
                        owner: this.permissions.checkIfNetworkOwner(user._id),
                    });

                    networkUsers.push(castUser);
                });

                networkUsers.sort((a, b) => {
                    const res = a.firstname.toLocaleLowerCase().localeCompare(b.firstname.toLocaleLowerCase());
                    if (res !== 0) {
                        return res;
                    }
                    return a.lastname.toLocaleLowerCase().localeCompare(b.lastname.toLocaleLowerCase());
                });

                const index = networkUsers.findIndex(user => user._id === this.me.value._id);
                if (index > -1) {
                    const user = networkUsers[index];
                    networkUsers.splice(index, 1);
                    networkUsers.unshift(user);
                }

                return networkUsers;
            })
        );
    }

    /** @deprecated Use `getWorkspaceSettings instead` */
    private getNetworkSetting(key: string): any {
        const network = `cid_${this.core.network.value._id}`;

        if (!this.me.value || !this.me.value.settings?.[network] || typeof this.me.value.settings?.[network]?.[key] === 'undefined') {
            return null;
        }

        return this.me.value.settings[network][key];
    }

    getWorkspaceSettings(
        /** Defaults to core.network.value._id if undefined  */
        workspaceId = this.core.network.value?._id
    ): UserSettings | string[] | undefined {
        if (!workspaceId) {
            return;
        }

        return this.me.value?.settings?.[`cid_${workspaceId}`];
    }
}
