/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { FeedRoutingModule } from './feed-routing.module';
import { FeedComponent } from './feed/feed.component';
import { FeedPostEditorComponent } from './feed-post-editor/feed-post-editor.component';
import { FeedPostCommentViewComponent } from './feed-post-comment-view/feed-post-comment-view.component';
import { FeedPostCommentWriteComponent } from './feed-post-comment-write/feed-post-comment-write.component';
import { FeedPostCommentsComponent } from './feed-post-comments/feed-post-comments.component';
import { FeedPostLikeButtonComponent } from './feed-post-like-button/feed-post-like-button.component';
import { FeedPostOptionsButtonComponent } from './feed-post-options-button/feed-post-options-button.component';
import { FeedPostComponent } from './feed-post/feed-post.component';
import { ActivitiesSharedModule } from 'app/activities-shared/activities-shared.module';
import { PeopleSharedModule } from 'app/people-shared/people-shared.module';
import { FeedPostDetailResolverService } from 'app/_services/feed-post-detail-resolver.service';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@leeviviertola/date-time-picker';
import { PollModule } from 'app/poll/poll.module';
import { ButtonsModule } from 'app/buttons/buttons.module';
import { FeedTagsComponent } from './feed-tags/feed-tags.component';
import { FeedPostScheduleDialogComponent } from './feed-post-schedule-dialog/feed-post-schedule-dialog.component';
import { TextEditorModule } from 'app/text-editor/text-editor.module';

@NgModule({
    imports: [
        CommonModule,
        FeedRoutingModule,
        SharedModule,
        ActivitiesSharedModule,
        PeopleSharedModule,
        TranslocoRootModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        PollModule,
        ButtonsModule,
        TextEditorModule,
    ],
    declarations: [
        FeedComponent,
        FeedPostComponent,
        FeedPostCommentViewComponent,
        FeedPostCommentWriteComponent,
        FeedPostCommentsComponent,
        FeedPostEditorComponent,
        FeedPostLikeButtonComponent,
        FeedPostOptionsButtonComponent,
        FeedTagsComponent,
        FeedPostScheduleDialogComponent,
    ],
    exports: [FeedPostComponent],
    providers: [FeedPostDetailResolverService],
})
export class FeedModule {}
