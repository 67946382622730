/** @format */

import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatSelectSearchComponent } from 'ngx-mat-select-search';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { CoreService } from 'app/_services/core.service';
import { Company, PersonalSettings, Team, User } from '../../_models';
import { TeamService } from 'app/_services/team.service';
import { PeopleService } from 'app/people/people.service';
import { Member } from 'app/_models/member.model';
import { UserService } from 'app/_services/user.service';
import { GroupService } from 'app/_services/group.service';
import { PermissionType, PermissionValueType } from 'app/_models/permission-type.model';
import { Group } from 'app/_models/group.model';

@Component({
    selector: 'app-permission-selector',
    templateUrl: './permission-selector.component.html',
    styleUrls: ['./permission-selector.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class PermissionSelectorComponent implements OnInit, OnDestroy, OnChanges {
    @Input() members: Member[] = [];
    @Input() permissionTypes: PermissionType[] = [];

    @Output() memberAdd = new EventEmitter<string>();
    @Output() memberRemove = new EventEmitter<string>();
    @Output() permissionGrant = new EventEmitter<{ memberId: string; permission: PermissionValueType }>();
    @Output() permissionDeny = new EventEmitter<{ memberId: string; permission: PermissionValueType }>();

    @ViewChild('userSelect', { static: true }) userSelect: MatSelect;
    @ViewChild('userSelectorSearch', { static: true }) userSelectorSearch: MatSelectSearchComponent;

    options: any = {
        placeholderLabel: '',
        noEntriesFoundLabel: this.translocoService.translate('shared.permission-selector.no_results_found_label'),
    };
    userCtrl: UntypedFormControl = new UntypedFormControl();
    userFilterCtrl: UntypedFormControl = new UntypedFormControl();
    filteredTeams: Team[] = [];
    filteredUsers: User[] = [];
    filteredGroups: Group[] = [];
    searchString = '';
    network: BehaviorSubject<Company>;

    private me: PersonalSettings;
    private onDestroy = new Subject<void>();
    private allUsers: User[] = [];
    private allTeams: Team[] = [];
    private allGroups: Group[] = [];
    private defaultPlaceHolder = this.translocoService.translate('shared.permission-selector.select_users_and_teams');

    constructor(
        private core: CoreService,
        private peopleService: PeopleService,
        public teamService: TeamService,
        public userService: UserService,
        private groupService: GroupService,
        private cdr: ChangeDetectorRef,
        private translocoService: TranslocoService
    ) {}

    ngOnInit() {
        this.options.placeholderLabel = this.defaultPlaceHolder;
        this.network = this.core.network;
        this.me = this.core.user.value;

        this.core.teams.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.updateTeams();
            },
        });

        this.userFilterCtrl.valueChanges.pipe(debounceTime(300), takeUntil(this.onDestroy)).subscribe({
            next: filterValue => {
                if (!this.filteredUsers) {
                    return;
                }

                this.searchString = filterValue.toLocaleLowerCase().replace(/\s/g, '');
                this.updateUsers();
                this.updateTeams();
                this.updateGroups();
                this.setSelectorStatus();

                if (this.searchString) {
                    this.filteredUsers = this.filteredUsers.filter(user =>
                        user.display_name.toLocaleLowerCase().replace(/\s/g, '').includes(this.searchString)
                    );
                    this.filteredTeams = this.filteredTeams.filter(team =>
                        team.name.toLocaleLowerCase().replace(/\s/g, '').includes(this.searchString)
                    );
                    this.filteredGroups = this.filteredGroups.filter(group =>
                        group.name.toLocaleLowerCase().replace(/\s/g, '').includes(this.searchString)
                    );
                }
            },
        });

        this.network.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.init(),
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.members && changes.members.currentValue && !changes.members.firstChange) {
            this.updateUsers();
            this.updateTeams();
            this.updateGroups();
            this.setSelectorStatus();
            this.cdr.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    addMember(memberId: string): void {
        this.memberAdd.next(memberId);
        this.resetSelector();
    }

    trackByFn(index: number, item: Group | Team | User) {
        return item._id;
    }

    compareFn(a: Team | User, b: Team | User): boolean {
        return a && b ? a._id === b._id : a === b;
    }

    get workspaceIsSelected(): boolean {
        return this.members?.some(member => member.id.startsWith('network_'));
    }

    private init() {
        this.updateUsers();
        this.updateTeams();
        this.updateGroups();
        this.setSelectorStatus();

        this.userCtrl.markAsTouched();
    }

    private resetSelector() {
        this.userCtrl.markAsTouched();
    }

    private updateUsers() {
        this.allUsers = this.peopleService.usersByNetwork.value;

        const existingMemberIds = this.members?.map(member => member.id.split('_')[1]) || [];

        this.filteredUsers = this.allUsers.filter(user => !existingMemberIds.includes(user._id));
        const index = this.filteredUsers.findIndex(user => user._id === this.me._id);
        if (index > -1) {
            const user = this.filteredUsers[index];
            this.filteredUsers.splice(index, 1);
            this.filteredUsers.unshift(user);
        }
    }

    private updateGroups() {
        this.allGroups = this.groupService.getGroups();
        const existingMemberIds = this.members?.map(member => member.id.split('_')[1]) || [];

        this.filteredGroups = this.allGroups.filter(group => !existingMemberIds.includes(group._id));
    }

    private updateTeams() {
        this.allTeams = this.teamService.getTeams();
        const existingMemberIds = this.members?.map(member => member.id.split('_')[1]) || [];

        this.filteredTeams = this.allTeams.filter(team => !existingMemberIds.includes(team._id));
    }

    private setSelectorStatus() {
        if (this.filteredUsers.length === 0 && this.filteredTeams.length === 0 && this.filteredGroups.length === 0) {
            this.options.placeholderLabel = this.translocoService.translate('shared.permission-selector.all_selected_placeholder');
            this.userCtrl.disable();
        } else {
            this.options.placeholderLabel = this.defaultPlaceHolder;
            this.userCtrl.enable();
        }
    }
}
