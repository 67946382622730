/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { NgxStackViewComponent } from './ngx-stack-view/ngx-stack-view.component';

@NgModule({
    declarations: [NgxStackViewComponent],
    imports: [CommonModule, MatProgressSpinnerModule],
    exports: [NgxStackViewComponent],
})
export class NgxStackViewModule {}
