<div class="search-bar-wrapper" *ngIf="enableSearch">
    <mat-form-field (click)="$event.stopPropagation()" color="accent" appearance="outline" class="form-field search-field highlight-bg full-width">
        <mat-icon matPrefix svgIcon="hailer-search" class="dimmed-prefix"></mat-icon>
        <input matInput [placeholder]="'shared.user-menu.search_for_users' | transloco" [formControl]="searchForm"
            (click)="$event.stopPropagation()">
    </mat-form-field>
</div>
<div class="menu-container" [style.margin-top]="enableSearch ? '-2px' : 'unset'">
    <!-- Users section -->
    <button mat-menu-item disabled *ngIf="groupsTeams?.teams?.length > 0 || groupsTeams?.groups?.length > 0">
        <span class="menu-subheader">{{"shared.user-menu.users" | transloco}}</span>
    </button>
    <button mat-menu-item *ngFor="let user of (users | uidSorter:{search:searchForm.value})" (click)="openUserSidebar(user._id)">
        <app-user-profile-picture [userId]="user._id" [size]="32" style="margin-right: 8px"></app-user-profile-picture>
        <span style="text-transform: capitalize;">{{user.display_name}}</span>
    </button>

    <!-- Teams section -->
    <ng-container *ngIf="groupsTeams?.teams?.length > 0">
        <button mat-menu-item disabled>
            <span class="menu-subheader">{{"shared.user-menu.teams" | transloco}}</span>
        </button>
        <ng-container *ngFor="let team of groupsTeams.teams">
            <button mat-menu-item disabled *ngIf="!searchForm.value ||team.name.toLocaleLowerCase().trim().includes(searchForm.value.toLocaleLowerCase().trim())">
                <span class="team-group-name">{{ team.name }}</span>
            </button>
        </ng-container>
    </ng-container>

    <!-- Groups section -->
    <ng-container *ngIf="groupsTeams?.groups?.length > 0">
        <button mat-menu-item disabled>
            <span class="menu-subheader">{{"shared.user-menu.groups" | transloco}}</span>
        </button>
        <ng-container *ngFor="let group of groupsTeams.groups">
            <button mat-menu-item disabled *ngIf="!searchForm.value || group.name.toLocaleLowerCase().trim().includes(searchForm.value.toLocaleLowerCase().trim())">
                <span class="team-group-name">{{ group.name }}</span>
            </button>
        </ng-container>
    </ng-container>
</div>