/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { CoreService } from 'app/_services/core.service';
import { RPCService } from 'app/_services/rpc.service';
import { ActivitiesService, ActivityResult } from 'app/activities/activities.service';
import { Activity, CanBeLinkedFrom, Process } from '@app/models';
import { UserService } from 'app/_services/user.service';
import { ProcessService } from 'app/_services/process.service';
import { PermissionService } from 'app/_services/permission.service';
import { V3ActivityViewContextService } from 'app/v3-activity/v3-activity-view-context.service';
import { ActivityTemplateField, SetViewArguments } from 'app/_models/v3-activity.model';

interface EvaluateResponse {
    activity: any;
    context: any;
}

@Component({
    selector: 'app-function-editor-dialog',
    templateUrl: './function-editor-dialog.component.html',
    styleUrls: ['./function-editor-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        V3ActivityViewContextService,
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'activities', alias: 'activities' },
        },
    ],
})
export class FunctionEditorDialogComponent implements OnInit, OnDestroy {
    @ViewChild('depExpansion', { static: false }) expansion: MatExpansionPanel;

    page = 'edit';
    processId: string;
    activityId: string;
    fieldId: string;
    process: Process;
    field: any;
    fieldName = '';
    codeData = '';
    result: any;
    darkTheme = false;
    activity: Activity;

    templateField: ActivityTemplateField;

    dependencies = [];
    dependencyLabels: { [label: string]: string } = {};
    dependencyWarning: { [label: string]: boolean } = {};

    activityFields = [];
    linkedToFields = [];
    linkedFromFields = [];
    staticIds = [];

    error: string;

    evaluationDependencies: any;
    additionalEvaluationContext: any;

    codeChanged = new Subject<string>();

    editorOptions = {
        theme: 'vs-light',
        language: 'javascript',
        wordWrap: 'on',
        fontSize: '14px',
        fontFamily: 'monospace',
        scrollBeyondLastLine: false,
        minimap: {
            enabled: false,
        },
        inlayHints: {
            enabled: true,
        },
        scrollbar: {
            alwaysConsumeMouseWheel: false,
        },
        renderWhitespace: 'none',
    };

    themes = {
        light: 'vs-light',
        dark: 'vs-dark',
    };

    private buildComplete = false;
    private onDestroy = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<FunctionEditorDialogComponent>,
        public permissions: PermissionService,
        private rpc: RPCService,
        private core: CoreService,
        private activities: ActivitiesService,
        private processes: ProcessService,
        private cdr: ChangeDetectorRef,
        private userService: UserService,
        private snackBar: MatSnackBar,
        private viewContext: V3ActivityViewContextService,
        private translocoService: TranslocoService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // Disable ESC key closing the dialog (ESC is used to close popup in editor)
        dialogRef.disableClose = true;

        // Extreme foul hack, not to be left here
        setTimeout(() => {
            try {
                const javascriptDefaults = (window as any).monaco.languages.typescript.javascriptDefaults;
                javascriptDefaults.addExtraLib('const dep = {};', 'rowinfo.js');
            } catch (e) {}
        }, 500);
    }

    async ngOnInit() {
        if (!this.data.processId) {
            this.error = this.translocoService.translate('activities.function-editor-dialog.processid_required');
            return;
        }

        if (!this.data.fieldId) {
            this.error = this.translocoService.translate('activities.function-editor-dialog.fieldid_required');
            return;
        }

        this.processId = this.data.processId;
        this.fieldId = this.data.fieldId;

        if (this.fieldId === 'nameField') {
            this.fieldId = 'name';
        }

        this.process = this.core.processById(this.processId);

        if (!this.process) {
            this.error = 'Failed to get process.';
            console.log(`Failed to get processById(${this.processId})`);
            return;
        }

        // This is not actually a field, but should kinda be treated like one
        if (this.fieldId === 'name') {
            this.field = { function: this.process.nameFunction, label: 'Name', type: 'text' };
        } else {
            this.field = this.process.fields[this.fieldId];
        }

        if (!this.field) {
            this.error = 'Could not get field from process.';
            return;
        }

        this.code = this.field.function;
        if (this.field.functionVariables || (this.fieldId === 'name' && this.process.nameFunctionVariables)) {
            const functionVariables = this.field.functionVariables || this.process.nameFunctionVariables;
            Object.keys(functionVariables).forEach(label => {
                if (Array.isArray(functionVariables[label])) {
                    functionVariables[label].forEach(value => this.dependencies.push({ label, value }));
                } else {
                    this.dependencies.push({ label, value: functionVariables[label] });
                }
            });
        }

        this.fieldName = this.field.label;
        this.activityId = this.data.activityId;

        if (this.activityId) {
            this.activity = await this.activities.getActivity(this.activityId);
        }

        if (!this.activity) {
            const activity = await this.getFirstActivity(this.processId);

            if (activity) {
                this.activityId = activity._id;
                this.activity = activity;
            } else {
                this.activityId = null;
            }
        }

        this.codeChanged.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.onDestroy)).subscribe({
            next: code => this.evaluate(this.activityId, this.fieldId, code),
        });

        this.core.user.pipe(take(1)).subscribe({
            next: data => {
                if (data.globalSettings && data.globalSettings.theme) {
                    const theme = this.themes[data.globalSettings.theme] || 'vs-light';

                    this.editorOptions = { ...this.editorOptions, theme };
                    this.darkTheme = theme === 'vs-dark';
                    this.cdr.detectChanges();
                }
            },
        });

        this.buildDependenciesList();

        const setInfoArgs: SetViewArguments = {
            action: 'view',
            workflowId: this.processId,
            activityId: this.activityId,
            overviewPeek: true,
        };

        void this.viewContext.initializeSidenavInfo(setInfoArgs);
        this.templateField = this.getTemplateField();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    getTemplateField(): ActivityTemplateField {
        if (this.fieldId === 'name') {
            return {
                id: 'nameField',
                label: this.process.nameColumnText,
                placeholder: this.process.nameFieldPlaceHolderText,
                functionEnabled: this.process.nameFunctionEnabled,
            };
        }

        const processField = this.process.fields[this.fieldId];
        return {
            id: this.fieldId,
            label: processField.label,
            required: processField.required,
            placeholder: processField.placeholder,
            subtype: processField.type,
            data: processField.data,
            description: processField.description,
            unit: processField.unit,
            defaultTo: processField.defaultTo,
            defaultValue: processField.defaultValue,
            functionEnabled: processField.functionEnabled,
        };
    }

    buildDependenciesList() {
        this.activities
            .getLinkableProcesses(this.process._id)
            .pipe(take(1))
            .subscribe({
                next: (result: CanBeLinkedFrom[]) => {
                    const getNameText = process => process.nameColumnText || 'Name';

                    const addProcess = process => {
                        if (this.staticIds.some(obj => obj.value[0].value.data[0] === process._id)) {
                            return;
                        }

                        const ids = [];
                        this.staticIds.push({ label: process.name, value: ids });
                        const processValue = { type: '?', data: [process._id] };
                        ids.push({ label: 'Process', value: processValue });
                        this.dependencyLabels[this.valueToUnique(processValue)] = this.translocoService.translate(
                            'activities.function-editor-dialog.dependency-labels.process_name',
                            {
                                processName: process.name,
                            }
                        );

                        process.phasesOrder.forEach(phaseId => {
                            const phase = process.phases[phaseId];
                            const value = { type: '?', data: [process._id, phase._id] };
                            ids.push({ label: phase.name, value });
                            this.dependencyLabels[this.valueToUnique(value)] = `Phase Id: ${phase.name} of ${process.name}`;
                        });
                    };

                    this.activityFields.push({ label: 'Data', value: { type: '=', data: ['data'] } });
                    this.activityFields.push({ label: getNameText(this.process), value: { type: '=', data: ['data', 'name'] } });
                    this.dependencyLabels.data = this.translocoService.translate(
                        'activities.function-editor-dialog.dependency-labels.field_data'
                    );
                    this.dependencyLabels['data=name'] = this.translocoService.translate(
                        'activities.function-editor-dialog.dependency-labels.field_name',
                        {
                            fieldLabel: getNameText(this.process),
                        }
                    );

                    addProcess(this.process);

                    Object.keys(this.process.fields).forEach(fieldId => {
                        // Same activity
                        const value = { type: '=', data: [fieldId] };
                        this.activityFields.push({ label: this.process.fields[fieldId].label, value });
                        this.dependencyLabels[this.valueToUnique(value)] = this.translocoService.translate(
                            'activities.function-editor-dialog.dependency-labels.field_name',
                            {
                                fieldLabel: this.process.fields[fieldId].label,
                            }
                        );

                        if (this.process.fields[fieldId].type === 'activitylink') {
                            this.process.fields[fieldId].data.forEach(processId => {
                                const linkedProcess = this.processes.getProcess(processId);
                                if (linkedProcess) {
                                    addProcess(linkedProcess);

                                    const fields = [];
                                    const dataValue = { type: '>', data: [fieldId, processId, 'data'] };
                                    const nameValue = { type: '>', data: [fieldId, processId, 'data', 'name'] };
                                    fields.push({ label: 'Data', value: dataValue });
                                    fields.push({ label: getNameText(linkedProcess), value: nameValue });

                                    this.dependencyLabels[this.valueToUnique(dataValue)] = this.translocoService.translate(
                                        'activities.function-editor-dialog.dependency-labels.linked_to_fields_data',
                                        {
                                            linkedProcessName: linkedProcess.name,
                                            fieldLabel: this.process.fields[fieldId].label,
                                        }
                                    );

                                    this.dependencyLabels[this.valueToUnique(nameValue)] = this.translocoService.translate(
                                        'activities.function-editor-dialog.dependency-labels.linked_to_fields_name',
                                        {
                                            linkedNameText: getNameText(linkedProcess),
                                            linkedProcessName: linkedProcess.name,
                                            fieldLabel: this.process.fields[fieldId].label,
                                        }
                                    );

                                    Object.keys(linkedProcess.fields).forEach(fieldId2 => {
                                        // Linked to
                                        const label = linkedProcess.fields[fieldId2].label;
                                        const linkedValue = { type: '>', data: [fieldId, fieldId2] };
                                        this.dependencyLabels[this.valueToUnique(linkedValue)] = this.translocoService.translate(
                                            'activities.function-editor-dialog.dependency-labels.linked_to_fields_name',
                                            {
                                                linkedNameText: label,
                                                linkedProcessName: linkedProcess.name,
                                                fieldLabel: this.process.fields[fieldId].label,
                                            }
                                        );

                                        fields.push({ label, value: linkedValue });
                                    });
                                    const mainLabel = `${this.process.fields[fieldId].label}: ${linkedProcess.name}`;
                                    this.linkedToFields.push({ label: mainLabel, value: fields });
                                }
                            });
                        }
                    });

                    // Checking if the user has access to the processes first phase
                    const addedProcesses: string[] = [];
                    result.forEach(canBeLinkedFrom => {
                        const processId = canBeLinkedFrom.process._id;
                        if (!addedProcesses.includes(processId) && this.permissions.viewOnlyPhase(this.core.user.value?.id, processId)) {
                            addedProcesses.push(processId);
                            const linkedProcess = this.processes.getProcess(processId);
                            addProcess(linkedProcess);

                            const fields = [];
                            fields.push({ label: 'Data', value: { type: '<', data: [processId, 'data'] } });
                            fields.push({ label: getNameText(linkedProcess), value: { type: '<', data: [processId, 'data', 'name'] } });
                            this.dependencyLabels[`${processId}<data`] = this.translocoService.translate(
                                'activities.function-editor-dialog.dependency-labels.linked_from_fields_data',
                                {
                                    linkedProcessName: canBeLinkedFrom.process.name,
                                }
                            );

                            this.dependencyLabels[`${processId}<data<name`] = this.translocoService.translate(
                                'activities.function-editor-dialog.dependency-labels.linked_from_fields_name',
                                {
                                    linkedProcessName: canBeLinkedFrom.process.name,
                                    fieldLabel: getNameText(linkedProcess),
                                }
                            );

                            Object.keys(canBeLinkedFrom.process.fields).forEach(fieldId => {
                                // Linked from
                                const label = canBeLinkedFrom.process.fields[fieldId].label;
                                const value = { type: '<', data: [processId, fieldId] };
                                this.dependencyLabels[this.valueToUnique(value)] = this.translocoService.translate(
                                    'activities.function-editor-dialog.dependency-labels.linked_from_fields_name',
                                    {
                                        linkedProcessName: canBeLinkedFrom.process.name,
                                        fieldLabel: label,
                                    }
                                );

                                fields.push({ label, value });
                            });
                            this.linkedFromFields.push({ label: canBeLinkedFrom.process.name, value: fields });
                        }
                    });

                    this.buildComplete = true;
                    this.cdr.detectChanges();
                },
                error: (error: any) => {
                    console.error('getLinkableProcesses error:', error);
                },
            });
    }

    async getFirstActivity(processId: string): Promise<Activity> {
        const process = this.core.processById(processId);
        const phaseId = process.phasesOrder[0];

        return new Promise((resolve, reject) => {
            const match = {
                phase: phaseId,
                process: process._id,
            };

            const options = {
                sortBy: 'priority',
                sortOrder: 'asc',
                limit: 1,
                skip: 0,
                search: null,
            };

            this.activities
                .listActivitiesV2(match, options)
                .pipe(take(1))
                .subscribe({
                    next: (activity: ActivityResult) => resolve(activity.activities[0]),
                });
        });
    }

    get code() {
        return this.codeData;
    }

    set code(code: string) {
        this.codeData = code;
        this.codeChanged.next(code);
    }

    /**
     * Evaluate the fields of an activity
     *
     * Evaluate the fields of an activity with given code without
     * changing code in the Hailer process.
     *
     * This is used to debug and develop function fields.
     *
     * @param activityId
     * @param fieldId
     * @param code Javascript code
     */
    async evaluate(activityId: string, fieldId: string, code: string) {
        if (!this.canEvaluate(activityId, code)) {
            this.result = null;
            return;
        }

        let evaluated: EvaluateResponse;

        try {
            evaluated = (await this.rpc.requestAsync('v2.activities.evaluate', [activityId, fieldId, code, this.dependenciesObj])) as any;
        } catch (err) {
            this.error = err.msg;
            this.cdr.detectChanges();

            return;
        }

        const activity = evaluated.activity;

        this.process.fields = this.core.processById(activity.process).fields;

        if (fieldId === 'name') {
            // Fake the process field (for app-activity-field-view)

            // Fake the activity field (for app-activity-field-view)
            activity.fields.push({
                id: '::name',
                value: activity.name,
                type: 'text',
                error: activity.nameError || null,
            });
        }

        activity.fields = activity.fields.reduce((prev, curr) => {
            prev[curr.id] = {
                value: curr.value,
                ...(curr.error ? { error: curr.error } : null),
            };
            return prev;
        }, {});

        this.result = activity;
        this.viewContext.activity.next(this.result);
        /* Compare the new evaluation context to the current to allow collapsing
           only when evaluation has changed */
        if (JSON.stringify(this.evaluationDependencies) !== JSON.stringify(evaluated.context)) {
            this.evaluationDependencies = { dep: evaluated.context?.dependencies };

            const restEval = evaluated.context;
            // Remove dependencies from the additional evaluation context
            delete restEval.dependencies;
            this.additionalEvaluationContext = restEval;
        }

        /* Very hacky solution to inject auto-completion data into monaco.
           TODO: Look into javascriptDefaults.setExtraLibs missing in the monaco version we use. */
        if (evaluated.context && evaluated.context.row) {
            const javascriptDefaults = (window as any).monaco.languages.typescript.javascriptDefaults;
            javascriptDefaults._extraLibs['rowinfo.js'] = [
                '/** Row fields and link information */',
                `const dep = ${JSON.stringify(evaluated.context.dependencies)};`,
                '/** Process meta information */',
                `const info = ${JSON.stringify(evaluated.context.info)};`,
            ].join('\n');
            // Monaco.languages.typescript.javascriptDefaults.addExtraLib() cannot update old scripts
            javascriptDefaults.addExtraLib('// dummy lib');
        }

        this.cdr.detectChanges();
    }

    /**
     * Save function to process
     *
     * Saves function field code or displays error.
     *
     * If fieldId is set to `name` the `nameFunction` is updated using `process.set_info`, else
     * update field function using `process.update_field`.
     */
    async saveFunction(code: string) {
        if (code === '') {
            return;
        }

        this.page = 'saving';
        this.cdr.detectChanges();

        let op = 'process.update_field';
        let args = [this.processId, this.fieldId, { function: code, functionVariables: this.dependenciesObj } as any];

        // Override in case we are saving the name-field function
        if (this.fieldId === 'name') {
            op = 'process.set_info';
            args = [this.processId, { nameFunction: code, nameFunctionVariables: this.dependenciesObj }];
        }

        try {
            await this.rpc.requestAsync(op, args);
        } catch (error) {
            // Could not save function
            this.page = 'error';
            this.error = error.msg;
            this.cdr.detectChanges();

            return;
        }

        this.close({ saved: true });
    }

    close(options: { saved?: boolean } = {}) {
        this.dialogRef.close({ saved: options.saved, code: this.code });
    }

    setTheme(theme: 'vs-dark' | 'vs-light') {
        this.darkTheme = theme === 'vs-dark';
        this.userService.setFunctionEditorTheme(theme);
    }

    isString(val) {
        return typeof val === 'string';
    }

    addDependency(label: string, value: string) {
        this.dependencies.push({ label, value });
        if (this.expansion && !this.expansion.expanded) {
            this.expansion.open();
        }

        this.evaluate(this.activityId, this.fieldId, this.code);
    }

    removeDependency(dependency) {
        const index = this.dependencies.findIndex(item => dependency.value === item.value);
        this.dependencies.splice(index, 1);
        this.evaluate(this.activityId, this.fieldId, this.code);
    }

    setVariable(dependency, value) {
        if (value) {
            dependency.label = value;
        }
        this.evaluate(this.activityId, this.fieldId, this.code);
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.dependencies, event.previousIndex, event.currentIndex);
        this.evaluate(this.activityId, this.fieldId, this.code);
    }

    getDependencyLabels(dependency): string {
        if (!this.buildComplete) {
            return;
        }

        const value = this.valueToUnique(dependency);

        try {
            if (this.dependencyLabels[value]) {
                return this.dependencyLabels[value];
            }

            if (dependency.type === '>') {
                const [linkedFieldId, fieldId] = dependency.data;
                for (const processId of this.process.fields[linkedFieldId].data) {
                    const process = this.processes.getProcess(processId);
                    if (process.fields[fieldId]) {
                        const label = process.fields[fieldId].label;
                        this.dependencyLabels[value] = this.translocoService.translate(
                            'activities.function-editor-dialog.dependency-labels.linked_to_fields_name',
                            {
                                linkedNameText: label,
                                linkedProcessName: process.name,
                                fieldLabel: this.process.fields[fieldId].label,
                            }
                        );
                        break;
                    }
                }
            } else if (dependency.type === '<') {
                const [processId, fieldId] = dependency.data;
                const process = this.processes.getProcess(processId);
                const label = process.fields[fieldId]?.label || 'Unknown field';
                this.dependencyLabels[value] = this.translocoService.translate(
                    'activities.function-editor-dialog.dependency-labels.linked_from_fields_name',
                    {
                        linkedProcessName: process.name,
                        fieldLabel: label,
                    }
                );
            } else if (dependency.type === '?') {
                const [processId, phaseId] = dependency.data;
                const process = this.processes.getProcess(processId);
                if (process) {
                    if (phaseId) {
                        this.dependencyLabels[value] = this.translocoService.translate(
                            'activities.function-editor-dialog.dependency-labels.process_phase_name',
                            {
                                linkedProcessName: process.name,
                                phaseName: process.phases[phaseId].name,
                            }
                        );
                    } else {
                        this.dependencyLabels[value] = this.translocoService.translate(
                            'activities.function-editor-dialog.dependency-labels.process_name',
                            {
                                processName: process.name,
                            }
                        );
                    }
                } else {
                    this.dependencyLabels[value] = this.translocoService.translate(
                        'activities.function-editor-dialog.dependency-labels.unknown_process'
                    );
                }
            }
        } catch (error) {
            this.dependencyLabels[value] = this.translocoService.translate(
                'activities.function-editor-dialog.dependency-labels.corrupted_dependency'
            );
        }
        this.dependencyWarning[value] = true;
        return (
            this.dependencyLabels[value] ||
            this.translocoService.translate('activities.function-editor-dialog.dependency-labels.unknown_field', {
                value,
            })
        );
    }

    isBadDependency(value) {
        return value.data ? this.dependencyWarning[this.valueToUnique(value)] : true;
    }

    async copyToClipboard(jsonObj: any) {
        // Stringify & prettify JSON object
        let objString = JSON.stringify(jsonObj, null, 4);
        // Remove outer curly brackets from the string
        objString = objString.substring(1, objString.length - 1);
        await navigator.clipboard.writeText(objString);
        this.snackBar.open(this.translocoService.translate('activities.function-editor-dialog.dep_copied'), 'OK', { duration: 2000 });
    }

    get outputIsError() {
        return this.result?.fields[this.fieldId === 'name' ? 'name' : this.fieldId]?.error;
    }

    private canEvaluate(activityId, code) {
        return activityId && code?.trim();
    }

    // Turn dependency object into a unique string
    private valueToUnique(value: { type: string; data: string[] }) {
        return value.data ? value.data.join(value.type) : '';
    }

    private get dependenciesObj() {
        return this.dependencies.reduce((acc, cur) => {
            if (acc[cur.label]) {
                // Stacked variable
                if (!Array.isArray(acc[cur.label])) {
                    acc[cur.label] = [acc[cur.label]];
                }
                acc[cur.label].push(cur.value);
            } else {
                // Not stacked variable
                acc[cur.label] = cur.value;
            }
            return acc;
        }, {});
    }
}
