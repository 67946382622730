import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { ResultDialogData } from '../poll/poll.component';

@Component({
    selector: 'app-poll-view-results-dialog',
    templateUrl: './poll-view-results-dialog.component.html',
    styleUrls: ['./poll-view-results-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'poll' }],
})
export class PollViewResultsDialogComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ResultDialogData,
        private dialogRef: MatDialogRef<PollViewResultsDialogComponent>) {}

    close(): void {
        if (!this.dialogRef) {
            return;
        }

        this.dialogRef.close(undefined);
    }
}
