<!-- @format -->

<div class="fill-field-container" style="font-size: 14px" (click)="$event.stopPropagation()">
	<mat-form-field data-cy="user-role-selector" appearance="outline" class="full-width">
		<mat-select #optionSelect (selectionChange)="changePermission($event)" [value]="preselectedRole || permissionType" [disabled]="disableSelector()">
			<mat-option data-cy="user-role-selector-option" [value]="'user'">{{ 'settings.workspace-permission-selector.user' | transloco }}</mat-option>
			<mat-option data-cy="user-role-selector-option" [value]="'inviter'">{{ 'settings.workspace-permission-selector.inviter' | transloco }}</mat-option>
			<mat-option data-cy="user-role-selector-option" [value]="'guest'">{{ 'settings.workspace-permission-selector.guest' | transloco }}</mat-option>
			<mat-option data-cy="user-role-selector-option" [value]="'admin'" [disabled]="!permissions.isNetworkAdmin">
				{{ 'settings.workspace-permission-selector.admin' | transloco }}
			</mat-option>
			<mat-option data-cy="user-role-selector-option" [value]="'owner'" [disabled]="!permissions.isNetworkOwner">
				{{ 'settings.workspace-permission-selector.owner' | transloco }}
			</mat-option>

			<!-- Custom roles -->
			<mat-option data-cy="user-role-selector-option" *ngFor="let role of customRoles" [value]="role._id"
				[disabled]="!(core.permission.role.users.update(network._id) | hasPermission)">
				{{ role.name }}
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>
