<div class="topbar-container">
    <h3 mat-dialog-title>{{'shared.license-exceeded-dialog.workspace_lisence_exceeded' | transloco}}</h3>
    <button data-cy="license-exceeded-dialog-close-button" class="close-btn" mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button> 
</div>
<div class="dialog-content">
    <div>
        <h3 *ngIf="permissions.isNetworkOwner" data-cy="license-exceeded-dialog-text">{{'shared.license-exceeded-dialog.upgrade_hailer_to_continue' | transloco}}</h3>
        <h3 *ngIf="!permissions.isNetworkOwner">{{'shared.license-exceeded-dialog.contact_workspace_owner' | transloco}}</h3>
        <ul>
            <li>{{'shared.license-exceeded-dialog.sales_text1' | transloco}}</li>
            <li>{{'shared.license-exceeded-dialog.sales_text2' | transloco}}</li>
            <li>{{'shared.license-exceeded-dialog.sales_text3' | transloco}}</li>
        </ul>
    </div>
    <img crossorigin [src]="'assets/img/mr_unlimited.png'" [draggable]="false">
</div>
<div class="buttons-container">
    <button *ngIf="permissions.isNetworkOwner" mat-flat-button color="accent" (click)="onBuy()" class="single-row-button" [mat-dialog-close]="true">
        <span *ngIf="data['trial']">{{'shared.license-exceeded-dialog.start_free_trial' | transloco}}</span>
        <span *ngIf="!data['trial']">{{'shared.license-exceeded-dialog.buy_now' | transloco}}</span>
    </button>
</div>