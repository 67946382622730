<div class="messages-container" #messagesContainer [class.sidenav-container]="sidenavMode">
    <div *ngIf="!sidenavMode && screenWidth > 600" class="date-divider top text message"></div>
    <div *ngIf="(messages | async)?.length && !(oldestLoaded | async)" class="skeleton-container">
        <ng-container *ngFor="let skeletonMessage of skeletonMessages">
            <ng-container *ngTemplateOutlet="skeletonTemplate; context:{ $implicit: skeletonMessage }"></ng-container>
        </ng-container>
    </div>

    <div *ngIf="newContact | async">
        <span data-cy="new-private-discussion-message" class="new-contact-message">{{'discussion.messages_container.start_discussion' | transloco}} {{ discussionSubject }}.</span>
    </div>

    <!--For every message-->
    <div data-cy="discussion-message-item" *ngFor="let message of (messages | async)" class="message"
        [class.consecutive]="message.isConsecutive && message.type === 'user'" [class.date-divider]="message.dateDivider" #message
        [class.highlight-message]="(editingMessage | async)[message._id]?.editing || (contextMenuMessage | async)?._id === message._id"
        [attr.focused-message]="message._id === this.focusedMessage?._id ? true : null"
        (contextmenu)="(noSelection && message.type === 'user' && !inMobileDiscussionView && !message.removed) ? openContextMenu($event, message) : null"
        [attr.id]="message._id" [style.position]="!inMobileDiscussionView && !sidenavMode ? 'sticky' : 'relative'">
        <!-- #region ACTIVITY MESSAGES -->
        <div class="container activity-created-body" *ngIf="message.type === 'activity.created'">
            <mat-icon class="activity-icon" svgIcon="hailer-activity"></mat-icon>
            <div class="flexbox-column">
                <div class="message-header activity-created-header">
                    <span>{{'discussion.messages_container.activity_created' | transloco}}
                        <app-user-link
                            [userId]="message.uid"
                            [color]="(participantColorMap | async)[message.uid] || 'var(--user-link-default)'"
                            [darkLink]="theme.getActiveTheme().name === 'light'">
                        </app-user-link>
                    </span>
                    <span class="timestamp-darker">{{message.created | date:'HH:mm'}}</span>
                </div>
                <div class="activity-created data-render-style">
                    <app-activity-data-render *ngIf="core.processFromPhaseId(message.meta?.currentPhase); else noPermission"
                        [process]="core.processFromPhaseId(message.meta?.currentPhase)" [discussionMode]="true" [activity]="message.meta">
                    </app-activity-data-render>
                </div>
            </div>
        </div>

        <ng-container *ngIf="message.type?.includes('activity') && message.type !== 'activity.created'">
            <div class="message-body">
                <mat-icon *ngIf="['activity.updated', 'activity.removed'].includes(message.type)" svgIcon="hailer-activity"
                    class="activity-icon"></mat-icon>
                <mat-icon *ngIf="['linked.activity.created', 'linked.activity.moved'].includes(message.type)"
                    svgIcon="hailer-linked-activity" class="activity-icon"></mat-icon>
                <div class="flexbox-column activity-message">
                    <ng-container *ngIf="message.type === 'activity.updated'">
                        <div class="message-header">
                            <span>
                                {{'discussion.messages_container.activity_updated' | transloco}}
                                <app-user-link
                                    [userId]="message.uid"
                                    [color]="(participantColorMap | async)[message.uid] || 'var(--user-link-default)'"
                                    [darkLink]="theme.getActiveTheme().name === 'light'">
                                </app-user-link>
                            </span>
                            <span class="timestamp">{{message.created | date:'HH:mm'}}</span>
                        </div>
                        <app-message-activity-update *ngIf="core.processById(message.meta._process); else noPermission"
                            [message]="getDeepCopy(message)" [process]="core.processById(message.meta._process)">
                        </app-message-activity-update>
                    </ng-container>

                    <ng-container *ngIf="message.type === 'activity.removed'">
                        <div class="message-header">
                            <span>
                                {{'discussion.messages_container.activity_removed' | transloco}}
                                <app-user-link
                                    [userId]="message.uid"
                                    [color]="(participantColorMap | async)[message.uid] || 'var(--user-link-default)'"
                                    [darkLink]="theme.getActiveTheme().name === 'light'">
                                </app-user-link>
                            </span>
                            <span class="timestamp">{{message.created | date:'HH:mm'}}</span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="message.type === 'linked.activity.created'">
                        <div class="message-header">
                            <span>
                                {{'discussion.messages_container.linked_activity_created' | transloco}} 
                                <app-user-link
                                    [userId]="message.uid"
                                    [color]="(participantColorMap | async)[message.uid] || 'var(--user-link-default)'"
                                    [darkLink]="theme.getActiveTheme().name === 'light'">
                                </app-user-link>
                            </span>
                            <span class="timestamp">{{message.created | date:'HH:mm'}}</span>
                        </div>
                        <span class="timestamp-padding">
                            <strong class="linked-activity-name"
                                (click)="openActivity(message.meta.activity._id, message.meta.activity.process)">{{message.meta.activity.name}}</strong>
                                {{'discussion.messages_container.in' | transloco}}
                            <strong>{{message.meta.process.name}}</strong>
                            {{'discussion.messages_container.was_linked' | transloco}}
                        </span>
                    </ng-container>

                    <ng-container *ngIf="message.type === 'linked.activity.moved'">
                        <div class="message-header">
                            <span>
                                {{'discussion.messages_container.linked_activity_moved' | transloco}}
                                <app-user-link
                                    [userId]="message.uid"
                                    [color]="(participantColorMap | async)[message.uid] || 'var(--user-link-default)'"
                                    [darkLink]="theme.getActiveTheme().name === 'light'">
                                </app-user-link>
                            </span>
                            <span class="timestamp">{{message.created | date:'HH:mm'}}</span>
                        </div>
                        <span class="timestamp-padding">
                            <strong class="linked-activity-name"
                                (click)="openActivity(message.meta.activity._id, message.meta.activity.process)">{{message.meta.activity.name}}</strong>
                                {{'discussion.messages_container.in' | transloco}} <strong>{{message.meta.process.name}}</strong>
                                {{'discussion.messages_container.was_moved' | transloco}} <strong>{{message.meta.process.phases[message.meta.movedFrom].name}}</strong>
                                {{'discussion.messages_container.to' | transloco}} <strong>{{message.meta.process.phases[message.meta.movedTo].name}}</strong>
                        </span>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-template #noPermission>
            <span class="no-permission">
                <mat-icon>block_outline</mat-icon>{{'discussion.messages_container.no_permission' | transloco}}
            </span>
        </ng-template>
        <!-- #endregion -->

        <!-- #region EVENT MESSAGES -->
        <ng-container *ngIf="message.type?.includes('event')">
            <div class="message-body">
                <mat-icon class=" event-icon" svgIcon="hailer-events"></mat-icon>
                <div class="flexbox-column event-message">
                    <ng-container *ngIf="message.type === 'event.new'">
                        <div class="message-header" *ngIf="message.type === 'event.new'">
                            <span>
                                {{'discussion.messages_container.event_created' | transloco}}
                                <app-user-link
                                    [userId]="message.uid"
                                    [color]="(participantColorMap | async)[message.uid] || 'var(--user-link-default)'"
                                    [darkLink]="theme.getActiveTheme().name === 'light'">
                                </app-user-link>
                            </span>
                            <span class="timestamp">{{message.created | date:'HH:mm'}}</span>
                        </div>
                        <app-event-data [event]="message.meta"></app-event-data>
                    </ng-container>

                    <ng-container *ngIf="message.type === 'event.updated'">
                        <div class="message-header" *ngIf="message.type === 'event.updated'">
                            <span>
                                {{'discussion.messages_container.event_updated' | transloco}}
                                <app-user-link
                                    [userId]="message.uid"
                                    [color]="(participantColorMap | async)[message.uid] || 'var(--user-link-default)'"
                                    [darkLink]="theme.getActiveTheme().name === 'light'">
                                </app-user-link>
                            </span>
                            <span class="timestamp">{{message.created | date:'HH:mm'}}</span>
                        </div>
                        <app-event-data [event]="message.meta"></app-event-data>
                    </ng-container>

                    <ng-container *ngIf="message.type === 'event.removed'">
                        <div class="message-header" *ngIf="message.type === 'event.removed'">
                            <span>
                                {{'discussion.messages_container.event_removed' | transloco}}
                                <app-user-link
                                    [userId]="message.uid"
                                    [color]="(participantColorMap | async)[message.uid] || 'var(--user-link-default)'"
                                    [darkLink]="theme.getActiveTheme().name === 'light'">
                                </app-user-link>
                            </span>
                            <span class="timestamp">{{message.created | date:'HH:mm'}}</span>
                        </div>
                        <span class="timestamp-padding">{{'discussion.messages_container.continue' | transloco}}</span>
                    </ng-container>

                    <div *ngIf="message.type === 'event.attend_response'">
                        <app-message-event-attend [message]="getDeepCopy(message)"></app-message-event-attend>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- #endregion -->

        <!-- #region PARTICIPANT MESSAGES -->
        <ng-container *ngIf="message.type?.includes('discussion') || message.type === 'user.join' || message.type === 'user.leave'">
            <div class="discussion-participating">
                <ng-container *ngIf="message.type === 'user.join'">
                    <mat-icon class="participation-icon" color="accent">redo</mat-icon>
                    <app-user-link
                        [userId]="message.uid"
                        [color]="(participantColorMap | async)[message.uid] || 'var(--user-link-default)'"
                        [darkLink]="theme.getActiveTheme().name === 'light'">
                    </app-user-link>
                    {{'discussion.messages_container.joined' | transloco}}
                </ng-container>

                <ng-container *ngIf="message.type === 'user.leave'">
                    <mat-icon class="participation-icon" color="primary">undo</mat-icon>
                    <app-user-link
                        [userId]="message.uid"
                        [color]="(participantColorMap | async)[message.uid] || 'var(--user-link-default)'"
                        [darkLink]="theme.getActiveTheme().name === 'light'">
                    </app-user-link>
                    {{'discussion.messages_container.left' | transloco}}
                </ng-container>

                <ng-container *ngIf="message.type === 'discussion.invites'">
                    <app-user-link
                        [userId]="message.uid"
                        [color]="(participantColorMap | async)[message.uid] || 'var(--user-link-default)'"
                        [darkLink]="theme.getActiveTheme().name === 'light'">
                    </app-user-link>
                    {{'discussion.messages_container.invited' | transloco}}
                    <span *ngFor="let userId of message.meta; index as i; last as isLast" class="user-link-with-comma-or-and-wrapper">
                        <app-user-link
                            [userId]="userId"
                            [color]="(participantColorMap | async)[userId] || 'var(--user-link-default)'"
                            [darkLink]="theme.getActiveTheme().name === 'light'">
                        </app-user-link>
                        <span *ngIf="(i == message.meta.length - 2)" class="and-wrapper">{{'discussion.messages_container.and' | transloco}}</span>
                        <span *ngIf="!(i == message.meta.length - 2) && !isLast">,</span>
                    </span>
                    {{'discussion.messages_container.join_discussion' | transloco}}
                </ng-container>

                <ng-container *ngIf="message.type === 'discussion.removesinvites'">
                    <app-user-link
                        [userId]="message.uid"
                        [color]="(participantColorMap | async)[message.uid] || 'var(--user-link-default)'"
                        [darkLink]="theme.getActiveTheme().name === 'light'">
                    </app-user-link>
                    {{'discussion.messages_container.removed' | transloco}}
                    <span *ngFor="let userId of message.meta.removed; index as i; last as isLast">
                        <app-user-link
                            [userId]="userId"
                            [color]="(participantColorMap | async)[userId] || 'var(--user-link-default)'"
                            [darkLink]="theme.getActiveTheme().name === 'light'">
                        </app-user-link>
                        {{isLast ? '' : (i == message.meta.length - 2) ? ('discussion.messages_container.and' | transloco) : ','}}
                    </span>
                    {{'discussion.messages_container.from_discussion' | transloco}}
                </ng-container>

                <ng-container *ngIf="message.type === 'discussion.update'">
                    <app-user-link
                        [userId]="message.meta.uid"
                        [color]="(participantColorMap | async)[message.meta.uid] || 'var(--user-link-default)'"
                        [darkLink]="theme.getActiveTheme().name === 'light'">
                    </app-user-link>
                    {{'discussion.messages_container.updated' | transloco}}
                </ng-container>
            </div>
        </ng-container>
        <!-- #endregion -->

        <ng-container *ngIf="message.type === 'meet.start'">
            <app-message-meet [message]="getDeepCopy(message)" [participantColorMap]="(participantColorMap | async)"></app-message-meet>
        </ng-container>

        <!-- #region Message content-->
        <ng-container *ngIf="message.type === 'user'">
            <app-message-user [style.width]="'100%'" [message]="message" (reactToMessage)="reactToMessage($event.message, $event.reaction)" (starMessage)="starMessage(message)"></app-message-user>
        </ng-container>
        <ng-container *ngIf="message.dateDivider">
            <div class="date-divider-text">{{message.created | lastWeekDay | async}}</div>
        </ng-container>

        <!-- #endregion -->

        <!-- #region message actions -->
        <div data-cy="discussion-hover-container" *ngIf="message.type === 'user' && !message.removed && !(editingMessage | async)[message._id]?.editing && !inMobileDiscussionView"
			class="container align-items-center message-options-container">
			<app-button
				dataCy="discussion-edit-button-hover"
				*ngIf="message.uid === (me | async)?.id"
				(clickEvent)="editMessage(message)"
				tippyText="{{ 'discussion.messages_container.edit_message' | transloco }}"
				ariaLabel="{{ 'discussion.messages_container.edit_message' | transloco }}"
				type="icon"
				size="small"
				color="icon-default"
				hailerIconName="hailer-edit"
				focusOutline="inner"></app-button>
			<app-button
				dataCy="discussion-react-button-hover"
				*ngIf="message.uid !== (me | async)?.id"
				[menuTriggerFor]="reactions"
				[menuTriggerData]="{ message: message }"
				tippyText="{{ 'discussion.messages_container.add_reaction' | transloco }}"
				ariaLabel="{{ 'discussion.messages_container.add_reaction' | transloco }}"
				type="icon"
				size="small"
				color="icon-default"
				hailerIconName="hailer-reaction"
				focusOutline="inner"></app-button>
			<app-button
				dataCy="discussion-reply-button-hover"
				*ngIf="message.uid !== (me | async)?.id"
				(clickEvent)="replyToMessage(message); closeDiscussionInputMenu('forward')"
				tippyText="{{ 'discussion.messages_container.reply_message' | transloco }}"
				ariaLabel="{{ 'discussion.messages_container.reply_message' | transloco }}"
				type="icon"
				size="small"
				color="icon-default"
				hailerIconName="hailer-reply"
				focusOutline="inner"></app-button>
			<app-button
				dataCy="discussion-star-button-hover"
				*ngIf="message.uid !== (me | async)?.id"
				(clickEvent)="starMessage(message)"
				[tippyText]="
					message?.isStarred ? ('discussion.messages_container.unstar' | transloco) : ('discussion.messages_container.star' | transloco)
				"
				[ariaLabel]="
					message?.isStarred ? ('discussion.messages_container.unstar' | transloco) : ('discussion.messages_container.star' | transloco)
				"
				type="icon"
				size="small"
				color="icon-default"
				[hailerIconName]="message?.isStarred ? 'hailer-unstar' : 'hailer-star'"
				focusOutline="inner"></app-button>
			<app-button
				dataCy="discussion-more-options-hover"
				type="icon"
				size="small"
				color="icon-default"
				hailerIconName="hailer-see-more"
				[menuTriggerFor]="more"
				[menuTriggerData]="{ message: message }"
				tippyText="{{ 'discussion.messages_container.options' | transloco }}"
				ariaLabel="{{ 'discussion.messages_container.aria-options' | transloco }}"
				focusOutline="inner"></app-button>
        </div>

        <!-- #endregion -->
    </div>

    <div *ngIf="screenWidth <= 600 && (contextMenuMessage | async)" class="context-menu-overlay">
        <div (touchstart)="closeContextMenu(); $event.stopPropagation()" class="close-area"></div>
        <div class="context-menu-container">

            <ng-container *ngTemplateOutlet="reactionActionContainer; context: {$implicit: contextMenuMessage.value}" ></ng-container>

            <button data-cy="discussion-edit-button" mat-menu-item *ngIf="(contextMenuMessage | async).uid === (me | async)?.id" (click)="editMessage(contextMenuMessage.value); closeContextMenu()">
                <mat-icon svgIcon="hailer-edit"></mat-icon>
                {{'discussion.messages_container.edit' | transloco}}
            </button>

            <button data-cy="discussion-reply-button" mat-menu-item (click)="replyToMessage(contextMenuMessage.value); closeContextMenu(); closeDiscussionInputMenu('forward')">
                <mat-icon svgIcon="hailer-reply"></mat-icon>
                {{'discussion.messages_container.reply' | transloco}}
            </button>

            <!--reply privately button, forwards message to the sender, not available for own or private messages-->
            <button data-cy="discussion-reply-privately-button" mat-menu-item (click)="replyPrivately(contextMenuMessage.value); closeContextMenu()"
              *ngIf="(!(discussion | async)?.private) && !((contextMenuMessage | async).uid === this.me.value._id)">
                <mat-icon svgIcon="hailer-reply-privately"></mat-icon>
                {{'discussion.messages_container.reply-privately' | transloco}}
            </button>

            <button data-cy="discussion-star-button" mat-menu-item (click)="starMessage(contextMenuMessage.value); closeContextMenu()">
                <mat-icon [svgIcon]="contextMenuMessage.value.isStarred ? 'hailer-unstar' : 'hailer-star'"></mat-icon>
                {{contextMenuMessage.value.isStarred ? ('discussion.messages_container.unstar' | transloco): ('discussion.messages_container.star' | transloco)}}
            </button>

            <button data-cy="discussion-copy-button" mat-menu-item (click)="copyToClipboard(contextMenuMessage.value); closeContextMenu()">
                <mat-icon svgIcon="hailer-copy"></mat-icon>
                {{'discussion.messages_container.copy' | transloco}}
            </button>
            <!--forwarding messages button, forwards message data to a dialog-->
            <button data-cy="discussion-forward-button" mat-menu-item (click)="openForwardMessageDialog(contextMenuMessage.value); closeContextMenu(); closeDiscussionInputMenu('reply')">
                <mat-icon svgIcon="hailer-forward"></mat-icon>
                {{'discussion.messages_container.forward' | transloco}}
            </button>
            <button data-cy="discussion-delete-button" mat-menu-item class="no-button for-text-and-icon" (click)="deleteMessage(contextMenuMessage.value); closeContextMenu()"
              *ngIf="(contextMenuMessage | async).uid === (me | async)?.id" class="destructive-text">
                <mat-icon class="warn" svgIcon="hailer-trash"></mat-icon>
                {{'discussion.messages_container.delete' | transloco}}
            </button>
        </div>
    </div>

<!-- Menu which opens when right-clicking message -->
    <ng-template #messageMenu let-message>
        <div class="message-context-menu">
            <ng-container *ngTemplateOutlet="reactionActionContainer; context: {$implicit: message}"></ng-container>

            <button data-cy="discussion-edit-button" mat-menu-item *ngIf="message.uid === (me | async)?.id" (click)="editMessage(message); closeContextMenu()">
                <mat-icon svgIcon="hailer-edit"></mat-icon>{{'discussion.messages_container.edit' | transloco}}
            </button>

            <button data-cy="discussion-reply-button" mat-menu-item (click)="replyToMessage(message); closeContextMenu(); closeDiscussionInputMenu('forward')">
                <mat-icon svgIcon="hailer-reply"></mat-icon>{{'discussion.messages_container.reply' | transloco}}
            </button>

            <!--reply privately button, forwards message to the sender, not available for own or private messages-->
            <div data-cy="discussion-reply-privately-button" *ngIf="(!(discussion | async)?.private) && !(message.uid === this.me.value._id)">
                <button mat-menu-item (click)="replyPrivately(message); closeContextMenu()">
                    <mat-icon svgIcon="hailer-reply-privately"></mat-icon>{{'discussion.messages_container.reply-privately' | transloco}}
                </button>
            </div>

			<button data-cy="discussion-star-button" mat-menu-item (click)="starMessage(message); closeContextMenu()">
				<mat-icon [svgIcon]="message.isStarred ? 'hailer-unstar' : 'hailer-star'"></mat-icon
				>{{ message.isStarred ? ('discussion.messages_container.unstar' | transloco) : ('discussion.messages_container.star' | transloco) }}
			</button>

            <button data-cy="discussion-copy-button" mat-menu-item (click)="copyToClipboard(message); closeContextMenu()">
                <mat-icon svgIcon="hailer-copy"></mat-icon>{{'discussion.messages_container.copy' | transloco}}
            </button>

            <!--forwarding messages button, forwards message data to a dialog-->
            <button data-cy="discussion-forward-button" mat-menu-item (click)="openForwardMessageDialog(message); closeContextMenu(); closeDiscussionInputMenu('reply')">
                <mat-icon svgIcon="hailer-forward"></mat-icon>{{'discussion.messages_container.forward' | transloco}}
            </button>

            <button mat-menu-item class="no-button for-text-and-icon " *ngIf="message.uid === (me | async)?.id" (click)="deleteMessage(message); closeContextMenu()"
                data-cy="discussion-delete-button" class="destructive-text">
                <mat-icon class="warn" svgIcon="hailer-trash"></mat-icon>{{'discussion.messages_container.delete' | transloco}}
            </button>

        </div>
    </ng-template>

    <mat-menu #reactions="matMenu">
        <ng-template matMenuContent let-message="message">
            <button data-cy="thumbs-up-reaction-hover" mat-menu-item (click)="reactToMessage(message, 'like'); closeContextMenu()">
                <span class="reactions-menu-emoji">👍</span>
                {{'discussion.messages_container.reactions.like' | transloco}}
            </button>
            <button data-cy="thumbs-down-reaction-hover" mat-menu-item (click)="reactToMessage(message, 'dislike'); closeContextMenu()">
                <span class="reactions-menu-emoji">👎</span>
                {{'discussion.messages_container.reactions.dislike' | transloco}}
            </button>
            <button data-cy="laughing-reaction-hover" mat-menu-item (click)="reactToMessage(message, 'funny'); closeContextMenu()">
                <span class="reactions-menu-emoji">😂</span>
                {{'discussion.messages_container.reactions.funny' | transloco}}
            </button>
            <button data-cy="thinking-reaction-hover" mat-menu-item (click)="reactToMessage(message, 'thinking'); closeContextMenu()">
                <span class="reactions-menu-emoji">🤔</span>
                {{'discussion.messages_container.reactions.thinking' | transloco}}
            </button>
            <button data-cy="cookie-reaction-hover" mat-menu-item (click)="reactToMessage(message, 'cookie'); closeContextMenu()">
                <span class="reactions-menu-emoji">🍪</span>
                {{'discussion.messages_container.reactions.cookie' | transloco}}
            </button>
        </ng-template>
    </mat-menu>

    <!-- Menu which opens from more options button -->
    <mat-menu #more="matMenu" [overlapTrigger]="false" >
        <ng-template matMenuContent let-message="message">
			<div class="reactions">
                <button
                    mat-menu-item
                    data-cy="thumbs-up-reaction"
                    class="no-button reaction-button flex"
                    (click)="reactToMessage(message, 'like'); closeContextMenu()"
                    tippy="{{'discussion.messages_container.reactions.like' | transloco}}"
                    attr.aria-label="{{'discussion.messages_container.reactions.aria-like' | transloco}}">
                    👍
                </button>
                <button
                    mat-menu-item
                    data-cy="thumbs-down-reaction"
                    class="no-button reaction-button flex"
                    (click)="reactToMessage(message, 'dislike'); closeContextMenu()"
                    tippy="{{'discussion.messages_container.reactions.dislike' | transloco}}"
                    attr.aria-label="{{'discussion.messages_container.reactions.aria-dislike' | transloco}}">
                    👎
                </button>
                <button
                    mat-menu-item
                    data-cy="laughing-reaction"
                    class="no-button reaction-button flex"
                    (click)="reactToMessage(message, 'funny'); closeContextMenu()"
                    tippy="{{'discussion.messages_container.reactions.funny' | transloco}}"
                    attr.aria-label="{{'discussion.messages_container.reactions.aria-funny' | transloco}}">
                    😂
                </button>
                <button
                    mat-menu-item
                    data-cy="thinking-reaction"
                    class="no-button reaction-button flex"
                    (click)="reactToMessage(message, 'thinking'); closeContextMenu()"
                    tippy="{{'discussion.messages_container.reactions.thinking' | transloco}}"
                    attr.aria-label="{{'discussion.messages_container.reactions.aria-thinking' | transloco}}">
                    🤔
                </button>
                <button
                    mat-menu-item
                    data-cy="cookie-reaction"
                    class="no-button reaction-button flex"
                    (click)="reactToMessage(message, 'cookie'); closeContextMenu()"
                    tippy="{{'discussion.messages_container.reactions.cookie' | transloco}}"
                    attr.aria-label="{{'discussion.messages_container.reactions.aria-cookie' | transloco}}">
                    🍪
                </button>
            </div>

			<button
				data-cy="discussion-edit-button-see-more"
				mat-menu-item
				*ngIf="message.uid === (me | async)?.id"
				(click)="editMessage(message); closeContextMenu()">
				<mat-icon svgIcon="hailer-edit"></mat-icon>{{ 'discussion.messages_container.edit' | transloco }}
			</button>
            <button data-cy="discussion-reply-button-see-more" mat-menu-item (click)="replyToMessage(message); closeContextMenu(); closeDiscussionInputMenu('forward')">
                <mat-icon svgIcon="hailer-reply"></mat-icon>{{'discussion.messages_container.reply' | transloco}}
            </button>
            <!--reply privately button, forwards message to the sender, not available for own or private messages-->
            <div data-cy="discussion-reply-privately-button-see-more" *ngIf="(!(discussion | async)?.private) && !(message.uid === this.me.value._id)">
                <button mat-menu-item (click)="replyPrivately(message); closeContextMenu()">
                    <mat-icon svgIcon="hailer-reply-privately"></mat-icon>{{'discussion.messages_container.reply-privately' | transloco}}
                </button>
            </div>
			<button data-cy="discussion-star-button-see-more" mat-menu-item (click)="starMessage(message); closeContextMenu()">
				<mat-icon [svgIcon]="message?.isStarred ? 'hailer-unstar' : 'hailer-star'"></mat-icon
				>{{
					message?.isStarred ? ('discussion.messages_container.unstar' | transloco) : ('discussion.messages_container.star' | transloco)
				}}
			</button>
            <!--forwarding messages button, forwards message data to a dialog-->
            <button data-cy="discussion-forward-button-see-more" mat-menu-item (click)="openForwardMessageDialog(message); closeDiscussionInputMenu('reply')">
                <mat-icon svgIcon="hailer-forward"></mat-icon>{{'discussion.messages_container.forward' | transloco}}
            </button>
             <button data-cy="discussion-copy-button-see-more" mat-menu-item (click)="copyToClipboard(message)">
                <mat-icon svgIcon="hailer-copy"></mat-icon>{{'discussion.messages_container.copy' | transloco}}
            </button>
            <button mat-menu-item class="no-button for-text-and-icon " *ngIf="message.uid === (me | async)?.id" (click)="deleteMessage(message)"
                data-cy="discussion-delete-button-see-more" class="destructive-text">
                <mat-icon class="warn" svgIcon="hailer-trash"></mat-icon>{{'discussion.messages_container.delete' | transloco}}
            </button>
        </ng-template>
    </mat-menu>

    <div *ngIf="(messages | async)?.length && !(newestLoaded | async)" class="skeleton-container">
        <ng-container *ngFor="let skeletonMessage of skeletonMessages">
            <ng-container *ngTemplateOutlet="skeletonTemplate; context:{ $implicit: skeletonMessage }"></ng-container>
        </ng-container>
    </div>

    <ng-template #skeletonTemplate let-skeletonMessage>
        <div [style.width]="(skeletonMessage.type === 0 || skeletonMessage.type === 1) ? (skeletonMessage.type + 5) * 10 + '%' : '100%'"
            style="margin: 16px 12px;" class="container">
            <div style="border-radius: 50%; width: 40px; height: 40px; background: var(--user-message); flex-shrink: 0;"></div>
            <div style="margin-left: 8px;" class="text-lines">
                <div class="container" style="padding-bottom: 4px;">
                    <div *ngIf="skeletonMessage.type === 0" style="width: 80px;"></div>
                    <div *ngIf="skeletonMessage.type === 1" style="width: 70px;"></div>
                    <div *ngIf="skeletonMessage.type === 2" style="width: 100px;"></div>
                    <div *ngIf="skeletonMessage.type === 3" style="width: 120px;"></div>

                    <div style="width: 40px; margin-left: 8px;"></div>
                </div>
                <div *ngIf="skeletonMessage.type === 0" style="width: 100%;"></div>
                <div *ngIf="skeletonMessage.type === 1" style="width: 98%;"></div>
                <ng-container *ngIf="skeletonMessage.type === 2">
                    <div style="width: 95%;"></div>
                    <div style="width: 82%;"></div>
                </ng-container>
                <ng-container *ngIf="skeletonMessage.type === 3">
                    <div style="width: 98%;"></div>
                    <div style="width: 96%;"></div>
                    <div style="width: 100%;"></div>
                    <div style="width: 50%;"></div>
                </ng-container>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #reactionActionContainer let-message>
	<div class="reactions">
        <button
            mat-menu-item
            data-cy="thumbs-up-reaction"
            class="no-button reaction-button flex"
            (click)="reactToMessage(message, 'like'); closeContextMenu()"
            tippy="{{'discussion.messages_container.reactions.like' | transloco}}"
            attr.aria-label="{{'discussion.messages_container.reactions.aria-like' | transloco}}">
            👍
        </button>
        <button
            mat-menu-item
            data-cy="thumbs-down-reaction"
            class="no-button reaction-button flex"
            (click)="reactToMessage(message, 'dislike'); closeContextMenu()"
            tippy="{{'discussion.messages_container.reactions.dislike' | transloco}}"
            attr.aria-label="{{'discussion.messages_container.reactions.aria-dislike' | transloco}}">
            👎
        </button>
        <button
            mat-menu-item
            data-cy="laughing-reaction"
            class="no-button reaction-button flex"
            (click)="reactToMessage(message, 'funny'); closeContextMenu()"
            tippy="{{'discussion.messages_container.reactions.funny' | transloco}}"
            attr.aria-label="{{'discussion.messages_container.reactions.aria-funny' | transloco}}">
            😂
        </button>
        <button
            mat-menu-item
            data-cy="thinking-reaction"
            class="no-button reaction-button flex"
            (click)="reactToMessage(message, 'thinking'); closeContextMenu()"
            tippy="{{'discussion.messages_container.reactions.thinking' | transloco}}"
            attr.aria-label="{{'discussion.messages_container.reactions.aria-thinking' | transloco}}">
            🤔
        </button>
        <button
            mat-menu-item
            data-cy="cookie-reaction"
            class="no-button reaction-button flex"
            (click)="reactToMessage(message, 'cookie'); closeContextMenu()"
            tippy="{{'discussion.messages_container.reactions.cookie' | transloco}}"
            attr.aria-label="{{'discussion.messages_container.reactions.aria-cookie' | transloco}}">
            🍪
        </button>
    </div>
</ng-template>
