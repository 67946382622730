/** @format */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
                alias: 'public',
            },
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
}
