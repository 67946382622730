<div>
    <router-outlet *ngIf="hideLoadingScreen"></router-outlet>
    <div *ngIf="!removeLoadingScreen" [ngClass]="{'loaded': hideLoadingScreen}" class="connecting">
        <div class="logo-container">
            <img crossorigin [src]="'assets/img/hailerlogo.png'">
        </div>
        <div class="loading-info-box">
            <div class="progress-bar">
                <div [ngStyle]="{'width': (loadingProgress.status | async).percentage + '%'}" class="progress"></div>
            </div>
            <p>{{ (loadingProgress.status | async).label }}</p>
        </div>
    </div>
    <!-- Removed for now -->
    <!-- <div *ngIf="(reconnecting| async)" class="reconnect">
        <span style="margin-left: 8px;">Reconnecting</span><mat-spinner [diameter]="20"></mat-spinner>
    </div> -->
</div>
