<div class="dropdown-item-container">
    <div [ngSwitch]="item?.type" style="overflow: hidden;">
        <div class="dropdown-item" *ngSwitchCase="'user'">
            <app-dropdown-user-item style="width: 100%;" [item]="item" class="dropdown-user-item"></app-dropdown-user-item>
        </div>
        <div class="dropdown-item" *ngSwitchCase="'team'">
            <app-dropdown-team-item style="width: 100%;" [item]="item"></app-dropdown-team-item>
        </div>
        <div class="dropdown-item" *ngSwitchCase="'activity'">
            <app-dropdown-activity-item style="width: 100%;" [item]="item"></app-dropdown-activity-item>
        </div>
        <div class="dropdown-item" *ngSwitchCase="'calendar'">
            <app-dropdown-calendar-item style="width: 100%;" [item]="item"></app-dropdown-calendar-item>
        </div>
        <div class="dropdown-item" *ngSwitchCase="'workspace'">
            <app-dropdown-workspace-item style="width: 100%;" [item]="item"></app-dropdown-workspace-item>
        </div>
        <div class="dropdown-item" *ngSwitchDefault>
            <p class="dropdown-item-title">
                {{item?.title}}
            </p>
        </div>
    </div>
    <button class="only-button" *ngIf="onlySelector" (click)="$event.stopPropagation(); onlyItem.next(item);" mat-button>Only</button>
</div>