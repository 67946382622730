/** @format */

export class ActivityLinkField {
    constructor(
        public value: {
            name: string;
            _id: string;
        }
    ) {}
}
