/** @format */

import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { JwtHelperService } from '@auth0/angular-jwt';

import { authConfigMap } from '../../../sso-config';

export type Provider = 'digione' | 'lidl';

@Injectable({
    providedIn: 'root',
})
export class OidcService {
    private jwtHelper: JwtHelperService = new JwtHelperService();

    constructor(private oauthService: OAuthService) {
        // Get providerId after returning from providers login page.
        const providerId = sessionStorage.getItem('providerId');

        if (providerId) {
            const authConfigInit = authConfigMap[providerId];

            this.oauthService.configure(authConfigInit);
            this.oauthService.tokenValidationHandler = new JwksValidationHandler();
            this.oauthService.loadDiscoveryDocumentAndTryLogin();
        }
    }

    signIn(providerId: Provider): void {
        const authConfig = authConfigMap[providerId];

        if (!authConfig) {
            return;
        }

        this.oauthService.configure(authConfig);
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        this.oauthService.loadDiscoveryDocumentAndTryLogin();

        // Temporary save providerId to know what to load when returning back from providers login page
        sessionStorage.setItem('providerId', providerId);

        this.oauthService.initImplicitFlow();
    }

    signOut(): void {
        sessionStorage.removeItem('providerId');
        this.oauthService.logOut(true);
    }

    getIdentityClaims() {
        return this.oauthService.getIdentityClaims();
    }

    getIdToken() {
        return this.oauthService.getIdToken();
    }

    getClientId() {
        return this.oauthService.clientId;
    }

    getAccessToken() {
        return this.oauthService.getAccessToken();
    }

    getDecodedIdToken() {
        return this.jwtHelper.decodeToken(this.oauthService.getIdToken());
    }
}
