/** @format */

import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { CoreService } from './core.service';
import { FeedPermission } from '../../../test/deps/hailer-api/src/api/v3/permissions/permission-objects/feed-permission';
import { PermissionMap } from '../../../test/deps/hailer-api/src/api/v3/permissions/permission-models';
import { PhasePermission } from '../../../test/deps/hailer-api/src/api/v3/permissions/permission-objects/phase-permission';
import { ActivityPermission } from '../../../test/deps/hailer-api/src/api/v3/permissions/permission-objects/activity-permission';
import { RolePermission } from '../../../test/deps/hailer-api/src/api/v3/permissions/permission-objects/role-permission';
import { RPCService } from './rpc.service';
import { DiscussionPermission } from '../../../test/deps/hailer-api/src/api/v3/permissions/permission-objects/discussion-permission';

export class V2PermissionService {
    feed: FeedPermission;
    phase: PhasePermission;
    activity: ActivityPermission;
    role: RolePermission;
    discussion: DiscussionPermission;
    /** Emits workspaceIds where permissions has changed */
    changed = new Subject<string[]>();

    /** Includes all currently loaded workspace permissions */
    map: PermissionMap = {};

    /** The amount of time frontend waits until fetching missing permissions again in milliseconds */
    private notFoundDebounce = 10000;
    private notFoundQueue: { [workspaceId: string]: number } = {};

    static async from(core: CoreService, rpc: RPCService, router: Router): Promise<V2PermissionService> {
        const permission = new V2PermissionService(core, rpc, router);
        let permissionMap: PermissionMap = {};

        try {
            if (!core.network.value) {
                throw new Error('core workspace is undefined');
            }

            permissionMap = await permission.getWorkspacePermissions(core.network.value._id);
        } catch (error) {
            console.warn('Failed to fetch initial permissions', error);
        }

        permission.setPermissions(permissionMap);
        return permission;
    }

    private constructor(private core: CoreService, private rpc: RPCService, private router: Router) {
        this.rpc.signals.subscribe({
            next: signal => {
                switch (signal.sig) {
                    case 'update.permissions':
                        this.setPermissions(signal.meta?.permissionMap || {});
                        break;
                    default:
                        break;
                }
            },
        });
    }

    /**
     * This function is called when hasPermission pipe does not find user permissions with specified workspaceId.
     *
     * 1. Try to fetch the permission from the backend
     * 2. Wait for any other request for missing workspace permissions and add them to the queue
     * 3. Try to fetch the permission again if the queue has items and specified workspace permissions are still missing from the frontend
     **/
    async notFound(workspaceId: string): Promise<void> {
        if (this.notFoundQueue[workspaceId]) {
            this.notFoundQueue[workspaceId]++;
            return;
        }

        this.notFoundQueue[workspaceId] = 1;
        let permissions = await this.getWorkspacePermissions(workspaceId);

        this.setPermissions(permissions);
        setTimeout(async () => {
            const count = this.notFoundQueue[workspaceId] || 0;

            if (count <= 1) {
                delete this.notFoundQueue[workspaceId];
                return;
            }

            if (this.map[workspaceId]) {
                delete this.notFoundQueue[workspaceId];
                return;
            }

            permissions = await this.getWorkspacePermissions(workspaceId);
            this.setPermissions(permissions);
            delete this.notFoundQueue[workspaceId];
        }, this.notFoundDebounce);
    }

    private get userId(): string {
        return this.core.user.value._id;
    }

    private setPermissions(permissionMap: PermissionMap): void {
        this.map = this.updatePermissionMap(permissionMap);
        this.feed = new FeedPermission(this.userId, this.map);
        this.role = new RolePermission(this.userId, this.map);
        this.phase = new PhasePermission(this.userId, this.map);
        this.activity = new ActivityPermission(this.userId, this.map);
        this.discussion = new DiscussionPermission(this.userId, this.map);
        this.changed.next(Object.keys(permissionMap || {}));
        void this.checkValidRoute();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private updatePermissionMap(updated: PermissionMap): PermissionMap {
        const original = { ...this.map };

        if (!Object.keys(updated || {}).length) {
            return original;
        }

        if (!Object.keys(original).length) {
            return updated;
        }

        for (const [workspaceId, originalWorkspacePermissions] of Object.entries(original)) {
            const updatedWorkspacePermissions = updated[workspaceId];

            if (!updatedWorkspacePermissions) {
                // TODO: Refactor permission updated signal to support removed values. Currently we dont know if the permission was removed or just not updated.
                continue;
            }

            for (const key in updatedWorkspacePermissions) {
                if (Object.keys(updatedWorkspacePermissions[key]).length) {
                    Object.assign(originalWorkspacePermissions[key], updatedWorkspacePermissions[key]);
                }
            }
        }

        for (const [workspaceId, updatedWorkspacePermissions] of Object.entries(updated)) {
            if (original[workspaceId]) {
                continue;
            }

            original[workspaceId] = updatedWorkspacePermissions;
        }

        return original;
    }

    private async getWorkspacePermissions(workspaceId: string): Promise<PermissionMap> {
        try {
            return (await this.rpc.requestAsync('v3.user.permissions', [this.userId, workspaceId])) as PermissionMap;
        } catch (error) {
            console.warn('Failed to fetch missing permissions', error);
            return {};
        }
    }

    /** Checks if user has permission to be in a route, redirects to discussions if not */
    private async checkValidRoute(): Promise<void> {
        const [canAccessFeedError] = this.feed.load(this.core.network.value._id);
        if (location.hash.includes('/feed') && canAccessFeedError) {
            await this.router.navigate(['/discussions']);
            return;
        }
    }
}
