/** @format */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject, zip } from 'rxjs';
import { filter, pluck, takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { File } from 'app/_models';
import { Tag } from 'app/_models/tag.model';
import { CoreService } from 'app/_services/core.service';
import { ObjectFilterOptions } from 'app/_models/tag-filter-list.model';
import { FileService } from 'app/files-shared/file.service';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    selector: 'app-tag-filter-object-list',
    templateUrl: './tag-filter-object-list.component.html',
    styleUrls: ['./tag-filter-object-list.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class TagFilterObjectListComponent implements OnInit, OnDestroy {
    @Input() objectsFilterOptions: BehaviorSubject<ObjectFilterOptions>;
    @Input() searchString: BehaviorSubject<string>;
    @Input() resetDeletableObjects: Subject<string>;
    @Output() objectToDelete = new EventEmitter<string>();
    @Output() objectSelected = new EventEmitter<string>();

    filteringMode = new BehaviorSubject<'cloud' | 'folder'>('cloud');
    initialData = new BehaviorSubject<any>({});

    // ** NoMatches are used in the folder view to show files that has no tags */
    noMatches = new BehaviorSubject<{ [type: string]: string[] }>({});

    // ** PartialMatches are files that still has tags attached to them */
    partialMatches = new BehaviorSubject<{ [type: string]: string[] }>({});

    // ** ExactMatches are files that have the exact tags attached to them */
    exactMatches = new BehaviorSubject<{ [type: string]: string[] }>({});

    // ** LinkedTags are tags that objets still has from the allowed tags */
    linkedTags = new BehaviorSubject<string[]>([]);

    // ** SelectedTags - currently filtering tags */
    selectedTags = new BehaviorSubject<string[]>([]);

    // ** ObjectCount shows how many objects are under a tag */
    objectCount = new BehaviorSubject<{ [tagId: string]: number }>({});

    // ** AllowedTags - tags that are allowed to show in tag pool/folders */
    allowedTags = new BehaviorSubject<string[]>([]);

    // ** DeletableObjects - objects that are ready to be deleted */
    deletableObjects = new BehaviorSubject<string[]>([]);

    private onDestroy = new Subject<void>();
    private networkId: string;
    private networkTags = new BehaviorSubject<{ [id: string]: Tag }>({});
    private initialObjects = new BehaviorSubject<string[]>([]);

    constructor(private core: CoreService, private files: FileService, private rpc: RPCService) {}

    ngOnInit() {
        this.objectsFilterOptions
            .pipe(
                takeUntil(this.onDestroy),
                filter(it => !!it)
            )
            .subscribe({
                next: opts => {
                    this.networkId = opts.cid;

                    this.core.tags.pipe(takeUntil(this.onDestroy), pluck(this.networkId)).subscribe({
                        next: val => this.networkTags.next(val),
                    });

                    this.initialObjects.next(opts.objects ? opts.objects : []);
                    this.sortAllowedTags(opts.allowedTags ? opts.allowedTags : []);

                    /* This only works with files. For now.
                       Lets make sure that we have ALL of the data before we try to do anything funny... */

                    const subArray = this.initialObjects.value.map(id => this.files.getFileProperties(id));
                    if (subArray.length > 0) {
                        zip(...subArray).subscribe({
                            next: files => {
                                const data = {};
                                files.forEach((file: any) => (data[file._id] = file));
                                this.initialData.next(data);
                                this.sortMatches({ file: this.initialObjects.value });
                                this.resetFilter();
                            },
                        });
                    } else {
                        this.initialData.next({});
                        this.sortMatches({ file: [] });
                        this.resetFilter();
                    }

                    // Do an initial filtering to get tagObjectCount
                    this.initialObjectCountFetch();
                    this.initialFileTagFetch();
                },
            });

        this.resetDeletableObjects.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.deletableObjects.next([]),
        });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    isSelected(tagId: string) {
        return !!this.selectedTags.value.find(_id => _id === tagId);
    }

    enableTag(tagId: string): boolean {
        const hasObjects = !!this.getFolderObjectCount(tagId);
        const hasTags = !!this.linkedTags.value.find(_id => _id === tagId);

        if (this.selectedTags.value.length === 0) {
            return hasObjects;
        }

        return hasTags && hasObjects;
    }

    toggleViewMode() {
        if (this.filteringMode.value === 'cloud') {
            this.filteringMode.next('folder');
        } else {
            this.filteringMode.next('cloud');
        }
    }

    getTag(tagId: string) {
        return this.networkTags.value[tagId];
    }

    getObject(objectId: string): Observable<File> {
        // Filter objects if searching...works on files only for now...
        if (this.searchString.value) {
            const object = this.initialData.value[objectId];
            if (this.doSearching(object.name, this.searchString.value)) {
                return this.initialData.pipe(pluck(objectId));
            }
            return null;
        }
        return this.initialData.pipe(pluck(objectId));
    }

    cloudViewFilter(tagId: string) {
        const args = this.selectedTags.value;
        const index = args.indexOf(tagId);
        if (index > -1) {
            const newArgs = args.splice(index, 1);
            this.filter(newArgs);
        } else {
            args.push(tagId);
            this.filter(args);
        }
    }

    folderViewFilter(tagId: string) {
        const args = this.selectedTags.value;
        const index = args.indexOf(tagId);
        if (index > -1) {
            const newArgs = args.splice(0, index + 1);
            this.filter(newArgs);
        } else {
            args.push(tagId);
            this.filter(args);
        }
    }

    getFolderObjectCount(tagId: string) {
        return this.objectCount.value ? this.objectCount.value[tagId] : 0;
    }

    resetFilter() {
        this.selectedTags.next([]);
        this.linkedTags.next(this.allowedTags.value.length > 0 ? this.allowedTags.value : []);
        this.exactMatches.next({});
        this.partialMatches.next(this.sortMatches({ file: this.initialObjects.value }));
        this.sortAllowedTags(this.allowedTags.value);
    }

    folderViewBackButton() {
        const args = this.selectedTags.value;
        args.pop();

        if (args.length) {
            this.filter(args);
        } else {
            this.resetFilter();
        }
    }

    tagCloudAddTag(tagId: string) {
        /* We don't check whether the tag is already in the array
           since it should not be possible. */
        if (this.enableTag(tagId)) {
            const args = this.selectedTags.value;
            args.push(tagId);
            this.filter(args);
        }
    }

    tagCloudRemoveTag(tagId: string) {
        const args = this.selectedTags.value;
        const index = args.findIndex(id => id === tagId);

        if (index > -1) {
            args.splice(index, 1);
        }

        if (args.length > 0) {
            this.filter(args);
        } else {
            this.resetFilter();
        }
    }

    selectFile(fileId: string) {
        this.objectSelected.emit(fileId);
    }

    getUserName(uid: string): string {
        return this.files.getUser(uid).display_name;
    }

    deleteObject(objectId: string) {
        const currentObjects = this.deletableObjects.value;
        currentObjects.push(objectId);
        this.deletableObjects.next(currentObjects);
        this.objectToDelete.emit(objectId);
    }

    unDeleteObject(objectId: string) {
        const currentObjects = this.deletableObjects.value.filter(currentId => objectId !== currentId);
        this.deletableObjects.next(currentObjects);
        this.objectToDelete.emit(objectId);
    }

    isDeletable(objectId: string): boolean {
        return this.deletableObjects.value.includes(objectId);
    }

    private initialObjectCountFetch() {
        if (this.initialObjects.value.length > 0) {
            this.allowedTags.value.map(tagId => {
                this.rpc.request('v2.tags.filter', [this.networkId, { tags: [tagId], objects: this.initialObjects.value }]).subscribe({
                    next: response => {
                        this.updateObjectCount(Object.keys(response.tagObjectCount).length > 0 ? response.tagObjectCount : { [tagId]: 0 });
                    },
                });
            });
        } else {
            this.objectCount.next({});
        }
    }

    // ** InitialFileTagFetch does fetching and filtering for objects that dont have allowedTags attached to them */
    private initialFileTagFetch(): void {
        if (this.initialObjects.value.length > 0) {
            this.rpc.request('v2.tags.check', [this.networkId, [this.initialObjects.value]]).subscribe({
                next: response => {
                    if (response) {
                        const matches = this.initialObjects.value.filter(objectId => {
                            const objectTags = response[objectId];
                            if (objectTags) {
                                return objectTags.length > 0 ? this.compareTagsToAllowedTags(objectTags) : true;
                            }
                            return true;
                        });
                        this.noMatches.next({ ['file']: matches });
                    }
                },
                error: error => console.error('error happened while checking object tags', error),
            });
        }
    }

    private compareTagsToAllowedTags(tags: string[]): boolean {
        return !!tags.find(tagId => this.allowedTags.value.includes(tagId));
    }

    private updateObjectCount(newValue: { [tagId: string]: number }) {
        if (newValue && Object.keys(newValue).length > 0) {
            const objectCount = this.objectCount.value;
            Object.keys(newValue).map(responseTagId => {
                objectCount[responseTagId] = newValue[responseTagId];
            });
            this.objectCount.next(objectCount);
        }
    }

    private sortAllowedTags(tagsToSort: string[]) {
        tagsToSort.sort((a, b) => {
            const aValue = this.networkTags.value[a].value.toLocaleLowerCase();
            const bValue = this.networkTags.value[b].value.toLocaleLowerCase();

            return aValue.localeCompare(bValue);
        });

        this.allowedTags.next(tagsToSort);
    }

    private sortMatches(matchObject: any): any {
        if (matchObject && Object.keys(matchObject).length > 0) {
            const files = matchObject.file;
            if (Object.values(files).length > 0) {
                files.sort((a: string, b: string) => {
                    const aName = this.initialData.value[a]?.name;
                    const bName = this.initialData.value[b]?.name;

                    return aName.toLocaleLowerCase().localeCompare(bName.toLocaleLowerCase());
                });
            }
            matchObject.file = files;
        }
        return matchObject;
    }

    private filter(tags: string[]): void {
        this.rpc.request('v2.tags.filter', [this.networkId, { tags, objects: this.initialObjects.value }]).subscribe({
            next: response => {
                this.exactMatches.next(this.sortMatches(response.exactMatches));
                this.partialMatches.next(this.sortMatches(response.partialMatches));
                this.selectedTags.next(response.arguments);
                this.updateObjectCount(response.tagObjectCount);
                this.linkedTags.next(response.linkedTags);

                this.sortAllowedTags(this.allowedTags.value);
            },
        });
    }

    private doSearching(input: string, search: string): boolean {
        return input.toLowerCase().replace(' ', '').indexOf(search.toLowerCase().replace(' ', '')) > -1;
    }
}
