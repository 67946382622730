{{redraw()}}
<mat-expansion-panel data-cy="linked-activities-field" *ngIf="linkedFromWorkflow" #workflowPanel (afterCollapse)="collapsed.emit()"
    (afterExpand)="expanded.emit(); phaseAccordion?.openAll();" [expanded]="true" class="mat-elevation-z0 process-container">
    <mat-expansion-panel-header>
        <div class="flexbox-row--center-centered">
            <h3 class="panel-header-title two-line-ellipsis">{{linkedFromWorkflow.name}}</h3>
            <ng-container *ngIf="description">
                <mat-icon data-cy="activity-sidenav-linked-from-description" [tippy]="description" class="tooltip-icon-small" [satPopoverAnchor]="descriptionPopover"
                    (click)="descriptionPopover.toggle(); $event.stopPropagation();" svgIcon="hailer-tooltip"></mat-icon>
                <sat-popover hasBackdrop #descriptionPopover verticalAlign="below">
                    <div data-cy="activity-sidenav-linked-from-description-text" class="popover-container mat-elevation-z8" [innerHTML]="description | urlify">
                    </div>
                </sat-popover>
            </ng-container>
            <div class="amount-bubble">
                <p data-cy="activity-sidenav-activity-count"> {{linkedFromWorkflow.activityCount | thousandSeparator}}</p>
            </div>
        </div>
        <ng-container *ngIf="viewContext.canCreateLinkedActivityInFirstPhase(linkedFromWorkflowId)">
            <button data-cy="activity-sidenav-add-linked-activity-button" *ngIf="oneCreatePossibility else addWithPhaseMenu"
                [disabled]="!linkedFromWorkflowId || viewContext.overviewPeek" class="no-button for-icon add-button"
                (click)="openActivityCreate(linkedFromWorkflowId, linkedFromWorkflow?.dataset ? editableLinkedFromPhaseIds?.[0] : undefined); $event.stopPropagation()">
                <mat-icon svgIcon="hailer-plus"></mat-icon>
            </button>
        </ng-container>

        <ng-template #addWithPhaseMenu>
            <button [disabled]="!linkedFromWorkflowId || viewContext.overviewPeek" class="no-button for-icon add-button"
                [matMenuTriggerFor]="phaseMenu" (click)="$event.stopPropagation(); $event.stopPropagation()">
                <mat-icon svgIcon="hailer-plus"></mat-icon>
            </button>

            <mat-menu #phaseMenu>
                <ng-container *ngIf="editableLinkedFromPhaseIds.length > 1; else fieldList">
                    <ng-container *ngFor="let phaseId of editableLinkedFromPhaseIds">
                        <button data-cy="linkable-phase-item" *ngIf="(viewContext.creatableLinkableFields(linkedFromWorkflowId, phaseId)?.length || 0) <= 1; else phaseItemWithFieldMenu"
                            mat-menu-item (click)="openActivityCreate(linkedFromWorkflowId, phaseId)">
                            {{linkedFromWorkflow.phases?.[phaseId]?.name || viewContext.getPhaseName(linkedFromWorkflowId, phaseId)}}
                        </button>
            
                        <ng-template #phaseItemWithFieldMenu>
                            <button data-cy="linkable-phase-item" mat-menu-item [matMenuTriggerFor]="fieldMenu">
                                {{linkedFromWorkflow.phases?.[phaseId]?.name || viewContext.getPhaseName(linkedFromWorkflowId, phaseId)}}
                            </button>
                        </ng-template>
            
                        <mat-menu #fieldMenu>
                            <ng-container *ngTemplateOutlet="fieldList; context: { phaseId }"></ng-container>
                        </mat-menu>
                    </ng-container>
                </ng-container>
            </mat-menu>

            <ng-template #fieldList let-phaseId="phaseId">
                <ng-container *ngFor="let fieldId of viewContext.creatableLinkableFields(linkedFromWorkflowId, phaseId)">
                    <button data-cy="linkable-field-item" mat-menu-item (click)="openActivityCreate(linkedFromWorkflowId, phaseId, fieldId)">
                        {{viewContext.getFieldLabel(linkedFromWorkflowId, fieldId) || 'n/a'}}
                    </button>
                </ng-container>
            </ng-template>
        </ng-template>
    </mat-expansion-panel-header>

    <!-- Phase -->
    <mat-accordion *ngIf="linkedFromPhaseIds?.length; else noPhases" [multi]="true" [displayMode]="'flat'">
        <ng-container *ngFor="let phaseId of linkedFromPhaseIds; trackBy: trackById">
            <div *ngIf="linkedFromWorkflow.phases?.[phaseId]?.activityCount && workflowPanel.expanded" class="phase-container" [id]="phaseId">
                <mat-expansion-panel #phasePanel [expanded]="true" class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <div class="flexbox-row--center-centered">
                            <h3 class="panel-header-title two-line-ellipsis">{{linkedFromWorkflow.phases?.[phaseId]?.name}}</h3>
                            <div class="amount-bubble">
                                <p>{{linkedFromWorkflow.phases?.[phaseId]?.activityCount | thousandSeparator}}</p>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <!-- Activity -->
                    <mat-list *ngIf="phasePanel.expanded" class="linked-activities-list">
                        <mat-list-item
                            *ngFor="let activity of linkedFromWorkflow?.phases?.[phaseId]?.activities?.slice(0, activityLimit); trackBy: trackById"
                            (click)="openActivity(activity._id, linkedFromWorkflowId); $event.stopPropagation()"
                            class="activity-item">
                            <span [tippy]="(viewContext.fullscreen | async) ? null : activity.name">{{activity.name}}</span>
                            <p class="activity-primary-field-info" *ngIf="activity.primaryNumericField || activity.primaryNumericField === 0">
                                {{(activity.primaryNumericField || 0) | thousandSeparator}} {{getPrimaryFieldData(phaseId, 'unit')}}
                            </p>
                        </mat-list-item>

                        <mat-list-item *ngIf="activityLimit && ((linkedFromWorkflow.phases?.[phaseId]?.activityCount || 0) > activityLimit)"
                            (click)="viewContext.highlightLinkedFromPhase.next(phaseId); $event.stopPropagation()" class="activity-item">
                            <span class="show-more-text">
                                {{'activity-sidenav.linked.show' | transloco}}
                                {{((linkedFromWorkflow.phases?.[phaseId]?.activityCount || 0) - activityLimit) | thousandSeparator}}
                                {{'activity-sidenav.linked.more' | transloco}}
                            </span>
                        </mat-list-item>

                        <mat-list-item class="load-more-item" *ngIf="showLoadMore(phaseId)">
                            <button *ngIf="!(viewContext.loadingLinkedFrom | async); else loadingSpinner" mat-icon-button
                                (click)="loadNextLinkedActivities(phaseId); $event.stopPropagation()">
                                <mat-icon color="primary">expand_more</mat-icon>
                            </button>
                            <ng-template #loadingSpinner>
                                <mat-spinner color="primary" [diameter]="24"></mat-spinner>
                            </ng-template>
                        </mat-list-item>

                    </mat-list>
                </mat-expansion-panel>
                <div *ngIf="linkedFromWorkflow.phases?.[phaseId]?.primaryNumericTotal" (click)="$event.stopPropagation()"
                    class="activity-footer-item panel-header-title two-line-ellipsis">
                    <span>{{getPrimaryFieldData(phaseId, 'label')}}</span>
                    <p>{{(linkedFromWorkflow.phases?.[phaseId]?.primaryNumericTotal || 0) | thousandSeparator}} {{getPrimaryFieldData(phaseId,
                        'unit')}}</p>
                </div>
            </div>
        </ng-container>
    </mat-accordion>

    <ng-template #noPhases>
        <span data-cy="activity-sidenav-empty-state-text" *ngIf="workflowPanel.expanded" class="empty-state-text">
            {{'activity-sidenav.linked.no_linked' | transloco}}
            {{ linkedFromWorkflow?.dataset ? ('activity-sidenav.linked.dataset' | transloco) : ('activity-sidenav.linked.workflow' | transloco) }}.</span>
    </ng-template>
</mat-expansion-panel>