/** @format */

import { Injectable, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { CoreSignal } from 'app/_models/coreSignal.enum';
import { CoreService } from 'app/_services/core.service';
import { RPCService } from 'app/_services/rpc.service';
import { Role, RoleMap, WorkspaceRoleMap } from '../../../../test/deps/hailer-api/src/api/v3/permissions/permission-models';
import { PermitKeys } from '../../../../test/deps/hailer-api/src/api/v3/permissions/permission-keys';

@Injectable({
    providedIn: 'root'
})
export class RoleSettingsService implements OnDestroy {
    created = new Subject<string>();
    updated = new Subject<string>();
    removed = new Subject<string>();

    map: RoleMap = {};
    mapUpdated = new Subject<void>();

    private onDestroy = new Subject<void>();

    constructor(private core: CoreService, private rpc: RPCService) {
        this.rpc.signals.pipe(takeUntil(this.onDestroy)).subscribe({
            next: async ({ meta, sig }) => {
                const { roleIds } = meta;
                const removed = sig === CoreSignal.WorkspaceRoleRemoved;
                const created = sig === CoreSignal.WorkspaceRoleCreated;
                const updated = sig === CoreSignal.WorkspaceRoleUpdated;

                if (!roleIds?.length) {
                    await this.updateMapItems();
                    return;
                }

                for (const roleId of roleIds) {
                    if (removed) {
                        delete this.map[roleId];
                        this.mapUpdated.next();
                        this.removed.next(roleId);
                        return;
                    }

                    if (!created && !updated) {
                        return;
                    }

                    await this.updateMapItems([roleId]);

                    if (updated) {
                        this.updated.next(roleId);
                    }

                    if (created) {
                        this.created.next(roleId);
                    }
                }
            },
        });

        // Initial load
        void this.updateMapItems();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    /** @deprecated This will be removed once roles are released */
    checkLicense(): boolean {
        // Const { license } = this.core.networks.value[this.core.network.value._id];
        const { license } = this.core.network.value;
        return license.override?.customRoles;
    }

    async updateMapItems(roleIds?: string[]) {
        try {
            const roleMap = await this.list(roleIds);
            Object.assign(this.map, roleMap);
            this.mapUpdated.next();
        } catch (error) {
            console.error('Failed to update role item', error);
        }
    }

    async list(roleIds?: string[]): Promise<RoleMap> {
        if (!this.checkLicense()) {
            return {};
        }

        const workspaceId = this.core.network.value._id;
        const [listPermissionError] = this.core.permission.role.list(workspaceId);
        if (listPermissionError) {
            console.error('No permission to list roles!');
            return {};
        }

        const requestData: { [workspaceId: string]: string[] | undefined } = { [workspaceId]: roleIds || [] };
        const workspaceRoleMap = (await this.rpc.requestAsync('v3.network.role.list', [requestData])) as WorkspaceRoleMap;
        return workspaceRoleMap[workspaceId] || {};
    }

    async create(options: { name: string; description?: string, permits?: PermitKeys[] }): Promise<Role | undefined> {
        const workspaceId = this.core.network.value._id;
        const workspaceRoleMap = (await this.rpc.requestAsync('v3.network.role.create', [{ [workspaceId]: [options] }])) as WorkspaceRoleMap;
        return Object.values(workspaceRoleMap[workspaceId] || {})[0];
    }

    async remove(roleId: string): Promise<boolean> {
        const workspaceId = this.core.network.value._id;
        return (await this.rpc.requestAsync('v3.network.role.remove', [{ [workspaceId]: [roleId] }])) as boolean;
    }

    async edit(roleId: string, options: { name?: string; description?: string }): Promise<boolean> {
        return (await this.rpc.requestAsync('v3.network.role.update', [
            { [this.core.network.value._id]: { [roleId]: options } },
        ])) as boolean;
    }

    async updateUsers(roleId: string, userId: string): Promise<boolean> {
        return (await this.rpc.requestAsync('company.set_user_data', [
            { section: 'customRole', uid: userId, value: roleId },
        ])) as Promise<boolean>;
    }

    getUserIds(roleId: string): string[] {
        const role = this.map[roleId];
        if (!role) {
            console.warn('Cannot find role');
            return [];
        }

        const workspace = this.core.networks.value[role.cid];
        const roleUserIds: string[] = [];
        const members = workspace?.members || [];

        for (const { uid, customRole } of members) {
            if (customRole !== roleId) {
                continue;
            }

            roleUserIds.push(uid);
        }

        return roleUserIds;
    }
}
