/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { DateUtil } from 'app/_helpers/date-util';
import { TranslateService } from 'app/_services/translate.service';

@Pipe({ name: 'lastWeekDay' })
export class LastWeekDayPipe implements PipeTransform {
    constructor(private translocoService: TranslocoService, private translateService: TranslateService) {}

    async transform(timestamp: number): Promise<string> {
        if (!timestamp) {
            return '';
        }

        await this.translateService.translationLoaded('misc');

        const date = new Date(timestamp);
        const diff = DateUtil.diff(date);

        if (diff.sameDay) {
            return this.translocoService.translate('misc.date.today');
        } else if (diff.sameYear && diff.diffInDates === 1) {
            return this.translocoService.translate('misc.date.yesterday');
        } else if (diff.sameWeek) {
            return DateUtil.getDayStr(date, undefined, this.translocoService.translate('misc.date.locale'));
        }

        return (
            `${DateUtil.getMonthStr(date, 'short', this.translocoService.translate('misc.date.locale'))}` +
            ` ${date.getUTCDate()}. ${date.getUTCFullYear()}`
        );
    }
}
