/** @format */

import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { App } from 'app/_models/app.model';
import { AppService } from 'app/_services/app.service';
import { CoreService } from 'app/_services/core.service';
import { RPCService } from 'app/_services/rpc.service';
import { Server } from '@wishcore/wish-rpc';
import { SideNavService } from 'app/_services/side-nav.service';
import Joi, { AnySchema } from 'joi';
import { validActivity, validErrorResponse, validUser, validWorkflow } from '../validation';
import { AppApiV1 } from './app-api-v1';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilesService } from 'app/_services/files.service';
import { MatDialog } from '@angular/material/dialog';
import { InsightService } from '../insight.service';
import { AppApiV2 } from './app-api-v2';

export const hailerArgs = (...args: AnySchema[]): Joi.ArraySchema | Joi.ObjectSchema => {
    return Joi.array()
        .required()
        .ordered(...args)
        .label('HailerArgs');
};

export const workflowProjection = (workflow): any => {
    const validation = validWorkflow.validate(workflow, { stripUnknown: true, allowUnknown: true });

    if (validation.error) {
        console.log('Validation error on data', workflow);
        return { error: validation.error.toString(), details: validation.error.details };
    }

    return validation.value;
};

export const workflowConvert = ({ /* fieldsOrder, */ ...workflow }): any => {
    return workflowProjection({
        ...workflow,
        // fields: fieldsOrder.map(fieldId => workflow.fields[fieldId]),
    });
};

export const activityProjection = (activity): any => {
    const validation = validActivity.validate(activity, { stripUnknown: true, allowUnknown: true });

    if (validation.error) {
        return { error: validation.error.toString(), details: validation.error.details, original: activity };
    }

    return validation.value;
};

export const activityConvert = ({ fields, team_account, followers, discussion, currentPhase, process, ...activity }: any): any => {
    return activityProjection({
        ...activity,
        fields: Array.isArray(fields)
            ? fields.reduce((map, field) => { map[field.id] = field.value; return map; }, {})
            : Object.entries<any>(fields).reduce((map, [fieldId, field]) => { map[fieldId] = field.value; return map; }, {}),
        discussionId: discussion,
        followerIds: followers,
        workflowId: process,
        phaseId: currentPhase,
        teamId: team_account?.team,
    });
};

export const userProjection = (user): any => {
    const validation = validUser.validate(user, { stripUnknown: true, allowUnknown: true });

    if (validation.error) {
        return { error: validation.error.toString(), details: validation.error.details, original: user };
    }

    return validation.value;
};

export const userConvert = (user): any => userProjection(user);

/*

// This should be used for error validation, separately

let errorValidator = Joi.object().keys({
    code: Joi.number().required().description('code'),
    msg: Joi.string().required().description('message'),
    details: Joi.object(),
});

if (process.env.DEVLOCAL) {
    errorValidator = errorValidator.keys({
        debug: Joi.any(),
    });
}

*/

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-app-page',
    templateUrl: './app-page.component.html',
    styleUrls: ['./app-page.component.scss'],
})
export class AppPageComponent implements OnInit, OnDestroy, DoCheck {
    ready = new BehaviorSubject(false);
    appId?: string;
    app: App;
    iframeSrc: SafeUrl;
    registered: { clientId: number; appId: string; origin: string; source: any } | undefined;
    server: Server;
    eventListener: (this: Window, ev: MessageEvent<any>) => any;
    showConfig: boolean;
    destroy = new Subject<void>();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appService: AppService,
        private sideNav: SideNavService,
        private core: CoreService,
        private rpc: RPCService,
        private sanitizer: DomSanitizer,
        private snackbar: MatSnackBar,
        private files: FilesService,
        private dialog: MatDialog,
        private insightService: InsightService
    ) {
        this.server = new Server();

        /* For some reason this.server is undefined if this function is not defined in the constructor
         * Event is of type MessageEvent<any>, but there is something wrong with typescript
         * typings which breaks the event.source.postMessage() interface */
        this.eventListener = async event => {
            const url = new URL(this.app.url);

            // Only listen to messages from the loaded app
            if (event.origin !== url.origin) {
                return;
            }

            let msg: any;

            try {
                msg = JSON.parse(event.data);
            } catch (error) {
                // Console output left here for development purposes. Should be dropped, when proper error handling is implemented.
                console.log('Hailer App API received malformed message', event.data);
                return;
            }

            if (msg.op === 'register') {
                try {
                    this.server.closeAll();
                    this.server.destroy();
                    this.destroy.next();
                    this.server = new Server();

                    if (msg.args?.[0]?.appApiVersion === '2') {
                        const api = new AppApiV2(
                            this.app,
                            this.core,
                            this.server,
                            this.rpc,
                            this.appService,
                            this.insightService,
                            this.snackbar,
                            this.files,
                            this.sideNav,
                            this.dialog,
                            this.destroy
                        );
                    } else {
                        const api = new AppApiV1(
                            this.app,
                            this.core,
                            this.server,
                            this.rpc,
                            this.appService,
                            this.insightService,
                            this.snackbar,
                            this.files,
                            this.sideNav,
                            this.dialog,
                            this.destroy
                        );
                    }

                    void rpc.requestAsync('v3.telemetry.send', [
                        'App started',
                        { appId: this.appId, url: this.app.url, workspaceId: this.app.cid, private: this.app.isPrivate, appApiVersion: msg.args?.[0]?.appApiVersion || '1' },
                    ]);

                    this.registered = {
                        clientId: this.server.open(),
                        origin: event.origin,
                        appId: this.app._id,
                        source: event.source,
                    };

                    this.sendToApp('register', { appId: this.app._id, workspaceId: this.app.cid });
                } catch (error) {
                    console.log('Failed to register app, invalid JSON message', error);
                }
            } else if (this.registered) {
                const endpoint = (this.server as any).modules[msg.op];

                if (!endpoint) {
                    console.log('Cannot find api endpoint:', msg.op);
                    return;
                }

                if (typeof endpoint.argumentValidation === 'function') {
                    const schema: Joi.AnySchema = await endpoint.argumentValidation({ args: msg.args }, {});

                    const validation = schema.validate(msg.args);

                    if (validation.error) {
                        // return nice standard error
                        console.error('Malformed args', msg.op, 'args:', msg.args, validation.error, 'details:', validation.error.details);
                        event.source?.postMessage(
                            JSON.stringify({
                                err: msg.id,
                                data: {
                                    name: validation.error.name,
                                    msg: validation.error.message,
                                    code: 191,
                                    source: 'AppAPI',
                                    details: validation.error.details,
                                    debug: { op: msg.op, args: msg.args },
                                },
                            }),
                            { targetOrigin: this.registered?.origin }
                        );
                        return;
                    }
                }

                this.server.parse(
                    msg,
                    async response => {
                        // In case frame in unloaded and mesage cannot be passed, return false.
                        if (!event.source) {
                            return false;
                        }

                        if (response.err) {
                            const validation = validErrorResponse.validate(response.data);

                            if (validation.error) {
                                console.warn(
                                    msg.op,
                                    'malformed error response:',
                                    response.data,
                                    validation.error,
                                    'details:',
                                    validation.error.details
                                );
                            }
                        } else if (typeof endpoint.resultValidation === 'function') {
                            const schema: AnySchema = await endpoint.resultValidation(response, {});

                            const validation = schema.validate(response.data);

                            if (validation.error) {
                                console.warn(
                                    msg.op,
                                    'malformed response:',
                                    response.data,
                                    validation.error,
                                    'details:',
                                    validation.error.details
                                );
                            }
                        }

                        event.source.postMessage(JSON.stringify(response), { targetOrigin: this.registered?.origin });

                        return true;
                    },
                    {},
                    this.registered.clientId
                );
            } else {
                console.error('App needs to be registered before accessing Hailer');
            }
        };
    }

    ngDoCheck(): void {
        // Check if route params has changed (component is reused)
        if (this.appId !== this.route.snapshot.params.appId) {
            this.appId = this.route.snapshot.params.appId;
            void this.loadAppById(this.appId);
        }
    }

    ngOnInit(): void {
        this.appId = this.route.snapshot.params.appId;

        void this.loadAppById(this.appId);
    }

    async loadAppById(appId?: string): Promise<void> {
        if (this.app) {
            window.removeEventListener('message', this.eventListener);
        }

        if (!appId) {
            console.error('Could not load App from route!');
            await this.router.navigate(['/']);
            return;
        }

        // Marketplace (visible for all users)
        if (appId === '6620d1850271340451e65bad') {
            this.app = {
                _id: '6620d1850271340451e65bad',
                uid: this.core.user?.value?._id,
                name: 'Marketplace',
                isPrivate: true,
                description: 'Download Business Apps in Marketplace',
                url: 'https://apps.hailer.com/55703b133131611a0def6221/6620d1850271340451e65bad/',
                allowedUrls: [],
                members: [],
                config: {
                    fields: {},
                },
                updated: 7,
                created: 7,
            } as App;
        } else {
            const appMap = this.appService.getAppMap().value;
            let selectedApp = appMap[this.core.network.value._id]?.[appId];

            if (!selectedApp) {
                selectedApp = appMap[this.core.user.value._id]?.[appId];

                if (!selectedApp) {
                    console.error('Could not load App from enabled Hailer apps!');
                    await this.router.navigate(['/']);
                    return;
                }
            }

            this.app = { ...selectedApp, isPrivate: !selectedApp.cid };

            const manifest = await this.appService.fetchManifest(this.app.url);

            if (!manifest) {
                console.log('App manifest failed to download.');
                return;
            }

            this.app.manifest = manifest;

            if (this.app.cid) {
                // This is a workspace app
            } else {
                // This is a user app
            }
        }

        window.addEventListener('message', this.eventListener);

        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.app.url}index.html`);

        this.ready.next(true);
    }

    ngOnDestroy(): void {
        // Remove event listener to prevent duplicates when switching back and forth between the dashboards
        window.removeEventListener('message', this.eventListener);
        this.destroy.next();
        this.destroy.complete();
    }

    toggleConfig(): void {
        this.showConfig = !this.showConfig;
    }

    sendToApp(op: string, data: any): void {
        if (this.registered) {
            this.registered.source.postMessage(JSON.stringify({ op, data }), this.registered.origin);
        }
    }

    async navigate(url: string): Promise<void> {
        await this.router.navigate([url]);
    }
}
