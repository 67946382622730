/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Subject, debounceTime, filter, takeUntil } from 'rxjs';

import { V3ActivityViewContextService } from 'app/v3-activity/v3-activity-view-context.service';
import { V3EditMultipleHelperService } from 'app/v3-activity/v3-edit-multiple-helper.service';
import { ActivityTemplateField } from 'app/_models/v3-activity.model';
import { SideNavService } from 'app/_services/side-nav.service';
import { TranslateService } from 'app/_services/translate.service';
import { V3ActivitySidenavComponent } from '../v3-activity-sidenav.component';
import { DropdownItems, DropdownOptions, DropdownSelectedItems } from 'app/_models/dropdown-selector.model';

@Component({
    selector: 'app-v3-activity-options',
    templateUrl: './v3-activity-options.component.html',
    styleUrls: ['./v3-activity-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class V3ActivityOptionsComponent implements OnInit, OnDestroy {
    @ViewChild('followersList', { static: false }) followersList: MatSelectionList;

    optionFields: (ActivityTemplateField | undefined)[] = [];
    fieldErrorMap: { [fieldId: string]: boolean } = {};
    ownerTeamField: {
        items: BehaviorSubject<DropdownItems>;
        options: BehaviorSubject<DropdownOptions>;
        selectedItems: BehaviorSubject<DropdownSelectedItems>;
        field: ActivityTemplateField;
    } | null;

    private onDestroy = new Subject<void>();

    constructor(
        public viewContext: V3ActivityViewContextService,
        public v3EditMultipleHelper: V3EditMultipleHelperService,
        private cdr: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private transloco: TranslocoService,
        private translate: TranslateService,
        private sidenav: SideNavService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getOwnerTeamField();
        this.optionFields = [this.ownerTeamField?.field || undefined, await this.getOwnerUserField(), await this.getCreatedDateField()];

        this.viewContext.activity
            .pipe(
                takeUntil(this.onDestroy),
                filter(activity => !!activity)
            )
            .subscribe({
                next: async () => {
                    await this.getOwnerTeamField();
                    this.optionFields = [this.ownerTeamField?.field || undefined, await this.getOwnerUserField(), await this.getCreatedDateField()];

                    this.cdr.detectChanges();
                },
            });

        this.viewContext.editing
            .pipe(
                takeUntil(this.onDestroy),
                filter(state => !state)
            )
            .subscribe({
                next: () => this.followersList?.deselectAll(),
            });

        this.v3EditMultipleHelper.selectedActivities
            .pipe(
                takeUntil(this.onDestroy),
                filter(() => this.viewContext.action === 'editMultiple')
            )
            .subscribe({
                next: () => this.cdr.detectChanges(),
            });

        this.viewContext.unknownUsers.pipe(takeUntil(this.onDestroy), debounceTime(200)).subscribe({
            next: () => this.cdr.detectChanges(),
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    onErrors(fieldId: string, errorState: boolean) {
        if (!fieldId) {
            return;
        }

        this.fieldErrorMap[fieldId] = errorState;
        const currentErrors = this.viewContext.errorsInSidenav.value;
        currentErrors.invalidOptions = Object.values(this.fieldErrorMap)?.includes(true);
        this.viewContext.errorsInSidenav.next(currentErrors);
    }

    async removeFollower(userId: string) {
        if (!this.viewContext.activity.value) {
            return;
        }

        try {
            await this.viewContext.updateFollowers([{ _id: this.viewContext.activity.value._id }], { [userId]: null });
        } catch (error) {
            console.error('Failed to remove user from discussion', error);
        }
    }

    optionChanged(fieldId: string, newValue: any) {
        const activity = this.viewContext.activity.value;
        switch (fieldId) {
            case 'ownerTeam':
                const teamNotChanged = activity?.team_account?.team === newValue;
                const teamNotSetInEditMultiple = this.viewContext.action === 'editMultiple' && !newValue;
                if (teamNotChanged || teamNotSetInEditMultiple) {
                    return delete this.viewContext.toSave.options.teamId;
                }

                this.viewContext.toSave.options.teamId = newValue;
                break;
            case 'ownerUser':
                const userNotChanged = activity?.uid === newValue;
                const userNotSetInEditMultiple = this.viewContext.action === 'editMultiple' && !newValue;
                if (userNotChanged || userNotSetInEditMultiple) {
                    return delete this.viewContext.toSave.options.userId;
                }

                this.viewContext.toSave.options.userId = newValue;
                break;
            default:
                break;
        }
    }

    async deleteActivity() {
        let content = '';
        let title = '';
        const activityName = this.viewContext.activity.value?.name;
        const activitiesToSave = this.viewContext.toSave.activities?.map(({ _id }) => _id);
        await this.translate.translationLoaded('activity-sidenav');
        switch (this.viewContext.action) {
            case 'view':
                content = `${this.transloco.translate('activity-sidenav.delete-dialog.single.content')} ${activityName}?`;
                title = this.transloco.translate('activity-sidenav.delete-dialog.single.title');
                break;
            case 'editMultiple':
                content = `
                    ${this.transloco.translate('activity-sidenav.delete-dialog.multiple.content.1')}
                    ${activitiesToSave?.length}
                    ${this.transloco.translate('activity-sidenav.delete-dialog.multiple.content.2')}
                 `;
                title = this.transloco.translate('activity-sidenav.delete-dialog.multiple.title');
                break;
            default:
                console.error(`Unsupported action: ${this.viewContext.action}`);
                return;
        }

        this.viewContext.dialog
            .showConfirm(this.transloco.translate('activity-sidenav.delete-dialog.confirm'), content, title)
            .pipe(
                takeUntil(this.onDestroy),
                filter(confirmed => !!confirmed)
            )
            .subscribe({
                next: async () => {
                    this.viewContext.removingActivity = true;

                    try {
                        switch (this.viewContext.action) {
                            case 'view':
                                if (!this.viewContext.activity.value) {
                                    console.error('Activity is not defined somehow');
                                    break;
                                }

                                await this.viewContext.removeActivities([this.viewContext.activity.value._id]);
                                this.snackBar.open(`${activityName} removed!`, 'Ok', { duration: 3000 });
                                break;
                            case 'editMultiple':
                                await this.viewContext.removeActivities(activitiesToSave);
                                this.snackBar.open(`${activitiesToSave.length} activities removed!`, 'Ok', { duration: 3000 });
                                break;
                            default:
                                break;
                        }
                        this.viewContext.popSidenav.next();
                    } catch (error) {
                        switch (this.viewContext.action) {
                            case 'view':
                                console.error(`Failed to remove ${activityName}!`);
                                this.snackBar.open(`Failed to remove ${activityName}!`, 'Ok');
                                break;
                            case 'editMultiple':
                                console.error(`Failed to remove ${activitiesToSave.length} activities!`);
                                this.snackBar.open(`Failed to remove ${activitiesToSave.length} activities!`, 'Ok');
                                break;
                            default:
                                break;
                        }
                        this.viewContext.removingActivity = false;
                    }
                },
            });
    }

    allowEditing(fieldId: string): boolean {
        return this.viewContext.editing.value && fieldId !== 'createdDate';
    }

    async copyToClipboard(toCopy: any): Promise<void> {
        if (typeof toCopy !== 'string') {
            toCopy = JSON.stringify(toCopy);
        }

        await navigator.clipboard.writeText(toCopy);
        await this.translate.translationLoaded('activity-sidenav');
        this.snackBar.open(this.transloco.translate('activity-sidenav.content_copied_snackbar'), 'OK', { duration: 2000 });
    }

    openActivity(activityId: string) {
        const nextPhaseId = this.viewContext.nextPhaseId.value;
        this.sidenav.create(V3ActivitySidenavComponent, {
            activityId,
            editing: true,
            ...(nextPhaseId ? { nextPhaseId } : {}),
            updated: () => this.sidenav.pop(),
            canceled: () => this.sidenav.pop(),
        });
    }

    hasFieldErrors(activityId: string): boolean {
        return !!this.viewContext.errorsInSidenav.value?.activityFieldErrors?.[activityId];
    }

    get isAdmin(): boolean {
        const isAdmin = this.viewContext.isWorkflowAdmin || this.viewContext.isWorkspaceAdmin;
        const isOwner = this.viewContext.isWorkspaceOwner;
        return isOwner || isAdmin;
    }

    private async getOwnerUserField(): Promise<ActivityTemplateField> {
        await this.translate.translationLoaded('activity-sidenav');
        return {
            id: 'ownerUser',
            label: this.transloco.translate('activity-sidenav.settings.field.owner_user.label'),
            type: 'string',
            required: true,
            placeholder: this.transloco.translate('activity-sidenav.settings.field.owner_user.placeholder'),
            subtype: 'users',
        };
    }

    private async getCreatedDateField(): Promise<ActivityTemplateField | undefined> {
        const showCreatedDateField = this.viewContext.action === 'view';
        await this.translate.translationLoaded('activity-sidenav');
        return showCreatedDateField
            ? {
                  id: 'createdDate',
                  label: this.transloco.translate('activity-sidenav.settings.field.created_date.label'),
                  type: 'number',
                  required: true,
                  placeholder: this.transloco.translate('activity-sidenav.settings.field.created_date.placeholder'),
                  subtype: 'date',
              }
            : undefined;
    }

    private async getOwnerTeamField(): Promise<void> {
        const ownerTeamTemplate = this.viewContext.template.value?.team;
        await this.translate.translationLoaded('activity-sidenav');

        const ddItems: DropdownItems = {};
        for (const teamItem of ownerTeamTemplate?.selectableTeams || []) {
            ddItems[teamItem._id] = {
                _id: teamItem._id,
                type: 'team',
                title: teamItem.name,
                meta: {
                    memberCount: teamItem.memberCount,
                },
            };
        }

        const dropdownOptions = new DropdownOptions();
        dropdownOptions.appearance = 'fill';
        dropdownOptions.multiple = false;
        dropdownOptions.selectLastItem = true;
        dropdownOptions.required = true;
        dropdownOptions.hideClearButton = true;
        dropdownOptions.closeOnSelection = true;

        const field: ActivityTemplateField = {
            id: 'ownerTeam',
            label:
                this.viewContext.template.value?.team?.label ||
                this.transloco.translate('activity-sidenav.settings.field.owner_team.label'),
            type: 'string',
            required: true,
            placeholder: this.transloco.translate('activity-sidenav.settings.field.owner_team.placeholder'),
            subtype: 'teams',
        };

        if (this.ownerTeamField) {
            this.ownerTeamField.items.next(ddItems);
            this.ownerTeamField.field = field;
            this.cdr.detectChanges();
            return;
        }

        const predefinedTeamId = this.viewContext.activity.value?.team_account?.team;

        this.ownerTeamField = {
            items: new BehaviorSubject(ddItems),
            options: new BehaviorSubject(dropdownOptions),
            selectedItems: new BehaviorSubject(predefinedTeamId ? { team: [predefinedTeamId] } : {}),
            field,
        };

        this.cdr.detectChanges();
        this.ownerTeamField.selectedItems.pipe(takeUntil(this.onDestroy)).subscribe({
            next: ({ team }) => {
                const teamId = team?.[0];
                if (!teamId) {
                    console.warn('Selected owner team id not defined');
                    return;
                }

                this.optionChanged('ownerTeam', teamId);
            },
        });
    }
}
