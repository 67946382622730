/** @format */

import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { MatSliderChange } from '@angular/material/slider';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { FilesService, UploadState } from '../../_services/files.service';

interface CropImageOptions {
    url: string;
    isPublic?: boolean;
    aspectRatio: number;
}

@Component({
    selector: 'app-crop-image-dialog',
    templateUrl: './crop-image-dialog.component.html',
    styleUrls: ['./crop-image-dialog.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'dialogs', alias: 'dialogs' },
        },
    ],
})
export class CropImageDialogComponent {
    @ViewChild(ImageCropperComponent, { static: true }) cropper: ImageCropperComponent;
    @ViewChild('fileInput', { static: true }) fileInput: HTMLInputElement;

    croppedImage?: string;
    imgUrl: string;
    zoomPer: number;
    filename: string;
    uploading: number;
    finishedId: string;
    subscribed: Subscription;
    imageChangedEvent = null;
    enableCropping = false;
    transformCropper: ImageTransform = {};

    constructor(
        private dialogRef: MatDialogRef<CropImageDialogComponent>,
        private fileService: FilesService,
        @Inject(MAT_DIALOG_DATA) public options: CropImageOptions
    ) {}

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    onCropped(cropEvent: ImageCroppedEvent): void {
        this.croppedImage = cropEvent.base64 || undefined;

        const filename = `cropped-${this.filename}`;
        const file = this.fileService.base64ToFile(this.croppedImage, filename);

        let options: { isPublic: boolean } | undefined;

        if (this.options.isPublic) {
            options = { isPublic: true };
        }

        this.subscribed = this.fileService.upload(file, options).subscribe({
            next: (response: UploadState) => {
                if (response.progress) {
                    this.uploading = response.progress;
                }
                if (response.finished) {
                    if (response.fileId) {
                        this.finishedId = response.fileId;
                    }

                    this.close();
                }
            },
            error: error => console.error('upload error: ', error),
        });
    }

    getPercentage() {
        return this.uploading;
    }

    onImageLoaded() {
        this.filename = this.imageChangedEvent.target.files[0].name;
    }

    onError(error: any) {
        console.error('Failed to load an image: ', error);
    }

    cancel() {
        if (this.subscribed) {
            this.subscribed.unsubscribe();
        }
        if (this.finishedId) {
            this.fileService.deleteFile(this.finishedId).pipe(take(1)).subscribe();
        }
        this.dialogRef.close();
    }

    cropAndSave() {
        this.uploading = 0.01;
        this.cropper.crop();
    }

    close() {
        this.dialogRef.close(this.finishedId);
    }

    flipImage(flip: 'left' | 'right') {
        const rotate = this.transformCropper.rotate;

        let degrees = flip === 'left' ? -90 : 90;

        if (rotate) {
            degrees = flip === 'left' ? rotate - 90 : rotate + 90;
        }

        this.transformCropper = {
            ...this.transformCropper,
            rotate: degrees,
        };
    }

    sliderChange(event: MatSliderChange) {
        const value = event.value;
        this.transformCropper = {
            ...this.transformCropper,
            scale: value > 1 ? value : 1,
        };
    }

    zoomImage(direction: 'in' | 'out') {
        const step = 1;
        const zoom = this.transformCropper.scale;

        let scale = step;
        if (zoom) {
            scale = direction === 'in' ? zoom + step : zoom - step;
        }

        this.transformCropper = {
            ...this.transformCropper,
            scale: scale > 1 ? scale : 1,
        };
    }
}
