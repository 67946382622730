/** @format */

import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { Team } from '@app/models';
import { PermissionListMember } from 'app/_models/member.model';
import { TeamService } from 'app/_services/team.service';
import { UserService } from 'app/_services/user.service';
import { environment } from '@app/env';
import { PermissionType, PermissionValueType } from 'app/_models/permission-type.model';
import { CoreService } from 'app/_services/core.service';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { PeopleService } from 'app/people/people.service';
import { Group } from 'app/_models/group.model';
import { GroupService } from 'app/_services/group.service';

@Component({
    selector: 'app-permission-list',
    templateUrl: './permission-list.component.html',
    styleUrls: ['./permission-list.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class PermissionListComponent implements OnInit, OnChanges {
    @Input() members: PermissionListMember[] = [];
    @Input() permissionTypes: PermissionType[] = [];

    @Output() memberRemove = new EventEmitter<string>();
    @Output() permissionGrant = new EventEmitter<{ memberId: string; permission: PermissionValueType }>();
    @Output() permissionDeny = new EventEmitter<{ memberId: string; permission: PermissionValueType }>();

    memberTeamMap: { [teamId: string]: Team } = {};
    memberGroupMap: { [groupId: string]: Group } = {};

    profileImageBaseUrl = `${environment.wsUrl}/profileimage/square75/`;

    constructor(
        public teamService: TeamService,
        public userService: UserService,
        private core: CoreService,
        private dialogHelperService: DialogHelperService,
        private cdr: ChangeDetectorRef,
        public peopleService: PeopleService,
        private translocoService: TranslocoService,
        private groupService: GroupService
    ) {}

    ngOnInit(): void {
        void this.setMemberTeamMap();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.members && changes.members.currentValue) {
            this.sortMembers();
            void this.setMemberTeamMap();
            this.cdr.detectChanges();
        }
    }

    async setMemberTeamMap() {
        for (const member of this.members) {
            if (member.id.startsWith('team_') && !this.memberTeamMap[member.id]) {
                const team = await this.teamService.loadTeam(member.id);
                this.memberTeamMap[member.id] = team;
            }
            if (member.id.startsWith('group_') && !this.memberGroupMap[member.id]) {
                const group = await this.groupService.getGroup(member.id);
                this.memberGroupMap[member.id] = group;
            }
        }
        this.cdr.detectChanges();
    }

    removeMember(memberId: string): void {
        const confirm = this.translocoService.translate('shared.permissions.remove_confirm');
        const title = this.translocoService.translate('shared.permissions.remove_title');
        const content = this.translocoService.translate('shared.permissions.remove_content');

        this.dialogHelperService
            .showConfirm(confirm, content, title)
            .pipe(
                filter(confirmed => !!confirmed),
                take(1)
            )
            .subscribe({
                next: () => this.memberRemove.next(memberId),
            });
    }

    grantPermission(memberId: string, permission: PermissionValueType) {
        this.permissionGrant.next({ memberId, permission });
    }

    denyPermission(memberId: string, permission: PermissionValueType) {
        this.permissionDeny.next({ memberId, permission });
    }

    getWorkspaceName(workspaceId: string): string {
        if (workspaceId && workspaceId.startsWith('network_')) {
            workspaceId = workspaceId.replace('network_', '');
        }

        return this.core.networks.value[workspaceId]?.name || '';
    }

    private sortMembers() {
        this.members.sort((memberA, memberB) => {
            if (memberA.id.substr(0, 5) !== memberB.id.substr(0, 5)) {
                return memberA.id.localeCompare(memberB.id);
            }

            let nameA = '';
            let nameB = '';
            const bothAreUsers = memberA.id.startsWith('user_');

            if (bothAreUsers) {
                const userA = this.userService.getUser(memberA.id.slice(5));
                nameA = `${userA?.lastname} ${userA?.firstname}`;

                const userB = this.userService.getUser(memberB.id.slice(5));
                nameB = `${userB?.lastname} ${userB?.firstname}`;
            } else {
                nameA = this.teamService.getTeam(memberA.id.slice(5))?.name;
                nameB = this.teamService.getTeam(memberB.id.slice(5))?.name;
            }

            return nameA.localeCompare(nameB);
        });
        this.cdr.detectChanges();
    }
}
