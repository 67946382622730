<!-- @format -->

<div class="container">
    <div class="topbar">
        <button
            *ngIf="this.isAppPage"
            mat-icon-button
            class="hover-lighten"
            tippy="Back to Apps"
            (click)="clickBackButton()"
            style="left: 8px">
            <mat-icon class="back-button">arrow_back</mat-icon>
        </button>

        <span class="title" *ngIf="!this.isAppPage">Apps & Insights</span>
    </div>
</div>
