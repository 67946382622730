<!-- @format -->

<div #messageView class="center-area">
	<!-- {{ redraw() }} Enable this for counting redraw events -->
	<div class="focusing" *ngIf="selectedDiscussion.focusing | async">
		<div class="loader"></div>
	</div>
	<div class="messenger-bg"></div>
	<div *ngIf="!sidenavMode" class="discussion-header">
		<div class="container align-items-center header-container">
			<app-button
				type="icon"
				color="icon-default"
				hailerIconName="hailer-chevron"
				matIconClass="hailer-chevron left"
				(clickEvent)="closeDiscussion()"
				ariaLabel="{{ 'discussion.message-view.close-discussion' | transloco }}"
				class="close-discussion-button"
                focusOutline="inner">
			</app-button>

			<div class="flexbox-column" style="overflow: hidden">
				<h3>{{ getDiscussionSubject(discussion | async) }}</h3>
				<span *ngIf="(discussion | async) && !(typingUsers | async)?.length">
					{{ "discussion.message-view.last_active" | transloco }} {{ (discussion | async)?.last_active | todayDate | async }}
				</span>

				<span *ngIf="(discussion | async) && (typingUsers | async)?.length">
					<ng-container *ngIf="(typingUsers | async)?.length <= 3">
						<span>
							<span *ngFor="let userId of typingUsers | async" class="user-container">
								<app-user-link
									*ngIf="userId && participantColorMap?.value"
									[userId]="userId"
									[color]="(participantColorMap | async)[userId] || 'var(--user-link-default)'"
									[darkLink]="theme.getActiveTheme().name === 'light'"
									[inheritLinkSize]="true">
								</app-user-link>
								<span class="comma">, </span>
							</span>
						</span>
						<span data-cy="is-typing">
							{{
								(typingUsers | async).length > 1
									? ("discussion.message-view.are_typing" | transloco)
									: ("discussion.message-view.is_typing" | transloco)
							}}
						</span>
					</ng-container>
					<ng-container *ngIf="(typingUsers | async)?.length > 3">
						{{ "discussion.message-view.people_typing" | transloco }}
					</ng-container>
				</span>
			</div>
		</div>

		<div class="button-container">
			<div
				data-cy="profile-picture-top-bar-button"
				*ngIf="screenWidth < 1400"
				class="participants-container"
				[style.border-right]="(newContact | async) ? 'none' : 'null'">
				<ng-container *ngIf="!(discussion | async)?.private && !(newContact | async)">
					<ng-container *ngIf="screenWidth > 600">
						<img
							*ngFor="let participant of participants | async | keyvalue | slice : 0 : 3"
							class="profile-picture"
							width="40"
							height="40"
							[src]="getProfilePictureURL(participant?.value?._id)"
							(click)="openUser(participant?.value?._id)"
							onError="this.onerror = null; this.src='../assets/img/default-profile-picture.jpeg'"
							[tippy]="participant?.value?.display_name"
							[alt]="participant?.value?.display_name"
							tabindex="0" />

						<ng-container *ngIf="!(discussion | async)?.private">
							<app-button
								*ngIf="(participants | async | keyvalue)?.length - 3 <= 0"
								type="icon"
								color="secondary"
								hailerIconName="hailer-add-user-fill"
								[dataCy]="'add-participants-header-button'"
								[isDisabled]="isUserGuest"
								(clickEvent)="addParticipants()"
								[tippyText]="'discussion.message-view.add-participants' | transloco"
								[ariaLabel]="'discussion.message-view.add-participants' | transloco">
							</app-button>
                            <app-button
                                *ngIf="(participants | async | keyvalue)?.length - 3 > 0"
                                [menuTriggerFor]="participantsMenu"
                                type="count"
                                color="secondary"
                                size="medium"
                                tippyText="{{ 'discussion.message-view.more-participants' | transloco }}"
                                [count]="(participants | async | keyvalue)?.length - 3">
                            </app-button>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="screenWidth <= 600">
						<app-button
							*ngIf="(participants | async | keyvalue)?.length > 0"
							type="icon"
							color="icon-default"
							hailerIconName="hailer-group-fill"
							[menuTriggerFor]="participantsMenu">
						</app-button>
					</ng-container>

					<mat-menu #participantsMenu="matMenu">
						<button
							mat-menu-item
							*ngFor="
								let participant of screenWidth > 600 ? (participants | async | keyvalue | slice : 3) : (participants | async | keyvalue)
							"
							(click)="openUser(participant?.value?._id)">
							<app-user-profile-picture
								style="margin-right: 16px; height: 40px"
								[userId]="participant?.value?._id"
								[size]="32"></app-user-profile-picture>
							<span>{{ participant?.value?.display_name }}</span>
						</button>
						<button *ngIf="!(discussion | async)?.private" mat-menu-item (click)="addParticipants()" [disabled]="isUserGuest">
							<mat-icon svgIcon="hailer-add-user" style="margin-right: 8px"></mat-icon>
							{{ "discussion.message-view.add-participants" | transloco }}
						</button>
					</mat-menu>
				</ng-container>
				<ng-container *ngIf="(discussion | async)?.private || (newContact | async)">
					<img
						*ngFor="let participant of participantsWithoutMe"
						class="profile-picture"
						width="40"
						height="40"
						[src]="getProfilePictureURL(participant)"
						(click)="openUser(participant)"
						onError="this.onerror = null; this.src='../assets/img/default-profile-picture.jpeg'"
						[tippy]="getParticipantName(participant)"
						[alt]="getParticipantName(participant)"
						tabindex="0" />
				</ng-container>
			</div>

			<ng-container *ngIf="screenWidth > 600 && screenWidth <= 1400 && !(newContact | async)">
				<app-button
					dataCy="unstar-discussion-top-bar-button"
					(clickEvent)="toggleStarred()"
					type="icon"
					color="icon-default"
					[hailerIconName]="(discussion | async)?.isStarred ? 'hailer-star-fill' : 'hailer-star'"
					[matIconClass]="(discussion | async)?.isStarred ? 'star-icon active' : 'star-icon inactive'"
					[tippyText]="
						(discussion | async)?.isStarred ? ('discussion.message-view.unstar' | transloco) : ('discussion.message-view.star' | transloco)
					"
					[ariaLabel]="
						(discussion | async)?.isStarred ? ('discussion.message-view.unstar' | transloco) : ('discussion.message-view.star' | transloco)
					">
				</app-button>

				<app-mute-discussion-button [discussion]="discussion | async"> </app-mute-discussion-button>

				<!--
                If Jitsi is re-enabled, find the external Jitsi angular app and link to that (needs fixing first)

                <app-button
					type="icon"
					color="icon-default"
					hailerIconName="hailer-meeting"
					[isDisabled]="true"
					tippyText="{{ 'discussion.message-view.open_meeting' | transloco }}"
					ariaLabel="{{ 'discussion.message-view.open_meeting' | transloco }}">
				</app-button>
                -->

				<app-button
					*ngIf="!(discussion | async)?.private"
					type="icon"
					color="icon-destructive"
					hailerIconName="hailer-sign-out"
					(clickEvent)="leaveDiscussion()"
					tippyText="{{ 'discussion.message-view.leave_discussion' | transloco }}"
					ariaLabel="{{ 'discussion.message-view.leave_discussion' | transloco }}">
				</app-button>
			</ng-container>

			<ng-container *ngIf="screenWidth <= 600 && !(newContact | async)">
				<app-button type="icon" color="icon-default" hailerIconName="hailer-more" [menuTriggerFor]="discussionOptionsMenu"> </app-button>
			</ng-container>

			<ng-container *ngIf="screenWidth > 600">
				<button
					data-cy="discussion-view-activity-button"
					*ngIf="selectedDiscussion.allowActivitySidenav"
					mat-icon-button
					(click)="openActivity()"
					class="hovering-button activity-button"
					tippy="{{ 'discussion.message-view.view_activity' | transloco }}"
					[attr.aria-label]="'discussion.message-view.view_activity' | transloco">
					<mat-icon svgIcon="hailer-activity" class="activity-icon"></mat-icon>
					<mat-icon class="chevron-left-icon">chevron_left</mat-icon>
				</button>
				<button
					data-cy="discussion-view-event-button"
					*ngIf="selectedDiscussion.allowEventSidenav"
					mat-icon-button
					(click)="openEvent()"
					class="hovering-button event-button"
					tippy="{{ 'discussion.message-view.view_event' | transloco }}"
					[attr.aria-label]="'discussion.message-view.view_event' | transloco">
					<mat-icon svgIcon="hailer-events" class="event-icon"></mat-icon>
					<mat-icon class="chevron-left-icon">chevron_left</mat-icon>
				</button>
				<button
					data-cy="discussion-view-group-button"
					*ngIf="discussionType === 'group'"
					mat-icon-button
					(click)="openGroup()"
					class="hovering-button group-button"
					tippy="{{ 'discussion.message-view.view_group' | transloco }}"
					[attr.aria-label]="'discussion.message-view.view_group' | transloco">
					<mat-icon svgIcon="hailer-group" class="group-icon"></mat-icon>
					<mat-icon class="chevron-left-icon">chevron_left</mat-icon>
				</button>
			</ng-container>
			<mat-menu #newLinkedActivityMenu="matMenu">
				<ng-template matMenuContent>
					<button (click)="createLinkedActivity(object.process)" *ngFor="let object of canBeLinkedFromProcesses | async" mat-menu-item>
						{{ object?.process.name }}
					</button>
				</ng-template>
			</mat-menu>
		</div>
	</div>

	<div *ngIf="(discussion | async) && sidenavMode" class="action-menu">
		<app-button type="icon" color="tertiary-highlight-background" hailerIconName="hailer-see-more" [menuTriggerFor]="discussionOptionsMenu">
		</app-button>
	</div>
	<app-messages-container
		[style.display]="'contents'"
		(clearDiscussionVariables)="clearDiscussionVariables($event)"
		(closeDiscussionInputMenuRequest)="closeDiscussionInputMenu($event)">
	</app-messages-container>
	<app-discussion-input
		[style.display]="(contextMenuMessage | async) ? 'none' : 'unset'"
		(resized)="onInputResize($event)"
		#discussionInput
		[class.fixed]="inMobileDiscussionView"
		(newDiscussion)="selectNewDiscussion($event)"
		[showJumpToBottom]="!(newestLoaded | async) && (messages | async)?.length > 0"
		(jumpToBottom)="jumpToBottom()"
		(jumpToSentMessage)="jumpToSentMessage()">
	</app-discussion-input>
	<div *ngIf="!inMobileDiscussionView" class="upload-screen" [class.upload-drag]="dragging | async">
		<mat-icon>cloud_upload</mat-icon>
	</div>

	<mat-menu #discussionOptionsMenu="matMenu">
		<button mat-menu-item (click)="toggleStarred()">
			<mat-icon
				[svgIcon]="(discussion | async)?.isStarred ? 'hailer-star-fill' : 'hailer-star'"
				[ngClass]="(discussion | async)?.isStarred ? 'star-icon active' : 'star-icon inactive'"></mat-icon>
			<span>{{
				!(discussion | async)?.isStarred ? ("discussion.message-view.star" | transloco) : ("discussion.message-view.unstar" | transloco)
			}}</span>
		</button>
		<app-mute-discussion-button [discussion]="discussion | async" [menuMode]="true"> </app-mute-discussion-button>
		<!-- <button mat-menu-item [disabled]="true">
			<mat-icon svgIcon="hailer-meeting" class="default"></mat-icon><span>{{ "discussion.message-view.open_meeting" | transloco }}</span>
		</button> -->
		<button *ngIf="!(discussion | async)?.private" mat-menu-item (click)="leaveDiscussion()" class="destructive-text">
			<mat-icon svgIcon="hailer-sign-out" class="warn"></mat-icon
			><span>{{ "discussion.message-view.leave_discussion" | transloco }}</span>
		</button>
	</mat-menu>
</div>

<div class="side-list" *ngIf="!sidenavMode">
	<div class="message-search-container fill-field-container">
		<ng-container *ngIf="screenWidth > 1400 && !(newContact | async)">
			<app-button
				(clickEvent)="toggleStarred()"
				type="icon"
				color="icon-default"
				[hailerIconName]="(discussion | async)?.isStarred ? 'hailer-star-fill' : 'hailer-star'"
				[matIconClass]="(discussion | async)?.isStarred ? 'star-icon active' : 'star-icon'"
				[tippyText]="
					(discussion | async)?.isStarred ? ('discussion.message-view.unstar' | transloco) : ('discussion.message-view.star' | transloco)
				"
				[ariaLabel]="
					(discussion | async)?.isStarred ? ('discussion.message-view.unstar' | transloco) : ('discussion.message-view.star' | transloco)
				"></app-button>

			<app-mute-discussion-button [discussion]="discussion | async"> </app-mute-discussion-button>

			<!-- <app-button
				type="icon"
				color="icon-default"
				hailerIconName="hailer-meeting"
				[isDisabled]="true"
				tippyText="{{ 'discussion.message-view.open_meeting' | transloco }}"
				ariaLabel="{{ 'discussion.message-view.open_meeting' | transloco }}">
			</app-button> -->
		</ng-container>
		<mat-form-field appearance="fill">
			<input placeholder="{{ 'discussion.message-view.search_messages' | transloco }}" matInput [formControl]="messageSearch" />
			<button
				mat-button
				*ngIf="messageSearch.value"
				matSuffix
				mat-icon-button
				[attr.aria-label]="'discussion.message-view.clear_search' | transloco"
				(click)="messageSearch.setValue('')">
				<mat-icon svgIcon="hailer-x"></mat-icon>
			</button>
		</mat-form-field>

		<app-button
			*ngIf="screenWidth > 1400 && !(discussion | async)?.private"
			type="icon"
			color="icon-destructive"
			hailerIconName="hailer-sign-out"
			(clickEvent)="leaveDiscussion()"
			tippyText="{{ 'discussion.message-view.leave_discussion' | transloco }}"
			ariaLabel="{{ 'discussion.message-view.leave_discussion' | transloco }}">
		</app-button>
	</div>
	<div class="participant-list">
		<ng-container *ngIf="searchedMessages?.length <= 0; else searchMessageList">
			<div class="activity-overview" *ngIf="selectedDiscussion.allowActivitySidenav">
				<button
					class="activity-name no-button"
					(click)="openActivity()"
					[attr.aria-label]="('discussion.message-view.view_activity' | transloco) + (discussion | async)?.subject">
					<mat-icon svgIcon="hailer-activity"></mat-icon>
                    {{ (discussion | async)?.subject }}
				</button>
			</div>

			<div class="event-overview" *ngIf="selectedDiscussion.allowEventSidenav">
				<button
					class="event-name no-button"
					(click)="openEvent()"
					[attr.aria-label]="('discussion.message-view.view_event' | transloco) + (discussion | async)?.subject">
					<mat-icon svgIcon="hailer-events"></mat-icon>
                    {{ (discussion | async)?.subject }}
				</button>
			</div>

			<div class="group-overview" *ngIf="discussionType === 'group'">
				<button
					class="group-name no-button"
					(click)="openGroup()"
					[attr.aria-label]="('discussion.message-view.view_group' | transloco) + (discussion | async)?.subject">
					<mat-icon svgIcon="hailer-group"></mat-icon>
                    {{ (discussion | async)?.subject }}
				</button>
			</div>

			<ng-container *ngIf="(discussion | async)?.private || (newContact | async)">
				<button
					class="container participant align-items-center private-participant no-button"
					*ngFor="let participant of participantsWithoutMe"
					(click)="openUser(participant)"
					[attr.aria-label]="('discussion.message-view.view-profile' | transloco) + getParticipantName(participant)">
					<app-user-profile-picture class="profile-picture" [userId]="participant" [size]="64"></app-user-profile-picture>
					<div class="flexbox-column">
						<span class="bold">{{ getParticipantName(participant) }}</span>
						<small *ngIf="discussion | async" class="two-line-ellipsis">{{
							(typingUsers | async)?.includes(participant?.value?._id)
								? ("discussion.message-view.participants" | transloco)
								: (participants | async)[participant]?.status
						}}</small>
					</div>
				</button>
			</ng-container>

			<ng-container *ngIf="!(discussion | async)?.private && !(newContact | async)">
				<span class="title-header" tabindex="0"
					>{{ 'discussion.message-view.participants' | transloco }} {{ (participants | async | keyvalue)?.length }}</span
				>
				<button
					class="container participant align-items-center no-button"
					*ngFor="let participant of participants | async | keyvalue | alphabeticalSort : 'value.display_name' : true"
					(click)="openUser(participant?.value?._id)">
					<app-user-profile-picture class="profile-picture" [userId]="participant?.value?._id" [size]="36"></app-user-profile-picture>
					<div class="flexbox-column">
						<span>{{ participant?.value?.display_name }}</span>
						<small>{{
							(typingUsers | async)?.includes(participant?.value?._id) ? ('discussion.message-view.is_typing' | transloco) : ''
						}}</small>
					</div>
				</button>
				<button
					*ngIf="!(discussion | async)?.private"
					class="container participant align-items-center no-button"
					[disabled]="isUserGuest"
					(click)="addParticipants()">
					<mat-icon svgIcon="hailer-add-user" style="margin: 8px"></mat-icon>
					<div class="flexbox-column">
						<span style="margin-left: 8px">{{ "discussion.message-view.add-participants" | transloco }}</span>
					</div>
				</button>
			</ng-container>
		</ng-container>
		<ng-template #searchMessageList>
			<button *ngFor="let message of searchedMessages" class="container message-result no-button" (click)="focusToMessage(message)">
				<app-user-profile-picture [userId]="message.uid" [size]="36"></app-user-profile-picture>
				<div class="vertical-menu-flex">
					<span
						><strong>{{
							(participants | async)[message.uid]?.display_name || ("discussion.message-view.unknown_user" | transloco)
						}}</strong></span
					>
					<span>"{{ message.msg }}"</span>
					<span class="small-text"
						>{{ message._discussion.subject }} -
						{{ message.created | date : "mediumDate" : undefined : ("misc.date.locale" | transloco) }}</span
					>
				</div>
			</button>
		</ng-template>
	</div>
</div>
