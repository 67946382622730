<!-- @format -->

<app-container>
    <app-app-topbar [parent]="'management'"></app-app-topbar>

    <div class="app-management-page-container">
        <div class="list-component-container">
            <!-- Workspace Apps -->
            <app-app-list
                class="list"
                [listItems]="this.appService.getWorkspaceApps().value"
                (selectedApp)="$event ?? appService.routeToApp($event)">
            </app-app-list>

            <!-- User Apps -->
            <app-app-list
                *ngIf="userAppsEnabled()"
                class="list"
                [listItems]="this.appService.getUserApps().value"
                (selectedApp)="$event ?? appService.routeToApp($event)">
            </app-app-list>
        </div>
    </div>
</app-container>
