/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { SharedModule } from '@app/shared/shared.module';
import { FileViewComponent } from './file-view/file-view.component';
import { ButtonsModule } from 'app/buttons/buttons.module';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
    declarations: [FileViewComponent],
    imports: [CommonModule, SharedModule, TranslocoModule, ButtonsModule, A11yModule],
    exports: [FileViewComponent],
})
export class FilesSharedModule {}
