<mat-toolbar class="toolbar__main-row mat-elevation-z4">
    <div class="toolbar-items-container">
        <button mat-icon-button *ngIf="network" [matMenuTriggerFor]="networkMenu">
            <div matListAvatar class="network-icon" [style.background]="network.settings?.color">
                <p>{{networksLogo}}</p>
            </div>
        </button>
        <mat-menu #networkMenu="matMenu" [overlapTrigger]="false" xPosition="after" matMenuContent>
            <button [disabled]="permissions.isNetworkGuest" mat-menu-item *ngIf="permissions.isNetworkAdmin" [routerLink]="['/settings']">
                <mat-icon style="margin-bottom: 4px;" svgIcon="hailer-settings"></mat-icon>
                <span>{{'shared.top-toolbar.workspace_settings' | transloco}}</span>
            </button>
            <button mat-menu-item disabled *ngIf="(networks | async)?.length > 1">
                <span class="other-networks-text">{{'shared.top-toolbar.my_workspaces' | transloco}}</span>
            </button>
            <div *ngFor="let network of networks | async">
                <button class="network-button" mat-menu-item (click)="core.switchNetwork(network._id)">
                    <div class="current-network-container" *ngIf="currentNetworkId === network._id">
                        <span class="current-network-name network-name one-line-ellipsis">{{network.name}}</span>
                        <span class="enabled-bubble"></span>
                    </div>
                    <div *ngIf="currentNetworkId !== network._id">
                        <span class="network-name one-line-ellipsis">{{network.name}}</span>
                    </div>
                </button>
            </div>
            <button *ngIf="!isManagedUser" mat-menu-item (click)="newNetwork()">
                <mat-icon class="success" svgIcon="hailer-plus"></mat-icon>
                <span class="create-button-text">{{'shared.top-toolbar.create_new_workspace' | transloco}}</span>
            </button>
        </mat-menu>

        <div class="header-title-container">
            <span *ngIf="!(reconnecting| async); else reconnectingMobile">
                <img crossorigin draggable="false" [src]="'assets/img/hailerlogo3_white.svg'"
                style="width: 80px; margin-left: 24px; margin-bottom: 4px;">
            </span>
            <ng-template #reconnectingMobile>
                <span class="reconnecting-mobile">
                    <mat-spinner [diameter]="20"></mat-spinner><span style="margin-left: 4px;">{{'shared.top-toolbar.reconnecting' | transloco}}</span>
                </span>
            </ng-template>
            <!--p class="hailer-title disable-selection header-item" (click)="toggleTheme()" [ngClass]="{'header-item-hidden': switchInProgress}">Hailer</p-->
        </div>
        <div class="toolbar__actions">
            <app-global-search dataCy="global-search-button-top-toolbar"></app-global-search>
            <button mat-icon-button [matMenuTriggerFor]="notificationsMenu">
                <mat-icon style="color: white;" matBadge="{{verifyEmailUnreadCount === 0 ? (notificationUnreadCount|async) : ((notificationUnreadCount|async)+1)}}"
                matBadgeColor="warn">{{(verifyEmailUnreadCount === 0 ? (notificationUnreadCount|async) : ((notificationUnreadCount|async)+1)) > 0 ? 'notifications' : 'notifications_none'}}</mat-icon>
            </button>
            <mat-menu #notificationsMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="override-mat-menu"
            (closed)="markAllNotificationsSeen()">
                <ng-template matMenuContent>
                    <mat-list>
                        <mat-list-item class="title-item" (click)="$event.stopPropagation()">
                            <h4>{{'shared.top-toolbar.notifications' | transloco}}</h4>
                            <div *ngIf="verifyEmailUnreadCount === 0" matBadge={{notificationUnreadCount|async}} matBadgeColor={{notificationBadgeColor}}
                                class="notification-menu__badge">
                            </div>
                            <div *ngIf="verifyEmailUnreadCount === 1" matBadge={{(notificationUnreadCount|async)+1}} matBadgeColor={{notificationBadgeColor}}
                                class="notification-menu__badge">
                            </div>
                            <button class="mark-all-as-read-button" color="accent" mat-icon-button
                                (click)="markAllNotificationsRead(); $event.stopPropagation()" 
                                tippy="{{'shared.top-toolbar.tippy.mark_all_as_read' | transloco}}">
                                <mat-icon>check</mat-icon>
                            </button>
                        </mat-list-item>
                        <div #notificationContainer (scroll)="onScroll()" class="notification-container">
                            <app-notification #notificationComponent *ngIf="!(user | async)?.emailVerified"
		                        [notification]="verifyEmailNotification"></app-notification>
                            <app-notification #notificationComponent *ngFor="let notification of (notifications | async)"
                                [notification]="notification"></app-notification>
                        </div>
                    </mat-list>
                </ng-template>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>
