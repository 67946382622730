/** @format */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'urlify' })
export class UrlifyPipe implements PipeTransform {
    transform(value = '', embedVideo?: boolean): any {
        // Fix for broken links
        const find = '&#10;';
        const replace = '\n';
        value = value ? value.replace(new RegExp(find, 'g'), replace) : value;

        // URLs
        const pattern = /((?:(https?:\/\/|ftp:\/\/)|www\.)[A-Za-z0-9][\w@!?#%&\/\[\]\(\)|+=;:,.~-]*)/gi;
        value = value.replace(pattern, (match, url, protocol) => {
            const youtube = url.match(/(https?:\/\/|www\.)youtu(\.be\/([\w-]+)|be\.com\/(.*?)v=([\w-]+))/i);
            if (youtube && (youtube[5] || youtube[3]) && embedVideo) {
                // HasEmbeds = true;
                const id = youtube[5] || youtube[3];

                const aspectRatioWrapperCSS = 'position:relative;width:100%;height:0;padding-top:56%;';
                const aspectRatioWrapperStart = `<div style="${aspectRatioWrapperCSS}">`;
                const aspectRatioWrapperEnd = '</div>';

                const iframeCSS = 'position:absolute;width:100%;height:100%;left:0;top:0;';
                const iframeStart = `<iframe style="${iframeCSS}" src="https://www.youtube.com/embed/`;
                const iframeEnd = '?rel=0" frameborder="0" allowfullscreen></iframe>';

                const element = aspectRatioWrapperStart + iframeStart + id + iframeEnd + aspectRatioWrapperEnd;
                console.log('element', element)
                return match.replace(url, element);
            }
            return match.replace(url, `<a href="${protocol ? '' : 'http://'}${url}" target="_blank" rel="noreferrer">${url}</a>`);
        });

        // Emails
        value = value.replace(/(?:^|\s)(mailto:)([\w\.-]+@[\w\.-]+[A-Za-z]{2,})(?!\S)/gi, (match, mailto, email) => {
            match = match.replace(mailto, '');
            return match.replace(email, `<a href="mailto:${email}">${email}</a>`);
        });

        /* If (typeof obj === 'object' && hasEmbeds) {
               obj.hasEmbeds = true;
           } */

        return value;
    }
}
