/** @format */

export class DateTimeRangeField {
    constructor(
        public value: {
            start: string;
            end: string;
        }
    ) {}
}
