/** @format */

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, filter, takeUntil } from 'rxjs';

import { V3ActivityViewContextService } from 'app/v3-activity/v3-activity-view-context.service';
import { DropdownSelectedItems } from 'app/_models/dropdown-selector.model';
import { SelectedDiscussionService } from 'app/_services/selected-discussion.service';

@Component({
    selector: 'app-v3-activity-discussion',
    templateUrl: './v3-activity-discussion.component.html',
    styleUrls: ['./v3-activity-discussion.component.scss'],
    providers: [SelectedDiscussionService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class V3ActivityDiscussionComponent implements OnInit, OnDestroy {
    private discussionId: string | undefined;
    private onDestroy = new Subject<void>();

    constructor(public viewContext: V3ActivityViewContextService, private selectedDiscussion: SelectedDiscussionService) {}

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static redrawCount = 0;
    redraw() {
        V3ActivityDiscussionComponent.redrawCount += 1;
        // Console.log('V3ActivityDiscussionComponent view redrawed: ' + V3ActivityDiscussionComponent.redrawCount + ' times');
    }

    ngOnInit(): void {
        if (this.viewContext.activity.value) {
            this.discussionId = this.viewContext.activity.value.discussion;
        }

        this.viewContext.activity.pipe(takeUntil(this.onDestroy)).subscribe({
            next: activity => {
                if (!activity || activity.discussion === this.discussionId) {
                    return;
                }

                this.discussionId = activity?.discussion;
            },
        });

        this.viewContext.currentTab
            .pipe(
                takeUntil(this.onDestroy),
                filter(tab => tab === 'discussion')
            )
            .subscribe({
                next: () => {
                    this.selectedDiscussion.scrollTo.next('jumpToBottom');
                    this.selectedDiscussion.focusMessageInput.next();
                },
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    usersSelected(event: DropdownSelectedItems) {
        if (!event?.user?.length) {
            return delete this.viewContext.toSave.options.followerIds;
        }

        this.viewContext.toSave.options.followerIds = event.user;
    }
}
