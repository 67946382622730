/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

import { PersonalSettings, Process, Team, TeamAccount, TeamAccountGroup, TeamMap } from '@app/models';
import { CoreService } from './core.service';
import { RPCService } from './rpc.service';

@Injectable({
    providedIn: 'root',
})
export class TeamService {
    allTeams: BehaviorSubject<TeamMap>;
    teams: Observable<{ [id: string]: Team }>;
    sortedTeams: Observable<Team[]>;
    constructor(private core: CoreService, private rpc: RPCService, private translocoService: TranslocoService) {
        this.allTeams = this.core.teams;
        this.teams = this.core.networkFilter<Team>(this.core.teams);
        this.sortedTeams = this.teams.pipe(
            map(tm => Object.values(tm).sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())))
        );
    }

    getTeam(teamId: string, options?: { workspaceId?: string }): Team {
        const workspaceId = options?.workspaceId || this.core.network.value._id;

        if (teamId && teamId.startsWith('team_')) {
            teamId = teamId.slice(5);
        }

        const allTeams = this.allTeams?.value;
        if (allTeams && Object.keys(allTeams).length) {
            const networkTeams = allTeams[workspaceId];
            if (!networkTeams) {
                return new Team().deserialize({
                    name: this.translocoService.translate('misc.services.team.unknown_team1'),
                    _id: '0000000',
                });
            }
            const foundTeam = networkTeams[teamId];
            if (foundTeam) {
                return foundTeam;
            }
        }

        return new Team().deserialize({
            name: this.translocoService.translate('misc.services.team.unknown_team1'),
            _id: '0000000',
        });
    }

    async loadTeam(teamId: string, options?: { workspaceId?: string }): Promise<Team | null> {
        if (!teamId) {
            return null;
        }

        const workspaceId = options?.workspaceId || this.core.network.value._id;

        if (teamId.startsWith('team_')) {
            teamId = teamId.slice(5);
        }

        const allTeams = this.allTeams?.value;
        if (allTeams && Object.keys(allTeams).length) {
            const networkTeams = allTeams[workspaceId];
            if (!networkTeams) {
                return (await this.rpc.requestAsync('company.load_team', [teamId])) as Team;
            }
            const foundTeam = networkTeams[teamId];
            if (foundTeam) {
                return foundTeam;
            }
        }

        return (await this.rpc.requestAsync('company.load_team', [teamId])) as Team;
    }

    getTeams(options?: { workspaceId?: string }): Team[] {
        const workspaceId = options?.workspaceId || this.core.network.value._id;
        const teams = this.core.teams.getValue();

        if (!Object.keys(teams)?.length || !teams[workspaceId]) {
            return [];
        }

        return Object.values(teams[workspaceId]).sort((t1, t2) => {
            if (!t1.name) {
                t1.name = this.translocoService.translate('misc.services.team.unknown_team2');
            }

            if (!t2.name) {
                t2.name = this.translocoService.translate('misc.services.team.unknown_team2');
            }

            return t1.name.toLowerCase().localeCompare(t2.name.toLowerCase());
        });
    }

    getTeamByName(teamName: string): Team {
        const foundTeam = Object.values(this.allTeams.value[this.core.network.value?._id]).find(
            team => team.name.toLocaleLowerCase() === teamName.toLocaleLowerCase()
        );
        if (foundTeam) {
            return foundTeam;
        }
        return new Team().deserialize({
            name: this.translocoService.translate('misc.services.team.unknown_team1'),
            _id: '0000000',
        });
    }

    getUserTeams(uid: string, options?: { workspaceId?: string }): Team[] {
        const userTeams: Team[] = [];
        const workspaceId = options?.workspaceId || this.core.network.value?._id;

        if (!this.allTeams?.value || !this.allTeams.value[workspaceId]) {
            return [];
        }

        for (const team of Object.values(this.allTeams.value[workspaceId])) {
            if (team?.members?.includes(uid)) {
                userTeams.push(team);
            }
        }
        return userTeams;
    }

    getUserTeamsFromAllWorkspaces(userId: string): Team[] {
        const teams: Team[] = [];
        for (const workspaceId in this.core.networks.value) {
            if (!workspaceId) {
                continue;
            }
            teams.push(...this.getUserTeams(userId, { workspaceId }));
        }

        return teams;
    }

    ownTeam(teamAccount: TeamAccount, userId: string): boolean {
        if (this.getTeam(teamAccount.team).members) {
            if (this.getTeam(teamAccount.team).members.find(user => user === userId)) {
                return true;
            }
            return false;
        }
        return false;
    }

    addUsersToTeam(teamId: string, userIds?: string[]): Observable<any> {
        const currentUsers = this.getTeam(teamId).members;
        const users = userIds?.concat(currentUsers) || [];
        return this.rpc.request('company.update_team_users', [teamId, users]);
    }

    removeUserFromTeam(teamId: string, userId: string): Observable<any> {
        const currentUsers = this.getTeam(teamId).members;
        const users = currentUsers.filter(id => id !== userId);
        return this.rpc.request('company.update_team_users', [teamId, users]);
    }

    generateTeamAccountList(process: Process, user: PersonalSettings, initValue?: TeamAccount, allTeams = false): any[] {
        const teams: Team[] = this.getTeams();

        const filteredTeams = allTeams ? teams : [];

        if (user && user.id && !allTeams) {
            teams.forEach((team: Team) => {
                if (team.members.findIndex(userId => userId === user.id) !== -1) {
                    filteredTeams.push(team);
                }
            });
        }

        const shadowGroup: TeamAccountGroup[] = [];
        filteredTeams.forEach((team: Team, index: number) => {
            shadowGroup.push({
                accounts: [],
                name: team.name,
                _id: team._id,
            });
        });

        const teamGroups: TeamAccountGroup[] = shadowGroup.filter((value: TeamAccountGroup) => value.accounts.length > 0);

        if (initValue && !teamGroups.find(teamGroup => teamGroup._id === initValue.team)) {
            const initTeam = teams[teams.findIndex(team => team._id === initValue.team)];

            if (initTeam) {
                const initTeamObject = {
                    accounts: [],
                    name: initTeam.name,
                    _id: initTeam._id,
                };

                teamGroups.push(initTeamObject);
            }
        }

        return teamGroups;
    }
}
