/** @format */

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, Subject, filter, take, takeUntil } from 'rxjs';
import { formatDate } from '@angular/common';
import moment from 'moment';

import { ActivityLinkField, Country, CountrySelectOptions, ProcessFieldType } from '@app/models';
import { TeamUserSelectorComponent } from '@app/shared/user-team-selector/team-user-selector.component';
import { DropdownSelectedItems } from 'app/_models/dropdown-selector.model';
import { TeamUserSelectorOptions } from 'app/_models/teamUserSelectorOptions.model';
import { ActivityFieldValue, ActivityLink, ActivityTemplateField } from 'app/_models/v3-activity.model';
import { V3ActivityViewContextService } from '../v3-activity-view-context.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FileUploaderComponent } from '@app/shared/file-uploader/file-uploader.component';
import { FilesService } from 'app/_services/files.service';

@Component({
    selector: 'app-v3-activity-input-field',
    templateUrl: './v3-activity-input-field.component.html',
    styleUrls: ['./v3-activity-input-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class V3ActivityInputFieldComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('teamUserSelector', { static: true }) teamUserSelector: TeamUserSelectorComponent;
    @ViewChild('focusInput', { static: false }) focusInput: ElementRef;
    @ViewChild('optionSelect', { static: false }) optionSelect: MatSelect;
    @ViewChild('dateInput', { static: false }) dateInput: ElementRef;
    @ViewChild('uploader', { static: false }) uploader: FileUploaderComponent;

    @Input() field?: ActivityTemplateField;

    /** Overrules value from activity, used in the import edit component */
    @Input() fieldValue: any;
    @Input() isNextPhaseField: boolean;

    @Output() valueChange = new EventEmitter<any>();
    @Output() errors = new EventEmitter<boolean>();

    formControl = new FormControl();
    countrySelectorOptions: CountrySelectOptions;
    userSelectorPredefinedItems = new BehaviorSubject<string[]>([]);
    userSelectorOptions: BehaviorSubject<TeamUserSelectorOptions>;
    teamSelectorOptions: BehaviorSubject<TeamUserSelectorOptions>;
    teamSelectorPredefinedItems = new BehaviorSubject<string[]>([]);
    activitySelectorOptions: any;

    activityValue: any;
    placeholder: any;

    defaultPlaceholder = {
        date: 'Choose date',
        daterange: 'Choose date range',
        datetimerange: 'Choose date and time range',
        datetime: 'Choose date and time',
        time: 'Choose time',
        timerange: 'Choose time range',
        users: 'Select users',
        teams: 'Select teams',
        country: 'Search countries',
        activitylink: 'Search activities',
    };

    private overrideValue: any = null;
    private onDestroy = new Subject<void>();

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        public viewContext: V3ActivityViewContextService,
        private cdr: ChangeDetectorRef,
        public fileService: FilesService
    ) {}

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static redrawCount = 0;
    redraw() {
        V3ActivityInputFieldComponent.redrawCount += 1;
        // Console.log('V3ActivityInputFieldComponent view redrawed: ' + V3ActivityInputFieldComponent.redrawCount + ' times');
    }

    ngOnInit(): void {
        this.formControl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe({
            next: value => {
                const noValue = !value && value !== 0 && value !== false;
                if (noValue || !this.field) {
                    void this.valueChange.emit(null);
                    return;
                }

                switch (this.field.subtype) {
                    case 'time':
                    case 'datetime': {
                        const date = Date.parse(value);
                        this.valueChange.emit(date ? date : null);
                        break;
                    }
                    case 'date': {
                        const utcDate = this.dateToUTCTimestamp(new Date(value));
                        this.valueChange.emit(utcDate ? utcDate : null);
                        break;
                    }
                    case 'daterange': {
                        const utcStart = this.dateToUTCTimestamp(new Date(value[0]));
                        const utcEnd = this.dateToUTCTimestamp(new Date(value[1]));
                        this.formControl.setErrors(!utcStart || !utcEnd ? { invalidDateRange: true } : null);

                        this.valueChange.emit({ start: utcStart, end: utcEnd });
                        break;
                    }
                    case 'timerange':
                    case 'datetimerange': {
                        const start = Date.parse(value[0]);
                        const end = Date.parse(value[1]);
                        this.formControl.setErrors(!start || !end ? { invalidDateRange: true } : null);

                        this.valueChange.emit({ start, end });
                        break;
                    }
                    case 'activitylink':
                        this.valueChange.emit(typeof value === 'string' ? value : value._id);
                        break;
                    case 'text':
                        if (this.field.id === 'nameField' && this.formControl.errors?.duplicate) {
                            this.formControl.setErrors(null);
                            this.formControl.updateValueAndValidity();
                        }
                        this.valueChange.emit(value);
                        break;
                    case 'textarea':
                        this.resizeTextArea(this.focusInput.nativeElement);
                        this.valueChange.emit(value);
                        break;
                    default:
                        this.valueChange.emit(value);
                        break;
                }
            },
        });

        let lastFormInvalidState = this.formControl.invalid;
        this.formControl.statusChanges
            .pipe(
                takeUntil(this.onDestroy),
                filter(() => this.formControl.invalid !== lastFormInvalidState)
            )
            .subscribe({
                next: () => {
                    lastFormInvalidState = this.formControl.invalid;
                    this.errors.emit(lastFormInvalidState);
                },
            });

        void this.getInfo();

        this.viewContext.template
            .pipe(
                takeUntil(this.onDestroy),
                filter(template => !!template && !!this.field)
            )
            .subscribe({
                next: template => {
                    const updatedField = template?.fields.find(({ id }) => id === this.field?.id);
                    this.field = updatedField || this.field;
                    void this.getInfo();
                },
            });

        if (this.field?.functionEnabled) {
            this.viewContext.fieldOverrides
                .pipe(
                    takeUntil(this.onDestroy),
                    filter(
                        overrides =>
                            !!this.field && this.viewContext.valueChanged(overrides[this.field.id], this.overrideValue, this.field.id)
                    )
                )
                .subscribe({
                    next: async overrides => {
                        if (!this.field) {
                            return;
                        }

                        this.overrideValue = overrides[this.field.id];
                        this.formControl.updateValueAndValidity({ emitEvent: false });
                        this.setOptions();
                        const placeholder = await this.getPlaceholder();
                        // Placeholder does not display 0 as a number as it evaluates to falsy
                        this.placeholder = placeholder === 0 ? placeholder.toString() : placeholder;
                        this.cdr.detectChanges();
                    },
                });
        }

        if (this.field?.modifier?.file && this.field?.required) {
            this.formControl.setValidators([Validators.required]);
            this.formControl.markAsTouched();
            this.formControl.markAsDirty();
            this.formControl.updateValueAndValidity();
        }

        this.viewContext.errorsInSidenav
            .pipe(
                takeUntil(this.onDestroy),
                filter(errors => this.field?.id === 'nameField' && !!errors.duplicate)
            )
            .subscribe({
                next: () => {
                    this.formControl.setErrors({ duplicate: true });
                },
            });
    }

    setFileToBeRemoved(fileId: string) {
        if (!this.viewContext.toSave.options.files) {
            this.viewContext.toSave.options.files = {};
        }
        const fileIds = JSON.parse(this.formControl.value as string);
        const filteredIds = fileIds.filter(id => id !== fileId);
        this.formControl.setValue(filteredIds.length ? JSON.stringify(filteredIds) : null);
        this.cdr.detectChanges();
        if (this.fileToBeRemoved(fileId)) {
            delete this.viewContext.toSave.options.files[fileId];
            if (!Object.keys(this.viewContext.toSave.options.files || {}).length) {
                delete this.viewContext.toSave.options.files;
            }
            this.cdr.detectChanges();
            return;
        }

        this.viewContext.toSave.options.files[fileId] = null;

        if (!this.viewContext.editingFiles.value) {
            this.viewContext.editingFiles.next(true);
        }

        this.cdr.detectChanges();
    }

    fileToBeRemoved(fileId: string): boolean {
        return this.viewContext.toSave?.options?.files?.[fileId] === null;
    }

    ngAfterViewInit(): void {
        if (this.viewContext.loadingInfo.value) {
            this.viewContext.loadingInfo
                .pipe(
                    takeUntil(this.onDestroy),
                    filter(state => !state),
                    take(1)
                )
                .subscribe({
                    next: () => this.focusOnField(),
                });

            return;
        }

        this.focusOnField();

        this.uploader?.fileIds.pipe(takeUntil(this.onDestroy)).subscribe({
            next: fileIds => {
                if (this.viewContext.action === 'create') {
                    if (!fileIds.length) {
                        return delete this.viewContext.toSave.activity.fileIds;
                    }
                    this.formControl.setValue(fileIds.length ? JSON.stringify(fileIds) : null);
                    return (this.viewContext.toSave.activity.fileIds = fileIds);
                }
                const currentFileIds = this.activityValue ? JSON.parse(this.activityValue) : [];
                currentFileIds.push(...fileIds);
                this.formControl.setValue(currentFileIds.length ? JSON.stringify(currentFileIds) : null);

                if (!fileIds.length) {
                    return delete this.viewContext.toSave.options.files;
                }

                const filesMap = fileIds.reduce((map, userId) => {
                    map[userId] = true;
                    return map;
                }, {});

                this.viewContext.toSave.options.files = filesMap;
                if (!this.viewContext.editingFiles.value) {
                    this.viewContext.editingFiles.next(!!fileIds?.length);
                }
            }
        });

    }

    ngOnDestroy(): void {
        this.errors.emit(false);
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    cypressIdentifier(): string {
        const identifier = this.field.id === 'nameField' ? this.field.id : this.field.label;
        return `input-field-${identifier}`;
    }

    focusOnField(): void {
        if (!this.isFieldFocused) {
            return;
        }

        if (this.focusInput?.nativeElement) {
            this.focusInput.nativeElement.click();
            return;
        }

        if (this.optionSelect) {
            void this.optionSelect.open();
            return;
        }
    }

    convertBooleanToNumber(event: MatCheckboxChange): void {
        if (event.checked) {
            this.formControl.setValue(1);
        } else {
            this.formControl.setValue(0);
        }
    }

    get fileIds(): string[] {
        if (typeof this.formControl.value !== 'string') {
            return [];
        }
        return JSON.parse(this.formControl.value as string);
    }

    selectCountry(value: Country | Country[]): void {
        const code = Array.isArray(value) ? value[0]?.code : value.code;
        this.formControl.setValue(code);
    }

    selectUser(change: DropdownSelectedItems): void {
        const selectedUser = change?.user ? change.user[0] : null;
        this.formControl.setValue(selectedUser);
    }

    selectTeam(change: DropdownSelectedItems): void {
        const selectedTeam = change?.team ? change.team[0] : null;
        this.formControl.setValue(selectedTeam);
    }

    clearValue(): void {
        this.formControl.reset();
        this.formControl.markAsTouched();
        this.formControl.markAsDirty();
        this.formControl.updateValueAndValidity();
    }

    setLinkedActivity(event: ActivityLink | ActivityLinkField | ActivityLinkField[], mode: 'created' | 'selected'): void {
        if (mode === 'created') {
            this.activitySelectorOptions = undefined;
            this.cdr.detectChanges();
            this.setActivitySelectorOptions(event);
        }

        this.formControl.setValue(event);
    }

    get isNetworkGuest(): boolean {
        return this.viewContext.isWorkspaceGuest;
    }

    async getPlaceholder(): Promise<number | string> {
        if (!this.field) {
            return '';
        }

        if (this.overrideValue != null) {
            const value = this.overrideValue;
            switch (this.field.subtype) {
                case 'date':
                    return formatDate(value, 'd MMM y', this.locale);
                case 'daterange':
                    return `${formatDate(value.start, 'd MMM y', this.locale)}\n${formatDate(value.end, 'd MMM y', this.locale)}`;
                case 'time':
                    return formatDate(value, 'HH:mm', this.locale);
                case 'timerange':
                    return `${formatDate(value.start, 'HH:mm', this.locale)}\n${formatDate(value.end, 'HH:mm', this.locale)}`;
                case 'datetimerange':
                    return `${formatDate(value.start, 'd MMM y, HH:mm', this.locale)}\n${formatDate(
                        value.end,
                        'd MMM y, HH:mm',
                        this.locale
                    )}`;
                case 'datetime':
                    return formatDate(value, 'd MMM y, HH:mm', this.locale);
                case 'users':
                    return this.viewContext.getUserNameAsync(value);
                default:
                    return value;
            }
        }

        const defaultPlaceholder = this.field?.type ? this.defaultPlaceholder[this.field.type] : undefined;
        return this.field?.placeholder || defaultPlaceholder || '';
    }

    getActivityValue(): ActivityFieldValue | undefined {
        if (this.fieldValue) {
            return this.fieldValue;
        }

        if (!this.field) {
            return undefined;
        }

        const activity = this.viewContext.activity.value;

        switch (this.field.id) {
            case 'ownerTeam':
                return activity?.team_account?.team;
            case 'ownerUser':
                return activity?.uid;
            case 'createdDate':
                return activity?.created;
            case 'nameField':
                return activity?.name;
            default:
                return activity?.fields?.[this.field.id]?.value;
        }
    }

    get isFieldFocused(): boolean {
        if (!this.viewContext.focusOnField || !this.field) {
            return false;
        }

        return this.viewContext.focusOnField === this.field.id;
    }

    get onMobile(): boolean {
        return window.innerWidth < 600;
    }

    get datePickerMode(): 'dialog' | 'popup' {
        return this.onMobile ? 'dialog' : 'popup';
    }

    get hasActivityValue(): boolean {
        return !!this.activityValue || this.activityValue === 0 || this.activityValue === false;
    }

    private async getInfo(): Promise<void> {
        this.activityValue = this.getActivityValue();
        this.placeholder = await this.getPlaceholder();

        if (!this.field || (this.field.subtype && this.viewContext.isStaticField(this.field.subtype))) {
            return;
        }

        this.setValidators();

        const initFieldValue = this.viewContext.initFieldValues?.[this.field.id]?.value;

        if (!this.formControl.dirty) {
            if (this.hasActivityValue || initFieldValue) {
                this.setFieldValue(this.hasActivityValue ? this.activityValue : initFieldValue);
            } else {
                this.setDefaultToValues();
            }
        }

        this.setOptions();
        this.cdr.detectChanges();
    }

    private setOptions(): void {
        if (!this.field) {
            return;
        }

        switch (this.field.subtype) {
            case 'users':
                this.setUserSelectorOptions();
                break;
            case 'teams':
                this.setTeamSelectorOptions();
                break;
            case 'country':
                this.setCountrySelectorOptions();
                break;
            case 'activitylink':
                this.setActivitySelectorOptions();
                break;
            default:
                break;
        }
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private dateToUTCTimestamp(date: Date): number | null {
        if (!date) {
            return null;
        }

        return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    }

    private customRequiredValidator(getOverride: () => any): ValidatorFn {
        if (!this.field?.functionEnabled) {
            return Validators.required;
        }
        return (formControl: AbstractControl): ValidationErrors | null => {
            const hasValue = formControl.value != null ? formControl.value : getOverride();
            return hasValue != null ? null : { requiredCustom: { value: true } };
        };
    }

    private setValidators() {
        if (this.viewContext.action === 'editMultiple' && !this.isNextPhaseField) {
            // Dont want validators if editing multiple
            return;
        }

        if (!this.field) {
            return;
        }

        const fieldsGuestCantEdit: ProcessFieldType[] = ['activitylink', 'users', 'teams'];
        const guestCantEdit = this.viewContext.isWorkspaceGuest && this.field.subtype && fieldsGuestCantEdit.includes(this.field.subtype);

        if (this.field.required && !guestCantEdit) {
            // Set field as required
            this.formControl.setValidators([this.customRequiredValidator(() => !this.viewContext.editing.value || this.overrideValue)]);
        } else if (this.formControl.hasValidator(Validators.required)) {
            // Remove required validator if not required by process anymore
            this.formControl.removeValidators(Validators.required);
        }

        this.formControl.markAsTouched();
        this.formControl.updateValueAndValidity();
    }

    private setFieldValue(value: any): void {
        if (!this.field) {
            return;
        }

        switch (this.field.subtype) {
            case 'users':
                this.userSelectorPredefinedItems.next([value]);
                break;
            case 'teams':
                this.teamSelectorPredefinedItems.next([value]);
                break;
            case 'time':
            case 'datetime': {
                this.formControl.setValue(new Date(value));
                break;
            }
            case 'date': {
                const date = new Date(value);
                // Remove the timezone offset
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                this.formControl.setValue(date);
                break;
            }
            case 'daterange': {
                const start = new Date(value.start);
                const end = new Date(value.end);
                // Remove the timezone offset
                start.setMinutes(start.getMinutes() + start.getTimezoneOffset());
                end.setMinutes(end.getMinutes() + end.getTimezoneOffset());
                this.formControl.setValue([start, end]);
                break;
            }
            case 'timerange':
            case 'datetimerange': {
                this.formControl.setValue([new Date(value.start), new Date(value.end)]);
                break;
            }
            case 'activitylink':
                this.formControl.setValue(value._id);
                break;
            case 'country':
                this.formControl.setValue(value.code ? value.code : value);
                break;
            default:
                this.formControl.setValue(value);
                break;
        }
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private convertTimestampToUtcISOString(timestamp: number): string {
        const utcDateString = moment.utc(timestamp).format('YYYY-MM-DD');
        return moment(`${utcDateString}T00:00:00.000`).toISOString();
    }

    private setDefaultToValues(): void {
        if (!this.field) {
            return;
        }

        if ((!this.field.defaultTo && !this.field.modifier?.checkbox) || this.formControl.value || this.viewContext.action === 'editMultiple') {
            return;
        }

        const defaultValue = this.field.defaultValue || null;

        const now = Date.now();
        const day = 1000 * 60 * 60 * 24;
        const hour = 1000 * 60 * 60;

        switch (this.field.subtype) {
            case 'textpredefinedoptions':
                this.formControl.setValue(defaultValue);
                break;
            case 'time':
            case 'datetime':
            case 'date':
                this.formControl.setValue(new Date(now));
                break;
            case 'daterange':
            case 'datetimerange':
                this.formControl.setValue([new Date(now), new Date(now + day)]);
                break;
            case 'timerange':
                this.formControl.setValue([new Date(now), new Date(now + hour)]);
                break;
            case 'users':
                this.userSelectorPredefinedItems.next([this.viewContext.me._id]);
                break;
            case 'numeric':
                if (this.field.modifier?.checkbox) {
                    this.formControl.setValue(0);
                }
                break;
            default:
                break;
        }
    }

    private setUserSelectorOptions(): void {
        if (!this.field) {
            return;
        }

        const required = this.viewContext.action !== 'editMultiple' ? this.field.required : this.isNextPhaseField && this.field.required;
        const currentOptions = this.userSelectorOptions?.value || new TeamUserSelectorOptions();

        currentOptions.appearance = 'fill';
        currentOptions.multiple = false;
        currentOptions.placeholder = this.placeholder;
        currentOptions.showUsers = this.viewContext.workflow.value?.cid;
        currentOptions.required = !!required;
        currentOptions.closeOnSelection = true;
        currentOptions.selectLastItem = !!required;
        currentOptions.hideClearButton = !!required && !this.field.functionEnabled;

        if (required && this.field.functionEnabled) {
            currentOptions.customValidator = this.customRequiredValidator(() => this.overrideValue);
        }

        this.userSelectorOptions = new BehaviorSubject(currentOptions);
    }

    private setTeamSelectorOptions(): void {
        if (!this.field) {
            return;
        }

        const required = this.viewContext.action !== 'editMultiple' ? this.field.required : this.isNextPhaseField && this.field.required;
        const currentOptions = this.teamSelectorOptions?.value || new TeamUserSelectorOptions();

        currentOptions.appearance = 'fill';
        currentOptions.multiple = false;
        currentOptions.placeholder = this.placeholder;
        currentOptions.showTeams = this.field.id === 'ownerTeam' ? this.viewContext.me._id : 'all';
        currentOptions.required = !!required;
        currentOptions.closeOnSelection = true;
        currentOptions.selectLastItem = !!required;
        currentOptions.hideClearButton = !!required && !this.field.functionEnabled;
        currentOptions.workspaceId = this.viewContext.workflow.value?.cid;

        if (required && this.field.functionEnabled) {
            currentOptions.customValidator = this.customRequiredValidator(() => this.overrideValue);
        }

        this.teamSelectorOptions = new BehaviorSubject(currentOptions);
    }

    private setActivitySelectorOptions(initValue?: ActivityLink | ActivityLinkField | ActivityLinkField[]): void {
        if (!this.field) {
            return;
        }

        const required = this.viewContext.action !== 'editMultiple' ? this.field.required : this.isNextPhaseField && this.field.required;
        if (!initValue) {
            initValue = this.hasActivityValue ? this.activityValue : this.viewContext.initFieldValues?.[this.field.id]?.value || undefined;
        }

        this.activitySelectorOptions = {
            filterByProcess: this.field.data,
            noEntriesFoundLabel: 'No matches!',
            placeholderLabel: this.placeholder,
            required: required || false,
            reverse: true,
            sortField: 'name',
            fieldName: ' ',
            initValue,
            multiple: false,
            showResetButton: true,
        };
    }

    private setCountrySelectorOptions(): void {
        if (!this.field) {
            return;
        }

        const required = this.viewContext.action !== 'editMultiple' ? this.field.required : this.isNextPhaseField && this.field.required;

        const initFieldValue = this.viewContext.initFieldValues?.[this.field.id]?.value;
        this.countrySelectorOptions = {
            noEntriesFoundLabel: 'No countries found!',
            placeholderLabel: this.placeholder,
            required: required || false,
            fieldName: ' ',
            initValue: this.hasActivityValue ? this.activityValue : initFieldValue || this.formControl.value || '',
            showResetButton: true,
        };
    }

    private resizeTextArea(element: HTMLElement): void {
        element.style.height = 'auto';
        element.style.height = `${element.scrollHeight}px`;
    }
}
