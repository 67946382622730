<!-- @format -->

<ng-container *ngIf="screenWidth >= 600; else mobileNav">
	<mat-nav-list class="sidenav-list">
		<div class="notification-wrapper closed">
			<img crossorigin [style.display]="'none'" draggable="false" [src]="'assets/img/hailerlogo3_white.svg'" />

			<!-- Notifications button -->
			<button
				data-cy="notifications-center-button"
				class="no-button notifications-button"
				[matMenuTriggerFor]="notificationsMenu"
				#menuTrigger="matMenuTrigger"
				tippy="{{ 'shared.side-nav.notifications' | transloco }}">
				<app-icon-with-badge
					[ngClass]="
						(verifyEmailUnreadCount === 0 ? (notificationUnreadCount | async) : (notificationUnreadCount | async) + 1) > 0
							? 'white'
							: 'grey-low'
					"
					[badgeNumber]="verifyEmailUnreadCount === 0 ? (notificationUnreadCount | async) : (notificationUnreadCount | async) + 1"
					[hailerIconName]="
						(verifyEmailUnreadCount === 0 ? (notificationUnreadCount | async) : (notificationUnreadCount | async) + 1) > 0
							? 'hailer-notification-fill'
							: 'hailer-notification'
					">
				</app-icon-with-badge>
			</button>
			<mat-menu
				#notificationsMenu="matMenu"
				[overlapTrigger]="false"
				xPosition="after"
				class="override-mat-menu"
				(closed)="markAllNotificationsSeen()">
				<ng-template matMenuContent>
					<mat-list>
						<div data-cy="notification-center-header" class="title-item" (click)="$event.stopPropagation()">
							<h4>{{ 'shared.side-nav.notifications' | transloco }}</h4>
							<div
								*ngIf="verifyEmailUnreadCount === 0"
								matBadge="{{ notificationUnreadCount | async }}"
								matBadgeColor="warn"
								class="notification-menu__badge"></div>
							<div
								*ngIf="verifyEmailUnreadCount === 1"
								matBadge="{{ (notificationUnreadCount | async) + 1 }}"
								matBadgeColor="warn"
								class="notification-menu__badge"></div>
							<button
								class="mark-all-as-read-button"
								color="accent"
								mat-icon-button
								(click)="markAllNotificationsRead(); menuTrigger.closeMenu()"
								tippy="{{ 'shared.side-nav.mark_all_as_read' | transloco }}">
								<mat-icon svgIcon="hailer-tick"></mat-icon>
							</button>
						</div>
						<div #notificationContainer (scroll)="onScroll()" class="notification-container">
							<app-notification
								#notificationComponent
								*ngIf="!(user | async)?.emailVerified"
								[notification]="verifyEmailNotification"></app-notification>
							<app-notification
                                data-cy="notification-container"
								#notificationComponent
								*ngFor="let notification of notifications | async"
								[notification]="notification"></app-notification>
							<div class="flexbox-row--center-centered">
								<button
									class="no-button load-more"
									[attr.aria-label]="'shared.side-nav.load-more-tip' | transloco"
									tippy="{{ 'shared.side-nav.load-more-tip' | transloco }}"
									(click)="$event.stopPropagation(); loadMoreNotifications()">
									{{ 'shared.side-nav.load-more' | transloco }}
								</button>
							</div>
						</div>
					</mat-list>
				</ng-template>
			</mat-menu>
		</div>

		<mat-divider
			style="width: calc(100% - 24px); margin-left: 12px; border-color: #ffffff1c; margin-bottom: 8px"
			class="hide-on-mobile"></mat-divider>

		<!-- Discussion -->
		<mat-list-item
			data-cy="discussion-navigation-button"
			[routerLink]="['/discussions']"
			routerLinkActive="active-link">
			<app-icon-with-badge
				[badgeNumber]="unreadDiscussionsCount"
				hailerIconName="hailer-discussion"
				[style.margin-top]="'-8px'"></app-icon-with-badge>
			<span class="item-name-desktop">{{ 'shared.side-nav.discussions' | transloco }}</span>
			<h4 class="visible-text hidden-text">
				{{ 'shared.side-nav.discussions_sidebar_expanded' | transloco }}
			</h4>
		</mat-list-item>

		<!-- Feed -->
		<div>
			<mat-list-item
				data-cy="feed-navigation-button"
				[disabled]="!(core.permission.feed.load(currentNetworkId) | hasPermission)"
				[routerLink]="['/feed']"
				routerLinkActive="active-link">
				<app-icon-with-badge
					[badgeNumber]="totalUnread > 0 && !inFeed ? totalUnread : null"
					hailerIconName="hailer-feed"
					[style.margin-top]="'-8px'"></app-icon-with-badge>
				<span class="item-name-desktop">{{ 'shared.side-nav.feed' | transloco }}</span>
				<h4 class="visible-text hidden-text">
					{{ 'shared.side-nav.feed_sidebar_expanded' | transloco }}
				</h4>
			</mat-list-item>
		</div>

		<!-- Events -->
		<div [tippy]="guestUserTooltip" [isEnabled]="permissions.isNetworkGuest">
			<mat-list-item
				data-cy="calendar-navigation-button"
				[disabled]="permissions.isNetworkGuest"
				[routerLink]="['/events']"
				routerLinkActive="active-link">
				<div style="height: 24px">
					<mat-icon [style.margin-top]="'-8px'" svgIcon="hailer-calendar"></mat-icon>
				</div>
				<span class="item-name-desktop">{{ 'shared.side-nav.calendar' | transloco }}</span>
				<h4 class="visible-text hidden-text">
					{{ 'shared.side-nav.calendar_sidebar_expanded' | transloco }}
				</h4>
			</mat-list-item>
		</div>

		<!-- Activities -->
		<div [tippy]="guestUserTooltip" [isEnabled]="permissions.isNetworkGuest">
			<mat-list-item
				data-cy="activities-navigation-button"
				[disabled]="permissions.isNetworkGuest"
				[routerLink]="['/activities']"
				routerLinkActive="active-link">
				<div style="height: 24px">
					<mat-icon svgIcon="hailer-activities" [style.margin-top]="'-8px'"></mat-icon>
				</div>
				<span class="item-name-desktop">{{ 'shared.side-nav.activities' | transloco }}</span>
				<h4 class="visible-text hidden-text">
					{{ 'shared.side-nav.activities_sidebar_expanded' | transloco }}
				</h4>
			</mat-list-item>
		</div>

		<!-- Apps -->
		<div *ngIf="this.inAppsBeta()" [tippy]="guestUserTooltip" [isEnabled]="permissions.isNetworkGuest">
			<mat-list-item
                data-cy="apps-navigation-button"
				[disabled]="permissions.isNetworkGuest"
                [matMenuTriggerFor]="appsMenu"
                [ngClass]="{ 'mat-focus-indicator active-link': inApps() }"
			>
				<div style="height: 24px">
					<mat-icon
						[style.margin-top]="'-8px'"
						class="figma-icons-line-white"
						svgIcon="hailer-apps-plus"></mat-icon>
				</div>
				<span class="item-name-desktop">{{
					'shared.side-nav.apps-insights' | transloco
				}}</span>
				<h4 class="visible-text hidden-text">{{ 'shared.side-nav.apps-insights' | transloco }}</h4>
			</mat-list-item>

            <!-- Apps menu -->
            <mat-menu #appsMenu="matMenu" [overlapTrigger]="false" xPosition="after" matMenuContent>
                <button mat-menu-item disabled>
                    <span class="other-networks-text">{{ 'shared.side-nav.app_workspace_apps' | transloco }}</span>
                </button>

                <div *ngFor="let app of appService.getWorkspaceApps() | async">
                    <button data-cy="app-list-item" class="network-button" mat-menu-item (click)="appService.routeToApp(app)">
                        <img
                            class="image"
                            width="32px"
                            height="32px"
                            style="border-radius: 8px; margin-right: 8px;"
                            src="{{ app.image ? baseUrl + '/' + app.image : appDefaultImage }}"
                            alt="app image"
                        />
                        <div class="network-container flex">
                            <span class="network-name one-line-ellipsis">{{ app.name }}</span>
                        </div>
                    </button>
                </div>

                <button mat-menu-item disabled *ngIf="userApps.length">
                    <span class="other-networks-text">{{ 'shared.side-nav.app_user_apps' | transloco }}</span>
                </button>

                <div *ngFor="let app of userApps;">
                    <button data-cy="app-list-item" class="network-button" mat-menu-item (click)="appService.routeToApp(app)">
                        <img
                            class="image"
                            width="32px"
                            height="32px"
                            style="border-radius: 8px; margin-right: 8px;"
                            src="{{ app.image ? baseUrl + '/' + app.image : appDefaultImage }}"
                            alt="app image"
                        />
                        <div class="network-container flex">
                            <span class="network-name one-line-ellipsis">{{ app.name }}</span>
                        </div>
                    </button>
                </div>

                <button data-cy="apps-menu-create-button" *ngIf="permissions.isNetworkAdmin" mat-menu-item (click)="appService.routeToAppManager()">
                    <mat-icon class="success" svgIcon="hailer-settings"></mat-icon>
                    <span class="create-button-text">{{ 'shared.side-nav.app_open_manager' | transloco }}</span>
                </button>
            </mat-menu>

		</div>

		<!-- People -->
		<div [tippy]="guestUserTooltip" [isEnabled]="permissions.isNetworkGuest">
			<mat-list-item
				data-cy="people-navigation-button"
				[disabled]="permissions.isNetworkGuest"
				[routerLink]="['/people']"
				routerLinkActive="active-link">
				<div style="height: 24px">
					<mat-icon svgIcon="hailer-team" [style.margin-top]="'-8px'"></mat-icon>
				</div>
				<span class="item-name-desktop">{{ 'shared.side-nav.people' | transloco }}</span>
				<h4 class="visible-text hidden-text">
					{{ 'shared.side-nav.people_sidebar_expanded' | transloco }}
				</h4>
			</mat-list-item>
		</div>

		<!-- Workspace settings -->
		<div [tippy]="guestUserTooltip" [isEnabled]="permissions.isNetworkGuest">
			<mat-list-item
				data-cy="settings-navigation-button"
				[disabled]="permissions.isNetworkGuest"
				*ngIf="permissions.isNetworkInviter"
				[routerLink]="['/settings']"
				routerLinkActive="active-link">
				<div style="height: 24px">
					<mat-icon [style.margin-top]="'-8px'" svgIcon="hailer-settings"></mat-icon>
				</div>
				<span class="item-name-desktop">{{ 'shared.side-nav.settings' | transloco }}</span>
				<h4 class="visible-text hidden-text">
					{{ 'shared.side-nav.settings_sidebar_expanded' | transloco }}
				</h4>
			</mat-list-item>
		</div>

		<!-- Development -->
		<mat-list-item *ngIf="debugModeEnabled" [routerLink]="['/development']" routerLinkActive="active-link">
			<div style="height: 24px">
				<mat-icon [style.margin-top]="'-8px'" svgIcon="hailer-dev"></mat-icon>
			</div>
			<span class="item-name-desktop">{{ 'shared.side-nav.development' | transloco }}</span>
			<h4 class="visible-text hidden-text">
				{{ 'shared.side-nav.development_sidebar_expanded' | transloco }}
			</h4>
		</mat-list-item>
	</mat-nav-list>
	<div class="flexbox-column" style="padding-bottom: 16px; padding-left: 16px; width: 40px">
		<!-- Network button -->
		<button
			data-cy="workspaces-list-button"
			*ngIf="network"
			[matMenuTriggerFor]="networkMenu"
			tippy="{{ network.name }}"
			class="no-button flex">
			<div matListAvatar class="network-icon" [style.background-color]="stylingHelperService.getNetworkBackgroundColor(network)">
				<p [style.color]="stylingHelperService.getNetworkTextColor(network)">{{ networksLogo }}</p>
			</div>
		</button>

		<!-- Network menu -->
		<mat-menu #networkMenu="matMenu" [overlapTrigger]="false" xPosition="after" matMenuContent>
			<button mat-menu-item disabled *ngIf="(networks | async)?.length > 1">
				<span class="other-networks-text">{{ 'shared.side-nav.network_my_workspaces' | transloco }}</span>
			</button>
			<div *ngFor="let network of networks | async">
				<button data-cy="workspaces-list-item" class="network-button" mat-menu-item (click)="core.switchNetwork(network._id)">
					<div class="current-network-container" data-cy="current-network-container" *ngIf="currentNetworkId === network._id">
						<span data-cy="current-network-name" class="current-network-name network-name one-line-ellipsis bold">{{ network.name }}</span>
						<span data-cy="current-network-indicator" class="current-network-indicator enabled-bubble"></span>
					</div>

					<div *ngIf="currentNetworkId !== network._id" class="network-container flex">
						<span class="network-name one-line-ellipsis">{{ network.name }}</span>
					</div>
				</button>
			</div>
			<button data-cy="workspaces-menu-create-button" *ngIf="!isManagedUser" mat-menu-item (click)="newNetwork()">
				<mat-icon class="success" svgIcon="hailer-plus"></mat-icon>
				<span class="create-button-text">{{ 'shared.side-nav.network_create_workspace' | transloco }}</span>
			</button>
		</mat-menu>

		<!-- Profile button -->
		<button
			data-cy="user-profile-menu-button"
			mat-icon-button
			*ngIf="user | async as Me"
			[matMenuTriggerFor]="profileMenu"
			class="hover-lighten"
			tippy="{{ Me?.display_name }}"
			style="margin: 4px 0; line-height: 0">
			<app-user-profile-picture matListAvatar [userId]="Me._id" [size]="32"></app-user-profile-picture>
		</button>

		<!-- Profile menu -->
		<mat-menu #profileMenu="matMenu" [overlapTrigger]="false" xPosition="after">
			<button data-cy="profile-editor-dialog-button" mat-menu-item (click)="showMyProfile()">
				<mat-icon svgIcon="hailer-edit"></mat-icon>
				<span>{{ 'shared.side-nav.profile_edit_profile' | transloco }}</span>
			</button>

			<button
				data-cy="change-theme-button"
				mat-menu-item
				#trigger="matMenuTrigger"
				[matMenuTriggerFor]="themeMenu"
				(mouseenter)="trigger.openMenu()">
				<mat-icon svgIcon="hailer-change-theme"></mat-icon>
				<span>{{ 'shared.side-nav.profile_change_theme' | transloco }}</span>
			</button>
			<button data-cy="profile-menu-logout-button" mat-menu-item (click)="logout()">
				<mat-icon svgIcon="hailer-sign-out"></mat-icon>
				<span>{{ 'shared.side-nav.profile_sign_out' | transloco }}</span>
			</button>
		</mat-menu>

		<!-- Theme menu -->
		<mat-menu #themeMenu="matMenu" [overlapTrigger]="false" xPosition="after">
			<mat-radio-group (change)="onThemeChange($event)" style="display: flex; flex-direction: column">
				<mat-radio-button [checked]="selectedTheme() === 'light'" value="light" class="mat-menu-item">{{
					'shared.side-nav.theme_light' | transloco
				}}</mat-radio-button>
				<mat-radio-button [checked]="selectedTheme() === 'dark'" value="dark" class="mat-menu-item">{{
					'shared.side-nav.theme_dark' | transloco
				}}</mat-radio-button>
			</mat-radio-group>
		</mat-menu>

		<!-- Global search -->
		<app-global-search
			dataCy="global-search-button-sidenav"
			[modalMode]="true"
			[ngClass]="permissions.isNetworkGuest ? '' : 'hover-lighten'"
			class="hide-on-mobile">
		</app-global-search>

		<!-- Usetiful/ Onboarding layer button -->
		<button
			data-cy="start-tutorial-button"
			onboarding="onboarding"
			class="hover-lighten hide-on-mobile no-button sidenav-icon-button"
			tippy="{{ 'shared.side-nav.tutorial-start' | transloco }}"
			[attr.aria-label]="'shared.side-nav.tutorial-start' | transloco">
			<mat-icon class="toolbar-icon accent" svgIcon="hailer-help"></mat-icon>
		</button>
	</div>
</ng-container>

<!-- Mobile view -->
<ng-template #mobileNav>
	<div
		class="filters-toolbar"
		[style.paddingBottom.px]="platform.IOS ? '16' : '0'"
		[class.filters-toolbar-contextual-add-open]="showContextualAdd">
		<!-- Activities -->
		<button
			[disabled]="permissions.isNetworkGuest"
			mat-flat-button
			[routerLink]="['/activities']"
			routerLinkActive="mobile-active-link"
			(click)="vibrate()">
			<mat-icon svgIcon="hailer-activities"></mat-icon>
		</button>

		<!-- Feed -->
		<button
			[disabled]="!(core.permission.feed.load(network._id) | hasPermission)"
			mat-flat-button
			[routerLink]="['/feed']"
			routerLinkActive="mobile-active-link"
			(click)="contextualAdd('feed')">
			<mat-icon svgIcon="hailer-logo" class="nav-icon"></mat-icon>
			<mat-icon color="accent" class="add-icon" svgIcon="hailer-plus"></mat-icon>
		</button>

		<!-- Discussions -->
		<button
			mat-flat-button
			[routerLink]="['/discussions']"
			[routerLinkActive]="permissions.isNetworkGuest ? '' : 'mobile-active-link'"
			(click)="contextualAdd('discussion')">
			<app-icon-with-badge [badgeNumber]="unreadDiscussionsCount" hailerIconName="hailer-messenger" class="nav-icon"> </app-icon-with-badge>
			<mat-icon color="accent" class="add-icon" svgIcon="hailer-plus"></mat-icon>
		</button>

		<!-- Events -->
		<button
			[disabled]="permissions.isNetworkGuest"
			mat-flat-button
			[routerLink]="['/events']"
			routerLinkActive="mobile-active-link"
			(click)="contextualAdd('events')">
			<mat-icon svgIcon="hailer-events" class="nav-icon"></mat-icon>
			<mat-icon color="accent" class="add-icon" svgIcon="hailer-plus"></mat-icon>
		</button>

		<!-- People -->
		<button
			[disabled]="permissions.isNetworkGuest"
			mat-flat-button
			[routerLink]="['/people']"
			routerLinkActive="mobile-active-link"
			(click)="contextualAdd('people')">
			<ng-container *ngIf="permissions.isNetworkInviter; else peopleIcon">
				<mat-icon svgIcon="hailer-team" class="nav-icon"></mat-icon>
				<mat-icon color="accent" class="add-icon" svgIcon="hailer-plus"></mat-icon>
			</ng-container>
			<ng-template #peopleIcon>
				<mat-icon svgIcon="hailer-team"></mat-icon>
			</ng-template>
		</button>
	</div>
	<div
		[class.contextual-add-menu-hidden]="!showContextualAdd"
		class="contextual-add-menu"
		[style.paddingBottom.px]="platform.IOS ? '16' : '0'">
		<mat-action-list *ngIf="showContextualAdd === 'feed'">
			<button mat-list-item (click)="createPost()">
				<mat-icon svgIcon="hailer-plus"></mat-icon>
				<span style="margin-top: -16px">{{ 'shared.side-nav.mobile.people.new_post' | transloco }}</span>
				<small>{{ 'shared.side-nav.mobile.people.share_with_workspace' | transloco }}</small>
			</button>
		</mat-action-list>
		<mat-action-list *ngIf="showContextualAdd === 'discussion'">
			<button mat-list-item (click)="createDiscussion('activity')">
				<mat-icon svgIcon="hailer-activity" style="color: #76ffa8"></mat-icon>
				<span style="margin-top: -16px; color: #76ffa8">{{ 'shared.side-nav.mobile.people.new_activity_discussion' | transloco }}</span>
				<small>{{ 'shared.side-nav.mobile.people.link_discussion' | transloco }}</small>
			</button>
			<button data-cy="discussion-create-event" mat-list-item (click)="createDiscussion('event')">
				<mat-icon svgIcon="hailer-calendar" style="color: #ffa28e"></mat-icon>
				<span style="margin-top: -16px; color: #ffa28e">{{ 'shared.side-nav.mobile.people.new_event_discussion' | transloco }}</span>
				<small>{{ 'shared.side-nav.mobile.people.link_discussion_to_event' | transloco }}</small>
			</button>
			<button data-cy="discussion-create-group" mat-list-item (click)="createDiscussion('group')">
				<mat-icon svgIcon="hailer-group" style="color: #77b9ff"></mat-icon>
				<span style="margin-top: -16px; color: #77b9ff">here</span>
				<small>{{ 'shared.side-nav.mobile.people.group_to_chat' | transloco }}</small>
			</button>
			<button data-cy="discussion-create-private" mat-list-item (click)="createDiscussion('private')">
				<mat-icon style="color: #d0d0d0" svgIcon="hailer-user"></mat-icon>
				<span style="margin-top: -16px">{{ 'shared.side-nav.mobile.people.new_private_discussion' | transloco }}</span>
				<small>{{ 'shared.side-nav.mobile.people.private_discussion' | transloco }}</small>
			</button>
		</mat-action-list>
		<mat-action-list *ngIf="showContextualAdd === 'events'">
			<button mat-list-item (click)="createActivityEvent()">
				<mat-icon svgIcon="hailer-activity" style="color: #76ffa8"></mat-icon>
				<span style="margin-top: -16px; color: #76ffa8">{{ 'shared.side-nav.mobile.people.new_activity_event' | transloco }}</span>
				<small>{{ 'shared.side-nav.mobile.people.link_event_to_activity' | transloco }}</small>
			</button>
			<button mat-list-item (click)="createEvent()">
				<mat-icon svgIcon="hailer-calendar" style="color: #ffa28e"></mat-icon>
				<span style="margin-top: -16px; color: #ffa28e">{{ 'shared.side-nav.mobile.people.new_event' | transloco }}</span>
				<small>{{ 'shared.side-nav.mobile.people.single_or_recurring' | transloco }}</small>
			</button>
			<button mat-list-item (click)="createCalendar()">
				<mat-icon svgIcon="hailer-calendar"></mat-icon>
				<span>{{ 'shared.side-nav.mobile.people.create_calendar' | transloco }}</span>
			</button>
		</mat-action-list>
		{{ network.private }}
		<mat-action-list *ngIf="showContextualAdd === 'people'">
			<button mat-list-item (click)="invitePeople()">
				<mat-icon svgIcon="hailer-group"></mat-icon>
				<span style="margin-top: -16px">{{ 'shared.side-nav.mobile.people.invite_people' | transloco }}</span>
				<small>{{ 'shared.side-nav.mobile.people.invite_new_users' | transloco }}</small>
			</button>
		</mat-action-list>
	</div>
</ng-template>
