/** @format */

import { Injectable } from '@angular/core';

import { environment } from '@app/env';

@Injectable({
    providedIn: 'root',
})
export class FinndentService {
    constructor() {}

    /**
     * Send formdata as post to Finndent Configurator
     *
     * @param activityId
     * @param activityName
     * @param userId
     * @param email
     * @param userTeamsIds
     */
    async sendData(activityId: string, activityName: string, userId: string, email: string, userTeamsIds: string[]) {
        const url = environment.finndentUrl;

        const form = document.createElement('form');
        form.target = '_blank';
        form.method = 'POST';
        form.action = url;

        const inputUser = document.createElement('input');
        inputUser.type = 'hidden';
        inputUser.name = 'user_id';
        inputUser.setAttribute('value', userId);
        form.appendChild(inputUser);

        const inputEmail = document.createElement('input');
        inputEmail.type = 'hidden';
        inputEmail.name = 'user_email';
        inputEmail.setAttribute('value', email);
        form.appendChild(inputEmail);

        const inputTeam = document.createElement('input');
        inputTeam.type = 'hidden';
        inputTeam.name = 'team_ids';
        inputTeam.setAttribute('value', userTeamsIds.toString());
        form.appendChild(inputTeam);

        const inputActivity = document.createElement('input');
        inputActivity.type = 'hidden';
        inputActivity.name = 'activity_id';
        inputActivity.setAttribute('value', activityId);
        form.appendChild(inputActivity);

        const inputActivityName = document.createElement('input');
        inputActivityName.type = 'hidden';
        inputActivityName.name = 'activity_name';
        inputActivityName.setAttribute('value', activityName);
        form.appendChild(inputActivityName);

        document.body.appendChild(form);
        form.submit();
    }
}
