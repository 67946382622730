/** @format */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { RPCService } from './rpc.service';
import { MessagesGlobalSearch } from 'app/_models/globalSearch.model';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    constructor(private rpc: RPCService) {}

    globalSearch(query: string, filters: string[], discussionId?: string): Observable<any> {
        return new Observable(observer => {
            this.rpc
                .request('v2.search.global', [{ search: query, sections: filters, discussionId }])
                .pipe(take(1))
                .subscribe({
                    next: results => {
                        observer.next(results);
                        observer.complete();
                    },
                    error: err => observer.error(err),
                });
        });
    }

    async globalSearchAsync(query: string, filters: string[], discussionId?: string): Promise<any> {
        return this.rpc.requestAsync('v2.search.global', [{ search: query, sections: filters, discussionId }]);
    }

    /** Truncates messages to a search keyword and adds "..." to the start/end */
    overflowMessages(messages: MessagesGlobalSearch[], searchTerm: string, peekAmount = 30): MessagesGlobalSearch[] {
        return messages.map(message => {
            message.msg = message.msg.trim();

            const termIndex = message.msg.toLowerCase().indexOf(searchTerm.toLowerCase());
            const minCharIndex = termIndex - peekAmount > 0 ? termIndex - peekAmount : 0;
            const maxCharIndex =
                termIndex + searchTerm.length + peekAmount < message.msg.length
                    ? termIndex + searchTerm.length + peekAmount
                    : message.msg.length;

            const newMessage = message.msg.slice(minCharIndex, maxCharIndex).trim();
            const startOverflow = minCharIndex !== 0 ? '...' : '';
            const endOverflow = maxCharIndex !== message.msg.length ? '...' : '';

            message.msg = startOverflow + newMessage + endOverflow;
            return message;
        });
    }
}
