<!-- @format -->

<div class="login-background" *ngIf="!loginToken">
    <div class="bubbles"></div>
    <div class="language-change-container">
        <button
            class="no-button for-text-and-icon"
            [ngClass]="languageMenuOpen ? 'menu-open' : 'menu-closed'"
            (menuOpened)="languageMenuOpen = true"
            (menuClosed)="languageMenuOpen = false"
            [matMenuTriggerFor]="menu"
            data-cy="language-change-button">
            <span class="language-button-text">{{ selectedLanguage }}</span>
            <mat-icon svgIcon="hailer-chevron"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" aria-selected="selectedLanguage" class="multilanguage-menu">
            <button mat-menu-item (click)="changeLanguage('en')">English</button>
            <button mat-menu-item (click)="changeLanguage('fi')">Suomi</button>
            <button mat-menu-item (click)="changeLanguage('sv')">Svenska</button>
        </mat-menu>
    </div>

    <div class="login-error-container">
        <h1>404</h1>
        <h2>{{ 'public.login-token.page_gone' | transloco }}</h2>
        <p class="ns-text-regular-2">{{ 'public.login-token.link_expired' | transloco }}</p>
        <div class="login-button-container-one-button">
            <button mat-flat-button class="primary-public-pages" [routerLink]="['/login']" data-cy="back-to-login-button">
                <span>{{ 'public.forgot-password-page.button_back_to_login' | transloco }}</span>
            </button>
        </div>
    </div>
</div>
