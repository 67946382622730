/** @format */

import { createReducer, on } from '@ngrx/store';

import { MessageDraftMap } from '@app/models';
import { loadMessageDraftMap, setMessageDraft, setMessageDraftMap } from '../actions/message-draft.actions';

export const initialState: MessageDraftMap = {};

const reducer = createReducer(
    initialState,
    on(loadMessageDraftMap, state => state),
    on(setMessageDraft, (state, data) => {
        const newState = { ...state };
        newState[data.discussionId] = data.messageDraft;
        return newState;
    }),
    on(setMessageDraftMap, (state, data) => data.messageDrafts)
);

export const messageDraftReducer = (state, action) => reducer(state, action);
