/** @format */

import { Pipe, PipeTransform } from '@angular/core';

import { hashtagRegex } from './hashtag.pipe.regex';

@Pipe({
    name: 'hashtag',
})
export class HashtagPipe implements PipeTransform {
    transform(text: any, args?: any): any {
        /* RegexSupplant is not exported in latest version of twitter-text.
           const regex = /(?:^|\s)(#{hashSigns})(?!\uFE0F|\u20E3)(#{hashtagAlphaNumeric}*#{hashtagAlpha}#{hashtagAlphaNumeric}*)/gi;
           var pattern = regexSupplant(regex); */
        return text.replace(hashtagRegex, (match, hash, tag) => {
            return match.replace(hash + tag, `<a href="javascript:void(0)">#${tag}</a>`);
        });
    }
}
