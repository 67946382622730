/** @format */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForgotPasswordPageComponent } from './public/forgot-password-page/forgot-password-page.component';
import { PageNotFoundComponent } from './public/page-not-found/page-not-found.component';
import { LoginPageComponent } from './public/login-page/login-page.component';
import { RegisterPageComponent } from './public/register-page/register-page.component';
import { ValidateEmailResolver } from './_models/validateEmailResolver.model';
import { DevelopmentPageComponent } from './public/development-page/development-page.component';
import { LoginTokenComponent } from './public/login-token/login-token.component';
import { FrontPageGuard } from './_guards/frontpage.guard';
import { AuthGuard } from './_guards/auth.guard';
import { RedirectComponent } from './public/redirect/redirect.component';
import { EntraLoginPageComponent } from './public/entra-login-page/entra-login-page.component';

const routes: Routes = [
    {
        path: '',
        component: LoginPageComponent,
        // DigiOne oidc login is using the FrontPageGuard. See local-server.ts app.get('/auth_callback' for more info.
        canActivate: [FrontPageGuard],
    },
    {
        path: 'entralogin',
        component: EntraLoginPageComponent,
    },
    {
        path: 'entralogin/:clientId/:tenantId',
        component: EntraLoginPageComponent,
    },
    {
        path: 'login',
        component: LoginPageComponent,
    },
    {
        path: 'redirect/:providerId',
        component: RedirectComponent,
    },
    {
        path: 'register',
        component: RegisterPageComponent,
    },
    {
        path: 'validate',
        component: LoginPageComponent,
        resolve: {
            validate: ValidateEmailResolver,
        },
    },
    {
        path: 'unlockaccount',
        component: LoginTokenComponent,
    },
    {
        path: 'forgotpassword',
        component: ForgotPasswordPageComponent,
    },
    {
        path: 'forgotpassword/:ticketId',
        component: ForgotPasswordPageComponent,
    },
    {
        path: 'devtools',
        component: DevelopmentPageComponent,
    },
    {
        path: '404',
        component: PageNotFoundComponent,
    },
    {
        path: 'invite',
        loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
        // CanActivate: [AuthGuard]
    },
    {
        path: 'feed',
        loadChildren: () => import('./feed/feed.module').then(m => m.FeedModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'discussions',
        loadChildren: () => import('./discussion/discussion.module').then(m => m.DiscussionModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'people',
        loadChildren: () => import('./people/people.module').then(m => m.PeopleModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'activities',
        loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'development',
        loadChildren: () => import('./development/development.module').then(m => m.DevelopmentModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'apps',
        loadChildren: () => import('./app/app.module').then(m => m.AppModule),
        canActivate: [AuthGuard],
    },
    {
        path: '**',
        redirectTo: '#/404',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
