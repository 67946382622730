/** @format */

import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { V3ActivitySidenavComponent } from 'app/v3-activity/v3-activity-sidenav/v3-activity-sidenav.component';
import { SideNavService } from './side-nav.service';
import { RoutingService } from './routing.service';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';

@Injectable({
    providedIn: 'root',
})
export class LinkResolverService {
    constructor(private router: Router, private sideNav: SideNavService) {
        this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(async (event: NavigationStart) => {
            await this.handleNavigation(event.url);
        });
    }

    private async handleNavigation(url: string): Promise<void> {
        const urlArray = url.split('/').filter(value => !!value);
        const [ui, type, action, itemId] = urlArray;

        if (ui !== 'ui' || !type || !itemId) {
            return;
        }

        // Prevent navigation
        await this.router.navigate([], { replaceUrl: true });

        switch (type) {
            case 'activity':
                this.sideNav.create(V3ActivitySidenavComponent, {
                    activityId: itemId,
                });

                break;
            case 'user':
                this.sideNav.create(UserDetailComponent, {
                    userId: itemId,
                    initialTab: urlArray[4],
                });

                break;
            default:
                break;
        }
    }
}

export type ViewTypes = 'activities' | 'discussions' | 'events' | 'feed' | 'files' | 'people';
