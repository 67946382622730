/** @format */

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { take } from 'rxjs/operators';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { ImageViewerService, ViewerImage } from 'app/_services/image-viewer.service';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { File } from 'app/_models/file.model';
import { FileViewComponent } from 'app/files-shared/file-view/file-view.component';
import { FilesService } from './../../_services/files.service';
import { MessageAttachment } from 'app/_models/message-attachment.model.';
import { SideNavService } from 'app/_services/side-nav.service';
import { environment } from '@app/env';

const video = document.createElement('video');

@Component({
    selector: 'app-attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'shared', alias: 'shared' } }],
})
export class AttachmentsComponent implements OnInit {
    @Input() attachments: { [id: string]: File } = {};
    supportedFiles: MessageAttachment[] = [];
    otherFiles: MessageAttachment[] = [];
    maxImageDimension = 330; // Sets maximum width and/or height for images

    constructor(
        public filesService: FilesService,
        private imageViewerService: ImageViewerService,
        private sideNavService: SideNavService,
        private platform: Platform,
        private dialogHelperService: DialogHelperService,
        private translocoService: TranslocoService
    ) {}

    ngOnInit() {
        this.processAttachments();
    }

    getDownloadUrl(fileId: string): string {
        return this.filesService.getDownloadUrl(fileId);
    }

    processAttachments() {
        if (!this.attachments) {
            return;
        }

        Object.keys(this.attachments).forEach(fileIndex => {
            const file = this.attachments[fileIndex];

            if (file.isPicture && file.type !== 'image/gif') {
                this.supportedFiles.push({
                    _id: file._id,
                    squareUrl: `${environment.wsUrl}/image/square200/${file._id}`,
                    loResUrl: `${environment.wsUrl}/image/lores/${file._id}`,
                    url: `${environment.wsUrl}/image/hires/${file._id}`,
                    original: `${environment.wsUrl}/image/original/${file._id}`,
                    name: file.name,
                    downloadUrl: `${environment.wsUrl}/file/${file._id}?dl=1`,
                    isPicture: true,
                    file,
                    aspectRatio: file.dimensions?.aspectRatio || 1,
                });
            } else if (file.isVideo && video.canPlayType(file.type)) {
                this.supportedFiles.push({
                    _id: file._id,
                    url: `${environment.wsUrl}/file/${file._id}`,
                    name: file.name,
                    downloadUrl: `${environment.wsUrl}/file/${file._id}?dl=1`,
                    isVideo: true,
                    file,
                });
            } else if (file.type === 'image/gif') {
                const url = `${environment.wsUrl}/file/${file._id}`;
                this.supportedFiles.push({
                    _id: file._id,
                    url,
                    squareUrl: url,
                    name: file.name,
                    downloadUrl: `${environment.wsUrl}/file/${file._id}?dl=1`,
                    isGif: true,
                    file,
                    aspectRatio: file.dimensions?.aspectRatio || 1,
                });
            } else {
                this.otherFiles.push({
                    _id: file._id,
                    url: `${environment.wsUrl}/file/${file._id}`,
                    name: file.name,
                    file,
                });
            }
        });
    }

    viewImages(images: ViewerImage[], index: number) {
        this.imageViewerService.viewImages(images, index);
    }

    getImageUrl(attachment: MessageAttachment): string {
        const hasMultipleFiles = this.supportedFiles.length > 1;
        const singleFileUrl = attachment.isGif ? attachment.url : attachment.loResUrl;
        return hasMultipleFiles ? attachment.squareUrl : singleFileUrl;
    }

    openFileSideNav(fileId: string): void {
        if (this.platform.SAFARI) {
            this.showError(
                this.translocoService.translate('shared.file-viewer.error-viewing-title'),
                this.translocoService.translate('shared.file-viewer.safari-error')
            );
        } else {
            this.sideNavService.clear();
            this.sideNavService.create(FileViewComponent, {
                fileId,
            });
        }
    }

    private showError(errorTitle: string, errorMsg: string): void {
        this.dialogHelperService.showError('OK', errorMsg, errorTitle, false).pipe(take(1)).subscribe();
    }
}
