<div class="topbar-container">
    <h3 mat-dialog-title>{{'dialogs.remove_dialog.dialog_header' | transloco}}</h3>
</div>
<div mat-dialog-content>
  <p><b>{{data}}</b></p>
  <p>{{'dialogs.remove_dialog.dialog_content' | transloco}}</p>
  <mat-form-field appearance="outline">
    <input data-cy="remove-dialog-input" matInput [formControl]="deleteInput">
    <mat-hint>{{'dialogs.remove_dialog.delete_input_hint' | transloco}}</mat-hint>
  </mat-form-field>
</div>
<div class="buttons-container">
  <button data-cy="remove-dialog-cancel-button" mat-button [mat-dialog-close]="false">{{'dialogs.remove_dialog.cancel_button' | transloco}}</button>
  <button data-cy="remove-dialog-confirm-button" mat-raised-button color="warn" [mat-dialog-close]="true" [disabled]="true" #deleteBtn>{{'dialogs.remove_dialog.delete_button' | transloco}}</button>
</div>