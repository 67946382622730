/** @format */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/** Component creates an x button to be used to clear values or filters
 *
 * the reload icon can be used instead if necessary by making `showReloadIcon` true.
 * Defaults to false so that the hailer-x is always used.
 *
 */
@Component({
    selector: 'app-clear-x-button',
    templateUrl: './clear-x-button.component.html',
    styleUrls: ['./clear-x-button.component.scss'],
})
export class ClearXButtonComponent implements OnInit {
    @Input() showReloadIcon = false;

    @Output() clickEvent = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {
        if (this.showReloadIcon === undefined) {
            this.showReloadIcon = false;
        }
    }

    handleClick() {
        this.clickEvent.emit();
    }
}
