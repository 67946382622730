/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RPCService } from 'app/_services/rpc.service';

@Injectable({
    providedIn: 'root',
})
export class V3EditMultipleHelperService {
    selectedActivities = new BehaviorSubject<StrippedActivity[]>([]);

    constructor(private rpc: RPCService) {
        this.rpc.subscribe('activities.updated', (meta: any) => {
            if (!meta?.removed) {
                return;
            }

            this.unSelectActivities(meta.activity_id);
        });
    }

    resetSelectedActivities(activities: StrippedActivity[]) {
        this.selectedActivities.next(activities);
    }

    selectActivities(activities: StrippedActivity[]) {
        const selectedActivities = this.selectedActivities.value;
        const filteredActivities: StrippedActivity[] = [];

        activities.forEach(activity => {
            if (selectedActivities.find(currentActivity => activity._id === currentActivity._id)) {
                // Don't select activity if selected already
                return;
            }

            filteredActivities.push(activity);
        });

        const updatedActivities = selectedActivities.concat(filteredActivities);
        this.selectedActivities.next(updatedActivities);
    }

    unSelectActivities(activityIds: string[]) {
        const selectedActivities = this.selectedActivities.value;
        const filteredActivities = selectedActivities.filter(
            currentActivity => !activityIds.find(activity => currentActivity._id === activity)
        );

        this.selectedActivities.next(filteredActivities);
    }
}

export interface StrippedActivity {
    _id: string;
    name: string;
    currentPhase: string;
}
