/** @format */

import { App, AppCreateValue, AppEditValue } from 'app/_models/app.model';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Optional,
} from '@angular/core';
import { Subject, filter, take, tap } from 'rxjs';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CropImageDialogComponent } from 'app/_dialogs/crop-image-dialog/crop-image-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@app/env';
import { AppService } from 'app/_services/app.service';
import { stripUndefined } from 'app/_helpers/util';
import { CoreService } from 'app/_services/core.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-app-editor',
    templateUrl: './app-editor.component.html',
    styleUrls: ['./app-editor.component.scss'],
})
export class AppEditorComponent implements OnDestroy, OnChanges {
    // If null, treated as creation component
    @Input() app: App | null;

    private onDestroy = new Subject<void>();

    name = new UntypedFormControl('');
    description = new UntypedFormControl('');
    url = new FormControl<string | undefined>({ value: undefined, disabled: false });
    appForm = new UntypedFormGroup({
        name: this.name,
        description: this.description,
        url: this.url,
    });

    image: string | null = null;
    imageUrl: string | null = null;

    constructor(
        private zone: NgZone,
        private core: CoreService,
        private translocoService: TranslocoService,
        private appService: AppService,
        private cdr: ChangeDetectorRef,
        private snackbar: MatSnackBar,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) @Optional() public data?: App,
        @Optional() private dialogRef?: MatDialogRef<AppEditorComponent>
    ) {
        if (data && dialogRef) {
            this.app = data;

            this.name.setValue(this.app.name);
            this.description.setValue(this.app.description);
            this.url.setValue(this.app.url);
            this.image = this.app.image || null;
            this.updateImage(this.image);
        }
    }

    ngOnChanges(): void {
        this.name.setValue(this.app?.name ?? '');
        this.description.setValue(this.app?.description ?? '');
        this.url.setValue(this.app?.url);
        this.updateImage(this.app?.image ?? null);
    }

    ngOnDestroy(): void {
        this.onDestroy.complete();
    }

    openUploadDialog(): void {
        this.zone.run(() => {
            const imageDialog = this.dialog.open(CropImageDialogComponent, {
                width: '800px',
                data: { url: this.image, aspectRatio: 1 / 1, isPublic: true },
            });

            imageDialog
                .afterClosed()
                .pipe(
                    filter(data => !!data),
                    tap((data: string) => {
                        this.updateImage(data);
                        this.cdr.detectChanges();
                    }),
                    take(1)
                )
                .subscribe({
                    next: () => this.cdr.detectChanges(),
                    error: error => {
                        console.error(this.translocoService.translate('activities.process-editor-social.crop_image_error'), error);
                    },
                });
        });
    }
    removeImage(): void {
        this.updateImage(null);
        this.cdr.detectChanges();
    }

    /** Update image and image url in this component. Runs when component is rendered,
     * 	selected app changes or new image is picked by user.
     *
     * 	@param image file id of the image or null if app has no image or it is removed in picker
     */
    updateImage(image: string | null): void {
        this.image = image;
        if (image) {
            this.imageUrl = `${environment.wsUrl}/image/square100/${image}`;
        } else {
            this.imageUrl = null;
        }
    }

    async create(): Promise<void> {
        const value = stripUndefined<AppCreateValue>({
            cid: this.core.network.value._id,
            name: this.name.value,
            description: this.description.value,
            url: this.url.value,
            image: this.image,
        });

        await this.appService.create(value);

        this.close();
    }

    async edit(app: App): Promise<void> {
        const value: AppEditValue = {
            name: this.name.value,
            description: this.description.value,
            url: this.url.value,
            image: this.image,
        };

        await this.appService.update(app._id, value);

        this.close();
    }

    async copyToClipboard(value: string): Promise<void> {
        if (!value) {
            return;
        }

        await navigator.clipboard.writeText(value);
        this.snackbar.open('App ID copied!', 'Ok', { duration: 2000 });
    }

    close(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }
}
