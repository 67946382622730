/** @format */

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { FeedPostComment } from '@app/models';

@Component({
    selector: 'app-feed-post-comments',
    templateUrl: './feed-post-comments.component.html',
    styleUrls: ['./feed-post-comments.component.scss'],
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'feed', alias: 'feed' } }],
})
export class FeedPostCommentsComponent implements OnInit {
    @ViewChild('commentlist', { static: true }) commentlist;
    @Input() comments: FeedPostComment[];

    showLimit = 3;

    constructor() {}

    ngOnInit() {}

    showMore() {
        this.showLimit += 5;
    }
}
