<!-- @format -->

<div data-cy="upload-xlsx-error-dialog-title" class="topbar-container">
	<h3 mat-dialog-title>{{ data.title }}</h3>
</div>
<div data-cy="upload-xlsx-error-dialog-body" class="dialog-content">
	<p>{{ 'dialogs.error_dialog.error_dialog_text_before' | transloco }}</p>
	<br />
	<p>
		<em>{{ data.content }}</em>
	</p>
	<br />
	<p *ngIf="data.showTryAgainMessage">{{ 'dialogs.error_dialog.error_dialog_text_after' | transloco }}</p>
</div>
<div class="popup-single-button-wrapper buttons-container">
    <app-button dataCy="error-dialog-confirm-button" color="tertiary-default-background" size="large" width="popup-single-button" [mat-dialog-close]="true" [focusInitial]="true" [label]="data.confirm" [ariaLabel]="data.confirm">
	</app-button>
</div>
