/**
 * eslint-disable @typescript-eslint/naming-convention
 *
 * @format
 */

/** @format */

import Joi from 'joi';

export const validObjectId = Joi.string().hex().length(24).label('ObjectId');

export const validUserId = validObjectId.description('userId').label('userId');

export const validTeamId = validObjectId.description('teamId').label('teamId');

export const validGroupId = validObjectId.description('groudId').label('groudId');

export const validFileId = validObjectId.description('fileId').label('fileId');

export const validCalendarId = validObjectId.description('calendarId').label('ObjectId');

export const validEventId = validObjectId.description('eventId').label('ObjectId');

export const validNetworkId = validObjectId.description('workspaceId (former networkId)').label('WorkspaceId');

export const validProcessId = validObjectId.description('workflowId').label('processId');

export const validPhaseId = validObjectId.description('phaseId (phase in a Workflow)').label('phaseId');

export const validFieldId = validObjectId.description('fieldId').label('fieldId');

export const validActivityId = validObjectId.description('activityId').label('activityId');

export const validDiscussionId = validObjectId.description('discussionId').label('discussionId');

export const validMessageId = validObjectId.description('messageId').label('ObjectId');

export const validBrandId = Joi.string()
    .regex(/^[0-9a-fA-F]{64}$/)
    .label('ObjectId');

export const validFeedPostId = validObjectId.description('feedPostId').label('ObjectId');
export const validFeedPostType = Joi.string()
    .valid('user', 'activity.created', 'activity.updated')
    .description('Valid type that feed post can be');

export const validFeedCommentId = validObjectId.description('feedCommentId').label('ObjectId');

export const validInsightId = validObjectId.description('insightId').label('ObjectId');

export const validAppId = validObjectId.description('appId').label('ObjectId');
export const validPollId = validObjectId.description('pollId').label('ObjectId');
export const validPollOptionId = validObjectId.description('pollOptionId').label('ObjectId');

export const validLinkType = Joi.string().valid('reaction', 'tag', 'linked-to', 'in_process', 'user-mention', 'apple-id', 'feed.lastseen');
export const validLinkTargetType = Joi.string().valid('feedPost', 'poll', 'message', 'activity', 'file');
export const validLinkSourceType = Joi.string().valid('feedPost', 'message');

export const validProductId = validObjectId.description('productId').label('ObjectId');

export const validProductTargetId = validObjectId.description('productTargetId').label('ObjectId');

export const validHash = Joi.string()
    .regex(/^[0-9a-fA-F]{64}$/)
    .label('ObjectId');

export const validTime = Joi.number().integer().description('Time (milliseconds since 1970-01-01 UTC)').label('JoiTime');
export const validDate = Joi.number()
    .integer()
    .description('Date (Time 00:00:00.000 of day starting milliseconds since 1970-01-01 UTC)')
    .label('JoiDate');

export const validErrorResponse = Joi.object({
    msg: Joi.string().max(512).required(),
    code: Joi.number().required(),
    details: Joi.any(),
});

export const processPhaseSchema = Joi.object({
    _id: validObjectId.required(),
    // followers: Joi.array().items(validObjectId).max(128).required().label('ProcessPhase'),
    name: Joi.string().max(96).required(),
    description: Joi.string().allow('').max(256).truncate(),
    uid: validUserId, // TODO was required, consider removing altogether..?
    created: validTime, // Was required, but is removed when phase is removed for some reason. We should leave the created timestamp when removing (backend fix).
    // members: memberSchema,
    fields: Joi.array().items(validObjectId).max(256),
    // enableAnnouncement: Joi.boolean(),
    // announcementFields: Joi.array().items(validObjectId).max(30),
    // announcementRecipients: Joi.array().items(Joi.string().max(30)),
    // announcementFieldsOrder: Joi.array().items(validObjectId).max(30),
    // announcementToOwner: Joi.boolean(),
    // announcementToOwnerTeam: Joi.boolean(),
    // announcementAllowPrivateReply: Joi.boolean().description('Enables replying to feed announcements if true'),
    isEndpoint: Joi.boolean(),
    possibleNextPhase: Joi.array().items(validObjectId).max(32),
    possibleNextPhaseSettings: Joi.object()
        .pattern(
            /^[0-9a-fA-F]{24}$/,
            Joi.object({
                text: Joi.string().allow('').allow(null).max(128), // added allow null, as found issue in #Hailer workspace
            }).allow(null) // TODO should we allow null here?
        )
        .label('NextPhaseSettings'),
    fieldOptions: Joi.object({
        tableDeadlineField: validObjectId.allow(null),
        eventDateField: validObjectId.allow(null),
    }).label('FieldOptions'),
    primaryNumericField: validObjectId,
    webhooksEnabled: Joi.boolean(),
    webhookUrl: Joi.string().max(2046),
    webhookAdded: Joi.boolean(),
    webhookUpdated: Joi.boolean(),
    primaryDateField: Joi.alternatives(Joi.string().valid('created', 'updated', 'completedOn'), validObjectId, null),
    removed: validTime,
    removedBy: validUserId,
    color: Joi.string()
        .pattern(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/)
        .allow(null),
}).label('ProcessPhase');

export const validWorkflow = Joi.object({
    _id: validObjectId,
    name: Joi.string().required(), // Removed string length requirement .max(96)
    description: Joi.string().max(1024).allow(''),
    workspaceId: validNetworkId.required(),
    // defaultView: Joi.valid('timeline', 'table', 'kanban', 'calendar', 'map'),
    // uid: validUserId.required(),
    // members: memberSchema.required(),
    // created: JoiTime.required(),
    // translations: processTranslationsSchema,
    fields: Joi.object().pattern(
        /^[0-9a-fA-F]{24}$/,
        Joi.object({
            label: Joi.string().required(), // Removed string length requirement .max(128)
            type: Joi.string()
                .valid(
                    'activitylink',
                    'country',
                    'date',
                    'daterange',
                    'datetime',
                    'numeric',
                    'numericunit',
                    'teams',
                    'text',
                    'textarea',
                    'textunit',
                    'textpredefinedoptions',
                    'time',
                    'timerange',
                    'datetimerange',
                    'users',
                    'linkedfrom',
                    'subheader'
                )
                .required(),
            required: Joi.boolean(),
            unit: Joi.string().max(20),
            placeholder: Joi.string().max(256),
            description: Joi.string().max(256),
            data: Joi.array().items(Joi.string()), // Removed string length requirement .max(64)
            _id: validObjectId.required(),
            functionEnabled: Joi.boolean(),
            editable: Joi.boolean(),
            // validate: Joi.boolean(),
            // created: JoiTime,
            // updated: JoiTime,
            // uid: validUserId, // was required, consider removing altogether?
            // defaultTo: Joi.boolean(),
            // defaultValue: Joi.string().allow('').max(128),
            // defaultToSelf: Joi.boolean(),
            // function: Joi.string().max(2 * 6144),
            // functionVariables: functionDependencySchema,
            // inviteToDiscussionOnChange: Joi.boolean(),
            // starred: Joi.array().items(validObjectId),
            // reminderEnabled: Joi.boolean().description('Enable reminder'),
            // reminderSettings: reminderSettingsSchema,
            // collapsedByDefault: Joi.boolean(),
        })
    ),
    // starred: Joi.array().items(validObjectId),
    phases: Joi.object()
        .pattern(/^[0-9a-fA-F]{24}$/, processPhaseSchema)
        .max(32),
    phasesOrder: Joi.array().items(validObjectId).max(32),
    // phasesRemoved: Joi.array().items(validObjectId),
    enableMapLocation: Joi.boolean(),
    // initPhaseDescription: Joi.string().allow('').max(128),
    nameColumnText: Joi.string().max(64),
    nameFieldPlaceHolderText: Joi.string().allow('').max(128),
    // predefinedNamePrefix: Joi.string().max(32),
    // enableAttachments: Joi.boolean(),
    // enableAddedField: Joi.boolean(),
    // enableModifiedField: Joi.boolean(),
    // enableMessenger: Joi.boolean(),
    // allowGuests: Joi.boolean(),
    // enableGuestEditing: Joi.boolean(),
    // defaultGroupByField: validObjectId.allow(null),
    // locationRequired: Joi.boolean(),
    // coverImage: validObjectId,
    // personInCharge: validObjectId,
    // createNewLabel: Joi.string().allow('').max(96),
    // personInChargeLabel: Joi.string().allow('').max(64),
    // updated: JoiTime,
    // removed: JoiTime,
    // order: Joi.number(),
    // enableOwnerTeamField: Joi.boolean(),
    // ownerTeamFieldTitle: Joi.string().max(64),
    // enablePreselectedTeam: Joi.boolean(),
    // preselectedTeam: Joi.object({
    //     team: validObjectId,
    //     account: validObjectId,
    // }).allow(null),
    fieldsOrder: Joi.array().items(validObjectId).required(),
    enableUnlinkedMode: Joi.boolean(),
    // enableLinkedAnnouncements: Joi.boolean(),
    // enablePredefinedName: Joi.boolean(),
    // enableUniqueName: Joi.boolean(),
    // enableOwnerField: Joi.boolean(),
    // allowCustomTags: Joi.boolean(),
    // allowMultipleTags: Joi.boolean(),
    // requireFileTag: Joi.boolean(),
    /*
    publicForm: Joi.object({
        key: Joi.string().max(32),
        creator: validObjectId,
        team_account: teamAccountSchema.required(),
        followers: validObjectId.allow(''),
        active: Joi.boolean().required(),
        allowRead: Joi.boolean().description('If true, show activitylink fields and allow search activities'),
        enableMessaging: Joi.boolean().description('If true, enables form messaging endpoints'),
        theme: Joi.object({
            foregroundColor: Joi.string().regex(/^#[0-9a-f]{6}$/).required().description('Hex color value'),
            backgroundColor: Joi.string().regex(/^#[0-9a-f]{6}$/).required().description('Hex color value'),
            complementaryColor: Joi.string().regex(/^#[0-9a-f]{6}$/).required().description('Hex color value'),
            textColor: Joi.string().regex(/^#[0-9a-f]{6}$/).required().description('Hex color value'),
            headerColor: Joi.string().regex(/^#[0-9a-f]{6}$/).required().description('Hex color value'),
            logoId: validFileId.allow(null),
        }).allow(null).description('Theme information containing colors and logo for form')
    }),
    */
    // nameFunction: Joi.string().max(2 * 4096),
    // nameFunctionVariables: functionDependencySchema,
    // nameFunctionEnabled: Joi.boolean(),
    // nameEditable: Joi.boolean(),
    // createdActivities: Joi.number(),
    /*
    documentTemplates: Joi.object().pattern(
        /^.*$/,
        Joi.object({
            templateId: validObjectId,
            name: Joi.string().max(64),
            order: Joi.number(),
            fieldMap: Joi.object({
                staticActivityIds: Joi.string().allow('').max(256),
                images: Joi.object().pattern(
                    /^.*$/,
                    Joi.object({
                        value: Joi.string().allow('').max(256),
                        description: Joi.string().allow('').max(256),
                    })
                ),
                activityLinks: Joi.object().pattern(
                    /^.*$/,
                    Joi.object({
                        label: Joi.string().allow('').max(256),
                        process: Joi.string().allow('').max(24),
                        field: Joi.string().allow('').max(256),
                        description: Joi.string().allow('').max(256),
                    })
                ),
                fields: Joi.object().pattern(
                    /^.*$/,
                    Joi.object({
                        value: Joi.string().allow('').max(256),
                        label: Joi.string().allow('').max(256),
                        description: Joi.string().allow('').max(256),
                    })
                ),
            }),
            opts: Joi.object({
                formatFieldValue: Joi.boolean(),
                thousandSeparator: Joi.string().allow('').max(4),
                decimalSeparator: Joi.string().allow('').max(4),
                toFixedDigits: Joi.string().allow('').max(4),
                toFixedIds: Joi.string().allow('').max(512),
                useRenderedActivity: Joi.boolean(),
            }),
        })
    ),
    */
    // color: Joi.string()
    //     .pattern(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/)
    //     .allow(null),
    // isStarred: Joi.boolean(),
    // packageHash: validHash,
});

export const validActivityListArguments = [
    Joi.object()
        .keys({
            processId: validProcessId.required(),
            phaseId: validPhaseId.required(),
        })
        .required()
        .description('Workflow object'),
    Joi.object()
        .keys({
            sortBy: Joi.alternatives([
                Joi.string().valid('name', 'created', 'updated', 'following', 'owner', 'team', 'completedOn', 'priority'),
                validObjectId.description('Add `fieldId` for sorting by field'),
            ]).default('updated'),
            sortOrder: Joi.string().valid('asc', 'desc').default('desc').description('"asc" for ascending and "desc" for descending order'),
            limit: Joi.number().integer().min(1).max(250).default(20).description('Limit the maximum number of results'),
            skip: Joi.number().integer().default(0).description('Skip the number of first results. Default: 0'),
            // includeUsers: Joi.boolean().default(true).description('Includes available users & fields list in the "metadata" object'),
            // includeTeams: Joi.boolean().default(true).description('Includes available teams & accounts list in the "metadata" object'),
            // includeHistory: Joi.boolean()
            //     .default(false)
            //     .description('Includes "history" array of objects with all versions of the activity'),
            filters: Joi.any(), // ActivityFilterValidator
        })
        .description('Options object'),
];

export const validActivity = Joi.object({
    _id: validActivityId.required().description('The `_id` of the activity'),
    workflowId: validProcessId.required(),
    // followerIds: Joi.array().items(validUserId),
    teamId: validTeamId.required(),
    name: Joi.string().description('The name of the activity'),
    created: validTime.required(),
    updated: validTime,
    // uid: validUserId.required(),
    phaseId: validPhaseId.required(),
    // discussionId: validDiscussionId.required(),
    fields: Joi.object()
        .pattern(/^[0-9a-f]{24}$/, Joi.any())
        .description('Fields of activity, format of items is dependent on the configuration of the process given in `process` field.'),
    // files: Joi.array().items(validFileId),
    priority: Joi.number(),
    // active: Joi.boolean().description('Activity not in endpoint phase is considered active'),
    location: Joi.object().keys({
        type: Joi.string().valid('point', 'area', 'polyline'),
        label: Joi.string().allow('', null),
        data: Joi.array().items(
            Joi.object().keys({
                lat: Joi.number(),
                lng: Joi.number(),
            })
        ),
    }),
    sequence: Joi.number(),
});

export const validUser = Joi.object({
    _id: validObjectId,
    firstname: Joi.string().required(),
    lastname: Joi.string().required(),
    // default_company: Joi.string(), // Temporarily disabled for refactoring .required(),
    // companies: Joi.array().items(validObjectId),
    // default_profilepic: validObjectId.required(),
    // created: JoiTime.required(),
    // updated: JoiTime,
    // removed: JoiTime,
    /** Send notifications to managed users. Managed users email is generated and can’t be used for notifications */
    // notificationEmail: Joi.string().email(),
    // email: Joi.string().required(),
    // emailCheck: Joi.string(),
    // emailToken: Joi.string(),
    // emailVerified: Joi.boolean(),
    /*
    lastLocation: Joi.object({
        lat: Joi.number(),
        lng: Joi.number(),
        name: Joi.string(),
        time: JoiTime
    }).allow(null),
    */
    // lastSeen: JoiTime.required(),
    // calendars: Joi.array().items(validObjectId),
    // settings: Joi.object({}).pattern(/^cid_[0-9a-fA-F]{24}$/, Joi.object()),
    // status: Joi.string(),
    // personalIcalLink: Joi.string(),
    /*
    globalSettings: Joi.object({
        enterToSend: Joi.boolean(),
        discussionMessageEmail: Joi.boolean(),
        feedPostEmail: Joi.boolean(),
        theme: Joi.string().allow('light', 'dark', null),
    }).allow(null),
    */
    // profilepics: Joi.array().items(validObjectId),
    // preferredLanguages: validLanguages,
    // permissions: Joi.any(), // TODO: Remove this
    // managedUser: Joi.boolean().description('Indicates if user is managed user or not'),
    // loginAttempts: Joi.number()
});
