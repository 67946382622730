<!-- @format -->

<div class="dialog-header">
    <h1 data-cy="dialog-title" mat-dialog-title>Permissions</h1>
    <app-button
        dataCy="close-activity-button"
        type="icon"
        hailerIconName="hailer-x"
        color="icon-white-all-themes"
        tippyText="Close"
        ariaLabel="Close popup window"
        (clickEvent)="close()"></app-button>
</div>

<div style="height: 100%; overflow-y: scroll">
    <h2 *ngIf="!insight" class="dialog-second-header">Failed to load Insight</h2>

    <div *ngIf="insight">
        <h2 data-cy="insight-name" class="dialog-second-header text-align-left">{{ insight.name }}</h2>

        <h3 data-cy="insight-dialog-header" class="flex-align-center"><mat-icon svgIcon="hailer-lock" class="margin-right-medium"></mat-icon>Permissions</h3>

        <div class="permission-container">
            <app-permission-selector
                data-cy="permission-selector"
                [members]="selectorMembers"
                (memberAdd)="memberAdd(insight._id, $event)"
                (memberRemove)="memberRemove(insight._id, $event)">
            </app-permission-selector>
        </div>
    </div>

    <h3 data-cy="public-sharing-title" class="flex-align-center"><mat-icon svgIcon="hailer-share" class="margin-right-medium"></mat-icon>Public sharing</h3>

    <form [formGroup]="form">
        <mat-checkbox data-cy="check-box-enable-public-sharing" color="accent" class="checkbox-thin margin-left-medium" formControlName="public">
            Enable public sharing
        </mat-checkbox>
    </form>

    <div *ngIf="insight.publicKey" class="margin-top-large">
        <app-button
            data-cy="copy-public-link-button"
            type="text-with-icon"
            color="tertiary-default-background"
            hailerIconName="hailer-copy"
            label="Copy public link"
            ariaLabel="Copy public link"
            [tippyText]="service.getInsightPublicUrl(insight.publicKey)"
            (clickEvent)="service.copyToClipboard(service.getInsightPublicUrl(insight.publicKey))"></app-button>
    </div>
</div>
