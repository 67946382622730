/** @format */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { Discussion, DiscussionMap, User } from '@app/models';
import { PeopleService } from 'app/people/people.service';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';
import { CoreService } from 'app/_services/core.service';

@Component({
    selector: 'app-discussion-picker',
    templateUrl: './discussion-picker.component.html',
    styleUrls: ['./discussion-picker.component.scss'],
})
export class DiscussionPickerComponent implements OnInit {
    @Input() discussions: Observable<DiscussionMap>;
    @Input() hiddenDiscussionIds: string[] = [];
    @Output() discussionId = new EventEmitter<string>();
    /** Emitted when a searched user is clicked */
    @Output() userId = new EventEmitter<string>();

    sortedDiscussions: Observable<Discussion[]>;
    searchedDiscussions: Observable<Discussion[]>;
    finalDiscussions: Observable<Discussion[]>;
    selectedFilter: { viewValue: string; value?: string } = { viewValue: 'Filter Discussions', value: null };
    searchString: string;
    newContacts = new BehaviorSubject<User[]>([]);

    constructor(private people: PeopleService, private core: CoreService, public v3Discussion: V3DiscussionService) {}

    ngOnInit(): void {
        this.sortedDiscussions = this.discussions.pipe(map(discussionMap => this.discussionMapToSortedArray(discussionMap)));
    }

    setFinalDiscussions(filteredDiscussions: Observable<Discussion[]>) {
        this.finalDiscussions = filteredDiscussions.pipe(map(discussions => this.sortUserDiscussionsToTop(discussions)));
    }

    setSearchString(str: string) {
        this.searchString = str;
        this.searchContacts();
    }

    setSelectedFilter(filter) {
        this.selectedFilter = filter;
    }

    discussionClicked(discussionId: string) {
        this.discussionId.emit(discussionId);
    }

    searchedUserClicked(userId: string) {
        const userDiscussions = this.v3Discussion.getPrivate(userId);

        if (userDiscussions.length === 1) {
            this.discussionId.emit(userDiscussions[0]._id);
            return;
        }

        this.discussionId.emit(userId);
        return;
    }

    private searchContacts() {
        if (this.searchString.length < 3) {
            this.newContacts.next([]);
            return;
        }

        const allUsers = this.people.getUsers();
        const filteredNewContacts = allUsers.filter(
            user => user._id !== this.core.user.value._id && user.display_name.toLowerCase().includes(this.searchString)
        );

        this.newContacts.next(filteredNewContacts);
    }

    private sortUserDiscussionsToTop(discussions: Discussion[]): Discussion[] {
        const usersToTopFilters = ['starred', 'unread'];
        // If selected filter is not null or part of the usersToTopFilters
        const uniqueFilterCase = this.selectedFilter.value !== null && !usersToTopFilters.includes(this.selectedFilter.value);

        if (!this.searchString?.length || !discussions?.length || uniqueFilterCase) {
            return discussions || [];
        }

        const privateDiscussions = discussions.filter(discussion => discussion.private) || [];
        const otherDiscussions = discussions.filter(discussion => !discussion.private) || [];
        const sortedDiscussions = privateDiscussions.concat(otherDiscussions);
        return sortedDiscussions;
    }

    private discussionMapToSortedArray(discussionMap: DiscussionMap): Discussion[] {
        if (!Object.keys(discussionMap)?.length) {
            return [];
        }
        return this.sortDiscussionsByLastActive(Object.keys(discussionMap).map(_id => discussionMap[_id]));
    }

    // Sorts the discussion by latest activity and filters out the discussion where the message is being forwarded from
    private sortDiscussionsByLastActive(discussions: Discussion[]): Discussion[] {
        return discussions
            .sort((a, b) => b.last_active - a.last_active)
            .filter(origin => this.hiddenDiscussionIds.indexOf(origin._id) === -1);
    }

    get maxBufferSize(): number {
        const discussionListTopBarHeight = 146;
        const discussionItemHeight = 72;
        const viewPortHeight = window.innerHeight - discussionListTopBarHeight || 0;
        const maxBufferSize = Math.floor(viewPortHeight / discussionItemHeight);
        return discussionItemHeight * (maxBufferSize + 10);
    }

    get minBufferSize(): number {
        const discussionListTopBarHeight = 146;
        const discussionItemHeight = 72;
        const viewPortHeight = window.innerHeight - discussionListTopBarHeight || 0;
        const minBufferSize = Math.floor(viewPortHeight / discussionItemHeight);
        return discussionItemHeight * (minBufferSize + 5);
    }
}
