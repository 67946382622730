/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CoreService } from 'app/_services/core.service';
import { RPCService } from 'app/_services/rpc.service';

@Injectable({ providedIn: 'root' })
export class FeedStaticService {
    unreadPostCounts = new BehaviorSubject<{ [cid: string]: number }>({});
    focusCreate = new BehaviorSubject<boolean>(false);

    constructor(private rpc: RPCService, private core: CoreService) {
        this.core.onAuthenticated(() => {
            void this.setUnreadAmounts();
            this.rpc.signals.subscribe({
                next: signal => {
                    switch (signal.sig) {
                        case 'wall2.delete_post':
                        case 'wall2.new_post':
                        case 'feed.post.created':
                            void this.setUnreadAmounts();
                            break;
                        default:
                            break;
                    }
                },
            });
        });
    }

    async setSeen(workspaceIds: string[]): Promise<{ seen: number; networks: string[] }> {
        const seen = await this.rpc.requestAsync('wall2.seen', [{ networks: workspaceIds }]);

        const unreadCounts = this.unreadPostCounts.value;
        for (const workspaceId of workspaceIds) {
            delete unreadCounts[workspaceId];
        }

        this.unreadPostCounts.next(unreadCounts);
        return seen;
    }

    async setUnreadAmounts(): Promise<void> {
        const typeFiltersString = localStorage.getItem('feedSelectedTypeFilters');
        const typeFilters = typeFiltersString ? JSON.parse(typeFiltersString) : [];

        const selectedWorkspaceIds = this.core.user.value?.globalSettings?.feedSelectedWorkspaces;
        const workspaceIds = selectedWorkspaceIds?.length ? selectedWorkspaceIds : Object.keys(this.core.networks.value);

        try {
            const amounts: { [workspaceId: string]: number } | undefined = await this.rpc.requestAsync('wall2.unread', [
                { workspaceIds, types: typeFilters },
            ]);

            if (!amounts) {
                throw new Error('Unread amounts are undefined');
            }

            this.unreadPostCounts.next(amounts);
        } catch (error) {
            console.error('Failed to get unread amounts', error);
        }
    }
}
