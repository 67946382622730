<!-- @format -->
<form>
    <div class="entra-login-button-container--multiple-buttons">
        <button
            (click)="loginEntra()"
            [disabled]="logInDisabled() || logInInProgress"
            data-cy="login-entra-button"
            mat-flat-button
            class="single-row-button primary-public-pages">
            {{
                (saving | async) || logInInProgress
                    ? ('public.login-form.button_login_1' | transloco)
                    : ('public.login-form.button_login_2' | transloco)
            }}
        </button>
        <button
            *ngIf="logInInProgress"
            (click)="clearLocalStorage()"
            mat-flat-button
            class="single-row-button primary-public-pages">
            {{ ('public.login-form.button_reset' | transloco) }}
        </button>

        <ng-container *ngIf="entraLoginError">
            <span class="login-error">{{ 'public.login-form.login_error' | transloco }}</span>
            <span class="login-error">{{ entraLoginError }}</span>
        </ng-container>
    </div>
</form>
