/** @format */

import Joi from 'joi';
import { validObjectId, validProcessId, validUserId } from './validation';

const workflowPermissionSchema = Joi.string().valid('admin', 'any', 'ownteam', 'own', 'guest', 'none');
const workflowPhasePermissionSchema = Joi.string().valid('edit', 'view', 'none');
const calendarPermissionSchema = Joi.string().valid('admin', 'user');

export const permissionPathSchema = {
    type: Joi.string().valid('network', 'user', 'team', 'group'),
    id: validObjectId,
    permission: Joi.alternatives(workflowPermissionSchema, workflowPhasePermissionSchema, calendarPermissionSchema),
};

export const workflowPermissionMapSchema = Joi.object().pattern(validProcessId, {
    // hasAccess: Joi.boolean(),
    isAdmin: Joi.boolean(),
    // permission: workflowPermissionSchema,
    // permissions: Joi.array().items(permissionPathSchema),
    // phases: Joi.object().pattern(validPhaseId, {
    //     name: Joi.string(),
    //     hasAccess: Joi.boolean(),
    //     permission: workflowPhasePermissionSchema,
    //     permissions: Joi.array().items(permissionPathSchema),
    //     restricted: Joi.boolean(),
    // }),
    // teams: Joi.array().valid(validTeamId),
});

export const workspaceRolesPermissionSchema = Joi.object({
    isAdmin: Joi.boolean(),
    isOwner: Joi.boolean(),
    isGuest: Joi.boolean(),
    isInviter: Joi.boolean(),
    isUser: Joi.boolean(),
    // custom: roleSchema,
});

export const permissionMapSchema = Joi.object().pattern(validUserId, {
    workflow: workflowPermissionMapSchema,
    workspace: workspaceRolesPermissionSchema,
    // calendar: calendarPermissionMapSchema,
    // insight: insightPermissionMapSchema,
    // app: appPermissionMapSchema,
    // teams: Joi.array().items(validTeamId),
    // permits: Joi.array().items(Joi.string()),
});
/* 
export const insightPermissionMapSchema = Joi.object().pattern(validInsightId, {
    hasAccess: Joi.boolean(),
    permissions: Joi.array().items(permissionPathSchema),
    permission: Joi.string().valid('edit'),
});

export const appPermissionMapSchema = Joi.object().pattern(validAppId, {
    hasAccess: Joi.boolean(),
    permissions: Joi.array().items(permissionPathSchema),
    permission: Joi.string().valid('edit'),
});

export const calendarPermissionMapSchema = Joi.object().pattern(validCalendarId, {
    hasAccess: Joi.boolean(),
    permissions: Joi.array().items(permissionPathSchema),
    permission: Joi.string().valid('edit'),
});

*/
