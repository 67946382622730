/** @format */

import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export const feedPostLikeButtonAnimations = [
    trigger('likeBounce', [
        state(
            'not-liked',
            style({
                opacity: 0,
                color: 'var(--text-medium)',
            })
        ),
        state(
            'liked',
            style({
                opacity: 1,
                color: '#ff5252',
            })
        ),
        transition('not-liked => liked', [
            animate(
                '.55s',
                keyframes([
                    style({ transform: 'scale(0.2)', offset: 0, opacity: 1 }),
                    style({ transform: 'scale(0.2)', offset: 0.45 }),
                    style({ transform: 'scale(1.2)', color: '#ff5252', offset: 0.67 }),
                    style({ transform: 'scale(1)', offset: 1 }),
                ])
            ),
        ]),
        transition('liked => not-liked', [
            animate(
                '.2s',
                keyframes([
                    style({ transform: 'scale(1.4)', color: 'var(--text-medium)', offset: 0 }),
                    style({ transform: 'scale(1)', offset: 1 }),
                ])
            ),
        ]),
    ]),
    trigger('circleAnim', [
        transition('not-liked => liked', [
            animate(
                '.3s',
                keyframes([
                    style({ transform: 'scale(10)', opacity: '1', fill: '#ff1744', offset: 0.4 }),
                    style({ transform: 'scale(11)', opacity: '1', fill: '#ff5252', offset: 0.55 }),
                    style({ transform: 'scale(12)', opacity: '1', fill: '#ff8a80', offset: 0.65 }),
                    style({
                        transform: 'scale(13)',
                        opacity: '1',
                        fill: 'transparent',
                        stroke: '#ff8a80',
                        strokeWidth: '.5',
                        offset: 0.75,
                    }),
                    style({
                        transform: 'scale(17)',
                        opacity: '1',
                        fill: 'transparent',
                        stroke: '#ff8a80',
                        strokeWidth: '.2',
                        offset: 0.85,
                    }),
                    style({
                        transform: 'scale(18)',
                        opacity: '1',
                        fill: 'transparent',
                        stroke: '#ff8a80',
                        strokeWidth: '.1',
                        offset: 0.95,
                    }),
                    style({
                        transform: 'scale(19)',
                        opacity: '1',
                        fill: 'transparent',
                        stroke: '#ff8a80',
                        strokeWidth: '0',
                        offset: 1,
                    }),
                ])
            ),
        ]),
    ]),
];
