/** @format */

import { Injectable, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, RedirectRequest, AccountInfo, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';
import { CoreService } from './core.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    // Observable status tracker
    private statusSubject = new Subject<boolean>();
    status$ = this.statusSubject.asObservable();

    private idToken: string | null = null;
    private onDestroy = new Subject<void>();
    entraLoginError: string;

    constructor(private msalService: MsalService, private auth: CoreService, private router: Router) {}

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    loginPopup(): Promise<AuthenticationResult | null> {
        const loginRequest = {
            scopes: ['openid', 'profile', 'User.Read'],
        };
    
        // Convert Observable to Promise using firstValueFrom
        return firstValueFrom(this.msalService.loginPopup(loginRequest));
    }

    async processLogin(result: AuthenticationResult): Promise<void> {
        if (result) {
            this.msalService.instance.setActiveAccount(result.account);
            this.idToken = result.idToken;
    
            const isIdTokenSet = this.getIsIdTokenSet();
            if (isIdTokenSet) {
                console.log('ID token already set. User will not be logged in again.');
                return;
            }
    
            this.setIsIdTokenSet('true');
    
            // You can call your backend with the token here
            try {
                this.auth.loginMicrosoft(result.idToken).pipe(takeUntil(this.onDestroy)).subscribe({
                    next: () => {
                        this.clearLocalStorage(); // Clear local storage after successful login
                        console.log('Login successful, redirecting...');
                        this.router.navigate(['/discussions']); // Redirect to a secure page
                    },
                    error: error => {
                        console.error('Backend login failed:', error);
                        this.entraLoginError = error.msg;
                    }
                });
            } catch (error) {
                console.error('Error calling backend:', error);
                this.entraLoginError = error.msg;
            }
        }
    }

    logout(): void {
        this.msalService.logoutPopup();
    }

    isLoggedIn(): boolean {
        return this.msalService.instance.getActiveAccount() !== null;
    }

    async getIdToken(): Promise<string | null> {
        if (!this.idToken) {
            const account: AccountInfo | null = this.msalService.instance.getActiveAccount();
            if (account) {
                try {
                    const tokenResponse: AuthenticationResult = await this.msalService.instance.acquireTokenSilent({
                        account: account,
                        scopes: ['openid', 'profile', 'User.Read'],
                    });
                    this.idToken = tokenResponse.idToken;
                    return this.idToken;
                } catch (error) {
                    console.error('Error acquiring token silently:', error);
                    return null;
                }
            }
        }
        return this.idToken;
    }

    async configureMsalInstance(clientId: string, tenantId: string): Promise<void> {
        const msalInstance = new PublicClientApplication({
            auth: {
                clientId: clientId,
                authority: `https://login.microsoftonline.com/${tenantId}`,
                redirectUri: '/',
                navigateToLoginRequestUrl: true,
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: false,
            },
        });

        await msalInstance.initialize();
        this.msalService.instance = msalInstance;
    }

    setIsIdTokenSet(isIdTokenSet: string): void {
        localStorage.setItem('isIdTokenSet', isIdTokenSet);
    }

    getIsIdTokenSet(): string | null {
        return localStorage.getItem('isIdTokenSet');
    }

    setIsEntraLoginInProgress(isEntraLoginInProgress: string): void {
        localStorage.setItem('isEntraLoginInProgress', isEntraLoginInProgress);
    }

    getIsEntraLoginInProgress(): string | null {
        return localStorage.getItem('isEntraLoginInProgress');
    }

    clearLocalStorage(): void {
        localStorage.removeItem('isIdTokenSet');
        localStorage.removeItem('isEntraLoginInProgress');
    }

    updateStatus(status: boolean): void {
        this.statusSubject.next(status);
    }
}
