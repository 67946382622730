<!-- Tag cloud filter -->
<button mat-button (click)="toggleViewMode()" style="width: 100%;">
    <mat-icon style="margin-right: 8px;" class="figma-icons-line" svgIcon="{{((filteringMode | async) === 'cloud') ? 'hailer-personal-files' : 'hailer-folder'}}"></mat-icon>
    {{(filteringMode | async)}}
</button>
<div *ngIf="(filteringMode | async) === 'cloud'" class="file-tags-container">
    <mat-form-field style="width: 100%" appearance="outline">
        <mat-chip-list #tagCloud aria-label="Tag selection">
            <ng-container *ngFor="let tagId of (allowedTags | async)">

                <!--Selected tags -->
                <mat-chip color="primary" selected *ngIf="getTag(tagId) && isSelected(tagId); else notSelected"
                    (click)="tagCloudRemoveTag(tagId)" class="tag-chip">
                    {{getTag(tagId)?.value}}
                </mat-chip>

                <!--Unselected tags -->
                <ng-template #notSelected>
                    <mat-chip *ngIf="getTag(tagId)" [disabled]="!enableTag(tagId)" [selectable]="enableTag(tagId)"
                        (click)="tagCloudAddTag(tagId)" class="tag-chip">
                        {{getTag(tagId)?.value}}
                    </mat-chip>
                </ng-template>

            </ng-container>
        </mat-chip-list>
        <button mat-icon-button matSuffix (click)="resetFilter()" *ngIf="(selectedTags | async)?.length > 1">
            <mat-icon>clear</mat-icon>
        </button>
    </mat-form-field>

    <!-- File list for cloud filter -->
    <mat-list role="list">
        <ng-container *ngFor="let fileId of (exactMatches | async)?.file">
            <div class="files-wrapper">
                <mat-list-item *ngIf="(getObject(fileId) | async)" role="listitem" (click)="selectFile(fileId)" class="file-item border-bottom">
                    <div class="file-item-content-left">
                        <h3 tippy="{{(getObject(fileId) | async)?.name}}">{{(getObject(fileId) | async)?.name}}</h3>
                        <p>{{'shared.tag-filter-object-list.added_by' | transloco}}
                            {{getUserName((getObject(fileId) | async)?.uid)}}</p>
                        <p>{{'shared.tag-filter-object-list.date' | transloco}}
                            {{(getObject(fileId) | async)?.created | date: 'short'}}</p>
                    </div>
                    <div class="file-item-content-right">
                        <button class="no-button for-icon" [ngClass]="isDeletable(fileId) ? 'warn' : 'primary'" *ngIf="!(objectsFilterOptions | async)?.disableDeletion"
                            (click)="$event.stopPropagation(); isDeletable(fileId) ? unDeleteObject(fileId) : deleteObject(fileId)">
                            <mat-icon svgIcon="hailer-trash"></mat-icon>
                        </button>
                        <p>{{(getObject(fileId) | async)?.length | filesize}}</p>
                    </div>
                </mat-list-item>
            </div>
        </ng-container>
        <ng-container *ngFor="let fileId of (partialMatches | async)?.file">
            <div class="files-wrapper">
                <mat-list-item *ngIf="(getObject(fileId) | async)" role="listitem" (click)="selectFile(fileId)" class="file-item border-bottom">
                    <div class="file-item-content-left">
                        <h3 tippy="{{(getObject(fileId) | async)?.name}}">{{(getObject(fileId) | async)?.name}}</h3>
                        <p>{{'shared.tag-filter-object-list.added_by' | transloco}}
                            {{getUserName((getObject(fileId) | async)?.uid)}}</p>
                        <p>{{'shared.tag-filter-object-list.date' | transloco}}
                            {{(getObject(fileId) | async)?.created | date: 'short'}}</p>
                    </div>
                    <div class="file-item-content-right">
                        <button class="no-button for-icon" [ngClass]="isDeletable(fileId) ? 'warn' : 'primary'" *ngIf="!(objectsFilterOptions | async)?.disableDeletion"
                            (click)="$event.stopPropagation(); isDeletable(fileId) ? unDeleteObject(fileId) : deleteObject(fileId)">
                            <mat-icon svgIcon="hailer-trash"></mat-icon>
                        </button>
                        <p>{{(getObject(fileId) | async)?.length | filesize}}</p>
                    </div>
                </mat-list-item>
            </div>
        </ng-container>
    </mat-list>
</div>

<div *ngIf="(filteringMode | async) === 'folder'">
    <div>
        <button mat-icon-button color="alert" (click)="folderViewBackButton()" *ngIf="(selectedTags | async).length > 0">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <span *ngFor="let tagId of (selectedTags | async); let i = index">{{i > 0 ? '/':''}}{{getTag(tagId).value}}</span>
    </div>
    <mat-list role="list">
        <mat-list-item *ngFor="let tagId of (linkedTags | async)" role="listitem" (click)="folderViewFilter(tagId)" class="folder-name">
            <mat-icon style="margin-right: 8px;" svgIcon="hailer-folder"></mat-icon>{{getTag(tagId).value}} {{getFolderObjectCount(tagId)}}
        </mat-list-item>
        <ng-container *ngIf="(exactMatches | async)">
            <ng-container *ngFor="let fileId of (exactMatches | async).file">
                <div class="files-wrapper">
                    <mat-list-item *ngIf="(getObject(fileId) | async)" role="listitem" (click)="selectFile(fileId)" class="file-item border-bottom">
                        <div class="file-item-content-left">
                            <h3 tippy="{{(getObject(fileId) | async)?.name}}">{{(getObject(fileId) | async)?.name}}</h3>
                            <p>{{'shared.tag-filter-object-list.added_by' | transloco}}
                                {{getUserName((getObject(fileId) | async)?.uid)}}</p>
                            <p>{{'shared.tag-filter-object-list.date' | transloco}}
                                {{(getObject(fileId) | async)?.created | date: 'short'}}</p>
                        </div>
                        <div class="file-item-content-right">
                            <button class="no-button for-icon" [ngClass]="isDeletable(fileId) ? 'warn' : 'primary'" *ngIf="!(objectsFilterOptions | async)?.disableDeletion"
                                (click)="$event.stopPropagation(); isDeletable(fileId) ? unDeleteObject(fileId) : deleteObject(fileId)">
                                <mat-icon svgIcon="hailer-trash"></mat-icon>
                            </button>
                            <p>{{(getObject(fileId) | async)?.length | filesize}}</p>
                        </div>
                    </mat-list-item>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="(noMatches | async) && (selectedTags | async)?.length < 1">
            <div class="files-wrapper">
                <ng-container *ngFor="let fileId of (noMatches | async).file">
                    <mat-list-item *ngIf="(getObject(fileId) | async)" role="listitem" (click)="selectFile(fileId)" class="file-item border-bottom">
                        <div class="file-item-content-left">
                            <h3 tippy="{{(getObject(fileId) | async)?.name}}">{{(getObject(fileId) | async)?.name}}</h3>
                            <p>{{'shared.tag-filter-object-list.added_by' | transloco}}
                                {{getUserName((getObject(fileId) | async)?.uid)}}</p>
                            <p>{{'shared.tag-filter-object-list.date' | transloco}}
                                {{(getObject(fileId) | async)?.created | date: 'short'}}</p>
                        </div>
                        <div class="file-item-content-right">
                            <button class="no-button for-icon" [ngClass]="isDeletable(fileId) ? 'warn' : 'primary'" *ngIf="!(objectsFilterOptions | async)?.disableDeletion"
                                (click)="$event.stopPropagation(); isDeletable(fileId) ? unDeleteObject(fileId) : deleteObject(fileId)">
                                <mat-icon svgIcon="hailer-trash">
                                </mat-icon>
                            </button>
                            <p>{{(getObject(fileId) | async)?.length | filesize}}</p>
                        </div>
                    </mat-list-item>
                </ng-container>
            </div>
        </ng-container>
    </mat-list>
</div>