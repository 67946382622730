/** @format */

import { Deserializable } from './deserializable.model';

export class FeedPostCommentSend {
    text = '';
    files: string[] = [];
    loc: any;
}

export class FeedPostComment implements Deserializable {
    cid: string;
    comment: {
        from: string;
        text: string;
    };
    created: string;
    post_id: string;
    _id: string;
    _metas: any;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
