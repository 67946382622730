/** @format */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Platform } from '@angular/cdk/platform';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import * as _moment from 'moment';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

import { ApiNotificationService } from 'app/_services/notification.service';
import { Company, CompanyMap } from '../../_models/company.model';
import { CoreService } from 'app/_services/core.service';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { EditCalendarComponent } from 'app/events/components/edit-calendar/edit-calendar.component';
import { WindowListenerService } from '../../_services/window-listener.service';
import { EventsService } from 'app/events/events.service';
import { FeedService } from 'app/feed/feed.service';
import { HelperService } from 'app/_services/helper.service';
import { InvitePageComponent } from 'app/people/invite-page/invite-page.component';
import { NewNetworkDialogComponent } from '../new-network-dialog/new-network-dialog.component';
import { Calendar, Notification } from '@app/models';
import { NotificationComponent } from '../notification/notification.component';
import { PermissionService } from 'app/_services/permission.service';
import { PersonalSettings } from '../../_models/personal-settings.model';
import { ProfileDialogComponent } from 'app/people/profile-dialog/profile-dialog.component';
import { SideNavService } from 'app/_services/side-nav.service';
import { UserService } from 'app/_services/user.service';
import { UserThemes } from 'app/_models/user-theme.type';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';
import { EventSidenavComponent } from 'app/events-shared/event-sidenav/event-sidenav.component';
import { TranslateService } from 'app/_services/translate.service';
import { AppService } from 'app/_services/app.service';
import { StylingHelperService } from 'app/_services/styling-helper.service';
import { environment } from '@app/env';
import { InsightService } from 'app/app/insight.service';
import { App } from 'app/_models/app.model';
import { FeedStaticService } from 'app/feed/feed-static.service';

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class SideNavComponent implements OnInit, OnDestroy {
    @ViewChild('notificationContainer', { read: ElementRef, static: false }) notificationContainer: ElementRef;
    @ViewChildren('notificationComponent') notificationComponents: QueryList<NotificationComponent>;

    notifications: BehaviorSubject<any>;
    notificationUnreadCount: BehaviorSubject<number>;
    user: BehaviorSubject<PersonalSettings>;
    verifyEmailNotification: Notification;
    verifyEmailUnreadCount = 0;
    networks: Observable<Company[]>;
    screenWidth = window.innerWidth;
    networksLogo: string;
    showContextualAdd: 'discussion' | 'events' | 'feed' | 'people' = null;
    currentThemeName = '';
    guestUserTooltip = 'Contact your admin to get access';
    isManagedUser: boolean;
    unreadFeedPosts: { [networkId: string]: number } = {};
    totalUnread = 0;

    appDefaultImage = '../../../assets/img/hailerlogo2.svg';
    baseUrl = `${environment.wsUrl}/image/square100`;
    userApps: App[] = [];

    private onDestroy = new Subject<void>();

    constructor (
        public stylingHelperService: StylingHelperService,
        public core: CoreService,
        public platform: Platform,
        public permissions: PermissionService,
        public appService: AppService,
        public insightService: InsightService,
        private zone: NgZone,
        private cdr: ChangeDetectorRef,
        private matDialog: MatDialog,
        private router: Router,
        private feedStatic: FeedStaticService,
        private notificationService: ApiNotificationService,
        private helpers: HelperService,
        private sideNav: SideNavService,
        private windowListener: WindowListenerService,
        private eventsService: EventsService,
        private userService: UserService,
        private v3Discussion: V3DiscussionService,
        private translocoService: TranslocoService,
        private translateService: TranslateService
    ) {
        this.translateService.translationLoaded('shared').then(() => {
            this.guestUserTooltip = this.translocoService.translate('shared.side-nav.get_user_tooltip');
        });
    }

    ngOnInit() {
        this.user = this.core.user;
        // Double negation casts to either true or false, used in .html
        this.isManagedUser = !!this.core.user.value.managedUser;
        this.notifications = this.notificationService.notifications;
        this.notificationUnreadCount = this.notificationService.notificationsUnreadCount;

        this.core.network.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.networksLogo = this.network.name.substring(0, 1).toUpperCase();
                this.cdr.detectChanges();
            },
        });

        this.networks = this.core.networks.pipe(
            map((networks: CompanyMap) => Object.values(networks).sort(this.helpers.sortAlphabetically('name'))),
            tap(() => this.cdr.detectChanges()),
            takeUntil(this.onDestroy)
        );

        this.core.user.pipe(takeUntil(this.onDestroy)).subscribe({
            next: user => {
                if (user.emailVerified) {
                    this.verifyEmailUnreadCount = 0;
                } else {
                    this.verifyEmailUnreadCount = 1;
                }

                this.emailVerified();
                this.cdr.detectChanges();
            },
        });

        this.windowListener.size.pipe(takeUntil(this.onDestroy)).subscribe({
            next: ({ x }) => {
                this.screenWidth = x;
                this.cdr.detectChanges();
            },
        });

        this.v3Discussion.unreadDiscussions.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.cdr.detectChanges(),
        });

        this.core.permission.changed
            .pipe(
                takeUntil(this.onDestroy),
            )
            .subscribe({
                next: () => {
                    this.cdr.detectChanges();
                },
            });

        this.feedStatic.unreadPostCounts.pipe(takeUntil(this.onDestroy)).subscribe({
            next: (unreadCounts: { [cid: string]: number }) => {
                this.totalUnread = 0;
                for (const workspaceId of Object.keys(unreadCounts)) {
                    if (!workspaceId || !unreadCounts[workspaceId]) {
                        continue;
                    }
                    this.totalUnread += unreadCounts[workspaceId] || 0;
                }
                this.cdr.detectChanges();
            },
        });
        
        this.userApps = this.appService.getUserApps().value;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    get inFeed(): boolean {
        return this.router.url.includes('feed');
    }

    get currentNetworkId(): string {
        return this.core.network.value?._id;
    }

    get network(): Company {
        return this.core.network.value;
    }

    get unreadFeedPostCounts() {
        return this.feedStatic.unreadPostCounts.value;
    }

    get unreadDiscussionsCount(): number {
        return Object.keys(this.v3Discussion.unreadDiscussions.value)?.length || null;
    }

    async logout() {
        await this.core.logout();
    }

    newNetwork() {
        this.zone.run(() => {
            this.matDialog.open(NewNetworkDialogComponent, { panelClass: 'dialog-padding' });
        });
    }

    showMyProfile() {
        this.zone.run(() => {
            this.matDialog.open(ProfileDialogComponent, {
                panelClass: 'profile-dialog',
                height: '600px',
                width: '800px',
                maxWidth: 800,
            });
        });
    }

    get debugModeEnabled() {
        return !!localStorage.getItem('debug');
    }

    get userPermissions(): Observable<any> {
        return this.core.user.pipe(map(u => u.permissions));
    }

    onScroll() {
        const el = this.notificationContainer.nativeElement;
        if (el.clientHeight + el.scrollTop >= el.scrollHeight) {
            this.loadMoreNotifications();
        }
    }

    markAllNotificationsRead() {
        this.notificationService.markAllNotificationsRead();
        this.notificationComponents.forEach(notification => notification.markAsRead());
        this.verifyEmailUnreadCount = 0;
    }

    markAllNotificationsSeen() {
        this.notificationService.markAllNotificationsSeen();
    }

    loadMoreNotifications() {
        this.notificationService.loadMoreNotifications();
    }

    contextualAdd(view: 'discussion' | 'events' | 'feed' | 'people') {
        if ((view === 'people' && !this.permissions.isNetworkInviter) || this.permissions.isNetworkGuest) {
            return;
        }

        this.vibrate();

        if (location.href.includes(view)) {
            this.showContextualAdd = this.showContextualAdd ? null : view;
        }
    }

    createPost() {
        this.feedStatic.focusCreate.next(true);
        this.showContextualAdd = null;
    }

    createDiscussion(type: 'activity' | 'event' | 'group' | 'private') {
        this.v3Discussion.openQuickAddMenu.next(type);
        this.showContextualAdd = null;
        this.cdr.detectChanges();
    }

    createCalendar(): void {
        const newCalendar = {
            name: 'New Calendar',
            color: '#438EB9',
        } as Calendar;
        let newCalendarId = '';

        this.eventsService
            .createCalendar(newCalendar)
            .pipe(
                tap(calendar => (newCalendarId = calendar._id)),
                switchMap(() => this.core.calendars.pipe(skip(1))),
                take(1)
            )
            .subscribe({
                next: () => {
                    this.zone.run(() => {
                        this.matDialog.open(EditCalendarComponent, {
                            data: newCalendarId,
                            maxHeight: 1000,
                            maxWidth: 900,
                        });
                    });
                    this.showContextualAdd = null;
                },
                error: error => console.error('Could not create calendar', error),
            });
    }

    createEvent() {
        this.sideNav.create(EventSidenavComponent, {
            create: {
                date: _moment(),
            },
        });
        this.showContextualAdd = null;
    }

    createActivityEvent() {
        this.eventsService.openCreateMenu.next();
        this.showContextualAdd = null;
    }

    invitePeople() {
        this.zone.run(() => {
            this.matDialog.open(InvitePageComponent, {
                height: 'fit-content',
                maxHeight: '500px',
                width: '500px',
                panelClass: 'network-invite-dialog',
            });
        });
    }

    vibrate() {
        Haptics.impact({ style: ImpactStyle.Light });
    }

    onThemeChange(event) {
        this.userService
            .setUserTheme(event.value)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                error: error => console.error('error happened while updating user theme', error),
            });
    }

    selectedTheme(): UserThemes {
        const theme = this.user?.value?.globalSettings?.theme;
        return theme ? theme : 'light';
    }

    inAppsBeta(): boolean {
        const hasUserApp = !!this.appService.getUserApps().value?.length;
        const hasWorkspaceApp = !!this.appService.getWorkspaceApps().value?.length;
        const hasInsight = !!this.insightService.insights?.length;

        if (hasUserApp || hasWorkspaceApp || hasInsight) {
            return true;
        }

        return localStorage.getItem('apps-beta') === 'true';
    }

    onFeed(): boolean {
        return this.router.url.split('/')[1]?.includes('feed') || false;
    }

    /** Determine if we are in the apps section of Hailer (app management view, single app view or insights view) */
    inApps(): boolean {
        return ['apps', 'insight'].includes(this.router.url.split('/')[1] || '');
    }

    private emailVerified() {
        const uid = this.core.user.value.id;
        const cid = this.core.network.value._id;

        this.verifyEmailNotification = {
            _id: '',
            uid,
            type: 'hailer.verifyEmail',
            cid,
            clicked: false,
            seen: false,
            created: Date.now(),
            from: uid,
            meta: {
                company: {
                    description: 'Hailer',
                },
            },
        };
    }
}
