<!-- @format -->

<app-button
    type="icon"
    color="icon-default"
    size="medium"
    hailerIconName="hailer-see-more"
    [dataCy]="feedPost._id + '-post-options-button'"
    [menuTriggerFor]="menu"
    tippyText="{{ 'feed.feed_options.options' | transloco }}"
    ariaLabel="{{ 'feed.feed_options.aria-options' | transloco }}"></app-button>

<mat-menu #menu="matMenu" [overlapTrigger]="false" xPosition="before">
    <button
        data-cy="feed-reply-privately-button"
        *ngIf="
            feedPost.allowReply &&
            feedPost.uid !== user.value._id &&
            !people.isMockUser(feedPost.uid) &&
            core.users.value[feedPost.uid] &&
            (core.permission.discussion.start.private(
                feedPost.uid,
                core.getUser(feedPost.uid).networks | workspaceIdsToWorkspaces,
                team.getUserTeamsFromAllWorkspaces(feedPost.uid)
            ) | hasPermission)
        "
        mat-menu-item
        (click)="replyPrivately()">
		<mat-icon svgIcon="hailer-reply-privately"></mat-icon>
		{{ 'feed.feed_options.reply' | transloco }}
	</button>
	<button data-cy="copy-post-url-button" mat-menu-item (click)="copyPostURL()">
		<mat-icon svgIcon="hailer-link"></mat-icon>
		{{ 'feed.feed_options.copy_url' | transloco }}
	</button>
	<button [disabled]="feed.editingPost | async" *ngIf="core.permission.feed.post.edit(feedPost) | hasPermission" data-cy="edit-post-button" id="edit"
		mat-menu-item (click)="openEdit.emit()">
		<mat-icon svgIcon="hailer-edit"></mat-icon>
		{{ 'feed.feed_options.edit' | transloco }}
	</button>
	<button *ngIf="core.permission.feed.post.pin(feedPost.cid) | hasPermission" data-cy="pin-post-button" id="pin" mat-menu-item
		(click)="pinPost()">
		<mat-icon svgIcon="hailer-pin"></mat-icon>
		{{feedPost.pinned ? ( 'feed.feed_options.unpin' | transloco ) : ( 'feed.feed_options.pin' | transloco )}}
    </button>
    <!-- Hide post button -->
    <button *ngIf="feedPost.pinned || postHidden" mat-menu-item (click)="toggleHiddenState()">
        <mat-icon [svgIcon]="postHidden ? 'hailer-show' : 'hailer-hide'"></mat-icon>
        {{postHidden ? ( 'feed.feed_options.show' | transloco ) : ( 'feed.feed_options.hide' | transloco )}}
    </button>
    <!-- <button data-cy="star-post-button" id="star" mat-menu-item
    (click)="starPost()">
    <mat-icon [svgIcon]="feedPost.isStarred ? 'hailer-star-fill' : 'hailer-star'" class="starred-icon" [ngClass]="feedPost.isStarred ? 'active' : 'inactive'"></mat-icon>
    {{feedPost.isStarred ? ( 'feed.feed_options.unstar' | transloco ) : ( 'feed.feed_options.star' | transloco )}}
</button> -->
    <button *ngIf="core.permission.feed.post.remove(feedPost.cid, feedPost.uid) | hasPermission" data-cy="delete-post-button" id="delete" mat-menu-item (click)="removePost()" class="destructive-text">
		<mat-icon svgIcon="hailer-trash" class="warn"></mat-icon>
		{{ 'feed.feed_options.delete' | transloco }}
	</button>
</mat-menu>