/** @format */

import { Component, Input } from '@angular/core';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { passwordStrengthMeterAnimations } from './password-strength-meter.animations';
import { passwordConfig } from 'app/_helpers/password-helper';

@Component({
    selector: 'app-password-strength-meter',
    templateUrl: './password-strength-meter.component.html',
    styleUrls: ['./password-strength-meter.component.scss'],
    animations: passwordStrengthMeterAnimations,
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class PasswordStrengthMeterComponent {
    @Input() passwordScore: number;
    @Input() passwordInputFocused: boolean;

    translocoService: TranslocoService;

    // Used in HTML
    minLength = passwordConfig.minLength;

    colors = ['deep-red', 'red', 'yellow', 'green', 'deep-green'];

    // Returns transloco keys
    getPasswordStrengthLabel(passwordScore: number): string {
        const baseString = 'shared.password-strength-meter.';
        switch (passwordScore) {
            case 4:
                return `${baseString}very_strong`;
            case 3:
                return `${baseString}strong`;
            case 2:
                return `${baseString}average`;
            case 1:
                return `${baseString}weak`;
            default:
                return `${baseString}very_weak`;
        }
    }
}
