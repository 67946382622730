/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { FileUploaderComponent } from './../../shared/file-uploader/file-uploader.component';
import { FeedService } from '../feed.service';
import { FeedPostCommentSend } from '@app/models';
import { PeopleService } from 'app/people/people.service';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    selector: 'app-feed-post-comment-write',
    templateUrl: './feed-post-comment-write.component.html',
    styleUrls: ['./feed-post-comment-write.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'feed', alias: 'feed' } }],
})
export class FeedPostCommentWriteComponent implements OnInit, OnDestroy {
    @ViewChild('uploader', { static: true }) uploader: FileUploaderComponent;

    @Input() feedPostId: string;

    newComment = new FeedPostCommentSend();
    sending = false;
    focusState = new BehaviorSubject<boolean>(false);
    keepOpen = false;

    uploadInProgress: boolean;

    private onDestroy = new Subject<void>();

    constructor(
        private feedService: FeedService,
        private peopleService: PeopleService,
        private dialogHelperService: DialogHelperService,
        private cdr: ChangeDetectorRef,
        private rpc: RPCService,
    ) {}

    ngOnInit() {}

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();

        this.uploader.uploadInProgress.pipe(takeUntil(this.onDestroy)).subscribe({
            next: uploadInProgress => {
                this.uploadInProgress = uploadInProgress;
                this.cdr.detectChanges();
            },
        });
    }

    isFocused(): boolean {
        return this.focusState.value || this.newComment.text.length > 0 || this.uploader.fileIds.value?.length > 0 || this.keepOpen;
    }

    onEnter($event) {
        if (true) {
            // Send with Enter check
            $event.preventDefault();
            this.addComment();
        }
    }

    async addComment(): Promise<void> {
        const files = this.uploader.fileIds.value;
        if (this.uploader.inProgress()) {
            this.showError('File upload in progress, please wait.');
            return;
        }

        this.newComment.files = files;

        this.sending = true;

        try {
            await this.rpc.requestAsync('wall2.save_comment', [this.newComment, this.feedPostId]);
            this.newComment = new FeedPostCommentSend();
        } catch (error) {
            console.error('Failed to create comment', error);
        }

        this.sending = false;
        this.cdr.detectChanges();
        this.uploader.reset();
        this.keepOpen = false;
    }

    onFocus(keepOpen = false) {
        this.keepOpen = keepOpen;
        this.focusState.next(true);
        this.cdr.detectChanges();
    }

    onBlur() {
        setTimeout(() => {
            this.focusState.next(false);
            this.cdr.detectChanges();
        }, 200);
    }

    private showError(errorMsg: string) {
        const confirm = `Ok`;
        const content = errorMsg;
        const title = `Error`;

        this.dialogHelperService.showError(confirm, content, title).pipe(take(1)).subscribe();
    }
}
