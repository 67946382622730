import { useEffect, useState, useRef } from "react";
import { useInsight } from "./use-insight";

declare const Chart: any;

export default function PivotChart() {
  const { api, chart, data, theme } = useInsight();

  const [chartInstance, setChartInstance] = useState<typeof Chart>(null);

  const canvasRef = useRef(null);

  const pivotData = data.pivot;

  useEffect(() => {
    if (!pivotData || !window['Chart']) {
      console.log('No chart on window. Library chart.js must be loaded in the page.');
      return;
    }

    if (typeof Chart.version !== 'string' || !Chart.version.startsWith('4.')) {
      console.warn('Found Chart.js with unacceptable version:', Chart.version, ', expecting 4.x');
    }

    chartInstance?.destroy();

    const colorPalette = ['#0E6EB8', '#FB8C00', '#7B1FA2', '#4CAF50', '#D50000', '#6D4C41'];

    const oldChart = Chart.getChart(canvasRef?.current);

    if (oldChart) {
      // workaround when react reuses the old element, not sure how to fix this properly
      oldChart?.destroy?.();
    }

    let datasets = [];

    if (pivotData.rows.length && (!pivotData.cols.length || !pivotData.cols[0]?.key )) {
      // Drawing totals from rows
      datasets = pivotData.rows.map((row) => ({
        data: [row.total],
        label: row.key,
        fill: false,
        tension: 0.5,
        borderColor: row.color + 'c0',
        backgroundColor: row.color + 'a4',
        hoverBackgroundColor: row.color,
      }));
    } else {
      // Drawing 2d pivot
      datasets = pivotData.table.map((row, index) => ({
        data: pivotData.table[index],
        label: pivotData.rows[index].key,
        fill: false,
        tension: 0.5,
        borderColor: colorPalette[index % colorPalette.length] + 'c0',
        backgroundColor: colorPalette[index % colorPalette.length] + 'a4',
        hoverBackgroundColor: colorPalette[index % colorPalette.length],
      }));
    }

    setChartInstance(new Chart(canvasRef.current, {
      type: chart.type,
      data: {
        labels: pivotData.cols.map(col => col.key),
        datasets,
      },
      options: {
        maintainAspectRatio: false,
        animation: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: theme === 'dark' ? '#EDEFF3' : '#4f5053', 
            },
            grid: {
              color: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            },
          },
          x: {
            ticks: {
              color: theme === 'dark' ? '#EDEFF3' : '#4f5053',
            },
            grid: {
              color: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            },
          }
        },
        plugins: {
          legend: {
            display: datasets.length <= 15,
            position: 'right',
            labels: {
                color: theme === 'dark' ? '#EDEFF3' : '#4f5053',
            }
          }
        }
      }
    }));
  }, [pivotData, chart.type, theme])

  return (
  <div className="chart">
    <div className="insight-chart-toggle-wrapper">
    <div>
        <select className="insight-select" value={chart.type} onChange={(event: any) => { api.chart.setType(event.target.value) }}>
          <option value="line">Line</option>
          <option value="bar">Bar</option>
        </select>
      </div>
      <canvas ref={canvasRef}></canvas>
    </div>
  </div>
  )
}
