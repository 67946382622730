/** @format */

import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[appAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
    @Input('appAutofocus') focusOnPhone: boolean;
    @Input('appAutofocusDelay') delay: number;

    constructor(private el: ElementRef) {}

    ngAfterViewInit(): void {
        this.delay = this.delay || 0;

        if (this.focusOnPhone || !this.isPhone) {
            window.setTimeout(() => {
                const targetElement = this.getTargetElement(this.el.nativeElement);
                if (targetElement) {
                    targetElement.focus();
                }
            }, this.delay);
        }
    }

    private get isPhone(): boolean {
        return window.innerWidth <= 600;
    }

    private getTargetElement(hostElement: HTMLElement): HTMLElement | null {
        // When setting focus to ButtonComponent, we need to set it to the button element inside the app-button
        if (hostElement.tagName.toLowerCase() === 'app-button') {
            return hostElement.querySelector('button');
        }
        return hostElement;
    }
}
