/** @format */

import { ChangeDetectorRef, Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { CoreService } from 'app/_services/core.service';
import { OidcService, Provider } from 'app/_services/oidc.service';
import { authConfigMap } from '../../../../sso-config';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
                alias: 'public',
            },
        },
    ],
})
export class RedirectComponent implements OnInit, OnDestroy {
    providerId: Provider;

    providerIds: Provider[] = [];

    idToken: string;

    private onDestroy = new Subject<void>();

    constructor(
        private auth: CoreService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private oidc: OidcService
    ) {}

    ngOnInit(): void {
        this.providerIds = Object.keys(authConfigMap) as Provider[];
        this.providerId = this.router.url.split('/redirect/')[1] as Provider;
        if (!this.providerIds.includes(this.providerId)) {
            void this.router.navigate(['/login']);
        }
        this.checkSignInOpenId();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    signIn(providerId: Provider): void {
        this.oidc.signIn(providerId);
    }

    signOut(): void {
        this.oidc.signOut();
    }

    private checkSignInOpenId(): void {
        this.idToken = this.oidc.getIdToken();

        if (!this.idToken) {
            this.signIn(this.providerId);
            return;
        }

        this.auth
            .loginOpenId(this.idToken)
            .pipe(takeUntil(this.onDestroy))
            .subscribe(
                () => {
                    void this.router.navigate(['/discussions']);
                },
                (error: any) => {
                    console.warn('oidc login error: ', error.msg)
                    this.signOut();
                    this.cdr.detectChanges();
                }
            );
    }
}
