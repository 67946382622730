/** @format */

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FeedPost } from '@app/models';
import { FeedService } from 'app/feed/feed.service';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    selector: 'app-feed-post-dialog',
    templateUrl: './feed-post-dialog.component.html',
    styleUrls: ['./feed-post-dialog.component.scss'],
    providers: [FeedService],
})
export class FeedPostDialogComponent implements OnInit, OnDestroy {
    postId: string;
    post?: FeedPost;
    private onDestroy = new Subject<void>();

    constructor(
        private dialogRef: MatDialogRef<FeedPostDialogComponent>,
        private feed: FeedService,
        private rpc: RPCService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {
        this.postId = this.data;
    }

    ngOnInit(): void {
        void this.setPost();
        this.feed.postUpdated
            .pipe(
                takeUntil(this.onDestroy),
                filter(postId => postId === this.postId)
            )
            .subscribe({
                next: () => void this.setPost(),
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    private async setPost(): Promise<void> {
        this.post = this.feed.findFeedPost(this.postId);
        if (this.post) {
            return;
        }

        const posts = await this.feed.loadPosts({ postIds: [this.postId], includeScheduled: true });
        this.post = posts?.[0];
    }
}
