/** @format */

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { PeopleService } from 'app/people/people.service';
import { CoreService } from 'app/_services/core.service';
import { RoutingService } from 'app/_services/routing.service';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    selector: 'app-new-network-dialog',
    templateUrl: './new-network-dialog.component.html',
    styleUrls: ['./new-network-dialog.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class NewNetworkDialogComponent implements OnInit {
    newHailerName: string;
    creating: boolean;
    defaultToNetwork = false;
    isManagedUser: boolean;

    constructor(
        public dialogRef: MatDialogRef<NewNetworkDialogComponent>,
        private rpc: RPCService,
        private core: CoreService,
        private peopleService: PeopleService,
        private snackBar: MatSnackBar,
        private route: RoutingService,
        private translocoService: TranslocoService
    ) {}

    ngOnInit() {
        this.creating = false;
        this.isManagedUser = !!this.core.user.value.managedUser;
    }

    createNewNetwork(newHailerName: string, switchNetwork: boolean) {
        this.creating = true;

        this.rpc.request('v2.network.create', [newHailerName])
            .pipe(take(1))
            .subscribe({
                next: (result: string) => {
                    this.closeNewNetworkDialog();
                    this.snackBar.open(
                        newHailerName + this.translocoService.translate('shared.new-network-dialog.snackbar.created'),
                        this.translocoService.translate('shared.new-network-dialog.snackbar.ok'),
                        { duration: 2500 }
                    );

                    if (this.defaultToNetwork) {
                        this.setDefaultNetwork(result);
                    }

                    if (switchNetwork) {
                        this.switchToNetwork(result);
                    }
                },
                error: (error: any) => {
                    console.error('Error creating new workspace: ', error);
                },
            });
    }

    closeNewNetworkDialog() {
        this.dialogRef.close();
    }

    private switchToNetwork(networkId: string) {
        this.core.switchNetwork(networkId).subscribe({
            next: () => {
                this.route.navigate(['/activities']);
            },
            error: (error: any) => console.error('Error switching workspace', error),
        });
    }

    private setDefaultNetwork(networkId: string) {
        if (networkId) {
            this.peopleService.changeUserInfo('default_company', networkId).subscribe({
                error: (error: any) => console.error('error while setting default company', error),
            });
        }
    }
}
