<!-- @format -->

<!--Filters toolbar-->
<div class="filters-toolbar" [class.sticky-filters]="selectedFilterLocal.value.value">
	<!-- TODO: add aria-label to clearing filters. Did not do now since the filtering header will be redone to a possibly different format -->
	<button
		mat-flat-button
		*ngFor="let filter of filters"
		(click)="setFilter(filter)"
		class="container align-items-center"
		[class.selected-filter]="filter.value === selectedFilterLocal.value.value"
		[ngSwitch]="filter.value"
		attr.aria-label="{{ 'discussion.list.filter' | transloco }} {{ 'discussion.list.' + filter.value | transloco }} {{
			'discussion.list.filter-discussions' | transloco
		}}">
		<mat-icon
			*ngSwitchCase="'starred'"
			data-cy="starred-filter-button"
			svgIcon="hailer-star"
			tippy="{{ 'discussion.list.starred_button_hover' | transloco }}"></mat-icon>
		<mat-icon
			*ngSwitchCase="'unread'"
			data-cy="unread-filter-button"
			svgIcon="hailer-unread-messages"
			tippy="{{ 'discussion.list.unread_button_hover' | transloco }}"></mat-icon>
		<mat-icon
			*ngSwitchCase="'private'"
			data-cy="private-filter-button"
			svgIcon="hailer-user"
			tippy="{{ 'discussion.list.private_button_hover' | transloco }}"></mat-icon>
		<mat-icon
			*ngSwitchCase="'groups'"
			data-cy="group-filter-button"
			svgIcon="hailer-group"
			tippy="{{ 'discussion.list.group_button_hover' | transloco }}"></mat-icon>
		<mat-icon
			*ngSwitchCase="'activities'"
			data-cy="activity-filter-button"
			svgIcon="hailer-activity"
			tippy="{{ 'discussion.list.activity_button_hover' | transloco }}"></mat-icon>
		<mat-icon
			*ngSwitchCase="'events'"
			data-cy="event-filter-button"
			svgIcon="hailer-events"
			tippy="{{ 'discussion.list.event_button_hover' | transloco }}"></mat-icon>
		<mat-icon style="display: none" tippy="{{ 'discussion.list.remove_filter_button_hover' | transloco }}" svgIcon="hailer-x"></mat-icon>
	</button>
</div>
