/** @format */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Capacitor } from '@capacitor/core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.deployed) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(async () => {
        /* Don't register sw on native platforms. It breaks capacitor plugins and we handle the same things with capacitor. */
        if ('serviceWorker' in navigator && !Capacitor.isNativePlatform()) {
            try {
                const registration = await navigator.serviceWorker.register('/assets/service-worker.js', { updateViaCache: 'none', type: 'classic' });

                if (registration.installing) {
                    console.log('Service worker installing');
                } else if (registration.waiting) {
                    console.log('Service worker installed');
                } else if (registration.active) {
                    console.log('Service worker active');
                }
            } catch (error) {
                console.error('Error registering SW (inner catch in main.ts:', error.message, error.stack);
                const errorStr = 'Storage access is restricted in this context due to user settings or private browsing mode';
                if (error.message.includes(errorStr)) {
                    localStorage.setItem('storageRestricted', 'true');
                }
            }
        }
    })
    .catch(err => console.error('Error installing SW (outer catch in main.ts): ', err.message, err.stack));
