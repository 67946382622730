/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContenteditableValueAccessorModule } from '@tinkoff/angular-contenteditable-accessor';
import { AngularResizeEventModule } from 'angular-resize-event';
import { TranslocoModule } from '@ngneat/transloco';

import { SharedModule } from '@app/shared/shared.module';
import { MentionModule } from 'app/angular-mentions';
import { ExtendedMarkdownModule } from 'app/extended-markdown/extended-markdown.module';
import { DiscussionDetailResolverService } from 'app/_services/discussion-detail-resolver.service';
import { DiscussionContainerComponent } from './discussion-container/discussion-container.component';
import { DiscussionInputComponent } from './discussion-input/discussion-input.component';
import { EventDataComponent } from './event-data/event-data.component';
import { MessageActivityUpdateComponent } from './message-activity-update/message-activity-update.component';
import { MessageEventAttendComponent } from './message-event-attend/message-event-attend.component';
import { MessageMeetComponent } from './message-meet/message-meet.component';
import { MessageViewComponent } from './message-view/message-view.component';
import { MuteDiscussionButtonComponent } from './mute-discussion-button/mute-discussion-button.component';
import { UserPictureComponent } from './user-picture/user-picture.component';
import { MessageUserComponent } from './message-user/message-user.component';
import { MessagesContainerComponent } from './messages-container/messages-container.component';
import { DiscussionPickerComponent } from './discussion-picker/discussion-picker.component';
import { DiscussionFilterHeaderComponent } from './discussion-filter-header/discussion-filter-header.component';
import { DiscussionListItemComponent } from './discussion-list-item/discussion-list-item.component';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { DiscussionSearchComponent } from './discussion-search/discussion-search.component';
import { ForwardToDiscussionsDialogComponent } from './forward-to-discussions-dialog/forward-to-discussions-dialog.component';
import { ButtonsModule } from 'app/buttons/buttons.module';
import { MessageLinkComponent } from './message-link/message-link.component';
import { PollModule } from 'app/poll/poll.module';
import { PipesModule } from 'app/pipes/pipes.module';

@NgModule({
    declarations: [
        MessageViewComponent,
        DiscussionInputComponent,
        EventDataComponent,
        MessageEventAttendComponent,
        MessageActivityUpdateComponent,
        MuteDiscussionButtonComponent,
        DiscussionContainerComponent,
        MessageMeetComponent,
        UserPictureComponent,
        MessageUserComponent,
        MessagesContainerComponent,
        DiscussionPickerComponent,
        DiscussionListItemComponent,
        DiscussionFilterHeaderComponent,
        DiscussionSearchComponent,
        ForwardToDiscussionsDialogComponent,
        MessageLinkComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        ContenteditableValueAccessorModule,
        ExtendedMarkdownModule,
        MentionModule,
        AngularResizeEventModule,
        TranslocoRootModule,
        TranslocoModule,
        ButtonsModule,
        PollModule,
        PipesModule
    ],
    providers: [DiscussionDetailResolverService],
    exports: [
        MessageViewComponent,
        DiscussionContainerComponent,
        DiscussionInputComponent,
        MuteDiscussionButtonComponent,
        UserPictureComponent,
        DiscussionPickerComponent,
        DiscussionListItemComponent,
        DiscussionFilterHeaderComponent,
        DiscussionSearchComponent,
    ],
})
export class DiscussionSharedModule {}
