/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, Optional } from '@angular/core';
import { App } from 'app/_models/app.model';
import { AppService } from 'app/_services/app.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PermissionListMember } from 'app/_models/member.model';
import { SideNavService } from 'app/_services/side-nav.service';
import { TranslocoService } from '@ngneat/transloco';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-app-permissions',
    templateUrl: './app-permissions.component.html',
    styleUrls: ['./app-permissions.component.scss'],
})
export class AppPermissionsComponent {
    @Input() app: App;

    selectorMembers: PermissionListMember[] = [];

    constructor(
        public sideNav: SideNavService,
        public translate: TranslocoService,
        public appService: AppService,
        private rpc: RPCService,
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) @Optional() public data?: App,
        @Optional() private dialogRef?: MatDialogRef<AppPermissionsComponent>
    ) {
        if (data && dialogRef) {
            this.app = data;
            this.selectorMembers = this.app.members.map(member => ({ ...member, allowRemove: true }));
        }
    }

    close(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        } else {
            this.sideNav.clear();
        }
    }


    async memberAdd(app: App, memberId: string): Promise<void> {
        await this.appService.memberAdd(app, memberId);
        await this.updatePermissions(app._id);
    }

    async memberRemove(app: App, memberId: string): Promise<void> {
        await this.appService.memberRemove(app, memberId);
        await this.updatePermissions(app._id);
    }

    async updatePermissions(appId: string): Promise<void> {
        const workspaceId = this.app.cid;

        const apps = (await this.rpc.requestAsync('v3.app.list', [workspaceId])) as App[];

        const app = apps.find(app => app._id === appId) as App | null;

        if (!app) {
            return;
        }

        this.app.members = app.members;
        this.selectorMembers = this.app.members.map(member => ({ ...member, allowRemove: true }));
        this.cdr.detectChanges();
    }
}
