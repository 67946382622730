<!-- @format -->

<app-button
	[dataCy]="dataCy"
	type="icon"
	color="icon-white-all-themes"
	size="medium"
	[ariaLabel]="'shared.global-search.aria-label.search-icon' | transloco"
	hailerIconName="hailer-search"
	(clickEvent)="openModal(); focusModalSearchInput()"
	[tippyText]="'shared.global-search.tippy.search' | transloco"
	[isDisabled]="permissions.isNetworkGuest"></app-button>

<div class="overlay" (click)="closeModal()" *ngIf="modalOpen"></div>

<div class="mat-elevation-z16 modal-container" *ngIf="modalOpen">
	<div class="modal-header">
		<app-button
			type="icon"
			color="icon-default"
			size="medium"
			matIconClass="back-arrow"
			[ariaLabel]="'shared.global-search.aria-label.go-back' | transloco"
			hailerIconName="hailer-tailed-arrow"
			(clickEvent)="closeModal()"
			[tippyText]="'shared.global-search.tippy.go-back' | transloco"></app-button>
	</div>

	<div class="mobile-modal-header">
		{{ 'shared.global-search.global_search' | transloco }}
		<app-button
			type="icon"
			color="icon-default"
			size="medium"
			[ariaLabel]="'shared.global-search.aria-label.go-back' | transloco"
			hailerIconName="hailer-x"
			(clickEvent)="closeModal()"
			[tippyText]="'shared.global-search.tippy.go-back' | transloco"></app-button>
	</div>

	<div class="results-container">
		<mat-form-field class="global-search-field full-width" appearance="standard" (click)="$event.stopPropagation()">
			<input
				data-cy="global-search-input"
				#modalSearchInput="matInput"
				type="text"
				placeholder="{{ 'shared.global-search.search_placeholder' | transloco }}"
				matInput
				[formControl]="searchControl" />
		</mat-form-field>

		<mat-option disabled="true" style="padding: 0 !important">
			<div class="header-item">
				<h4>{{ getHeaderText() }}</h4>
				<app-button
					type="icon"
					color="icon-accent"
					size="medium"
					matIconClass="no-margin"
					[ariaLabel]="
						filterBar
							? ('shared.global-search.aria-label.open-filters' | transloco)
							: ('shared.global-search.aria-label.close-filters' | transloco)
					"
					hailerIconName="hailer-filter-posts"
					(clickEvent)="filterBar = !filterBar"
					[tippyText]="
						filterBar ? ('shared.global-search.tippy.filters' | transloco) : ('shared.global-search.tippy.close-filters' | transloco)
					"></app-button>
			</div>
			<div *ngIf="filterBar" class="header-item">
				<!-- TODO make this into a template with app-buttons -->
				<button
					*ngFor="let filter of allFilters; trackBy: trackByFn"
					mat-icon-button
					(click)="updateFilters(filter)"
					class="filter-button"
					[ngClass]="{ 'selected-filter': filters[filter] }">
					<ng-container [ngSwitch]="filter">
						<mat-icon
							*ngSwitchCase="'users'"
							svgIcon="hailer-group"
							tippy="{{ 'shared.global-search.tippy.users' | transloco }}"></mat-icon>
						<mat-icon
							*ngSwitchCase="'activities'"
							svgIcon="hailer-activity"
							tippy="{{ 'shared.global-search.tippy.activities' | transloco }}"></mat-icon>
						<mat-icon
							*ngSwitchCase="'events'"
							svgIcon="hailer-calendar"
							tippy="{{ 'shared.global-search.tippy.events' | transloco }}"></mat-icon>
						<mat-icon
							*ngSwitchCase="'messages'"
							svgIcon="hailer-messages"
							tippy="{{ 'shared.global-search.tippy.messages' | transloco }}"></mat-icon>
						<mat-icon *ngSwitchCase="'feed'" svgIcon="hailer-feed" tippy="{{ 'shared.global-search.tippy.feed' | transloco }}"></mat-icon>
						<mat-icon
							*ngSwitchCase="'files'"
							tippy="{{ 'shared.global-search.tippy.files' | transloco }}"
							svgIcon="hailer-files"></mat-icon>
						<mat-icon *NgSwitchDefault svgIcon="hailer-warning"></mat-icon>
					</ng-container>
				</button>
			</div>
		</mat-option>

		<!-- Search results -->
		<!-- Users -->
		<div>
			<mat-optgroup label="{{ 'shared.global-search.label.users' | transloco }}" *ngIf="searchData.users?.length">
				<mat-option
					data-cy="global-search-users-options"
					*ngFor="let item of searchData.users; trackBy: trackById"
					[value]="{ 'type': 'users', 'id': item._id }"
					(onSelectionChange)="searchSelect($event.source.value.type, $event.source.value.id); closeModal()">
					<div class="item-with-image">
						<app-user-profile-picture [userId]="item._id" [size]="40"></app-user-profile-picture>
						<div class="vertical-menu-flex">
							<div>
								<span>
									<strong>{{ getUser(item._id).display_name }}</strong>
								</span>
							</div>
							<div class="ns-text-small-1 text-med flex-align-center">
								<span>
									{{ 'shared.global-search.last_seen' | transloco }}
									{{ getUser(item._id).lastSeen | timeago : 'short' : false | async }}
								</span>
							</div>
						</div>
					</div>
				</mat-option>
			</mat-optgroup>
		</div>

		<!-- Activities -->
		<div>
			<mat-optgroup label="{{ 'shared.global-search.label.activities' | transloco }}" *ngIf="searchData.activities?.length">
				<mat-option
					data-cy="global-search-activities-options"
					*ngFor="let item of searchData.activities; trackBy: trackById"
					[value]="{ 'type': 'activities', 'id': item._id }"
					(onSelectionChange)="searchSelect($event.source.value.type, $event.source.value.id); closeModal()">
					<div class="vertical-menu-flex">
						<span>
							<strong>{{ item.name }}</strong>
						</span>
						<span> {{ getPhaseName(item.process, item.currentPhase) | async }} </span>
						<div class="ns-text-small-1 text-med flex-align-center">
							<span>
								{{ 'shared.global-search.added' | transloco }} <strong> {{ item.created | date : 'dd.MM.yyyy' }} </strong>
							</span>
							<span class="dash"> - </span>
							<span>
								{{ 'shared.global-search.updated' | transloco }} <strong>{{ item.updated | date : 'dd.MM.yyyy' }}</strong>
							</span>
						</div>
						<div class="ns-text-small-1 text-med flex-align-center">
							<span class="link-font" (click)="toggleFollow(item); $event.stopPropagation()">
								{{
									item.followers.includes(me._id) ? ('shared.global-search.leave' | transloco) : ('shared.global-search.join' | transloco)
								}}
							</span>
							<span class="dash"> - </span>
							<span>{{ 'shared.global-search.followers' | transloco }}{{ item.followers.length }} </span>
						</div>
					</div>
				</mat-option>
			</mat-optgroup>
		</div>

		<!-- Events -->
		<div>
			<mat-optgroup label="{{ 'shared.global-search.label.events' | transloco }}" *ngIf="searchData.events?.length">
				<mat-option
					data-cy="global-search-events-options"
					*ngFor="let item of searchData.events; trackBy: trackById"
					[value]="{ 'type': 'events', 'id': item._id }"
					(onSelectionChange)="searchSelect($event.source.value.type, $event.source.value.id); closeModal()">
					<div class="vertical-menu-flex">
						<span>
							<strong>{{ item.title }}</strong>
						</span>
						<span>{{ item.calendar.name }}</span>
						<ng-container *ngIf="!item.allDay; else allDay">
							<span class="ns-text-small-1 text-med" *ngIf="item.start !== item.end">{{ 'shared.global-search.from' | transloco }}
                                <strong> {{ item.start | date : 'dd.MM.yyyy, hh:mm' }}</strong>
								{{ 'shared.global-search.to' | transloco }}
                                <strong> {{ item.end | date : 'dd.MM.yyyy, hh:mm' }}</strong>
							</span>
							<span class="ns-text-small-1 text-med" *ngIf="item.start === item.end">{{ 'shared.global-search.at' | transloco }}
                                <strong> {{ item.start | date : 'dd.MM.yyyy, hh:mm' }} </strong>
							</span>
						</ng-container>
						<ng-template #allDay>
							<span class="ns-text-small-1 text-med" *ngIf="item.start !== item.end">{{ 'shared.global-search.from' | transloco }}
                                <strong>{{ item.start | date : 'dd.MM.yyyy' }} </strong>
								{{ 'shared.global-search.to' | transloco }}
                                <strong>{{ item.end | date : 'dd.MM.yyyy' }}</strong>
							</span>
							<span class="ns-text-small-1 text-med" *ngIf="item.start === item.end">{{ 'shared.global-search.on' | transloco }}
                                <strong>{{ item.start | date : 'dd.MM.yyyy' }}</strong>
							</span>
						</ng-template>
					</div>
				</mat-option>
			</mat-optgroup>
		</div>

		<!-- Feed -->
		<div>
			<mat-optgroup label="{{ 'shared.global-search.label.feed' | transloco }}" *ngIf="searchData.feed?.length">
				<mat-option
					*ngFor="let item of searchData.feed; trackBy: trackById"
					[value]="{ 'type': 'feed', 'id': item._id }"
					(onSelectionChange)="searchSelect($event.source.value.type, $event.source.value.id); closeModal()">
					<div class="item-with-image">
						<app-user-profile-picture [userId]="item.uid" [size]="40"></app-user-profile-picture>
						<div class="vertical-menu-flex">
							<span>
								<strong>{{ item.initMessage.subject }}</strong>
							</span>
							<span>"{{ item.initMessage.text }}"</span>
							<div class="ns-text-small-1 text-med flex-align-center">
								<strong> {{ item.created | date : 'dd.MM.yyyy' }}</strong>
								<span class="dash"> - </span>
								<span>{{ getUser(item.uid).display_name }}</span>
							</div>
						</div>
					</div>
				</mat-option>
			</mat-optgroup>
		</div>

		<!-- Messages -->
		<div>
			<mat-optgroup label="{{ 'shared.global-search.label.messages' | transloco }}" *ngIf="searchData.messages?.length">
				<mat-option
					data-cy="global-search-messages-options"
					*ngFor="let message of searchData.messages; trackBy: trackById"
					[value]="{ 'type': 'messages', 'id': message._discussion._id }"
					(onSelectionChange)="searchSelect($event.source.value.type, message); closeModal()">
					<div class="item-with-image">
						<app-user-profile-picture [userId]="message.uid" [size]="40"></app-user-profile-picture>
						<div class="vertical-menu-flex">
							<span>
								<strong>{{ getUser(message.uid).display_name }}</strong>
							</span>
							<span>"{{ message.msg }}"</span>
							<div class="ns-text-small-1 text-med flex-align-center">
								<span> {{ message._discussion.subject }}</span>
								<span class="dash" *ngIf="message._discussion.subject"> - </span>
								<strong> {{ message.created | date : 'dd.MM.yyyy' }} </strong>
							</div>
						</div>
					</div>
				</mat-option>
			</mat-optgroup>
		</div>

		<!-- Files -->
		<div>
			<mat-optgroup label="{{ 'shared.global-search.label.files' | transloco }}" *ngIf="searchData.files?.length">
				<mat-option
					*ngFor="let item of searchData.files; trackBy: trackById"
					[value]="{ 'type': 'files', 'id': item._id }"
					(onSelectionChange)="searchSelect($event.source.value.type, $event.source.value.id); closeModal()">
					<div class="vertical-menu-flex">
						<span>
							<strong>{{ item.name }}</strong> <small> - {{ item.length | filesize }} </small>
						</span>
						<span>{{ getUser(item.uid).display_name }}</span>
						<span class="ns-text-small-1 text-med" *ngFor="let path of item.paths; trackBy: trackById">{{ getFilePath(path) }}</span>
						<span class="ns-text-small-1 text-med">{{ 'shared.global-search.added' | transloco }}<strong>{{ item.created | date : 'dd.MM.yyyy' }}</strong>
						</span>
					</div>
				</mat-option>
			</mat-optgroup>
		</div>
	</div>
</div>
