import { Component, Input } from '@angular/core';
import { LinkData } from '../../../../test/deps/hailer-api/shared/link-types';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FeedPostDialogComponent } from 'app/core/feed-post-dialog/feed-post-dialog.component';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
    selector: 'app-message-link',
    templateUrl: './message-link.component.html',
    styleUrls: ['./message-link.component.scss'],
    providers: [
        { provide: TRANSLOCO_SCOPE, useValue: 'markdown' }
    ]
})
export class MessageLinkComponent {
    constructor(private matDialog: MatDialog) {}
    @Input() link: LinkData;
    @Input() messageId: string;

    onLinkClick() {
        switch (this.link.targetType) {
            case 'feedPost': {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.data = this.link._id;
                dialogConfig.panelClass = 'feed-post-dialog';
                this.matDialog.open(FeedPostDialogComponent, dialogConfig);
                break;
            }
            default:
                break;
        }
    }
}
