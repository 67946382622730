<div class="wrapper">
    <div style="text-align: center;">
        <div><app-hailer-logo></app-hailer-logo></div>
        <div><button (click)="router.navigate(['/login'])" mat-button>Back to login page</button></div>
        <div *ngIf="(core.state | async) === 'authenticated'">"Logged in.
            <button mat-button (click)="core.logout().subscribe()">Logout</button>
        </div>
    </div>
    <div *ngIf="(core.state | async) !== 'authenticated'">
        {{ (core.state | async) }}
        Not logged in

        <div>
            <mat-form-field appearance="fill">
                <input type="text" [formControl]="userFilter" matInput autocomplete="off" placeholder="Search users">
            </mat-form-field>
        </div>

        <div *ngFor="let user of userFilter.value ? filteredUsers : users">
            <button data-cy="login-as-debug-button" mat-button (click)="login(user)">Login as {{ user }}</button>
        </div>
    </div>
</div>
