/** @format */

import { Deserializable } from './deserializable.model';

export class UserLogin implements Deserializable {
    _id: string;
    firstname: string;
    lastname: string;
    display_name: string;
    password?: string;
    default_profilepic?: string;
    username?: string;
    online?: boolean;
    lastseen?: string;
    selected?: boolean;
    short_name?: string;
    networks: string[];
    status: string;
    titles: any[];
    rememberLogin: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
