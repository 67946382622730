/** @format */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-app-topbar',
    templateUrl: './app-topbar.component.html',
    styleUrls: ['./app-topbar.component.scss'],
})
export class AppTopbarComponent implements OnInit {
    @Input() parent: string;

    @Output() readonly backButtonClick = new EventEmitter<boolean>();

    isAppPage = false;

    ngOnInit(): void {
        this.isAppPage = this.parent === 'appPage';
    }

    clickBackButton(): void {
        this.backButtonClick.emit(true);
    }
}
