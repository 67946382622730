/** @format */

export type PermissionValueType = 'admin' | 'any' | 'edit' | 'guest' | 'inviter' | 'own' | 'owner' | 'ownteam' | 'user' | 'viewOnly';
export interface PermissionType {
    label: string;
    value: PermissionValueType;
}

// These three exports get their values changes in their models.ts files for translations
export const processPermissionTypes: PermissionType[] = [
    { label: 'Own', value: 'own' },
    { label: 'Own Team', value: 'ownteam' },
    { label: 'Any', value: 'any' },
    { label: 'Admin', value: 'admin' },
];

export const phasePermissionTypes: PermissionType[] = [{ label: 'View Only', value: 'viewOnly' }];

export const calendarPermissionTypes: PermissionType[] = [{ label: 'Admin', value: 'admin' }];
