import { InsightDataRow, InsightDataValue, InsightError, InsightErrorCode, InsightPivotTable, MatrixItem } from "./insight";
import { distinctValueLimit, pivotSizeLimit } from "./use-insight";

export const downloadCsv = (content: string, filename: string) => {
  if (!content) {
    console.log('File to download was empty, bailing out.');
  }

  const isWindowsOs = navigator.platform === 'Win32';

  const bom = isWindowsOs ? '\xFE\xBB\xBF' : '';
  const contentWithBom = bom + content;

  const file = new File([contentWithBom], filename + '.csv', {
    type: 'text/csv;charset=utf-8',
  })
  
  const link = document.createElement('a')
  const url = URL.createObjectURL(file)

  link.href = url
  link.download = file.name
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
};

export const downloadDataCsv = (insight, data): void => {
  if (!data || !insight) {
    return;
  }

  const delimiter = ';';

  const fullTable = [data.headers, ...data.rows];
  const csv = fullTable.map(row => row.map(column => typeof column === 'string' ? '"' + column.replace(/\"/g, '""') + '"': column).join(delimiter)).join('\n')

  downloadCsv(csv, insight.name);
}

export const downloadTableCsv = (insight, table): void => {
  if (!table || !insight) {
    return;
  }

  const delimiter = ';';

  const fullTable = [table.headers, ...table.rows];
  const csv = fullTable.map(row => row.map(column => typeof column === 'string' ? '"' + column.replace(/\"/g, '""') + '"': column).join(delimiter)).join('\n')

  downloadCsv(csv, insight.name);
}

export const downloadPivotCsv = (insight, pivot): void => {
  if (!pivot || !insight) {
    return;
  }

  const delimiter = ';';

  // Header columns from pivot column key
  let data = delimiter + pivot.cols.map((col, index) => col.key).join(delimiter) + delimiter + 'Total' + '\n';

  // Append all table rows, with first column as pivot row key
  data += pivot.table.map((row, index) => {
    return [
      pivot.rows[index].key, delimiter,
      row.join(delimiter), delimiter,
      pivot.rows[index].total
    ].join('');
  }).join('\n');

  downloadCsv(data, insight.name);
}

export const formatValue = (value: InsightDataValue): InsightDataValue => {
    if (typeof value === 'number') {
        const thousandsSeparatedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return thousandsSeparatedValue;
    }
    return value;
}

const minReducer = (aggr, value) => {
  if (aggr === undefined) {
    return value;
  } else if (value === undefined) {
    return aggr;
  } else {
    return Math.min(aggr, value);
  }
}

const maxReducer = (aggr, value) => {
  if (aggr === undefined) {
    return value;
  } else if (value === undefined) {
    return aggr;
  } else {
    return Math.max(aggr, value);
  }
}

/** Takes raw input rows and returns filtered and sorted rows */
export const filterAndSortInputData = (data: { rows: InsightDataRow[] }, filters: { search?: string, columns?: { [column: string]: string } }, sort?: [string, boolean]) => {
  // rebuild table data rows
  const rows = [];

  for (let i = 0; i < data.rows.length; i++) {
      let include = true;
      const columns = Object.values(data.rows[i]);

      if (filters.search) {
          const match = filters.search.toLocaleLowerCase();
          if (!columns.join().toLowerCase().includes(match)) {
              include = false;
          }
      }

      for (const [col, value] of Object.entries(filters.columns)) {
          if (!String(data.rows[i][col]).toLowerCase().includes(value.toLowerCase())) {
              include = false;
          }
      }

      if (include) {
          rows.push(columns);
      }
  }

  if (sort) {

      const key = Number(sort[0]);
      const ascending = sort[1] ?? true;

      rows.sort((a, b) => {
          if (typeof key === 'undefined' || isNaN(key)) { return 0; }

          if (typeof a[key] === 'number' && typeof b[key] === 'number') {
              const valueA = a[key] ?? Number.MAX_SAFE_INTEGER;
              const valueB = b[key] ?? Number.MAX_SAFE_INTEGER

              if (ascending) {
                  return valueA > valueB ? 1 : -1;
              } else {
                  return valueA < valueB ? 1 : -1;
              }
          } else if (
              typeof a[key] === 'string' || typeof a[key] === 'object' &&
              typeof b[key] === 'string' || typeof b[key] === 'object'
          ) {
              const valueA = (a[key] || 'zzz').toString() as string;
              const valueB = (b[key] || 'zzz').toString() as string;

              if (ascending) {
                  return valueA.localeCompare(valueB);
              } else {
                  return valueB.localeCompare(valueA);
              }
          } else {
              console.log('trying to compare different types', typeof a[key], typeof b[key]);
          }
      });
  }

  return rows;
}

/** This is the same as renderPivot, except no rendering of HTML elements */
export const buildPivotTableAndEmit = (matrixData: { [key: string]: { value: number } }, rows: MatrixItem[], cols: MatrixItem[], mt: number, options: { rows, cols }) => {
  const rowKeys = options.rows || [];
  const colKeys = options.cols || [];

  const pivotTable = { table: [], rows, cols };

  for (let i = 0; i < colKeys.length; i++) {
      let hco = 1;
      for (let i2 = 0; i2 < cols.length; i2++) {
          if (i + 1 == colKeys.length) {
              // 
          } else if (i2 + 1 < cols.length) {
              if (cols[i2].keys[i] == cols[i2 + 1].keys[i]) {
                  hco++;
              } else {
                  hco = 1;
              }
          }
      }
  }

  if (rowKeys.length > 0) {
      for (let i = 0; i < rows.length; i++) {
          const row = [];

          for (let i2 = 0; i2 < rows[i].keys.length; i2++) {
              let hla = rows[i].keys[i2];
              if (i > 0) {
                  if (rows[i].keys[i2] == rows[i - 1].keys[i2]) {
                      hla = '';
                  }
              }
              let hco = 1;
              if (i2 + 1 == rows[i].keys.length && colKeys.length > 0) {
                  hco = 2;
                  hla = rows[i].keys[i2];
              }
          }
          if (colKeys.length > 0) {
              for (let i2 = 0; i2 < cols.length; i2++) {
                  const key = [rows[i].key, cols[i2].key].join('|');

                  const ddd = { key };

                  for (let i3 = 0; i3 < rowKeys.length; i3++) {
                      ddd[rowKeys[i3]] = rows[i].keys[i3];
                  }

                  for (let i3 = 0; i3 < colKeys.length; i3++) {
                      ddd[colKeys[i3]] = cols[i2].keys[i3];
                  }

                  // This should be the pivot value
                  const pivotValue = matrixData[key]?.value;
                  row.push(pivotValue ?? null);
              }

              pivotTable.table.push(row);
          }

          const ddd = { key: rows[i].key };

          for (let i3 = 0; i3 < rowKeys.length; i3++) {
              ddd[rowKeys[i3]] = rows[i].keys[i3];
          }
      }
  }

  // TODO: Re-enable
  //options.event?.data?.pivot?.(pivotTable.rows, pivotTable.cols, pivotTable.table, mt);
  
  // remove: data.setPivot({ rows: pivotTable.rows, cols: pivotTable.cols, table: pivotTable.table, total: mt });

  return { rows: pivotTable.rows, cols: pivotTable.cols, table: pivotTable.table, total: mt }; 
}

/** Builds the pivot table, rows and columns, and populates values into table */
export const buildPivot = (pivotRows, options: { pivot: { rows, cols, value?, sort?} }): [InsightError | null, InsightPivotTable | null] => {
  const matrixData: { [key: string]: { value: number, values: number[], count: number, sum: number, rowKey: string, colKey: string } } = {};

  const matrixRow: { [key: string]: MatrixItem & { count: number, sum: number, min: number, max: number } } = {};
  const matrixColumn: { [key: string]: MatrixItem & { count: number, sum: number, min: number, max: number } } = {};

  const valueColumn = options.pivot.value.column || '';

  let mt = 0;

  let matrixCount = 0;
  let matrixSum = 0;
  let matrixMin = Number.MAX_VALUE;
  let matrixMax = Number.MIN_VALUE;

  let pivotRowCount = 0;
  let pivotColCount = 0;

  for (const row of pivotRows) {
    const mks = [];
    const rks = [];
    const cks = [];

    for (const pivotRow of options.pivot.rows) {
      rks.push(row[pivotRow]);
      mks.push(row[pivotRow]);
    }

    const rowKey = rks.join('|');

    if (!matrixRow[rowKey]) {
      matrixRow[rowKey] = { key: rowKey, keys: rks, total: 0, count: 0, sum: 0, min: Number.MAX_VALUE, max: Number.MIN_VALUE };
      pivotRowCount++;

      if (pivotRowCount > pivotSizeLimit){
        console.log('Pivot table size limit (rows) reached, preventing draw. Limit is set to:', pivotSizeLimit);
        return [{ msg: 'Pivot row count exceeded limit: ' + pivotSizeLimit, code: InsightErrorCode.PivotTooLarge }, null];
      }
    }

    for (const pivotCol of options.pivot.cols) {
      cks.push(row[pivotCol]);
      mks.push(row[pivotCol]);
    }
    const colKey = cks.join('|');
    if (!matrixColumn[colKey]) {
      matrixColumn[colKey] = { key: colKey, keys: cks, total: 0, count: 0, sum: 0, min: Number.MAX_VALUE, max: Number.MIN_VALUE };
      pivotColCount++;

      if (pivotColCount > pivotSizeLimit){
        console.log('Pivot table size limit (columns) reached, preventing draw. Limit is set to:', pivotSizeLimit);
        return [{ msg: 'Pivot column count exceeded limit: ' + pivotSizeLimit, code: InsightErrorCode.PivotTooLarge }, null];
      }
    }
    const mk = mks.join('|');
    if (!matrixData[mk]) {
      matrixData[mk] = { value: 0, count: 0, sum: 0, values: [], rowKey, colKey };
    }

    const value = Number(row[valueColumn]) || 0;
    const isNumberValue = Number(row[valueColumn]) ? true : false;

    matrixCount++;
    matrixSum += value;

    if (isNumberValue) {
      matrixMin = Math.min(value, matrixMin);
      matrixMax = Math.max(value, matrixMax);

      matrixRow[rowKey].min = Math.min(value, matrixRow[rowKey].min);
      matrixColumn[colKey].min = Math.min(value, matrixColumn[colKey].min);

      matrixRow[rowKey].max = Math.max(value, matrixRow[rowKey].max);
      matrixColumn[colKey].max = Math.max(value, matrixColumn[colKey].max);
    }

    matrixRow[rowKey].count++;
    matrixColumn[colKey].count++;
    matrixData[mk].count++;
    matrixRow[rowKey].sum += value;
    matrixColumn[colKey].sum += value;
    matrixData[mk].sum += value;
    matrixData[mk].values.push(value);
  }

  for (const [key, cell] of Object.entries(matrixData)) {
    switch (options.pivot.value.formula) {
      case 'Count':
        mt += cell.count;
        matrixData[key].value = cell.count;
        matrixRow[cell.rowKey].total += cell.count;
        matrixColumn[cell.colKey].total += cell.count;
        break;
      case 'Sum':
        mt += cell.sum;
        matrixData[key].value = cell.sum;
        matrixRow[cell.rowKey].total += cell.sum;
        matrixColumn[cell.colKey].total += cell.sum;
        break;
      case 'Min':
        const min = matrixData[key].values.reduce(minReducer, undefined);
        mt = Math.min(min, matrixMin);
        matrixData[key].value = min;
        matrixRow[cell.rowKey].total = Math.min(min, matrixRow[cell.rowKey].min);
        matrixColumn[cell.colKey].total = Math.min(min, matrixColumn[cell.colKey].min);
        break;
      case 'Max':
        const max = matrixData[key].values.reduce(maxReducer, undefined);
        mt = Math.max(max, matrixMax);
        matrixData[key].value = max;
        matrixRow[cell.rowKey].total = Math.max(max, matrixRow[cell.rowKey].max);
        matrixColumn[cell.colKey].total = Math.max(max, matrixColumn[cell.colKey].max);
        break;
      case 'Avg':
        if (matrixData[key].count > 0) {
          const avg = matrixData[key].sum / matrixData[key].count;
          mt = matrixSum / matrixCount;
          matrixData[key].value = avg;
          matrixRow[cell.rowKey].total = matrixRow[cell.rowKey].sum / matrixRow[cell.rowKey].count;
          matrixColumn[cell.colKey].total = matrixColumn[cell.colKey].sum / matrixColumn[cell.colKey].count;
        }

        break;
    }
  }

  const ra: MatrixItem[] = [];
  for (const [y, x] of Object.entries(matrixRow)) {
    ra.push(x);
  }
  const ca: MatrixItem[] = [];
  for (const [y, x] of Object.entries(matrixColumn)) {
    ca.push(x);
  }

  // TODO: Add locale sort (for strings below)

  // TODO: Fix multi level pivot label sorting

  ra.sort((a, b) => {
    const ascending = options.pivot.sort.row[1] ?? true;
    const sortColumn = options.pivot.sort?.row[0];

    if (typeof sortColumn === 'undefined') {
      return 0;
    }

    const valueA = a[sortColumn];
    const isNumberA = !isNaN(valueA as any)

    const valueB = b[sortColumn];
    const isNumberB = !isNaN(valueB as any)

    if (isNumberA && isNumberB) {
      if (ascending) {
        return Number(a[sortColumn]) > Number(b[sortColumn]) ? 1 : -1;
      } else {
        return Number(a[sortColumn]) < Number(b[sortColumn]) ? 1 : -1;
      }
    } else {
      if (ascending) {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    }

  });

  ca.sort((a, b) => {
    const ascending = options.pivot.sort.col[1] ?? true;
    const key = options.pivot.sort.col[0];

    let av = a[key];
    let bv = b[key];
    if (Array.isArray(av) && Array.isArray(bv)) {
      if (
        !isNaN(av[0]) && !isNaN(av[1]) &&
        !isNaN(bv[0]) && !isNaN(bv[1])
      ) {
        if (ascending) {
          return av[0] !== bv[0] ? av[0] - bv[0] : av[1] - bv[1];
        } else {
          return av[0] !== bv[0] ? bv[0] - av[0] : bv[1] - av[1];
        }
      }
    }

    if (ascending) {
      if (!isNaN(a[key]) && !isNaN(b[key])) {
        return Number(a[key]) > Number(b[key]) ? 1 : -1;
      }
      return a[key] > b[key] ? 1 : -1;
    } else {
      if (!isNaN(a[key]) && !isNaN(b[key])) {
        return Number(a[key]) < Number(b[key]) ? 1 : -1;
      }
      return a[key] < b[key] ? 1 : -1;
    }
  });

  const colorPalette = ['#0E6EB8', '#FB8C00', '#7B1FA2', '#4CAF50', '#D50000', '#6D4C41'];

  let ic = 0;
  for (let i = 0; i < ra.length; i++) {
    if (ic == colorPalette.length) {
      ic = 0;
    }
    ra[i].color = colorPalette[ic];
    ic++;
  }
  const rows: MatrixItem[] = [];
  const cols: MatrixItem[] = [];
  const round = Math.pow(10, options.pivot.value.round);
  for (let i = 0; i < ra.length; i++) {
    /// Disabled limits a they cannot be set in the frontend for now
    // if (i < options.pivot.limit.rows || options.pivot.limit.rows == 0) {
    rows.push(ra[i]);
    rows[i].total = Math.round(ra[i].total * round) / round;
    // }
  }
  for (let i = 0; i < ca.length; i++) {
    /// Disabled limits a they cannot be set in the frontend for now
    // if (i < options.pivot.limit.cols || options.pivot.limit.cols == 0) {
    cols.push(ca[i]);
    cols[i].total = Math.round(ca[i].total * round) / round;
    // }
  }
  for (const [y, x] of Object.entries(matrixData)) {
    matrixData[y].value = Math.round(x.value * round) / round;
  }
  mt = Math.round(mt * round) / round;

  return [null, buildPivotTableAndEmit(matrixData, rows, cols, mt, { rows: options.pivot.rows, cols: options.pivot.cols })];
}
