/** @format */

export abstract class GDrawable<Type extends google.maps.Marker | google.maps.Polygon | google.maps.Polyline> {
    data: Type;
    infoWindow: google.maps.InfoWindow;
    editable: boolean;
    customId: string;

    constructor(data: Type, infoWindow: google.maps.InfoWindow, editable: boolean, customId?: string) {
        this.data = data;
        this.infoWindow = infoWindow;
        this.editable = editable;
        this.data.setDraggable(editable);
        this.customId = customId;
    }

    // Returns an array. If the instance contains a Marker, then the array will only contain one element.
    getLocationData(): google.maps.LatLng[] {
        if (this.data instanceof google.maps.Marker) {
            return [this.data.getPosition()];
        }
        // Data is of type polygon or polyline so it will have .getPath() method.
        return (this.data as google.maps.Polygon).getPath().getArray();
    }

    getType() {
        if (this.data instanceof google.maps.Marker) {
            return 'marker';
        } else if (this.data instanceof google.maps.Polygon) {
            return 'polygon';
        } else if (this.data instanceof google.maps.Polyline) {
            return 'line';
        }

        return null;
    }

    remove() {
        this.data.setMap(null);
    }

    setDraggable(state: boolean) {
        this.data.setDraggable(state);
    }

    abstract equals(other: any): boolean;
}
