/** @format */

export enum CoreSignal {
    ActivitiesCreated = 'activities.created',
    ActivitiesUpdated = 'activities.updated',
    ActivitiesFollow = 'activities.follow',
    CacheInvalidate = 'cache.invalidate',
    CalendarReload = 'calendar.reload',
    CalendarRemoved = 'calendar.removed',
    CompanyNewInvitation = 'company.new_invitation',
    CompanyReload = 'company.reload',
    CompanyRemoveInvitation = 'company.remove_invitation',
    CompanyUpdateProcessLastActive = 'company.update_process_last_active',
    DevicesNew = 'devices.new',
    DiscussionCreated = 'discussion.created',
    DiscussionRead = 'discussion.read',
    DiscussionRemoved = 'discussion.remove',
    DiscussionUpdated = 'discussion.updated',
    MessengerNew = 'messenger.new',
    NetworkTags = 'network.tags',
    NotificationReload = 'notification.reload',
    UserReload = 'user.reload',
    WorkspaceRoleUpdated = 'workspace.role.updated',
    WorkspaceRoleCreated = 'workspace.role.created',
    WorkspaceRoleRemoved = 'workspace.role.removed',
    AppRefresh = 'app.refresh',
}
