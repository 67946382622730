<!-- @format -->
<ng-container *ngIf="textForm">
    <quill-editor
        [class.text-editor-required]="requiredError"
        [style.border-radius]="requiredError ? styles?.['border-radius'] : undefined"
        #quillEditor
        [attr.data-cy]="dataCy || 'text-editor'"
        [formControl]="textForm"
        [modules]="modules"
        [preserveWhitespace]="false"
        bounds="self"
        [styles]="styles"
        placeholder="{{ placeholder }}"></quill-editor>
</ng-container>
