/** @format */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { User } from '@app/models';
import { PeopleService } from 'app/people/people.service';
import { environment } from '@app/env';

@Component({
    selector: 'app-user-image-link',
    templateUrl: './user-image-link.component.html',
    styleUrls: ['./user-image-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserImageLinkComponent implements OnInit, OnChanges, OnDestroy {
    @Input() userId: string;
    @Input() disableTooltip = false;
    @Input() size = 32;

    // eslint-disable-next-line
    @Output() click = new EventEmitter<any>();

    url: string;
    user: User;

    private onDestroy = new Subject<void>();
    constructor(private peopleService: PeopleService, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.url = environment.wsUrl;
        this.peopleService.unknownUsers.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.user = this.peopleService.getUser(this.userId);
                this.cdr.detectChanges();
            },
        });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userId && changes.userId.currentValue) {
            this.user = this.peopleService.getUser(this.userId);
        }
    }
}
