/** @format */

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, debounceTime, filter, lastValueFrom, take, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { DiscussionFileResponse, DiscussionLink, File } from '@app/models';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { FileUploaderComponent } from '@app/shared/file-uploader/file-uploader.component';
import { FileViewComponent } from 'app/files-shared/file-view/file-view.component';
import { FilesService } from 'app/_services/files.service';
import { ObjectFilterOptions } from 'app/_models/tag-filter-list.model';
import { SideNavService } from 'app/_services/side-nav.service';
import { TagSelectorOptions } from 'app/_models/tag-selector-options.model';
import { V3ActivityViewContextService } from 'app/v3-activity/v3-activity-view-context.service';
import { ImageViewerService, ViewerImage } from 'app/_services/image-viewer.service';
import { environment } from '@app/env';
import { RPCService } from 'app/_services/rpc.service';
import { ObjectTagsResponse, TagObjectArgs } from 'app/_services/tag.service';

@Component({
    selector: 'app-v3-activity-files',
    templateUrl: './v3-activity-files.component.html',
    styleUrls: ['./v3-activity-files.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'activity-sidenav', alias: 'activity-sidenav' } }],
})
export class V3ActivityFilesComponent implements OnDestroy, AfterViewInit {
    @ViewChild('uploader', { static: false }) uploader: FileUploaderComponent;

    tagSelectorOptions: BehaviorSubject<TagSelectorOptions>;

    fileFilterListOptions: BehaviorSubject<ObjectFilterOptions>;
    resetTagFilerDeletableObjects = new Subject<string>();
    tagObjectListSearch = new BehaviorSubject<string>('');

    tagError = false;

    loadingZip = false;

    discussionFiles = new BehaviorSubject<File[]>([]);

    totalFiles: number | null = null;

    allFilesLoaded = false;

    discussionLinks: DiscussionLink[] = [];

    searchFilesControl = new FormControl<string>('');

    draggingFile = false;

    translationLoaded = false;

    private loadingDiscussionFiles = false;
    private onDestroy = new Subject<void>();
    constructor(
        public viewContext: V3ActivityViewContextService,
        public files: FilesService,
        public sideNav: SideNavService,
        private cdr: ChangeDetectorRef,
        private platform: Platform,
        private dialogHelperService: DialogHelperService,
        private translocoService: TranslocoService,
        private imageViewerService: ImageViewerService,
        private rpc: RPCService
    ) {}

    @HostListener('dragover', ['$event'])
    onDragover(event) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.viewContext.workflow.value?.enableAttachments || !this.viewContext.canBeEdited) {
            return;
        }

        event.dataTransfer.dropEffect = 'copy';

        if (this.draggingFile) {
            return;
        }

        this.draggingFile = this.viewContext.workflow.value?.enableAttachments;
        this.cdr.detectChanges();
    }

    @HostListener('dragleave', ['$event'])
    onDragleave(event) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.viewContext.workflow.value?.enableAttachments || !this.viewContext.canBeEdited) {
            return;
        }

        if (!this.draggingFile) {
            return;
        }

        this.draggingFile = false;
        this.cdr.detectChanges();
    }

    @HostListener('drop', ['$event'])
    onFileDrop(event) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.viewContext.workflow.value?.enableAttachments || !this.viewContext.canBeEdited) {
            return;
        }

        const files = event.dataTransfer.files || [];

        for (const file of files) {
            this.uploader.uploadFile(file);
        }

        this.draggingFile = false;
        this.cdr.detectChanges();
    }

    @HostListener('paste', ['$event'])
    onFilePaste(event) {
        const filesEnabled = this.viewContext.workflow.value?.enableAttachments;
        const inFilesTab = this.viewContext.currentTab.value === 'files';

        if (!filesEnabled || !this.viewContext.canBeEdited || !inFilesTab) {
            return;
        }

        event.preventDefault();

        const files = event.clipboardData.files || [];

        for (const file of files) {
            this.uploader.uploadFile(file);
        }
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static redrawCount = 0;
    redraw() {
        V3ActivityFilesComponent.redrawCount += 1;
        // Console.log('V3ActivityFilesComponent view redrawed: ' + V3ActivityFilesComponent.redrawCount + ' times');
    }

    viewImages(images: File[], index: number): void {
        const preparedImages = images.map(image => {
            const isPicture = image.isPicture && image.type !== 'image/gif';
            const isVideo = image.isVideo;
            const preparedImage: ViewerImage = {
                url: isPicture ? `${environment.wsUrl}/image/hires/${image._id || ''}` : `${environment.wsUrl}/file/${image._id || ''}`,
                _id: image._id || '',
                type: image.type,
                original: isPicture ? `${environment.wsUrl}/image/hires/${image._id || ''}` : '',
                isVideo,
                isPicture,
                name: image.name,
                downloadUrl: `${environment.wsUrl}/file/${image._id || ''}?dl=1`,
            };
            return preparedImage;
        });

        this.imageViewerService.viewImages(preparedImages, index);
    }

    ngAfterViewInit(): void {
        this.uploader.uploadInProgress.pipe(takeUntil(this.onDestroy)).subscribe({
            next: value => {
                this.uploadInProgress(value);
                this.cdr.detectChanges();
            },
        });

        this.uploader.fileIds.pipe(takeUntil(this.onDestroy)).subscribe({
            next: fileIds => {
                if (this.viewContext.action === 'create') {
                    if (!fileIds.length) {
                        return delete this.viewContext.toSave.activity.fileIds;
                    }
                    return (this.viewContext.toSave.activity.fileIds = fileIds);
                }

                if (!fileIds.length) {
                    return delete this.viewContext.toSave.options.files;
                }

                const filesMap = fileIds.reduce((map, userId) => {
                    map[userId] = true;
                    return map;
                }, {});

                this.viewContext.toSave.options.files = filesMap;
                if (!this.viewContext.editingFiles.value) {
                    this.viewContext.editingFiles.next(!!fileIds?.length);
                }
            },
        });

        this.viewContext.editing
            .pipe(
                takeUntil(this.onDestroy),
                filter(editing => !editing && !this.viewContext.errorsInSidenav.value.filesUploading)
            )
            .subscribe({
                next: () => this.uploader.reset(),
            });

        this.viewContext.loadingInfo
            .pipe(
                takeUntil(this.onDestroy),
                filter(loading => !loading)
            )
            .subscribe({
                next: () => this.cdr.detectChanges(),
            });

        this.viewContext.activity
            .pipe(
                takeUntil(this.onDestroy),
                filter(activity => !!activity)
            )
            .subscribe({
                next: activity => {
                    const currentOptions = this.fileFilterListOptions?.value;
                    if (currentOptions) {
                        currentOptions.objects = activity?.files?.map(({ _id }) => _id);

                        if (!this.fileFilterListOptions?.value) {
                            this.fileFilterListOptions = new BehaviorSubject(currentOptions);
                        } else {
                            this.fileFilterListOptions.next(currentOptions);
                        }
                    }

                    this.cdr.detectChanges();
                },
            });

        this.viewContext.workflow
            .pipe(
                takeUntil(this.onDestroy),
                debounceTime(100),
                filter(process => !!process)
            )
            .subscribe({
                next: () => {
                    this.getUploaderTags();
                },
            });

        this.searchFilesControl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe({
            next: val => this.tagObjectListSearch.next(val),
        });

        combineLatest([this.viewContext.v3Permissions, this.viewContext.unknownUsers])
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: () => this.cdr.detectChanges(),
            });

        this.viewContext.updateDiscussionAttachments
            .pipe(
                takeUntil(this.onDestroy),
                filter(() => !!this.viewContext.activity.value && !!this.viewContext.workflow.value)
            )
            .subscribe({
                next: () => this.updateDiscussionAttachments(),
            });

        if (!this.viewContext.activity || !this.viewContext.workflow) {
            combineLatest([this.viewContext.activity, this.viewContext.workflow])
                .pipe(
                    takeUntil(this.onDestroy),
                    filter(() => !!this.viewContext.activity.value && !!this.viewContext.workflow.value),
                    take(1)
                )
                .subscribe({
                    next: () => this.updateDiscussionAttachments(),
                });
        } else {
            void this.updateDiscussionAttachments();
        }

        this.searchFilesControl.valueChanges.pipe(debounceTime(250), takeUntil(this.onDestroy)).subscribe(async () => {
            if (!this.activityDiscussion) {
                return;
            }

            const discussionFileResponse = await this.loadDiscussionFiles();

            this.totalFiles = discussionFileResponse.total;
            this.allFilesLoaded = discussionFileResponse.allLoaded;
            this.discussionFiles.next(discussionFileResponse.files);
            this.cdr.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    async updateDiscussionAttachments() {
        this.loadingDiscussionFiles = true;
        this.cdr.detectChanges();

        const inDiscussion = this.viewContext.activity.value?.followers?.includes(this.viewContext.me._id);
        const enableMessenger = this.viewContext.workflow.value?.enableMessenger;

        if (!this.activityDiscussion || !enableMessenger || !inDiscussion) {
            this.loadingDiscussionFiles = false;
            this.cdr.detectChanges();
            return;
        }

        try {
            let discussionFileResponse = await this.loadDiscussionFiles({ skip: this.totalFiles ? this.totalFiles : 0 });

            // Total files is null if this is the first load
            if (this.totalFiles !== null) {
                /* We get the difference of the dicussion total files since the last update, so that we can
               know how many files to remove from the old discussion array to ensure there are no duplicates. */
                const fileDiff = discussionFileResponse.total - this.totalFiles;

                let allNewFiles = [...discussionFileResponse.files];
                while (allNewFiles.length < fileDiff) {
                    discussionFileResponse = await this.loadDiscussionFiles({ skip: allNewFiles.length });
                    allNewFiles = allNewFiles.concat(discussionFileResponse.files);
                }

                /* We want to keep old loaded files loaded, so we have add the old files on top of the new response.
				   100 is the amount of files each request gets at once, so we remove the left over amount from the beginning of the old files */
                const oldFilesCount = fileDiff % 100 !== 0 ? fileDiff % 100 : 100;
                this.discussionFiles.next([...allNewFiles, ...this.discussionFiles.value.slice(100 - oldFilesCount)]);
            } else {
                this.discussionFiles.next(discussionFileResponse.files);
            }
            this.totalFiles = discussionFileResponse.total;
            this.allFilesLoaded = this.totalFiles === this.discussionFiles.value.length;

            this.discussionLinks = await this.viewContext.getDiscussionLinks(this.activityDiscussion);
        } catch (error) {
            console.error('Failed to get discussion attachments!', error);
        }

        this.loadingDiscussionFiles = false;
        this.cdr.detectChanges();
    }

    private async loadDiscussionFiles(options?: { skip?: number }): Promise<DiscussionFileResponse> {
        if (!this.activityDiscussion) {
            return { total: 0, allLoaded: true, files: [] };
        }

        const searchString = this.searchFilesControl.value?.toLocaleLowerCase()?.trim() || '';

        return this.viewContext.getDiscussionFiles(this.activityDiscussion, {
            reverse: true,
            sortBy: 'created',
            search: searchString,
            ...(options ?? {}),
        });
    }

    async downloadFilesAsZip() {
        if (!this.fileIds.length) {
            return void console.warn('No files to download');
        }

        this.loadingZip = true;
        this.cdr.detectChanges();

        try {
            await this.files.downloadFilesAsZip(this.fileIds, this.zipName);
        } catch (error) {
            console.error('Failed to download files as zip!', error);
        }

        this.loadingZip = false;
        this.cdr.detectChanges();
    }

    async getUploaderTags() {
        const process = this.viewContext.workflow.value;

        if (!process?.cid || !process?._id) {
            return;
        }

        try {
            const tags = await this.checkTags(process.cid, process._id);

            const opts: TagSelectorOptions = {
                cid: process.cid,
                tagPool: tags[process._id],
                allowNewTag: process.allowCustomTags,
                multiple: process.allowMultipleTags,
                required: process.requireFileTag,
            };

            if (!this.tagSelectorOptions?.value) {
                this.tagSelectorOptions = new BehaviorSubject(opts);
            } else {
                this.tagSelectorOptions.next(opts);
            }

            const activity = this.viewContext.activity.value;
            if (activity) {
                const args: ObjectFilterOptions = {
                    cid: activity.cid,
                    objects: this.fileIds,
                    allowedTags: tags[process._id],
                    disableDeletion: !this.viewContext.canBeEdited,
                };

                if (!this.fileFilterListOptions?.value) {
                    this.fileFilterListOptions = new BehaviorSubject(args);
                } else {
                    this.fileFilterListOptions.next(args);
                }
            }

            this.cdr.detectChanges();
        } catch (error) {
            console.error('error happened while fetching process tags', error);
        }
    }

    async loadMoreFiles(): Promise<void> {
        this.loadingDiscussionFiles = true;
        this.cdr.detectChanges();

        if (!this.activityDiscussion || this.allFilesLoaded) {
            return;
        }

        const discussionFileResponse = await this.loadDiscussionFiles({
            skip: this.discussionFiles.value.length,
        });

        this.discussionFiles.next([...this.discussionFiles.value, ...discussionFileResponse.files]);
        this.allFilesLoaded = discussionFileResponse.allLoaded;
        this.totalFiles = discussionFileResponse.total;

        this.loadingDiscussionFiles = false;
        this.cdr.detectChanges();
        return;
    }

    async fileTagged(args: { tagId: string; fileId: string }) {
        if (!args.fileId || !args.tagId) {
            console.warn('Invalid arguments for tagging a file');
            return;
        }

        try {
            if (!this.processCid) {
                throw new Error('No workspace id');
            }

            const tagInProcess = this.tagSelectorOptions?.value.tagPool?.includes(args.tagId);
            if (!tagInProcess && this.viewContext.workflow.value) {
                // If a new tag was created, add it to the process first
                await this.tag(this.processCid, {
                    tag: args.tagId,
                    target: this.viewContext.workflow.value._id,
                    type: 'process',
                });
            }

            await this.tag(this.processCid, {
                tag: args.tagId,
                target: args.fileId,
                type: 'file',
            });

            this.cdr.detectChanges();
        } catch (error) {
            console.error('error happened while tagging a file', error);
        }
    }

    async fileUntagged(args: { tagId: string; fileId: string }) {
        if (!args?.tagId || !args?.fileId || !this.processCid) {
            console.warn('Invalid arguments for untagging a file');
            return;
        }

        try {
            await this.untag(this.processCid, args.tagId, args.fileId);
            this.cdr.detectChanges();
        } catch (error) {
            console.error('error happened while untagging a file', error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    trackFileById(index: number, file: File) {
        return file._id;
    }

    setFileToBeRemoved(fileId: string) {
        if (!this.viewContext.toSave.options.files) {
            this.viewContext.toSave.options.files = {};
        }

        if (this.fileToBeRemoved(fileId)) {
            delete this.viewContext.toSave.options.files[fileId];

            if (!Object.keys(this.viewContext.toSave.options.files || {}).length) {
                delete this.viewContext.toSave.options.files;
            }
            this.cdr.detectChanges();
            return;
        }

        this.viewContext.toSave.options.files[fileId] = null;

        if (!this.viewContext.editingFiles.value) {
            this.viewContext.editingFiles.next(true);
        }

        this.cdr.detectChanges();
    }

    fileToBeRemoved(fileId: string): boolean {
        return this.viewContext.toSave?.options?.files?.[fileId] === null;
    }

    checkTags(cid: string, processId: string): Promise<ObjectTagsResponse> {
        return lastValueFrom(this.rpc.request('v2.tags.check', [cid, [processId]]));
    }

    async tag(workspaceId: string, args: TagObjectArgs): Promise<boolean> {
        if (!workspaceId) {
            return false;
        }

        return lastValueFrom(this.rpc.request('v2.tags.tag', [workspaceId, args]));
    }

    async untag(workspaceId: string, tagId: string, targetId: string): Promise<boolean> {
        if (!workspaceId) {
            return false;
        }

        return lastValueFrom(this.rpc.request('v2.tags.untag', [workspaceId, { tag: tagId, target: targetId }]));
    }

    uploadInProgress(status: boolean) {
        const currentErrors = this.viewContext.errorsInSidenav.value;
        currentErrors.filesUploading = status || false;
        this.viewContext.errorsInSidenav.next(currentErrors);
    }

    openFile(fileId: string): void {
        if (this.platform.SAFARI) {
            this.showError(
                this.translocoService.translate('shared.file-viewer.error-viewing-title'),
                this.translocoService.translate('shared.file-viewer.safari-error')
            );
        } else {
            this.sideNav.create(FileViewComponent, { fileId });
        }
    }

    private showError(errorTitle: string, errorMsg: string): void {
        this.dialogHelperService.showError('OK', errorMsg, errorTitle, false).pipe(take(1)).subscribe();
    }
    get processCid(): string | undefined {
        return this.viewContext.workflow.value?.cid;
    }

    get showTagFilterList(): boolean {
        const tags = this.fileFilterListOptions?.value?.allowedTags;
        return this.viewContext.fileTaggingLicense && tags ? tags.length > 0 : false;
    }

    get activityDiscussion(): string | undefined {
        return this.viewContext.activity.value?.discussion;
    }

    get enableAttachments(): boolean {
        return this.viewContext.workflow.value?.enableAttachments || false;
    }

    get fileIds(): string[] {
        return this.viewContext.activity.value?.files?.map(file => file._id) || [];
    }

    get zipName(): string {
        return `${this.viewContext.activity.value?.name.toLocaleLowerCase().replace(' ', '-')}-files`;
    }

    get noAttachments(): boolean {
        const discussionAttachments = !!this.discussionFiles.value.length || !!this.discussionLinks.length;
        const activityFiles = !!this.viewContext.activity.value?.files?.length;
        const loading = this.loadingDiscussionFiles || this.viewContext.loadingInfo.value;

        return !loading && !activityFiles && !discussionAttachments;
    }

    get filteredActivityFiles(): File[] {
        const searchString = this.searchFilesControl.value?.toLocaleLowerCase()?.trim() || '';
        const activityFiles = this.viewContext.activity.value?.files;

        if (!searchString) {
            return activityFiles || [];
        }

        return activityFiles?.filter(({ name }) => !!name.toLocaleLowerCase().trim().includes(searchString)) || [];
    }

    get filteredDiscussionLinks(): DiscussionLink[] {
        const searchString = this.searchFilesControl.value?.toLocaleLowerCase()?.trim() || '';

        if (!searchString) {
            return this.discussionLinks || [];
        }

        return this.discussionLinks?.filter(({ link }) => !!link.toLocaleLowerCase().trim().includes(searchString)) || [];
    }
}
