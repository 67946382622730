/** @format */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Team, User } from '@app/models';
import { PeopleService } from 'app/people/people.service';
import { DropdownItems, DropdownOptions, DropdownSelectedItems } from 'app/_models/dropdown-selector.model';
import { Group } from 'app/_models/group.model';
import { TeamUserSelectorOptions } from 'app/_models/teamUserSelectorOptions.model';
import { CoreService } from 'app/_services/core.service';
import { GroupService } from 'app/_services/group.service';
import { PermissionService } from 'app/_services/permission.service';
import { TeamService } from 'app/_services/team.service';

@Component({
    selector: 'app-team-user-selector',
    templateUrl: './team-user-selector.component.html',
    styleUrls: ['./team-user-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamUserSelectorComponent implements OnInit, OnDestroy, OnChanges {
    @Input() options: BehaviorSubject<TeamUserSelectorOptions>;
    @Input() hideItems: BehaviorSubject<string[]>;
    @Input() predefinedItems: BehaviorSubject<string[]>;
    @Input() selectAll: MatCheckboxChange;
    @Input() resetSelection: Subject<void>;

    @Output() results = new EventEmitter<DropdownSelectedItems>();

    dropdownOptions = new BehaviorSubject<DropdownOptions>(new DropdownOptions());
    items = new BehaviorSubject<DropdownItems>({});
    selectedItems = new BehaviorSubject<DropdownSelectedItems>({});

    private parentUpdate = false;
    private onDestroy = new Subject<void>();

    constructor(
        private people: PeopleService,
        private team: TeamService,
        private cdr: ChangeDetectorRef,
        private core: CoreService,
        private groups: GroupService,
        private permission: PermissionService
    ) {}

    ngOnInit(): void {
        if (this.options) {
            this.options.pipe(takeUntil(this.onDestroy)).subscribe({
                next: options => {
                    this.parentUpdate = true;
                    const currentOptions = this.dropdownOptions.value;

                    Object.keys(options).forEach(optionKey => {
                        if (currentOptions[optionKey] === undefined) {
                            return;
                        }

                        currentOptions[optionKey] = options[optionKey];
                    });

                    this.dropdownOptions.next(currentOptions);
                    this.ascertainTeamsAndUsers();
                },
            });
        }

        if (this.predefinedItems) {
            this.predefinedItems.pipe(takeUntil(this.onDestroy)).subscribe({
                next: () => {
                    this.parentUpdate = true;
                    this.ascertainTeamsAndUsers();
                },
            });
        }

        if (this.hideItems) {
            this.hideItems.pipe(takeUntil(this.onDestroy)).subscribe({
                next: () => {
                    this.parentUpdate = true;
                    this.ascertainTeamsAndUsers();
                },
            });
        }

        if (this.resetSelection) {
            this.resetSelection.pipe(takeUntil(this.onDestroy)).subscribe({
                next: () => this.selectedItems.next({}),
            });
        }

        this.selectedItems.pipe(takeUntil(this.onDestroy)).subscribe({
            next: itemsMap => {
                if (this.parentUpdate) {
                    this.parentUpdate = false;
                    return;
                }
                this.results.emit(itemsMap);
            },
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectAll && changes.selectAll.currentValue) {
            this.selectAllUsersTeams();
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    async ascertainTeamsAndUsers() {
        if (!this.predefinedItems?.value?.length) {
            this.createItemsForSelector();
            return;
        }

        const users = this.getUsers();
        const teams = this.getTeams();
        const groups = this.getGroups();

        if (this.options.value.type === 'user') {
            for (const userId of this.predefinedItems.value) {
                if (!userId || users.find(user => user._id === userId)) {
                    continue;
                }
                const loadedUser = await this.people.getUserAsync(userId);
                if (!loadedUser) {
                    continue;
                }
                const tempUser = new User({
                    _id: userId,
                    firstname: loadedUser.firstname,
                    lastname: loadedUser.lastname,
                    title: {},
                });
                users.push(tempUser);
            }
        }

        if (this.options.value.type === 'team') {
            for (const teamId of this.predefinedItems.value) {
                if (!teamId || teams.find(team => team._id === teamId)) {
                    continue;
                }
                const loadedTeam = await this.team.loadTeam(teamId);
                if (!loadedTeam) {
                    continue;
                }
                const tempTeam = new Team({
                    _id: teamId,
                    name: loadedTeam.name,
                    members: [],
                });
                teams.push(tempTeam);
            }
        }

        if (this.options.value.type !== 'group') {
            this.createItemsForSelector(users, teams, groups);
            return;
        }

        for (const groupId of this.predefinedItems.value) {
            if (!groupId || groups.find(group => group._id === groupId)) {
                continue;
            }
            const loadedGroup = await this.groups.getGroup(groupId);
            if (!loadedGroup) {
                continue;
            }
            const tempGroup = new Group({
                _id: groupId,
                name: loadedGroup.name,
                users: [],
                teams: [],
                groups: [],
            });
            groups.push(tempGroup);
        }

        this.createItemsForSelector(users, teams, groups);
    }

    private createItemsForSelector(users?: User[], teams?: Team[], groups?: Group[]) {
        users = users ? users : this.getUsers();
        teams = teams ? teams : this.getTeams();
        groups = groups ? groups : this.getGroups();
        const items: DropdownItems = {};

        if (users?.length) {
            users.forEach(
                user =>
                    (items[user._id] = {
                        _id: user._id,
                        type: 'user',
                        title: user.display_name,
                    })
            );
        }

        if (teams?.length) {
            teams.forEach(
                team =>
                    (items[team._id] = {
                        _id: team._id,
                        type: 'team',
                        title: team.name,
                        meta: {
                            memberCount: team.members?.length,
                        },
                    })
            );
        }

        if (groups?.length) {
            groups.forEach(
                group =>
                    (items[group._id] = {
                        _id: group._id,
                        type: 'group',
                        title: group.name,
                        meta: {
                            memberCount: group.users.length,
                        },
                    })
            );
        }

        if (this.hideItems?.value?.length) {
            Object.keys(items).forEach(id => {
                if (this.hideItems.value?.includes(id)) {
                    delete items[id];
                }
            });
        }

        if (this.options.value.showMe && this.options.value.type === 'user') {
            items[this.core.user.value._id] = {
                _id: this.core.user.value._id,
                type: 'user',
                title: 'Me',
            };
        }

        this.items.next(items);
        this.setPredefinedItems();
        this.parentUpdate = false;
        this.cdr.detectChanges();
    }

    private selectAllUsersTeams() {
        if (this.selectAll.checked) {
            const selected: DropdownSelectedItems = {};
            this.items.forEach(items => {
                Object.keys(items).forEach(itemId => {
                    const itemType = items?.[itemId]?.type;
                    if (!itemType) {
                        return;
                    }

                    if (!selected[itemType]) {
                        selected[itemType] = [];
                    }
                    selected[itemType]?.push(itemId);
                });
            });

            this.selectedItems.next(selected);
        } else {
            this.selectedItems.next({});
        }
    }

    private setPredefinedItems() {
        if (!this.predefinedItems?.value?.length) {
            return;
        }

        const selected = this.options.value.multiple ? this.selectedItems.value : {};
        this.predefinedItems.value.forEach(id => {
            const item = this.items.value[id];

            if (!item) {
                return;
            }

            if (!selected[item.type]) {
                selected[item.type] = [id];
                return;
            }

            const alreadySelected = !!Object.values(selected).find(arr => arr.includes(id));

            if (alreadySelected) {
                return;
            }

            selected[item.type]?.push(id);
        });
        this.selectedItems.next(selected);
    }

    private getGroups(): Group[] {
        const showGroups = this.options.value.showGroups;

        if (!showGroups || !this.permission.isNetworkAdmin) {
            return [];
        }

        switch (showGroups) {
            case 'all':
                return this.groups.getGroups();
            default:
                return this.groups.getGroups({ workspaceId: showGroups });
        }
    }

    private getUsers(): User[] {
        const showUsers = this.options.value.showUsers;

        if (!showUsers) {
            return [];
        }

        switch (showUsers) {
            case 'all':
                return this.people.getUsers();
            default:
                return this.people.getNetworkUsers(showUsers);
        }
    }

    private getTeams(): Team[] {
        const showTeams = this.options.value.showTeams;

        if (!showTeams) {
            return [];
        }

        switch (showTeams) {
            case 'all':
                return this.team.getTeams({ workspaceId: this.options.value.workspaceId });
            default:
                return this.team.getUserTeams(showTeams, { workspaceId: this.options.value.workspaceId });
        }
    }
}
