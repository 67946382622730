/** @format */

import { GDrawable } from './gdrawable.model';
import { Location } from './activity.model';

export class GPolyline extends GDrawable<google.maps.Polyline> {
    constructor(polyline: google.maps.Polyline, infoWindow: google.maps.InfoWindow, editable: boolean, customId?: string) {
        super(polyline, infoWindow, editable, customId);
    }

    static fromHailerLocation(location: Location, draggable = false): GPolyline {
        if (location.type !== 'polyline') {
            console.error(new Error('gmap-component error: location type is not polyline'));
            return null;
        }

        if (location.data) {
            const polyline = new google.maps.Polyline({
                path: location.data,
            });
            return new GPolyline(polyline, null, draggable);
        }
        return null;
    }

    static fromGooglePolyline(polyline: google.maps.Polyline): GPolyline {
        return new GPolyline(polyline, null, true);
    }

    equals(other: any): boolean {
        if (other instanceof GPolyline) {
            if (this.data.getPath().getLength() === other.data.getPath().getLength()) {
                let res = true;
                // Should terminate once we find a pair that are not equal.
                for (let i = 0; i < this.data.getPath().getArray().length && res; i++) {
                    res = this.data.getPath().getAt(i).equals(other.data.getPath().getAt(i));
                }

                return res;
            }
        }

        return false;
    }
}
