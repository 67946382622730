<div class="content scrollable gray-bg" *ngIf="file && !error" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="file-view-header">
        <app-button
        *ngIf="(stackSize$ | async) > 1"
        type="icon"
        size="medium"
        color="icon-white-all-themes"
        (clickEvent)="popSidenav()"
        hailerIconName="hailer-tailed-arrow"
        matIconClass="back-arrow"
        class="files-back-button"
        tippyText="{{ 'files.file_view.close-first' | transloco }}"
        ariaLabel="{{ 'files.file_view.close-first-aria' | transloco }}"
        ></app-button>
       <h2 tippy="{{file.name}}">{{file.name}}</h2>
        <app-button
        type="icon"
        size="medium"
        color="icon-white-all-themes"
        (clickEvent)="clearSidenav()"
        hailerIconName="hailer-x"
        class="files-close-button"
        tippyText="{{ 'files.file_view.close' | transloco }}"
        ariaLabel="{{ 'files.file_view.close-aria' | transloco }}"></app-button>
    </div>

    <div *ngIf="type === 'image/png' || type === 'image/jpeg' || type === 'image/jpg' || type === 'image/gif'"
        style="max-height: 50vh; overflow: hidden;">
        <a href="{{originalUrl}}" target="_blank">
            <img crossorigin="use-credentials" class="header-img" draggable="false" [src]="hiresUrl" attr.alt="{{ 'files.file_view.file-open' | transloco }}">
        </a>
    </div>
    <div class="file-pdf" *ngIf="type === 'application/pdf'" style="max-width: 100%; max-height: 50%">
        <object [attr.data]="sourceUrl" type="application/pdf" width="100%" height="70vw"></object>
    </div>
    <div class="file-video" *ngIf="type === 'video/mp4'" style="max-width: 100%; max-height: 50%">
        <video width="100%" controls controlsList="nodownload">
            <source id="videoReload" [src]="sourceUrl" type="video/mp4">
        </video>
    </div>
    <div>
        <mat-divider></mat-divider>
        <div class="description-container">
            <p>{{ 'files.file_view.description_uploader' | transloco }}</p>
            <div class="uploader-container">
                <app-user-image-link (click)="openUserSidenav(file.uid)" [userId]="file.uid" [size]="40"
                    class="uploader-img"></app-user-image-link>
            </div>
        </div>
        <mat-divider aria-hidden="true"></mat-divider>
        <div class="description-container">
            <p>{{ 'files.file_view.description_uploaded' | transloco }}</p>
            <p>{{file.created | date: 'dd.MM.yyyy': undefined: ( 'misc.date.locale' | transloco )}}</p>
        </div>
        <mat-divider aria-hidden="true"></mat-divider>
        <div class="description-container">
            <p>{{ 'files.file_view.description_size' | transloco }}</p>
            <p>{{file.length | filesize}}</p>
        </div>
        <mat-divider aria-hidden="true"></mat-divider>
        <div class="description-container">
            <p>{{ 'files.file_view.description_type' | transloco }}</p>
            <p>{{fileType}}</p>
        </div>
        <mat-divider *ngIf="file.description && personalFilesSelected === false" aria-hidden="true"></mat-divider>
        <div *ngIf="file.description && personalFilesSelected === false" class="description-container">
            <p>{{ 'files.file_view.description_description' | transloco }}</p>
            <p>{{file.description}}</p>
        </div>
        <mat-divider *ngIf="file.updated && personalFilesSelected === true" aria-hidden="true"></mat-divider>
        <div *ngIf="file.updated && personalFilesSelected === true" class="description-container">
            <p>{{ 'files.file_view.description_last_edited' | transloco }}</p>
            <p>{{file.updated | date: 'dd.MM.yyyy h:mm:ss a z': undefined: ( 'misc.date.locale' | transloco)}}</p>
        </div>
        <mat-divider aria-hidden="true"></mat-divider>
        <div *ngIf="(tagSelectorOptions | async) && showTagSelector()" style="margin-top: 16px;">
            <app-tag-selector (untagged)="untagFile($event)" (tagged)="tagFileOrProcess($event)" [selectorOptions]="tagSelectorOptions">
            </app-tag-selector>
        </div>
    </div>
    <div class="download-container">
            <app-button
            type="text-with-icon"
            size="medium"
            color="primary"
            width="full"
            hailerIconName="hailer-download"
            (clickEvent)="filesService.download(file)"
            label="{{ 'files.file_view.download_button' | transloco }}"
            ariaLabel="{{ 'files.file_view.download_button' | transloco }}"
            ></app-button>
    </div>
    <div *ngIf="personalFilesSelected === true" class="personal-form-div">
        <mat-divider></mat-divider>
        <mat-form-field floatLabel="always" appearance="outline" class="personal-form-field">
            <mat-label>{{ 'files.file_view.filename_label' | transloco }}: </mat-label>
            <input matInput placeholder="{{ 'files.file_view.filename_label' | transloco }}" [(ngModel)]="file.name" (keyup.enter)="editFileProperties()"
                on-focusout="editFileProperties()">
        </mat-form-field>
        <mat-divider></mat-divider>
        <mat-form-field floatLabel="always" appearance="outline" class="personal-form-field">
            <mat-label>{{ 'files.file_view.description_description' | transloco }}: </mat-label>
            <input matInput placeholder="{{ 'files.file_view.description_description' | transloco }}" [(ngModel)]="file.description" (keyup.enter)="editFileProperties()"
                on-focusout="editFileProperties()">
        </mat-form-field>
        <mat-divider></mat-divider>
    </div>

    <div class="save-button-container" *ngIf="showSaveButtons">
        <button mat-stroked-button color="warn">{{ 'files.file_view.discard_button' | transloco }}</button>
        <button mat-stroked-button [disabled]="!enableSaveButton()" color="primary">{{ 'files.file_view.save_button' | transloco }}</button>
    </div>

</div>

<div class="sidenav-bg" style="display: flex;" *ngIf="!file && !loading || error">
    <div class="sidenav-error-container">
        <h4>{{ 'files.file_view.sidenav_error' | transloco }}</h4>
        <div class="close-error-buttons">
            <button mat-icon-button (click)="popSidenav()" *ngIf="(stackSize$ | async) > 1">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <button mat-icon-button (click)="clearSidenav()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</div>