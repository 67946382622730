/** @format */

import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appDropdownPreventClosing]',
})
export class DropdownPreventClosingDirective {
    @Output() appDropdownPreventClosing = new EventEmitter();

    @HostListener('click', ['$event']) onClick($event: Event) {
        $event.stopPropagation();
        this.appDropdownPreventClosing.emit($event);
    }
}
