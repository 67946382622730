/** @format */

import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { UserMap } from './../../_models/user.model';
import { PersonalSettings } from '@app/models';
import { CoreService } from 'app/_services/core.service';
import { PeopleService } from 'app/people/people.service';
import { UserService } from 'app/_services/user.service';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    selector: 'app-join-network-dialog',
    templateUrl: './join-network-dialog.component.html',
    styleUrls: ['./join-network-dialog.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'core',
                alias: 'core',
            },
        },
    ],
})
export class JoinNetworkDialogComponent implements OnInit, OnDestroy {
    user: BehaviorSubject<PersonalSettings>;
    invitedBy: string;
    defaultToNetwork = false;
    isManagedUser: boolean;

    private onDestroy = new Subject<void>();
    private companyId: string;
    private inviteKey: string;

    constructor(
        private core: CoreService,
        public dialogRef: MatDialogRef<JoinNetworkDialogComponent>,
        private userService: UserService,
        private peopleService: PeopleService,
        private cdr: ChangeDetectorRef,
        private rpc: RPCService,
        @Inject(MAT_DIALOG_DATA) public inviteData: any
    ) {}

    ngOnInit() {
        this.user = this.core.user;
        // Double negation casts to either true or false, used in .html
        this.isManagedUser = !!this.core.user.value.managedUser;
        if (this.inviteData.network) {
            this.invitedBy = this.peopleService.getUser(this.inviteData.invited_by).display_name;
        }

        this.companyId = this.inviteData.company ? this.inviteData.company._id : this.inviteData.cid;
        this.inviteKey = this.inviteData.invite ? this.inviteData.invite.invite_key : this.inviteData.invite_key;

        if (!this.inviteData.user) {
            void this.getInviteDataUser();
        }

        if (!this.inviteData.company) {
            this.peopleService.unknownUsers.pipe(takeUntil(this.onDestroy)).subscribe({
                next: (unknownUsers: UserMap) => {
                    if (this.user && Object.keys(unknownUsers).includes(this.inviteData.invited_by)) {
                        const user = unknownUsers[this.inviteData.invited_by];
                        this.invitedBy = user.display_name ? user.display_name : `${user.firstname} ${user.lastname}`;
                        this.cdr.detectChanges();
                    }
                },
            });
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    async getInviteDataUser() {
        try {
            this.inviteData = await this.rpc.requestAsync('company.check_invite', [this.inviteKey]);
        } catch (error) {
            console.error('Failed to fetch invite data', error);
        }

        this.cdr.detectChanges();
    }

    acceptInvitation() {
        if (this.defaultToNetwork) {
            this.setDefaultNetwork(this.companyId);
        }

        this.userService
            .acceptInvitation(this.inviteKey)
            .pipe(
                switchMap(async () => {
                    await this.core.initData();

                    if (this.defaultToNetwork) {
                        return this.core.switchNetwork(this.companyId);
                    }
                    return of([]);
                }),
                take(1)
            )
            .subscribe({
                error: error => console.error('Error accepting invitation', error),
            });

        this.dialogRef.close();
    }

    declineInvitation() {
        this.userService
            .declineInvitation(this.inviteKey)
            .pipe(take(1))
            .subscribe({
                error: (error: any) => console.error('Error declining invitation', error),
            });

        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    private setDefaultNetwork(networkId: string) {
        if (!networkId) {
            return;
        }

        this.peopleService
            .changeUserInfo('default_company', networkId)
            .pipe(take(1))
            .subscribe({
                error: (error: any) => console.error('error while setting default company', error),
            });
    }
}
