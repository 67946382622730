/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { File } from 'app/_models/file.model';
import { RPCService } from 'app/_services/rpc.service';
import { User } from '@app/models';
import { PeopleService } from 'app/people/people.service';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    // Super quick and dirty cache for file metadata...
    fileProperties = new BehaviorSubject<{ [fileId: string]: File }>({});
    fileOpen$: Observable<string>;

    private fileOpenSubject = new BehaviorSubject<string>(null);

    constructor(private rpcService: RPCService, private peopleService: PeopleService) {
        this.init();
    }

    getFileProperties(fileId: string): Observable<File> {
        const found = this.fileProperties.value[fileId];

        if (found) {
            return of<File>(found);
        }

        return this.fetchFileProperties(fileId).pipe(
            map((response: File) => {
                const files = this.fileProperties.value;
                files[fileId] = response;
                this.fileProperties.next(files);
                return response;
            })
        );
    }

    getPersonalFiles(): Observable<any> {
        return this.rpcService.request('files.my_files', []);
    }

    loadFileSets(): Observable<any> {
        return this.rpcService.request('files.load_filesets', []);
    }

    deleteFiles(fileIds: any[]): Observable<any> {
        return this.rpcService.request('files.remove_files', [fileIds]);
    }

    linkFiles(filesetId: string, selectedFiles: any[]): Observable<any> {
        return this.rpcService.request('files.link_files', [{ fset_id: filesetId, files: selectedFiles }]);
    }

    unlinkFiles(filesetId: string, selectedFiles: any[]): Observable<any> {
        return this.rpcService.request('files.unlink_files', [{ files: selectedFiles, fileset: filesetId }]);
    }

    editFileProperties(filedata: any[]): Observable<any> {
        return this.rpcService.request('files.set_file_properties', filedata);
    }

    openFile(fileId: string): void {
        this.fileOpenSubject.next(fileId);
    }

    fetchFileProperties(fileId: string): Observable<any> {
        return this.rpcService.request('files.get_file_properties', [fileId]);
    }

    getUser(uid: string): User {
        return this.peopleService.getUser(uid);
    }

    private init() {
        this.fileOpen$ = this.fileOpenSubject.asObservable();
    }
}
