/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { Discussion, MuteDuration } from '@app/models';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';

@Component({
    selector: 'app-mute-discussion-button',
    templateUrl: './mute-discussion-button.component.html',
    styleUrls: ['./mute-discussion-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'discussion', alias: 'discussion' } }],
})
export class MuteDiscussionButtonComponent implements OnInit {
    @Input() discussion: Discussion;
    @Input() menuMode = false;
    @Output() toggled = new EventEmitter<boolean>();

    veryLongTime = 315360000000000;

    constructor(private v3Discussion: V3DiscussionService, private cdr: ChangeDetectorRef) {}

    ngOnInit() {}

    toggleMuted(muteDuration: MuteDuration) {
        this.v3Discussion
            .mute(this.discussion._id, muteDuration)
            .pipe(take(1))
            .subscribe({
                next: () => this.toggled.emit(true),
                error: (error: any) => console.error('toggleMuted error', error),
            });
    }
}
