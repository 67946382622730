<!-- @format -->

<div class="modal-header flex-row--space-between">
	<h3 class="dialog-title">{{ "shared.user-invite-dialog.invite_users" | transloco }}</h3>
	<div class="popup-header-close-button-wrapper">
		<app-button
			type="icon"
			color="icon-white-all-themes"
			hailerIconName="hailer-x"
			(clickEvent)="closeModal()"
			ariaLabel="{{ 'shared.user-invite-dialog.close' | transloco }}"></app-button>
	</div>
</div>
<div mat-dialog-content style="flex: 1; display: flex; flex-direction: column">
	<div style="margin-top: 16px">
		<app-team-user-selector
			#teamUserSelector
			class="fill-field-container"
			[predefinedItems]="selectorPredefinedItems"
			[hideItems]="selectorHiddenItems"
			[options]="userSelectorOptions"
			(results)="addFollower($event)"></app-team-user-selector>
		<span *ngIf="invites.length > 0" class="invite-number">
			{{ invites.length }}{{ "shared.user-invite-dialog.users_selected" | transloco }}
		</span>
	</div>
	<div style="flex: 1; overflow: auto">
		<span *ngIf="users.length <= 0 && teams.length <= 0" class="invitations-text-empty-state">
			{{ "shared.user-invite-dialog.no_users_selected" | transloco }}
		</span>
		<div *ngIf="users.length > 0" style="margin-bottom: 16px">
			<span class="invited-item-header">{{ "shared.user-invite-dialog.users" | transloco }}</span>
			<ng-container *ngFor="let user of users">
				<div class="invited-item" *ngIf="!(selectorHiddenItems | async)?.includes(user._id)">
					<app-user-profile-picture matListAvatar [userId]="user._id" [size]="40" aria-hidden="true"></app-user-profile-picture>
					{{ user.firstname }} {{ user.lastname }}
					<app-button
						type="icon"
						color="icon-destructive"
						hailerIconName="hailer-x"
						(clickEvent)="removeSelection(user._id)"
						ariaLabel="{{ 'shared.user-invite-dialog.remove' | transloco }} {{ user.firstname }} {{ user.lastname }}"></app-button>
				</div>
			</ng-container>
		</div>
		<div *ngIf="teams.length > 0" style="margin-bottom: 16px">
			<span class="invited-item-header">{{ "shared.user-invite-dialog.teams" | transloco }}</span>
			<div *ngFor="let team of teams" class="invited-item">
				<mat-icon class="team-icon" svgIcon="hailer-group"></mat-icon>
				{{ team.name }}
				<app-button
					type="icon"
					color="icon-destructive"
					hailerIconName="hailer-x"
					(clickEvent)="removeSelection(team._id)"
					ariaLabel="{{ 'shared.user-invite-dialog.remove' | transloco }} {{ team.name }}"></app-button>
			</div>
		</div>
	</div>
	<ng-container *ngIf="!options.hideGuests">
		<div *ngIf="!options.allowGuests" style="color: grey; text-align: center; padding-bottom: 10px">
			{{ "shared.user-invite-dialog.inviting_guests_disabled" | transloco }}
		</div>
		<div *ngIf="canInviteGuests" class="guest-invite-container">
			<div [ngStyle]="{ 'pointer-events': options.allowGuests ? 'auto' : 'none' }" class="fill-field-container">
				<mat-form-field class="full-width" appearance="fill">
					<mat-label>{{ "shared.user-invite-dialog.add_guests_to_discussion" | transloco }}</mat-label>
					<input
						data-cy="guest-user-email-input"
						matInput
						type="text"
						placeholder="{{ 'shared.user-invite-dialog.guest_email_placeholder' | transloco }}"
						[formControl]="guestEmail" />
				</mat-form-field>
			</div>
			<button
				class="no-button for-icon"
				satPopoverAnchor
				#anchor="satPopoverAnchor"
				(click)="anchor.popover.toggle()"
				attr.aria-label="{{ 'shared.user-invite-dialog.info_button' | transloco }}">
				<mat-icon> help_outline </mat-icon>
			</button>
			<sat-popover
				[anchor]="anchor"
				hasBackdrop
				verticalAlign="below"
				attr.aria-label="{{ 'shared.user-invite-dialog.invite_guests_info' | transloco }}">
				<div class="popover-container mat-elevation-z8">
					{{ "shared.user-invite-dialog.invite_guests_info" | transloco }}
				</div>
			</sat-popover>
		</div>
	</ng-container>
</div>
<div class="dialog-buttons-container">
	<button data-cy="cancel-invites-button" class="cancel-btn" mat-button color="primary" type="button" (click)="closeModal()">
		{{ "shared.user-invite-dialog.cancel" | transloco }}
	</button>
	<button
		data-cy="send-invites-button"
		class="invite-btn"
		mat-stroked-button
		color="accent"
		[disabled]="disableSendButton"
		type="button"
		(click)="sendGuestInvites()"
		[mat-dialog-close]="invites">
		{{ "shared.user-invite-dialog.invite" | transloco }}
	</button>
</div>
