/** @format */

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { Message } from '@app/models';

@Component({
    selector: 'app-message-meet',
    templateUrl: './message-meet.component.html',
    styleUrls: ['./message-meet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'discussion', alias: 'discussion' } }],
})
export class MessageMeetComponent implements OnInit {
    @Input() message: Message;
    @Input() participantColorMap: any;

    password: string;

    ngOnInit(): void {
        if (this.message.discussion && this.message.discussion.length > 5) {
            this.password = this.message.discussion.substring(0, 5);
        }
    }
}
