/** @format */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { Activity } from '@app/models';
import { CoreService } from './core.service';
import { SideNavService } from './side-nav.service';
import { EventSidenavComponent } from 'app/events-shared/event-sidenav/event-sidenav.component';
import { V3ActivitySidenavComponent } from 'app/v3-activity/v3-activity-sidenav/v3-activity-sidenav.component';
import { RPCService } from './rpc.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FeedPostDialogComponent } from 'app/core/feed-post-dialog/feed-post-dialog.component';

@Injectable()
export class ApiNotificationService {
    loading = false;
    notifications: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    notificationsUnreadCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private router: Router, private core: CoreService, private rpc: RPCService, private sideNavService: SideNavService, private matDialog: MatDialog) {
        this.notifications = this.core.notifications;
        this.notificationsUnreadCount = this.core.notificationCount;
    }

    loadMoreNotifications() {
        this.core.loadMoreNotifications();
    }

    markAllNotificationsRead() {
        this.core.markAllNotificationsRead();
    }

    markAllNotificationsSeen() {
        this.core.markAllNotificationsSeen();
    }

    getUnreadNotificationCount() {
        this.core.getUnreadNotificationCount();
    }

    markNotificationAsRead(id: string): Observable<boolean> {
        return this.rpc.request('notification.mark_as_read', [id]);
    }

    notificationActivity(activity: Activity, isGuest?: boolean) {
        if (window.location.hash.includes('activities/process') || isGuest) {
            this.sideNavService.clear();
            this.sideNavService.create(V3ActivitySidenavComponent, {
                activityId: activity._id,
            });
        } else {
            this.router.navigate(['activities', 'process', activity.process, 'phase', activity.currentPhase, 'activity', activity._id]);
        }
    }

    notificationPhase(processId: string, phaseId: string) {
        this.router.navigate(['activities', 'process', processId, 'phase', phaseId]);
    }

    notificationEvent(eventId: string) {
        if (window.location.hash.includes('events')) {
            this.sideNavService.clear();
            this.sideNavService.create(EventSidenavComponent, {
                eventId,
            });
        } else {
            this.router.navigate(['events', eventId]);
        }
    }

    notificationMessenger(discussionId: string) {
        this.router.navigate(['discussions', discussionId]);
    }

    notificationFeed(postId: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = postId;
        dialogConfig.panelClass = 'feed-post-dialog';
        this.matDialog.open(FeedPostDialogComponent, dialogConfig);
    }
}
