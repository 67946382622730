/** @format */

import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { FormControl } from '@angular/forms';

import { GMarker, GPolygon, GPolyline, User } from '@app/models';

export class GMapConf {
    mapOptions?: google.maps.MapOptions;
    markers?: GMarker[] = [];
    polygons?: GPolygon[] = [];
    polylines?: GPolyline[] = [];
    showMarkers?: boolean;
    showPolygons?: boolean;
    showPolylines?: boolean;
    allowCreate?: boolean;
    allowSearch?: boolean;

    onMapReady?: (map: google.maps.Map) => void;
}

@Component({
    selector: 'app-gmap',
    templateUrl: './gmap.component.html',
    styleUrls: ['./gmap.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class GmapComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() config: GMapConf;
    @ViewChild('mapDiv', { static: true }) mapDiv: ElementRef;
    @ViewChild('searchPlacesInput', { static: false }) searchPlacesInput: ElementRef;

    map: google.maps.Map;
    googleDefined: boolean;
    defaultMapOptions: google.maps.MapOptions;
    defaultConfig: GMapConf;

    // Marker observables
    markerClicks$ = new Subject<GMarker>();
    markerDragDone$ = new Subject<GMarker>();
    markerAdded$ = new Subject<GMarker>();

    // Polyline observables
    polylineClicks$ = new Subject<GPolyline>();
    polylineDragDone$ = new Subject<GPolyline>();
    polylineAdded$ = new Subject<GPolyline>();

    // Polygon observables
    polygonClicks$ = new Subject<GPolygon>();
    polygonDragDone$ = new Subject<GPolygon>();
    polygonAdded$ = new Subject<GPolygon>();

    searchPlacesForm = new FormControl<string>('');
    searchResults = new BehaviorSubject<any[]>([]);

    private markers: GMarker[];
    private polygons: GPolygon[];
    private polylines: GPolyline[];

    private markerArray: (GMarker | GPolygon | GPolyline)[] = [];

    private options: google.maps.MapOptions | undefined;

    // Drawing configurations
    private typeMarker: any;
    private typePolygon: any;
    private typePolyline: any;

    private drawManagerOptions: google.maps.drawing.DrawingManagerOptions;
    private drawManager: google.maps.drawing.DrawingManager | null;

    private onDestroy = new Subject<void>();

    constructor() {}

    ngOnInit() {
        this.init();

        if (this.googleDefined) {
            if (!this.config) {
                this.config = this.defaultConfig;
            }

            this.options = this.config.mapOptions ? this.config.mapOptions : this.defaultConfig.mapOptions;
            this.markers = (this.config.markers ? this.config.markers : this.defaultConfig.markers) || [];
            this.polygons = (this.config.polygons ? this.config.polygons : this.defaultConfig.polygons) || [];
            this.polylines = (this.config.polylines ? this.config.polylines : this.defaultConfig.polylines) || [];

            this.markerArray.push(...this.markers);
            this.markerArray.push(...this.polygons);
            this.markerArray.push(...this.polylines);

            this.map = new google.maps.Map(this.mapDiv.nativeElement, this.options);

            google.maps.event.addListenerOnce(this.map, 'idle', () => {
                this.initMarkers();
                this.setDrawState(this.config.allowCreate || false);
                if (this.config.onMapReady) {
                    this.config.onMapReady(this.map);
                }
            });
        }
    }

    ngAfterViewInit(): void {
        this.setSearchField();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    setSearchField() {
        if (!this.config.allowSearch) {
            return;
        }

        const searchInput = this.searchPlacesInput?.nativeElement;
        if (!searchInput) {
            return void console.error('Cannot find search input');
        }

        const options: google.maps.places.AutocompleteOptions = {
            bounds: this.map.getBounds(),
        };

        const searchBox = new google.maps.places.Autocomplete(searchInput, options);
        searchBox.addListener('place_changed', () => {
            const place = searchBox.getPlace();

            if (!place) {
                console.error('Place is not defined');
                return;
            }

            if (!place.geometry?.location) {
                console.error('Place contains no geometry');
                return;
            }

            this.markerChange(
                new google.maps.Marker({
                    map: this.map,
                    title: place.name,
                    position: place.geometry.location,
                })
            );

            const bounds = new google.maps.LatLngBounds();
            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }

            this.map.fitBounds(bounds);
        });
    }

    fitMarkers() {
        if (this.markerArray.length > 0) {
            const bounds = new google.maps.LatLngBounds();
            this.markerArray.forEach(marker => {
                marker.getLocationData().forEach(ltLn => bounds.extend(ltLn));
            });
            this.fitBounds(bounds, 14);
        }
    }

    fitBounds(bounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral, maxZoom?: number) {
        this.map.fitBounds(bounds);
        this.map.panToBounds(bounds);
        const mapZoom = this.map.getZoom() || 0;

        if (maxZoom && mapZoom > maxZoom) {
            this.map.setZoom(maxZoom);
        }
    }

    removeAllMarkers() {
        for (let i = this.markerArray.length - 1; i >= 0; i--) {
            this.markerArray[i].remove();
            this.markerArray.splice(i, 1);
        }
    }

    addNewMarker(marker: GMarker | GPolygon | GPolyline) {
        if (this.markerArray.length > 0) {
            this.replaceMarkerAt(marker, 0);
        } else {
            this.addMarker(marker);
        }
    }

    panTo(position: google.maps.LatLng) {
        this.map.panTo(position);
    }

    redrawMarkers() {
        this.initMarkers();
    }

    replaceMarkerAt(newMarker: GMarker | GPolygon | GPolyline, index: number) {
        if (!newMarker) {
            throw new Error('newMarker must be defined!');
        }

        if (index >= this.markerArray.length) {
            throw new Error(`Index out of range. ${this.markerArray.length} < ${index}`);
        }

        this.markerArray[index].data.setMap(null);
        this.markerArray[index] = newMarker;
        this.drawMarker(this.markerArray[index]);
    }

    replaceMarker(newMarker: GMarker | GPolygon | GPolyline, oldMarker: GMarker | GPolygon | GPolyline) {
        if (!newMarker || !oldMarker) {
            throw new Error('You need to provide both new marker and the marker to be replaced');
        }

        const foundI = this.markerArray.findIndex(it => it.equals(oldMarker));

        if (foundI < 0) {
            throw new Error('No old marker found in array!');
        }

        this.replaceMarkerAt(newMarker, foundI);
    }

    replaceAllMarkers(newMarkers: (GMarker | GPolygon | GPolyline)[]) {
        this.removeAllMarkers();

        this.markerArray.push(...newMarkers);

        this.redrawMarkers();
    }

    // Skip duplicates
    addMarker(markerData: GMarker | GPolygon | GPolyline) {
        const foundI = this.markerArray.findIndex(it => it.equals(markerData));

        if (foundI < 0) {
            this.markerArray.push(markerData);
            this.drawMarker(markerData);
        }
    }

    addMultipleMarkers(markerArray: (GMarker | GPolygon | GPolyline)[]) {
        markerArray.forEach(m => this.addMarker(m));
    }

    removeMarker(marker: GMarker) {
        const i = this.markerArray.findIndex(mark => mark.equals(marker));
        if (i >= 0) {
            this.markerArray.splice(i, 1);
            marker.remove();
        } else {
            marker.data.setMap(null);

            console.error(
                // eslint-disable-next-line
                'GMap error - No matching marker found. Managed to remove marker from the map, but for some reason it was not in the maps Marker array...'
            );
        }
    }

    markerChange(marker: google.maps.Marker) {
        const newMarker = GMarker.fromGoogleMarker(marker);
        this.addNewMarker(newMarker);
        this.markerAdded$.next(newMarker);
    }

    setDrawState(state: boolean) {
        if (state) {
            this.drawManager = new google.maps.drawing.DrawingManager(this.drawManagerOptions);
            this.drawManager.setMap(this.map);

            this.drawManager.addListener('markercomplete', (marker: google.maps.Marker) => {
                this.markerChange(marker);
            });

            this.drawManager.addListener('polygoncomplete', (polygon: google.maps.Polygon) => {
                const newPoly = GPolygon.fromGooglePolygon(polygon);
                this.addNewMarker(newPoly);
                this.polygonAdded$.next(newPoly);
            });

            this.drawManager.addListener('polylinecomplete', (polyline: google.maps.Polyline) => {
                const newPoly = GPolyline.fromGooglePolyline(polyline);
                this.addNewMarker(newPoly);
                this.polylineAdded$.next(newPoly);
            });
        } else if (this.drawManager) {
            // Remove the drawing capabilities and unset the draw manager.
            this.drawManager.setMap(null);
            this.drawManager = null;
        }
    }

    addUsersToMap(users: User[], replaceMarker = false) {
        const markers = users.filter(u => u.lastLocation).map(u => GMarker.fromUser(u));
        if (replaceMarker) {
            this.replaceAllMarkers(markers);
        } else {
            this.addMultipleMarkers(markers);
        }
    }

    addUserToMap(user: User, replaceMarker: boolean) {
        this.addUsersToMap([user], replaceMarker);
    }

    get mapLoaded(): boolean {
        return !!this.map;
    }

    get markerList(): (GMarker | GPolygon | GPolyline)[] {
        return this.markerArray;
    }

    getZoom(): number {
        return this.map.getZoom() || 0;
    }

    private init() {
        this.googleDefined = !!(window as any).google;

        if (!this.googleDefined) {
            return;
        }

        // Drawing configurations
        this.typeMarker = google.maps.drawing.OverlayType.MARKER;
        this.typePolygon = google.maps.drawing.OverlayType.POLYGON;
        this.typePolyline = google.maps.drawing.OverlayType.POLYLINE;

        this.drawManagerOptions = {
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [this.typeMarker, this.typePolygon, this.typePolyline],
            },
            polygonOptions: {
                clickable: true,
                draggable: true,
                editable: true,
                // @ts-ignore: Undocumented feature
                suppressUndo: true,
            },
            polylineOptions: {
                clickable: true,
                draggable: true,
                editable: true,
                // @ts-ignore: Undocumented feature
                suppressUndo: true,
            },
        };

        this.defaultConfig = {
            allowCreate: false,
            mapOptions: this.defaultMapOptions,
            markers: [],
            polygons: [],
            polylines: [],
            showPolygons: false,
            showPolylines: false,
            showMarkers: false,
        };

        this.defaultMapOptions = {
            center: { lat: 60.394266, lng: 25.659521 },
            zoom: 13,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            // Hide unnecessary controls
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            gestureHandling: 'cooperative',
        };

        this.defaultConfig = {
            allowCreate: false,
            mapOptions: this.defaultMapOptions,
            markers: [],
            polygons: [],
            polylines: [],
            showPolygons: false,
            showPolylines: false,
            showMarkers: false,
        };
    }

    private initMarkers() {
        if (this.markerArray.length) {
            const bounds = new google.maps.LatLngBounds();
            this.markerArray.forEach(mark => {
                this.drawMarker(mark);
                mark.getLocationData().forEach(ltLn => bounds.extend(ltLn));
            });
            this.fitBounds(bounds, 14);
        }
    }

    private drawMarker(data: GMarker | GPolygon | GPolyline) {
        const marker = data.data;
        const window = data.infoWindow;

        marker.setMap(this.map);
        if (window) {
            marker.addListener('mouseover', () => {
                window.open(this.map, marker);
            });

            marker.addListener('mouseout', () => {
                window.close();
            });
        }

        marker.addListener('click', () => {
            if (data instanceof GMarker) {
                this.markerClicks$.next(data);
            } else if (data instanceof GPolygon) {
                this.polygonClicks$.next(data);
            } else if (data instanceof GPolyline) {
                this.polylineClicks$.next(data);
            }
        });
        marker.addListener('mouseup', () => {
            if (data instanceof GMarker) {
                this.markerDragDone$.next(data);
            } else if (data instanceof GPolygon) {
                this.polygonDragDone$.next(data);
            } else if (data instanceof GPolyline) {
                this.polylineDragDone$.next(data);
            }
        });
    }
}
