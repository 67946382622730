<ng-container *ngIf="!ready">
    <div class="placeholder">
        <div style="height: 113px;"></div>
        <svg height="56" width="100%">
            <line x1="0" y1="0" x2="100%" y2="0" style="stroke:grey; stroke-width:2"/>
            <circle cx="28" cy="28" r="20" stroke="white" stroke-width="2" fill="grey"></circle>
            <circle cx="60" cy="28" r="20" stroke="white" stroke-width="2" fill="grey"></circle>
            <circle cx="92" cy="28" r="20" stroke="white" stroke-width="2" fill="grey"></circle>
            <circle cx="calc(100% - 76px)" cy="28" r="20" stroke="white" stroke-width="2" fill="grey"></circle>
            <circle cx="calc(100% - 32px)" cy="28" r="20" stroke="white" stroke-width="2" fill="grey"></circle>
            <line x1="0" y1="56" x2="100%" y2="56" style="stroke:grey; stroke-width:2"/>
        </svg>
        <svg height="48" width="100%">

            <line x1="0" y1="48" x2="100%" y2="48" style="stroke:grey; stroke-width:2"/>
        </svg>
        <svg height="45" width="100%">
            <rect x="16" y="8" width="80" height="18" fill="grey"></rect>
            <line x1="0" y1="45" x2="100%" y2="45" style="stroke:grey; stroke-width:2"/>
        </svg>
        <svg height="56" width="100%">
            <rect x="16" y="20" width="70" height="14" fill="grey"></rect>
            <rect x="136" y="20" width="60%" height="14" fill="grey"></rect>
            <line x1="0" y1="56" x2="100%" y2="56" style="stroke:grey; stroke-width:2"/>
        </svg>
        <svg height="56" width="100%">
            <rect x="16" y="20" width="60" height="14" fill="grey"></rect>
            <rect x="136" y="20" width="30%" height="14" fill="grey"></rect>
            <line x1="0" y1="56" x2="100%" y2="56" style="stroke:grey; stroke-width:2"/>
        </svg>
        <svg height="56" width="100%">
            <rect x="16" y="20" width="80" height="14" fill="grey"></rect>
            <rect x="136" y="8" width="60%" height="35" fill="grey"></rect>
            <line x1="0" y1="56" x2="100%" y2="56" style="stroke:grey; stroke-width:2"/>
        </svg>
        <svg height="56" width="100%">
            <rect x="16" y="20" width="70" height="14" fill="grey"></rect>
            <rect x="136" y="20" width="50%" height="14" fill="grey"></rect>
            <line x1="0" y1="56" x2="100%" y2="56" style="stroke:grey; stroke-width:2"/>
        </svg>
    </div>
    <div class="loading-content">
        <mat-spinner diameter="40" style="margin-top: -20px" mode="indeterminate"></mat-spinner>
    </div>
</ng-container>