<!-- @format -->

<form [formGroup]="loginForm" (ngSubmit)="sendUserLogin()">
    <div class="flex-column--centered">
        <mat-form-field color="accent" floatLabel="always" appearance="outline" class="form-field">
            <mat-label attr.aria-label="{{ 'public.login-form.email_label' | transloco }}">{{
                'public.login-form.email_label' | transloco
            }}</mat-label>
            <input
                data-cy="login-email-input"
                id="email"
                autocomplete="email"
                matInput
                [appAutofocus]="true"
                formControlName="username"
                type="email"
                placeholder="{{ 'public.register-form.email_placeholder' | transloco }}"
                [ngClass]="{ 'is-invalid': (saving | async) && loginForm.controls.username?.errors }"
                class="form-control" />
            <mat-error *ngIf="emailFieldInvalid()">{{ 'public.register-form.email_invalid_hint' | transloco }}</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" floatLabel="always" appearance="outline" class="form-field">
            <mat-label attr.aria-label="{{ 'public.login-form.password_label' | transloco }}">{{
                'public.login-form.password_label' | transloco
            }}</mat-label>
            <input
                data-cy="login-password-input"
                id="password"
                autocomplete="current-password"
                matInput
                formControlName="password"
                placeholder="{{ 'public.login-form.enter_password' | transloco }}"
                class="form-control"
                [type]="passwordVisible ? 'text' : 'password'" />
            <mat-icon
                class="password-visibility-button"
                matSuffix
                [svgIcon]="passwordVisible ? 'hailer-show' : 'hailer-hide'"
                (click)="passwordVisible = !passwordVisible"></mat-icon>
            <!--<mat-hint *ngIf="loginForm.controls.password.errors && loginForm.controls.password.errors.required">Password is required</mat-hint>-->
            <mat-error
                data-cy="invalid-credentials-hint"
                *ngIf="loginForm.controls.password?.errors && loginForm.controls.password?.errors?.inValid"
                >{{ 'public.login-form.wrong_hint' | transloco }}</mat-error
            >
            <mat-error
                data-cy="blocked-credentials-hint"
                *ngIf="loginForm.controls.password?.errors && loginForm.controls.password?.errors?.loginBlocked"
                >{{ 'public.login-form.login_blocked' | transloco }}</mat-error
            >
        </mat-form-field>
    </div>
    <div *ngIf="hasForgotPasswordLink && !loginForm.controls.password?.errors?.loginBlocked" class="forgot-password-wrapper">
        <a data-cy="forgot-password-link" class="login-link" [routerLink]="['/forgotpassword']">{{
            'public.login-form.forgot_password_link' | transloco
        }}</a>
    </div>
    <div class="login__button-container--multiple-buttons">
        <button data-cy="login-button" mat-flat-button class="single-row-button primary-public-pages" [disabled]="logInDisabled()">
            {{ (saving | async) ? ('public.login-form.button_login_1' | transloco) : ('public.login-form.button_login_2' | transloco) }}
        </button>
    </div>
    <div class="flex-column--centered">
        <div *ngFor="let providerId of providerIds" class="login__button-container--multiple-buttons">
            <button
                *ngIf="!token && !(saving | async)"
                mat-flat-button
                class="single-row-button primary-public-pages"
                (click)="signIn(providerId)">
                {{ 'public.login-form.button_login_2' | transloco }} {{ providerId }}
            </button>
        </div>
        <div *ngIf="token && (saving | async)" class="flex-column--centered">
            {{ 'public.login-form.button_login_1' | transloco }}
            <mat-spinner color="accent" diameter="25" mode="indeterminate"></mat-spinner>
        </div>
        <ng-container *ngIf="oidcLoginError">
            <span class="login-error">{{ oidcLoginError }}</span>
        </ng-container>
    </div>

    <div class="flex-column--centered">
        <ng-container *ngIf="googleLoginError">
            <span class="login-error">{{ googleLoginError }}</span>
        </ng-container>
        <div id="googleBtn" class="login-button-container-google-button"></div>
        <div *ngIf="isMobile" class="login__button-container--multiple-buttons">
            <button mat-flat-button class="single-row-button g-sign-in-button-mobile" (click)="signInGoogleCapacitor()">
                <div class="content-container">
                    <img src="/assets/icons/g-logo.png" />
                    <span>{{ 'public.login-form.sign_in_google' | transloco }}</span>
                </div>
            </button>
        </div>
    </div>

    <ng-container *ngIf="registeringError">
        <span class="login-error">{{ 'public.login-form.register_error' | transloco }}</span>
    </ng-container>
    <ng-container *ngIf="loggingInError">
        <span class="login-error">{{ 'public.login-form.login_error' | transloco }}</span>
    </ng-container>
</form>
