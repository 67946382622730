/** @format */

import { Attribute, Directive, HostBinding } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[matInput]',
})

/** This directive selects ALL matInput elements and sets an id and autocomplete attributes to them if not already set */
export class PreventAutofillDirective {
    @HostBinding('attr.id') _id;
    @HostBinding('attr.autocomplete') auto;
    constructor(@Attribute('id') id: string, @Attribute('autocomplete') autocomplete: string) {
        this._id = id || 'prevent-autofill-input';
        this.auto = autocomplete || 'off';
    }
}
