/** @format */

import { createAction, props } from '@ngrx/store';

import { PersonalSettings } from '@app/models';
import { GlobalSettings } from 'app/_models/globalSettings.model';

export const loadPersonalSettings = createAction('[Personal Settings] Load Personal Settings');

export const loadPersonalSettingsSuccess = createAction(
    '[Personal Settings API] Load Personal Settings Success',
    props<{
        personalSettings: PersonalSettings;
    }>()
);

export const setPersonalSettings = createAction(
    '[Personal Settings] Set Personal Settings',
    props<{
        personalSettings: PersonalSettings;
    }>()
);

export const setGlobalSettings = createAction(
    '[Personal Settings] Set Global Settings',
    props<{
        globalSettings: GlobalSettings;
    }>()
);

export const setGlobalSettingsSuccess = createAction('[Personal Settings] Set Global Settings Success');
