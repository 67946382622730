/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatMenu } from '@angular/material/menu';
import { UntypedFormControl } from '@angular/forms';

import { Activity, Company, User } from '@app/models';
import { GlobalSearch, GlobalSearchFilePath } from 'app/_models/globalSearch.model';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { ActivitiesService } from 'app/activities/activities.service';
import { ApiNotificationService } from 'app/_services/notification.service';
import { CoreService } from 'app/_services/core.service';
import { FeedPostDialogComponent } from 'app/core/feed-post-dialog/feed-post-dialog.component';
import { FileViewComponent } from 'app/files-shared/file-view/file-view.component';
import { PeopleService } from 'app/people/people.service';
import { PermissionService } from 'app/_services/permission.service';
import { RoutingService } from 'app/_services/routing.service';
import { SearchService } from 'app/_services/search.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
    @Input() modalMode = false;
    @Input() dataCy: string;
    @ViewChild('modalSearchInput', { static: false }) modalSearchInput: MatInput;
    @ViewChild('searchInput', { static: false }) searchInput: MatInput;
    @ViewChild('searchMenu', { static: false }) searchMenu: MatMenu;

    searchControl = new UntypedFormControl();
    searchData: GlobalSearch = {};
    network: Company;
    me: any;
    loading = false;
    filterBar = false;
    filters = {
        users: true,
        activities: true,
        events: true,
        feed: true,
        messages: true,
        files: true,
    };
    modalOpen = false;

    private onDestroy = new Subject<void>();
    private query = '';
    private appContainer: HTMLElement;

    constructor(
        private zone: NgZone,
        private search: SearchService,
        private peopleService: PeopleService,
        private activitiesService: ActivitiesService,
        private router: RoutingService,
        private sideNavService: SideNavService,
        private core: CoreService,
        private matDialog: MatDialog,
        private notificationService: ApiNotificationService,
        private cdr: ChangeDetectorRef,
        public permissions: PermissionService,
        private translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        this.appContainer = document.getElementById('appContainer');

        this.searchControl.valueChanges.pipe(takeUntil(this.onDestroy), debounceTime(300)).subscribe((query: string) => {
            this.query = query;
            this.globalSearch();
            this.cdr.detectChanges();
        });

        this.core.user.pipe(takeUntil(this.onDestroy)).subscribe(me => (this.me = me));
        this.core.network.pipe(takeUntil(this.onDestroy)).subscribe(network => (this.network = network));
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
        enableBodyScroll(this.appContainer);
    }

    get allFilters(): string[] {
        return Object.keys(this.filters);
    }

    getHeaderText(): string {
        if (this.loading) {
            return `${this.translocoService.translate('shared.global-search.ts_searching_for')} ${this.query}...`;
        }

        if (this.allFilters.every(filter => !this.filters[filter])) {
            return this.translocoService.translate('shared.global-search.ts_choose_one_filter');
        }

        if (!this.query || this.query.length < 3) {
            return this.translocoService.translate('shared.global-search.ts_minimum_characters_to_search');
        }

        let total = 0;
        Object.keys(this.searchData).forEach(key => {
            total += this.searchData[key].length;
        });

        if (total === 0) {
            return `${this.translocoService.translate('shared.global-search.ts_no_results_for')} ${this.query}.`;
        }

        return this.translocoService.translate('shared.global-search.ts_search_result_for', { total, query: this.query });
        // + `${total} Search result${total > 0 ? 's' : ''} for ${this.query}.`;
    }

    globalSearch(): void {
        if (this.query && this.query.length >= 3) {
            this.loading = true;
            const filterArray = this.allFilters.filter(filter => this.filters[filter]);

            this.search
                .globalSearch(this.query, filterArray)
                .pipe(takeUntil(this.onDestroy))
                .subscribe(
                    (searchData: GlobalSearch) => {
                        this.loading = false;
                        if (searchData.messages) {
                            searchData.messages = this.search.overflowMessages(searchData.messages, this.query);
                        } else {
                            searchData.messages = [];
                        }
                        this.searchData = searchData;
                        console.log(searchData);
                        this.cdr.detectChanges();
                    },
                    err => {
                        this.loading = false;
                        this.searchData = {};
                        this.cdr.detectChanges();
                        console.error(err);
                    }
                );
        } else {
            this.searchData = {};
        }
        this.cdr.detectChanges();
    }

    getUser(id: string): User {
        return this.peopleService.getUser(id);
    }

    getPhaseName(processId: string, phaseId: string): Observable<string> {
        return this.activitiesService.getProcess(processId).pipe(map(process => `${process[0].name} - ${process[0].phases[phaseId].name}`));
    }

    toggleFollow(activity: Activity): void {
        this.activitiesService.followActivity(activity._id).subscribe(resp => {
            if (resp) {
                activity.followers.push(this.me._id);
            } else {
                const index = activity.followers.findIndex(id => this.me._id === id);
                activity.followers.splice(index, 1);
            }
        });
    }

    getDisplayName(userId: string): string {
        return this.peopleService.getUser(userId).display_name;
    }

    updateFilters(filter: string): void {
        this.filters[filter] = !this.filters[filter];
        this.globalSearch();
    }

    resetInput(): void {
        this.searchControl.setValue('');
    }

    async searchSelect(type: string, data: any): Promise<void> {
        this.searchControl.setValue('');
        switch (type) {
            case 'users':
                this.sideNavService.clear();
                this.sideNavService.create(UserDetailComponent, {
                    userId: data,
                });
                break;
            case 'activities':
                await this.router.navigate(['activities', 'activity', data]);
                break;
            case 'events':
                this.notificationService.notificationEvent(data);
                break;
            case 'feed':
                this.zone.run(() => {
                    this.matDialog.open(FeedPostDialogComponent, {
                        data,
                        panelClass: 'feed-post-dialog',
                    });
                });
                break;
            case 'files':
                await this.router.navigate(['files']).then(() => {
                    this.sideNavService.clear();
                    this.sideNavService.create(FileViewComponent, {
                        fileId: data,
                    });
                });
                break;
            case 'messages':
                const discussionId = data?.discussion;
                await this.router.navigate(['discussions', discussionId], { queryParams: { messageId: data._id } });
                break;
            default:
                console.log('error');
        }
    }

    focusModalSearchInput(): void {
        setTimeout(() => this.modalSearchInput?.focus(), 0);
    }

    openModal(): void {
        this.modalOpen = true;
        disableBodyScroll(this.appContainer);
    }

    closeModal(): void {
        this.modalOpen = false;
        enableBodyScroll(this.appContainer);
    }

    getFilePath(path: GlobalSearchFilePath): string {
        const type = path.type[0].toUpperCase() + path.type.slice(1);
        const name = path.name;

        return type.toLocaleLowerCase() !== 'file' ? `${type} / ${name}` : 'File';
    }

    /* #region track by functions */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
    trackById = (_index: number, item: any): string => item._id;

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    trackByFn = (index: number, item: unknown): string => `${item} ${index}`;
}
