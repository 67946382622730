/** @format */

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    Renderer2,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { TranslocoService } from '@ngneat/transloco';
import { TranslateService } from 'app/_services/translate.service';

/**
 * Button component for basic button styles.
 *
 * Variants can be viewed at Developer Playground.
 *
 * Buttons in Hailer Design System: https://www.figma.com/file/gnBpr38fzpkYGU6NFTDspA/Atoms?node-id=2-7&t=8YPPIWon8Rp3mH02-0
 */

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    providers: [],
})
export class ButtonComponent implements OnInit, AfterViewInit {
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    /**
     * The type variant of the button. Defaults to 'text'.
     *
     * 'save', 'delete', 'cancel' and 'ok' types are text-type buttons with ready-made label, aria-label
     * and styling. They are made to be used on primary background (= white on light mode)
     * and might not be suitable for all kinds of backgrounds.
     */
    @Input() type: 'cancel' | 'count' | 'delete' | 'icon' | 'ok' | 'save' | 'text-with-icon' | 'text' = 'text';
    /**
     * The color variant of the button. Defaults to 'primary'.
     * Variants starting with "icon-" are for icon-buttons that have a transparent background.
     */
    @Input() color:
        | 'destructive-default'
        | 'destructive-toned-down'
        | 'icon-accent'
        | 'icon-default'
        | 'icon-destructive'
        | 'icon-success'
        | 'icon-white-all-themes'
        | 'primary-soft'
        | 'primary'
        | 'secondary'
        | 'tertiary-default-background'
        | 'tertiary-highlight-background'
        | 'tertiary-soft' = 'primary';
    /**
     * The size variant of the button. Defaults to 'medium'.
     * Exception: 'icon' type buttons can't be of size 'large'.
     */
    @Input() size: 'large' | 'medium' | 'small' = 'medium';
    /**
     * When width is 'full', the button will take full width (100 %) of the parent
     * container (but not exceeding 360 px).
     *
     * When width is 'half', the button will take 50 % width of the parent container
     * (but not exceeding 360 px).
     *
     * 'popup-single-button' is for setting the width for a single one-line button in a popup.
     * Width will be 175 px, and width can be max 100 % of the parent container (but not exceeding 360 px).
     *
     * 'popup-two-buttons' is for setting the width for two side-by-side buttons in a popup.
     * Width for a button will be 175 px, and width can be max 50 % of the parent container (but not exceeding 360 px).
     */
    @Input() width: 'full' | 'half' | 'popup-single-button' | 'popup-two-buttons';
    /**
     * 'focusOutline' is for cases when the outline can't be outer as by default. Do not use with 'primary', 'secondary' or 'destructive-default' buttons as contrast for accessibility will not be sufficient.
     */
    @Input() focusOutline: 'inner' | 'outer' = 'outer';
    /**
     * The name of Hailer icon to be shown in the button.
     */
    @Input() hailerIconName: string;
    /**
     * The label text for the button.
     */
    @Input() label: string;
    /**
     * The aria-label (text alternative for screen readers) for the button.
     *
     * This is required for accessibility, and for all types of buttons.
     *
     * Aria-label can be the same as the label of the button if that is
     * descriptive enough to give the user a clear understanding of what the button does.
     *
     * Aria-label is needed not only for better describing the purpose of the button,
     * but to prevent screen readers reading uppercase button labels letter by letter.
     *
     * Remember to give translations.
     */
    @Input() ariaLabel: string;
    /**
     * When 'isDisabled', the button will be aria-disabled, meaning the button can be focused on keyboard, but is not clickable.
     */
    @Input() isDisabled = false;
    /**
     * When 'isLoading', the button will show a loading spinner instead of text or icon
     * and the button is disabled (not aria-disabled). Button will keep it's original width.
     */
    @Input() isLoading = false;
    /**
     * The text to show in the tooltip.
     */
    @Input() tippyText: string;
    /**
     * 'data-cy' value.
     */
    @Input() dataCy: string;

    /**
     * Sets a specific class to the mat-icon inside the button component.
     * Only used for certain cases e.g. arrows, stars, x, minus and plus
     */
    @Input() matIconClass: string;

    /**
     * [matMenuTriggerFor]
     */
    @Input() menuTriggerFor: MatMenu;
    /**
     * [matMenuTriggerData]
     */
    @Input() menuTriggerData: any;

    /**
     * Used for the count button, sets the number inside the button and the aria label
     */
    @Input() count: number;

    /**
     * Sets the border radius of the button. Default is used for all buttons except specific ones (activity and event sidenav cancel/save buttons)
     */
    @Input() borderRadius: 'default-radius' | 'rectangular-radius' = 'default-radius';

    /**
     * '[focusInitial]="true"' sets 'cdkFocusInitial' to the button element inside app-button.
     */
    @Input() focusInitial = false;

    /**
     * The optional link URL to navigate to when the button is clicked.
     * If provided, the button will act as a link, and look like a button.
     */
    @Input() link: string;

    /**
     * The target for opening the link.
     * Is '_self' by default, meaning the link will open in the current browsing context.
     * Other 'target' values can be used, like '_blank', which makes the link open in a new window or tab.
     */
    @Input() target = '_self';

    /**
     * The relationship between the linked resource and the current document.
     */
    @Input() rel: string;

    @Output() clickEvent = new EventEmitter<MouseEvent>();

    draftButton: string;
    saveButton: string;
    cancelButton: string;
    deleteButton: string;
    okButton: string;
    countButton: string;
    countButtonAria: string;

    constructor(
        private translocoService: TranslocoService,
        private translateService: TranslateService,
        private cdr: ChangeDetectorRef,
        private renderer: Renderer2,
        private appButton: ElementRef
    ) {}

    async ngOnInit(): Promise<void> {
        this.checkCombinations();
        if (this.type === 'count') {
            this.countButton = `+${this.formatNumber(this.count)}`;
        }

        await this.translateService.translationLoaded('buttons');
        this.saveButton = this.translocoService.translate('buttons.buttons.save');
        this.draftButton = this.translocoService.translate('buttons.buttons.save');
        this.cancelButton = this.translocoService.translate('buttons.buttons.cancel');
        this.deleteButton = this.translocoService.translate('buttons.buttons.delete');
        this.okButton = this.translocoService.translate('buttons.buttons.ok');
        if (this.type === 'count') {
            this.countButtonAria = `${this.translocoService.translate('buttons.buttons.count-aria-see')} ${
                this.count
            } ${this.translocoService.translate('buttons.buttons.count-aria-more')}`;
        }

        this.cdr.detectChanges();
    }

    ngAfterViewInit(): void {
        if (this.focusInitial) {
            this.renderer.setAttribute(this.appButton.nativeElement.querySelector('button'), 'cdkFocusInitial', '');
        }
    }

    handleClick(event: MouseEvent): void {
        if (this.isDisabled) {
            return;
        }

        this.clickEvent.emit(event);
    }

    private checkCombinations(): void {
        if (this.color.includes('icon') && this.type.includes('text')) {
            throw new Error(`Can't combine color='icon-' with
            type='text' or type='text-with-icon'. 'icon-' colors combinable only with type='icon'.`);
        }

        if (this.type === 'icon' && this.size === 'large') {
            throw new Error(`Can't combine type='icon' and size='large'. Only 'small' or 'medium' sized icon buttons allowed.`);
        }
    }

    private formatNumber(num: number): string {
        const formatter = new Intl.NumberFormat('en', { notation: 'compact' });
        return formatter.format(num);
    }
}
