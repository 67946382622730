import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PollEditorComponent } from './poll-create/poll-editor.component';
import { MaterialModule } from 'app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PollComponent } from './poll/poll.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { TippyModule } from '@ngneat/helipopper';
import { MatListModule } from '@angular/material/list';
import { ButtonsModule } from 'app/buttons/buttons.module';
import { PipesModule } from 'app/pipes/pipes.module';
import { PollViewResultsDialogComponent } from './poll-view-results-dialog/poll-view-results-dialog.component';

@NgModule({
    declarations: [
        PollEditorComponent,
        PollComponent,
        PollViewResultsDialogComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        MatProgressBarModule,
        TranslocoRootModule,
        TranslocoModule,
        TippyModule,
        MatListModule,
        ButtonsModule,
        PipesModule,
    ],
    exports: [
        PollEditorComponent,
        PollComponent,
        PollViewResultsDialogComponent,
    ],
})
export class PollModule { }
