<div class="discussions-list">
    <div class="top-bar">
        <!-- Discussion search bar -->
        <div class="discussion-search-container">
            <app-discussion-search (searchString)="setSearchString($event)" [inputDiscussions]="sortedDiscussions | async" (outputDiscussions)="searchedDiscussions = $event"></app-discussion-search>
        </div>
        <!-- Filter bar -->
        <app-discussion-filter-header (selectedFilter)="setSelectedFilter($event)" [inputDiscussions]="searchedDiscussions | async" (outputDiscussions)="setFinalDiscussions($event)"></app-discussion-filter-header>
    </div>

    <!--No discussions found with message/name search-->
    <!-- <div class="no-discussions-notice" *ngIf="(searchString || selectedFilter.value) && ((filteredDiscussions | async)?.length + searchedMessages?.length + (newContacts | async)?.length) <= 0"> -->
    <div class="no-discussions-notice" *ngIf="(searchString || selectedFilter?.value) && (finalDiscussions | async)?.length <= 0">
        <h3>{{ 'discussion.list.no_search_result_bar' | transloco }}</h3>
        <span>{{'discussion.list.no_search_results_body' | transloco}}</span>
    </div>

    <cdk-virtual-scroll-viewport 
        itemSize="72"
        class="scroll"
        [style.flex]="1" 
    >
        <!--Displays person's sorted discussion list-->
        <div class="dicussion-list">
            <div *cdkVirtualFor="let discussion of (finalDiscussions | async)" class="list-item">
                <app-discussion-list-item data-cy="discussion-list-item-forward" (navigateToDiscussion)="discussionClicked($event)" [discussion]="discussion"></app-discussion-list-item>
            </div>
        </div>

        <!--New contacts-->
        <ng-container *ngIf="(newContacts | async)?.length">
            <span class="list-header">{{'discussion.list.list_header_contacts' | transloco}}</span>
            <div *ngFor="let user of (newContacts | async)" class="container message-result" (click)="searchedUserClicked(user._id)"
                style="align-items: center;">
                <app-user-profile-picture [userId]="user._id" [size]="40"></app-user-profile-picture>
                <div class="vertical-menu-flex">
                    <span><strong>{{user.display_name || ('discussion.list.display_name_unknown' | transloco)}}</strong></span>
                </div>
            </div>
        </ng-container>
    </cdk-virtual-scroll-viewport>
</div>