import { produce } from 'immer';
import { create } from 'zustand'
import { InsightChartOptions, InsightError, InsightData, InsightDataValue, InsightDrillDown, InsightFilters, InsightOptions, InsightPivotData, InsightPivotOptions, InsightTableData, InsightTableOptions, MatrixItem } from './insight';
import { downloadDataCsv, downloadPivotCsv, downloadTableCsv, filterAndSortInputData, buildPivot } from './util';
import { createJSONStorage, persist } from 'zustand/middleware';
import { createContext, useContext, useState } from 'react';

export const distinctValueLimit = 200;
/** Limit size on rows and columns, to avoid crashes due to out of memory issues */
export const pivotSizeLimit = 250;

type InsightState = ReturnType<typeof insightState>;

type InsightStoreSet = (partial: InsightState | Partial<InsightState> | ((state: InsightState) => InsightState | Partial<InsightState>), replace?: boolean) => void;
type InsightStoreGet = () => InsightState;

let hackCb: (state: any) => void = () => {};

/// BEGIN: Somewhat hacky debounce and update all base data
let debounceUpdateAllTimer: any = 0;

/** Updates data for pivot, chart, and table, using a debounce, to make perform better */
const debouncedFilterInput = (set, get, timeout = 150) => {
    if (debounceUpdateAllTimer) {
        clearTimeout(debounceUpdateAllTimer);
    }

    debounceUpdateAllTimer = setTimeout(() => { set(produce<InsightState>(state => updateAll(get, state))); }, timeout);
}

const updateAll = (get, state: InsightState) => {
    // Tried with using set and state only, but performance was slow for some reason. 
    // Seems the get() is faster for accessing state, but that seems weird
    const filteredInput = filterAndSortInputData({ rows: get().data.data.rows }, get().filters, get().table.sort);
    state.data.table.rows = filteredInput;

    const distinct: { [key: number]: { [value: InsightDataValue]: true } } = {};
    const type: { [key: number]: { [type: string]: true } } = {};

    for (let i = 0; i < get().data.data.headers.length; i++) {
        distinct[i] = {};
        type[i] = {};
    }

    for(const row of filteredInput) {
        for (const columnIndex in row) {
            const value = row[columnIndex];
            distinct[columnIndex][value] = true;
            type[columnIndex][typeof value] = true;
        }
    }

    state.data.type = type;

    state.data.distinct = Object.values(distinct).reduce<{ [column: number]: InsightDataValue[] | null }>((map, distinctValuesMap, index) => {
        const list = Object.keys(distinctValuesMap);
  
        if (list.length > distinctValueLimit) {
          map[index] = null;
        } else {
          map[index] = list.sort();
        }

        return map;
    }, {} as { [header: string]: InsightDataValue[] });

    const [error, pivot] = buildPivot(filteredInput, { pivot: get().pivot });

    state.data.pivot = pivot;
    state.api.error.pivot = error;
}
/// END: Somewhat hacky debounce and update all base data

const insightState = (set: InsightStoreSet, get: InsightStoreGet) => ({
    api: {
        init: (data, options, cb) => {
            hackCb = cb;
            set(produce<InsightState>(state => {
                state.name = options.name;
                state.options = options;

                if (options.drillDown) {
                    state.drillDown = options.drillDown;
                }

                if (options.theme) {
                    state.theme = options.theme;
                }

                if (options.chart) {
                    state.chart.type = options.chart.type;
                }

                state.pivot = options.pivot;
                state.table = options.table;
                state.show.topbar = options.show?.topbar ?? true;
                state.show.chart = options.show?.chart ?? true;
                state.show.pivot = options.show?.pivot ?? true;
                state.show.table = options.show?.table ?? true;

                state.data.data = data;
                state.data.table.headers = data.headers;
            }));

            set(produce<InsightState>(state => updateAll(get, state)));
        },
        show: {
            setTopbar: (show: boolean) => set(produce(state => { state.show.topbar = show; })),
            setPivot: (show: boolean) => set(produce<InsightState>(state => { state.show.pivot = show; })),
            setTable: (show: boolean) => set(produce<InsightState>(state => { state.show.table = show; })),
            setChart: (show: boolean) => set(produce<InsightState>(state => { state.show.chart = show; })),
        },
        filters: {
            setSearch: (search: string) => {
                set(produce<InsightState>(state => {
                    state.filters.search = search ?? undefined;
                }));

                debouncedFilterInput(set, get);
            },
            setDistinct: (map) => {
                set(produce<InsightState>(state => {
                    state.filters.distinct = map;
                }));

                debouncedFilterInput(set, get);
            },
            setColumns: (index: number, filter: string | null) => {
                set(produce<InsightState>(state => {
                    if (filter) {
                        state.filters.columns[index] = filter;
                    } else {
                        delete state.filters.columns[index];
                    }
                }));

                debouncedFilterInput(set, get);
            },
            clearSortingAndFilters: () => {
                set(produce<InsightState>(state => {
                    delete state.table.sort;
                    state.filters.search = '';
                    state.filters.columns = {};
                    state.pivot.sort = {
                        row: ['keys', true],
                        col: ['keys', true]
                    };
                    state.pivot.limit = { cols: 0, rows: 0 };
                    delete state.drillDown;
                }));

                debouncedFilterInput(set, get);
            },
        },
        unsetDrillDown: () => {
            set(produce<InsightState>(state => {
                state.drillDown = undefined;
            }));
        },
        setDrillDown: (row?: MatrixItem, col?: MatrixItem) => {
            const keys = [];

            if (row?.keys) {
                keys.push(...row.keys);
            }

            if (col?.keys) {
                keys.push(...col.keys);
            }

            const drillDown = { key: keys.join('|') };

            if (col?.keys) {
                get().pivot.cols.map((originalColumnIndex, index) => {
                    drillDown[originalColumnIndex] = col.keys[index];
                });
            }

            if (row?.keys) {
                get().pivot.rows.map((originalColumnIndex, index) => {
                    drillDown[originalColumnIndex] = row.keys[index];
                });
            }

            set(produce<InsightState>(state => { state.drillDown = drillDown; }));

            get().options.event.drillDown?.(drillDown);
        },
        chart: {
            setType: (type: 'bar' | 'line') => set(produce<InsightState>(state => { state.chart.type = type })),
        },
        pivot: {
            set: (pivot: InsightPivotOptions) => {
                set(produce<InsightState>(state => {
                    state.pivot = pivot;
                }));

                debouncedFilterInput(set, get);
            },
            setRow: (index: number, value: any) => {
                set(produce<InsightState>(state => {
                    state.pivot.rows[index] = String(value);
                    state.pivot.rows = state.pivot.rows.filter(item => !!item);
                }))

                debouncedFilterInput(set, get);
            },
            setCol: (index: number, value: any) => {
                set(produce<InsightState>(state => {
                    state.pivot.cols[index] = String(value);
                    state.pivot.cols = state.pivot.cols.filter(item => !!item);
                }))

                debouncedFilterInput(set, get);
            },
            setPivotValueFormula: (formula: any) => {
                set(produce<InsightState>(state => {
                    state.pivot.value.formula = formula;

                    const [error, pivot] = buildPivot(state.data.data.rows, { pivot: state.pivot });

                    state.data.pivot = pivot;
                    state.api.error.pivot = error;
                }));

                debouncedFilterInput(set, get);
            },
            setPivotSumColumn: (sumColumn: any) => {
                set(produce<InsightState>(state => {
                    state.pivot.value.column = sumColumn;

                    const [error, pivot] = buildPivot(state.data.data.rows, { pivot: state.pivot });

                    state.data.pivot = pivot;
                    state.api.error.pivot = error;
                }));

                debouncedFilterInput(set, get);
            },
            setPivotLimitRows: (limit: any) => {
                set(produce<InsightState>(state => { state.pivot.limit.rows = limit; }));

                debouncedFilterInput(set, get);
            },
            setPivotLimitCols: (limit: any) => {
                set(produce<InsightState>(state => { state.pivot.limit.cols = limit; }));

                debouncedFilterInput(set, get);
            },
            sortRowKeys: () => {
                set(produce<InsightState>(state => {
                    let order = true;
                    if (state.pivot.sort.row[0] === 'keys') {
                        order = !state.pivot.sort.row[1];
                    }

                    state.pivot.sort.row = ['keys', order]
                }));

                // TODO: Pivot table sort can be done with a simple sort step with the existing data, which is much, much faster
                debouncedFilterInput(set, get);
            },
            sortColKeys: () => {
                set(produce<InsightState>(state => {
                    let order = true;
                    if (state.pivot.sort.col[0] === 'keys') {
                        order = !state.pivot.sort.col[1];
                    }

                    state.pivot.sort.col = ['keys', order]
                }));

                // TODO: Pivot table sort can be done with a simple sort step with the existing data, which is much, much faster
                debouncedFilterInput(set, get);
            },
            totalsRowClick: () => {
                set(produce<InsightState>(state => {
                    let order = true;
                    if (state.pivot.sort.row[0] === 'total') {
                        order = !state.pivot.sort.row[1];
                    }

                    state.pivot.sort.row = ['total', order]
                }));

                // TODO: Pivot table sort can be done with a simple sort step with the existing data, which is much, much faster
                debouncedFilterInput(set, get);
            },
            totalsColClick: () => {
                set(produce<InsightState>(state => {
                    let order = true;
                    if (state.pivot.sort.col[0] === 'total') {
                        order = !state.pivot.sort.col[1];
                    }

                    state.pivot.sort.col = ['total', order]
                }));

                // TODO: Pivot table sort can be done with a simple sort step with the existing data, which is much, much faster
                debouncedFilterInput(set, get);
            }
        },
        table: {
            set: (tableOptions: InsightTableOptions) => {
                set(produce<InsightState>(state => {
                    state.table = tableOptions;

                    if (tableOptions.sort) {
                        state.data.table.rows = filterAndSortInputData({ rows: get().data.data.rows }, get().filters, tableOptions.sort);
                    }
                }));
            },
            setColumns: (values: { value: number, label: string }[]) => {
                set(produce<InsightState>(state => {
                    state.table.hidden = state.data.data.headers.reduce(
                        (map, value, columnIndex) => {
                            if (!values.find((show) => show.value === columnIndex)) {
                                map[columnIndex] = true;
                            }
                            return map;
                        },
                        {}
                    );

                    state.data.table.rows = filterAndSortInputData({ rows: get().data.data.rows }, get().filters, get().table.sort);
                }));
            },
            sort: (columnIndex: string) => {
                // Toggle undefined => true => false => undefined ...

                // Toggle order from previous sort
                let order;

                switch (get().table.sort?.[1]) {
                    case true:
                        order = false;
                        break;
                    case false:
                        order = undefined;
                        break;
                    case undefined:
                        order = true;
                        break;
                }

                if (get().table.sort?.[0] !== columnIndex) {
                    // Changed column for sorting, start from ascending
                    order = true;
                }

                // Update actual sort
                const sort: [string, boolean] = order === undefined ? undefined : [columnIndex, order];

                set(produce<InsightState>(state => {
                    state.table.sort = sort;
                }));

                set(produce<InsightState>(state => {
                    state.data.table.rows = filterAndSortInputData({ rows: get().data.data.rows }, get().filters, get().table.sort);
                }));
            },
            filter: {
                clear: () => {
                    set(produce<InsightState>(state => { state.table.filters = {}; }));
                },
                setDistinct: (columnIndex: number, values: any) => {
                    set(produce<InsightState>(state => {
                        if (!values || !values.length) {
                            delete state.table.filters.distinct?.[columnIndex];
                            return;
                        }
    
                        if (!state.table.filters.distinct) {
                            state.table.filters.distinct = {};
                        }
    
                        state.table.filters.distinct[columnIndex] = values.map(value => value.value);
                    }));

                    set(produce<InsightState>(state => {
                        state.data.table.rows = filterAndSortInputData({ rows: get().data.data.rows }, get().filters || {}, get().table.sort);
                    }));
                },
            },
        },
        data: {
            setPivot: (pivot: InsightPivotData) => set(produce<InsightState>(state => { state.data.pivot = pivot })),
            setTable: (table: InsightTableData) => set(produce<InsightState>(state => { state.data.table = table })),
            setRenderedTable: (table: InsightTableData) => set(produce<InsightState>(state => { state.data.renderedTable = table })),
            setData: (data) => set(produce<InsightState>(state => { state.data.data = data })),
            export: {
                pivot: () => { downloadPivotCsv({ name: 'Insight Pivot - ' + (get().name || 'Unnamed') }, get().data.pivot); },
                table: () => { downloadTableCsv({ name: 'Insight Table - ' + (get().name || 'Unnamed') }, get().data.renderedTable); },
                data: () => { downloadDataCsv({ name: 'Insight Data - ' + (get().name || 'Unnamed') }, get().data.data) },
            }
        },
        error: {
            pivot: null as InsightError | null
        }
    },
    name: undefined as string | undefined,
    theme: 'light' as 'light' | 'dark',
    options: null as InsightOptions,
    show: {
        topbar: true,
        pivot: true,
        table: true,
        chart: true,
    },
    chart: {
        type: 'line',
    } as InsightChartOptions,
    filters: {
        search: '',
        columns: {},
        distinct: {},
    } as InsightFilters,
    pivot: {
        rows: [],
        cols: [],
    } as InsightPivotOptions,
    table: {} as InsightTableOptions,
    data: {
        /** Raw input data, this should only change if the Insight source data is changed */
        data: { headers: [], rows: [] } as InsightData,
        /** First filter step result, used for pivot, chart, and table */
        filtered: { headers: [], rows: [] } as InsightData,
        pivot: { rows: [], cols: [], table: [], total: 0 } as InsightPivotData,
        table: { headers: [], rows: [], data: [] } as InsightTableData,
        /** Distinct value map by column/header, used to determin if pivoting is allowed on column */
        distinct: {} as { [header: string]: InsightDataValue[] },
        /**
         * Column content type map
         * 
         * Example: A number column at index 3 with one or more null values would look like this:
         * 
         * ```js
         * { 3: { null: true, number: true } }
         * ```
         **/
        type: {} as { [header: string]: { [type: string]: true } },
        /**
         * TODO: This is a workaround for state manager issues, to get this forward, fine, but not clean,
         * table should probably use data.filtered as source and data.table should contain the
         * renderedTable data
         **/
        renderedTable: { headers: [], rows: [], data: [] } as InsightTableData,
    },
    drillDown: undefined as InsightDrillDown,
});

export const useInsightSingleton = create(persist(insightState, {
    name: 'insight-persistence-dummy-key', // name of the item in the storage (must be unique)
    storage: createJSONStorage(() => ({
        getItem: (name): string => {
            // console.log('persist: getItem', name);
            return '{}';
        },
        setItem: (name, value) => {
            // hijacking the persistence layer callback for emitting change events, filtered by partialize
            hackCb?.(JSON.parse(value).state);
        },
        removeItem: (name) => {
            // console.log('persist: removeItem', name);
        }
    })),
    partialize: (state) => ({
        name: state.name as string | undefined,
        show: state.show,
        chart: state.chart,
        filters: state.filters,
        pivot: state.pivot,
        table: state.table,
        drillDown: state.drillDown,
    }),
}));


/* Multi-store via contexts based on https://github.com/pmndrs/zustand/issues/128 */
const context = createContext(undefined);
export const useInsight = (): InsightState =>  useContext(context)(state => state);

export function InsightStore({ children }) {
    const [useStore] = useState(() => create(persist(insightState, {
        name: 'insight-persistence-dummy-key', // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => ({
            getItem: (name): string => {
                // console.log('persist: getItem', name);
                return '{}';
            },
            setItem: (name, value) => {
                // hijacking the persistence layer callback for emitting change events, filtered by partialize
                hackCb?.(JSON.parse(value).state);
            },
            removeItem: (name) => {
                // console.log('persist: removeItem', name);
            }
        })),
        partialize: (state) => ({
            name: state.name as string | undefined,
            show: state.show,
            chart: state.chart,
            filters: state.filters,
            pivot: state.pivot,
            table: state.table,
            drillDown: state.drillDown,
        }),
    })))

    return (
        <context.Provider value={useStore}>
            {children}
        </context.Provider>
    )
}
