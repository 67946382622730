/** @format */

import { DropdownOptions } from './dropdown-selector.model';

export class TeamUserSelectorOptions extends DropdownOptions {
    /**
     * @typedef all: All users,
     * @typedef string: Network id for filtering users by network
     * @typedef undefined: do not show users
     */
    showUsers?: string | 'all';

    /**
     * @typedef all: All network teams,
     * @typedef string: User id for filtering teams by user,
     * @typedef undefined: do not show teams,
     */
    showTeams?: string | 'all';

    /**
     * @typedef all: All network groups,
     * @typedef string: Network id for filtering groups by network
     * @typedef undefined: do not show groups,
     */
    showGroups?: string | 'all';
    workspaceId?: string;

    type?: 'group' | 'team' | 'user';

    showMe?: boolean = false;
}
