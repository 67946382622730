/** @format */

export const importedIcons = {
    // Hailer icons (Old)
    'hailer-events': '../assets/icons/events.svg',
    'hailer-add-user-inverted': '../assets/icons/add-user-inverted.svg',
    'hailer-private-discussion': '../assets/icons/private-discussion.svg',
    'hailer-messenger': '../assets/icons/messenger.svg',
    'hailer-admin-mode': '../assets/icons/admin-mode.svg',
    'hailer-logout': '../assets/icons/logout.svg',
    'hailer-logout-inverted': '../assets/icons/logout-inverted.svg',
    'hailer-edit-profile': '../assets/icons/edit-profile.svg',
    'hailer-reload-app': '../assets/icons/reload-app.svg',
    'hailer-personal-files': '../assets/icons/personal-files.svg',
    'hailer-upload': '../assets/icons/upload.svg',
    'hailer-users': '../assets/icons/users.svg',
    'hailer-teams': '../assets/icons/teams.svg',
    'hailer-logs': '../assets/icons/logs.svg',
    'hailer-activities-figma': '../assets/icons/figma_activities.svg',
    'hailer-people': '../assets/icons/people.svg',
    'hailer-copy-inverted': '../assets/icons/copy-inverted.svg',
    'hailer-menu-burger-closed': '../assets/icons/menu-burger-closed.svg',
    'hailer-menu-burger-open': '../assets/icons/menu-burger-open.svg',
    'hailer-group-figma': '../assets/icons/figma-group.svg',
    'hailer-group-figma-fill': '../assets/icons/figma-group-fill.svg',
    'hailer-activities-filter-arrow': '../assets/icons/activities-filter-arrow.svg',
    'hailer-public-form-url': '../assets/icons/copy-public-form-url.svg',
    'hailer-activity-invert': '../assets/icons/activity-invert.svg',
    'hailer-group-invert': '../assets/icons/group-invert.svg',
    'hailer-calendar-invert': '../assets/icons/calendar-invert.svg',
    'hailer-add': '../assets/icons/add.svg',
    'hailer-remove': '../assets/icons/remove.svg',
    'hailer-tooltip': '../assets/icons/tooltip.svg',
    'hailer-white-tick': '../assets/icons/tick-white.svg',
    'hailer-groupby': '../assets/icons/groupby.svg',
    'hailer-groupby-invert': '../assets/icons/groupby-invert.svg',
    'hailer-info-stroke': '../assets/icons/stroke/info-information-circle.svg',
    'hailer-more': '../assets/icons/more.svg',
    'hailer-print-inverted': '../assets/icons/print-inverted.svg',
    'hailer-leave-chat': '../assets/icons/leave-chat.svg',
    'hailer-delete': '../assets/icons/delete.svg',
    'hailer-import-export': '../assets/icons/import-export.svg',
    'hailer-check': '../assets/icons/check.svg',
    'hailer-tick-fill': '../assets/icons/tick-fill.svg',

    /* New icons */
    'hailer-logo': '../assets/img/hailerlogo.svg',

    /* Main */
    'hailer-feed': '../assets/icons/feed.svg',
    'hailer-feed-fill': '../assets/icons/feed-fill.svg',
    'hailer-tick': '../assets/icons/tick.svg',
    'hailer-tick-small': '../assets/icons/tick-small.svg',
    'hailer-map': '../assets/icons/map.svg',
    'hailer-map-fill': '../assets/icons/map-fill.svg',
    'hailer-kanban': '../assets/icons/kanban.svg',
    'hailer-kanban-fill': '../assets/icons/kanban-fill.svg',
    'hailer-workflow': '../assets/icons/workflow.svg',
    'hailer-calendar': '../assets/icons/calendar.svg',
    'hailer-calendar-fill': '../assets/icons/calendar-fill.svg',
    'hailer-linked-activity': '../assets/icons/linked-activity.svg',
    'hailer-linked-activity-fill': '../assets/icons/linked-activity-fill.svg',
    'hailer-linked-activity-edit': '../assets/icons/linked-activity-edit.svg',
    'hailer-activity': '../assets/icons/activity.svg',
    'hailer-activity-fill': '../assets/icons/activity-fill.svg',
    'hailer-activities': '../assets/icons/activities.svg',
    'hailer-activities-fill': '../assets/icons/activities-fill.svg',
    'hailer-phases': '../assets/icons/phases.svg',
    'hailer-phases-fill': '../assets/icons/phases-fill.svg',
    'hailer-dataset': '../assets/icons/dataset.svg',
    'hailer-birds-eye-view': '../assets/icons/birds-eye.svg',
    'hailer-birds-eye-view-fill': '../assets/icons/birds-eye-fill.svg',
    'hailer-discussion': '../assets/icons/discussion.svg',
    'hailer-discussion-fill': '../assets/icons/discussion-fill.svg',
    'hailer-messages': '../assets/icons/messages.svg',
    'hailer-messages-fill': '../assets/icons/messages-fill.svg',
    'hailer-attach': '../assets/icons/attach.svg',
    'hailer-list': '../assets/icons/list.svg',
    'hailer-see-more': '../assets/icons/see-more.svg',
    'hailer-dev': '../assets/icons/dev.svg',
    'hailer-dev-fill': '../assets/icons/dev-fill.svg',
    'hailer-workspace': '../assets/icons/workspace.svg',
    'hailer-workspace-fill': '../assets/icons/workspace-fill.svg',

    /* Main actions */
    'hailer-x': '../assets/icons/x.svg',
    'hailer-x-small': '../assets/icons/x-small.svg',
    'hailer-plus': '../assets/icons/plus.svg',
    'hailer-plus-small': '../assets/icons/plus-small.svg',
    'hailer-minus-small': '../assets/icons/minus-small.svg',
    'hailer-info': '../assets/icons/info.svg',
    'hailer-info-fill': '../assets/icons/info-fill.svg',
    'hailer-save': '../assets/icons/save.svg',
    'hailer-copy': '../assets/icons/copy.svg',
    'hailer-copy-fill': '../assets/icons/copy-fill.svg',
    'hailer-search': '../assets/icons/search.svg',
    'hailer-search-fill': '../assets/icons/search-fill.svg',
    'hailer-trash': '../assets/icons/trash.svg',
    'hailer-trash-fill': '../assets/icons/trash-fill.svg',
    'hailer-edit': '../assets/icons/edit.svg',
    'hailer-edit-fill': '../assets/icons/edit-fill.svg',
    'hailer-salmon': '../assets/icons/salmon.svg',
    'hailer-print': '../assets/icons/print.svg',
    'hailer-print-fill': '../assets/icons/print-fill.svg',
    'hailer-share': '../assets/icons/share.svg',
    'hailer-share-fill': '../assets/icons/share-fill.svg',
    'hailer-input': '../assets/icons/input.svg',
    'hailer-collapse-view': '../assets/icons/collapse-view.svg',
    'hailer-expand-view': '../assets/icons/expand-view.svg',
    'hailer-sign-in': '../assets/icons/sign-in.svg',
    'hailer-sign-in-fill': '../assets/icons/sign-in-fill.svg',
    'hailer-sign-out': '../assets/icons/sign-out.svg',
    'hailer-sign-out-fill': '../assets/icons/sign-out-fill.svg',
    'document-preview': '../assets/icons/document-preview.svg',
    'document-preview-hide': '../assets/icons/document-preview-hide.svg',
    'multiple-documents-search': '../assets/icons/multiple-documents-search.svg',

    /* Settings / options */
    'hailer-settings': '../assets/icons/settings.svg',
    'hailer-settings-fill': '../assets/icons/settings-fill.svg',
    'hailer-options': '../assets/icons/options.svg',
    'hailer-options-fill': '../assets/icons/options-fill.svg',
    'hailer-admin': '../assets/icons/admin.svg',
    'hailer-admin-fill': '../assets/icons/admin-fill.svg',
    'hailer-no-admin': '../assets/icons/no-admin.svg',
    'hailer-payment': '../assets/icons/payment.svg',
    'hailer-payment-fill': '../assets/icons/payment-fill.svg',
    'hailer-visuals': '../assets/icons/visuals.svg',
    'hailer-visuals-fill': '../assets/icons/visuals-fill.svg',

    /* Apps / insights */
    'hailer-apps-plus': '../assets/icons/apps-plus.svg',
    'hailer-apps-plus-fill': '../assets/icons/apps-plus-fill.svg',
    'hailer-apps-plus-colour': '../assets/icons/apps-plus-color.svg',
    'hailer-apps-colour': '../assets/icons/apps-colour.svg',
    'hailer-apps-fill': '../assets/icons/apps-fill.svg',
    'hailer-apps': '../assets/icons/apps.svg',
    'hailer-insights-colour': '../assets/icons/insights-colour.svg',
    'hailer-insights-fill': '../assets/icons/insights-fill.svg',
    'hailer-insights': '../assets/icons/insights.svg',

    /* Discussion/message actions */
    'hailer-enter': '../assets/icons/enter.svg',
    'hailer-send': '../assets/icons/send.svg',
    'hailer-forward': '../assets/icons/forward.svg',
    'hailer-forward-fill': '../assets/icons/forward-fill.svg',
    'hailer-reply': '../assets/icons/reply.svg',
    'hailer-reply-fill': '../assets/icons/reply-fill.svg',
    'hailer-reply-privately': '../assets/icons/reply-privately.svg',
    'hailer-reaction': '../assets/icons/reaction.svg',
    'hailer-reaction-fill': '../assets/icons/reaction-fill.svg',
    'hailer-meeting': '../assets/icons/meeting.svg',
    'hailer-meeting-fill': '../assets/icons/meeting-fill.svg',
    'hailer-unread-messages': '../assets/icons/unread-messages.svg',
    'hailer-unread-messages-fill': '../assets/icons/unread-messages-fill.svg',

    /* Field types */
    'hailer-link': '../assets/icons/link.svg',
    'hailer-country': '../assets/icons/country.svg',
    'hailer-text': '../assets/icons/text.svg',
    'hailer-text-area': '../assets/icons/text-area.svg',
    'hailer-numeric': '../assets/icons/numeric.svg',
    'hailer-time': '../assets/icons/time.svg',
    'hailer-time-fill': '../assets/icons/time-fill.svg',
    'hailer-predefined-options': '../assets/icons/predefined-options.svg',
    'hailer-subheader': '../assets/icons/subheader.svg',
    'hailer-image': '../assets/icons/image.svg',
    'hailer-image-fill': '../assets/icons/image-fill.svg',

    /* Feed actions */
    'hailer-dislike': '../assets/icons/dislike.svg',
    'hailer-dislike-fill': '../assets/icons/dislike-fill.svg',
    'hailer-like': '../assets/icons/like.svg',
    'hailer-like-fill': '../assets/icons/like-fill.svg',
    'hailer-sort-posts': '../assets/icons/sort-posts.svg',
    'hailer-filter-posts': '../assets/icons/filter-posts.svg',
    'hailer-pin': '../assets/icons/pin.svg',
    'hailer-pin-fill': '../assets/icons/pin-fill.svg',

    /* Misc action icons */
    /* Show/hide / eye */
    'hailer-show': '../assets/icons/show.svg',
    'hailer-show-fill': '../assets/icons/show-fill.svg',
    'hailer-hide': '../assets/icons/hide.svg',
    'hailer-hide-fill': '../assets/icons/hide-fill.svg',

    /* Lock/unlock */
    'hailer-lock': '../assets/icons/lock.svg',
    'hailer-lock-fill': '../assets/icons/lock-fill.svg',
    'hailer-unlock': '../assets/icons/unlock.svg',
    'hailer-unlock-fill': '../assets/icons/unlock-fill.svg',

    /* Volume up/down/off */
    'hailer-volume-up': '../assets/icons/volume-up.svg',
    'hailer-volume-up-fill': '../assets/icons/volume-up-fill.svg',
    'hailer-volume-down': '../assets/icons/volume-down.svg',
    'hailer-volume-down-fill': '../assets/icons/volume-down-fill.svg',
    'hailer-volume-off': '../assets/icons/volume-off.svg',
    'hailer-volume-off-fill': '../assets/icons/volume-off-fill.svg',

    /* Thumbs up/down / yes/no */
    'hailer-thumbs-up': '../assets/icons/thumbs-up.svg',
    'hailer-thumbs-up-fill': '../assets/icons/thumbs-up-fill.svg',
    'hailer-thumbs-down': '../assets/icons/thumbs-down.svg',
    'hailer-thumbs-down-fill': '../assets/icons/thumbs-down-fill.svg',

    'hailer-change-theme': '../assets/icons/change-theme.svg',
    'hailer-change-theme-fill': '../assets/icons/change-theme-fill.svg',
    'hailer-filter': '../assets/icons/filter.svg',
    'hailer-filter-fill': '../assets/icons/filter-fill.svg',
    'hailer-open-new-window': '../assets/icons/open-new-window.svg',
    'hailer-open-new-window-fill': '../assets/icons/open-new-window-fill.svg',

    /* Misc */
    /* 'hailer-bubble': '../assets/icons/bubble.svg',
    'hailer-bubble-fill': '../assets/icons/bubble-fill.svg', */
    'hailer-open-menu': '../assets/icons/open-menu.svg',
    'hailer-close-menu': '../assets/icons/close-menu.svg',
    'hailer-menu': '../assets/icons/menu.svg',
    'hailer-help': '../assets/icons/help.svg',
    'hailer-location': '../assets/icons/location.svg',
    'hailer-location-fill': '../assets/icons/location-fill.svg',
    'hailer-drag-reorder': '../assets/icons/drag-reorder.svg',
    'hailer-reorder': '../assets/icons/reorder.svg',
    'hailer-ical': '../assets/icons/ical.svg',
    'hailer-shopping-cart': '../assets/icons/shopping-cart.svg',
    'hailer-phone': '../assets/icons/phone.svg',
    'hailer-mail': '../assets/icons/mail.svg',
    'hailer-warning': '../assets/icons/warning.svg',
    'hailer-warning-fill': '../assets/icons/warning-fill.svg',
    'hailer-video': '../assets/icons/video.svg',
    'hailer-video-fill': '../assets/icons/video-fill.svg',
    'hailer-question': '../assets/icons/question.svg',

    /* Stars */
    'hailer-star': '../assets/icons/star.svg',
    'hailer-star-fill': '../assets/icons/star-fill.svg',
    'hailer-star-small': '../assets/icons/star-small.svg',
    'hailer-star-small-fill': '../assets/icons/star-small-fill.svg',
    'hailer-star-list': '../assets/icons/star-list.svg',
    'hailer-star-list-fill': '../assets/icons/star-list-fill.svg',
    'hailer-unstar': '../assets/icons/unstar.svg',
    /* Notifications on/off */
    'hailer-notification': '../assets/icons/notification.svg',
    'hailer-notification-fill': '../assets/icons/notification-fill.svg',
    'hailer-notification-off': '../assets/icons/notification-off.svg',
    'hailer-notification-off-fill': '../assets/icons/notification-off-fill.svg',

    /* Arrows */
    'hailer-to-start': '../assets/icons/arrow-to-start.svg',
    'hailer-download': '../assets/icons/download.svg',
    'hailer-download-all': '../assets/icons/download-all.svg',
    'hailer-refresh': '../assets/icons/refresh.svg',
    'hailer-reload': '../assets/icons/reload.svg',
    'hailer-sort': '../assets/icons/sort.svg',
    'hailer-undo': '../assets/icons/undo.svg',
    'hailer-chevron': '../assets/icons/small-chevron-down.svg',
    'hailer-large-chevron': '../assets/icons/large-chevron-down.svg',
    'hailer-tailed-arrow': '../assets/icons/arrow-up.svg',

    /* People, users, teams and groups */
    'hailer-add-user': '../assets/icons/add-user.svg',
    'hailer-add-user-fill': '../assets/icons/add-user-fill.svg',
    'hailer-user': '../assets/icons/user.svg',
    'hailer-user-fill': '../assets/icons/user-fill.svg',
    'hailer-group': '../assets/icons/group.svg',
    'hailer-group-fill': '../assets/icons/group-fill.svg',
    'hailer-team': '../assets/icons/team.svg',
    'hailer-team-fill': '../assets/icons/team-fill.svg',
    'hailer-roles': '../assets/icons/roles.svg',
    'hailer-roles-fill': '../assets/icons/roles-fill.svg',
    'hailer-groups': '../assets/icons/groups.svg',
    'hailer-groups-fill': '../assets/icons/groups-fill.svg',

    /* Folders, files and documents */
    'hailer-folders': '../assets/icons/folders.svg',
    'hailer-folders-fill': '../assets/icons/folders-fill.svg',
    'hailer-folder': '../assets/icons/folder.svg',
    'hailer-text-document': '../assets/icons/text-document.svg',
    'hailer-file': '../assets/icons/file.svg',
    'hailer-file-fill': '../assets/icons/file-fill.svg',
    'hailer-files': '../assets/icons/files.svg',
    'hailer-files-fill': '../assets/icons/files-fill.svg',
    'hailer-download-cloud': '../assets/icons/download-cloud.svg',
    'hailer-download-cloud-fill': '../assets/icons/download-cloud-fill.svg',
    'hailer-upload-cloud': '../assets/icons/upload-cloud.svg',
    'hailer-upload-cloud-fill': '../assets/icons/upload-cloud-fill.svg',
    'hailer-files-cloud': '../assets/icons/files-cloud.svg',
    'hailer-files-cloud-fill': '../assets/icons/files-cloud-fill.svg',

    /* Social media */
    'hailer-facebook': '../assets/icons/facebook.svg',
    'hailer-camera': '../assets/icons/camera.svg',
    'hailer-linkedin': '../assets/icons/linkedin.svg',
    'hailer-twitter': '../assets/icons/twitter.svg',

    // Font awesome icons
    'fa-thumbs-up': '../assets/icons/fa-thumbs-up.svg',
    'fa-thumbs-up-o': '../assets/icons/fa-thumbs-up-o.svg',
    'fa-thumbs-down': '../assets/icons/fa-thumbs-down.svg',
    'fa-thumbs-down-o': '../assets/icons/fa-thumbs-down-o.svg',
    'fa-send': '../assets/icons/paper-plane-solid.svg',

    // MD Icons
    earth: '../assets/icons/earth.svg',
    numeric: '../assets/icons/numeric.svg',
    'account-multiple': '../assets/icons/account-multiple.svg',
    'card-text-outline': '../assets/icons/card-text-outline.svg',
    'format-text': '../assets/icons/format-text.svg',
    enter: '../assets/icons/enter.svg',

    // Google icons
    'google-drive': '../assets/icons/google-drive.svg',
    google: '../assets/icons/google.svg',
};
