/** @format */

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { DropdownItem } from 'app/_models/dropdown-selector.model';

@Component({
    selector: 'app-dropdown-activity-item',
    templateUrl: './dropdown-activity-item.component.html',
    styleUrls: ['./dropdown-activity-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownActivityItemComponent implements OnInit {
    @Input() item: DropdownItem;

    constructor() {}

    ngOnInit(): void {}
}
