<div *ngIf="inviteData.company">
    <div class="top-bar">
        <img crossorigin class="network-icon" draggable="false" matListAvatar [src]="'assets/img/hailerlogo.svg'"
            alt="Workspace icon">
        <div class="top-text">
            <h3>{{'core.join-network-dialog.header_join_network' | transloco}}<span class="network-name">{{inviteData.company.name}}</span>?</h3>
            <p>{{'core.join-network-dialog.inv_from' | transloco}}<span class="user-name">{{inviteData.user.firstname}}
                    {{inviteData.user.lastname}}</span>
            </p>
        </div>
        <button class="close-button" mat-icon-button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="main-content">
        <h4>{{'core.join-network-dialog.header_description' | transloco}}</h4>
        <p class="description">{{inviteData.company.description}}</p>
        <div class="default-network-checkbox">
            <mat-checkbox [(ngModel)]="defaultToNetwork">{{'core.join-network-dialog.set_as_default' | transloco}}</mat-checkbox>
        </div>
    </div>
    <div class="bottom-buttons">
        <button class="bottom-buttons-button" mat-flat-button color="warn"
            (click)="declineInvitation()">{{'core.join-network-dialog.decline_inv' | transloco}}</button>
        <button data-cy="join-network-dialog-accept-button" class="bottom-buttons-button" mat-flat-button color="accent" (click)="acceptInvitation()">{{'core.join-network-dialog.join_network' | transloco}}</button>
    </div>
</div>

<div *ngIf="inviteData.network && inviteData.user">
    <div class="top-bar">
        <img crossorigin class="network-icon" draggable="false" matListAvatar [src]="'assets/img/hailerlogo.svg'"
            alt="Network icon">
        <div class="top-text">
            <h3>{{'core.join-network-dialog.header_join_network' | transloco}}<span class="network-name">{{inviteData.network.name}}</span>?</h3>
            <p>{{'core.join-network-dialog.inv_from' | transloco}}<span class="user-name">{{invitedBy}}</span></p>
        </div>
        <button class="close-button" mat-icon-button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div *ngIf="(user | async)?._id === inviteData.user._id" class="main-content">
        <h4>{{'core.join-network-dialog.header_description' | transloco}}</h4>
        <p class="description">{{inviteData.network.short_desc}}</p>
        <div class="default-network-checkbox">
            <mat-checkbox [(ngModel)]="defaultToNetwork">{{'core.join-network-dialog.set_as_default' | transloco}}</mat-checkbox>
        </div>
    </div>
    <div *ngIf="(user | async)?._id !== inviteData.user._id" class="main-content">
        <h4 data-cy="invitation-was-intended-for-specific-user" style="margin: 16px 0;">{{'core.join-network-dialog.inv_was_intended' | transloco}}{{inviteData.user.firstname}}
            {{inviteData.user.lastname}}</h4>
    </div>
    <div *ngIf="inviteData.network && (user | async)?._id === inviteData.user._id" class="bottom-buttons">
        <button [disabled]="isManagedUser" class="bottom-buttons-button" mat-flat-button color="warn"
            (click)="declineInvitation()">{{'core.join-network-dialog.decline_inv' | transloco}}</button>
        <button data-cy="join-network-dialog-accept-button" class="bottom-buttons-button" mat-flat-button color="accent" (click)="acceptInvitation()">{{'core.join-network-dialog.join_network' | transloco}}</button>
    </div>
</div>

<div *ngIf="inviteData.network && !inviteData.user">
    <div class="top-bar">
        <img crossorigin class="network-icon" draggable="false" matListAvatar [src]="'assets/img/hailerlogo.svg'"
            alt="Workspace icon">
        <div class="top-text">
            <h3>{{'core.join-network-dialog.header_join_network' | transloco}}<span class="network-name">{{inviteData.network.name}}</span>?</h3>
            <p>{{'core.join-network-dialog.inv_from' | transloco}}<span class="user-name">{{invitedBy}}</span>
            </p>
        </div>
        <button class="close-button" mat-icon-button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="main-content">
        <h4 data-cy="invitation-was-intended-for-unknown-user" style="margin: 16px 0;">{{'core.join-network-dialog.inv_intended_for' | transloco}}</h4>
    </div>
</div>