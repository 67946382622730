/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SideNavService {
    sideNavContent$ = new Subject<{
        sidenav: any;
        props: { [property: string]: any };
    }>();
    sideNavPop$ = new Subject<void>();
    sideNavClear$ = new Subject<void>();
    stackSize$ = new BehaviorSubject<number>(0);
    clearIfExport = new Subject<void>();
    fullscreen = new BehaviorSubject<boolean>(false);
    activityUpgraded = new Subject<void>();
    marginTop = new BehaviorSubject<number>(0);
    sidenavStyling = new BehaviorSubject<{ marginTop?: number; adaptiveBackground?: any }>(null);

    private stackSize = 0;

    constructor() {}

    create<T>(sidenav: T, props: { [property: string]: any } = {}, styling?: { marginTop?: number; adaptiveBackground?: any }) {
        this.sideNavContent$.next({ sidenav, props });
        ++this.stackSize;
        this.stackSize$.next(this.stackSize);
        this.sidenavStyling.next(styling);
    }

    pop(): void {
        this.sideNavPop$.next();
        --this.stackSize;
        this.stackSize$.next(this.stackSize);
        if (this.stackSize === 0) {
            this.fullscreen.next(false);
        }
    }

    add(): void {
        ++this.stackSize;
        this.stackSize$.next(this.stackSize);
    }

    subtract(): void {
        --this.stackSize;
        this.stackSize$.next(this.stackSize);
    }

    clear(): void {
        this.sideNavClear$.next();
        this.stackSize = 0;
        this.stackSize$.next(this.stackSize);
        this.fullscreen.next(false);
        this.marginTop.next(0);
    }

    toggleFullscreen() {
        this.fullscreen.next(!this.fullscreen.value);
    }

    closeExport() {
        this.clearIfExport.next();
    }
}
