/** @format */

import { Injectable } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { map, pluck, take } from 'rxjs/operators';

import { CoreService } from './core.service';
import { Process } from 'app/_models/process.model';
import { RPCService } from './rpc.service';

@Injectable({
    providedIn: 'root',
})
export class ProcessService {
    processesByNetwork: Observable<{ [pid: string]: Process }>;
    datasetsByNetwork: Observable<{ [pid: string]: Process }>;

    sortedProcessesByNetwork: Observable<Process[]>;
    sortedDatasetsByNetwork: Observable<Process[]>;
    processStarredUpdate = new Subject<any>();

    constructor(private core: CoreService, private rpcService: RPCService) {
        this.processesByNetwork = merge(this.core.network, this.core.processes, this.core.state).pipe(
            map(_ => {
                const cid = this.core.network.value._id;
                const processes = this.core.processes.getValue();
                const state = this.core.state.getValue();

                if (cid && processes && state === 'authenticated' && processes[cid]) {
                    return processes[cid];
                }
                return {};
            }),
            map(processes => {
                const filtered = {};
                Object.keys(processes).forEach(key => {
                    if (!processes[key].enableUnlinkedMode) {
                        filtered[key] = processes[key];
                    }
                });
                return filtered;
            })
        );

        this.datasetsByNetwork = merge(this.core.network, this.core.processes, this.core.state).pipe(
            map(_ => {
                const cid = this.core.network.value._id;
                const processes = this.core.processes.getValue();
                const state = this.core.state.getValue();

                if (cid && processes && state === 'authenticated' && processes[cid]) {
                    return processes[cid];
                }
                return {};
            }),
            map(processes => {
                const filtered = {};
                Object.keys(processes).forEach(key => {
                    if (processes[key].enableUnlinkedMode) {
                        filtered[key] = processes[key];
                    }
                });
                return filtered;
            })
        );

        this.sortedProcessesByNetwork = this.processesByNetwork.pipe(
            map(pros => {
                const proAr = Object.values(pros);
                return proAr.sort((a, b) => a.order - b.order);
            })
        );

        this.sortedDatasetsByNetwork = this.datasetsByNetwork.pipe(
            map(sets => {
                const dataAr = Object.values(sets);
                return dataAr.sort((a, b) => a.order - b.order);
            })
        );

        this.subscribeProcessStarred();
    }

    getProcessAsObservable(proId: string): Observable<Process> {
        return this.core.processes.pipe(pluck(this.core.network.value._id), pluck(proId), take(1));
    }

    getProcess(proId: string): Process {
        const cid = this.core.network.value._id;
        const processes = this.core.processes.getValue();

        if (cid && processes && processes[cid] && processes[cid][proId]) {
            return processes[cid][proId];
        }

        return null;
    }

    searchProcess(query: string): Observable<Process[]> {
        return this.sortedProcessesByNetwork.pipe(
            map(processes => {
                const proAr = [];
                processes.forEach(pro => {
                    if (pro.name.indexOf(query) > -1) {
                        proAr.push(pro);
                    }
                });
                return proAr;
            })
        );
    }

    private subscribeProcessStarred(): void {
        this.rpcService.subscribe('process.starred', (meta: any) => {
            this.processStarredUpdate.next(meta);
        });
    }
}
