import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { RouterModule } from '@angular/router';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OverlayModule } from '@angular/cdk/overlay';

import { OWL_DATE_TIME_FORMATS } from '@leeviviertola/date-time-picker';
import { CopyNetworkDialogComponent } from './copy-network-dialog/copy-network-dialog.component';

import { CountrySelectorComponent } from './country-selector/country-selector.component';
import { MaterialModule } from '../material.module';
import { ActivityDataFieldComponent } from './activity-data-field/activity-data-field.component';
import { ActivityDataRenderComponent } from './activity-data-render/activity-data-render.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { GmapComponent } from './gmap/gmap.component';
import { ContainerComponent } from './container/container.component';
import { FallbackImageDirective } from './directives/fallback-image.directive';
import { OnEnterDirective } from './directives/on-enter.directive';
import { OnScrollBottomDirective } from './directives/on-scroll-bottom.directive';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { IconWithBadgeComponent } from './icon-with-badge/icon-with-badge.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { NewNetworkDialogComponent } from './new-network-dialog/new-network-dialog.component';
import { ParticipantsPipe } from '../pipes/src/participants.pipe';
import { ProcessSelectorComponent } from './process-selector/process-selector.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { NotificationComponent } from './notification/notification.component';
import { TopToolbarComponent } from './top-toolbar/top-toolbar.component';
import { UserImageLinkComponent } from './user-image-link/user-image-link.component';
import { UserInviteDialogComponent } from './user-invite-dialog/user-invite-dialog.component';
import { UserLinkListComponent } from './user-link-list/user-link-list.component';
import { UserLinkComponent } from './user-link/user-link.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { UserProfilePictureComponent } from './user-profile-picture/user-profile-picture.component';
import { NgxStackViewModule } from 'app/ngx-stack-view/ngx-stack-view.module';

import { LicenseExceededDialogComponent } from './license-exceeded-dialog/license-exceeded-dialog.component';
import { TagSelectorComponent } from './tag-selector/tag-selector.component';
import { ImageViewerService } from 'app/_services/image-viewer.service';
import { ApiNotificationService } from 'app/_services/notification.service';
import { FilesService } from 'app/_services/files.service';
import { ActivitiesHelperService } from 'app/_services/activities-helper.service';
import { ParseIdsPipe } from '../pipes/src/parseIds.pipe';
import { TagFilterObjectListComponent } from './tag-filter-object-list/tag-filter-object-list.component';

import { AutofocusDirective } from './directives/autofocus.directive';

import { PermissionSelectorComponent } from './permission-selector/permission-selector.component';
import { PermissionListComponent } from './permission-list/permission-list.component';
import { PermissionTypeSelectorComponent } from './permission-type-selector/permission-type-selector.component';
import { DropdownSelectorComponent } from './dropdown-selector/dropdown-selector.component';
import { DropdownOptionContentComponent } from './dropdown-selector/dropdown-option-content/dropdown-option-content.component';
import { DropdownTeamItemComponent } from './dropdown-selector/dropdown-option-content/dropdown-team-item/dropdown-team-item.component';
import { DropdownUserItemComponent } from './dropdown-selector/dropdown-option-content/dropdown-user-item/dropdown-user-item.component';
import { DropdownActivityItemComponent } from './dropdown-selector/dropdown-option-content/dropdown-activity-item/dropdown-activity-item.component';
import { DropdownCalendarItemComponent } from './dropdown-selector/dropdown-option-content/dropdown-calendar-item/dropdown-calendar-item.component';
import { DropdownWorkspaceItemComponent } from './dropdown-selector/dropdown-option-content/dropdown-workspace-item/dropdown-workspace-item.component';
import { DropdownPreventClosingDirective } from './directives/dropdown-prevent-closing.directive';
import { TeamUserSelectorComponent } from './user-team-selector/team-user-selector.component';

import { SatPopoverModule } from '@ncstate/sat-popover';
import { TippyModule, popperVariation, tooltipVariation } from '@ngneat/helipopper';


import { GoogleMapsModule } from '@angular/google-maps';

import { AllowOnlyNumbersDirective } from './directives/allow-only-numbers.directive';
import { LanguageDragAndDropComponent } from './language-drag-and-drop/language-drag-and-drop.component';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { TranslocoRootModule } from 'app/transloco-root.module';

import localeFi from '@angular/common/locales/fi';
import localeSv from '@angular/common/locales/sv';
import { registerLocaleData } from '@angular/common';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { MatPaginationIntlService } from 'app/_services/paginator-translation.service';
import { TimelineGroupBySelectorComponent } from 'app/activities/components/timeline-group-by-selector/timeline-group-by-selector.component';
import { PasswordStrengthMeterComponent } from './password-strength-meter/password-strength-meter.component';
import { PreventAutofillDirective } from './directives/prevent-autofill.directive';
import { ClearXButtonComponent } from './clear-x-button/clear-x-button.component';
import { ButtonsModule } from 'app/buttons/buttons.module';
import { StylingHelperService } from 'app/_services/styling-helper.service';
import { A11yModule } from '@angular/cdk/a11y';
import { TakePictureDirective } from './directives/take-picture.directive';
import { WorkspaceIdsToWorkspaces } from '../pipes/src/workspaceids-to-objects.pipe';
import { PipesModule } from 'app/pipes/pipes.module';

registerLocaleData(localeFi);
registerLocaleData(localeSv);

tooltipVariation.arrow = true;
tooltipVariation.duration = 40;
tooltipVariation.animation = 'scale';
tooltipVariation.touch = 'hold';
tooltipVariation.trigger = 'mouseenter focus';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MY_MOMENT_FORMATS = {
    parseInput: { month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false },
    fullPickerInput: { month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false },
    datePickerInput: { month: 'short', day: 'numeric', year: 'numeric' },
    timePickerInput: { hour: '2-digit', minute: '2-digit', hour12: false },
    monthYearLabel: { month: 'short', year: 'numeric' },
    dateA11yLabel: { month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false },
    monthYearA11yLabel: { month: 'numeric', year: 'numeric' },
};

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        NgxMatSelectSearchModule,
        NgxStackViewModule,
        OverlayModule,
        ScrollingModule,
        SatPopoverModule,
        TippyModule.forRoot({
            defaultVariation: 'tooltip',
            delay: 150,
            interactive: false,
            variations: {
                tooltip: tooltipVariation,
                popper: popperVariation,
            },
        }),
        GoogleMapsModule,
        DragDropModule,
        TranslocoRootModule,
        ButtonsModule,
        A11yModule,
        PipesModule,
    ],
    declarations: [
        UserLinkComponent,
        UserLinkListComponent,
        UserProfilePictureComponent,
        OnScrollBottomDirective,
        ActivityDataRenderComponent,
        ActivityDataFieldComponent,
        OnEnterDirective,
        FileUploaderComponent,
        UserInviteDialogComponent,
        CountrySelectorComponent,
        AttachmentsComponent,
        ProcessSelectorComponent,
        UserImageLinkComponent,
        UserMenuComponent,
        GmapComponent,
        FallbackImageDirective,
        ContainerComponent,
        ImageViewerComponent,
        TopToolbarComponent,
        SideNavComponent,
        NewNetworkDialogComponent,
        CopyNetworkDialogComponent,
        GlobalSearchComponent,
        NotificationComponent,
        IconWithBadgeComponent,
        LicenseExceededDialogComponent,
        TagSelectorComponent,
        TagFilterObjectListComponent,
        AutofocusDirective,
        TakePictureDirective,
        PreventAutofillDirective,
        PermissionSelectorComponent,
        PermissionListComponent,
        PermissionTypeSelectorComponent,
        DropdownSelectorComponent,
        DropdownOptionContentComponent,
        DropdownTeamItemComponent,
        DropdownUserItemComponent,
        DropdownActivityItemComponent,
        DropdownCalendarItemComponent,
        DropdownWorkspaceItemComponent,
        DropdownPreventClosingDirective,
        TeamUserSelectorComponent,
        AllowOnlyNumbersDirective,
        LanguageDragAndDropComponent,
        TimelineGroupBySelectorComponent,
        PasswordStrengthMeterComponent,
        ClearXButtonComponent,
        WorkspaceIdsToWorkspaces,
    ],
    exports: [
        UserLinkComponent,
        UserLinkListComponent,
        UserProfilePictureComponent,
        CountrySelectorComponent,
        FormsModule,
        ReactiveFormsModule,
        NgxMatSelectSearchModule,
        OnScrollBottomDirective,
        ActivityDataRenderComponent,
        ActivityDataFieldComponent,
        OnEnterDirective,
        FileUploaderComponent,
        MaterialModule,
        AttachmentsComponent,
        ProcessSelectorComponent,
        UserImageLinkComponent,
        UserMenuComponent,
        GmapComponent,
        FallbackImageDirective,
        AllowOnlyNumbersDirective,
        TagFilterObjectListComponent,
        TagSelectorComponent,
        ContainerComponent,
        ScrollingModule,
        AutofocusDirective,
        TakePictureDirective,
        PreventAutofillDirective,
        PermissionSelectorComponent,
        PermissionListComponent,
        DropdownSelectorComponent,
        DropdownOptionContentComponent,
        DropdownTeamItemComponent,
        DropdownUserItemComponent,
        DropdownActivityItemComponent,
        DropdownCalendarItemComponent,
        DropdownWorkspaceItemComponent,
        DropdownPreventClosingDirective,
        TeamUserSelectorComponent,
        TippyModule,
        LanguageDragAndDropComponent,
        TimelineGroupBySelectorComponent,
        PasswordStrengthMeterComponent,
        ClearXButtonComponent,
        WorkspaceIdsToWorkspaces,
        PipesModule,
    ],
    providers: [
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
        { provide: TRANSLOCO_SCOPE, useValue: 'misc' },
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginationIntlService,
        },
        ParticipantsPipe,
        ActivitiesHelperService,
        FilesService,
        ImageViewerService,
        StylingHelperService,
        ApiNotificationService,
        ParseIdsPipe,
    ],
})
export class SharedModule {}
