/** @format */

import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, takeUntil } from 'rxjs';

import { TranslateService } from './translate.service';

@Injectable()
export class MatPaginationIntlService extends MatPaginatorIntl {
    paginatorOfTranslation: string;
    private onDestroy = new Subject<void>();

    constructor(private translocoService: TranslocoService, private translateService: TranslateService) {
        super();
        this.translateService.translationLoaded('misc').then(() => {
            this.translocoService.langChanges$.pipe(takeUntil(this.onDestroy)).subscribe({
                next: () => {
                    this.translateLabels();
                },
            });
        });
    }

    getRangeLabel = (page: number, pageSize: number, length: number): string => {
        const ofTranslation = this.paginatorOfTranslation;
        if (length === 0 || pageSize === 0) {
            return `0 ${ofTranslation} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

        const endIndex = Math.min(startIndex + pageSize, length);
        return `${startIndex + 1} - ${endIndex} ${ofTranslation} ${length}`;
    };

    injectTranslocoService(translate: TranslocoService): void {
        this.translocoService = translate;

        this.translocoService.langChanges$.subscribe(() => {
            this.translateLabels();
        });

        this.translateLabels();
    }

    translateLabels(): void {
        this.firstPageLabel = this.translocoService.translate('misc.paginator.first_page_label');
        this.itemsPerPageLabel = this.translocoService.translate('misc.paginator.items_per_page_label');
        this.lastPageLabel = this.translocoService.translate('misc.paginator.last_page_label');
        this.nextPageLabel = this.translocoService.translate('misc.paginator.next_page_label');
        this.previousPageLabel = this.translocoService.translate('misc.paginator.previous_page_label');
        this.paginatorOfTranslation = this.translocoService.translate('misc.paginator.of');
        this.changes.next();
    }
}
