/** @format */

import { Pipe, PipeTransform } from '@angular/core';

import { Process } from '@app/models';

@Pipe({
    name: 'processDataset',
})
export class ProcessDatasetPipe implements PipeTransform {
    transform(value: Process[], args?: any): any {
        let out: Process[] = [];

        // If no input is not a array, return empty.
        if (!Array.isArray(value)) {
            return [];
        }

        // No argumented given, return default sorted process list.
        if (!args) {
            return value.sort((a, b) => (a.order !== undefined ? (a.order > b.order ? 1 : -1) : 1));
        }

        // Do the initial process | dataset splitting.
        out = value.filter(p => p.enableUnlinkedMode === args.datasetOnly || (!args.datasetOnly && p.enableUnlinkedMode === undefined));

        // If search is provided, create search data and match search text with it.
        if (args.search) {
            out = out.filter(p => this.generateSearchData(p, args.search));
        }

        // Do final sorting before sending response.

        out.sort((a, b) => {
            /* We need to do this sorting very weirdly,
               because firefox handles undefined values much differently than any othe browser it seems. */
            const aOrder = a.order;
            const bOrder = b.order;

            if ((aOrder || aOrder === 0) && (bOrder || bOrder === 0)) {
                return aOrder < bOrder ? -1 : 1;
            }
            return 0;
        });

        return out;
    }

    private generateSearchData(process: Process, search: string): boolean {
        const text: string[] = [];
        const searchLowerCase = search.toLowerCase();

        if (process.name.toLowerCase().includes(searchLowerCase)) {
            text.push(process.name);
        }

        process.phasesOrder.map(phase_id => {
            const phase = process.phases[phase_id];
            if (phase.name.toLowerCase().includes(searchLowerCase)) {
                text.push(phase.name);
            }
        });

        return text.length > 0;
    }
}
