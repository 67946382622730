/** @format */

import { createAction, props } from '@ngrx/store';

import { Message } from '@app/models';
import { V3DiscussionMessagesArgs } from 'app/_services/v3-messages.service';

export const loadLatestMessages = createAction(
    '[Messages] Load latest messages',
    props<{
        options: V3DiscussionMessagesArgs;
    }>()
);

export const loadPreviousMessages = createAction(
    '[Messages] Load previous messages',
    props<{
        options: V3DiscussionMessagesArgs;
    }>()
);

export const loadNextMessages = createAction(
    '[Messages] Load next messages',
    props<{
        options: V3DiscussionMessagesArgs;
    }>()
);

export const focusToMessage = createAction(
    '[Messages] Focus to message',
    props<{
        options: V3DiscussionMessagesArgs;
    }>()
);

export const getMessage = createAction(
    '[Messages] Get message',
    props<{
        options: V3DiscussionMessagesArgs;
    }>()
);

export const loadInitialMessagesSuccess = createAction(
    '[Messages API] Load Initial Messages Success',
    props<{
        newestLoaded: boolean;
        oldestLoaded: boolean;
        messages: {
            [discussionId: string]: Message[];
        };
    }>()
);

export const loadPreviousMessagesSuccess = createAction(
    '[Messages API] Load previous messages success',
    props<{
        oldestLoaded: boolean;
        messages: {
            [discussionId: string]: Message[];
        };
    }>()
);

export const loadNextMessagesSuccess = createAction(
    '[Messages API] Load next messages success',
    props<{
        newestLoaded: boolean;
        messages: {
            [discussionId: string]: Message[];
        };
    }>()
);

export const focusToMessageSuccess = createAction(
    '[Messages API] Focus to message success',
    props<{
        newestLoaded: boolean;
        oldestLoaded: boolean;
        messages: {
            [discussionId: string]: Message[];
        };
    }>()
);

export const getMessageSuccess = createAction(
    '[Messages API] Get message success',
    props<{
        messages: {
            [discussionId: string]: Message[];
        };
    }>()
);

export const addMessage = createAction(
    '[Messages] Add message',
    props<{
        message: Message;
    }>()
);

export const clearMessages = createAction(
    '[Messages] Clear messages',
    props<{
        discussionId: string;
    }>()
);

export const setScrollPosition = createAction(
    '[Messages] Set scroll position',
    props<{
        discussionId: string;
        scrollTop: number;
    }>()
);
