/** @format */

import { UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { Activity, Process } from '@app/models';
import { SideNavService } from 'app/_services/side-nav.service';
import { CoreService } from 'app/_services/core.service';
import { PermissionService } from 'app/_services/permission.service';
import { ProcessDatasetPipe } from 'app/activities/pipes/process-dataset.pipe';
import { ActivityLink, ActivityInitFieldValues as ActivityLinkInitFieldValues } from 'app/_models/v3-activity.model';
import { V3ActivitySidenavComponent } from 'app/v3-activity/v3-activity-sidenav/v3-activity-sidenav.component';

@Component({
    selector: 'app-create-linked-activity',
    templateUrl: './create-linked-activity.component.html',
    styleUrls: ['./create-linked-activity.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'activities-shared', alias: 'activities-shared' },
        },
    ],
})
export class CreateLinkedActivityComponent implements OnInit {
    @Input() activity: Activity;
    @Input() process: Process;
    @Input() fieldId: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() origin?: 'v3InputField';

    @Output() created = new EventEmitter<ActivityLink>();

    canBeLinkedTo: Process[] = [];

    constructor(private core: CoreService, private sideNavService: SideNavService, private permissions: PermissionService) {}

    ngOnInit() {
        this.setLinkableProcesses();
    }

    createLinkedActivity(processId: string) {
        try {
            const process = this.core.processes.value[this.core.network.value._id][processId];
            const initFieldValues: ActivityLinkInitFieldValues =
                this.activity && this.fieldId
                    ? {
                          [this.fieldId]: {
                              value: {
                                  _id: this.activity._id,
                                  name: this.activity.name,
                              },
                          },
                      }
                    : undefined;

            this.sideNavService.create(V3ActivitySidenavComponent, {
                action: 'create',
                processId: process._id,
                initFieldValues,
                created: (link: ActivityLink) => {
                    this.created.emit(link);

                    if (this.origin === 'v3InputField') {
                        this.sideNavService.pop();
                    }
                },
            });
        } catch (error) {
            console.error('createLinkedActivity error', error);
        }
    }

    private setLinkableProcesses(): void {
        const processesMap = this.core.processes.value[this.core.network.value._id];
        const processes = [];
        const linkableIds = this.process.fields[this.fieldId].data;
        const processDatasetPipe = new ProcessDatasetPipe();
        let workflows = [];
        let datasets = [];

        if (processesMap) {
            Object.keys(processesMap).forEach(id => [processes.push(processesMap[id])]);
        }

        workflows = processDatasetPipe.transform(processes, { datasetOnly: false });
        datasets = processDatasetPipe.transform(processes, { datasetOnly: true });

        workflows.forEach(workflow => {
            if (this.getCanBeLinkedTo(workflow._id, linkableIds)) {
                this.canBeLinkedTo.push(workflow);
            }
        });

        datasets.forEach(dataset => {
            if (this.getCanBeLinkedTo(dataset._id, linkableIds)) {
                this.canBeLinkedTo.push(dataset);
            }
        });
    }

    private getCanBeLinkedTo(processId, linkableIds): boolean {
        if (linkableIds.includes(processId)) {
            const firstPhaseAccess = this.permissions.viewOnlyPhase(this.core.user.value?.id, processId);

            if (!firstPhaseAccess) {
                return false;
            }

            return true;
        }
        return false;
    }
}
