<!-- @format -->

<div class="editor-container">
    <!-- If selectedApp is null, show creation labels -->
    <h1 *ngIf="!app">{{ 'apps.editor.titles.create' | transloco }}</h1>
    <h1 *ngIf="app">{{ 'apps.editor.titles.update' | transloco }} {{ app.name }}</h1>

    <div *ngIf="app" class="app-id" (click)="copyToClipboard(app._id)">
        {{ app._id }}
    </div>

    <div class="creation-form-container">
        <div class="creation-form-content">
            <form id="app-creation-form" [formGroup]="appForm">
                <div class="flex-column--centered">
                    <mat-form-field appearance="standard" class="form-field">
                        <mat-label>{{ 'apps.management.headers.name' | transloco }}</mat-label>
                        <mat-icon class="form-prefix" matPrefix svgIcon="hailer-text-area"></mat-icon>
                        <input
                            data-cy="app-form-name-input"
                            matInput
                            [appAutofocus]="true"
                            formControlName="name"
                            type="name"
                            class="form-control" />
                    </mat-form-field>
                </div>
                <div class="flex-column--centered">
                    <mat-form-field appearance="standard" class="form-field">
                        <mat-label>{{ 'apps.management.headers.description' | transloco }}</mat-label>
                        <mat-icon class="form-prefix" matPrefix svgIcon="hailer-text-area"></mat-icon>
                        <input
                            data-cy="app-form-description-input"
                            matInput
                            [appAutofocus]="true"
                            formControlName="description"
                            type="description"
                            class="form-control" />
                    </mat-form-field>
                </div>
                <div class="flex-column--centered">
                    <mat-form-field appearance="standard" class="form-field">
                        <mat-label>{{ 'apps.management.headers.url' | transloco }}</mat-label>
                        <mat-icon class="form-prefix" matPrefix svgIcon="hailer-text-area"></mat-icon>
                        <input
                            data-cy="app-form-url-input"
                            matInput
                            [appAutofocus]="true"
                            formControlName="url"
                            type="url"
                            class="form-control" />
                    </mat-form-field>
                </div>
            </form>
            <div class="flex-column--centered">
                <div class="image-upload-container">
                    <img
                        crossorigin="use-credentials"
                        [src]="imageUrl ?? '/assets/img/hailerlogo2.svg'"
                        alt="App image"
                        class="image-upload-bg"
                        [ngStyle]="{
                            'background-image': image ? 'url(\'/assets/img/checkerboard-pattern.png\')' : 'unset'
                        }"
                        onError="this.src='/assets/img/hailer_corner4.svg';" />
                    <div class="upload-button-container">
                        <app-button
                            type="text"
                            color="primary"
                            size="medium"
                            [label]="'apps.editor.image_upload' | transloco"
                            [ariaLabel]="'apps.editor.image_upload' | transloco"
                            (clickEvent)="openUploadDialog()">
                        </app-button>
                        <app-button
                            type="text"
                            color="destructive-default"
                            size="medium"
                            [label]="'apps.editor.image_remove' | transloco"
                            [ariaLabel]="'apps.editor.image_remove' | transloco"
                            (clickEvent)="removeImage()">
                        </app-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="button-container">
            <ng-container *ngIf="app">
                <app-button
                    data-cy="app-update-update-button"
                    [ariaLabel]="'apps.editor.update' | transloco"
                    [label]="'apps.editor.update' | transloco"
                    size="medium"
                    (clickEvent)="edit(app)">
                </app-button>
            </ng-container>

            <ng-container *ngIf="!app">
                <app-button
                    data-cy="app-update-create-button"
                    [ariaLabel]="'apps.editor.create' | transloco"
                    [label]="'apps.editor.create' | transloco"
                    size="medium"
                    (clickEvent)="create()">
                </app-button>
            </ng-container>

            <app-button
                type="cancel"
                size="medium"
                [ariaLabel]="'apps.management.headers.actions.close' | transloco"
                (clickEvent)="close()">
            </app-button>
        </div>
    </div>
</div>
