/** @format */

import { createAction, props } from '@ngrx/store';

import { UserMap } from '@app/models';

export const setUsers = createAction(
    '[Users] Set users',
    props<{
        users: UserMap;
    }>()
);
