/** @format */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject, debounceTime, takeUntil } from 'rxjs';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { Discussion } from '@app/models';
import { PeopleService } from 'app/people/people.service';
import { MessagesGlobalSearch } from 'app/_models/globalSearch.model';
import { CoreService } from 'app/_services/core.service';

@Component({
    selector: 'app-discussion-search',
    templateUrl: './discussion-search.component.html',
    styleUrls: ['./discussion-search.component.scss'],
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'discussion', alias: 'discussion' } }],
})
export class DiscussionSearchComponent implements OnInit, OnDestroy, OnChanges {
    @Input() inputDiscussions: Discussion[];
    @Output() outputDiscussions = new EventEmitter<Observable<Discussion[]>>();
    @Output() searchString = new EventEmitter<string>();

    constructor(private people: PeopleService, private core: CoreService) {}

    searchedMessages: MessagesGlobalSearch[] = [];
    discussionSearchCtrl = new FormControl();
    private onDestroy = new Subject<void>();
    private discussionsChanged = new Subject<void>();
    private localSearchString = '';

    ngOnInit(): void {
        const returnObservable = new Observable<Discussion[]>(subscriber => {
            subscriber.next(this.inputDiscussions);
            this.discussionSearchCtrl.valueChanges.pipe(takeUntil(this.onDestroy), debounceTime(350)).subscribe({
                next: value => {
                    if (!value) {
                        this.localSearchString = '';
                        this.searchString.emit(this.localSearchString);
                        subscriber.next(this.inputDiscussions);
                        return;
                    }

                    this.localSearchString = value.toLowerCase();
                    this.searchString.emit(this.localSearchString);

                    const filteredDiscussions = this.inputDiscussions.filter(discussion => this.matchSearchString(discussion));
                    subscriber.next(filteredDiscussions);
                },
            });

            this.discussionsChanged.pipe(takeUntil(this.onDestroy)).subscribe({
                next: () => {
                    const filteredDiscussions = this.inputDiscussions.filter(discussion => this.matchSearchString(discussion));
                    subscriber.next(filteredDiscussions);
                },
            });
        });

        this.outputDiscussions.emit(returnObservable);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.inputDiscussions && changes.inputDiscussions.currentValue !== changes.inputDiscussions.previousValue) {
            this.discussionsChanged.next();
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
        this.discussionsChanged.complete();
    }

    private getDiscussionSubject(discussion: Discussion): string {
        if (!discussion) {
            return '';
        }

        let subject: string;

        if (discussion.private) {
            const filteredParticipants = this.getParticipantsWithoutMe(discussion);
            subject = filteredParticipants[0] ? this.people.getUser(filteredParticipants[0]).display_name : 'Unknown User';
        }

        subject = discussion?.subject;

        return subject || '';
    }

    private matchSearchString(discussion: Discussion): boolean {
        if (!discussion) {
            return false;
        }

        const subjectMatch = this.getDiscussionSubject(discussion)?.toString().toLowerCase().includes(this.localSearchString);
        const participantMatch = !!this.getParticipantsWithoutMe(discussion)
            .map(participantUid => this.core.getUser(participantUid)?.display_name)
            .find(participantName => participantName?.toLocaleLowerCase()?.includes(this.localSearchString));

        return subjectMatch || participantMatch;
    }

    private getParticipantsWithoutMe(discussion: Discussion): string[] {
        return discussion.participants.filter(uid => uid !== this.core.user.value._id);
    }
}
