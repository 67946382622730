/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'thousandSeparator',
})
export class ThousandSeparatorPipe extends DecimalPipe implements PipeTransform {
    transform(value: any, digits?: string, locale?: string, decimals?: number) {
        const fixedNumber = decimals ? parseFloat(value.toFixed(decimals)) : value;
        return value && typeof value === 'number' ? super.transform(fixedNumber, digits).replace(/,/g, ' ') : value;
    }
}
