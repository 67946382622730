<!-- @format -->

<div class="forward-dialog-container">
	<div class="forward-header">
		<h1 class="header-text" mat-dialog-title>
			{{ data.title }}
		</h1>
		<app-button
			type="icon"
			size="medium"
			color="icon-white-all-themes"
			hailerIconName="hailer-x"
			(clickEvent)="onCancel()"
			[tippyText]="'dialogs.close' | transloco"
			[ariaLabel]="'dialogs.close-dialog' | transloco"></app-button>
	</div>
	<app-discussion-picker
		(discussionId)="onForward($event)"
		[discussions]="discussions"
		[hiddenDiscussionIds]="data.message.discussion"></app-discussion-picker>
</div>
