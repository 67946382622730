<!-- @format -->

<div class="activity-data" *ngIf="isRenderable">
	<div class="process-image" [style.backgroundImage]="'url(' + wsUrl + '/image/hires/' + process.coverImage + ')'"></div>
	<div class="activity-data-block activity-name">{{ activity.name }}</div>
	<div class="activity-data-fields">
		<ng-container *ngFor="let field of phaseAnnouncementFields | async">
			<ng-container *ngIf="getField(field._id)">
				<app-activity-data-field
					class="activity-data-block"
					[discussionMode]="styleClassMode"
					[field]="field"
					[data]="getField(field._id)"></app-activity-data-field>
			</ng-container>
		</ng-container>
	</div>
</div>
