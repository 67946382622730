/** @format */

import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { GoogleAuth as CapacitorGoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Capacitor } from '@capacitor/core';
import { CredentialResponse } from 'google-one-tap';

import { environment } from '@app/env';
import { RPCService } from './rpc.service';
import { CoreService } from 'app/_services/core.service';

type authType = 'login' | 'register';

@Injectable({
    providedIn: 'root',
})
export class GoogleAuth2Service {
    loginErrorMsg = new Subject<string>();
    registerErrorMsg = new Subject<string>();
    registerError = new Subject<RegisterError>();

    private clientId = environment.googleClientId;
    private copyNetworkId: string | undefined;

    constructor(private zone: NgZone, private rpc: RPCService, private auth: CoreService) {}

    async registerGoogleAsync(idToken: string, copyNetworkId?: string): Promise<RegisterResponse> {
        return (await this.rpc.requestAsync('v2.user.registerGoogle', [idToken, { copyNetworkId }])) as RegisterResponse;
    }

    signInCapacitor(type: authType): void {
        if (Capacitor.isNativePlatform()) {
            CapacitorGoogleAuth.signIn()
                .then(async gUser => {
                    const credential = gUser.authentication.idToken;

                    const response: CredentialResponse = {
                        credential,
                        // eslint-disable-next-line
                        select_by: 'auto'
                    };

                    switch (type) {
                        case 'login': {
                            await this.handleCredentialResponseLogin(response);
                            break;
                        }
                        case 'register': {
                            await this.handleCredentialResponseRegister(response);
                            break;
                        }
                    }
                })
                .catch(error => console.error(error));
        }
    }

    // Part of the Google identity services (accounts.google.com/gsi/client)
    loadGoogleLibrary(copyNetworkId?: string, options: { register?: boolean } = {}) {
        this.copyNetworkId = copyNetworkId;

        google.accounts.id.initialize({
            client_id: this.clientId,
            callback: options.register ? this.handleCredentialResponseRegister.bind(this) : this.handleCredentialResponseLogin.bind(this),
            // eslint-disable-next-line
            auto_select: false,
            // eslint-disable-next-line
            cancel_on_tap_outside: true
        });

        google.accounts.id.renderButton(
            document.getElementById('googleBtn') as HTMLElement,
            // eslint-disable-next-line
            { shape: 'pill', theme: 'outline', size: 'large', width: 330, logo_alignment: 'center' }
        );

        /* This is not working with Cypress. Disabled for now.
           Add import { PromptMomentNotification } from 'google-one-tap'; to use it again.
           google.accounts.id.prompt((notification: PromptMomentNotification) => { }); */
    }

    /* Log in to Hailer with Google
       If user does not exist, register user and log in again */
    private async handleCredentialResponseLogin(response: CredentialResponse) {
        const idToken = response.credential;

        try {
            await this.loginUser(idToken);
        } catch (loginError) {
            console.error('login error:', loginError);

            // If user has not yet registered but is trying to sign in with Google we register them here
            if (loginError.code === 404) {
                try {
                    await this.registerGoogleAsync(idToken);
                    await this.loginUser(idToken);
                } catch (registerError) {
                    console.log('Register Google error: ', registerError);
                    this.registerErrorMsg.next(registerError.msg);
                    return;
                }
            }

            this.loginErrorMsg.next(loginError.msg);
        }
    }

    // Register and login to Hailer with Google
    private async handleCredentialResponseRegister(response: CredentialResponse) {
        const idToken = response.credential;

        try {
            await this.registerGoogleAsync(idToken, this.copyNetworkId);
            await this.loginUser(idToken);
        } catch (error) {
            const registerError: RegisterError = {
                code: error.code,
                msg: error.msg,
            };

            // 409 = user already registered
            if (error.code === 409) {
                await this.loginUser(idToken);
                return;
            }

            console.error('Register and login error:', error);

            this.registerError.next(registerError);
        }
    }

    private async loginUser(idToken: string) {
        const sessionToken = (await this.rpc.requestAsync('v3.loginGoogle', [idToken])) as string;

        this.auth.setSessionToken(sessionToken);

        this.zone.run(async () => {
            await this.auth.initData();
        });
    }
}

interface RegisterResponse {
    uid: string;
    cid: string;
}
interface RegisterError {
    code: number;
    msg: string;
}
