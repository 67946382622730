/** @format */

export class CountryField {
    constructor(
        public value: {
            code: string;
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'country-code': string;
            name: string;
        }
    ) {}
}
