/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { unescape } from 'html-escaper';

@Pipe({
    name: 'htmlUnescape',
})
export class HtmlUnescapePipe implements PipeTransform {
    transform(text: any, onlyCode?: boolean): any {
        if (onlyCode) {
            const match = text.match(/<code\b[^>]*>([\w\W]*?)<\/code>/gm);
            if (!match) {
                return text;
            }
            return unescape(text);
        }

        return unescape(text);
    }
}
