import { PermissionCheckReturn, PermissionError, PermissionErrorCodes, PermissionMap, WorkspacePermissions } from '../permission-models';


export class PhasePermission {
    constructor(
    private userId: string,
    private permissions: PermissionMap,
    ) { }

    /** Workspace owners and admins are process admins */
    isAdmin(workspaceId: string): PermissionCheckReturn {
        const [error, workspacePermissions] = this.workspacePermissions(workspaceId);
        if (error) {
            return [error];
        }

        const isOwner = workspacePermissions?.workspace?.isOwner;
        const isAdmin = workspacePermissions?.workspace?.isAdmin;
        if (!isOwner && !isAdmin) {
            return [{ name: 'Invalid permissions', message: 'User needs to be an admin' }];
        }

        return [undefined, true];
    }

    list (workspaceId: string, workflowId: string, phaseId: string): PermissionCheckReturn {
        const [error, workspacePermissions] = this.workspacePermissions(workspaceId);
        if (error) {
            return [error];
        }
        const phaseAccess = workspacePermissions?.workflow?.[workflowId]?.phases?.[phaseId]?.hasAccess;

        if (!phaseAccess) {
            return [{ name: 'Insufficient permissions.', message: `Can't access process or phase.` }];
        }

        return [undefined, true];
    }


    private workspacePermissions(workspaceId: string): [error?: PermissionError, permissions?: WorkspacePermissions] {
        if (!this.permissions) {
            return [{ name: 'Invalid permissions', message: 'User permissions are undefined', code: PermissionErrorCodes.missing, workspaceId }];
        }

        const workspacePermissions = this.permissions[workspaceId];
        if (!workspacePermissions) {
            return [{ name: 'Invalid permissions', message: 'Workspace permissions are undefined', code: PermissionErrorCodes.missing, workspaceId }];
        }

        return [undefined, workspacePermissions];
    }
}
