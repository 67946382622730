/** @format */

import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor, registerPlugin } from '@capacitor/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { v4 as uuidv4 } from 'uuid';
import { filter } from 'rxjs/operators';

import { CoreService } from './core.service';
import { RPCService } from './rpc.service';

export interface HailerNotificationsPlugin {
    addListener(eventName: 'newToken', listener: (event: { token: string }) => void);
    addListener(eventName: 'openDiscussion', listener: (event: { discussionId: string }) => void);
    addListener(eventName: 'registrationError', listener: (event: { message: string }) => void);
    register(): Promise<void>;
}

@Injectable({ providedIn: 'root' })
export class PushNotificationService {
    private capacitorNotifications: HailerNotificationsPlugin;

    constructor(
        private rpc: RPCService,
        private core: CoreService,
        private deviceInfo: DeviceDetectorService,
        private router: Router,
        private zone: NgZone
    ) {}

    init() {
        this.capacitorNotifications = registerPlugin<HailerNotificationsPlugin>('HailerNotifications');

        this.capacitorNotifications.addListener('newToken', event => {
            this.zone.run(() => {
                this.registerToken(event.token);
            });
        });

        this.capacitorNotifications.addListener('openDiscussion', event => {
            this.zone.run(() => {
                this.router.navigate([`/discussions/${event.discussionId}`]);
            });
        });

        this.capacitorNotifications.addListener('registrationError', event => {
            console.error(`Firebase registration failed with message: ${event.message}`);
        });

        this.core.state.pipe(filter(state => state === 'authenticated')).subscribe({
            next: () => {
                this.capacitorNotifications.register();
            },
        });

        this.core.state.pipe(filter(state => state === 'login')).subscribe({
            next: () => {
                this.removeToken();
            },
        });
    }

    /**
     * Remove device token from the database.
     */
    private removeToken() {
        const token = window.localStorage.getItem('androidNotificationToken');
        if (token != null) {
            this.rpc.request('devices.remove', [token]).subscribe();
            window.localStorage.removeItem('androidNotificationToken');
        }
    }

    /**
     * Add device token to the database
     *
     * @param token
     */
    private registerToken(token: string) {
        window.localStorage.setItem('androidNotificationToken', token);

        const now = new Date();
        const device = {
            token,
            name: `${this.deviceInfo.os} - (Last login: ${now.toUTCString()})`,
            device_id: uuidv4(),
            platform: Capacitor.getPlatform(),
        };

        this.rpc.request('devices.register', [device]).subscribe();
    }
}
