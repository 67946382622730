/** @format */

import { createReducer, on } from '@ngrx/store';

import { PersonalSettings } from '@app/models';
import { loadPersonalSettingsSuccess, setGlobalSettings, setPersonalSettings } from '../actions/personal-settings.actions';

const reducer = createReducer(
    {},
    on(loadPersonalSettingsSuccess, (state, { personalSettings }) => personalSettings),
    on(setPersonalSettings, (state, { personalSettings }) => personalSettings),
    on(setGlobalSettings, (state, globalSettings) => ({ ...state, ...globalSettings } as unknown as PersonalSettings))
    // On(setGlobalSettings, (state, globalSettings) => Object.assign(Object.assign({}, state), globalSettings))
);

export const personalSettingsReducer = (state, action) => reducer(state, action);
