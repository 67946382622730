/** @format */

import { Injectable } from '@angular/core';
import * as _moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { flatMap, map, pluck } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { User, UserMap } from '../_models/user.model';
import { Team } from '../_models/team.model';
import { RPCService } from '../_services/rpc.service';
import { TeamService } from 'app/_services/team.service';
import { Device, GenericUpdate, Invitation, NewInvitations } from '@app/models';
import { environment } from '@app/env';
import { CoreService } from 'app/_services/core.service';

@Injectable({
    providedIn: 'root',
})
export class PeopleService {
    user = this.core.user;
    usersSubject = new BehaviorSubject<User[]>([]);
    users$ = this.usersSubject.asObservable();
    usersByNetwork = new BehaviorSubject<User[]>([]);
    unknownUsers: BehaviorSubject<UserMap> = this.core.unknownUsers;
    mockUserIds = [
        '000000000000000000000000', // Hailer Unlimited
        '000000000000000000000001', // Form User
    ];

    private apiKey = environment.googleMapsApiKey;
    private teamList: Team[] = [];

    private users: User[] = [];

    constructor(private core: CoreService, private rpc: RPCService, private teamService: TeamService, private http: HttpClient) {
        this.core.users.subscribe(users => {
            const userArray: User[] = [];
            for (const userId of Object.keys(users)) {
                const user = users[userId];

                userArray.push(user);
            }

            this.users = userArray;
            this.usersSubject.next(userArray);
        });

        this.usersSubject
            .pipe(
                map(usersArray => {
                    const filteredUsers = [];
                    usersArray.forEach(user => {
                        if (user.companies?.includes(this.core.network.value?._id)) {
                            filteredUsers.push(user);
                        }
                    });
                    return filteredUsers;
                }),
                map(usersFiltered => this.sortUsersByName(usersFiltered))
            )
            .subscribe(filtered => this.usersByNetwork.next(filtered));
    }

    findUserInviteByEmail(email: string): Invitation | null {
        let foundInvite: Invitation | null = null;

        const invitations = this.core.invitations.value;

        if (!Object.keys(invitations)?.length) {
            return null;
        }

        const networkInvitations = invitations[this.core.network.value?._id];

        if (!Object.keys(networkInvitations)?.length) {
            return null;
        }

        Object.values(networkInvitations).forEach(invitation => {
            if (foundInvite) {
                return;
            }
            if (invitation.email === email && invitation.cid === this.core.network.value._id) {
                foundInvite = invitation;
            }
        });
        return foundInvite;
    }

    inviteGuestsToDiscussion(guestEmails: string[], discussionId: string, cid?: string) {
        guestEmails.forEach(email => {
            const foundInvitation = this.findUserInviteByEmail(email);

            if (!foundInvitation) {
                const invitation: NewInvitations = {
                    email,
                    role: 'guest',
                    discussionIds: [discussionId],
                    cid,
                };

                this.rpc.request('v2.network.invite.send', [invitation]).subscribe({
                    error: error => console.error('Error happened while inviting a user', error),
                });
            } else {
                const discussionIds = foundInvitation.discussionIds ? foundInvitation.discussionIds : [];

                if (!discussionIds.includes(discussionId)) {
                    discussionIds.push(discussionId);
                    this.rpc.request('v2.network.invite.edit', [foundInvitation.invite_key, { discussionIds }]).subscribe({
                        error: error => console.error('Error happened while editing a invitation', error),
                    });
                }
            }
        });
    }

    compareUsers(a: User, b: User): number {
        if (b) {
            let firstnameComparison: number;
            if (b.firstname && a.firstname) {
                firstnameComparison = a.firstname.toLocaleLowerCase().localeCompare(b.firstname.toLocaleLowerCase());
            } else if (b.firstname && !a.firstname && a.lastname) {
                firstnameComparison = a.lastname.toLocaleLowerCase().localeCompare(b.firstname.toLocaleLowerCase());
            } else if (a.firstname && !b.firstname && b.lastname) {
                return a.lastname.toLocaleLowerCase().localeCompare(b.lastname.toLocaleLowerCase());
            }

            if (firstnameComparison === 0 && b.lastname && a.lastname) {
                return a.lastname.toLocaleLowerCase().localeCompare(b.lastname.toLocaleLowerCase());
            }
            return firstnameComparison;
        }
    }

    prepare(users: User[], originalList: User[]): void {
        const newUserIds = this.sortNewUsersFromOriginalUsers(users, originalList);
        this.findNewUsersFromTeams(newUserIds);
    }

    getUsers(): User[] {
        return this.sortUsersByName(this.users).filter(({ _id }) => !this.isMockUser(_id));
    }

    /**
     *  Checks if user is a mock user, accepts both users and user ids
     *
     * @param user user or user id
     * @returns true or false
     */
    isMockUser(user: User | string): boolean {
        if (this.mockUserIds.indexOf(user instanceof User ? user._id : user) !== -1) {
            return true;
        }
        return false;
    }

    /* Private sortUsersByDisplayName(users: User[]): User[] {
           return users.sort((u1, u2) => u1.display_name.toLowerCase().localeCompare(u2.display_name.toLowerCase()));
       } */

    getNetworkUsers(customCid?: string): User[] {
        if (!this.core.network.value?._id) {
            return [];
        }

        const cid = customCid ? customCid : this.core.network.value._id;
        return this.getUsers().filter(user => user?.companies?.includes(cid));
    }

    getNetworkGuestIds(networkId: string) {
        const members = this.core.networks.value?.[networkId]?.members;

        if (!members) {
            return [];
        }

        const guestUserIds = members.filter(member => member.guest).map(member => member.uid);

        return guestUserIds;
    }

    getTeamList(): Team[] {
        return this.teamList;
    }

    getUser(userId: string) {
        if (userId && userId.startsWith('user_')) {
            userId = userId.slice(5);
        }

        return this.core.getUser(userId);
    }

    async getUserAsync(userId: string): Promise<User | null> {
        if (userId && userId.startsWith('user_')) {
            userId = userId.slice(5);
        }

        return this.core.getUserAsync(userId);
    }

    getUserAsObservable(uid: string): Observable<User> {
        return this.core.users.pipe(pluck(uid));
    }

    getUserByName(name: string): User {
        const foundUser = Array.from(this.users.values()).find(
            user => `${user.firstname.toLocaleLowerCase()} ${user.lastname.toLocaleLowerCase()}` === name.toLocaleLowerCase()
        );
        if (foundUser) {
            return foundUser;
        }
        return new User().deserialize({ firstname: 'Unknown', lastname: 'user', _id: '000000' });
    }

    selectUser(user: User) {
        const foundUser = this.users.find(currentUser => currentUser._id === user._id);
        if (foundUser) {
            foundUser.selected = !user.selected;
        }
        /* TODO Check later if getInvites has to be called
           this.getInvites(); */
    }

    selectTeam(teamToSelect: Team) {
        const foundTeam = this.teamList.find(currentTeam => currentTeam._id === teamToSelect._id);
        if (foundTeam) {
            foundTeam.selected = !foundTeam.selected;
        }
        /* TODO Check later if getInvites has to be called
           this.getInvites(); */
    }

    getUserTeams(userId: string): Team[] {
        return this.teamList.filter(team => team.members.indexOf(userId) > -1);
    }

    getInvites(): any {
        const invitesUidArray: any[] = [];
        for (const team of this.teamList) {
            if (team.selected) {
                team.members.forEach(user => {
                    invitesUidArray.push(user);
                });
            }
        }

        for (const user of this.users) {
            if (user.selected) {
                invitesUidArray.push(user._id);
            }
        }
        return invitesUidArray;
    }

    lastSeen(user: User): string {
        if (user.online) {
            user.lastSeen = _moment().milliseconds(0).toISOString();
        } else if (user.lastSeen) {
            return user.lastSeen;
        } else {
            return 'never';
        }
    }

    displayName(user: User): string {
        return `${user.firstname} ${user.lastname}`;
    }

    profilePicture(user: User): string {
        return `${this.envUrl}/image/square200/${user.default_profilepic}`;
    }

    // TODO: is there a model for this?
    getProfileInfo(): Observable<any> {
        return this.rpc.request('user.get_profile_info', []);
    }
    // TODO: is there a model for this?
    addProfilePicture(fileId: string): Observable<any> {
        return this.rpc
            .request('user.add_profile_pic_to_user', [{ file_id: fileId }])
            .pipe(flatMap((data: any) => this.rpc.request('files.set_file_metadata', [{ file_id: fileId }, { profilepic: true }])));
    }
    // TODO: is there a model for this?
    deleteProfilePicture(profilePictureId: string): Observable<any> {
        return this.rpc.request('user.remove_profile_pic', [profilePictureId]);
    }
    // TODO: is there a model for this?
    setProfilePicture(profilePictureId: string): Observable<any> {
        return this.rpc.request('user.set_default_profile_pic', [profilePictureId]);
    }
    // TODO: is there a model for this?
    getDeviceList(): Observable<Device[]> {
        return this.rpc.request('devices.list', []);
    }
    // TODO: is there a model for this?
    removeDevice(token: string): Observable<any> {
        return this.rpc.request('devices.remove', [token]);
    }
    // TODO: is there a model for this?
    setNotifications(notifications: any): Observable<any> {
        return this.rpc.request('notification.set_notification_option', [notifications]);
    }

    changePassword(passwordFormValue: any): Observable<GenericUpdate> {
        return this.rpc.request('user.change_password', [passwordFormValue]);
    }
    // TODO: is there a model for this?
    changeUserInfo(field: any, value: any): Observable<boolean> {
        return this.rpc.request('user.set_user_info', [field, value]);
    }
    // TODO: is there a model for this?
    changeNetworkPropertyValue(fieldId: string, data: any): Observable<any> {
        return this.rpc.request('company.set_user_property_value', [fieldId, data]);
    }
    // TODO: is there a model for this?
    changeNetworkPropertyValueDate(fieldId: string, data: string): Observable<any> {
        return this.rpc.request('company.set_user_property_value', [fieldId, data]);
    }

    async getLocationAsync(): Promise<GeolocationPosition | undefined> {
        if (!navigator.geolocation) {
            console.error('Geolocation not supported');
            return;
        }

        return new Promise<GeolocationPosition | undefined>(
            (resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    data => resolve(data),
                    (error) => reject(error),
                    { timeout: 10000 }
                );
            }
        );
    }

    async getAddressesAsync(latitude: number, longitude: number): Promise<any> {
        return new Promise((resolve) => {
            this.http
                .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${this.apiKey}`)
                .subscribe(response => resolve(response));
        });
    }

    getGeoLocation() {
        const result = new Subject<any>();

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                data => {
                    result.next(data);
                    result.complete();
                },
                () => {
                    result.error('Request Invalid/Timeout');
                },
                { timeout: 10000 }
            );
        } else {
            result.error('Geolocation not supported');
        }

        return result.asObservable();
    }

    getGeoAddress(latitude: string, longitude: string) {
        const result = new Subject<any>();

        this.http
            .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${this.apiKey}`)
            .subscribe(response => {
                result.next(response);
                result.complete();
            });

        return result.asObservable();
    }

    private sortUsersByName(users: User[]) {
        return users.sort((a: User, b: User) => this.compareUsers(a, b));
    }

    private sortNewUsersFromOriginalUsers(users: User[], originalList: User[]): string[] {
        const newUserIds: string[] = [];

        for (const user of users) {
            if (originalList.indexOf(user) === -1) {
                newUserIds.push(user._id);
                user.selected = false;
                this.users.push(user);
            }
        }

        return newUserIds;
    }

    private findNewUsersFromTeams(newUserIds: string[]) {
        for (const team of this.teamService.getTeams()) {
            const userIdsFoundInTeam: string[] = [];

            for (const userId of team.members) {
                if (newUserIds.includes(userId)) {
                    userIdsFoundInTeam.push(userId);
                }
            }
            if (userIdsFoundInTeam.length) {
                this.teamList.push(
                    new Team().deserialize({
                        _id: team._id,
                        name: team.name,
                        users: userIdsFoundInTeam,
                        selected: false,
                    })
                );
            }
        }
    }

    private get envUrl(): string {
        return environment.wsUrl;
    }
}
