/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { Subject, takeUntil } from 'rxjs';

import { CoreService } from 'app/_services/core.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-login-token',
    templateUrl: './login-token.component.html',
    styleUrls: ['./login-token.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
            },
        },
    ],
})
export class LoginTokenComponent implements OnInit {
    loginToken: string | null;
    languageMenuOpen = false;
    languages: string[] = ['en', 'fi', 'sv'];
    selectedLanguage: string;

    private onDestroy = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private core: CoreService,
        private router: Router,
        private translocoService: TranslocoService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.route.queryParams.pipe(takeUntil(this.onDestroy)).subscribe(params => {
            this.loginToken = params.token;
            this.verifyToken();
        });

        this.setLanguage();
    }

    verifyToken(): void {
        if (!this.loginToken) {
            return;
        }
        this.core
            .loginToken(this.loginToken)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                error: error => {
                    console.error('error logging in', error);
                    this.core.setState('login');
                    this.loginToken = null;
                    this.cdr.detectChanges();
                },
            });
    }

    async resetPage(): Promise<void> {
        const currentUrl = JSON.parse(JSON.stringify(this.router.url));
        await this.router.navigateByUrl('/', { skipLocationChange: true });
        await this.router.navigateByUrl(currentUrl);
    }

    async changeLanguage(language: string): Promise<void> {
        this.selectedLanguage = language;
        this.translocoService.setActiveLang(language);
        localStorage.setItem('selectedLanguage', language);
        await this.resetPage();
    }

    setLanguage(): void {
        const selectedLanguage = localStorage.getItem('selectedLanguage');

        if (!selectedLanguage || selectedLanguage === 'null') {
            this.selectedLanguage = this.languages[0] ?? 'en';
            localStorage.setItem('selectedLanguage', this.languages[0] ?? 'en');
        } else {
            this.selectedLanguage = selectedLanguage;
        }

        this.translocoService.setActiveLang(this.selectedLanguage);
    }
}
