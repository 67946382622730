<!-- @format -->

<div class="sidenav-container">
	<div class="event-detail-header">
		<div [ngClass]="{ 'event-create-top-content': action === 'create' }" class="event-header-wrapper flex">
			<mat-icon class="calendar-icon" draggable="false" aria-label="Date button" svgIcon="hailer-events"></mat-icon>
			<div class="event-header" *ngIf="event !== null">
				<h1 class="event-name ns-title-bold-1 two-line-ellipsis">{{ event.title || '' }}</h1>
				<p class="network-tag two-line-ellipsis" [ngStyle]="{ 'background-color': calendar.color }">{{ calendar.name }}</p>
			</div>
		</div>
		<div class="event-buttons-wrapper">
			<app-button
				type="icon"
				color="icon-default"
				size="small"
				[ariaLabel]="'events.event_sidenav.aria-label.close' | transloco"
				hailerIconName="hailer-x"
				(clickEvent)="sideNav.clear()"
				[tippyText]="'events.event_sidenav.tippy.close' | transloco"></app-button>

			<app-button
				*ngIf="(sideNav.stackSize$ | async) > 1"
				type="icon"
				color="icon-default"
				size="small"
				matIconClass="back-arrow"
				[ariaLabel]="'events.event_sidenav.aria-label.go-back' | transloco"
				hailerIconName="hailer-logo"
				(clickEvent)="sideNav.pop()"
				[tippyText]="'events.event_sidenav.tippy.go-back' | transloco"></app-button>
		</div>
	</div>
	<ng-container>
		<ng-container *ngIf="action === 'view'">
			<app-event-detail #eventDetail [calendars]="calendars" [event]="event"></app-event-detail>
		</ng-container>
		<ng-container *ngIf="action === 'create'">
			<app-event-create [calendars]="calendars" [event]="event" (pop)="sideNav.pop()"></app-event-create>
		</ng-container>
		<ng-container *ngIf="action === 'error'">
			<h3 data-cy="unable-to-load-event" class="no-event">{{ 'events.event_sidenav.unable_to_load_event' | transloco }}</h3>
		</ng-container>
	</ng-container>
</div>
