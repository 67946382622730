/** @format */

import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { Subject, combineLatest, debounceTime, filter, take, takeUntil } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '@app/env';
import { Company, DocumentTemplate, Process } from '@app/models';
import { UserInviteDialogComponent } from '@app/shared/user-invite-dialog/user-invite-dialog.component';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';
import {
    ActivityInitFieldValues,
    ActivityLink,
    ActivitySidenavActions,
    ActivityTabTypes,
    SetCreateArguments,
    SetEditMultipleArguments,
    SetViewArguments,
    UpgradeDiscussionInfo,
} from 'app/_models/v3-activity.model';
import { FilesService } from 'app/_services/files.service';
import { FinndentService } from 'app/_services/finndent.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { TranslateService } from 'app/_services/translate.service';
import { ProcessEditorDocTemplatesService } from 'app/activities/components/process-editor/process-editor-doc-templates/process-editor-doc-templates.service';
import { V3ActivityViewContextService } from '../v3-activity-view-context.service';
import { V3EditMultipleHelperService } from '../v3-edit-multiple-helper.service';
import { WindowListenerService } from 'app/_services/window-listener.service';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { StylingHelperService } from 'app/_services/styling-helper.service';

@Component({
    selector: 'app-v3-activity-sidenav',
    templateUrl: './v3-activity-sidenav.component.html',
    styleUrls: ['./v3-activity-sidenav.component.scss'],
    providers: [
        V3ActivityViewContextService,
        {
            provide: TRANSLOCO_SCOPE,
            useValue: [
                { scope: 'activity-sidenav', alias: 'activity-sidenav' },
                { scope: 'activities-shared', alias: 'activities-shared' },
                { scope: 'activities', alias: 'activities' },
            ],
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class V3ActivitySidenavComponent implements OnInit, OnDestroy, AfterViewChecked {
    @ViewChild('activityTabs', { static: false }) activityTabs: MatTabGroup;
    @ViewChild('activityTopbar', { static: false }) activityTopbar: ElementRef;
    @ViewChild('controlButtons', { static: false }) controlButtons: any;
    @ViewChild('followersContainer', { static: false }) followersContainer: any;

    @Input() action: ActivitySidenavActions = 'view';
    @Input() activityId?: string;
    @Input() activities?: { _id: string; name: string }[];
    @Input() processId?: string;
    @Input() phaseId?: string;
    @Input() initTab: ActivityTabTypes = 'detail';
    @Input() initFieldValues: ActivityInitFieldValues = {};
    @Input() createdFromDiscussions?: boolean;
    @Input() createdFromEvents?: boolean;
    @Input() discussionInfo?: UpgradeDiscussionInfo;
    @Input() editing?: boolean;
    @Input() nextPhaseId?: string;

    @Output() created = new EventEmitter<ActivityLink>();
    @Output() updated = new EventEmitter<ActivityLink>();
    @Output() updatedMultiple = new EventEmitter<ActivityLink[]>();
    @Output() canceled = new EventEmitter<ActivityLink>();

    @Output() ready = new EventEmitter<boolean>();

    saveButtonTitles: { [action: string]: string } = {
        create: 'Create',
        view: 'Save',
    };
    followerPipeArgs = { returnAll: true, returnId: true, currentUserFirst: true };
    isSendingData = false;
    isGeneratingDocument = false;
    hideEditMultipleNote = true;
    editingMapLocation = false;

    saving = false;
    isDocumentPreview = false;
    sourceUrl: SafeResourceUrl;
    filename: string;
    applicationType: string;
    csvData: string;
    loadingPreviewDoc: boolean;
    templateName: string;

    selectedTemplates: string[] = [];

    editorOptions = {
        theme: 'vs-light',
        language: 'javascript',
        wordWrap: 'on',
        fontSize: '14px',
        fontFamily: 'monospace',
        scrollBeyondLastLine: false,
        minimap: {
            enabled: false,
        },
        inlayHints: {
            enabled: true,
        },
        scrollbar: {
            alwaysConsumeMouseWheel: false,
        },
        renderWhitespace: 'none',
    };

    screenWidth = window.innerWidth;
    documentPreviewMinWidth = 800;
    previousScrollPosition = 0;
    scrollingUpPosition = 0;
    scrollingDownPosition = 0;

    private onDestroy = new Subject<void>();
    private finndentProcessIds = environment.finndentProcessIds;
    private finndentPhaseIds = environment.finndentPhaseIds;

    constructor(
        public viewContext: V3ActivityViewContextService,
        public files: FilesService,
        public platform: Platform,
        public stylingHelperService: StylingHelperService,
        private zone: NgZone,
        private cdr: ChangeDetectorRef,
        private matDialog: MatDialog,
        private sideNav: SideNavService,
        private finndent: FinndentService,
        private transloco: TranslocoService,
        private translate: TranslateService,
        private v3EditMultipleHelper: V3EditMultipleHelperService,
        private processEditorDocTemplates: ProcessEditorDocTemplatesService,
        private windowListener: WindowListenerService,
        private dialog: DialogHelperService,
        private translocoService: TranslocoService
    ) {}

    @HostListener('document:keyup', ['$event'])
    onKeyUp(event) {
        const enterKeyPressed = event.key === 'Enter';
        const target = event.target as HTMLElement;

        if (!enterKeyPressed || this.viewContext.action !== 'create' || target.nodeName === 'TEXTAREA') {
            return;
        }

        this.saveActivity();
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static redrawCount = 0;
    redraw() {
        V3ActivitySidenavComponent.redrawCount += 1;
        // Console.log('V3ActivitySidenav view redrawed: ' + V3ActivitySidenavComponent.redrawCount + ' times');
    }

    ngOnInit(): void {
        this.setInfo();

        this.windowListener.size.pipe(takeUntil(this.onDestroy), debounceTime(500)).subscribe({
            next: ({ x }) => {
                this.screenWidth = x;
                this.cdr.detectChanges();
            },
        });

        combineLatest([
            this.viewContext.errorsInSidenav,
            this.viewContext.activity,
            this.viewContext.editing,
            this.viewContext.v3Permissions,
            this.sideNav.stackSize$,
            this.viewContext.workflow,
            this.viewContext.unknownUsers,
            this.viewContext.fullscreen,
            this.viewContext.editingFiles,
            this.viewContext.editingLocation,
        ])
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: () => {
                    if (this.viewContext.loadingInfo.value && this.editingMapLocation === !!this.viewContext.editingLocation.value) {
                        return;
                    }

                    // For some blasphemous reason without this, adding a map location won't trigger the Save & Cancel buttons to pop-up
                    this.editingMapLocation = !!this.viewContext.editingLocation.value;
                    this.cdr.detectChanges();
                },
            });

        this.viewContext.nextPhaseId.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.scrollTop(),
        });

        this.viewContext.currentTab.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.onScroll(),
        });

        this.viewContext.popSidenav.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.popSidenav(),
        });

        this.viewContext.highlightLinkedFromPhase
            .pipe(
                takeUntil(this.onDestroy),
                filter(phaseId => !!phaseId)
            )
            .subscribe({
                next: () => this.switchTab('linkedFrom'),
            });

        this.viewContext.loadingInfo
            .pipe(
                takeUntil(this.onDestroy),
                filter(state => state !== 'initial'),
                debounceTime(0),
                take(1)
            )
            .subscribe({
                next: () => {
                    this.ready.emit(true);
                    this.hideEditMultipleNote = this.viewContext.action !== 'editMultiple';

                    if (this.initTab && this.activeTab !== this.initTab) {
                        this.switchTab(this.initTab);
                    } else {
                        this.cdr.detectChanges();
                    }

                    if (this.activityTabs) {
                        this.activityTabs.animationDone.pipe(takeUntil(this.onDestroy)).subscribe({
                            next: () => {
                                this.viewContext.currentTab.next(this.activeTab);
                            },
                        });
                    }
                },
            });
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    selectTemplate(templateId: string) {
        const index = this.selectedTemplates.findIndex(id => id === templateId);
        if (index > -1) {
            this.selectedTemplates.splice(index, 1);
            return;
        }
        this.selectedTemplates.push(templateId);
    }

    selected(templateId: string) {
        return this.selectedTemplates.includes(templateId);
    }

    get activityTab() {
        const activityTabs = document.querySelectorAll('.activity-tab-background');
        return activityTabs[activityTabs.length - 1];
    }

    scrollTop() {
        this.activityTab?.scrollTo({ top: 0 });
    }

    onScroll() {
        const currentScrollPosition = this.activityTab?.scrollTop || 0;
        const phaseSelectorContainers = document.querySelectorAll('.phase-selector-container');
        const phaseSelectorContainer = phaseSelectorContainers[phaseSelectorContainers.length - 1];
        const scrollingDown = currentScrollPosition > this.previousScrollPosition;
        const scrollingUp = currentScrollPosition < this.previousScrollPosition;

        if (this.viewContext.action !== 'view' || this.isDataset) {
            return;
        }

        /* Phase selector is never sticky if scrolling position is 16px or less */
        if (currentScrollPosition <= 16) {
            phaseSelectorContainer?.classList.remove('phase-selector-sticky', 'slidein', 'slideout');
        }

        /* Set phase selector sticky immediately if scrolled down over 16px but less
        than 30px from top (can't be exact 16 because browser might not detect all points) */
        if (currentScrollPosition > 16 && currentScrollPosition < 30 && scrollingDown) {
            phaseSelectorContainer?.classList.remove('slidein');
            phaseSelectorContainer?.classList.add('phase-selector-sticky');
        }

        /* Selector shows up only after 20px treshold if scrolled up and then down again at the top part of the sidenav (prevents jumping) */
        if (currentScrollPosition < 250 && scrollingDown) {
            this.scrollingUpPosition = 0;
            this.scrollingDownPosition++;

            if (this.scrollingDownPosition > 20) {
                phaseSelectorContainer?.classList.add('phase-selector-sticky');
                this.scrollingDownPosition = 0;
            }
        }

        /* Phase selector should not be sticky ever if scrolled downwards
        and scrolling position is more than 250px from the top,
        and scrolled more than 20px (treshold for removing the stickyness) */
        if (currentScrollPosition >= 250 && scrollingDown) {
            this.scrollingUpPosition = 0;
            this.scrollingDownPosition++;

            if (this.scrollingDownPosition > 20) {
                phaseSelectorContainer?.classList.add('slideout');
                setTimeout(() => {
                    phaseSelectorContainer?.classList.remove('phase-selector-sticky', 'slideout');
                }, 370);
                this.scrollingDownPosition = 0;
            }
        }

        /* If scrolled upwards more than 20px phase selector slides in to be sticky (but not at the top part of the activity card) */
        if (currentScrollPosition >= 250 && scrollingUp) {
            this.scrollingDownPosition = 0;
            this.scrollingUpPosition++;

            if (this.scrollingUpPosition > 20) {
                phaseSelectorContainer?.classList.add('phase-selector-sticky', 'slidein');
                this.scrollingUpPosition = 0;
            }
        }

        /* If scrolled upwards more than 20px at the top part of the activity card,
        the phase selector stays sticky but does not slide in if it's already sticky */
        if (
            currentScrollPosition < 250 &&
            currentScrollPosition > 16 &&
            phaseSelectorContainer?.classList.contains('phase-selector-sticky') &&
            scrollingUp
        ) {
            this.scrollingDownPosition = 0;
            this.scrollingUpPosition++;

            if (this.scrollingUpPosition > 20) {
                phaseSelectorContainer?.classList.add('phase-selector-sticky');
                this.scrollingUpPosition = 0;
            }
        }

        /* If scrolled upwards at the top part of the activity card,
        and if the selector is not sticky yet, it gets sticky with sliding in */
        if (
            currentScrollPosition < 250 &&
            currentScrollPosition > 16 &&
            scrollingUp &&
            !phaseSelectorContainer?.classList.contains('phase-selector-sticky')
        ) {
            phaseSelectorContainer?.classList.add('phase-selector-sticky', 'slidein');
        }

        this.previousScrollPosition = currentScrollPosition;
    }

    async saveActivity() {
        if (this.disableSaveButton) {
            console.warn('Cannot save activity, errors in sidenav');
            return;
        }

        this.saving = true;
        this.cdr.detectChanges();

        switch (this.viewContext.action) {
            case 'create':
                await this.createActivity();
                break;
            case 'view':
                await this.updateActivity();
                await this.updateDocumentPreview();
                break;
            case 'editMultiple':
                await this.updateActivities();
                break;
            default:
                break;
        }

        this.saving = false;
        this.viewContext.editingFiles.next(false);
        this.viewContext.editingLocation.next(false);
        this.cdr.detectChanges();
    }

    clearView(options?: { reloadInfo?: boolean }) {
        switch (this.viewContext.action) {
            case 'view':
                if (!options?.reloadInfo) {
                    break;
                }

                this.viewContext.reloadSidenavInfo({ resetToSave: true });
                break;
            default:
                this.sideNav.pop();
                break;
        }
    }

    get documentTemplates(): DocumentTemplate[] {
        const templates = this.viewContext.workflow.value?.documentTemplates;

        const sortedDocumentTemplates = this.processEditorDocTemplates.sortTemplates(templates);
        return Object.values(sortedDocumentTemplates || {});
    }

    followActivity(): void {
        if (this.viewContext.action === 'create') {
            this.viewContext.joinCreated = !this.viewContext.joinCreated;

            if (!this.viewContext.toSave.options.followerIds) {
                this.viewContext.toSave.options.followerIds = [];
            }

            const currentUserId = this.viewContext.me._id;
            const currentUserIndex = this.viewContext.toSave.options.followerIds.findIndex(userId => currentUserId === userId);
            const currentUserFollowing = currentUserIndex !== -1;

            if (this.viewContext.joinCreated && !currentUserFollowing) {
                this.viewContext.toSave.options.followerIds.unshift(currentUserId);
            }

            if (!this.viewContext.joinCreated && currentUserFollowing) {
                this.viewContext.toSave.options.followerIds.splice(currentUserIndex, 1);
            }

            this.cdr.detectChanges();
            return;
        }

        const activityId = this.viewContext.activity.value?._id;
        if (!activityId) {
            return;
        }

        this.viewContext
            .followActivity(activityId)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    if (!this.meInDiscussion) {
                        this.switchTab('discussion');
                    }
                },
                error: (error: any) => console.error('followActivity error: ', error),
            });
    }

    async cancel() {
        await this.translate.translationLoaded('activity-sidenav');

        this.discardDialog()
            .pipe(
                takeUntil(this.onDestroy),
                take(1),
                filter(state => !!state)
            )
            .subscribe({
                next: () => {
                    if (this.viewContext.action !== 'view') {
                        return void this.sideNav.pop();
                    }

                    const activity = this.viewContext.activity.value;
                    if (activity) {
                        this.canceled.emit({ _id: activity._id, name: activity.name });
                    }
                    this.clearView({ reloadInfo: true });
                },
            });
    }

    discardDialog() {
        return this.viewContext.dialog.showConfirm(
            this.transloco.translate('activity-sidenav.discard-dialog.confirm'),
            this.transloco.translate('activity-sidenav.discard-dialog.content'),
            this.transloco.translate('activity-sidenav.discard-dialog.title')
        );
    }

    addFollowers() {
        const hiddenParticipants: string[] = this.viewContext.action === 'create' ? [] : this.viewContext.activity.value?.followers || [];

        const predefinedParticipants: string[] =
            (this.viewContext.action === 'create' ? this.viewContext.toSave.options.followerIds : []) || [];

        this.zone.run(() => {
            this.matDialog
                .open(UserInviteDialogComponent, {
                    height: '500px',
                    width: '500px',
                    data: {
                        hiddenParticipants,
                        predefinedParticipants,
                        allowGuests: this.viewContext.workflow.value?.allowGuests,
                        hideGuests: this.viewContext.action !== 'view',
                        discussionId: this.viewContext.activity.value?.discussion,
                        networkId: this.viewContext.workflow.value?.cid,
                    },
                })
                .afterClosed()
                .pipe(takeUntil(this.onDestroy))
                .subscribe({
                    next: async (followers: string[]) => {
                        if (!followers?.length) {
                            return;
                        }

                        if (this.viewContext.action === 'create') {
                            this.viewContext.joinCreated = followers.includes(this.viewContext.me._id);

                            this.viewContext.toSave.options.followerIds = followers;
                            this.cdr.detectChanges();
                            return;
                        }

                        try {
                            const followersMap = followers.reduce((map, userId) => {
                                map[userId] = true;
                                return map;
                            }, {});

                            let activities: {
                                _id: string;
                            }[] = [];

                            if (this.viewContext.action === 'editMultiple') {
                                activities = this.viewContext.toSave.activities;
                            } else if (this.viewContext.activity.value) {
                                activities = [{ _id: this.viewContext.activity.value._id }];
                            }

                            await this.viewContext.updateFollowers(activities, followersMap);
                        } catch (error) {
                            console.error('Failed to add followers', error);
                        }
                    },
                });
        });
    }

    async popSidenav(options?: { confirmDialog?: boolean }) {
        if (!this.viewContext.editing.value || !options?.confirmDialog) {
            return void this.sideNav.pop();
        }

        await this.translate.translationLoaded('activity-sidenav');

        this.discardDialog()
            .pipe(
                takeUntil(this.onDestroy),
                take(1),
                filter(state => !!state)
            )
            .subscribe({
                next: () => this.sideNav.pop(),
            });
    }

    async clearSidenav() {
        if (!this.viewContext.editing.value) {
            return void this.sideNav.clear();
        }

        await this.translate.translationLoaded('activity-sidenav');

        this.discardDialog()
            .pipe(
                takeUntil(this.onDestroy),
                take(1),
                filter(state => !!state)
            )
            .subscribe({
                next: () => this.sideNav.clear(),
            });
    }

    get network(): Company | undefined {
        const workspaceId = this.viewContext.activity.value?.cid;
        if (!workspaceId) {
            return undefined;
        }
        const network = this.viewContext.getNetwork(workspaceId);
        return network;
    }

    get workflow(): Process | null {
        return this.viewContext.workflow.value;
    }

    get processColor(): string {
        return this.viewContext.workflow.value?.color || '#405560';
    }

    backgroundImage(returnCircle: boolean): string {
        if (this.viewContext.error.value) {
            return this.defaultImage;
        }
        const defaultImage = returnCircle ? this.circleImage : this.defaultImage;

        const coverImage = this.viewContext.workflow.value?.coverImage;
        return coverImage ? `${environment.wsUrl}/image/lores/${coverImage}` : defaultImage;
    }

    readonly defaultImage = `/assets/img/pattern_fresh.svg`;

    readonly circleImage = `/assets/img/pattern_fresh_cropped.svg`;

    get disableSaveButton(): boolean {
        if (this.saving) {
            return true;
        }

        if (!this.viewContext.canBeEdited) {
            return true;
        }

        const errorsInSidenav = this.viewContext.errorsInSidenav.value;
        const filesUploading = errorsInSidenav.filesUploading;
        const invalidDetails = errorsInSidenav.invalidDetails;

        if (this.viewContext.action === 'editMultiple') {
            const invalidFields = !!Object.keys(errorsInSidenav.activityFieldErrors || {}).length;
            return filesUploading || invalidFields || !!invalidDetails;
        }

        const detailsChanging = errorsInSidenav.detailsChanging;
        const invalidOptions = errorsInSidenav.invalidOptions;
        const invalidMap = errorsInSidenav.invalidMap;

        return (
            detailsChanging ||
            invalidDetails ||
            invalidOptions ||
            invalidMap ||
            filesUploading ||
            (!this.viewContext.hasChanges && !this.viewContext.editingFiles.value && !this.viewContext.editingLocation.value) ||
            !!this.viewContext.loadingInfo.value
        );
    }

    get showDiscussionTab(): boolean {
        const inActivityDiscussionView = location.href.includes(`discussions/${this.viewContext.activity.value?.discussion}`);

        return !inActivityDiscussionView && !!this.viewContext.workflow.value?.enableMessenger && this.viewContext.action === 'view';
    }

    get showOptionsTab(): boolean {
        if (this.viewContext.action === 'editMultiple') {
            return true;
        }

        const isAdmin = this.viewContext.isWorkflowAdmin || this.viewContext.isWorkspaceAdmin;
        const isOwner = this.viewContext.isWorkspaceOwner;
        return this.viewContext.action !== 'create' && (isOwner || isAdmin);
    }

    get showFilesTab(): boolean {
        const workflow = this.viewContext.workflow.value;

        if (this.viewContext.action !== 'view') {
            return workflow?.enableAttachments || false;
        }

        return !!workflow?.enableAttachments || !!workflow?.enableMessenger;
    }

    get meInDiscussion(): boolean {
        return this.viewContext.activity.value?.followers?.includes(this.viewContext.me._id) || false;
    }

    get inDiscussion(): boolean {
        return this.meInDiscussion || (this.viewContext.action === 'create' && this.viewContext.joinCreated);
    }

    get showBackButton(): boolean {
        return this.sideNav.stackSize$.value > 1;
    }

    get activeTab(): ActivityTabTypes | undefined {
        if (!this.activityTabs) {
            return;
        }
        const selectedIndex = this.activityTabs.selectedIndex || 0;
        return this.activityTabs._tabs.get(selectedIndex)?.textLabel as ActivityTabTypes;
    }

    get tabGroupHeight(): string {
        const controlButtonsHeight = this.controlButtons?.nativeElement?.offsetHeight || 0;
        const topbarHeight = this.activityTopbar?.nativeElement?.offsetHeight || 0;
        const followersContainerHeight = this.followersContainer?.nativeElement?.offsetHeight || 0;
        const offset = controlButtonsHeight + topbarHeight + followersContainerHeight;

        return `calc(100% - ${offset}px)`;
    }

    get activityName(): string {
        const editedName = this.viewContext.toSave?.activity?.name;
        return editedName || this.viewContext.activity.value?.name || '';
    }

    get showFinndentButton(): boolean {
        if (!this.viewContext.workflow.value?._id || !this.viewContext.phaseId) {
            return false;
        }

        const finndentProcess = this.finndentProcessIds.includes(this.viewContext.workflow.value._id);
        const finndentPhase = this.finndentPhaseIds.includes(this.viewContext.phaseId);

        return finndentProcess && finndentPhase;
    }

    get followers(): string[] {
        if (this.viewContext.action === 'create') {
            return this.viewContext.toSave?.options?.followerIds || [];
        }

        return this.viewContext.activity.value?.followers || [];
    }

    get invalidOptions(): boolean {
        const errors = this.viewContext.errorsInSidenav.value;
        return errors.invalidOptions || !!Object.keys(errors.activityFieldErrors || {}).length;
    }

    get isDataset(): boolean {
        return this.viewContext.workflow.value?.enableUnlinkedMode || false;
    }

    get disableActivityCopyButton() {
        const phaseId =
            this.isDataset && this.viewContext.phaseId ? this.viewContext.phaseId : this.viewContext.workflow.value?.phasesOrder[0];
        const workflowId = this.viewContext.workflow.value?._id;
        if (!workflowId || !phaseId) {
            return true;
        }

        const canBeEdited = this.viewContext.canPhaseBeEdited(workflowId, phaseId);

        return !canBeEdited || this.viewContext.isWorkspaceGuest || this.viewContext.nextPhaseId.value;
    }

    async sendDataToFinndent() {
        this.isSendingData = true;
        this.cdr.detectChanges();

        try {
            const myId = this.viewContext.me._id;
            const myEmail = this.viewContext.me.email;
            const activity = this.viewContext.activity.value;
            if (!activity) {
                console.warn('Activity not defined!');
                return;
            }

            const userTeamsIds = this.viewContext.userTeams(myId).map(({ _id }) => _id);
            await this.finndent.sendData(activity._id, activity.name, myId, myEmail, userTeamsIds);
        } catch (error) {
            console.error('Failed to set data to Finndent!', error);
        }

        this.isSendingData = false;
        this.cdr.detectChanges();
    }

    openUser(userId: string) {
        this.sideNav.create(UserDetailComponent, { userId });
    }

    async downloadDocument(templateId?: string, savePdf?: boolean) {
        if (this.isGeneratingDocument) {
            return;
        }

        const activityId = this.viewContext.activity.value?._id;
        this.isGeneratingDocument = true;
        this.cdr.detectChanges();

        if (this.action === 'editMultiple' || this.selectedTemplates.length > 0) {
            this.selectedTemplates = this.selectedTemplates.length > 0 ? this.selectedTemplates : [templateId || '0'];
            const templateMap: { [templateId: string]: string[] } = {};
            const activityIds = this.activities?.map(activity => { return activity._id }) || [];
            for (const selectedTemplateId of this.selectedTemplates) {
                templateMap[selectedTemplateId] = activityIds.length > 0 ? activityIds : [activityId || ''];
            }
            await this.files.getDocumentGeneratedFile(undefined, undefined, undefined, templateMap);

            this.isGeneratingDocument = false;
            this.selectedTemplates = [];
            this.cdr.detectChanges();
            return;
        }

        if (!activityId) {
            console.warn('Activity id not defined');
            return;
        }

        try {
            await this.files.getDocumentGeneratedFile(activityId, templateId, savePdf);
        } catch (error) {
            console.error('Failed to download document template!', error);
        }

        this.isGeneratingDocument = false;
        this.cdr.detectChanges();
    }

    async toggleDocumentPreview(toggle: boolean): Promise<void> {
        this.isDocumentPreview = toggle;

        if (toggle) {
            await this.showDocumentPreview();
        }
    }

    async showDocumentPreview(name?: string): Promise<void> {
        if (!this.activityId) {
            return;
        }

        this.loadingPreviewDoc = true;

        const documentTemplates: DocumentTemplate[] = Object.values(this.viewContext.workflow.value?.documentTemplates || {});

        const docTemplate: DocumentTemplate | undefined = name ? documentTemplates.find(n => n.name === name) : documentTemplates[0];

        if (!docTemplate) {
            return;
        }

        const templateId = docTemplate.templateId;
        this.templateName = docTemplate.name;

        // Get preview data for template to show in html object
        const { filename, sourceUrl, applicationType, csvData } = await this.files.previewDocumentTemplate(
            this.activityId,
            templateId,
            undefined
        );

        this.filename = filename;
        this.sourceUrl = sourceUrl;
        this.applicationType = applicationType;
        this.csvData = csvData;

        this.loadingPreviewDoc = false;
        this.cdr.detectChanges();
    }

    async updateDocumentPreview(): Promise<void> {
        if (
            !this.isDocumentPreview ||
            !this.documentTemplates?.length ||
            !this.viewContext.fullscreen.value ||
            this.screenWidth <= this.documentPreviewMinWidth
        ) {
            return;
        }

        await this.showDocumentPreview(this.templateName);
    }

    private switchTab(tabLabel: ActivityTabTypes) {
        if (!this.activityTabs || this.activeTab === tabLabel) {
            return;
        }

        const tab = this.activityTabs._tabs.find(({ textLabel }) => textLabel === tabLabel);
        if (!tab) {
            return;
        }

        this.activityTabs.selectedIndex = tab.origin || tab.position;
        this.activityTabs.realignInkBar();
        this.cdr.detectChanges();
    }

    private async updateActivity() {
        const activity = this.viewContext.toSave.activity;
        const options = this.viewContext.toSave.options;
        const overrides = this.viewContext.fieldOverrides.value;
        const workflow = this.viewContext.workflow.value;
        const phaseId = this.viewContext.phaseId;

        if (overrides && Object.keys(overrides).length > 0) {
            for (const fieldId of Object.keys(overrides)) {
                if (fieldId === 'nameField') {
                    if (!activity.name) {
                        activity.name = overrides[fieldId];
                    }
                    continue;
                }
                if (!activity.fields || (phaseId && workflow?.phases[phaseId]?.isEndpoint)) {
                    continue;
                }
                if (activity.fields[fieldId]) {
                    continue;
                }
                activity.fields[fieldId] = overrides[fieldId];
            }
        }

        if (activity.fileIds?.length) {
            delete activity.fileIds;
        }

        if (options.followerIds?.length) {
            delete options.followerIds;
        }

        try {
            if (options.userId && activity?._id) {
                await this.viewContext.updateOwnerUser([activity._id], options.userId);
                delete options.userId;
            }

            await this.viewContext.update([activity], options);
            if (!this.viewContext.activity.value) {
                return;
            }

            if (this.viewContext.workflow.value?._id) {
                V3ActivityViewContextService.activityUpdated.next({
                    state: 'updated',
                    _id: this.viewContext.activity.value._id,
                    workflowId: this.viewContext.workflow.value._id,
                    phaseId: options.phaseId ? options.phaseId : this.viewContext.phaseId,
                });
            }

            this.updated.emit({ _id: this.viewContext.activity.value._id, name: this.viewContext.activity.value.name });
            this.clearView({ reloadInfo: true });
        } catch (error) {
            console.error('Failed to update an activity!', error);
            const currentErrors = this.viewContext.errorsInSidenav.value;
            currentErrors.duplicate = error.details?.db;
            this.viewContext.errorsInSidenav.next(currentErrors);
        }
    }

    private async updateActivities() {
        const activities = this.viewContext.toSave.activities;
        const options = this.viewContext.toSave.options;

        if (options.followerIds?.length) {
            delete options.followerIds;
        }

        try {
            if (options.userId) {
                const activityIds = activities.map(({ _id }) => _id);
                await this.viewContext.updateOwnerUser(activityIds, options.userId);
                delete options.userId;
            }

            await this.viewContext.update(activities, options);
            this.v3EditMultipleHelper.resetSelectedActivities([]);
            this.updatedMultiple.emit(activities);
            this.clearView();
        } catch (error) {
            console.error('Failed to update activities!', error);
            if (!error) {
                return;
            }

            const activityFieldErrors: { [activityId: string]: string[] } = {};

            for (const detail in error.details) {
                if (!detail) {
                    continue;
                }

                const splittedDetail = detail.split('.');
                const errorType = splittedDetail[0];
                const activityIndex = splittedDetail[1];
                const fieldId = splittedDetail[2];

                if (errorType !== 'fields' || activityIndex === undefined || !fieldId) {
                    continue;
                }

                const activity = activities[activityIndex];

                if (!activity) {
                    continue;
                }

                if (!activityFieldErrors[activity._id]) {
                    activityFieldErrors[activity._id] = [];
                }

                activityFieldErrors?.[activity._id]?.push(fieldId);
            }

            if (!Object.keys(activityFieldErrors).length) {
                return;
            }

            this.switchTab('options');

            const currentErrors = this.viewContext.errorsInSidenav.value;
            currentErrors.activityFieldErrors = activityFieldErrors;
            this.viewContext.errorsInSidenav.next(currentErrors);
        }
    }

    private async createActivity() {
        const overrides = this.viewContext.fieldOverrides.value;
        const activity = this.viewContext.toSave.activity;
        const options = this.viewContext.toSave.options;
        const workflowId = this.viewContext.workflow.value?._id;

        // This should probably not need to be here, as the followerIds should already be propely populated
        if (this.viewContext.joinCreated && !options.followerIds?.includes(this.viewContext.me._id) && this.viewContext.workflow.value?.enableMessenger) {
            options.followerIds = options.followerIds || [];
            options.followerIds?.push(this.viewContext.me._id);
        }
        if (!workflowId) {
            return;
        }

        if (!activity.fields) {
            activity.fields = {};
        }
        if (overrides && Object.keys(overrides).length > 0) {
            for (const fieldId of Object.keys(overrides)) {
                if (fieldId === 'nameField') {
                    if (!activity.name) {
                        activity.name = overrides[fieldId];
                    }
                    continue;
                }
                if (activity.fields[fieldId]) {
                    continue;
                }
                activity.fields[fieldId] = overrides[fieldId];
            }
        }

        if (options.files) {
            delete options.files;
        }

        try {
            const createdActivity = await this.viewContext.create(workflowId, activity, options);
            V3ActivityViewContextService.activityUpdated.next({
                state: 'created',
                _id: createdActivity._id,
                workflowId: createdActivity.process,
                phaseId: createdActivity.currentPhase,
            });

            this.created.emit({ _id: createdActivity._id, name: createdActivity.name });

            if (this.createdFromDiscussions) {
                this.viewContext.closeDiscussionCreate();
            }

            if (this.createdFromEvents) {
                this.viewContext.closeEventCreate();
            }

            this.activityId = createdActivity._id;
            this.processId = createdActivity.process;

            this.action = 'view';
            this.setInfo();
        } catch (error) {
            console.error('Failed to create an activity!', error);
            const confirm = 'OK';
            const content = `${this.translocoService.translate('misc.services.core.dialog-error.backend-response')} ${error.msg}`;
            const title = this.translocoService.translate('misc.services.core.dialog-error.creation-failed');

            this.dialog.showError(confirm, content, title);
            const currentErrors = this.viewContext.errorsInSidenav.value;
            currentErrors.duplicate = error.details?.db;
            this.viewContext.errorsInSidenav.next(currentErrors);
        }
    }

    private setInfo() {
        switch (this.action) {
            case 'editMultiple':
                const activities = this.activities?.map(activity => ({ _id: activity._id, name: activity.name })) || [];
                const editMultipleArgs: SetEditMultipleArguments = {
                    action: 'editMultiple',
                    workflowId: this.processId,
                    activities,
                    initFieldValues: this.initFieldValues,
                    phaseId: this.phaseId,
                };

                this.viewContext.initializeSidenavInfo(editMultipleArgs);
                break;
            case 'view':
                const setViewInfoArguments: SetViewArguments = {
                    action: 'view',
                    workflowId: this.processId,
                    activityId: this.activityId,
                    initFieldValues: this.initFieldValues,
                    editing: this.editing,
                    nextPhaseId: this.nextPhaseId,
                };

                this.viewContext.initializeSidenavInfo(setViewInfoArguments);
                break;
            default:
                const setCreateInfoArgs: SetCreateArguments = {
                    action: 'create',
                    workflowId: this.processId,
                    phaseId: this.phaseId,
                    discussionInfo: this.discussionInfo,
                    initFieldValues: this.initFieldValues,
                };

                this.viewContext.initializeSidenavInfo(setCreateInfoArgs);
                break;
        }
    }
}
