/** @format */

import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, combineLatest, merge } from 'rxjs';
import { debounceTime, filter, map, pluck, takeUntil } from 'rxjs/operators';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { Tag } from 'app/_models/tag.model';
import { PersonalSettings } from '@app/models';
import { CoreService } from 'app/_services/core.service';
import { TagSelectorOptions } from 'app/_models/tag-selector-options.model';
import { TagService } from 'app/_services/tag.service';

@Component({
    selector: 'app-tag-selector',
    templateUrl: './tag-selector.component.html',
    styleUrls: ['./tag-selector.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class TagSelectorComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('globalTagComplete', { static: false }) globalTagComplete: MatAutocomplete;
    @ViewChild('addTagInput', { static: false }) addTagInput: ElementRef<HTMLInputElement>;

    @Input() selectorOptions: BehaviorSubject<TagSelectorOptions>;
    @Input() focusSelector: Subject<void>;
    // eslint-disable-next-line
    @Output() error = new EventEmitter<boolean>();
    @Output() tagged = new EventEmitter<string>();
    @Output() untagged = new EventEmitter<string>();

    networkId: string;

    me: BehaviorSubject<PersonalSettings>;
    disableGlobalTags = new BehaviorSubject<boolean>(false);

    tagSearchControl = new UntypedFormControl('');

    enableNewTags = false;
    globalTagsLabel = this.translocoService.translate('shared.tag-selector.global_tags_label');
    errorMessage: string;
    selectableTags: Observable<string[]>;

    networkTags = new BehaviorSubject<Tag[]>([]);
    selectedTags = new BehaviorSubject<string[]>([]);

    private onDestroy = new Subject<void>();
    private tagPool: Observable<string[]>;

    constructor(
        private core: CoreService,
        private tags: TagService,
        private cdr: ChangeDetectorRef,
        private translocoService: TranslocoService
    ) {
        this.me = this.core.user;
    }

    ngOnInit() {
        this.selectorOptions
            .pipe(
                takeUntil(this.onDestroy),
                filter(it => !!it)
            )
            .subscribe({
                next: data => this.initSelectorData(data),
            });

        this.tags.networkTags.pipe(takeUntil(this.onDestroy)).subscribe({
            next: networkTags => {
                this.networkTags.next(networkTags);

                if (this.selectedTags && this.selectedTags.value) {
                    const selectedTags = this.selectedTags.value;
                    this.selectedTags.next(this.sortTags(selectedTags));
                }
            },
        });
    }

    ngAfterViewInit() {
        merge(this.selectedTags, this.selectorOptions)
            .pipe(debounceTime(50), takeUntil(this.onDestroy))
            .subscribe({
                next: () => {
                    if (this.selectorOptions && this.selectorOptions.value) {
                        if (this.selectorOptions.value.required) {
                            const selectedTagsLength = this.selectedTags.value ? this.selectedTags.value.length : 0;
                            if (selectedTagsLength < 1) {
                                this.error.emit(true);
                                this.errorMessage = 'tag is required';
                            } else {
                                this.error.emit(false);
                                this.errorMessage = '';
                            }
                        } else {
                            this.error.emit(false);
                            this.errorMessage = '';
                        }
                    } else {
                        this.error.emit(false);
                        this.errorMessage = '';
                    }
                },
            });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    untag(tagId: string) {
        if (tagId) {
            this.removeFromSelected(tagId);
        } else if (!tagId) {
            console.warn('untagTag error: No tag id');
        }
    }

    addTag(tag: { newTag: boolean; value: string }) {
        if (tag) {
            // Check if you can add multiple tags
            if (this.allowMultiple()) {
                // Check if this is a new tag
                if (tag.newTag) {
                    this.tags.create(this.selectorOptions.value.cid, tag.value).subscribe({
                        next: (tagId: string) => this.addSelectedTag(tagId),
                        error: error => console.error('error happened while creating a new tag', error),
                    });
                } else {
                    this.addSelectedTag(tag.value);
                }
            } else if (this.selectedTags.value.length > 1) {
                this.tagSearchControl.setErrors({ multipleTags: true });
                this.errorMessage = 'Too many tags';
            } else {
                // Replace previous tag with new one
                this.selectedTags.value.forEach(selectedTag => {
                    this.untag(selectedTag);
                });
                if (tag.newTag) {
                    this.tags.create(this.selectorOptions.value.cid, tag.value).subscribe({
                        next: (tagId: string) => this.addSelectedTag(tagId),
                        error: error => console.error('error happened while creating a new tag', error),
                    });
                } else {
                    this.addSelectedTag(tag.value);
                }
            }
        } else {
            console.warn('addTag error');
        }
    }

    getTag(tagId: string): Tag {
        return this.networkTags.value.find(({ _id }) => _id === tagId);
    }

    checkForDublicateTags(tagName: string) {
        if (typeof tagName === 'string') {
            return !!this.selectedTags.value.find(tagId => {
                const findTag = this.getTag(tagId);
                return findTag ? findTag.value.toLocaleLowerCase().trim() === tagName.toLocaleLowerCase().trim() : false;
            });
        }
        return false;
    }

    get hasCreateNewPermission(): boolean {
        return (
            this.selectorOptions.value &&
            this.selectorOptions.value.allowNewTag &&
            this.tagSearchControl.value &&
            !this.checkForDublicateTags(this.tagSearchControl.value)
        );
    }

    allowMultiple() {
        if (this.selectorOptions.value) {
            if (this.selectorOptions.value.multiple) {
                return true;
            }
            return this.selectedTags.value ? this.selectedTags.value.length < 1 : true;
        }
        return false;
    }

    allowTagRemoving(): boolean {
        const selectedTagsLength = this.selectedTags && this.selectedTags.value ? this.selectedTags.value.length : 0;
        if (
            this.selectorOptions &&
            this.selectorOptions.value &&
            this.selectorOptions.value.required &&
            !this.selectorOptions.value.multiple
        ) {
            if (selectedTagsLength > 1) {
                return true;
            }
            return false;
        }
        return true;
    }

    private initSelectorData(options: TagSelectorOptions) {
        this.tagPool = options.tagPool
            ? this.selectorOptions.pipe(pluck('tagPool'))
            : this.networkTags.pipe(map(tags => tags.map(({ _id }) => _id)));
        this.selectedTags.next(options.initialTags ? this.sortTags(options.initialTags) : []);
        this.selectableTags = combineLatest([this.tagPool, this.tagSearchControl.valueChanges]).pipe(
            // Preform a search if we a valid query
            debounceTime(50),
            map(([tags, query]) => {
                if (typeof query === 'string') {
                    return tags.filter(id => this.getTag(id).value.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
                }
                return tags;
            }),
            // Filter out all selected tags and sort alphabetically
            map(tags =>
                tags
                    .filter(id => !this.selectedTags.value.includes(id))
                    .sort((a: string, b: string) => {
                        const tagA = this.getTag(a).value.toLocaleLowerCase();
                        const tagB = this.getTag(b).value.toLocaleLowerCase();

                        return tagA.localeCompare(tagB);
                    })
            )
        );

        if (this.focusSelector) {
            this.focusSelector.pipe(debounceTime(50), takeUntil(this.onDestroy)).subscribe({
                next: () => {
                    this.addTagInput.nativeElement.focus();
                },
            });
        }
    }

    private resetAddTagField() {
        this.addTagInput.nativeElement.value = '';
        this.tagSearchControl.setValue('');
    }

    private removeFromSelected(tagId: string) {
        const selected = this.selectedTags.value;
        const index = selected.indexOf(tagId);
        selected.splice(index, 1);
        this.selectedTags.next(this.sortTags(selected));
        this.untagged.emit(tagId);
    }

    private sortTags(tagIds: string[]): string[] {
        const tags: Tag[] = [];

        if (tagIds && tagIds.length > 0) {
            tagIds.forEach(tagId => {
                const tag = this.tags.get(tagId);

                if (tag && tag._id) {
                    tags.push(tag);
                } else if (tagId) {
                    // This is temporary tag placeholder
                    tags.push({ _id: tagId, value: '' });
                }
            });

            tags.sort((t1, t2) => t1.value.toLocaleLowerCase().localeCompare(t2.value.toLocaleLowerCase()));

            return tags.map(({ _id }) => _id);
        }
        return [];
    }

    private addSelectedTag(tagId: string) {
        const selected = this.selectedTags.value;
        selected.push(tagId);
        this.selectedTags.next(this.sortTags(selected));
        this.tagged.emit(tagId);
        this.resetAddTagField();
        this.cdr.detectChanges();
    }
}
