<mat-icon matBadge="{{badgeNumber}}" matBadgeColor="warn" *ngIf="iconName">{{iconName}}</mat-icon>

<!-- updated one that we should be using from now on -->
<!-- interactive icon -->
<mat-icon class="icon-with-badge" *ngIf="!iconName && !indicatorIcon" matBadge="{{badgeNumber}}" [matBadgeColor]="!badgeColor ? 'warn' : badgeColor"
  svgIcon="{{hailerIconName}}" ngClass="{{iconColor}}"></mat-icon>

<!-- indicator icon -->
<div class="indicator-icon icon-with-badge" *ngIf="indicatorIcon && !iconName">
  <mat-icon matBadge="{{badgeNumber}}" [matBadgeColor]="!badgeColor ? 'warn' : badgeColor" svgIcon="{{hailerIconName}}"
    ngClass="{{iconColor}}"></mat-icon>
  <span class="cdk-visually-hidden">{{badgeDescription}}</span>
</div>
