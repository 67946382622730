<!-- @format -->

{{ redraw() }}
<div class="note-container" *ngIf="!viewContext.canBeEdited && !(viewContext.loadingInfo | async)">
	<div class="note-title-container flex-row--space-between">
		<p class="note-title">{{ 'activity-sidenav.detail.note-title' | transloco }}</p>
	</div>
	<p class="note-content">{{ 'activity-sidenav.detail.no_permission_text' | transloco }}</p>
</div>

<div
	[ngClass]="(viewContext.nextPhaseId | async) && !isDataset ? 'moving-phases-background' : 'phase-selector-container'"
	*ngIf="!viewContext.overviewPeek && showPhaseName">
	<p class="phase-label">
		{{
			(viewContext.workflow | async)?.enableUnlinkedMode
				? ('activity-sidenav.detail.category' | transloco)
				: ('activity-sidenav.detail.phase' | transloco)
		}}
	</p>
	<ng-container *ngIf="allowMovingActivity; else phaseName">
		<app-dropdown-selector
			data-cy="phase-selector-dropdown"
			*ngIf="(phaseSelectorItems | async) && hasPhaseSelectorItems"
			[options]="phaseSelectorOptions"
			[items]="phaseSelectorItems"
			[selectedItems]="phaseSelectorSelectedItems"></app-dropdown-selector>

		<!-- Next phase activity fields -->
		<ng-container *ngIf="!isDataset && !!(viewContext.nextPhaseId | async) && !!nextPhaseTemplate?.fields">
			<ng-container *ngFor="let field of nextPhaseTemplate?.fields; trackBy: trackById">
				<div
					*ngIf="showField(field)"
					class="activity-field"
					[class.border-bottom]="!(viewContext.editing | async) && !nextFieldIsSubheader(field.id)"
					[class]="field?.subtype + '-field'">
					<ng-container [ngTemplateOutlet]="activityField" [ngTemplateOutletContext]="{ field, isNextPhaseField: true }"></ng-container>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>

	<ng-template #phaseName>
		<p class="two-line-ellipsis phase-name" *ngIf="(viewContext.workflow | async) && viewContext.phaseId">
			{{ viewContext.getPhaseName(viewContext.workflow.value?._id!, viewContext.phaseId) }}
		</p>
	</ng-template>
</div>

<div data-cy="activity-detail-fields-container" class="activity-field-container" [class.container-top-margin]="!showPhaseName">
	<!-- Name field -->
	<div
		class="activity-field"
		[class.border-bottom]="!(viewContext.editing | async) && !nextFieldIsSubheader('nameField')"
		*ngIf="nameField">
		<ng-container [ngTemplateOutlet]="activityField" [ngTemplateOutletContext]="{ field: nameField }"></ng-container>
	</div>

	<!-- Owner team field -->
	<div *ngIf="ownerTeamField" class="activity-field" [class.border-bottom]="!(viewContext.editing | async)">
		<ng-container [ngTemplateOutlet]="activityField" [ngTemplateOutletContext]="{ field: ownerTeamField.field }"></ng-container>
	</div>

	<!-- Activity fields -->
	<ng-container *ngFor="let field of template?.fields; trackBy: trackById">
		<div
			data-cy="activity-field-container"
			*ngIf="showField(field)"
			[style.display]="isFieldCollapsed(field) ? 'none' : ''"
			class="activity-field"
			[class.border-bottom]="!(viewContext.editing | async) && !nextFieldIsSubheader(field.id)"
			[class]="field?.subtype + '-field'">
			<ng-container [ngTemplateOutlet]="activityField" [ngTemplateOutletContext]="{ field }"></ng-container>
		</div>
	</ng-container>
</div>

<ng-template #activityField let-field="field" let-isNextPhaseField="isNextPhaseField">
	<!-- Field header -->
	<div *ngIf="!viewContext.isStaticField(field?.subtype)" class="field-label-container">
		<p
            data-cy="activity-sidenav-field-label"
            class="field-label two-line-ellipsis"
            [class.error-field]="field.modifier?.file && field.required && (viewContext.editing | async) && fieldErrorMap[field.id]"
            [class.activitylink-field-label]="!(viewContext.editing | async)">{{ field.label }}</p>

		<ng-container *ngIf="field.description">
			<mat-icon
                data-cy="activity-sidenav-field-description-icon"
				svgIcon="hailer-tooltip"
				[tippy]="field.description"
				class="tooltip-icon-small"
				[satPopoverAnchor]="descriptionPopover"
				(click)="descriptionPopover.toggle(); $event.stopPropagation()"></mat-icon>
			<sat-popover hasBackdrop #descriptionPopover verticalAlign="below">
				<div data-cy="activity-sidenav-field-description-text" class="popover-container mat-elevation-z8" [innerHTML]="field.description | urlify"></div>
			</sat-popover>
		</ng-container>
	</div>

	<div data-cy="field-wrapper" class="field-wrapper flex-align-center">
		<!-- Edit field -->
        <ng-container *ngIf="editField(field); else fieldView" [ngSwitch]="field.id">
            <div class="fill-field-container activity-input-field" *ngSwitchCase="'ownerTeam'">
                <app-dropdown-selector *ngIf="ownerTeamField; else fieldView" data-cy="activity-owner-team-dropdown"
                    [class.function-field-width]="field.functionEnabled" [items]="ownerTeamField.items"
                    [options]="ownerTeamField.options" [selectedItems]="ownerTeamField.selectedItems">
                </app-dropdown-selector>
            </div>
            <app-v3-activity-input-field *ngSwitchDefault [class.function-field-width]="field.functionEnabled" class="activity-input-field"
                [field]="field" [isNextPhaseField]="isNextPhaseField" (errors)="onErrors(field.id, $event)"
                (valueChange)="fieldValueChange(field.id, $event)">
            </app-v3-activity-input-field>
        </ng-container>

		<ng-template #fieldView>
			<div
				(click)="
					!viewContext.canBeEdited || viewContext.isStaticField(field?.subtype) || viewContext.overviewPeek
						? null
						: viewContext.enableEditMode(field._id)
				"
				[class.function-field-width]="field.functionEnabled"
				class="field-container"
				[class]="field?.subtype + '-field'">
				<app-v3-activity-field *ngIf="!showStaticError(field) && !(viewContext.loadingInfo | async)" [field]="field">
				</app-v3-activity-field>
				<span class="error-field" *ngIf="showStaticError(field)">{{ 'activity-sidenav.field.required_text' | transloco }}</span>
			</div>
		</ng-template>

		<!-- Function field button -->
		<span
			data-cy="edit-function-button"
			*ngIf="field.functionEnabled && viewContext.action !== 'editMultiple'"
			class="function-edit-button"
			[class.view-mode]="!editField(field)"
			[ngClass]="canEditFunction ? 'admin' : 'user'"
			(click)="openFunctionFieldEditor(field.id); $event.stopPropagation()"
			[tippy]="
				canEditFunction
					? ('activity-sidenav.field.edit_function_tooltip' | transloco)
					: ('activity-sidenav.field.function_field_tooltip' | transloco)
			">
			ƒ
		</span>
	</div>
</ng-template>
