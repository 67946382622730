/** @format */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { filter, take } from 'rxjs';

import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { TranslateService } from 'app/_services/translate.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-language-drag-and-drop',
    templateUrl: './language-drag-and-drop.component.html',
    styleUrls: ['./language-drag-and-drop.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class LanguageDragAndDropComponent {
    @Input() allLanguages: string[];
    @Input() preferredLanguages: string[];
    @Output() setPreferredLanguages = new EventEmitter<string[]>();
    @Output() reloadTranslations = new EventEmitter<void>();

    constructor(
        private router: Router,
        private dialogHelperService: DialogHelperService,
        private translocoService: TranslocoService,
        private translateService: TranslateService
    ) {}

    drop(event: CdkDragDrop<string[]>) {
        const value = [...this.preferredLanguages];
        moveItemInArray(value, event.previousIndex, event.currentIndex);
        this.setPreferredLanguages.emit(value);
        this.reload();
    }

    async reload() {
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
        });

        await this.translateService.reloadTranslations();
        this.reloadTranslations.emit();
    }

    addLanguage(language: string) {
        if (!this.preferredLanguages || this.preferredLanguages.includes(language) || language.length === 0) {
            return;
        }
        this.preferredLanguages = this.preferredLanguages.concat(language);
        this.setPreferredLanguages.emit(this.preferredLanguages);
        this.reload();
    }

    removeLanguage(languageIndex: number) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.preferredLanguages = this.preferredLanguages.filter((_element, index) => index !== languageIndex);
        this.setPreferredLanguages.emit(this.preferredLanguages);
        this.reload();
    }

    deleteLanguage(languageindex: number) {
        const confirm = this.translocoService.translate('shared.language-drag-and-drop.confirm_delete');
        const content = this.translocoService.translate('shared.language-drag-and-drop.content_delete');
        const title = this.translocoService.translate('shared.language-drag-and-drop.title_delete');

        this.dialogHelperService
            .showConfirm(confirm, content, title)
            .pipe(
                filter(confirmed => !!confirmed),
                take(1)
            )
            .subscribe({
                next: () => {
                    this.removeLanguage(languageindex);
                },
            });
    }
}
