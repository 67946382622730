
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { DropdownItems, DropdownOptions, DropdownSelectedItems } from 'app/_models/dropdown-selector.model';
import { TeamUserSelectorOptions } from 'app/_models/teamUserSelectorOptions.model';
import { CoreService } from 'app/_services/core.service';
import { TranslateService } from 'app/_services/translate.service';
import { stripUndefined } from 'app/_helpers/util';


@Component({
    selector: 'app-feed-post-visibility-dialog',
    templateUrl: './feed-post-visibility-dialog.component.html',
    styleUrls: ['./feed-post-visibility-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: TRANSLOCO_SCOPE, useValue: { scope: 'feed', alias: 'feed' } },
        { provide: TRANSLOCO_SCOPE, useValue: { scope: 'shared', alias: 'shared' } },
    ],
})
export class FeedPostVisibilityDialogComponent implements OnInit, OnDestroy {
    workspaceSelectorOptions = new BehaviorSubject<DropdownOptions>(new DropdownOptions());
    workspaceSelectorSelectedItems = new BehaviorSubject<DropdownSelectedItems>({});
    workspaceSelectorItems = new BehaviorSubject<DropdownItems>({});

    userSelectorOptions = new BehaviorSubject<TeamUserSelectorOptions>(new TeamUserSelectorOptions());
    userSelectorPredefinedItems = new BehaviorSubject<string[]>([]);
    userSelectorHideItems = new BehaviorSubject<string[]>([]);
    resetRecipientSelector = new Subject<void>();

    selectedWorkspaceId: string | undefined;
    selectedRecipients: string[] = [];

    recipientSelectorReady = false;

    private onDestroy = new Subject<void>();

    constructor(
        private dialogRef: MatDialogRef<FeedPostVisibilityDialogComponent>,
        private core: CoreService,
        private translate: TranslateService,
        private transloco: TranslocoService,
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
        this.selectedRecipients = this.data.recipients || [];

        this.workspaceSelectorSelectedItems.pipe(takeUntil(this.onDestroy)).subscribe({
            next: selectedItems => {
                this.workspaceChange(selectedItems);
            },
        });

        void this.setUserSelector({ predefinedItems: this.data?.recipients });
        void this.setWorkspaceSelector();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    close(): void {
        if (!this.dialogRef) {
            return;
        }
        this.dialogRef.close();
    }

    confirm(): void {
        if (!this.dialogRef) {
            return;
        }

        this.dialogRef.close(stripUndefined({ recipients: this.selectedRecipients, workspaceId: this.workspaceId || undefined }));
    }

    recipientChange(selectedItems: DropdownSelectedItems): void {
        let recipients: string[] = [];
        if (!selectedItems) {
            return;
        }

        const allowedKeys = new Set(['user', 'team', 'group']);
        for (const key in selectedItems) {
            if (!allowedKeys.has(key)) {
                continue;
            }

            const recipientIds = selectedItems[key];
            const recipientPermissionStrings = recipientIds.map(_id => `${key}_${_id}`);
            recipients = recipients.concat(recipientPermissionStrings);
        }

        this.selectedRecipients = recipients;
    }

    workspaceChange(selectedItems: DropdownSelectedItems): void {
        const workspaceId = selectedItems.workspace?.[0];
        if (!selectedItems?.workspace?.length || workspaceId === this.workspaceId) {
            return;
        }

        this.selectedWorkspaceId = workspaceId;
        this.resetRecipientSelector.next();
        void this.setUserSelector();
    }

    get workspaceId(): string {
        return this.selectedWorkspaceId || this.data.workspaceId || this.core.network.value?._id;
    }

    private async setUserSelector(options?: { predefinedItems?: string[] }): Promise<void> {
        if (!this.core.user.value || !this.workspaceId) {
            return;
        }

        await this.translate.translationLoaded('feed');
        const workspaceId = this.core.network.value._id;
        const userId = this.core.user.value._id;
        const [error, feedAdmin] = this.core.permission.feed.isAdmin(workspaceId);
        this.userSelectorHideItems.next([this.core.user.value.id]);
        const currentOptions = this.userSelectorOptions.value;
        currentOptions.appearance = 'outline';
        currentOptions.multiple = true;
        currentOptions.placeholder = this.transloco.translate('feed.feed_create.placeholder-everyone');
        currentOptions.required = false;
        currentOptions.showTeams = feedAdmin ? 'all' : userId;
        currentOptions.showUsers = this.workspaceId;
        currentOptions.showGroups = this.workspaceId;
        currentOptions.selectLastItem = false;
        currentOptions.workspaceId = this.workspaceId;
        this.userSelectorOptions.next(currentOptions);

        const recipients = options?.predefinedItems || this.data?.post?.recipients;
        if (!recipients?.length) {
            this.recipientSelectorReady = true;
            this.cdr.detectChanges();
            return;
        }

        const selectedItems: string[] = [];
        for (const recipientId of recipients) {
            const split = recipientId.split('_');
            selectedItems.push(split[1]);
        }
        this.userSelectorPredefinedItems.next(selectedItems);
        this.recipientSelectorReady = true;
        this.cdr.detectChanges();
    }

    private async setWorkspaceSelector(): Promise<void> {
        if (this.data?.post) {
            // Workspace selector is only used for new posts
            return;
        }

        await this.translate.translationLoaded('feed');

        const currentOptions = this.workspaceSelectorOptions.value;
        currentOptions.appearance = 'outline';
        currentOptions.multiple = false;
        currentOptions.placeholder = this.transloco.translate('feed.select-workspace');
        currentOptions.required = true;
        currentOptions.hideClearButton = true;
        this.workspaceSelectorOptions.next(currentOptions);

        const currentItems = this.workspaceSelectorItems.value;
        for (const workspaceId in this.core.networks.value) {
            if (!workspaceId) {
                continue;
            }

            const workspace = this.core.networks.value[workspaceId];
            if (!workspace) {
                continue;
            }

            currentItems[workspaceId] = {
                _id: workspaceId,
                type: 'workspace',
                title: workspace.name,
                meta: { color: workspace.settings.color },
            };
        }

        this.workspaceSelectorItems.next(currentItems);

        const selectedItems = this.workspaceSelectorSelectedItems.value;
        if (selectedItems.workspace?.length || !this.workspaceId) {
            return;
        }

        selectedItems.workspace = [this.workspaceId];
        this.workspaceSelectorSelectedItems.next(selectedItems);
    }
}