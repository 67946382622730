/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { environment } from '@app/env';
import { TeamService } from 'app/_services/team.service';
import { PeopleService } from 'app/people/people.service';
import { ProcessField } from '@app/models';
import { SideNavService } from 'app/_services/side-nav.service';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';

@Component({
    selector: 'app-activity-data-field',
    templateUrl: './activity-data-field.component.html',
    styleUrls: ['./activity-data-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class ActivityDataFieldComponent implements OnInit {
    @Input() field: ProcessField;
    @Input() data: any;
    @Input() discussionMode: string;
    @HostBinding('class.max-width') maxWidth = false;

    apiUrl = environment.wsUrl;
    teamName: string;

    constructor(
        private peopleService: PeopleService,
        private teamService: TeamService,
        private sideNavService: SideNavService,
        private cdr: ChangeDetectorRef,
        private translocoService: TranslocoService
    ) {}

    ngOnInit() {
        if (this.field.type === 'textarea') {
            this.maxWidth = true;
        }
        if (this.field.type === 'activitylink' && !this.data.value) {
            console.warn('activitylink data field with no value property?');
        }
        if (this.field.type === 'teams') {
            this.setTeamName(this.data.value);
        }
    }

    getUser(userId: string) {
        const user = this.peopleService.getUser(userId);
        return user;
    }

    get flagClass(): string {
        if (this.data.value && this.data.value.code) {
            return `fi-${this.data.value.code.toLowerCase()}`;
        }
        return ``;
    }

    async setTeamName(teamId: string) {
        if (!teamId) {
            return '-';
        }
        try {
            this.teamName = (await this.teamService.loadTeam(teamId))?.name || 'n/a';
        } catch (error) {
            console.log('Error loading team: ', error);
        }
        this.cdr.detectChanges();
    }

    openUserSidenav(userId: string): void {
        this.sideNavService.create(UserDetailComponent, {
            userId,
        });
    }

    getFieldFileCount(value: string): number {
        return JSON.parse(value)?.length || 0;
    }

}
