/** @format */

import { RouterModule, Routes } from '@angular/router';
import { AppManagementComponent } from './app-management/app-management.component';
import { AppPageComponent } from './app-page/app-page.component';
import { NgModule } from '@angular/core';

const routes: Routes = [
    {
        path: '',
        component: AppManagementComponent,
    },
    {
        path: ':appId',
        component: AppPageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
