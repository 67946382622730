/** @format */

import { Pipe, PipeTransform } from '@angular/core';

import { CoreService } from 'app/_services/core.service';

@Pipe({
    name: 'alphabeticalSort',
})
export class AlphabeticalSortPipe implements PipeTransform {
    constructor(private core: CoreService) {}

    transform(array: any, field: string, currentUserFirst?: boolean): any[] {
        if (!Array.isArray(array)) {
            return;
        }

        const splitProperties = (obj: any): any => field.split('.').reduce((p, c) => (p && p[c]) || null, obj);

        array.sort((a: any, b: any) => {
            if (splitProperties(a) < splitProperties(b)) {
                return -1;
            } else if (splitProperties(a) > splitProperties(b)) {
                return 1;
            }
            return 0;
        });

        if (currentUserFirst) {
            const me = this.core.user.value._id;
            array.forEach((user, index) => {
                if (user.key === me) {
                    const userObject = user;
                    array.splice(index, 1);
                    array.unshift(userObject);
                }
            });
        }

        return array;
    }
}
