/* eslint-disable no-case-declarations */
/** @format */
// eslint-disable-file @typescript-eslint/naming-convention
/* eslint @typescript-eslint/naming-convention: 0 */

import { Pipe, PipeTransform } from '@angular/core';
import * as DOMPurify from 'dompurify';
import { marked } from 'marked';
import hljs from 'highlight.js';
import { LinkData } from '../../../../test/deps/hailer-api/shared/link-types';
import { UrlifyPipe } from './urlify.pipe';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '../../../../projects/insight/src/environments/environment';
import { CoreService } from 'app/_services/core.service';
import { HailerApiErrors } from '../../../../test/deps/hailer-api/shared/api-errors';
import { TranslocoService } from '@ngneat/transloco';
import { TranslateService } from 'app/_services/translate.service';

@Pipe({
    name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer,
        private core: CoreService,
        private transloco: TranslocoService,
        private translate: TranslateService
    ) {}

    async transform(value?: string, linkData?: LinkData[], skipTags?: boolean): Promise<SafeHtml> {
        await this.translate.translationLoaded('markdown');
        const renderer = new marked.Renderer();
        renderer.link = (href, title, text) => {
            if (!href || !text) {
                return false;
            }

            const [identifier, fallbackName] = text.split('|');
            let linkName = fallbackName;
            switch (identifier) {
                case 'hailerTag':
                    if (skipTags) {
                        return `[hailerTag|${fallbackName}](${href})`;
                    }

                    // Hack!
                    const user = this.core.users.value?.[href];
                    if (user) {
                        return `<a href="/#/ui/user/open/${href}" class="user-tag">${user.display_name}</a>`;
                    }

                    const target = linkData?.find(({ _id }) => _id === href);
                    if (!target) {
                        return `#${this.transloco.translate('markdown.no-link-data')}`;
                    }

                    switch (target?.targetType) {
                        case 'activity':
                            if (target?.data?.code === HailerApiErrors.PermissionDenied) {
                                return `<span class="md-no-permission">#${this.transloco.translate('markdown.no-permission')}</span>`;
                            }

                            if (target?.data?.name) {
                                linkName = target.data.name;
                            }
                            break;
                        default:
                            break;
                    }

                    return `<a href="/#/ui/${target.targetType}/open/${target._id}" class="activity-tag">#${linkName}</a>`;
                case 'coloredText':
                    return `<span style="color: ${href}">${linkName}</span>`;
                default:
                    if (href.startsWith(`${location.origin}/#/ui`)) {
                        return false;
                    }

                    const urlifyPipe = new UrlifyPipe();
                    const urlified = urlifyPipe.transform(href, true);

                    return urlified;
            }
        };

        renderer.code = (code, language) => {
            return `<pre>${hljs.highlight(code, { language: hljs.getLanguage(language) ? language : 'js' }).value}</pre>`;
        };

        renderer.codespan = (code) => {
            return `<p style="margin: 0; background: var(--background-secondary); width: fit-content;">${code}</p>`;
        };

        renderer.image = (itemId, title, meta) => {
            const [identifier] = meta.split('|');
            if (identifier !== 'hailerImage') {
                return false;
            }

            const target = linkData?.find(({ _id }) => _id === itemId);
            if (!target) {
                return false;
            }

            if (!target.data.isPicture) {
                return false;
            }

            return `<img src="${environment.wsUrl}/image/original/${target._id}">`;
        };

        marked.use({ renderer });

        return this.sanitizer.bypassSecurityTrustHtml(
            DOMPurify.sanitize(marked.parse(value, { gfm: true }), {
                ADD_TAGS: ['hailer', 'iframe', 'div', 'code'],
                ADD_ATTR: ['target', 'style', 'src', 'type', 'allowfullscreen', 'frameborder'],
            })
        );
    }
}
