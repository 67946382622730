/** @format */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
    focusToMessage,
    getMessage,
    getMessageSuccess,
    loadInitialMessagesSuccess,
    loadLatestMessages,
    loadNextMessages,
    loadNextMessagesSuccess,
    loadPreviousMessages,
    loadPreviousMessagesSuccess,
} from '../actions/message.actions';
import { V3MessagesService } from 'app/_services/v3-messages.service';

@Injectable()
export class MessageEffects {
    constructor(private actions: Actions, private v3Message: V3MessagesService) {}

    loadLatestMessages = createEffect(() =>
        this.actions.pipe(
            ofType(loadLatestMessages),
            mergeMap(action =>
                this.v3Message.latest(action.options).pipe(
                    map(data =>
                        loadInitialMessagesSuccess({
                            newestLoaded: data.newestLoaded,
                            oldestLoaded: data.oldestLoaded,
                            messages: { [action.options.discussionId]: data.messages },
                        })
                    ),
                    catchError(error => {
                        console.error('Failed to load latest messages', error);
                        return EMPTY;
                    })
                )
            )
        )
    );

    loadPreviousMessages = createEffect(() =>
        this.actions.pipe(
            ofType(loadPreviousMessages),
            mergeMap(action =>
                this.v3Message.previous(action.options).pipe(
                    map(data =>
                        loadPreviousMessagesSuccess({
                            oldestLoaded: data.oldestLoaded,
                            messages: { [action.options.discussionId]: data.messages },
                        })
                    ),
                    catchError(error => {
                        console.error('Failed to load previous messages', error);
                        return EMPTY;
                    })
                )
            )
        )
    );

    loadNextMessages = createEffect(() =>
        this.actions.pipe(
            ofType(loadNextMessages),
            mergeMap(action =>
                this.v3Message.next(action.options).pipe(
                    map(data =>
                        loadNextMessagesSuccess({
                            newestLoaded: data.newestLoaded,
                            messages: { [action.options.discussionId]: data.messages },
                        })
                    ),
                    catchError(error => {
                        console.error('Failed to load next messages', error);
                        return EMPTY;
                    })
                )
            )
        )
    );

    focusToMessage = createEffect(() =>
        this.actions.pipe(
            ofType(focusToMessage),
            mergeMap(action =>
                this.v3Message.focus(action.options).pipe(
                    map(data =>
                        loadInitialMessagesSuccess({
                            newestLoaded: data.newestLoaded,
                            oldestLoaded: data.oldestLoaded,
                            messages: { [action.options.discussionId]: data.messages },
                        })
                    ),
                    catchError(error => {
                        console.error('Failed to focus a message', error);
                        return EMPTY;
                    })
                )
            )
        )
    );

    getMessage = createEffect(() =>
        this.actions.pipe(
            ofType(getMessage),
            mergeMap(action =>
                this.v3Message.get(action.options).pipe(
                    map(data =>
                        getMessageSuccess({
                            messages: { [action.options.discussionId]: data.messages },
                        })
                    ),
                    catchError(error => {
                        console.error('Failed to get a message', error);
                        return EMPTY;
                    })
                )
            )
        )
    );
}
