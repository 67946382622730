<app-file-uploader data-cy="discussion-file-uploader" class="file-container" #uploader></app-file-uploader>

<!--Show reply to message draft box-->
<div *ngIf="replyMessage" class="reference-message-container">
	<app-user-profile-picture [size]="40" [userId]="replyMessage.uid" class="bounce-in"></app-user-profile-picture>
	<mat-icon svgIcon="hailer-reply-fill" class="pop-in reference-icon"></mat-icon>
	<div style="display: block; margin-left: 8px;" class="pop-in">
		<div class="reply-header">
			<span style="display: flex; align-items: center">
				<app-user-link [userId]="replyMessage.uid" [ownName]="replyMessage.uid === (personalSettings | async)?.id"></app-user-link>
			</span>
		</div>
		<span *ngIf="replyMessage.msg" class="innerHTML" appExtMarkdown [content]="replyMessage.msg"></span>
		<span *ngIf="!replyMessage.msg && replyMessage.files"><mat-icon>insert_drive_file</mat-icon></span>
	</div>

	<button mat-icon-button (click)="closeDiscussionInputMenu('reply')" style="margin: auto 0 auto auto" class="pop-in">
		<mat-icon>close</mat-icon>
	</button>
</div>

<div *ngIf="createPoll">
    <div class="remove-poll-btn-wrapper">
        <app-button
            [color]="'destructive-toned-down'"
            [type]="'text-with-icon'"
            size="small"
            [hailerIconName]="'hailer-delete'"
            [ariaLabel]="'poll.remove' | transloco"
            [label]="'poll.remove' | transloco"
            (clickEvent)="togglePollCreator()">
        </app-button>
    </div>
    <app-poll-editor (pollChanged)="pollChanged($event)" (validityChanged)="pollValidityChanged($event)"></app-poll-editor>
</div>

<!--Show forward message draft box-->
<div *ngIf="forwardedMessage" class="reference-message-container">
	<!--profile picture of the original or reply/privateReply message sender when forwarding-->
	<div *ngIf="!forwardedMessage?.forwardMessage?._id || forwardedMessage?.replyPrivately">
		<app-user-profile-picture [size]="40" [userId]="forwardedMessage.uid" class="bounce-in"></app-user-profile-picture>
	</div>
	<!--profile picture of the forwarded message sender when forwarding an already forwarded message-->
	<div *ngIf="forwardedMessage?.forwardMessage?._id && !forwardedMessage?.replyTo && !forwardedMessage?.replyPrivately">
		<app-user-profile-picture [size]="40" [userId]="forwardedMessage.forwardMessage.uid" class="bounce-in"></app-user-profile-picture>
	</div>
	<mat-icon svgIcon="hailer-forward-fill" class="pop-in reference-icon"></mat-icon>
	<div class="forward-data">
		<!--shows who the message is from-->
		<span class="forwarded-from-text pop-in">
			{{ 'discussion.messages_container.from' | transloco }}: <strong>{{ forwardedMessageUser }}</strong>
		</span>
		<div *ngIf="!forwardedMessage?.forwardMessage?._id || forwardedMessage?.replyPrivately">
			<!--show original message or replyPrivately message to be forwarded-->
			<span
				*ngIf="forwardedMessage.msg && forwardedMessage.files.length === 0"
				class="innerHTML"
				appExtMarkdown
				[content]="forwardedMessage.msg"></span>
			<!--if forwarding only a file of any kind show a file icon-->
			<span *ngIf="!forwardedMessage.msg && forwardedMessage.files.length > 0"><mat-icon>insert_drive_file</mat-icon></span>
			<!--if forwarding a file with a message show both message and a file icon-->
			<span *ngIf="forwardedMessage.msg && forwardedMessage.files.length > 0" class="forwarding-text-and-file">
				<mat-icon>insert_drive_file</mat-icon>
				<div class="pop-in forwarding-text">
					<span class="innerHTML" appExtMarkdown [content]="forwardedMessage.msg"></span>
				</div>
			</span>
		</div>
		<!--same functionality as above but for already forwarded messages-->
		<div *ngIf="forwardedMessage?.forwardMessage?._id && !forwardedMessage?.replyTo && !forwardedMessage?.replyPrivately">
			<span
				*ngIf="forwardedMessage.forwardMessage.msg && forwardedMessage.forwardMessage.files.length === 0"
				class="innerHTML"
				appExtMarkdown
				[content]="forwardedMessage.forwardMessage.msg"></span>
			<span *ngIf="!forwardedMessage.forwardMessage.msg && forwardedMessage.forwardMessage.files.length > 0"
				><mat-icon>insert_drive_file</mat-icon></span
			>
			<span
				*ngIf="forwardedMessage.forwardMessage.msg && forwardedMessage.forwardMessage.files.length > 0"
				class="forwarding-text-and-file">
				<mat-icon>insert_drive_file</mat-icon>
				<div class="pop-in forwarding-text">
					<span class="innerHTML" appExtMarkdown [content]="forwardedMessage.forwardMessage.msg"></span>
				</div>
			</span>
		</div>
	</div>
	<button class="close-forward-button pop-in" mat-icon-button (click)="this.closeDiscussionInputMenu('forward')">
		<mat-icon>close</mat-icon>
	</button>
</div>

<!--Show link draft box-->
<div *ngIf="linkingInfo" class="reference-message-container">
	<mat-icon svgIcon="hailer-forward-fill" class="pop-in reference-icon"></mat-icon>
	<div class="forward-data">
		<div>
            <span data-cy="discussion-input-link-header" class="forwarded-from-text pop-in">{{linkingInfo.data?.header}}</span>
            <div class="pop-in forwarding-text">
                <span data-cy="discussion-input-link-content" [innerHtml]="linkingInfo.data?.content | markdown : linkingInfo.linkedData | async"></span>
            </div>
		</div>
	</div>
	<button class="close-forward-button pop-in" mat-icon-button (click)="this.closeDiscussionInputMenu('link')">
		<mat-icon>close</mat-icon>
	</button>
</div>

<div [style.marginBottom.px]="platform.IOS ? '8' : '0'">
	<button
		*ngIf="showJumpToBottom"
		class="older-notifier no-button"
		(click)="jumpToBottom.next()"
		attr.aria-label="{{ 'discussion.input.recent_messages' | transloco }} {{ 'discussion.input.present-aria' | transloco }}">
		<span>
			{{ 'discussion.input.recent_messages' | transloco }}
		</span>
		<span class="container align-items-center">
			{{ 'discussion.input.present' | transloco }} <mat-icon svgIcon="hailer-chevron"></mat-icon>
		</span>
		<mat-icon svgIcon="hailer-discussion"></mat-icon>
	</button>

	<div class="container align-items-center textarea-container">
		<app-button
			dataCy="discussion-input-attach-button"
			[menuTriggerFor]="attachMenu"
			type="icon"
			[color]="screenWidth > 600 ? 'icon-default' : 'secondary'"
			[size]="screenWidth > 600 ? 'medium' : 'small'"
			hailerIconName="hailer-plus"
			[ariaLabel]="'discussion.input.add' | transloco"
			[tippyText]="'discussion.input.add' | transloco"
			class="add-button"></app-button>

		<ng-container *ngIf="screenWidth <= 600">
			<button
				*ngIf="(discussion | async)?.linked_activity && selectedDiscussion.allowActivitySidenav"
				mat-icon-button
				(click)="openActivity()"
				class="activity-button"
				[attr.aria-label]="'discussion.input.open-activity' | transloco"
				[tippy]="'discussion.input.open-activity' | transloco">
				<mat-icon svgIcon="hailer-activity"></mat-icon>
			</button>
			<button
				*ngIf="(discussion | async)?.linked_event && selectedDiscussion.allowEventSidenav"
				mat-icon-button
				(click)="openEvent()"
				class="event-button"
				[attr.aria-label]="'discussion.input.open-event' | transloco"
				[tippy]="'discussion.input.open-event' | transloco">
				<mat-icon svgIcon="hailer-events"></mat-icon>
			</button>
			<button
				*ngIf="discussionType === 'group'"
				mat-icon-button
				(click)="openGroup()"
				class="group-button"
				[attr.aria-label]="'discussion.input.open-group' | transloco"
				[tippy]="'discussion.input.open-group' | transloco">
				<mat-icon svgIcon="hailer-group"></mat-icon>
			</button>
		</ng-container>

		<div
			data-cy="discussion-input-field"
			role="textbox"
			class="textarea"
			#textarea
			[mention]="users"
			contenteditable="true"
			[mentionConfig]="mentionConfig"
			spellcheck="true"
			name="text"
			(closed)="mentionsClosed()"
			(opened)="mentionsOpened()"
			[formControl]="messageCtrl"
			[attr.placeholder]="discussionSubject"
			[attr.aria-label]="'discussion.input.insert-text' | transloco"></div>

		<app-button
            data-cy="change-send-settings-button"
			type="icon"
			[color]="enterToSend ? 'icon-success' : 'icon-default'"
			hailerIconName="hailer-enter"
			class="enter-button"
			[menuTriggerFor]="enterMenu"
			[attr.aria-label]="'discussion.input.enter-button' | transloco"
			[tippyText]="'discussion.input.enter-button' | transloco"></app-button>

		<!--send message button which is not disabled if forwarding a message-->
		<app-button
			dataCy="discussion-send-button"
			type="icon"
			color="primary"
			[size]="screenWidth > 600 ? 'medium' : 'small'"
			hailerIconName="hailer-send"
			[isDisabled]="!canSendMessage()"
			(clickEvent)="sendMessage()"
			class="send-button"
			[attr.aria-label]="'discussion.input.send-button' | transloco"
			[tippyText]="'discussion.input.send-button' | transloco"></app-button>
	</div>

	<mat-menu #enterMenu="matMenu">
		<button mat-menu-item (click)="setEnterToSend(true)" class="enter-menu-button">
			<mat-radio-group data-cy="radio-group-send-settings" [ngModel]="enterToSend">
				<mat-radio-button data-cy="send-with-enter" [value]="true" class="mat-menu-item send-with-enter">
					<span>{{ 'discussion.input.enter' | transloco }}</span>
					<br />
					<small>{{ 'discussion.input.shift_enter' | transloco }}</small>
				</mat-radio-button>
			</mat-radio-group>
		</button>

		<button mat-menu-item (click)="setEnterToSend(false)" class="enter-menu-button">
			<mat-radio-group [ngModel]="enterToSend">
				<mat-radio-button [value]="false" class="mat-menu-item send-with-click">
					<span>{{ 'discussion.input.click' | transloco }}</span>
					<small></small>
				</mat-radio-button>
			</mat-radio-group>
		</button>
	</mat-menu>

	<mat-menu #attachMenu="matMenu">
		<!-- TODO: Add google buttons -->
		<!-- <button *ngIf="isLoggedInGoogle" mat-menu-item (click)="createGoogleDocument()">
            <mat-icon svgIcon="google"></mat-icon>Add document
        </button> -->
        <button *ngIf="isCapacitor" mat-menu-item appTakePicture [appTakePictureUploader]="uploader">
            <mat-icon svgIcon="hailer-camera"></mat-icon>{{ 'discussion.input.take-picture' | transloco }}
        </button>
        <button mat-menu-item (click)="togglePollCreator()" data-cy="discussion-input-attach-poll-button">
            <mat-icon svgIcon="hailer-insights"></mat-icon>{{ (createPoll ? 'poll.remove' : 'poll.create') | transloco }}
        </button>
		<button mat-menu-item data-cy="attach-file-menu-element" (click)="openUploader()">
			<mat-icon svgIcon="hailer-attach"></mat-icon>{{ 'discussion.input.attach' | transloco }}
		</button>
		<button
			data-cy="discussion-tab-create-linked-activity-button"
			[disabled]="isUserGuest"
			mat-menu-item
			*ngIf="(canBeLinkedFromProcesses | async)?.length > 0"
			[matMenuTriggerFor]="linkedActivityMenu">
			<mat-icon svgIcon="hailer-linked-activity"></mat-icon>{{ 'discussion.input.linked_activity' | transloco }}
		</button>
	</mat-menu>

	<mat-menu #linkedActivityMenu="matMenu">
		<ng-container *ngFor="let processItem of linkableProcesses | keyvalue">
			<!-- Datasets -->
			<ng-container *ngIf="processItem.value[0].process.enableUnlinkedMode">
				<ng-container *ngIf="processItem.value[0].process.phasesOrder.length > 1 || processItem.value.length > 1; else singularity">
					<!-- Multiple categories : Multiple link fields -->
					<button
						data-cy="linkable-workflow-option"
						mat-menu-item
						[matMenuTriggerFor]="
							multiplePhasesHaveActivityLinkFields(processItem.value[0].process).multiple ? phasesSubmenu : activityLinkSubmenu
						"
						[matMenuTriggerData]="{
							processItem: processItem,
							phaseId: multiplePhasesHaveActivityLinkFields(processItem.value[0].process).phaseId
						}">
						{{ processItem.value[0].process.name }}
					</button>
				</ng-container>
			</ng-container>
			<!-- Workflows -->
			<ng-container *ngIf="!processItem.value[0].process.enableUnlinkedMode">
				<!-- Multiple activity link fields; else just process name -->
				<ng-container *ngIf="processItem.value.length > 1; else singularity">
					<button
						data-cy="linkable-workflow-option"
						mat-menu-item
						[matMenuTriggerFor]="activityLinkSubmenu"
						[matMenuTriggerData]="{ processItem: processItem, phaseId: processItem.value[0].process.phasesOrder[0] }">
						{{ processItem.value[0].process.name }}
					</button>
				</ng-container>
			</ng-container>

			<ng-template #singularity>
				<button
					data-cy="linkable-workflow-option"
					(click)="createLinkedActivity(processItem.value[0].process, processItem.value[0].field._id)"
					mat-menu-item>
					{{ processItem?.value[0].process.name }}
				</button>
			</ng-template>
		</ng-container>
	</mat-menu>

	<mat-menu #phasesSubmenu="matMenu">
		<ng-template matMenuContent let-processItem="processItem">
			<ng-container *ngIf="processItem.value.length > 1">
				<ng-container *ngFor="let phaseId of processItem.value[0].process.phasesOrder">
					<ng-container *ngIf="phaseHasActivityLinkFields(processItem.value[0].process, phaseId)">
						<button
							data-cy="linkable-phase-item"
							mat-menu-item
							[matMenuTriggerFor]="activityLinkSubmenu"
							[matMenuTriggerData]="{ processItem: processItem, phaseId: phaseId }">
							{{ processItem.value[0].process.phases[phaseId].name }}
						</button>
					</ng-container>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="processItem.value.length === 1">
				<button
					data-cy="linkable-phase-item"
					mat-menu-item
					*ngFor="let phaseId of processItem.value[0].process.phasesOrder"
					(click)="createLinkedActivity(processItem.value[0].process, processItem.value[0].field._id, phaseId)">
					{{ processItem.value[0].process.phases[phaseId].name }}
				</button>
			</ng-container>
		</ng-template>
	</mat-menu>

	<mat-menu #activityLinkSubmenu="matMenu">
		<ng-template matMenuContent let-processItem="processItem" let-phaseId="phaseId">
			<ng-container *ngFor="let processFieldPair of processItem.value">
				<button
					data-cy="linkable-field-item"
					(click)="createLinkedActivity(processFieldPair.process, processFieldPair.field._id, phaseId)"
					*ngIf="processItem.value[0].process?.phases[phaseId]?.fields.includes(processFieldPair.field._id)"
					mat-menu-item>
					{{ processFieldPair?.field.label }}
				</button>
			</ng-container>
		</ng-template>
	</mat-menu>
</div>
