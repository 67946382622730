/** @format */

import { createAction, props } from '@ngrx/store';

import { MessageDraft, MessageDraftMap } from '@app/models';

export const loadMessageDraftMap = createAction('[Message Drafts] Load Message Draft Map');

export const setMessageDraft = createAction(
    '[Message Drafts] Set Message Draft',
    props<{
        messageDraft: MessageDraft;
        discussionId: string;
    }>()
);

export const setMessageDraftMap = createAction(
    '[Message Drafts] Set Message Draft Map',
    props<{
        messageDrafts: MessageDraftMap;
    }>()
);

export const setMessageDraftCacheSuccess = createAction(
    '[Message Drafts Cache] Set Message Draft Cache Success',
    props<{
        messageDrafts: MessageDraftMap;
    }>()
);
