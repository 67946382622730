/** @format */

import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ActivityField, FeedPostActivity, FeedPostProcess, Phase, ProcessField } from '@app/models';
import { CoreService } from 'app/_services/core.service';
import { environment } from '@app/env';
import { translateProcess } from 'app/_helpers/process-translation-helper';

@Component({
    selector: 'app-activity-data-render',
    templateUrl: './activity-data-render.component.html',
    styleUrls: ['./activity-data-render.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityDataRenderComponent implements OnInit, OnDestroy {
    @Input() activity: FeedPostActivity;
    @Input() process: FeedPostProcess;
    @Input() isAnnouncement: boolean;
    @Input() discussionMode = false;
    @Output() participants = new EventEmitter();

    wsUrl = environment.wsUrl;
    phaseAnnouncementFields = new BehaviorSubject<ProcessField[]>([]);
    isRenderable = false;
    styleClassMode: string;
    fieldIds: string[] = [];

    private onDestroy = new Subject<void>();

    constructor(private core: CoreService) {}

    ngOnInit(): void {
        if (!this.activity || !this.process) {
            return;
        }

        this.getClassMode();

        if (!(this.activity && this.activity.fields && this.process && this.process.fields)) {
            console.error('Not enough data to display announcement!');
            return;
        }

        this.isRenderable = true;

        const currentPhase: Phase = this.process.phases[this.activity.currentPhase] as Phase;

        if (!currentPhase) {
            console.error('Current phase not found in process');
            return;
        }

        this.fieldIds = this.isAnnouncement ? currentPhase.announcementFields : currentPhase.fields;

        this.participants.emit(currentPhase.announcementRecipients);

        if (!this.fieldIds || !this.fieldIds.length) {
            console.warn('No fields to render');
            return;
        }

        this.core.user.pipe(takeUntil(this.onDestroy)).subscribe({
            next: user => {
                this.process = translateProcess(
                    this.process,
                    user.preferredLanguages,
                    this.core.network.value.settings.languages || []
                );

                const phaseAnnouncementFields: ProcessField[] = [];
                this.fieldIds.forEach(fieldId => {
                    const field = this.process.fields[fieldId];
                    if (field) {
                        phaseAnnouncementFields.push({ ...field, _id: fieldId });
                    } else {
                        console.warn('Field not found found!');
                    }
                });

                phaseAnnouncementFields.sort((first, second) => {
                    if (first.type === 'textarea' && second.type === 'textarea') {
                        return 0;
                    }
                    if (first.type === 'textarea') {
                        return 1;
                    }
                    if (second.type === 'textarea') {
                        return -1;
                    }
                    return 0;
                });

                this.phaseAnnouncementFields.next(phaseAnnouncementFields);
            },
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    /** Return field or false */
    getField(fieldId: string): ActivityField | false | undefined {
        if (!Array.isArray(this.activity.fields)) {
            return false;
        }

        return this.activity.fields.find(field => field.id === fieldId);
    }

    private getClassMode(): void {
        if (this.discussionMode) {
            this.styleClassMode = 'discussion-styles';
        } else {
            this.styleClassMode = '';
        }
    }
}
