<!-- @format -->

<div class="poll-container" *ngIf="poll">
    <p data-cy="poll-name" class="poll-name">{{ poll.name }}</p>
    <span *ngIf="poll.draft"> {{ 'poll.draft' | transloco }}: {{ poll.created | todayDate | async }}</span>
    <ng-container *ngIf="creatorCanNotVote; else normalPollInfo">
        <p class="poll-info">{{ 'poll.creator-cannot-vote' | transloco }}</p>
    </ng-container>
    <ng-template #normalPollInfo>
        <p class="poll-info">{{ (poll.multipleAnswers ? 'poll.multiple-allowed' : 'poll.multiple-not-allowed') | transloco }}</p>
        <p class="poll-info">{{ 'poll.vote-visibility.' + poll.answerVisibility | transloco }}</p>
    </ng-template>
    <mat-selection-list [multiple]="poll.multipleAnswers" class="selection-list">
        <ng-container *ngFor="let option of options; trackBy: trackById; let i = index">
            <div class="flex">
                <mat-list-option
                    [attr.data-cy]="'poll-option-' + i"
                    #option
                    [disabled]="optionDisabled(option._id)"
                    [selected]="optionSelected(option._id)"
                    (click)="optionSelected(option._id) ? retract(option._id) : vote(option._id)"
                    [value]="option._id"
                    class="poll-list-option">
                    <div class="flex-column full-width">
                        <div class="option-name-votes-wrapper">
                            <p
                                [attr.data-cy]="'poll-option-name-' + i"
                                class="option-name"
                                [class.margin-top-small]="userHasAnswered">{{ option.name }}
                            </p>
                            <p *ngIf="userHasAnswered && poll.answerVisibility !== 'creator'" class="answer-count" [class.margin-right-medium]="!poll.multipleAnswers">
                                {{
                                    (poll.answerCounts[option._id] || 0) +
                                        ' ' +
                                        ((poll.answerCounts[option._id] === 1 ? 'poll.vote-count-prefix-1' : 'poll.vote-count-prefix-2')
                                            | transloco)
                                }}
                            </p>
                        </div>
                        <div *ngIf="userHasAnswered && poll.answerVisibility !== 'creator'" class="flex-row--space-between-centered">
                            <mat-progress-bar
                                color="accent"
                                [mode]="'determinate'"
                                [value]="optionPercentages[option._id]"
                                class="poll-progress-bar"></mat-progress-bar>
                            <p class="percentage" [class.margin-right-medium]="!poll.multipleAnswers">
                                {{ optionPercentages[option._id] + '%' }}
                            </p>
                        </div>
                        <ng-container *ngIf="optionSelected(option._id) && !poll.multipleAnswers"><mat-icon svgIcon="hailer-check" class="selected-tick"></mat-icon></ng-container>
                    </div>
                </mat-list-option>
                <ng-container *ngIf="canSeeUsers">
                    <div class="voters-container" [style.width]="maxVotersWidth">
                        <ng-container *ngFor="let userId of optionUserIds(option._id); let i = index">
                            <img
                                *ngIf="i <= 2"
                                width="32"
							    height="32"
                                [tippy]="userNames[userId] || ''"
                                (click)="openUserSidenav(userId)"
                                [isEnabled]="!!userNames[userId]"
                                [attr.aria-label]="userNames[userId]"
                                [src]="profileImageBaseUrl + userId" />
                        </ng-container>
                        <app-button
                            *ngIf="optionUserIds(option._id).length > 3"
                            [menuTriggerFor]="userMenu"
                            type="count"
                            [count]="optionUserIds(option._id).length - 3"
                            size="small"
                            color="secondary">
                        </app-button>
                        <mat-menu #userMenu="matMenu">
                            <ng-container *ngFor="let userId of optionUserIds(option._id); let i = index">
                                <ng-container *ngIf="i > 2">
                                    <button mat-menu-item (click)="openUserSidenav(userId)" class="voters-menu-item">
                                        <img width="32" height="32" [src]="profileImageBaseUrl + userId" aria-hidden="true" />
                                        <span class="one-line-ellipsis">{{ userNames[userId] }}</span>
                                    </button>
                                </ng-container>
                            </ng-container>
                        </mat-menu>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </mat-selection-list>

    <ng-container *ngIf="canSeeUsers">
        <div class="view-votes-btn-container">
            <button (click)="openResultsDialog()" class="no-button margin-top-medium view-votes-btn">
                {{ 'poll.view-votes' | transloco }}
            </button>
        </div>
    </ng-container>
</div>
