/** @format */

import { Pipe, PipeTransform } from '@angular/core';

import { PermissionCheckReturn, PermissionErrorCodes } from '../../../../test/deps/hailer-api/src/api/v3/permissions/permission-models';
import { CoreService } from 'app/_services/core.service';

@Pipe({
    name: 'hasPermission',
})
export class HasPermissionPipe implements PipeTransform {
    constructor(private core: CoreService) {}

    transform(value: PermissionCheckReturn): boolean {
        const [error, hasPermission] = value;

        if (error?.code === PermissionErrorCodes.missing && error?.workspaceId) {
            void this.core.permission.notFound(error.workspaceId);
        }

        return !error && !!hasPermission;
    }
}
