/** @format */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { setMessageDraft, setMessageDraftCacheSuccess } from '../actions/message-draft.actions';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';

@Injectable()
export class MessageDraftEffects {
    setMessageDraft = createEffect(() =>
        this.actions.pipe(
            ofType(setMessageDraft),
            mergeMap(action =>
                this.v3Discussion.setMessageDraftCache(action.discussionId, action.messageDraft).pipe(
                    map(messageDrafts => setMessageDraftCacheSuccess({ messageDrafts })),
                    catchError(error => {
                        console.error(error);
                        return EMPTY;
                    })
                )
            )
        )
    );

    constructor(private actions: Actions, private v3Discussion: V3DiscussionService) {}
}
