/** @format */

import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Event, NavigationExtras, Params, Router } from '@angular/router';
import { Observable, take } from 'rxjs';

import { CoreService } from './core.service';
import { SideNavService } from './side-nav.service';

@Injectable({
    providedIn: 'root',
})
export class RoutingService {
    constructor(
        private core: CoreService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private sideNav: SideNavService
    ) {}

    userRedirect = '';
    userRedirectInProgress = false;

    async toDefaultView() {
        const state = this.core.state.value;
        if (state !== 'authenticated') {
            switch (state) {
                case 'connecting':
                    // Loop until connected
                    this.toDefaultView();
                    break;
                default:
                    this.navigate([`/login`]);
                    break;
            }
            return;
        }

        const network = this.core.network.value;
        const user = this.core.user.value;

        if (network && user) {
            const defaultView = network.settings.defaultView;

            if (this.userRedirect && !this.userRedirectInProgress) {
                this.userRedirectInProgress = true;
                await this.router.navigateByUrl(this.userRedirect);
                return;
            }

            switch (defaultView) {
                case 'activities':
                case 'events':
                case 'feed': {
                    this.navigate([`/${defaultView}`]);
                    break;
                }
                default: {
                    this.navigate(['/discussions']);
                    break;
                }
            }
        } else {
            console.warn('no user or network defined');
        }
    }

    navigate(commands: any[], extras?: NavigationExtras) {
        return this.router.navigate(commands, extras);
    }

    get snapshot(): ActivatedRouteSnapshot {
        return this.activatedRoute.snapshot;
    }

    get queryParams(): Observable<Params> {
        return this.activatedRoute.queryParams;
    }

    getQueryParams(): Promise<Params> {
        return new Promise<Params>((resolve) => this.activatedRoute.queryParams.pipe(take(1)).subscribe({ next: params => resolve(params) }));
    }

    get url(): string {
        return this.router.url;
    }

    get events(): Observable<Event> {
        return this.router.events;
    }
}

export type ViewTypes = 'activities' | 'discussions' | 'events' | 'feed' | 'files' | 'people';
