/** @format */

import { HttpClient } from '@angular/common/http';
import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, Translation, TranslocoLoader, TranslocoModule, translocoConfig } from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';

import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string) {
        if (!lang || lang === 'null') {
            return;
        }

        return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    }
}

@NgModule({
    imports: [TranslocoMessageFormatModule.forRoot()],
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: ['en', 'fi', 'sv'],
                defaultLang: window.navigator.languages[0] ? window.navigator.languages[0].slice(0, -3) : 'en',
                prodMode: environment.production,
                fallbackLang: 'en',
            }),
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    ],
})
export class TranslocoRootModule {}
