/** @format */

import {
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    Type,
    ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';

import { MentionComponent } from 'app/extended-markdown/mention/mention.component';
import { ExtendedMarkdownService, HailerTagType } from './extended-markdown.service';

@Directive({
    selector: '[appExtMarkdown]',
})
export class ExtendedMarkdownDirective implements OnInit, OnChanges, OnDestroy {
    @Input() content: string = null;

    @Output() contentUpdate = new Subject<void>();

    private onDestroy = new Subject<void>();
    private currentHailerTag: any = null;

    constructor(
        private element: ElementRef,
        private viewRef: ViewContainerRef,
        private factoryResolver: ComponentFactoryResolver,
        private emd: ExtendedMarkdownService
    ) {}

    ngOnInit(): void {
        if (!this.content) {
            return;
        }

        this.handleInput(this.emd.parse(this.content));
    }

    ngOnChanges(changes: SimpleChanges): void {
        const previousValue = changes.content.previousValue;
        const currentValue = changes.content.currentValue;
        const valueHasChanged = currentValue !== previousValue;

        if (previousValue && currentValue && valueHasChanged) {
            this.handleInput(this.emd.parse(currentValue));
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    private handleInput(parsedInput: string) {
        this.element.nativeElement.innerHTML = parsedInput;

        const elements = this.element.nativeElement.querySelectorAll('hailer');
        for (const el of elements) {
            const tagMeta = JSON.parse(el.textContent);
            this.currentHailerTag = el;
            this.pickHandler(tagMeta);
        }

        this.currentHailerTag = null;
        this.contentUpdate.next();
    }

    private pickHandler(tagMeta: { type: HailerTagType; data: number }) {
        if (!this.currentHailerTag || !tagMeta) {
            console.error('No element selected! Tag===null');
            return;
        }
        const data = this.emd.getTagData(tagMeta.data);
        switch (tagMeta.type) {
            case 'plaintext': {
                this.handlePlaintext(data);
                break;
            }

            case 'inAppLink': {
                break;
            }

            case 'userMention': {
                this.handleMention(data);
                break;
            }

            case 'youtube': {
                this.handleYoutube(data);
            }
        }
    }

    private handleMention(data: { id: string; username: string }) {
        const uid = data.id;
        const username = data.username;

        const component = this.createComponent(MentionComponent);
        component.instance.setUser(uid, username);
        this.replaceHailerTag(component);
    }

    private createComponent<T>(component: Type<T>): ComponentRef<T> {
        const factory = this.factoryResolver.resolveComponentFactory<T>(component);
        return this.viewRef.createComponent<T>(factory);
    }

    private handlePlaintext(data: string) {
        const elem = document.createElement('span');
        elem.textContent = data;
        this.currentHailerTag.parentElement.replaceChild(elem, this.currentHailerTag);
    }

    private handleYoutube(videoId: string) {
        const div = document.createElement('div');
        div.style.position = 'relative';
        div.style.width = '100%';
        div.style.height = '330px';

        const iframe = document.createElement('iframe');

        iframe.setAttribute('src', `https://www.youtube.com/embed/${videoId}?rel=0`);
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allowfullscreen', '');

        div.appendChild(iframe);
        this.currentHailerTag.parentElement.replaceChild(div, this.currentHailerTag);
    }

    private replaceHailerTag(newComponent: any) {
        if (!this.currentHailerTag) {
            console.error('No tag to replace! Tag===null');
            return;
        }

        this.currentHailerTag.parentElement.replaceChild(newComponent.location.nativeElement, this.currentHailerTag);
    }
}
