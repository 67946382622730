<mat-list data-cy="permission-list">
    <mat-list-item data-cy="permission-list-item" *ngFor="let member of members" style="display: flex; flex-direction: row; justify-content: space-between; height: auto;">
        <div style="display: flex; flex-wrap: wrap; flex: 1;">
            <div class="container align-items-center">
                <ng-container *ngIf="member.id.startsWith('network_')">
                    <mat-icon class="img-circle team-icon" svgIcon="hailer-logo">
                    </mat-icon>
                    <span>{{'shared.permissions.all' | transloco}}<strong>{{getWorkspaceName(member.id)}}</strong>{{'shared.permissions.members' | transloco}}</span>
                </ng-container>
                <ng-container *ngIf="member.id.startsWith('team_')">
                    <mat-icon class="img-circle team-icon" svgIcon="hailer-group">
                    </mat-icon>
                    {{memberTeamMap[member.id]?.name}}
                </ng-container>
                <ng-container *ngIf="member.id.startsWith('group_')">
                    <mat-icon class="img-circle team-icon" svgIcon="hailer-group">
                    </mat-icon>
                    {{memberGroupMap[member.id]?.name}}
                </ng-container>
                <ng-container *ngIf="member.id.startsWith('user_')">
                    <app-user-profile-picture matListAvatar [userId]="member.id.slice(5)" [size]="40" style="margin-right: 8px;"></app-user-profile-picture>
                    {{peopleService.getUser(member.id.substring(5))?.display_name}}
                </ng-container>
            </div>
            <!-- <div *ngIf="showRemovePermissions(member.id)" class="pull-right" style="display: flex;"> -->
            <div *ngIf="member.allowRemove" class="pull-right" style="display: flex;">
                <ng-container>
                    <div style="flex: 1; display: flex; align-items: center;">
                        <app-permission-type-selector
                            [permissionTypes]="permissionTypes"
                            [member]="member"
                            (permissionGrant)="grantPermission(member.id, $event)"
                            (permissionDeny)="denyPermission(member.id, $event)"
                        ></app-permission-type-selector>
                    </div>
                </ng-container>
                <button mat-icon-button color="warn" (click)="removeMember(member.id)">
                    <mat-icon aria-label="Remove permission from user">close</mat-icon>
                </button>
            </div>
        </div>
    </mat-list-item>
</mat-list>