/**
 * Eslint-disable @typescript-eslint/naming-convention
 *
 * @format
 */

export const SupportedNotificationTypes: { [key: string]: boolean } = {
    'activities.completed': true,
    'activities.moved': true,
    'activities.update': true,
    'activity.assigned': true,
    'activity.completed': true,
    'activity.created': true,
    'activity.moved': true,
    'activity.update': true,
    'company.invite_accepted': true,
    'event.invitation': true,
    'hailer.invite': true,
    'hailer.verifyEmail': true,
    'messenger.user_mentioned': true,
    'wall.comment': true,
    'activity.remind': true,
    'feed.user_mentioned': true,
};
