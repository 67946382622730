/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Message } from '@app/models';
import { RPCService } from 'app/_services/rpc.service';

@Injectable({
    providedIn: 'root',
})
export class V3MessagesService {
    newMessages = new Subject<Message[]>();

    private messagesPerDiscussion: 500;
    private discussionMessages = new BehaviorSubject<V3DiscussionMessageResponseMap>({});

    constructor(private rpc: RPCService) {}

    focus(args: V3DiscussionMessagesArgs): Observable<V3DiscussionMessageResponse> {
        return this.requestMessagesWithCache('v3.discussion.message.focus', args);
    }

    next(args: V3DiscussionMessagesArgs): Observable<V3DiscussionMessageResponse> {
        return this.requestMessagesWithCache('v3.discussion.message.next', args);
    }

    previous(args: V3DiscussionMessagesArgs): Observable<V3DiscussionMessageResponse> {
        return this.requestMessagesWithCache('v3.discussion.message.previous', args);
    }

    latest(args: V3DiscussionMessagesArgs): Observable<V3DiscussionMessageResponse> {
        return this.requestMessagesWithCache('v3.discussion.message.latest', args);
    }

    get(args: V3DiscussionMessagesArgs): Observable<V3DiscussionMessageResponse> {
        return this.requestMessagesWithCache('v3.discussion.message.get', args);
    }

    delete(discussionId: string, messageId: string): Observable<any> {
        return this.rpc.request('v2.discussion.message.delete', [discussionId, messageId]);
    }

    react(discussionId: string, messageId: string, reaction: string): Observable<any> {
        return this.rpc.request('v2.discussion.message.react', [discussionId, messageId, reaction]);
    }

    edit(discussionId: string, messageId: string, messageText: string): Observable<any> {
        return this.rpc.request('v2.discussion.message.edit', [discussionId, messageId, messageText]);
    }

    send(message: any, discussionId?: string): Observable<any> {
        const args = [message];

        if (discussionId) {
            args.push(discussionId);
        }

        return this.rpc.request('messenger.send', args);
    }

    cacheDiscussionMessages(discussionId: string, res: V3DiscussionMessageResponse, removedDiscussionIds?: string[]) {
        const cachedMessages = this.discussionMessages.value || {};
        const cachedDiscussion = cachedMessages[discussionId] || {
            messages: [],
            newestLoaded: false,
            oldestLoaded: false,
        };

        // We want to update these only when the cached value is false
        cachedDiscussion.newestLoaded = cachedDiscussion.newestLoaded ? cachedDiscussion.newestLoaded : res.newestLoaded || false;
        cachedDiscussion.oldestLoaded = cachedDiscussion.oldestLoaded ? cachedDiscussion.oldestLoaded : res.oldestLoaded || false;

        let newMessages: Message[] = [];

        // Update existing messages and find new messages
        if (cachedDiscussion.messages.length) {
            cachedDiscussion.messages = cachedDiscussion.messages.map(message => {
                const updatedMessage = res.messages.find(({ _id }) => _id === message._id);
                return updatedMessage ? updatedMessage : message;
            });

            // Filtering out the new messages
            newMessages = res.messages.filter(({ _id }) => !cachedDiscussion.messages.find(cachedMessage => cachedMessage._id === _id));
        } else {
            cachedDiscussion.messages = res.messages;
        }

        // Remove oldest messages if exceeding the limit
        if (cachedDiscussion.messages.length > this.messagesPerDiscussion) {
            // TODO: Try out slice here (-this.messagesPerDiscussion)
            cachedDiscussion.messages = cachedDiscussion.messages.splice(this.messagesPerDiscussion, cachedDiscussion.messages.length - 1);
        }

        // Add new messages from response to the cached messages and sort them by created date
        cachedDiscussion.messages = cachedDiscussion.messages.concat(newMessages).sort((a, b) => b.created - a.created);

        cachedMessages[discussionId] = cachedDiscussion;

        // Remove discussion messages if needed
        if (removedDiscussionIds?.length) {
            for (const removedDiscussionId of removedDiscussionIds) {
                delete cachedMessages[removedDiscussionId];
            }
        }

        this.discussionMessages.next(cachedMessages);
        this.newMessages.next(newMessages);
    }

    private requestMessagesWithCache(op: string, args: V3DiscussionMessagesArgs): Observable<V3DiscussionMessageResponse> {
        return new Observable(observer => {
            const cachedValues = this.getDiscussionMessagesFromCache(op, args.discussionId, args.messageId);

            if (this.upToDateValues(op, cachedValues)) {
                observer.next(cachedValues);
                return void observer.complete();
            }

            this.rpc.request(op, [args.messageId ? args.messageId : args.discussionId]).subscribe({
                next: (res: V3DiscussionMessageResponse) => {
                    observer.next(res);
                    this.cacheDiscussionMessages(args.discussionId, res);
                    observer.complete();
                },
                error: error => {
                    observer.error(error);
                    observer.complete();
                },
            });
        });
    }

    private upToDateValues(op: string, cachedValues: V3DiscussionMessageResponse): boolean {
        // If both are true then we know that all the messages are loaded
        if (cachedValues.newestLoaded && cachedValues.oldestLoaded) {
            return true;
        }

        // Checking if there are 50 messages or 100
        const correctAmountOfMessages =
            op !== 'v3.discussion.message.focus' ? cachedValues.messages.length === 50 : cachedValues.messages.length === 101;
        return correctAmountOfMessages;
    }

    private getDiscussionMessagesFromCache(op: string, discussionId: string, messageId: string): V3DiscussionMessageResponse {
        const cachedDiscussions = this.discussionMessages.value || {};
        const cachedDiscussion = cachedDiscussions[discussionId] || {
            messages: [],
            newestLoaded: false,
            oldestLoaded: false,
        };

        if (!discussionId || !cachedDiscussion?.messages?.length) {
            return cachedDiscussion;
        }

        const cachedMessages = cachedDiscussion.messages || [];

        let messagesToReturn: Message[] = [];
        const message = cachedMessages.find(({ _id }) => _id === messageId);
        let messageCount = 0;

        switch (op) {
            case 'v3.discussion.message.focus':
                messageCount = 0;
                if (!message) {
                    break;
                }

                // First, add 50 newer messages than the focus message
                cachedMessages.reverse();
                messagesToReturn = cachedMessages
                    .filter(value => {
                        if (value.created > message.created && messageCount < 50) {
                            messageCount++;
                            return true;
                        }

                        return false;
                    })
                    .reverse();
                cachedMessages.reverse();

                messagesToReturn.push(message);
                messageCount = 0;

                // Then, add 50 older messages to the end of the array
                messagesToReturn = messagesToReturn.concat(
                    cachedMessages.filter(value => {
                        if (value.created < message.created && messageCount < 50) {
                            messageCount++;
                            return true;
                        }

                        return false;
                    })
                );

                break;
            case 'v3.discussion.message.next':
                messageCount = 0;
                if (!message) {
                    break;
                }

                cachedMessages.reverse();
                messagesToReturn = cachedMessages
                    .filter(value => {
                        if (value.created > message.created && messageCount < 50) {
                            messageCount++;
                            return true;
                        }

                        return false;
                    })
                    .reverse();
                cachedMessages.reverse();
                break;
            case 'v3.discussion.message.previous':
                messageCount = 0;
                if (!message) {
                    break;
                }

                messagesToReturn = cachedMessages.filter(value => {
                    if (value.created < message.created && messageCount < 50) {
                        messageCount++;
                        return true;
                    }

                    return false;
                });
                break;
            case 'v3.discussion.message.get':
                messagesToReturn = message ? [message] : [];
                break;
            case 'v3.discussion.message.latest':
                messagesToReturn = cachedMessages.filter((value, index) => index < 50);
                break;
            default:
                break;
        }

        const newestMessage = cachedMessages[0];
        const oldestMessage = cachedMessages[cachedMessages.length - 1];
        const isOldestLoaded = messagesToReturn[messagesToReturn.length - 1]?._id === oldestMessage._id;
        const isNewestLoaded = messagesToReturn[0]?._id === newestMessage._id;

        return {
            oldestLoaded: cachedDiscussion.oldestLoaded ? isOldestLoaded : false,
            newestLoaded: cachedDiscussion.newestLoaded ? isNewestLoaded : false,
            messages: messagesToReturn,
        };
    }
}

export interface V3DiscussionMessagesFocusArgs {
    messageId: string;
}

export interface V3DiscussionMessagesArgs {
    messageId?: string;
    discussionId?: string;
    limit?: number;
}

export interface V3DiscussionMessagesNextArgs {
    messageId: string;
    limit: number;
}

export interface V3DiscussionMessagesPreviousArgs {
    messageId: string;
    limit: number;
}

export interface V3DiscussionMessagesLatestArgs {
    discussionId: string;
    limit: number;
}

export interface V3DiscussionMessagesGetArgs {
    messageId: string;
}

export interface V3DiscussionMessageResponse {
    messages: Message[];
    oldestLoaded?: boolean;
    newestLoaded?: boolean;
}

export interface V3DiscussionMessageResponseMap {
    [discussionId: string]: V3DiscussionMessageResponse;
}

export interface StoreDiscussionMessages {
    [discussionId: string]: V3DiscussionMessageResponse;
}
