import { useState } from "react";
import { MatrixItem } from "./insight";
import { distinctValueLimit, useInsight } from "./use-insight";
import { formatValue } from "./util";
import Tooltip from "./tooltip";
import PivotValueSelector from "./components/pivot-value-selector";
import '@szhsin/react-menu/dist/index.css';
import "@szhsin/react-menu/dist/theme-dark.css";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";

export default function Pivot() {
  const { api, pivot, data, show, drillDown, theme, options } = useInsight()
  const [error, setError] = useState<string | null>(null);

  const [rowHighlighted, setRowHighlighted] = useState(null);
 
  const headers = data.data.headers;

  const mergeSameTh = (cols: MatrixItem[], keyIndex: number) => {
    if (!cols.length) {
      return <></>;
    }

    const list = [];

    let previous = null;
    let colSpan = 1;
    let index = 0;

    for (const col of cols) {
      if (previous === null) {
        // ignore, but set previous value (below)
      } else if (previous === col.keys[keyIndex]) {
        // merge
        colSpan++;
      } else {
        // new th
        list.push(<th className={`insight-col-head`} key={index++} colSpan={colSpan}>{previous}</th>);
        colSpan = 1;
      }

      previous = col.keys[keyIndex];
    }

    list.push(<th className={`insight-col-head`} key={-1} colSpan={colSpan}>{previous}</th>);

    return list;
  }

  const pivotMaxDropDowns = 3;
  const pivotRowDropDowns = new Array((pivot?.rows?.length || 0) + 1).fill(1).slice(0, pivotMaxDropDowns);
  const pivotColDropDowns = new Array((pivot?.cols?.length || 0) + 1).fill(1).slice(0, pivotMaxDropDowns);

  const handleRowHighlight = (rowIndex) => {
    setRowHighlighted((prevHighlightedRow) => {
      return prevHighlightedRow === rowIndex ? null : rowIndex;
    })
  }

  const isRowHighlighted = (rowIndex) => rowIndex === rowHighlighted;

  const cellClickStyleHandler = () => {
    setRowHighlighted(false);
  }

  return <div className="pivot-container">
    <div>
      {api.error.pivot && <div className="insight-warn">No pivot data, error: {api.error.pivot?.msg}</div>}

      {show.pivot && (!pivot?.rows?.length || api.error.pivot) &&
        <div className="insight-empty-pivot-wrapper">
          <div className="insight-header-sm">
          Select at least one row for pivot
          </div>
          <form>
            <label className="insight-label">Rows (max 3)</label>
            {pivotRowDropDowns.map((item, index) => (
              <section key={index}>
                <select className="insight-select" onInput={(event: any) => { api.pivot.setRow(index, event.target.value) }} value={pivot.rows?.[index]}>
                  <option key={-1} value="">- Select -</option>
                  {headers.map((header, headerIndex) => (<option disabled={data.distinct[headerIndex] === null || data.distinct[headerIndex].length > distinctValueLimit} key={headerIndex} value={headerIndex}>{header} ({data.distinct[headerIndex] === null ? ('> ' + distinctValueLimit) : data.distinct[headerIndex].length})</option>))}
                </select>
              </section>
            ))}
            <label className="insight-label">Columns (max 3)</label>
            {pivotColDropDowns.map((item, index) => (
              <section key={index}>
                <select className="insight-select" onChange={(event: any) => { api.pivot.setCol(index, event.target.value) }} value={pivot.cols?.[index]}>
                  <option key={-1} value="">- Select -</option>
                  {headers.map((header, headerIndex) => (<option disabled={data.distinct[headerIndex] === null || data.distinct[headerIndex].length > distinctValueLimit} key={headerIndex} value={headerIndex}>{header} ({data.distinct[headerIndex] === null ? ('> ' + distinctValueLimit) : data.distinct[headerIndex].length})</option>))}
                </select>
              </section>
            ))}
          </form>

          {/* <form>
            <label>Max num of rows</label>
            <input className="insight-input wide-insight-input" type="number" min="1" onChange={(event: any) => { setPivotLimitRows(event.target.value) }} value={pivot.limit?.rows} />
            <label>Max num of columns</label>
            <input className="insight-input wide-insight-input" type="number" min="1" onChange={(event: any) => { setPivotLimitCols(event.target.value) }} value={pivot.limit?.cols} />
          </form> */}
        </div>
      }
    </div>

    {!api.error.pivot && (
      <>

        {pivot.rows.length > 0 && 
          <div className="insight-pivot-topbar">
            <span className="insight-label">Value by:</span>
            <PivotValueSelector />
          </div>
        }
        
        <table className="pivot">
          <tbody>
            {/* When only rows are selected, make "1d" pivot */}
            {(!pivot?.cols?.length && !!pivot?.rows?.length) && <>
              <tr>
                {pivot.rows.map((row, rowIndex, rows) => (
                  <th key={rowIndex} className="head label insight-row-head">
                    {rowIndex === 0 && <span onClick={api.pivot.sortRowKeys} className="material-symbols-outlined small pointer-cursor">{pivot.sort.row[0] !== 'keys' ? 'swap_vert' : (pivot.sort.row[1] ? 'arrow_upward_alt' : 'arrow_downward_alt')}</span>}

                    <select className="insight-select transparent-select insight-row-head" onInput={(event: any) => { api.pivot.setRow(rowIndex, event.target.value) }} value={pivot.rows?.[rowIndex]}>
                      <option key={-1} value="">- Clear Selection -</option>
                      {headers.map((header, headerIndex) => (<option disabled={data.distinct[headerIndex] === null || data.distinct[headerIndex].length > distinctValueLimit} key={headerIndex} value={headerIndex}>{header} ({data.distinct[headerIndex] === null ? ('> ' + distinctValueLimit) : data.distinct[headerIndex].length})</option>))}
                    </select>

                    {/* Add new row button with menu */}
                    {pivot.rows.length - 1 === rowIndex && pivot.rows.length < 3 && (
                    <Menu menuButton={
                      <div className="insight-add-button-menu-container">
                        <Tooltip text="Add new row" position="top">
                          <MenuButton className="insight-button sm-button insight-accent-button add-row-button"><span className="material-symbols-outlined">add</span></MenuButton>
                        </Tooltip>
                    </div>}
                    theming={theme === 'dark' ? 'dark' : undefined}
                    menuClassName="custom-insight-react-menu"
                    overflow="auto">
                  
                      {headers.map((header, headerIndex) =>
                        (<MenuItem
                          key={headerIndex}
                          value={headerIndex}
                          disabled={data.distinct[headerIndex] === null || data.distinct[headerIndex].length > distinctValueLimit}
                          onClick={() => api.pivot.setRow(rowIndex + 1, headerIndex)}>
                          {header} ({data.distinct[headerIndex] === null ? ('> ' + distinctValueLimit) : data.distinct[headerIndex].length})
                        </MenuItem>))}
                    </Menu>
                    )}
                  </th>
                ))}

                <th className="head label insight-col-head">
                  {/* Add new column button with menu */}
                  <Menu menuButton={
                    <MenuButton className="insight-button insight-button-with-icon insight-accent-button add-col-button">
                      <span className="material-symbols-outlined icon">add</span>Add column</MenuButton>}
                  theming={theme === 'dark' ? 'dark' : undefined}
                  menuClassName="custom-insight-react-menu"
                  overflow="auto">

                  {headers.map((header, headerIndex) =>
                    (<MenuItem
                      key={headerIndex}
                      value={headerIndex}
                      disabled={data.distinct[headerIndex] === null || data.distinct[headerIndex].length > distinctValueLimit}
                      onClick={() => api.pivot.setCol(1, headerIndex)}>
                      {header} ({data.distinct[headerIndex] === null ? ('> ' + distinctValueLimit) : data.distinct[headerIndex].length})
                    </MenuItem>))}
                  </Menu>

                          {/* When is this happening? */}
                  {pivot.cols.length > 0 && <span>{pivot.sort.col[0] === 'keys' ? pivot.sort.col[1] ? '➡' : '⬅' : <>⇄</>}</span>}
                </th>

                <th className="pointer-cursor" onClick={api.pivot.totalsRowClick}>
                  <span>{pivot.value.formula === 'Count' ? 'Totals' : pivot.value.formula}</span>
                  <span className="material-symbols-outlined small pointer-cursor insight-table-header-arrow">{pivot.sort.row[0] !== 'total' ? <></> : (pivot.sort.row[1] ? 'arrow_upward_alt' : 'arrow_downward_alt')}</span>
                </th>
              </tr>

              {data.pivot.rows.length > 0 && data.pivot.rows.map((row, rowIndex) => (
                <tr key={rowIndex} className={isRowHighlighted(rowIndex) ? 'highlighted-row' : ''}>
                  {row.keys.map((key, index, list) =>
                    <th key={index} className={`label insight-row-head pointer-cursor ${rowHighlighted === rowIndex ? 'highlighted-row-header' : ''}`} colSpan={index + 1 === list.length ? 2 : 1} onClick={() => handleRowHighlight(rowIndex)}><span style={{ color: row.color }}>■</span> {key}</th>
                  )}
                  <th className={`insight-row-totals pointer-cursor ${ row?.key === drillDown?.key ? 'totals-drilldown-cell' : '' }`}  onClick={() => {api.setDrillDown(row); cellClickStyleHandler() }}>{formatValue(row.total)}</th>
                </tr>
              ))}
            </>
            }


            {/* When both row and columns set, make "2d" pivot */}
            {(!!pivot?.cols?.length && !!pivot?.rows?.length) && <>
              {pivot.cols.map((col, colIndex, cols) => <tr key={colIndex}>
                { pivot.rows.map((row, rowIndex, rows) => (
                  colIndex + 1 == cols.length ? (
                    <th key={rowIndex} className="head label insight-row-head">
                      {rowIndex === 0 && <span onClick={api.pivot.sortRowKeys} className="material-symbols-outlined small pointer-cursor">{pivot.sort.row[0] !== 'keys' ? 'swap_vert' : (pivot.sort.row[1] ? 'arrow_upward_alt' : 'arrow_downward_alt') }</span>}

                      {/* Selector for pivot rows */}
                      <select className="insight-select transparent-select insight-row-head" onInput={(event: any) => { api.pivot.setRow(rowIndex, event.target.value) }} value={pivot.rows?.[rowIndex]}>
                        <option key={-1} value="">- Clear Selection -</option>
                        {headers.map((header, headerIndex) => (<option disabled={data.distinct[headerIndex] === null || data.distinct[headerIndex].length > distinctValueLimit} key={headerIndex} value={headerIndex}>{header} ({data.distinct[headerIndex] === null ? ('> ' + distinctValueLimit) : data.distinct[headerIndex].length})</option>))}
                      </select>

                      {/* Add new row button with menu */}
                      {pivot.rows.length - 1 === rowIndex && pivot.rows.length < 3 && (
                        <Menu menuButton={
                          <div className="insight-add-button-menu-container">
                            <Tooltip text="Add new row" position="top">
                              <MenuButton className="insight-button sm-button insight-accent-button add-row-button">
                              <span className="material-symbols-outlined">add</span></MenuButton>
                            </Tooltip>
                          </div>}
                        theming={theme === 'dark' ? 'dark' : undefined}
                        menuClassName="custom-insight-react-menu"
                        overflow="auto">

                        {headers.map((header, headerIndex) =>
                          (<MenuItem
                            key={headerIndex}
                            value={headerIndex}
                            disabled={data.distinct[headerIndex] === null || data.distinct[headerIndex].length > distinctValueLimit}
                            onClick={() => api.pivot.setRow(rowIndex + 1, headerIndex)}>
                            {header} ({data.distinct[headerIndex] === null ? ('> ' + distinctValueLimit) : data.distinct[headerIndex].length})
                          </MenuItem>))}
                        </Menu>
                      )}

                    </th>
                  ) : (
                    <th key={`static-pivot-value-${rowIndex}`} style={{ border: '0', backgroundColor: 'transparent' }}></th>
                  )
                ))}

                <th className="head label insight-col-head">
                  <span onClick={api.pivot.sortColKeys} className="material-symbols-outlined small pointer-cursor">{pivot.sort.col[0] === 'keys' ? (pivot.sort.col[1] ? 'arrow_right_alt' : 'arrow_left_alt') : 'swap_horiz'}</span>

                  {/* Selector for pivot cols */}
                  <select className="insight-select transparent-select insight-col-head" onInput={(event: any) => { api.pivot.setCol(colIndex, event.target.value) }} value={pivot.cols?.[colIndex]}>
                    <option key={-1} value="">- Clear Selection -</option>
                    {headers.map((header, headerIndex) => (<option disabled={data.distinct[headerIndex] === null || data.distinct[headerIndex].length > distinctValueLimit} key={headerIndex} value={headerIndex}>{header} ({data.distinct[headerIndex] === null ? ('> ' + distinctValueLimit) : data.distinct[headerIndex].length})</option>))}
                  </select>

                  {/* Add new column button with menu */}
                  {pivot.cols.length - 1 === colIndex && pivot.cols.length < 3 && (
                    <Menu menuButton={
                      <div className="insight-add-button-menu-container">
                        <Tooltip text="Add new column" position="top">
                          <MenuButton className="insight-button sm-button insight-accent-button add-col-button"><span className="material-symbols-outlined">add</span></MenuButton>
                        </Tooltip>
                      </div>}
                    theming={theme === 'dark' ? 'dark' : undefined}
                    menuClassName="custom-insight-react-menu"
                    overflow="auto">

                    {headers.map((header, headerIndex) =>
                      (<MenuItem
                        key={headerIndex}
                        value={headerIndex}
                        disabled={data.distinct[headerIndex] === null || data.distinct[headerIndex].length > distinctValueLimit}
                        onClick={() => api.pivot.setCol(colIndex + 1, headerIndex)}>
                        {header} ({data.distinct[headerIndex] === null ? ('> ' + distinctValueLimit) : data.distinct[headerIndex].length})
                        </MenuItem>))}
                    </Menu>
                  )}

                </th>
                {mergeSameTh(data.pivot.cols, colIndex)}

                { colIndex === 0 && (
                  <th rowSpan={pivot.cols.length} className="pointer-cursor" onClick={api.pivot.totalsRowClick}>
                    <span>{pivot.value.formula === 'Count' ? 'Totals' : pivot.value.formula}</span>
                    <span className="material-symbols-outlined small pointer-cursor insight-table-header-arrow">{pivot.sort.row[0] !== 'total' ? <></> : (pivot.sort.row[1] ? 'arrow_upward_alt' : 'arrow_downward_alt') }</span>
                  </th>
                )}
              </tr>)}

              { data.pivot.rows.length > 0 && data.pivot.rows.map((row, rowIndex) => (
                <tr key={rowIndex} className={isRowHighlighted(rowIndex) ? 'highlighted-row' : ''}>
                  { row.keys.map((key, index, list) => (
                    <th
                      key={index}
                      className={`label insight-row-head pointer-cursor ${rowHighlighted === rowIndex ? 'highlighted-row-header' : '' }`}
                      colSpan={index + 1 === list.length ? 2 : 1}
                      onClick={() => handleRowHighlight(rowIndex)}
                    >
                      <span style={{ color: row.color }}>■</span>
                      {key}
                    </th>
                  ))}
                  { data.pivot.cols.map((col, colIndex) =>
                    <td
                      key={colIndex} 
                      onClick={() =>{ api.setDrillDown(row, col); cellClickStyleHandler() }}
                      className={`${ row?.key + '|' + col?.key === drillDown?.key ? 'drilldown-cell' : '' }`}>
                        { formatValue(data.pivot.table?.[rowIndex]?.[colIndex]) }
                    </td>)}
                  <th className={`insight-row-totals pointer-cursor ${ row?.key === drillDown?.key ? 'totals-drilldown-cell' : '' }`} onClick={() =>{ api.setDrillDown(row); cellClickStyleHandler() }}>{formatValue(row.total)}</th>
                </tr>
              ))}
              </>
            }
            
          </tbody>

          {/* Same footer for "1d" and "2d" pivot */}
          {!!pivot?.rows?.length && (
            <tfoot>
              <tr>
                <th colSpan={pivot.rows.length + 1} onClick={api.pivot.totalsColClick}>
                  <span className="footer-totals">{pivot.value.formula === 'Count' ? 'Totals' : pivot.value.formula}</span>

                  {pivot.cols.length > 0 && <span className="material-symbols-outlined small pointer-cursor footer-arrow">{ pivot.sort.col[0] === 'total' ? (pivot.sort.col[1] ? 'arrow_right_alt' : 'arrow_left_alt') : 'swap_horiz' }</span> }
                </th>

                { pivot.cols.length > 0 && data.pivot.cols.map((col, colIndex) => <th key={colIndex} className={`insight-col-totals pointer-cursor ${ col?.key === drillDown?.key ? 'totals-drilldown-cell' : '' }`} onClick={() => api.setDrillDown(undefined, col)}>{formatValue(col.total)}</th>) }
                <th>{formatValue(data.pivot.total)}</th>
              </tr>
            </tfoot>
          )}

        </table>
      </>
    )}

  </div>;
}
