import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { PollOption, PollOptionMap } from '../../../../test/deps/hailer-api/shared/poll-types';
import { RPCService } from 'app/_services/rpc.service';
import { CoreService } from 'app/_services/core.service';
import { LinkDataMap, LinkPollData } from '../../../../test/deps/hailer-api/shared/link-types';
import { environment } from '@app/env';
import { Subject, takeUntil } from 'rxjs';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { TranslateService } from 'app/_services/translate.service';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { MatListOption } from '@angular/material/list';
import { SideNavService } from 'app/_services/side-nav.service';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';
import { PollViewResultsDialogComponent } from '../poll-view-results-dialog/poll-view-results-dialog.component'
import { MatDialog } from '@angular/material/dialog';

export interface ResultDialogData {
    pollName: string;
    pollOptions: PollOption[];
    profileImageBaseUrl: string;
    userNames: { [userId: string]: string };
    optionUserIds: (id: string) => string[];
    openUserSidenav: (userId: string) => void;
}

@Component({
    selector: 'app-poll',
    templateUrl: './poll.component.html',
    styleUrls: ['./poll.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: TRANSLOCO_SCOPE, useValue: 'poll' },
    ],
})
export class PollComponent implements OnInit {
    @Input() inputPoll: LinkPollData;
    /** Id if the element the poll is linked to */
    @Input() sourceId: string;

    @ViewChildren('option') optionElements: QueryList<MatListOption>;

    profileImageBaseUrl = `${environment.wsUrl}/profileimage/square50/`;
    votingOption?: string;
    optionPercentages: { [optionId: string]: number } = {};
    poll: LinkPollData;

    userNames: { [userId: string]: string } = {};
    maxVotersWidth = '0px';

    private onDestroy = new Subject<void>();
    constructor(
        public matDialog: MatDialog,
        private rpc: RPCService,
        private core: CoreService,
        private cdr: ChangeDetectorRef,
        private dialog: DialogHelperService,
        private translate: TranslateService,
        private transloco: TranslocoService,
        private sidenav: SideNavService,
    ) { }

    ngOnInit(): void {
        // The inputPoll supplied by message-link from messages is readOnly, so we have to take a deep copy
        this.poll = JSON.parse(JSON.stringify(this.inputPoll));
        this.setUserNames(this.answeredUserIds);
        // If a user votes on a poll, then hops between the discussions, the linkedData in the message will not have been updated
        void this.reloadPoll();

        this.rpc.subscribe('poll.vote', ({ pollId, optionId, userId, voteTime, answerCounts, usersAnsweredCount }) => {
            if (pollId !== this.poll._id) {
                return;
            }

            if (answerCounts) {
                this.poll.answerCounts = answerCounts;
            }

            if (usersAnsweredCount) {
                this.poll.usersAnsweredCount = usersAnsweredCount;
            }

            if (optionId && userId) {
                this.addVote(optionId, userId, voteTime);
            }

            this.calculateOptionPercentages();
            this.cdr.detectChanges();
        });

        this.rpc.subscribe('poll.retract', ({ pollId, optionId, userId, answerCounts, usersAnsweredCount }) => {
            if (pollId !== this.poll._id) {
                return;
            }

            if (answerCounts) {
                this.poll.answerCounts = answerCounts;
            }

            if (usersAnsweredCount) {
                this.poll.usersAnsweredCount = usersAnsweredCount;
            }

            if (optionId && userId) {
                this.removeVote(optionId, userId);
            }

            this.calculateOptionPercentages();
            this.cdr.detectChanges();
        });
    }

    get options(): PollOption[] {
        return Object.values(this.poll.options);
    }

    get canSeeUsers(): boolean {
        const userIsCreator = this.core.user.value?._id === this.poll.creatorId;
        if (userIsCreator && this.poll.answerVisibility === 'creator') {
            return true;
        }

        if (!this.userHasAnswered) {
            return false;
        }

        return this.poll.answerVisibility === 'public';
    }

    get creatorCanNotVote(): boolean {
        const userIsCreator = this.core.user.value?._id === this.poll.creatorId;
        return userIsCreator && this.poll.answerVisibility === 'creator';
    }

    addVote(optionId: string, userId: string, voteTime: number): void {
        if (!optionId || !userId || !voteTime) {
            return;
        }

        this.poll.answers = this.poll.answers || {};
        this.poll.answers[optionId] = this.poll.answers[optionId] || {};
        this.poll.answers[optionId]![userId] = { voteTime };
        this.calculateOptionPercentages();
        this.setUserNames([userId]);
    }

    removeVote(optionId: string, userId: string): void {
        if (!optionId || !userId) {
            return;
        }

        void this.reloadPoll();
    }

    setUserNames(userIds: string[]): void {
        for (const userId of userIds) {
            const userName = this.core.users.value[userId]?.display_name;
            if (!userName) {
                continue;
            }

            this.userNames[userId] = userName;
        }

        this.cdr.detectChanges();
    }

    optionDisabled(optionId: string): boolean {
        if (this.poll.creatorId === this.core.user.value?._id && this.poll.answerVisibility === 'creator') {
            // Creator cannot vote creator only poll
            return true;
        }

        if (this.poll.draft || this.poll.scheduled) {
            return true;
        }

        if (this.poll?.lockedVotes && this.optionSelected(optionId)) {
            return true;
        }

        if (!this.poll.multipleAnswers) {
            return !this.optionSelected(optionId) && this.userHasAnswered;
        }

        return false;
    }

    get answeredUserIds(): string[] {
        if (!this.poll.answers) {
            return [];
        }

        const userIds = new Set<string>();
        for (const optionId in this.poll.answers) {
            if (!optionId || !this.poll.answers[optionId]) {
                continue;
            }

            for (const userId in this.poll.answers[optionId]) {
                if (!userId) {
                    continue;
                }

                userIds.add(userId);
            }
        }

        return Array.from(userIds);
    }

    get userHasAnswered(): boolean {
        const userId = this.core.user.value?._id;
        if (!userId) {
            return true;
        }
        return Object.values(this.poll.answers || {}).some(optionUserMap => optionUserMap[userId]);
    }

    trackById(index: number, item: PollOption) {
        return item._id;
    }

    optionUserIds(optionId: string): string[] {
        return Object.keys(this.poll.answers?.[optionId] || {});
    }

    optionSelected(optionId: string): boolean {
        const userId = this.core.user.value?._id;
        if (!userId) {
            return false;
        }

        return !!this.poll.answers?.[optionId]?.[userId];
    }

    async vote(optionId: string): Promise<void> {
        if (this.poll.lockedVotes) {
            const confirmed = await this.dialog.showConfirmAsync(
                await this.transloco.translate('poll.confirm-vote'),
                `${await this.transloco.translate('poll.confirm-vote-text')}"${this.poll.options[optionId]?.name}"`,
                await this.transloco.translate('poll.confirm-vote'),
                'primary'
            );

            if (!confirmed) {
                this.toggleOptionCheckbox(optionId);
                return;
            }
        }
        const userId = this.core.user.value?._id;
        if (!userId) {
            console.warn('User is not defined');
            this.toggleOptionCheckbox(optionId);
            return;
        }

        if (this.optionSelected(optionId) || (this.userHasAnswered && !this.poll.multipleAnswers)) {
            this.toggleOptionCheckbox(optionId);
            return;
        }

        this.votingOption = optionId;
        this.cdr.detectChanges();
        try {
            await this.rpc.requestAsync('poll.vote', [{ pollId: this.poll._id, optionId }]);
            await this.reloadPoll();
        } catch (error) {
            console.error('Failed to vote', error);
        }

        this.votingOption = undefined;
        this.cdr.detectChanges();
    }

    async retract(optionId: string): Promise<void> {
        const userId = this.core.user.value?._id;
        if (!userId) {
            console.warn('User is not defined');
            this.toggleOptionCheckbox(optionId);
            return;
        }

        this.votingOption = optionId;
        this.cdr.detectChanges();
        await this.translate.translationLoaded('poll');

        const confirmed = await this.dialog.showConfirmAsync(
            await this.transloco.translate('poll.retract-vote'),
            await this.transloco.translate('poll.retract-confirm-text'),
            await this.transloco.translate('poll.retract-vote'),
        );

        if (!confirmed) {
            this.toggleOptionCheckbox(optionId);
            return;
        }

        try {
            await this.rpc.requestAsync('poll.retract', [{ pollId: this.poll._id, optionId }]);
            this.removeVote(optionId, userId);
        } catch (error) {
            console.error('Failed to vote', error);
        }

        this.votingOption = undefined;
        this.cdr.detectChanges();
    }

    toggleOptionCheckbox(optionId: string): void {
        const element = this.optionElements.find(option => option.value === optionId);
        element?.toggle();
        this.cdr.detectChanges();
    }

    async reloadPoll(): Promise<void> {
        if (!this.sourceId) {
            console.error('Cannot reload poll data without source id');
            return;
        }

        try {
            const linkTargetData: LinkDataMap = await this.rpc.requestAsync('v3.link.get.targets', [{ [this.sourceId]: [this.poll._id] }]);
            const poll = linkTargetData?.[this.sourceId]?.find(link => link.targetType === 'poll' && link._id === this.poll._id)?.data;
            if (!poll) {
                console.error('Failed to fetch poll. Poll is undefined');
                return;
            }

            this.poll = poll;
            this.calculateOptionPercentages();
        } catch (error) {
            console.error('Failed to reload poll data', error);
        }
    }

    openUserSidenav(userId: string): void {
        this.sidenav.create(UserDetailComponent, {
            userId,
        });
    }

    openResultsDialog() {
        this.matDialog.open(PollViewResultsDialogComponent, {
            width: '340px',
            data: {
                pollName: this.poll.name,
                pollOptions: Object.values(this.poll.options),
                profileImageBaseUrl: this.profileImageBaseUrl,
                userNames: this.userNames,
                optionUserIds: this.optionUserIds.bind(this),
                openUserSidenav: this.openUserSidenav.bind(this)
            } as ResultDialogData
        });
    }

    /** To be refactored */
    private calculateOptionPercentages(): void {
        for (const optionId in this.poll.options) {
            if (!optionId) {
                continue;
            }

            if (!this.poll.answerCounts?.[optionId] || !this.poll.usersAnsweredCount) {
                this.optionPercentages[optionId] = 0;
                continue;
            }

            const optionAnswerCount = this.poll.answerCounts?.[optionId] || 0;
            if (optionAnswerCount === this.poll.usersAnsweredCount) {
                this.optionPercentages[optionId] = 100;
                continue;
            }

            this.optionPercentages[optionId] = Math.floor((optionAnswerCount / this.poll.usersAnsweredCount) * 100);
        }

        this.calculateMaxVotersWidth();
    }

    private calculateMaxVotersWidth(): void {
        // Find the maximum number of voters across all options
        const maxVoters = Math.max(...this.options.map(option => this.poll.answerCounts?.[option._id] || 0), 0);
        // Cap the maximum number of voters to 4 for width calculation
        const effectiveVoters = Math.min(maxVoters, 4);
        // Calculate the width, 32px for each effective voter
        this.maxVotersWidth = `${effectiveVoters * 32}px`;

        this.cdr.detectChanges();
    }
}
