/** @format */

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-hailer-logo',
    templateUrl: './hailer-logo.component.html',
    styleUrls: ['./hailer-logo.component.css'],
})
export class HailerLogoComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
