import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlphabeticalSortPipe } from './src/alphabetical-sort.pipe';
import { HailerColorPipe } from './src/color.pipe';
import { DateFieldFilterPipe } from './src/date-field-filter.pipe';
import { FileSizePipe } from './src/file-size.pipe';
import { HashtagPipe } from './src/hashtag.pipe';
import { HtmlEscapePipe } from './src/html-escape.pipe';
import { HtmlUnescapePipe } from './src/html-unescape.pipe';
import { LastWeekDayPipe } from './src/last-week-day.pipe';
import { MarkdownPipe } from './src/markdown.pipe';
import { MentionsPipe } from './src/mentions.pipe';
import { Nl2brPipe } from './src/nl2br.pipe';
import { NumericFieldFilterPipe } from './src/numeric-field-filter.pipe';
import { ParseIdsPipe } from './src/parseIds.pipe';
import { ParticipantsPipe } from './src/participants.pipe';
import { HasPermissionPipe } from './src/permission.pipe';
import { SafePipe } from './src/safe.pipe';
import { SelectedFieldSortPipe } from './src/selected-field-sort.pipe';
import { TeamSorterPipe } from './src/team-sorter.pipe';
import { ThousandSeparatorPipe } from './src/thousand-separator.pipe';
import { TimeagoPipe } from './src/timeago.pipe';
import { TodayDatePipe } from './src/today-date.pipe';
import { UidSorterPipe } from './src/uid-sorter.pipe';
import { UrlifyPipe } from './src/urlify.pipe';



@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        TodayDatePipe,
        HashtagPipe,
        HtmlEscapePipe,
        HtmlUnescapePipe,
        SafePipe,
        TimeagoPipe,
        ParticipantsPipe,
        FileSizePipe,
        NumericFieldFilterPipe,
        DateFieldFilterPipe,
        SelectedFieldSortPipe,
        ThousandSeparatorPipe,
        UidSorterPipe,
        TeamSorterPipe,
        Nl2brPipe,
        UrlifyPipe,
        LastWeekDayPipe,
        ParseIdsPipe,
        MentionsPipe,
        AlphabeticalSortPipe,
        HailerColorPipe,
        HasPermissionPipe,
        TimeagoPipe,
        MarkdownPipe,
    ],
    exports: [
        MarkdownPipe,
        TodayDatePipe,
        HashtagPipe,
        HtmlEscapePipe,
        HtmlUnescapePipe,
        SafePipe,
        TimeagoPipe,
        FileSizePipe,
        NumericFieldFilterPipe,
        DateFieldFilterPipe,
        SelectedFieldSortPipe,
        ThousandSeparatorPipe,
        UidSorterPipe,
        TeamSorterPipe,
        Nl2brPipe,
        UrlifyPipe,
        LastWeekDayPipe,
        MentionsPipe,
        AlphabeticalSortPipe,
        ParticipantsPipe,
        HailerColorPipe,
        HasPermissionPipe,
        TimeagoPipe,
    ],
})
export class PipesModule { }
