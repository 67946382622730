import { useEffect } from "react";
import { InsightData, InsightOptions } from "./insight";
import Table from "./table";
import Pivot from "./pivot";
import PivotChart from "./pivot-chart";
import { useInsight } from "./use-insight";
import TopBar from "./topbar";
import './insight.css';

export default function App(props: { elementId: string, data: InsightData, options: InsightOptions, events?: (event: string) => void }) {
  const { api, show, options, theme } = useInsight();

  useEffect(() => {
    if (props.data && props.options) {
      // init the insight-lib state, and attach event listener
      api.init(props.data, props.options, (state) => props.events?.(state));
    }
  }, [props.data, props.options]);

  useEffect(() => {
    if (theme) {
      if (theme === 'dark') {
        document.body.classList.add('dark-theme');
      } else {
        document.body.classList.remove('dark-theme');
      }
    }
  }, [theme]);

  // Setting the grid based on which elements are toggled to be visible
  useEffect(() => {
    const container = document.querySelector<HTMLElement>('#' + props.elementId + ' .insight-container');

    if (container) {

      const elementsToCheck = ['pivot', 'table', 'chart'];
      const numVisibleElements = elementsToCheck.reduce((count, element) => (show[element] ? count + 1 : count), 0);

      let gridTemplateRows = '';

      switch (numVisibleElements) {
        case 3:
          gridTemplateRows = '1fr fit-content(30vh) fit-content(30vh)';
          break;
        case 2:
          gridTemplateRows = (show.chart && show.pivot) ? '1fr fit-content(50vh) 0' : (show.chart && show.table) ? '1fr 0 fit-content(50vh)' : '0 fit-content(50vh) 1fr';
          break;
        case 1:
          gridTemplateRows = show.table ? '0 0 1fr' : show.pivot ? '0 1fr 0' : '1fr 0 0';
          break;
        default:
          gridTemplateRows = '1fr 1fr 1fr';
          break;
      }

      container.style.gridTemplateRows = gridTemplateRows;
    }
  }, [show]);

  return (
    <>
      {!options && (
        <div className="loading-container">
          <h1>Loading...</h1>
        </div>
      )}

      {options && (
        <>
          {show.topbar && <TopBar />}

          <div className="insight-container">
            {show.chart && <PivotChart />}
            {show.pivot && <Pivot />}
            {show.table && <Table />}
          </div>
        </>
      )}
    </>
  );
}
