/** @format */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ImageViewerService {
    images = new Subject<{ images: ViewerImage[]; current: number }>();

    viewImages(images: ViewerImage[], index: number) {
        this.images.next({
            images,
            current: index,
        });
    }
}

export interface ViewerImage {
    _id: string;
    name: string;
    url: string;
    original: string;
    type: string;
    isVideo: boolean;
    isPicture: boolean;
    downloadUrl: string;
}
