/** @format */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { MatMenu } from '@angular/material/menu';

import { Company, Discussion, Process } from '@app/models';
import { PeopleService } from 'app/people/people.service';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';
import { CoreService } from 'app/_services/core.service';
import { environment } from '@app/env';
import { StylingHelperService } from 'app/_services/styling-helper.service';

@Component({
    selector: 'app-discussion-list-item',
    templateUrl: './discussion-list-item.component.html',
    styleUrls: ['./discussion-list-item.component.scss'],
})
export class DiscussionListItemComponent implements OnDestroy {
    @Input() discussion: Discussion;
    @Input() selectedDiscussion = new BehaviorSubject<string>(null);
    @Input() discussionIconMenu: MatMenu;

    @Output() navigateToDiscussion = new EventEmitter<string>();

    private me = this.core.user;
    private onDestroy = new Subject<void>();

    constructor(
        public viewContainerRef: ViewContainerRef,
        public stylingHelperService: StylingHelperService,
        private v3Discussion: V3DiscussionService,
        private core: CoreService,
        private people: PeopleService
    ) {}

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    discussionUnread(discussionId: string): boolean {
        return Object.keys(this.v3Discussion.unreadDiscussions.value)?.includes(discussionId);
    }

    getProfilePictureURL(discussion: Discussion): string {
        const baseUrl = `${environment.wsUrl}/image/square100/`;
        const filteredParticipants = this.getParticipantsWithoutMe(discussion);
        return baseUrl + this.people.getUser(filteredParticipants[0]).default_profilepic;
    }

    getDiscussionSubject(discussion: Discussion): string {
        if (!discussion) {
            return '';
        }
        let subject: string;
        if (discussion.private) {
            const filteredParticipants = this.getParticipantsWithoutMe(discussion);
            subject = filteredParticipants[0] ? this.people.getUser(filteredParticipants[0]).display_name : 'Unknown User';
            return subject || '';
        }
        subject = discussion?.subject;
        return subject || '';
    }

    getUnreadCount(discussionId: string) {
        return this.v3Discussion.unreadDiscussions.value[discussionId];
    }

    getNetwork(companyId: string): Observable<Company> {
        return this.core.networks.pipe(pluck(companyId));
    }

    getWorkflow(processId: string | undefined): Process | undefined {
        if (!processId) {
            return undefined;
        }
        return this.core.processById(processId);
    }

    getProcessName(processId: string | undefined): string {
        if (!processId) {
            return '';
        }
        return this.core.processById(processId)?.name;
    }

    getDiscussionType(discussion: Discussion): string {
        if (discussion.private) {
            return 'private';
        }
        if (!discussion.linked_activity && discussion.linked_event) {
            return 'events';
        } else if (discussion.linked_activity && !discussion.linked_event) {
            return 'activity';
        } else if (!discussion.linked_activity && !discussion.linked_event) {
            return 'group';
        }
        return '';
    }

    private getParticipantsWithoutMe(discussion: Discussion): string[] {
        return discussion.participants.filter(uid => uid !== this.me.value._id);
    }
}
