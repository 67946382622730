import { stripUndefined } from '../../../../../shared/util';
import { HailerApiErrors } from '../../../../modules/api-errors';
import { PermitKeys } from '../permission-keys';
import { PermissionCheckReturn, PermissionError, PermissionErrorCodes, PermissionMap, Role, WorkspacePermissions } from '../permission-models';

export class FeedPermission {
    constructor(private userId: string, private permissions: PermissionMap) { }

    /** Workspace owners and admins are feed admins */
    isAdmin(workspaceId: string): PermissionCheckReturn {
        const [canManageError, canManage] = this.hasCustomRolePermit(workspaceId, 'feed.manage');
        if (canManageError) {
            return [canManageError];
        }

        if (canManage) {
            return [undefined, true];
        }

        const [error, workspacePermissions] = this.workspacePermissions(workspaceId);
        if (error) {
            return [error];
        }

        const isOwner = workspacePermissions?.workspace?.isOwner;
        const isAdmin = workspacePermissions?.workspace?.isAdmin;
        if (!isOwner && !isAdmin) {
            return [{ name: 'Invalid permissions', message: 'User needs to be an admin' }];
        }

        return [undefined, true];
    }

    /** Feed post recipients can load feed posts if they have "feed.load" custom role permit */
    load(workspaceId: string): PermissionCheckReturn {
        const [canLoadError] = this.hasCustomRolePermit(workspaceId, 'feed.load');
        if (canLoadError) {
            return [canLoadError];
        }

        const [error, workspacePermissions] = this.workspacePermissions(workspaceId);
        if (error) {
            return [error];
        }

        const isGuest = workspacePermissions?.workspace?.isGuest;
        if (isGuest) {
            return [{ name: 'Permission denied', message: 'Guest users cannot access feed' }];
        }

        return [undefined, true];
    }

    /** Feed post permissions */
    post = {
        /** Feed admins can pin posts */
        pin: (workspaceId: string): PermissionCheckReturn => {
            return this.isAdmin(workspaceId);
        },
        /** Feed admins and feed post recipients can like the feed post. Guest users cannot */
        like: (post: { cid: string, recipients?: false | string[], uid: string }): PermissionCheckReturn => {
            const [canInteractError, canInteract] = this.hasCustomRolePermit(post.cid, 'feed.interact');
            if (canInteractError) {
                return [canInteractError];
            }

            if (canInteract) {
                return [undefined, true];
            }

            const [error, workspacePermissions] = this.workspacePermissions(post.cid);
            if (error) {
                return [error];
            }

            workspacePermissions?.workspace.custom;

            const isWorkspaceGuest = workspacePermissions?.workspace?.isGuest;
            if (isWorkspaceGuest) {
                return [{ name: 'Invalid permissions', message: 'Cannot like a post with current permissions' }];
            }

            const [adminError] = this.isAdmin(post.cid);
            if (!adminError) {
                return [undefined, true];
            }

            if (post.uid === this.userId) {
                return [undefined, true];
            }

            const [recipientError, isRecipient] = this.isFeedPostRecipient(post.cid, post.recipients || []);
            if (recipientError || !isRecipient) {
                return [recipientError || { name: 'Invalid permissions', message: 'User is not feed post recipient' }];
            }

            return [undefined, true];
        },

        /** Feed admins and post owners can remove feed posts. Guest users cannot */
        remove: (workspaceId: string, postOwnerId: string): PermissionCheckReturn => {
            if (postOwnerId === this.userId) {
                const [canInteractError, canInteract] = this.hasCustomRolePermit(workspaceId, 'feed.interact');
                if (canInteractError) {
                    return [canInteractError];
                }

                if (canInteract) {
                    return [undefined, true];
                }
            }

            const [canManageError, canManage] = this.hasCustomRolePermit(workspaceId, 'feed.manage');
            if (canManageError) {
                return [canManageError];
            }

            if (canManage) {
                return [undefined, true];
            }

            const [error, workspacePermissions] = this.workspacePermissions(workspaceId);
            if (error) {
                return [error];
            }

            const isWorkspaceGuest = workspacePermissions?.workspace?.isGuest;
            if (isWorkspaceGuest) {
                return [{ name: 'Invalid permissions', message: 'Cannot like a post with current permissions' }];
            }

            if (postOwnerId === this.userId) {
                // User can remove their own posts
                return [undefined, true];
            }

            const [adminError] = this.isAdmin(workspaceId);
            if (adminError) {
                return [adminError];
            }

            return [undefined, true];
        },

        /** Only guest users cannot create feed posts */
        create: (workspaceId: string): PermissionCheckReturn => {
            const [canInteractError, canInteract] = this.hasCustomRolePermit(workspaceId, 'feed.interact');
            if (canInteractError) {
                return [canInteractError];
            }

            if (canInteract) {
                return [undefined, true];
            }

            const [error, workspacePermissions] = this.workspacePermissions(workspaceId);

            if (error) {
                return [error];
            }

            if (workspacePermissions?.workspace?.isGuest) {
                return [{ name: 'Invalid permissions', message: 'Cannot create feed post with current permissions' }];
            }

            return [undefined, true];
        },

        /** Feed admins and feed post recipients can load feed posts. Guest users cannot */
        load: (post: { cid: string; uid: string; recipients?: string[] | false }): PermissionCheckReturn => {
            const [canLoadError, canLoad] = this.load(post.cid);
            if (canLoadError) {
                return [canLoadError];
            }

            const [recipientError, isRecipient] = this.isFeedPostRecipient(post.cid, post.recipients || []);
            if (canLoad && isRecipient) {
                return [undefined, true];
            }

            const [adminError] = this.isAdmin(post.cid);
            if (!adminError) {
                return [undefined, true];
            }

            if (this.userId === post.uid) {
                return [undefined, true];
            }

            if (recipientError || !isRecipient) {
                return [recipientError || { name: 'Invalid permissions', message: 'User is not feed post recipient' }];
            }

            return [undefined, true];
        },

        /** Users that can load feed posts can also follow them */
        follow: (post: { cid: string; uid: string; recipients?: string[] | false }): PermissionCheckReturn => {
            return this.post.load(post);
        },

        /** Users that can load feed posts can also unfollow them */
        unfollow: (post: { cid: string; uid: string; recipients?: string[] | false }): PermissionCheckReturn => {
            return this.post.load(post);
        },

        /** Feed admins and post owners can edit feed posts. Guest users cannot */
        edit: (post: { cid: string, uid: string, type: string }): PermissionCheckReturn => {
            if (post.type !== 'user') {
                return [{ message: 'Cannot edit non user post', name: 'Permission denied' }];
            }

            if (post.uid === this.userId) {
                const [canInteractError, canInteract] = this.hasCustomRolePermit(post.cid, 'feed.interact');
                if (canInteractError) {
                    return [canInteractError];
                }

                if (canInteract) {
                    return [undefined, true];
                }
            }

            const [canManageError, canManage] = this.hasCustomRolePermit(post.cid, 'feed.manage');
            if (canManageError) {
                return [canManageError];
            }

            if (canManage) {
                return [undefined, true];
            }

            const [error, workspacePermissions] = this.workspacePermissions(post.cid);

            if (error) {
                return [error];
            }

            if (workspacePermissions?.workspace?.isGuest) {
                return [{ name: 'Invalid permissions', message: 'Cannot create feed post with current permissions' }];
            }

            const [adminError] = this.isAdmin(post.cid);
            if (post.uid !== this.userId && adminError) {
                return [{ name: 'Invalid permissions', message: 'User is not post creator or feed admin' }];
            }

            return [undefined, true];
        },
    };

    /** Feed comment permissions */
    comment = {
        /** Feed admins and comment owners can remove feed comments. Guest users cannot */
        remove: (workspaceId: string, commentOwnerId: string): PermissionCheckReturn => {
            if (commentOwnerId === this.userId) {
                const [canInteractError, canInteract] = this.hasCustomRolePermit(workspaceId, 'feed.interact');
                if (canInteractError) {
                    return [canInteractError];
                }

                if (canInteract) {
                    return [undefined, true];
                }
            }

            const [canManageError, canManage] = this.hasCustomRolePermit(workspaceId, 'feed.manage');
            if (canManageError) {
                return [canManageError];
            }

            if (canManage) {
                return [undefined, true];
            }

            const [workspacePermissionError, workspacePermissions] = this.workspacePermissions(workspaceId);

            if (workspacePermissionError) {
                return [workspacePermissionError];
            }

            if (workspacePermissions?.workspace?.isGuest) {
                return [{ name: 'Invalid permissions', message: 'Cannot create feed post with current permissions' }];
            }

            if (commentOwnerId === this.userId) {
                // User can remove their own comments
                return [undefined, true];
            }

            const [adminError] = this.isAdmin(workspaceId);
            if (adminError) {
                return [adminError];
            }

            return [undefined, true];
        },

        /** Feed admins and feed post recipients can load feed post comments. Guest users cannot */
        load: (post: { cid: string; uid: string; recipients?: string[] | false }): PermissionCheckReturn => {
            const [canLoadError, canLoad] = this.load(post.cid);
            if (canLoadError) {
                return [canLoadError];
            }

            const [recipientError, isRecipient] = this.isFeedPostRecipient(post.cid, post.recipients || []);
            if (canLoad && isRecipient) {
                return [undefined, true];
            }

            const [adminError] = this.isAdmin(post.cid);
            if (!adminError) {
                return [undefined, true];
            }

            if (this.userId === post.uid) {
                return [undefined, true];
            }

            if (recipientError || !isRecipient) {
                return [recipientError || { name: 'Invalid permissions', message: 'User is not feed post recipient' }];
            }

            return [undefined, true];
        },

        /** Feed admins and feed post recipients can create comments. Guest users cannot */
        create: (post: { cid: string, recipients?: false | string[], uid: string }): PermissionCheckReturn => {
            const [canInteractError, canInteract] = this.hasCustomRolePermit(post.cid, 'feed.interact');
            if (canInteractError) {
                return [canInteractError];
            }

            if (canInteract) {
                return [undefined, true];
            }

            const [error, workspacePermissions] = this.workspacePermissions(post.cid);
            if (error) {
                return [error];
            }

            if (workspacePermissions?.workspace?.isGuest) {
                return [{ name: 'Invalid permissions', message: 'Cannot create feed post with current permissions' }];
            }

            const [adminError] = this.isAdmin(post.cid);
            if (!adminError) {
                return [undefined, true];
            }

            if (post.uid === this.userId) {
                return [undefined, true];
            }

            const [recipientError, isRecipient] = this.isFeedPostRecipient(post.cid, post.recipients || []);
            if (recipientError || !isRecipient) {
                return [recipientError || { name: 'Invalid permissions', message: 'User is not feed post recipient' }];
            }

            return [undefined, true];
        },

        /** Feed comment owners can edit the comment. Guest users cannot */
        edit: (workspaceId: string, commentOwnerId: string): PermissionCheckReturn => {
            const [canInteractError, canInteract] = this.hasCustomRolePermit(workspaceId, 'feed.interact');
            if (canInteractError) {
                return [canInteractError];
            }

            if (canInteract) {
                if (commentOwnerId !== this.userId) {
                    return [{ name: 'Invalid permissions', message: 'User is not comment creator' }];
                }

                return [undefined, true];
            }

            const [error, workspacePermissions] = this.workspacePermissions(workspaceId);
            if (error) {
                return [error];
            }

            if (workspacePermissions?.workspace?.isGuest) {
                return [{ name: 'Invalid permissions', message: 'Cannot create feed post with current permissions' }];
            }

            if (commentOwnerId !== this.userId) {
                return [{ name: 'Invalid permissions', message: 'User is not comment creator' }];
            }

            return [undefined, true];
        },
    };

    private workspacePermissions(workspaceId: string): [error?: PermissionError, permissions?: WorkspacePermissions] {
        if (!this.permissions) {
            return [{ name: 'Invalid permissions', message: 'User permissions are undefined', code: PermissionErrorCodes.missing, workspaceId }];
        }

        const workspacePermissions = this.permissions[workspaceId];
        if (!workspacePermissions) {
            return [
                { name: 'Invalid permissions', message: 'Workspace permissions are undefined', code: PermissionErrorCodes.missing, workspaceId },
            ];
        }

        return [undefined, workspacePermissions];
    }

    private isFeedPostRecipient(workspaceId: string, recipientIds: string[]): [error?: PermissionError, isRecipient?: boolean] {
        if (!Array.isArray(recipientIds) || !recipientIds.length) {
            return [undefined, true];
        }

        const [error, workspacePermissions] = this.workspacePermissions(workspaceId);
        if (error || !workspacePermissions) {
            return [error, false];
        }

        const recipientMatch = recipientIds.some(recipientId => workspacePermissions.permits?.includes(recipientId));
        return [undefined, recipientMatch || false];
    }

    private hasCustomRolePermit(workspaceId: string, permitKey: PermitKeys): PermissionCheckReturn {
        const [workspacePermissionError, workspacePermissions] = this.workspacePermissions(workspaceId);
        if (workspacePermissionError) {
            return [workspacePermissionError];
        }

        const customRole = workspacePermissions?.workspace.custom;
        if (!customRole) {
            return [];
        }

        if (!customRole.permits?.includes(permitKey)) {
            return [{ name: 'Permission denied', message: `User role does not have ${permitKey} permit in feed` }];
        }

        return [undefined, true];
    }
}
