/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import * as DOMPurify from 'dompurify';

@Pipe({
    name: 'safe',
})
export class SafePipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    transform(value: any, type: string): SafeValue | null {
        return this.bypassSecurityTrust(
            type,
            DOMPurify.sanitize(value, { ADD_TAGS: ['iframe'], ADD_ATTR: ['allowfullscreen', 'frameborder', 'target'] })
        );
    }

    private bypassSecurityTrust(type: string, purifiedValue: string): SafeValue | null {
        switch (type) {
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(purifiedValue);
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(purifiedValue);
            case 'script':
                return this.sanitizer.bypassSecurityTrustScript(purifiedValue);
            case 'url':
                return this.sanitizer.bypassSecurityTrustUrl(purifiedValue);
            case 'resourceUrl':
                return this.sanitizer.bypassSecurityTrustResourceUrl(purifiedValue);
            default:
                return null;
        }
    }
}
