/** @format */

import {
    AfterViewInit,
    ApplicationRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, throwError } from 'rxjs';
import { debounceTime, filter, skip, take, timeout } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';

import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { RPCService } from 'app/_services/rpc.service';
import { CoreService, SessionState } from 'app/_services/core.service';

import { importedIcons } from './_models/icons.model';

import { LicenseExceededDialogComponent } from './shared/license-exceeded-dialog/license-exceeded-dialog.component';

import { DevService } from './development/dev.service';
import { environment } from '@app/env';
import { PersistentLog } from './_helpers/util';

import { Company, PersonalSettings } from './_models';

import { PushNotificationService } from './_services/push-notification.service';
import { LoadingProgressService } from './_services/loading-progress.service';
import { WindowListenerService } from './_services/window-listener.service';
import { TeamService } from './_services/team.service';
import { TranslateService } from './_services/translate.service';
import { ThemeService } from './theme/theme.service';
import { LinkResolverService } from './_services/link-resolver.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'feed' }],
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild('maindiv', { static: false }) maindiv: ElementRef;
    isSocketConnected = false;
    isGuiReady = false;
    state: BehaviorSubject<SessionState>;
    connected: BehaviorSubject<boolean>;
    reconnecting = new BehaviorSubject<boolean>(false);
    hideLoadingScreen = false;
    removeLoadingScreen = false;
    isMobile = window.innerWidth < 600;
    t0 = Date.now();

    constructor(
        private loadingProgress: LoadingProgressService,
        private cdr: ChangeDetectorRef,
        private core: CoreService,
        private dialog: MatDialog,
        private domSanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry,
        private router: Router,
        private rpc: RPCService,
        private zone: NgZone,
        private applicationRef: ApplicationRef,
        private theme: ThemeService,
        private pushNotifications: PushNotificationService,
        private translocoService: TranslocoService,
        private windowListener: WindowListenerService,
        private teamService: TeamService,
        /* Meta tag */
        private metaTag: Meta,
        private translate: TranslateService,
        /** Unused, but registers tools for development and debugging */
        private dev: DevService,
        private linkResolver: LinkResolverService,
    ) {
        this.cdr.detach();
        this.initCapacitorApp();

        this.router.events.subscribe({
            next: () => void this.checkForVersionAndUpdate(),
        });
    }

    ngOnInit() {
        // Load the meta tag
        this.metaTag.addTags([
            {
                name: 'description',
                content: 'Work better together with Hailer. Work management app for teams and companies of all sizes.',
            },
            {
                name: 'keywords',
                content: 'work management app, workflow management platform',
            },
        ]);

        if (localStorage.getItem('debug')) {
            // First, store the original tick function
            const originalTick = this.applicationRef.tick;

            const changeDetectionLog = localStorage.getItem('changeDetection');
            this.applicationRef.tick = function () {
                // Save start time
                const windowsPerfomance = window.performance;
                const before = windowsPerfomance.now();

                // Run the original tick() function
                const returnValue = originalTick.apply(this, arguments);

                // Save end time, calculate the delta, then log to console
                const after = windowsPerfomance.now();
                const runTime = Math.round((after - before) * 100) / 100;
                if (runTime && changeDetectionLog) {
                    window.console.log('ChangeDetection:', runTime, 'ms');
                }
                return returnValue;
            };
        }

        this.state = this.core.state;
        this.connected = this.core.connected;
        this.core.connected
            .pipe(
                skip(1),
                debounceTime(5000),
                filter(() => !this.rpc.browserInactive)
            )
            .subscribe({
                next: () => {
                    setTimeout(() => {
                        const connected = this.core.connected.getValue();
                        this.reconnecting.next(!connected);
                    }, 10000);
                },
            });

        const splashScreenTimeout = 120000;
        this.loadingProgress.loaded
            .pipe(
                skip(1),
                take(1),
                timeout({
                    each: splashScreenTimeout,
                    with: () =>
                        throwError(() => {
                            console.warn('Loading timeout reached, loadingProgress.loaded might not have been set on view initialization!');
                            this.loadingProgress.loaded.next(true);
                            this.hideLoadingScreen = true;
                            this.removeLoadingScreen = true;
                        }),
                })
            )
            .subscribe({
                next: () => {
                    // This starts the fade-out animation
                    this.hideLoadingScreen = true;
                    setTimeout(() => {
                        // This actually removes the overlay component from the dom
                        this.removeLoadingScreen = true;
                    }, 2000);
                },
                error: error => {
                    console.log('Error listening to loadingProgress!', error);
                },
            });

        // All imported icons
        Object.keys(importedIcons).forEach(key => {
            this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(importedIcons[key]));
        });

        this.rpc.licenseExceeded.subscribe({
            next: (data: any) => {
                this.zone.run(() => {
                    this.dialog.open(LicenseExceededDialogComponent, {
                        data: {
                            trial: data,
                        },
                        width: '800px',
                    });
                });

                this.cdr.detectChanges();
            },
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
            next: () => {
                this.cdr.detectChanges();
            },
        });

        this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe({
            next: () => {
                new PersistentLog('update').write('app.component.ts: CheckForUpdate()');
                // TODO: App updating
                // this.worker.checkForUpdate();
            },
        });

        // Hacky version of connecting to custom backend.
        try {
            if (localStorage.getItem('debug')) {
                const port = parseInt(localStorage.getItem('port'), 10);

                if (port) {
                    environment.wsUrl = `https://localhost:${port}`;
                }
            }
        } catch (e) {}

        // Handle theme and language changes
        this.core.user.subscribe({
            next: personalSettings => {
                const newSelectedTheme = personalSettings?.globalSettings?.theme;
                if (personalSettings?.preferredLanguages?.[0] !== undefined) {
                    this.translocoService.setActiveLang(personalSettings?.preferredLanguages[0]);
                } else {
                    const storedLanguage = localStorage.getItem('selectedLanguage');
                    this.translocoService.setActiveLang(storedLanguage || 'en');
                }
                const activeTheme = this.theme?.themeName;

                if (newSelectedTheme === activeTheme) {
                    return;
                }

                this.theme.setTheme(newSelectedTheme || 'light');
            },
        });

        this.core.state.pipe().subscribe({
            next: state => {
                if (state === 'login') {
                    this.cdr.reattach();
                    this.cdr.detectChanges();

                    this.loadingProgress.hideLoadingScreen(0);
                } else if (state === 'authenticated') {
                    this.cdr.reattach();
                    this.cdr.detectChanges();

                    if (Capacitor.isNativePlatform()) {
                        // Route user with launch url if there is one
                        App.getLaunchUrl().then(launchUrl => {
                            if (launchUrl?.url) {
                                this.zone.run(() => this.router.navigateByUrl(this.getRouteFromUrl(launchUrl.url)));
                            }
                        });
                        this.pushNotifications.init();
                        return;
                    }

                    // Set the tags for the onboarding layer software Usetiful
                    this.setUsetifulTags();
                } else {
                    this.cdr.detach();
                }
            },
        });
    }

    ngAfterViewInit() {
        this.translate.translationLoaded('misc');
        setTimeout(() => {
            SplashScreen.hide();
        }, 250);
    }

    private checkingUpdate = false;
    /** Reads the version.json from cache and fetches the latest from the server, and reloads frontend if they are not the same */
    async checkForVersionAndUpdate(): Promise<void> {
        try {
            if (!this.connected.value || this.checkingUpdate) {
                return;
            }

            console.log('Checking for update.');

            this.checkingUpdate = true;

            const versionFile = 'version.json';
            const cache = await caches.open('version');

            const currentVersion = await (await cache.match(versionFile))?.json();
            const remoteVersionRequest = await fetch(versionFile);
            const remoteVersion = await remoteVersionRequest?.clone()?.json();

            if (!remoteVersion || currentVersion?.buildTime === remoteVersion.buildTime) {
                console.log('Missing remote version or no update available');
                this.checkingUpdate = false;
                return;
            }

            console.log('Current version:', currentVersion, 'remote version:', remoteVersion);

            await cache.put(versionFile, remoteVersionRequest);
            location.reload();
        } catch (error) {
            console.warn('Failed to check for update', error);
        }
    }

    /**
     * Used with the onboarding layer software Usetiful
     *
     * Sets Usetiful tags such as userId, tourIdentifier etc. dynamically
     *
     * Please note that wherever dot-notation is disabled for eslint is done since we can't set
     * the tags since they don't exist in Hailer frontend code but will be used by Usetiful in browser
     */
    setUsetifulTags() {
        // Check if tour is disabled, used with cypress tests
        const tourDisabled = localStorage.getItem('tourDisabled');
        if (tourDisabled) {
            console.warn('Onboarding tour has been disabled with a tourDisabled local storage variable');
            return;
        }

        /* Initialize usetifulTags as an empty object, tags are set dynamically later
           Dot notation can't be used since usetifulTags does not exist in code, though Usetiful script will use it in browser */
        // eslint-disable-next-line dot-notation
        window.usetifulTags = {};
        // eslint-disable-next-line dot-notation
        window.usetifulTags.isMobile = this.isMobile.toString(); // Set value before any window width changes happen

        // Get the empty script element from index.html, empty not to have the onboarding pop up if not logged in
        const usetifulScript = document.getElementById('usetifulScript') as HTMLScriptElement;

        /* Create the static, non-changing script here so that it's not created before the the tags are set.
           This has to be done like this so that the onboarding isn't triggered on login screen. */
        usetifulScript.text = `(function (w, d, s) {
            var a = d.getElementsByTagName('head')[0];
            var r = d.createElement('script');
            r.async = 1;
            r.src = s;
            r.setAttribute('id', 'usetifulScript');
            r.dataset.token = "cf12eb4455d5ffb7c150acb5aa9ef2ea";
            a.appendChild(r);
            })(window, document, "https://www.usetiful.com/dist/usetiful.js");
        `;
        document.body.appendChild(usetifulScript);

        /* The first userId needs to be waited for to be used with tags,
           make it into a promise that will be awaited later where userId is used */
        const userLoaded: Promise<string> = new Promise<string>(resolve =>
            this.core.user
                .pipe(
                    filter(user => user !== null),
                    take(1)
                )
                .subscribe({
                    next: (user: PersonalSettings) => {
                        resolve(user._id);
                    },
                })
        );

        // Dynamically update tags related to network using subscription
        this.core.network.pipe(filter(network => network !== null)).subscribe({
            next: async (network: Company) => {
                // Wait for the first userId to be fetched
                const userId = await userLoaded;
                // Used for checking the user's role in the network
                const me = network.members.find(member => member.uid === userId);

                // All possible user roles, array for easy extendability
                const userRoles = ['owner', 'admin', 'inviter', 'guest', 'user'];
                // Find the role of the user and default to 'user'
                const role = userRoles.find(userRole => me?.[userRole]) || 'user';

                /* See if onboardingType propertyfield exists, the user has it and send to Usetiful if those conditions are met
                   Multiple if blocks are needed to make sure everything works with Object.values and Object.keys */
                const propertyFields = network.propertyFields;
                if (propertyFields) {
                    const onboardingTypeField = Object.values(propertyFields.users).find(field => field.label === 'onboardingType');
                    // Do the following steps only if the onboardingType propertyField exists in the network
                    if (onboardingTypeField) {
                        const onboardingFieldId = onboardingTypeField._id;

                        const fields = network.members.find(user => user.uid === userId).fields;
                        /* Check if user has onboardingType field, if not, short-circuit execution,
                           if user has it send it's value to Usetiful */
                        if (fields && Object.keys(fields).find(field => field === onboardingFieldId)) {
                            // Find the index of onboardingType field
                            const index = Object.keys(fields).indexOf(onboardingFieldId);
                            // Find the value of onboardingType
                            const onboardingTypeValue = Object.values(fields)[index].toString();
                            // eslint-disable-next-line dot-notation
                            window.usetifulTags.onboardingType = onboardingTypeValue;
                        }
                    }
                }

                // Tags to identify the current network to Usetiful
                const networkId = network._id;
                const networkName = network.name;
                // Find the tourIdentifier (specific for network) set in Hailer admin panel, default to empty string if not found
                const tourIdentifier = network.license.override.tourIdentifier?.toString() || 'No tour identifier found!';

                // Set tags for Usetiful script, tags names into localstorage and their values into the tags
                const tags = { role, networkName, networkId, tourIdentifier };
                // eslint-disable-next-line dot-notation
                Object.entries(tags).forEach(([tagName, tagValue]) => (window.usetifulTags[tagName] = tagValue.toString()));
            },
        });

        // Dynamically update team tags, teams are sorted alphabetically. Subscription to update teams every time they change.
        this.core.teams.pipe(filter(teams => teams !== null)).subscribe({
            next: async () => {
                // Wait for the userId to exist
                const userId = await userLoaded;

                // Fetch all user's teams in this network
                const teams = this.teamService
                    .getUserTeams(userId)
                    .sort((t1, t2) => t1.name.toLowerCase().localeCompare(t2.name.toLowerCase()));

                const teamIds = teams.map(team => team._id);
                const teamNames = teams.map(team => team.name);

                // eslint-disable-next-line dot-notation
                teamIds.forEach((teamId, index) => (window.usetifulTags[`teamId${index}`] = teamId));

                // Set teamName tags dynamically
                // eslint-disable-next-line dot-notation
                teamNames.forEach((teamName, index) => (window.usetifulTags[`teamName${index}`] = teamName));
            },
        });

        // Dynamically update tags related to user using subscription
        this.core.user.pipe(filter(user => user !== null)).subscribe({
            next: (user: PersonalSettings) => {
                const userId = user._id;
                const userName = user.display_name;
                // Take user's preferredlanguage, default to English as a backup
                const language = user.preferredLanguages?.[0] || 'en';

                // Set tags for Usetiful script, tags names into localstorage and their values into the tags
                const tags = { userName, userId, language };

                // eslint-disable-next-line dot-notation
                Object.entries(tags).forEach(([tagName, tagValue]) => (window.usetifulTags[`${tagName}`] = tagValue.toString()));
            },
        });

        // Listen to window size and set the isMobile tag dynamically
        this.windowListener.size.pipe().subscribe({
            next: ({ x }) => {
                this.isMobile = x < 600;
                // eslint-disable-next-line dot-notation
                window.usetifulTags.isMobile = this.isMobile.toString();
                this.cdr.detectChanges();
            },
        });
    }

    initCapacitorApp() {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => this.router.navigateByUrl(this.getRouteFromUrl(event.url)));
        });
    }

    /**
     * Example url: https://app.hailer.com/#/discussions/611230785d5e9c046e54766c
     * would return '/discussions/611230785d5e9c046e54766c'
     */
    getRouteFromUrl(url: string) {
        const split = url.indexOf('#');
        url = url.slice(split + 1);
        return url;
    }
}
