import { useInsight } from "./use-insight";
import Tooltip from "./tooltip";
import ExportMenu from "./export-menu";
import FilterMenu from "./filters";

export default function TopBar() {
  const { api, show, filters, options, name } = useInsight();

  return <>

    <header className="insight-topbar">

      <Tooltip text={show.chart ? "Hide Chart" : "Show Chart"}>
        <button className={show.chart ? "insight-button insight-icon-button insight-button-toggled" : "insight-button insight-icon-button"} onClick={() => api.show.setChart(!show.chart)}>
          <span className="material-symbols-outlined">monitoring</span>
        </button>
      </Tooltip>
      <Tooltip text={show.pivot ? "Hide Pivot" : "Show Pivot"}>
        <button className={show.pivot ? "insight-button insight-icon-button insight-button-toggled" : "insight-button insight-icon-button"} onClick={() => api.show.setPivot(!show.pivot)}>
          <span className="material-symbols-outlined">pivot_table_chart</span>
        </button>
      </Tooltip>
      <Tooltip text={show.table ? "Hide Table" : "Show Table"}>
        <button className={show.table ? "insight-button insight-icon-button insight-button-toggled" : "insight-button insight-icon-button"} onClick={() => api.show.setTable(!show.table)}>
          <span className="material-symbols-outlined">data_table</span>
        </button>
      </Tooltip>

      <h1 className="insight-title">{name}</h1>

      <div className="insight-right-side-buttons">

      <div className="insight-search-wrapper">
        <input className="insight-input insight-search wide-insight-input" type="text" defaultValue={filters.search} placeholder="Search data..." onInput={(event: any) => api.filters.setSearch(event.target?.value)}></input>
        <span className="material-symbols-outlined insight-search-icon-topbar">search</span>
      </div>
      <FilterMenu />

      <ExportMenu />

      {options?.event?.refresh &&
        <Tooltip text="Refresh" position="right">
          <button className="insight-button insight-icon-button" onClick={() => { options.event?.refresh?.() }}>
            <span className="material-symbols-outlined">refresh</span>
          </button>
        </Tooltip>
      }

      </div>

    </header>
  </>
}
