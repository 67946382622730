/** @format */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { PersonalSettingsService } from 'app/_services/personal-settings.service';
import {
    loadPersonalSettings,
    loadPersonalSettingsSuccess,
    setGlobalSettings,
    setGlobalSettingsSuccess,
} from '../actions/personal-settings.actions';

@Injectable()
export class PersonalSettingsEffects {
    loadPersonalSettings = createEffect(() =>
        this.actions.pipe(
            ofType(loadPersonalSettings),
            mergeMap(() =>
                this.personalSettings.loadPersonalSettings().pipe(
                    map(personalSettings => loadPersonalSettingsSuccess({ personalSettings })),
                    catchError(error => {
                        console.error(error);
                        return EMPTY;
                    })
                )
            )
        )
    );

    setPersonalSettings = createEffect(() =>
        this.actions.pipe(
            ofType(setGlobalSettings),
            mergeMap(action =>
                this.personalSettings.setGlobalSettings(action.globalSettings).pipe(
                    map(() => setGlobalSettingsSuccess()),
                    catchError(error => {
                        console.error(error);
                        return EMPTY;
                    })
                )
            )
        )
    );

    constructor(private actions: Actions, private personalSettings: PersonalSettingsService) {}
}
