/** @format */

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as _moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { Calendar, Company, Event, PersonalSettings } from '@app/models';
import { EventsService } from 'app/events/events.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { CoreService } from 'app/_services/core.service';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';
import { TranslateService } from 'app/_services/translate.service';

@Component({
    selector: 'app-event-sidenav',
    templateUrl: './event-sidenav.component.html',
    styleUrls: ['./event-sidenav.component.scss'],
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'events', alias: 'events' } }],
})
export class EventSidenavComponent implements OnInit, OnDestroy {
    @Input() eventId?: string;
    @Input() create?: { allDay: boolean; date: any; calendarType: string };

    calendars: Calendar[] = [];
    action: string;
    event: Event = null;
    user: PersonalSettings;
    network: Company;
    eventNamePlaceholder: string;

    private onDestroy = new Subject<void>();

    constructor(
        private events: EventsService,
        private core: CoreService,
        public sideNav: SideNavService,
        private cdr: ChangeDetectorRef,
        private v3Discussion: V3DiscussionService,
        private translocoService: TranslocoService,
        private translateService: TranslateService
    ) {}

    async ngOnInit() {
        this.user = this.core.user.value;
        this.network = this.core.network.value;
        await this.translateService.translationLoaded('events');

        this.events.calendars.pipe(takeUntil(this.onDestroy)).subscribe({
            next: (calendars: Calendar[]) => (this.calendars = calendars),
        });

        // Hack until create is separated from view
        if (this.create) {
            this.translateService.translationLoaded('events').then(() => {
                this.eventNamePlaceholder = this.translocoService.translate('events.event_sidenav.new_event');
                this.prepareCreateEvent(this.create);
                return;
            });
        } else {
            this.loadEvent();

            this.events.eventReload.pipe(takeUntil(this.onDestroy)).subscribe({
                next: () => this.loadEvent(),
                error: (error: any) => console.error('eventReload error:', error),
            });

            this.v3Discussion.discussionsUpdated.pipe(takeUntil(this.onDestroy)).subscribe({
                next: discussions => {
                    if (discussions?.includes(this.event?.discussion)) {
                        this.loadEvent();
                    }
                },
            });

            this.core.network.pipe(takeUntil(this.onDestroy)).subscribe({
                next: (network: Company) => {
                    if (this.network._id !== network._id) {
                        this.sideNav.clear();
                    }
                },
            });
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    get calendar(): Calendar {
        const calendar = this.calendars.find((_calendar: Calendar) => _calendar._id === this.event.calendar_id);
        return calendar;
    }

    private loadEvent(): void {
        this.events.loadEvent(this.eventId).subscribe({
            next: (event: Event) => {
                this.prepareEventDetails(event);
            },
            error: (error: any) => {
                console.error('error in loadEvent:', error);
                this.event = null;
                this.action = 'error';
            },
        });
    }

    private prepareEventDetails(event: Event) {
        if (event) {
            this.action = 'view';
            this.event = event;
        } else {
            this.event = null;
        }
        this.cdr.detectChanges();
    }

    private prepareCreateEvent(model: { allDay: boolean; date: any; calendarType: string }) {
        this.action = 'create';
        const attendees = {};
        attendees[this.user.id] = null;

        const calendarId = this.calendars.length ? this.calendars[0]._id : null;
        let start;
        let end;
        if (!model.date?.start) {
            start = model.date;
        } else {
            start = model.date.start;
            end = model.date.end;
            const endMs = new Date(model.date.end).getTime();
            const dayInMs = 1000 * 60 * 60 * 24;
            if (model?.calendarType === 'dayGridMonth' || (model?.calendarType === 'timeGridWeek' && model?.allDay)) {
                end = new Date(endMs - dayInMs).toISOString();
            }
        }
        const allDay = model?.allDay;

        if (model?.allDay && !model?.date?.end) {
            end = start;
        } else if (!model?.allDay && !model?.date?.end) {
            const startMs = new Date(start).getTime();
            // End is one hour after start
            end = new Date((startMs / 1000 + 1 * 3600) * 1000).toISOString();
        }
        this.event = new Event({
            attendees,
            calendar_id: calendarId,
            end,
            start,
            title: this.eventNamePlaceholder,
            allDay,
        });
    }
}
