/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { User } from '@app/models';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';
import { PeopleService } from 'app/people/people.service';
import { SideNavService } from 'app/_services/side-nav.service';

@Component({
    selector: 'app-user-link',
    templateUrl: './user-link.component.html',
    styleUrls: ['./user-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class UserLinkComponent implements OnInit, OnChanges, OnDestroy {
    @Input() userId: string;
    @Input() whiteLink: boolean;
    /**
	'darkLink' darkens the link color for sufficient contrast with white or light gray background for accessibility reasons. Should be used only in light theme. 
	 */
    @Input() darkLink: boolean;
    /**
	'inheritLinkSize' sets the font-size to 'inherit'.
	 */
    @Input() inheritLinkSize: boolean;
    /**
	'lightFocus' sets the focus-outline color to a lighter variable for it to be clearly visible in user's own messages (dark background). Can be used for all themes as color changes only in light theme.
	 */
    @Input() lightFocus: boolean;
    @Input() clearStack = true;
    @Input() ownName = false;
    @Input() color = '';

    user: User;

    private onDestroy = new Subject<void>();

    constructor(private sideNavService: SideNavService, private people: PeopleService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.user = this.people.getUser(this.userId);

        this.people.unknownUsers.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.user = this.people.getUser(this.userId);
                this.cdr.detectChanges();
            },
        });
    }

    openUserSidebar(): void {
        if (this.clearStack) {
            this.sideNavService.clear();
        }

        this.sideNavService.create(UserDetailComponent, {
            userId: this.userId,
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userId && changes.userId.currentValue) {
            this.user = this.people.getUser(this.userId);
            this.cdr.detectChanges();
        }

        if (changes.color && changes.color.currentValue) {
            this.color = changes.color.currentValue;
            this.cdr.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }
}
