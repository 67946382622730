/** @format */

export class TeamAccount {
    account: string;
    team: string;

    constructor(teamAccountData?: any) {
        if (teamAccountData) {
            this.deserialize(teamAccountData);
        }
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
