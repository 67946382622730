<div class="network-field-container">
    <div class="network-field">
        <ng-container *ngIf="field" [ngSwitch]="field.type" [formGroup]="fieldsFormGroup">

            <ng-container *ngSwitchCase="'text'">
                <div class="fill-field-container">
                    <mat-form-field class="full-width" appearance="fill">
                        <textarea matInput type="text" formControlName="text" rows="2" [placeholder]="field.label"></textarea>
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'date'">
                <div class="fill-field-container">
                    <mat-form-field class="full-width" appearance="fill">
                        <input matInput [owlDateTimeTrigger]="date" [owlDateTime]="date" [placeholder]="field.label" formControlName="date">
                        <owl-date-time [pickerType]="'calendar'" firstDayOfWeek="1" #date></owl-date-time>
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'phone'">
                <div class="fill-field-container">
                    <mat-form-field class="full-width" appearance="fill">
                        <input matInput formControlName="phone" [placeholder]="field.label">
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'email'">
                <div class="fill-field-container">
                    <mat-form-field class="full-width" appearance="fill">
                        <input matInput type="email" formControlName="email" [placeholder]="field.label">
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'url'">
                <div class="fill-field-container">
                    <mat-form-field class="full-width" appearance="fill">
                        <input matInput type="url" formControlName="url" [placeholder]="field.label">
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'team'">
                <div class="fill-field-container">
                    <app-team-user-selector *ngIf="teamSelectorOptions" [options]="teamSelectorOptions" [predefinedItems]="teamSelectorPredefinedItems" (results)="selectTeams($event)"
                    class="form-field-fix invitation-team-selector"></app-team-user-selector>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'country'">
                <div class="fill-field-container">
                    <app-country-selector *ngIf="countrySelectorOptions" [options]="countrySelectorOptions"
                        (resultsWhenClosed)="selectCountry($event)">
                    </app-country-selector>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'optionlist'">
                <div class="fill-field-container">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-select [placeholder]="field.label" formControlName="optionlist" [multiple]="field.enableMultiple || false">
                            <ng-container *ngFor="let option of field.data">
                                <mat-option [value]="option">
                                    {{option}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
                {{field.type}}
            </ng-container>
        </ng-container>
    </div>
    <div class="network-field-buttons">
        <button mat-icon-button (click)="clearField()" color="warn">
            <mat-icon>clear</mat-icon>
        </button>
        <button mat-icon-button (click)="saveField()" color="accent">
            <mat-icon>done</mat-icon>
        </button>
    </div>
</div>