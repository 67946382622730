/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@leeviviertola/date-time-picker';

import { SharedModule } from '@app/shared/shared.module';
import { NetworkSettingsInputFieldsComponent } from './network-settings-input-fields/network-settings-input-fields.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { DiscussionSharedModule } from 'app/discussion-shared/discussion-shared.module';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { ButtonsModule } from 'app/buttons/buttons.module';

@NgModule({
    declarations: [UserDetailComponent, NetworkSettingsInputFieldsComponent],
    imports: [CommonModule, SharedModule, OwlDateTimeModule, OwlNativeDateTimeModule, DiscussionSharedModule, TranslocoRootModule, ButtonsModule],
    exports: [UserDetailComponent],
})
export class PeopleSharedModule {}
