<!-- @format -->

<div id="content" class="image-viewer-content" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="main-container" *ngIf="image" (click)="onClickBackground($event)">
        <div *ngIf="images.length > 1 && !inMobileView" class="desktop-controls">
            <button
                mat-icon-button
                class="control center-icon-button"
                *ngIf="previousEnabled"
                (click)="previousImage()"
                attr.aria-label="{{ 'shared.image-viewer.go-previous' | transloco }}">
                <mat-icon class="high-contrast-icon hailer-chevron left" svgIcon="hailer-chevron"></mat-icon>
            </button>
        </div>

        <div class="media-modal-wrapper">
            <div class="media-modal">
                <div class="media-container">
                    <a
                        tabindex="-1"
                        *ngIf="!image.isVideo; else video"
                        class="image-link flex"
                        [href]="image.original || image.url"
                        target="_blank">
                        <img
                            crossorigin="use-credentials"
                            data-cy="image-viewer-image"
                            [src]="
                                image.isPicture || image.type === 'image/svg+xml' || image.type === 'image/gif' || image.isGif
                                    ? image.url
                                    : filesService.getFileThumbnail(image, 'hires')
                            "
                            (load)="onLoaded()"
                            class="media"
                            alt="{{ image.name }}" />
                    </a>

                    <ng-template #video>
                        <video
                            tabindex="0"
                            *ngIf="image.type !== 'video/quicktime'"
                            attr.aria-label="{{ 'shared.image-viewer.video-file' | transloco }} {{ image.name }} {{
                                'shared.image-viewer.play-file' | transloco
                            }}"
                            [src]="image.url"
                            (loadeddata)="onLoaded()"
                            class="media"
                            controls
                            controlsList="nodownload"></video>

                        <div *ngIf="image.type === 'video/quicktime'" class="video-error flex-column-centered">
                            <h2 tabindex="0">{{ 'shared.image-viewer.video-error' | transloco }}</h2>
                        </div>
                    </ng-template>
                </div>

                <div class="footer">
                    <span class="filename">{{ image.name }}</span>
                    <div class="popup-two-buttons-wrapper">
                        <app-button
                            [type]="inMobileView ? 'icon' : 'text-with-icon'"
                            size="medium"
                            color="tertiary-default-background"
                            hailerIconName="hailer-download"
                            label="{{ 'shared.image-viewer.download' | transloco }}"
                            ariaLabel="{{ 'shared.image-viewer.download' | transloco }} {{ image.name }}"
                            (clickEvent)="filesService.download(image)">
                        </app-button>
                        <app-button
                            [type]="inMobileView ? 'icon' : 'text-with-icon'"
                            size="medium"
                            [link]="image.original || image.url"
                            target="_blank"
                            color="tertiary-default-background"
                            hailerIconName="hailer-open-new-window"
                            label="{{ 'shared.image-viewer.open_file' | transloco }}"
                            ariaLabel="{{ 'shared.image-viewer.open_file' | transloco }} {{ image.name }}">
                        </app-button>
                    </div>
                </div>

                <app-button
                    type="icon"
                    size="medium"
                    color="icon-white-all-themes"
                    (clickEvent)="closeViewer()"
                    hailerIconName="hailer-x"
                    ariaLabel="{{ 'shared.image-viewer.close-aria' | transloco }}"
                    tippyText="{{ 'shared.image-viewer.close' | transloco }}"
                    class="close-button center-icon-button"></app-button>
            </div>

            <div *ngIf="images.length > 1 && inMobileView" class="mobile-controls">
                <app-button
                    *ngIf="previousEnabled"
                    type="icon"
                    size="medium"
                    color="tertiary-default-background"
                    hailerIconName="hailer-chevron"
                    matIconClass="hailer-chevron left"
                    (clickEvent)="previousImage()"
                    ariaLabel="{{ 'shared.image-viewer.go-previous' | transloco }}"
                    class="mobile-control-left"></app-button>
                <app-button
                    *ngIf="nextEnabled"
                    type="icon"
                    size="medium"
                    color="tertiary-default-background"
                    hailerIconName="hailer-chevron"
                    matIconClass="hailer-chevron right"
                    (clickEvent)="nextImage()"
                    ariaLabel="{{ 'shared.image-viewer.go-next' | transloco }}"
                    class="mobile-control-right"></app-button>
            </div>
        </div>

        <div *ngIf="images.length > 1 && !inMobileView" class="desktop-controls">
            <button
                mat-icon-button
                class="control center-icon-button"
                *ngIf="nextEnabled"
                (click)="nextImage()"
                attr.aria-label="{{ 'shared.image-viewer.go-next' | transloco }}">
                <mat-icon class="high-contrast-icon hailer-chevron right" svgIcon="hailer-chevron"></mat-icon>
            </button>
        </div>
    </div>
</div>
