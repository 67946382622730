/** @format */

import { Component, EventEmitter, Inject, InjectionToken, OnInit, Output, ViewChild } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { FilesService } from 'app/_services/files.service';
import { ViewerImage } from 'app/_services/image-viewer.service';

export const IMAGE_DATA = new InjectionToken<any>('ImageData');

@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class ImageViewerComponent implements OnInit {
    @ViewChild('container', { static: false }) container;
    @Output() afterClosed = new EventEmitter<void>();

    images: ViewerImage[] = [];
    image;
    loading = false;
    previousEnabled = false;
    nextEnabled = false;

    private selectedImageIndex = 0;

    constructor(@Inject(IMAGE_DATA) public data: { images: ViewerImage[]; current: number }, public filesService: FilesService) {}

    onClickBackground($event) {
        if ($event.currentTarget === $event.target) {
            this.closeViewer();
        }
    }

    ngOnInit() {
        document.body.style.overflow = 'hidden';
        this.images = this.data?.images || [];
        this.selectedImageIndex = this.data?.current || 0;
        this.openImage(this.images[this.selectedImageIndex]);

        document.addEventListener('keydown', event => {
            switch (event.code) {
                case 'KeyA':
                case 'ArrowLeft':
                    this.previousImage();
                    break;
                case 'KeyD':
                case 'ArrowRight':
                    this.nextImage();
                    break;
                case 'Escape':
                    this.closeViewer();
                    break;
                default:
                    break;
            }
        });
    }

    closeViewer() {
        document.body.style.overflow = 'auto';
        this.image = null;
        this.afterClosed.emit();
    }

    onLoaded() {
        this.loading = false;
    }

    previousImage() {
        if (!this.previousEnabled) {
            return;
        }

        if (--this.selectedImageIndex < 0) {
            this.selectedImageIndex = this.images.length - 1;
        }

        this.openImage(this.images[this.selectedImageIndex]);
    }

    nextImage() {
        if (!this.nextEnabled) {
            return;
        }
        this.selectedImageIndex = (this.selectedImageIndex + 1) % this.images.length;
        this.openImage(this.images[this.selectedImageIndex]);
    }

    get inMobileView(): boolean {
        return window.innerWidth < 600;
    }

    private updateControls() {
        this.previousEnabled = this.selectedImageIndex > 0;
        this.nextEnabled = this.selectedImageIndex + 1 < this.images.length;
    }

    private openImage(image) {
        // Set loading if the url is not same.
        if (!this.image || this.image.url !== image.url) {
            this.loading = true;
        }
        this.image = image;
        this.updateControls();
    }
}
