/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { Subject, debounceTime, filter, takeUntil } from 'rxjs';

import { V3ActivityViewContextService } from 'app/v3-activity/v3-activity-view-context.service';
import { ActivityInitFieldValues, LinkedFromGroup } from 'app/_models/v3-activity.model';
import { SideNavService } from 'app/_services/side-nav.service';
import { V3ActivitySidenavComponent } from '../v3-activity-sidenav.component';

@Component({
    selector: 'app-v3-activity-linked',
    templateUrl: './v3-activity-linked.component.html',
    styleUrls: ['./v3-activity-linked.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class V3ActivityLinkedComponent implements OnInit, OnDestroy {
    @ViewChild(MatAccordion) linkedFromAccordion: MatAccordion;

    searchForm = new FormControl();
    expandedPanels: string[] = [];
    linkedFromWorkflowIds: string[] = [];

    private onDestroy = new Subject<void>();
    constructor(public viewContext: V3ActivityViewContextService, private cdr: ChangeDetectorRef, private sideNav: SideNavService) {}

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static redrawCount = 0;
    redraw() {
        V3ActivityLinkedComponent.redrawCount += 1;
        // Console.log('V3ActivityLinkedComponent view redrawed: ' + V3ActivityLinkedComponent.redrawCount + ' times');
    }

    ngOnInit(): void {
        this.viewContext.linkedFromGroup.pipe(takeUntil(this.onDestroy)).subscribe({
            next: group => {
                this.linkedFromWorkflowIds = Object.keys(group || {});
                this.cdr.detectChanges();
            },
        });

        this.viewContext.currentTab.pipe(takeUntil(this.onDestroy)).subscribe({
            next: tab => {
                if (tab === 'linkedFrom' || !this.viewContext.highlightLinkedFromPhase.value) {
                    return;
                }

                const element = document.getElementById(this.viewContext.highlightLinkedFromPhase.value);
                if (!element) {
                    return;
                }

                element.scrollIntoView();
                const elementClass = element.getAttribute('class') || '';
                element.setAttribute('class', `${elementClass} focused-phase`);
                this.cdr.detectChanges();

                setTimeout(() => {
                    element.setAttribute('class', elementClass);
                    this.viewContext.highlightLinkedFromPhase.next(null);
                    this.cdr.detectChanges();
                }, 2000);
            },
        });

        this.searchForm.valueChanges.pipe(takeUntil(this.onDestroy), debounceTime(500)).subscribe({
            next: searchString => this.viewContext.searchLinkedActivities(searchString),
        });
    }

    ngOnDestroy(): void {
        if (this.searchForm.value) {
            this.viewContext.searchLinkedActivities();
        }

        this.onDestroy.next();
        this.onDestroy.complete();
    }

    searchStringMatch(match: string): boolean {
        const searchString = this.searchForm.value?.toLocaleLowerCase().trim() || '';
        if (!searchString) {
            return true;
        }

        return !!match.toLocaleLowerCase().trim().includes(searchString);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    trackById(index: number, item: any) {
        if (item.processId) {
            return item.processId;
        }

        if (item.phaseId) {
            return item.phaseId;
        }

        return item._id;
    }

    get canCreateInWorkflowIds(): string[] {
        return this.linkedFromWorkflowIds.filter(workflowId => this.viewContext.canCreateLinkedActivityInFirstPhase(workflowId));
    }

    openActivityCreate(linkedFromWorkflow: LinkedFromGroup, fieldId?: string) {
        if (!linkedFromWorkflow) {
            return void console.error('Failed to open activity create!');
        }

        if (!fieldId) {
            const firstPhaseLinkedFields = linkedFromWorkflow.linkableFields.filter(id =>
                this.viewContext.isFieldInPhase(linkedFromWorkflow._id, id)
            );

            fieldId = firstPhaseLinkedFields[0];
        }

        const initFieldValues: ActivityInitFieldValues | undefined = fieldId
            ? {
                  [fieldId]: {
                      value: {
                          name: this.viewContext.activity.value?.name,
                          _id: this.viewContext.activity.value?._id,
                      },
                  },
              }
            : undefined;

        this.sideNav.create(V3ActivitySidenavComponent, {
            action: 'create',
            processId: linkedFromWorkflow._id,
            initFieldValues,
            created: () => this.sideNav.pop(),
        });
    }

    processExpanded(processId: string) {
        if (this.expandedPanels.includes(processId)) {
            return;
        }

        this.expandedPanels.push(processId);
    }

    processCollapsed(processId: string) {
        this.expandedPanels = this.expandedPanels.filter(id => id !== processId);
    }

    collapseAll() {
        this.expandedPanels = [];
        this.viewContext.toggleLinkedPhasePanels.next('collapse');
        this.linkedFromAccordion?.closeAll();
    }

    expandAll() {
        this.expandedPanels = Object.keys(this.viewContext.linkedFromGroup.value);
        this.linkedFromAccordion?.openAll();
        this.viewContext.toggleLinkedPhasePanels.next('expand');
    }

    hasActivities(workflowId: string): boolean {
        return !!this.viewContext.linkedFromGroup.value?.[workflowId]?.activityCount;
    }
}
