/** @format */

import { Injectable } from '@angular/core';
import { DateTimeAdapter } from '@leeviviertola/date-time-picker';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, filter, take, throwError, timeout } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TranslateService {
    supportedLanguages = ['en', 'fi', 'sv'];

    private loadedScopes = new BehaviorSubject<{ [language: string]: string[] }>({});

    constructor(private translocoService: TranslocoService, private dateTimeAdapter: DateTimeAdapter<any>) {
        this.translocoService.events$.pipe(filter(event => event.type === 'translationLoadSuccess')).subscribe({
            next: event => {
                const scope = event.payload.scope;
                if (!scope) {
                    return;
                }

                const scopeLangs = this.loadedScopes.value[scope] ?? [];

                if (scopeLangs.includes(event.payload.langName)) {
                    return;
                }

                scopeLangs.push(event.payload.langName);
                this.loadedScopes.next({ ...this.loadedScopes.value, [scope]: scopeLangs });
            },
        });

        this.translocoService.langChanges$.subscribe({
            next: locale => this.dateTimeAdapter.setLocale(locale || 'en'),
        });
    }

    translationLoaded(scope: string): Promise<boolean> {
        const currentLanguage = this.translocoService.getActiveLang();
        const loadedScopeLanguages = this.loadedScopes.value[scope];

        return new Promise(resolve => {
            if (!currentLanguage) {
                console.error(`Tried to load falsy language, has the active language been set?
                    Tried to load language: ${currentLanguage}
                    Loaded languages: ${loadedScopeLanguages}`);
                return;
            }

            if (loadedScopeLanguages?.includes(currentLanguage)) {
                resolve(true);
                return;
            }

            const scopeLoadTimeout = 5000;

            // This forcefully loads a scope in transloco, yes, the pipe and subscribe are neccessary, no, I don't know why -Patrik
            this.translocoService.selectTranslation(`${scope}/${currentLanguage}`).pipe(take(1)).subscribe();

            // We listen for the new scopes until our requested scope is loaded
            this.loadedScopes
                .pipe(
                    filter(() => !!this.loadedScopes.value[scope]?.includes(currentLanguage)),
                    take(1),
                    timeout({
                        each: scopeLoadTimeout,
                        with: () =>
                            throwError(() => {
                                console.warn('Failed to load translations for scope:', scope);
                                resolve(true);
                            }),
                    })
                )
                .subscribe({
                    next: () => {
                        resolve(true);
                    },
                    error: error => {
                        console.error('Error when listening to scope load: ', error);
                        resolve(true);
                    },
                });
        });
    }

    reloadTranslations(): Promise<void> {
        return new Promise(resolve => {
            const loadedScopes = Object.keys(this.loadedScopes.value);
            const promises: Promise<boolean>[] = [];
            for (const loadedScope of loadedScopes) {
                promises.push(this.translationLoaded(loadedScope));
            }

            Promise.allSettled(promises).finally(() => {
                resolve();
            });
        });
    }
}
