<!-- @format -->

<div>
    <div class="edit-title">
        <h1>{{ 'apps.configuration.configure' | transloco }} {{ app?.name }}</h1>
    </div>
    <form id="app-update-form" [formGroup]="appPermissions">
        <p>{{ 'apps.configuration.fields' | transloco }}:</p>
        <div *ngFor="let name of fields">
            <div class="flex-column--centered">
                <mat-form-field appearance="standard" class="form-field">
                    <mat-label>{{ name }}</mat-label>
                    <mat-hint align="start">type: {{ app.manifest?.config?.fields?.[name]?.type || 'string' }}</mat-hint>
                    <mat-hint align="end">{{ app.manifest?.config?.fields?.[name]?.required ? 'Required' : '' }}</mat-hint>
                    <input
                        *ngIf="fieldControls[name]"
                        data-cy="app-form-url-input"
                        [matTooltip]="app.manifest?.config?.fields?.[name]?.description || ''"
                        matInput
                        [appAutofocus]="true"
                        [formControl]="fieldControls[name]!"
                        type="url"
                        class="form-control" />
                </mat-form-field>
            </div>
        </div>
        <div class="button-container">
            <app-button data-cy="app-config-save-button" type="save" size="medium" (clickEvent)="onSubmitConfig()"></app-button>
            <app-button type="cancel" size="medium" (clickEvent)="close()"></app-button>
        </div>
    </form>
</div>
