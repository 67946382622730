<div class="invite-header">
	<div class="header-title-container">
		<h3 class="header-title">{{'invitepage.invite_people_to' | transloco}}</h3>
		<h3 class="header-network-name">{{ (network | async)?.name }}</h3>
	</div>
	<button data-cy="close-invite-dialog-button" mat-icon-button (click)="closeDialog()">
		<mat-icon>clear</mat-icon>
	</button>
</div>
<div *ngIf='(state |async) === "login"'>
	<h3 style="padding-left: 16px;">
		{{'invitepage.login_view_error' | transloco}}
	</h3>
</div>
<div *ngIf='(state |async) === "authenticated"' class="body-wrapper">
	<div style="padding: 8px;" *ngIf="(user | async)?.emailVerified === false">
		<h3 data-cy="validate-email-text">
			{{'invitepage.validate_email_error' | transloco}}
		</h3>
		<button *ngIf="!(sendingResendEmail | async); else sendingSpinner" (click)="resendValidationEmail()" color="primary"
			mat-stroked-button>
			{{'invitepage.resend_validation_email' | transloco}}
		</button>
		<ng-template #sendingSpinner>
			<mat-spinner [diameter]="24"></mat-spinner>
		</ng-template>
	</div>
	<div *ngIf="(user | async)?.emailVerified !== false">
		<div *ngIf="page === 'invite'">
			<div class="dialog-content">
				<form class="form-container" [formGroup]="inviteForm">
					<div class="fill-field-container">
						<p class="input-field-header">{{'invitepage.email' | transloco}}</p>
						<mat-form-field appearance="fill" class="form-field" style="width: 100%">
							<mat-icon class="form-prefix"
								[ngClass]="{'form-prefix-red': (inviteForm.controls.email.invalid && inviteForm.controls.email.touched)}"
								matPrefix>person_outline</mat-icon>
							<input data-cy="user-invite-email-input" matInput formControlName="email" type="email" placeholder="{{'invitepage.add_email' | transloco}}"
								[appAutofocus]="true" appAutofocusDelay="250" [ngClass]="{'is-invalid': inviteForm.controls.email.errors }"
								class="form-control email-input">
							<mat-error data-cy="invalid-email-error-message" *ngIf="inviteForm.controls.email.invalid && inviteForm.controls.email.touched">
								<p class="enter-valid-email">{{'invitepage.enter_valid_email_error' | transloco}}</p>
							</mat-error>
						</mat-form-field>
					</div>

					<div class="fill-field-container">
						<p class="input-field-header">{{'invitepage.title_position_in' | transloco}}{{ (network | async)?.name }}</p>
						<mat-form-field appearance="fill" class="form-field" style="width: 100%">
							<mat-icon class="form-prefix" matPrefix>work</mat-icon>
							<input data-cy="title-input" matInput formControlName="title" type="text" placeholder="{{'invitepage.add_title' | transloco}}"
								class="form-control title-input">
							<mat-error>
								<p class="enter-valid-email">{{'invitepage.enter_max_characters' | transloco}}</p>
							</mat-error>
						</mat-form-field>
					</div>

					<div class="container flex-field">
						<div *ngIf="inviteForm.controls['role'].value !== 'guest'" class="fill-field-container">
							<p class="input-field-header">{{'invitepage.teams' | transloco}}</p>
							<app-team-user-selector [options]="userSelectorOptions" (results)="parseUserSelector($event)"
								class="full-width form-field-fix team-selector"></app-team-user-selector>
						</div>
						<div class="fill-field-container">
							<p class="input-field-header">{{'invitepage.role' | transloco}}</p>
							<app-workspace-permission-selector (selectedRole)="selectInvitationRole($event)">
							</app-workspace-permission-selector>
						</div>
					</div>

                        <div class="fill-field-container">
                            <br>
                            <p class="input-field-header">{{'invitepage.message_to_user' | transloco}}</p>
                            <mat-form-field appearance="fill" class="form-field" style="width: 100%">
                                <textarea data-cy="message-input" matInput placeholder="{{'invitepage.add_message_to_user' | transloco}}"
                                            name="notes"
                                            formControlName="notes"
                                            rows="2" class="form-control">
                                </textarea>
						</mat-form-field>
					</div>

					<button data-cy="send-invite-button" mat-flat-button
						[disabled]="!inviteForm.controls.email.valid || (inviteForm.controls.role.value !== 'guest' &&  !inviteForm.value.teams?.length)"
						(click)="invite(inviteForm.controls.email.value)" color="accent" class="invite-button">{{'invitepage.invite' |
						transloco}}</button>
				</form>
				<mat-divider *ngIf="permissions.isNetworkInviter && invitations">
				</mat-divider>
				<div *ngIf="permissions.isNetworkInviter" class="users-container">
					<span class="invited-users-header" *ngIf="invitations">{{'invitepage.invited_users' | transloco}}</span>
					<div class="invited-user-container" *ngFor="let invitation of invitations">
						<button class="clear-invited-user" mat-icon-button color="warn" (click)="removeInvitation(invitation)">
							<mat-icon>clear</mat-icon>
						</button>
						<div class="text-container">
							<div class="text-info">
								<p class="title">{{'invitepage.email' | transloco}}:</p>
								<p class="info">{{ invitation.email }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>