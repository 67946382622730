/** @format */

import { Injectable } from '@angular/core';

import { CoreService } from 'app/_services/core.service';
import { RPCService } from 'app/_services/rpc.service';
import { environment } from '@app/env';

declare const $0: any;
declare const window: any;

@Injectable({
    providedIn: 'root',
})
export class DevService {
    environment: any;
    verifyLoggedEmail: any;

    constructor(private rpcService: RPCService, private core: CoreService) {
        // Set debug mode if localStorage contains a debug key
        try {
            if (localStorage.getItem('debug')) {
                console.log('Hailer is running in debug mode. See global variable "hlr". To disable: localStorage.removeItem("debug")');
                window.rpc = this.rpcService;
                window.probe = this.probe.bind(this);
                window.probeSelected = this.probeSelected;
                window.hlr = this;
                window.core = this.core;
                this.environment = environment;
            }
        } catch (e) {
            console.error('Dev service failed to initalize', e);
        }
    }

    testFunction() {
        console.log('Test');
    }

    private devFind(comp) {
        if (window.ng) {
            if (document.getElementsByTagName(comp).length >= 1) {
                return document.getElementsByTagName(comp)[0];
            }
            console.error(`No ngComponents found with name: ${comp}`);
        } else {
            console.error('This site does not run angular :(');
        }
    }

    private probe(comp) {
        if (window.ng) {
            return window.ng.probe(this.devFind(comp)).componentInstance;
        }
        console.error('No angular present..');
    }

    private probeSelected() {
        if (window.ng) {
            return window.ng.probe($0).componentInstance;
        }
        console.error('No angular present..');
    }
}
