<ng-container *ngIf="canBeLinkedTo.length === 0">
    <mat-icon>error_outline</mat-icon>
</ng-container>
<ng-container *ngIf="canBeLinkedTo.length === 1">
    <button (click)="createLinkedActivity(canBeLinkedTo[0]._id)" class="no-button for-icon add-linked-activity-button">
        <mat-icon svgIcon="hailer-plus"></mat-icon>
    </button>
</ng-container>
<ng-container *ngIf="canBeLinkedTo.length > 1">
    <button [matMenuTriggerFor]="shoppingCartMenu" class="no-button for-icon add-linked-activity-button">
        <mat-icon svgIcon="hailer-plus"></mat-icon>
    </button>
    <mat-menu #shoppingCartMenu="matMenu">
        <span class="link-activity-menu-header">{{ 'activities-shared.create-linked-activity.menu_header' | transloco }}</span>
        <button (click)="createLinkedActivity(process._id)" *ngFor="let process of canBeLinkedTo" mat-menu-item>{{ process.name }}</button>
    </mat-menu>
</ng-container>
