/** @format */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, skip, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { CoreService } from 'app/_services/core.service';
import { ApiNotificationService } from 'app/_services/notification.service';
import { Company, CompanyMap, Notification, PersonalSettings } from '@app/models';
import { NotificationComponent } from '../notification/notification.component';
import { HelperService } from 'app/_services/helper.service';
import { NewNetworkDialogComponent } from '../new-network-dialog/new-network-dialog.component';
import { PermissionService } from 'app/_services/permission.service';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    selector: 'app-top-toolbar',
    templateUrl: './top-toolbar.component.html',
    styleUrls: ['./top-toolbar.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopToolbarComponent implements OnInit, OnDestroy {
    @ViewChild('notificationContainer', { read: ElementRef, static: false }) notificationContainer: ElementRef;
    @ViewChildren('notificationComponent') notificationComponents: QueryList<NotificationComponent>;

    notificationBadgeColor = 'warn';
    verifyEmailNotification: Notification;
    verifyEmailUnreadCount = 0;
    networksLogo: string;
    isManagedUser: boolean;

    notifications: BehaviorSubject<Notification>;
    notificationUnreadCount: BehaviorSubject<number>;
    user: BehaviorSubject<PersonalSettings>;
    networks: Observable<Company[]>;
    connected = new BehaviorSubject<boolean>(true);
    reconnecting = new BehaviorSubject<boolean>(false);

    private onDestroy = new Subject<void>();

    constructor(
        private zone: NgZone,
        private notificationService: ApiNotificationService,
        private cdr: ChangeDetectorRef,
        private helpers: HelperService,
        private matDialog: MatDialog,
        public core: CoreService,
        public permissions: PermissionService,
        private rpc: RPCService
    ) {}

    ngOnInit() {
        this.user = this.core.user;
        // Double negation casts to either true or false, used in .html
        this.isManagedUser = !!this.core.user.value.managedUser;
        this.notifications = this.notificationService.notifications;
        this.notificationUnreadCount = this.notificationService.notificationsUnreadCount;

        this.core.connected
            .pipe(
                skip(1),
                debounceTime(5000),
                filter(() => !this.rpc.browserInactive)
            )
            .subscribe({
                next: () => {
                    setTimeout(() => {
                        const connected = this.core.connected.getValue();
                        this.reconnecting.next(!connected);
                    }, 10000);
                },
            });

        this.core.connected.pipe(skip(1), debounceTime(2500)).subscribe({
            next: con => {
                this.connected.next(con);
                this.cdr.detectChanges();
            },
        });

        this.core.user.pipe(takeUntil(this.onDestroy)).subscribe({
            next: user => {
                this.verifyEmailUnreadCount = user.emailVerified ? 0 : 1;
                this.emailVerified();
                this.cdr.detectChanges();
            },
        });

        this.networks = this.core.networks.pipe(
            map((networks: CompanyMap) => Object.values(networks).sort(this.helpers.sortAlphabetically('name'))),
            takeUntil(this.onDestroy)
        );

        this.core.network.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.networksLogo = this.network.name.substring(0, 1).toUpperCase();
                this.cdr.detectChanges();
            },
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    onScroll() {
        const el = this.notificationContainer.nativeElement;
        if (el.clientHeight + el.scrollTop >= el.scrollHeight) {
            this.loadMoreNotifications();
        }
    }

    markAllNotificationsRead() {
        this.notificationService.markAllNotificationsRead();
        this.notificationComponents.forEach(notification => notification.markAsRead());
        this.verifyEmailUnreadCount = 0;
    }

    markAllNotificationsSeen() {
        this.notificationService.markAllNotificationsSeen();
    }
    loadMoreNotifications() {
        this.notificationService.loadMoreNotifications();
    }

    get currentNetworkId() {
        return this.core.network.value._id;
    }

    newNetwork() {
        this.zone.run(() => {
            this.matDialog.open(NewNetworkDialogComponent, { panelClass: 'dialog-padding' });
        });
    }

    get network(): Company {
        return this.core.network.value;
    }

    get userPermissions(): Observable<any> {
        return this.core.user.pipe(map(u => u.permissions));
    }

    get debugModeEnabled() {
        return !!localStorage.getItem('debug');
    }

    private emailVerified() {
        const uid = this.core.user.value.id;
        const cid = this.core.network.value._id;

        this.verifyEmailNotification = {
            _id: '',
            uid,
            type: 'hailer.verifyEmail',
            cid,
            clicked: false,
            seen: false,
            created: Date.now(),
            from: uid,
            meta: {
                company: {
                    description: 'Hailer',
                },
            },
        };
    }
}
