/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@app/shared/shared.module';
import { ForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';
import { HailerLogoComponent } from './hailer-logo/hailer-logo.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { DevelopmentPageComponent } from './development-page/development-page.component';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { LoginTokenComponent } from './login-token/login-token.component';
import { EntraLoginPageComponent } from './entra-login-page/entra-login-page.component';

@NgModule({
    declarations: [
        ForgotPasswordPageComponent,
        HailerLogoComponent,
        LoginFormComponent,
        LoginPageComponent,
        PageNotFoundComponent,
        RegisterFormComponent,
        RegisterPageComponent,
        DevelopmentPageComponent,
        LoginTokenComponent,
        EntraLoginPageComponent,
    ],
    imports: [CommonModule, RouterModule, SharedModule, TranslocoRootModule],
    exports: [HailerLogoComponent, RegisterFormComponent],
})
export class PublicModule {}
