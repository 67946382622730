/** @format */

import { ErrorHandler, Injectable } from '@angular/core';

declare const insightRUM: any;

/**
 * Drop-in replacement for ErrorHandler
 *
 * If Hailer is run in debug mode use the regular error handler to get proper template errors etc.
 * otherwise try to push errors to Site24 via this reverse engineered call to onerrors
 *
 * Site24 RUM is only enabled in certain environments, see environments/index.<config>.html files.
 *
 * TODO: Look into Site24 call is not working as it should, as we don't get proper errors through
 **/
@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    handleError(error: any) {
        if ((window as any).Cypress || localStorage.getItem('debug')) {
            return void super.handleError(error);
        }

        if (!error) {
            error = new Error('Undefined error');
        }

        console.error('Angular error:', error.toString(), error.stack ? error.stack : '');
        const regex = /Loading chunk [\d]+ failed/;
        // If we start to miss chunks it's just better to reload altogether
        if (regex.test(error.toString())) {
            caches.keys().then(async keys => {
                const promises = [];
                keys.forEach(k => promises.push(caches.delete(k)));
                await Promise.all(promises);
                location.reload();
            });
        }

        if (typeof window.onerror === 'function') {
            try {
                insightRUM.eventHandlers.onerror([error.toString(), window.location.toString(), 0, 0, error], insightRUM);
            } catch (e) {}
        }
    }
}
