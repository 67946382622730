<!-- @format -->

<div class="flex-column poll-create-container">
    <p class="poll-header">{{ 'poll.create' | transloco }}</p>
    <mat-form-field color="accent" floatLabel="always" appearance="outline" class="form-field full-width">
        <mat-label>{{ 'poll.header.name' | transloco }}</mat-label>
        <input
            data-cy="poll-name-input"
            #pollName
            id="poll-name"
            class="form-control"
            matInput
            [placeholder]="'poll.placeholder.name' | transloco"
            [formControl]="pollFormGroup.controls.name"
            maxlength="150" />
        <mat-hint align="end">{{ pollName.value.length || 0 }} / 150</mat-hint>
    </mat-form-field>
    <ng-container *ngFor="let optionControl of pollFormGroup.controls.options.controls; let i = index; let isLast = last">
        <div class="option-add-wrapper">
            <div class="option-wrapper">
                <mat-form-field color="accent" floatLabel="always" appearance="outline" class="form-field full-width">
                    <mat-label>{{ ('poll.header.option' | transloco) + ' ' + (i + 1) }}</mat-label>
                    <input
                        [attr.data-cy]="'poll-option-input-' + i"
                        #pollOption
                        id="poll-option"
                        class="form-control"
                        matInput
                        [formControl]="optionControl.controls.name"
                        [placeholder]="'poll.placeholder.option' | transloco" 
                        maxlength="50" />
                    <mat-hint align="end">{{ pollOption.value.length || 0 }} / 50</mat-hint>
                </mat-form-field>
                <app-button *ngIf="optionRemoveEnabled && !disableAllFields"
                    [ariaLabel]="'poll.aria-label.remove-option' | transloco"
                    [tippyText]="'poll.aria-label.remove-option' | transloco"
                    [type]="'icon'"
                    [hailerIconName]="'hailer-x'"
                    [size]="'medium'"
                    [color]="'icon-default'"
                    (clickEvent)="removeOption(i)">
                </app-button>
            </div>
            <ng-container *ngIf="isLast">
                <div class="add-option-btn-container">
                    <app-button
                        data-cy="add-poll-option"
                        [type]="'text-with-icon'"
                        [hailerIconName]="'hailer-plus'"
                        [label]="'poll.add-option' | transloco"
                        [ariaLabel]="'poll.add-option' | transloco"
                        [color]="'secondary'"
                        [size]="'small'"
                        (clickEvent)="addOption()"
                        [isDisabled]="disableAllFields">
                    </app-button>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <div>
        <div class="field-view fill-field-container poll-visibility-selector">
            <p class="field-header"><mat-icon svgIcon="hailer-show" class="margin-right-small"></mat-icon>{{ 'poll.voters-visibility-header' | transloco }}</p>
            <mat-form-field appearance="outline" class="form-field" color="accent">
                <mat-select [formControl]="pollFormGroup.controls.answerVisibility">
                    <mat-option *ngFor="let type of visibilityTypes" [value]="type.value" class="visibility-option">
                        {{ type.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-slide-toggle [formControl]="pollFormGroup.controls.multipleAnswers" labelPosition="after" class="field-header">{{
            'poll.allow-multiple' | transloco
        }}</mat-slide-toggle>
        <mat-slide-toggle [formControl]="pollFormGroup.controls.lockedVotes" labelPosition="after" class="field-header">{{
            'poll.locked-votes' | transloco
        }}</mat-slide-toggle>
    </div>
</div>
