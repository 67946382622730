import { PermissionCheckReturn, PermissionError, PermissionErrorCodes, PermissionMap, WorkspacePermissions } from '../permission-models';

export class RolePermission {
    constructor(private userId: string, private permissions: PermissionMap) { }

    isAdminOrOwner(workspaceId: string): PermissionCheckReturn {
        const [error, workspacePermissions] = this.workspacePermissions(workspaceId);
        if (error) {
            return [error];
        }

        const isOwner = workspacePermissions?.workspace?.isOwner;
        const isAdmin = workspacePermissions?.workspace?.isAdmin;
        if (!isOwner && !isAdmin) {
            return [{ name: 'Invalid permissions', message: 'User needs to be an admin' }];
        }

        return [undefined, true];
    }

    users = {
        update: (workspaceId: string): PermissionCheckReturn => {
            return this.isAdminOrOwner(workspaceId);
        },
    };

    create(workspaceId: string): PermissionCheckReturn {
        return this.isAdminOrOwner(workspaceId);
    }

    remove(workspace: { _id: string; members?: { customRole?: string | undefined }[] }, roleId: string): PermissionCheckReturn {
        const [adminOrOwnerError] = this.isAdminOrOwner(workspace._id);
        if (adminOrOwnerError) {
            return [adminOrOwnerError];
        }

        for (const { customRole } of workspace.members || []) {
            if (customRole === roleId) {
                return [{ name: 'Permission denied', message: 'Cannot remove role with users' }];
            }
        }

        return [undefined, true];
    }

    list(workspaceId: string): PermissionCheckReturn {
        return this.isAdminOrOwner(workspaceId);
    }

    update(workspaceId: string): PermissionCheckReturn {
        return this.isAdminOrOwner(workspaceId);
    }

    private workspacePermissions(workspaceId: string): [error?: PermissionError, permissions?: WorkspacePermissions] {
        if (!this.permissions) {
            return [{ name: 'Invalid permissions', message: 'User permissions are undefined', code: PermissionErrorCodes.missing, workspaceId }];
        }

        const workspacePermissions = this.permissions[workspaceId];
        if (!workspacePermissions) {
            return [
                { name: 'Invalid permissions', message: 'Workspace permissions are undefined', code: PermissionErrorCodes.missing, workspaceId },
            ];
        }

        return [undefined, workspacePermissions];
    }
}
