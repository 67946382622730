/** @format */

import { Injectable } from '@angular/core';
import linkifyHtml from 'linkify-html';
import { Converter } from 'showdown';
import * as DOMPurify from 'dompurify';

const showdownHighlight = require('showdown-highlight');

export type HailerTagType = 'inAppLink' | 'plaintext' | 'userMention' | 'youtube';

// TODO: Move this to only be in the components where it is needed. Namely feed and discussions. COPMONENTS! Not modules.
@Injectable({
    providedIn: 'root',
})
export class ExtendedMarkdownService {
    private data: any[] = [];
    private images: any[] = [];
    private converter: Converter;

    constructor() {
        this.converter = new Converter();
        // Configure the converter
        if (showdownHighlight) {
            this.converter.addExtension(showdownHighlight);
        }

        this.converter.setOption('literalMidWordUnderscores', true);
        this.converter.setOption('requireSpaceBeforeHeadingText', true);

        // User mentions
        this.converter.addExtension({
            type: 'lang',
            regex: /\[user\|([^|]+)\|([^\]]+)\]/gim,
            replace: (_: string, id: string, username: string) => this.createPlaceholder('userMention', { id, username }),
        });

        // Youtube embeds
        this.converter.addExtension({
            type: 'lang',
            regex: /(?:https?:\/\/)(?:w{3}.)(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([-\w]{6,11})/gim,
            replace: (match: string, videoId: string) => this.createPlaceholder('youtube', videoId),
        });

        /* Showdown 2.0 should have better support for this, but oh boi...
           We'll have to make do for now.
           This does work but it definetly isn't pretty */
        this.converter.addExtension({
            type: 'listener',
            listeners: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'images.before': function (_, text) {
                    const imgRegex = /(!\[[^\]]*\]\([^\]]*\))/;
                    const res = text.match(imgRegex);
                    if (res !== null) {
                        return this.createPlaceholder('plaintext', res[1]);
                    }

                    return text;
                }.bind(this),
            },
        });

        this.converter.addExtension({
            type: 'output',
            filter: (msg: string) => {
                msg = linkifyHtml(msg, { defaultProtocol: 'https' });
                // Add "target='_blank'" to links after we've sanitized them
                DOMPurify.addHook('afterSanitizeAttributes', node => {
                    if ('target' in node && 'href' in node) {
                        node.setAttribute('target', '_blank');
                        node.setAttribute('rel', 'noopener');
                    }
                });

                const sanitized = DOMPurify.sanitize(msg, {
                    ADD_TAGS: ['hailer', 'iframe'],
                    ADD_ATTR: ['type', 'allowfullscreen', 'frameborder'],
                });

                DOMPurify.removeHook('afterSanitizeAttributes');

                return sanitized;
            },
        });
    }

    parse(text: string, opts: any = { handleHtml: 'escape' }): string {
        let mutatingText = text;
        mutatingText = this.removeHtml(text, opts.handleHtml);
        return this.converter.makeHtml(mutatingText);
    }

    getTagData(index: number): any {
        return this.data[index];
    }

    // Text: any <== TypeScript will whine about text.matchAll if it is typed as string...
    private removeHtml(text: any, handler: 'escape' | 'remove' | 'skip'): string {
        if (handler === 'skip') {
            return text;
        }
        const codeBlocks = this.escapeCodeBlocks(text);
        const codeFences = this.escapeCodeFences(codeBlocks.parsed);
        const inlines = this.escapeInlineCode(codeFences.parsed);
        let parsed = inlines.parsed as any;

        const htmlMatcher = /<(\w+)[^>]*>([^<]*)<\/\1>/gim;
        const matches = parsed.matchAll(htmlMatcher);

        for (const match of matches) {
            if (handler === 'escape') {
                parsed = parsed.replace(match[0], this.createPlaceholder('plaintext', match[0]));
            } else if (handler === 'remove') {
                parsed = parsed.replace(match[0], match[2]);
            }
        }

        inlines.inlines.forEach((data, index) => (parsed = parsed.replace(`>||>${index}<||<`, data)));
        codeFences.blocks.forEach((data, index) => (parsed = parsed.replace(`>[]>${index}<[]<`, data)));
        codeBlocks.blocks.forEach((data, index) => (parsed = parsed.replace(`>__>${index}<__<`, data)));

        return parsed;
    }

    private escapeCodeBlocks(text: any): { parsed: string; blocks: string[] } {
        const codeBlock = /^ {4,}.*$/gim;
        const matches = text.matchAll(codeBlock);
        const blocks: string[] = [];
        let mutatingStr = text;

        for (const match of matches) {
            blocks.push(match[0]);
            mutatingStr = mutatingStr.replace(match[0], `>__>${blocks.length - 1}<__<`);
        }

        return { parsed: mutatingStr, blocks };
    }

    private escapeCodeFences(text: any): { parsed: string; blocks: string[] } {
        const codeFence = /^`{3}(.*)$/gim;
        const matches = text.matchAll(codeFence);
        const start = [];
        const end = [];
        for (const boundry of matches) {
            if (start.length !== end.length) {
                end.push(boundry.index + boundry[0].length);
                continue;
            }
            start.push(boundry.index);
        }

        if (start.length !== end.length) {
            return { parsed: text, blocks: [] };
        }

        const blocks = [];
        let mutatingStr = text;

        for (let i = 0; i < start.length; i++) {
            const begin = start[i];
            const stop = end[i];

            const block = text.substring(begin, stop);
            blocks.push(block);
            mutatingStr = mutatingStr.replace(block, `>[]>${blocks.length - 1}<[]<`);
        }

        return { parsed: mutatingStr, blocks };
    }

    private escapeInlineCode(text: any): { parsed: string; inlines: string[] } {
        const inlineCode = /[^`](`[^\`]+`)[^`]/gim;
        const inlines = [];
        const matches = text.matchAll(inlineCode);
        let mutatingText = text;
        for (const match of matches) {
            inlines.push(match[1]);
            mutatingText = mutatingText.replace(match[1], `>||>${inlines.length - 1}<||<`);
        }
        return { parsed: mutatingText, inlines };
    }

    private createPlaceholder(type: HailerTagType, data: any) {
        this.data.push(data);
        const content = { type, data: this.data.length - 1 };
        return `<hailer>${JSON.stringify(content)}</hailer>`;
    }
}
