/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule } from '@leeviviertola/date-time-picker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { SatPopoverModule } from '@ncstate/sat-popover';

import { MY_MOMENT_FORMATS, SharedModule } from '@app/shared/shared.module';
import { FilesSharedModule } from 'app/files-shared/files-shared.module';
import { ActivitySelectorComponent } from './activity-selector/activity-selector.component';
import { CreateLinkedActivityComponent } from './create-linked-activity/create-linked-activity.component';
import { ActivityFieldService } from 'app/_services/activity-field.service';
import { ActivitiesFilterService } from 'app/_services/activities-filter.service';
import { ActivityFieldViewLiteComponent } from './activity-field-view-lite/activity-field-view-lite.component';
import { DiscussionSharedModule } from 'app/discussion-shared/discussion-shared.module';
import { ActivityFilterSidenavComponent } from './activity-filter-sidenav/activity-filter-sidenav.component';
import { ActivityFieldFilterComponent } from './activity-field-filter/activity-field-filter.component';
import { ActivitiesSharedRoutingModule } from './activities-shared-routing.module';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
    declarations: [
        ActivitySelectorComponent,
        CreateLinkedActivityComponent,
        ActivityFilterSidenavComponent,
        ActivityFieldFilterComponent,
        ActivityFieldViewLiteComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        FilesSharedModule,
        NgxPaginationModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        SatPopoverModule,
        DiscussionSharedModule,
        ActivitiesSharedRoutingModule,
        TranslocoRootModule,
    ],
    exports: [CreateLinkedActivityComponent, ActivityFieldViewLiteComponent, ActivitySelectorComponent],
    providers: [
        ActivityFieldService,
        ActivitiesFilterService,
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' },
    ],
})
export class ActivitiesSharedModule {}
