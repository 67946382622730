/** @format */

import { animate, style, transition, trigger } from '@angular/animations';

export const passwordStrengthMeterAnimations = [
    trigger('passwordMeterTrigger', [
        transition(':enter', [
            style({ opacity: 0, transform: 'translateX(5%)' }),
            animate('100ms', style({ opacity: 1, transform: 'translateX(0%)' })),
        ]),
        transition(':leave', [animate('100ms', style({ opacity: 0, transform: 'translateX(5%)' }))]),
    ]),
];
