<!-- @format -->

<div class="list-component">
	<!-- Title -->
	<div class="list-title-container">
		<div class="list-title">
			<mat-icon class="title-icon" svgIcon="hailer-apps-colour"></mat-icon>
			<div data-cy="app-title" class="list-title-text">
				<h3>{{ 'apps.management.app-list-title.apps' | transloco }}</h3>
				<span class="profile-text-overflow">{{ 'apps.management.app-list-title.description' | transloco }}</span>
			</div>
		</div>

		<div class="list-title-icon-container">
			<div *ngIf="isSearching && !this.isMobile" class="default-search-bar margin-right-small">
				<mat-icon svgIcon="hailer-search"></mat-icon>
				<input
					[appAutofocus]="false"
					placeholder="{{ 'apps.management.headers.actions.search' | transloco }}"
					autocomplete="off"
					[formControl]="nameSearchControl" />
				<mat-icon svgIcon="hailer-x" (click)="isSearching = false; this.nameSearchControl.setValue('')"></mat-icon>
			</div>
			<app-button
				*ngIf="!isSearching && !this.isMobile"
				type="icon"
				color="icon-default"
				size="medium"
				[ariaLabel]="'apps.management.headers.actions.search' | transloco"
				hailerIconName="hailer-search"
				(clickEvent)="isSearching = !isSearching"
				[tippyText]="'apps.management.headers.actions.search' | transloco">
			</app-button>
			<app-button
				[isDisabled]="!permissions.isNetworkAdmin"
				type="icon"
				color="icon-success"
				size="medium"
				data-cy="app-open-creation-form-button"
				[ariaLabel]="'apps.management.headers.actions.create' | transloco"
				hailerIconName="hailer-plus"
				(clickEvent)="createApp()"
				[tippyText]="'apps.management.headers.actions.create' | transloco">
			</app-button>
			<app-button
				*ngIf="this.isMobile"
				type="icon"
				color="icon-default"
				size="medium"
				matIconClass="hailer-chevron {{ !this.listClosed ? 'up' : '' }}"
				[ariaLabel]="'apps.management.headers.actions.toggle' | transloco"
				hailerIconName="hailer-chevron"
				(clickEvent)="this.listClosed ? openList() : closeList()"
				[tippyText]="'apps.management.headers.actions.toggle' | transloco">
			</app-button>
		</div>
	</div>

	<!-- Headers -->
	<div *ngIf="!this.isMobile" class="list-headers">
		<label>{{ 'apps.management.headers.name' | transloco }}</label>
		<label class="header-description">{{ 'apps.management.headers.description' | transloco }}</label>
		<label class="header-actions">{{ 'apps.management.headers.actions.actions' | transloco }}</label>
	</div>

	<!-- List of Insights/Apps -->
	<ng-container *ngFor="let item of visibleItems; last as isLast;">
		<div *ngIf="item" data-cy="app-list-item" class="list-item-container">
			<!-- Values for headers -->
			<div class="list-item name one-line-ellipsis" (click)="appService.routeToApp(item)">
				<img
					class="image"
					width="32px"
					height="32px"
					src="{{ item.image ? baseUrl + '/' + item.image : defaultImage }}"
					alt="app image" />
				<span class="name-text">{{ item.name }}<span *ngIf="item.manifest?.author" style="font-size: 0.875rem; color: var(--text-low)"><br />{{item.manifest?.author}}</span></span>
				<span *ngIf="item.manifest === null" style="font-size: 0.875rem; color: var(--warn-500)">Manifest missing</span>
			</div>
			<span *ngIf="!this.isMobile" class="list-item description one-line-ellipsis">{{ item.description }}</span>
            <span *ngIf="!this.isMobile && item.isProductVersionAvailable">
                <app-button
					*ngIf="!this.isMobile"
					[isDisabled]="!permissions.isNetworkAdmin"
					type="icon"
					color="icon-default"
					size="medium"
					[ariaLabel]="'apps.management.headers.actions.update-available' | transloco"
					hailerIconName="hailer-refresh"
					(clickEvent)="updateApp(item)"
					[tippyText]="'apps.management.headers.actions.update-available' | transloco">
				</app-button>
            </span>
			<span class="list-item actions">
				<!-- Edit item-->
				<app-button
					*ngIf="!this.isMobile"
					[isDisabled]="!permissions.isNetworkAdmin"
					type="icon"
					color="icon-default"
					size="medium"
					data-cy="app-open-update-form-button"
					[ariaLabel]="'apps.management.headers.actions.edit' | transloco"
					hailerIconName="hailer-edit-fill"
					(clickEvent)="editApp(item)"
					[tippyText]="'apps.management.headers.actions.edit' | transloco">
				</app-button>

				<!-- Other actions menu -->
				<button
					mat-icon-button
					class="hover-lighten"
					tippy="See more"
					[matMenuTriggerFor]="seeMoreMenu"
					data-cy="app-see-more-menu-button">
					<mat-icon svgIcon="hailer-see-more"></mat-icon>
				</button>
				<mat-menu #seeMoreMenu="matMenu" [overlapTrigger]="false" xPosition="after">
					<button
						mat-menu-item
						*ngIf="this.isMobile"
						[disabled]="!permissions.isNetworkAdmin"
						data-cy="app-open-update-form-button"
						(click)="editApp(item)">
						<mat-icon svgIcon="hailer-edit-fill"></mat-icon>
						<span>{{ 'apps.management.headers.actions.edit' | transloco }}</span>
					</button>
					<button
						mat-menu-item
						*ngIf="item.cid"
						[disabled]="!permissions.isNetworkAdmin"
						(click)="permissionEditor(item)"
						data-cy="app-permission-button">
						<mat-icon svgIcon="hailer-lock"></mat-icon>
						<span>{{ 'apps.management.headers.actions.permissions' | transloco }}</span>
					</button>
					<button
						*ngIf="item.config"
						mat-menu-item
						[disabled]="!permissions.isNetworkAdmin"
						(click)="configureApp(item)">
						<mat-icon svgIcon="hailer-settings"></mat-icon>
						<span>{{ 'apps.management.headers.actions.configuration' | transloco }}</span>
					</button>
					<button
						mat-menu-item
						[disabled]="!permissions.isNetworkAdmin"
						(click)="removeApp(item)"
						data-cy="app-remove-button"
                        class="destructive-text">
						<mat-icon svgIcon="hailer-delete" class="warn"></mat-icon>
						<span>{{ 'apps.management.headers.actions.remove' | transloco }}</span>
					</button>
				</mat-menu>
			</span>
		</div>
	</ng-container>
</div>
