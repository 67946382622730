/**
 * Eslint-disable @typescript-eslint/naming-convention
 *
 * @format
 */

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Member } from 'app/_models/member.model';

import { CoreService } from 'app/_services/core.service';
import { RPCService } from 'app/_services/rpc.service';

export interface FieldDependency {
    name: string;
    meta?: string;
    fieldId?: string;
    phaseId?: string;
}

export interface Source {
    workflowId: string;
    name: string;
    fields: FieldDependency[];
}

export interface InsightSource {
    name: string;
    workflowId: string;
    fields: {
        name: string;
        meta?: string;
        fieldId?: string;
        phaseId?: string;
    }[];
}

export interface InsightDoc {
    _id: string;
    name: string;
    sources: InsightSource[];
    cid?: string;
    /** User id of creator */
    uid: string;
    /**
     * This is not final, should be members?
     *
     * Private only admins and creator can see, Workspace: everyone in workspace can see, Team selected teams can see
     */
    members: Member[];
    /** Sharing via link is possible, using `publicKey` as id */
    public: boolean;
    /** Key used for sharing Insight publicly */
    publicKey?: string;
    /** Sandboxed javascript with access to all activities in given workflows */
    function?: string;
    /** To be used with SQLite if we want to.... */
    query?: string;
    /** Used as default values when loading view */
    presets: {
        version: 1;
        name: string | 'default';
        options: any;
    }[];
}

@Injectable({
    providedIn: 'root',
})
export class InsightService {
    insights: InsightDoc[] = [];

    constructor(
        private router: Router,
        private core: CoreService,
        private rpc: RPCService,
        private snackbar: MatSnackBar
    ) {
        this.core.network.subscribe({
            next: async workspace => {
                if (!workspace) {
                    return;
                }

                this.insights = await this.rpc.requestAsync('v3.insight.list', [workspace._id]);
            },
        });
    }

    list(): InsightDoc[] {
        return this.insights;
    }

    async copyToClipboard(value: string): Promise<void> {
        if (!value) {
            return;
        }

        await navigator.clipboard.writeText(value);
        this.snackbar.open('Public Insight key copied!', 'Ok', { duration: 2000 });
    }

    getInsightPublicUrl(key: string): string {
        return `${location.origin}/insight/?key=${key}`;
    }

    async remove(insightId: string): Promise<void> {
        try {
            await this.rpc.requestAsync('v3.insight.remove', [insightId]);
        } catch (error) {
            this.snackbar.open('Failed to remove Insight');
        }
    }

    async routeToInsight(insightId: string): Promise<boolean> {
        // eslint-disable-next-line @typescript-eslint/return-await
        return await this.router.navigate([`insight/${insightId}`]);
    }
}
