/** @format */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    Injector,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { filter, take, takeUntil } from 'rxjs/operators';
import { MediaMatcher } from '@angular/cdk/layout';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Platform } from '@angular/cdk/platform';

import { CoreService, SessionState } from 'app/_services/core.service';
import { ImageViewerService } from 'app/_services/image-viewer.service';
import { NgxStackViewComponent } from 'app/ngx-stack-view/ngx-stack-view/ngx-stack-view.component';
import { SideNavService } from 'app/_services/side-nav.service';
import { IMAGE_DATA, ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { WindowListenerService } from '../../_services/window-listener.service';
import { RoutingService } from 'app/_services/routing.service';

@Component({
    selector: 'app-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerComponent implements OnInit, OnDestroy {
    @ViewChild('theOneSidenav', { static: true }) theOneSidenav: MatSidenav;
    @ViewChild('theOneSidenav', { read: ElementRef, static: true }) theOneSidenavEl: ElementRef;
    @ViewChild(NgxStackViewComponent, { static: true }) stack: NgxStackViewComponent;
    @ContentChild('content', { static: false }) content: any;

    state: BehaviorSubject<SessionState>;
    screenWidth = window.innerWidth;
    marginTop = 0;
    adaptiveBackground = false;
    hideNavigation = new BehaviorSubject<boolean>(false);

    private fullscreenSidenav = false;
    private onDestroy = new Subject<void>();

    constructor(
        private zone: NgZone,
        private core: CoreService,
        private route: RoutingService,
        private sideNavService: SideNavService,
        private renderer: Renderer2,
        private imageViewer: ImageViewerService,
        private overlay: Overlay,
        private cdr: ChangeDetectorRef,
        private injector: Injector,
        private windowListener: WindowListenerService,
        public media: MediaMatcher,
        public platform: Platform
    ) {}

    ngOnInit() {
        this.route.queryParams.pipe(take(1)).subscribe(value => {
            // Checks if user has ?fullscreen query param at the end of their url
            this.hideNavigation.next(value.fullscreen !== undefined);
        });

        this.vhFix();
        // Document.addEventListener("scroll", this.scroll);

        this.state = this.core.state;

        this.sideNavService.marginTop.pipe(takeUntil(this.onDestroy)).subscribe({
            next: (marginTop: any) => (this.marginTop = marginTop),
        });

        this.sideNavService.sidenavStyling.pipe(takeUntil(this.onDestroy)).subscribe({
            next: (styling: { marginTop?: number; adaptiveBackground?: any }) => {
                if (!styling) {
                    return;
                }
                this.marginTop = styling?.marginTop ? styling.marginTop : 0;
                this.adaptiveBackground = styling?.adaptiveBackground ? styling.adaptiveBackground : false;
            },
        });

        this.sideNavService.sideNavContent$.pipe(takeUntil(this.onDestroy)).subscribe({
            next: (content: any) => {
                this.lockBody(!!this.stack.stack.length && this.screenWidth < 600);

                this.theOneSidenav.open();
                const { sidenav, props } = content;

                // NOTE: This will be removed once all instances of The One Sidenav have the same margintop

                this.stack.create(sidenav, props);
                this.cdr.detectChanges();
            },
        });

        this.sideNavService.sideNavPop$.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.stack.pop();
                this.cdr.detectChanges();
            },
        });

        this.sideNavService.sideNavClear$.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.stack.clear();
                this.cdr.detectChanges();
            },
        });

        this.sideNavService.fullscreen.pipe(takeUntil(this.onDestroy)).subscribe({
            next: fullscreen => {
                this.setSidenavFullScreen(fullscreen);
            },
        });

        this.imageViewer.images
            .pipe(
                takeUntil(this.onDestroy),
                filter(data => !!data)
            )
            .subscribe({
                next: data => this.openViewer(data),
            });

        this.windowListener.size.pipe(takeUntil(this.onDestroy)).subscribe({
            next: ({ x }) => {
                this.screenWidth = x;
                this.vhFix();
            },
        });

        this.windowListener.orientationChange.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.vhFix(),
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    changeDepth(depth: number) {
        if (depth === 0) {
            this.theOneSidenav.close();
            this.fullscreenSidenav = false;
            this.lockBody(false);
        }
    }

    get sidenavStatus(): boolean {
        return this.theOneSidenav.opened;
    }

    openViewer(obj) {
        const tokens = new WeakMap();
        tokens.set(IMAGE_DATA, obj);
        const overlayRef = this.overlay.create();
        const portal = new ComponentPortal(ImageViewerComponent, null, new PortalInjector(this.injector, tokens));

        this.zone.run(() => {
            overlayRef.attach(portal).instance.afterClosed.subscribe(() => overlayRef.detach());
        });
    }

    lockBody(lock: boolean) {
        if (!this.theOneSidenavEl) {
            return;
        }

        if (lock) {
            return disableBodyScroll(this.theOneSidenavEl);
        }

        enableBodyScroll(this.theOneSidenavEl);
    }

    // Create a CSS variable that correctly reflects window height
    private vhFix() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        this.updateSideNavFullscreen();
        this.cdr.detectChanges();
    }

    private setSidenavFullScreen(fullscreen: boolean) {
        this.fullscreenSidenav = fullscreen;
        this.updateSideNavFullscreen();
    }

    private updateSideNavFullscreen() {
        if (this.screenWidth > 600) {
            if (this.fullscreenSidenav) {
                this.renderer.setStyle(this.theOneSidenavEl.nativeElement, 'width', 'calc(100vw - 73px)');
            } else {
                this.renderer.setStyle(this.theOneSidenavEl.nativeElement, 'width', '400px');
            }
            this.cdr.detectChanges();
        }
    }
}
