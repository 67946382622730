/** @format */

import { Directive, HostListener, Input } from '@angular/core';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

@Directive({
    selector: '[appTakePicture]',
})
export class TakePictureDirective {
    @Input('appTakePictureUploader') uploader: FileUploaderComponent;

    @HostListener('click') async onClick(): Promise<void> {
        if (!this.uploader) {
            console.error('Take picture directive needs an file uploader!');
            return;
        }

        const image = await Camera.getPhoto({
            allowEditing: true,
            saveToGallery: true,
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
        });

        if (!image?.webPath) {
            console.error('Failed to read image path');
            return;
        }

        const response = await fetch(image.webPath);
        const blob = await response.blob();
        if (!blob.type.startsWith('image/')) {
            console.warn('File is not an image');
            return;
        }

        const file = new File([blob], `${new Date().toISOString()}.${image.format}`, { type: blob.type });
        await this.uploader.uploadFile(file);
    }
}
