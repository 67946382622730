<!-- @format -->

<ng-container *ngIf="showDropDown; else listView">
	<div class="field-with-clear grid-container-input-with-clear">
		<mat-form-field appearance="fill" floatLabel="never">
			<mat-label>{{ options.fieldName || ('shared.country-selector.select_label' | transloco) }}</mat-label>
			<mat-select
				[multiple]="options.multiple || false"
				[formControl]="countryCtrl"
				placeholder="{{ options.placeholderLabel }}"
				(openedChange)="emitWhenClosed($event)"
				(selectionChange)="results.emit($event.value)"
				#countrySelect>
				<mat-option>
					<ngx-mat-select-search
                        data-cy="country-search-input"
						[formControl]="countryFilterCtrl"
						[placeholderLabel]="options.placeholderLabel"
						[noEntriesFoundLabel]="options.noEntriesFoundLabel"></ngx-mat-select-search>
				</mat-option>
				<cdk-virtual-scroll-viewport [itemSize]="42" [minBufferPx]="42 * 5" [maxBufferPx]="42 * 8" [style.height.px]="200">
					<mat-optgroup label="{{ 'shared.country-selector.countries' | transloco }}">
						<ng-container *ngFor="let country of filteredCountries | async; trackBy: trackByFn">
							<span class="only-holder">
								<mat-option [value]="country">
									<span class="flag-icon fi" [ngClass]="flagClass(country)"></span>
									{{ country.name }}
								</mat-option>
								<button
									*ngIf="options.onlySelector"
									color="accent"
									class="only-button"
									(click)="$event.stopPropagation(); onlyItem(country)"
									mat-button>
									{{ 'shared.country-selector.only' | transloco }}
								</button>
							</span>
						</ng-container>
					</mat-optgroup>
				</cdk-virtual-scroll-viewport>
			</mat-select>
		</mat-form-field>

		<app-clear-x-button (clickEvent)="resetSelector()" *ngIf="options.showResetButton"></app-clear-x-button>
	</div>
</ng-container>

<!-- onlyItem doesnt make the checkbox checked nor show in the amount bubble
also when you click the checkboxes - not shown in amount bubble
-->
<ng-template #listView>
	<div [ngClass]="{ 'filter-options-scroll': countries.length > 7 }" class="filter-options flexbox-column">
		<div class="search-for-activity-filter">
			<mat-form-field appearance="fill" class="activity-filter">
				<mat-icon svgIcon="hailer-search"></mat-icon>
				<input [formControl]="countryFilterCtrl" matInput placeholder="{{ options.placeholderLabel }}" />
			</mat-form-field>
		</div>

		<mat-list>
			<mat-selection-list [multiple]="true" (selectionChange)="selectCountry($event)">
				<ng-container *ngFor="let country of filteredCountries | async; trackBy: trackByFn">
					<span class="only-holder">
						<mat-list-option [selected]="countrySelected(country.code)" [value]="country" checkboxPosition="before">
							<div class="only-holder-text">
								<div class="grid-container-small-first">
									<span class="flag-icon fi" [ngClass]="flagClass(country)"></span>
									{{ country.name }}
								</div>

								<button
									*ngIf="options.onlySelector"
									color="accent"
									class="only-button"
									(click)="$event.stopPropagation(); onlyItem(country)"
									mat-button>
									Only
								</button>
							</div>
						</mat-list-option>
					</span>
				</ng-container>
			</mat-selection-list>
		</mat-list>
	</div>
</ng-template>
