<app-user-image-link (click)="openUserSidenav(comment.uid)" [userId]="comment.uid" [size]="40" [disableTooltip]="true">
</app-user-image-link>
<div class="comment-body">
    <div class="comment-data flex-row--space-between">
        <app-user-link [userId]="comment.uid"></app-user-link>
        <div class="ns-text-small-3 flex">
            <span> {{ comment.created | todayDate | async }}</span>
            <mat-icon
                *ngIf="(core.permission.feed.comment.edit(comment.cid, comment.uid) | hasPermission) || (core.permission.feed.comment.remove(comment.cid, comment.uid) | hasPermission)"
                class="clickable small margin-left-small"
                [matMenuTriggerFor]="menu"
                svgIcon="hailer-settings"
                [attr.aria-label]="'feed.settings' | transloco"
            ></mat-icon>
        </div>
    </div>
    <div *ngIf="!editing" data-cy="post-comment-text" class="comment-text" [innerHTML]="comment.comment.text | htmlEscape | hashtag | urlify: true | nl2br | safe:'html'">
    </div>
    <div *ngIf="editing">
        <textarea matInput [(ngModel)]="newCommentText" cdkTextareaAutosize cdkAutosizeMinRows="1" type="text">></textarea>
        <button mat-stroked-button class="save-button" color="accent" [disabled]="!newCommentText.trim()"
            (click)="saveEditedComment()">{{ 'feed.feed_comment_view.save' | transloco }}</button>
        <button mat-button class="margin-right-8px cancel-button" color="primary" (click)="editing = false">{{ 'feed.feed_comment_view.cancel' | transloco }}</button>
    </div>
    <div class="attachments-media">
        <img crossorigin="use-credentials" class="mat-elevation-z3 comment-image" alt="attachment image" *ngFor="let image of attachments.supportedMedia; let i = index;"
            [src]="getImageUrl(image)" (click)="viewImages(attachments.supportedMedia, i)">
    </div>
    <div class="attachments-other">
        <div *ngFor="let attachment of attachments.other;">
            <div class="details">
                <div class="fileinfo">
                    <mat-icon>insert_drive_file</mat-icon>
                    <div class="filename">
                        <a (click)="openFile(attachment._id)" target="_blank">{{attachment.name}}</a>
                    </div>
                </div>
                <span class="download">
                    <a (click)="filesService.download(attachment)" target="_blank" mat-icon-button>
                        <mat-icon color="primary">cloud_download</mat-icon>
                    </a>
                </span>
            </div>
        </div>
    </div>
</div>
<mat-menu #menu="matMenu" [overlapTrigger]="false" xPosition="before">
    <button mat-menu-item *ngIf="core.permission.feed.comment.edit(comment.cid, comment.uid) | hasPermission" (click)="onEditClick()">
        <mat-icon svgIcon="hailer-edit"></mat-icon>{{ 'feed.feed_comment_view.edit' | transloco }}
    </button>
    <button mat-menu-item class="destructive-text" *ngIf="core.permission.feed.comment.remove(comment.cid, comment.uid) | hasPermission"
        (click)="removeComment()">
        <mat-icon svgIcon="hailer-trash" class="warn"></mat-icon>
        {{ 'feed.feed_comment_view.delete' | transloco }}
    </button>
</mat-menu>
