/** @format */

import { Injectable, NgZone, isDevMode } from '@angular/core';
import { ManagerOptions, Socket, SocketOptions, io } from 'socket.io-client';
import { Observable, Observer, Subject, fromEvent } from 'rxjs';
import { merge } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '@app/env';

let url: string = environment.wsUrl;
const options: Partial<ManagerOptions & SocketOptions> = environment.wsOptions;

interface CallbackMap {
    [key: string]: {
        cb: (err: any, data: any) => any;
        observer?: Observer<any>;
    };
}

interface Signal {
    sig: string;
    meta: any;
}

@Injectable({
    providedIn: 'root',
})
export class RPCService {
    connectionListener: Observable<any>;
    disconnectListener: Observable<any>;
    signals = new Subject<Signal>();
    licenseExceeded = new Subject<boolean>();
    documentVisible = true;
    reconnectOnVisible = false;

    requestFailed = new Subject<Error>();

    private browserActive = Date.now();
    private socket: Socket = null;
    private id = 0;
    private cbs: CallbackMap = {};
    private subscribers: any = {};
    private socketDisconnect: Observable<any>;
    private windowDisconnect: Observable<any>;

    constructor(private snackbar: MatSnackBar, private ngZone: NgZone) {
        try {
            if (localStorage.getItem('debug')) {
                const port = parseInt(localStorage.getItem('port'), 10);

                if (port) {
                    url = `https://localhost:${port}`;
                }
            }
        } catch (e) {}

        this.socket = io(url, options);
        this.socket.on('rpc-response', data => {
            if (data.data.sig) {
                data.data.ack = data.data.sig;
            }

            if (data.data.err) {
                data.data.ack = data.data.err;

                if (!this.cbs[data.data.err]) {
                    // No handler registered for this error id
                    return;
                }

                if (isDevMode()) {
                    const handler = this.cbs[data.data.err];

                    try {
                        if (!handler.observer || (handler.observer && typeof handler.observer.error !== 'function')) {
                            console.error('Server responded with an error:', data.data.data);
                        }
                    } catch (e) {
                        // Could not check if observer handles error, just ignore the whole thing
                    }
                }
            }

            if (this.cbs[data.data.ack]) {
                this.cbs[data.data.ack].cb(data.data.err ? true : null, data.data.data);
                if (!data.data.sig) {
                    delete this.cbs[data.data.ack];
                }
            } else if (this.subscribers[data.data.ack]) {
                // This is a socket signal emitted!!
                for (const ack of this.subscribers[data.data.ack]) {
                    ack(data.data.meta);
                }
            }

            // Always emit the signal from the signals-subject.
            if (data.data.sig) {
                this.signals.next(data.data);
            }
        });
        this.connectionListener = fromEvent(this.socket, 'connect');
        this.socketDisconnect = fromEvent(this.socket, 'disconnect');
        this.windowDisconnect = fromEvent(window, 'offline');

        this.disconnectListener = merge(this.socketDisconnect, this.windowDisconnect);

        window.addEventListener('online', () => {
            console.log('Browser is online, attempt to reconnect.');
            this.socket.disconnect();
            this.socket.connect();
        });

        window.addEventListener('visibilitychange', () => {
            const visible = document.visibilityState === 'visible';
            this.documentVisible = visible;
            if (visible) {
                this.checkConnection();
            }
        });

        // This.ngZone.runOutsideAngular(() => {
        setInterval(() => {
            // Check if the browser has been inactive for more than 20 seconds
            if (this.browserInactive && this.documentVisible) {
                const time = Date.now() - this.browserActive;
                console.log('Browser has been inactive, but is online & visible, attempt to reconnect. Inactive time:', time);
                this.socket.disconnect();
                this.socket.connect();
            } else if (this.browserInactive && !this.documentVisible) {
                this.reconnectOnVisible = true;
            }

            this.browserActive = Date.now();
        }, 2000);
        // });
    }

    checkConnection() {
        if (this.reconnectOnVisible && !this.socket.connected) {
            this.reconnectOnVisible = false;
            this.socket.disconnect();
            this.socket.connect();
        }
    }

    disconnect(): void {
        this.socket.disconnect();
    }

    reconnect(): void {
        this.socket.disconnect();
        this.socket.connect();
    }

    registerSignals(): void {
        this.rpc('core.signals', [], (err: boolean, data: any) => {});
    }

    unsubscribe(signal: string): void {
        delete this.subscribers[signal];
    }

    subscribe(signal: string, cb: (meta: any) => void) {
        if (!this.subscribers[signal]) {
            this.subscribers[signal] = [];
        }
        this.subscribers[signal].push(cb);
    }

    rpc(op: string, args: any, cb: (err: boolean, data: any) => void) {
        this.socket.emit('rpc-request', { op, args, id: ++this.id });
        this.cbs[this.id] = { cb };
    }

    request(op: string, args: any[] = []) {
        const time = Date.now();

        return new Observable((observer: Observer<any>) => {
            this.socket.emit('rpc-request', { op, args, id: ++this.id });
            this.cbs[this.id] = {
                cb: (err, data) => {
                    if (err) {
                        observer.error(data);
                        this.checkLicenseExceeded(data);

                        if (this.inDebugMode) {
                            this.requestFailed.next(data);
                        }

                        return;
                    }

                    observer.next(data);
                    observer.complete();

                    const responseTime = Date.now() - time;

                    if (!(window as any).Cypress && localStorage.getItem('debug') && responseTime > 200) {
                        this.snackbar.open(`[debug] Slow request ${op} took ${responseTime}ms`, '×', {
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 5000,
                        });
                    }
                },
                observer,
            };
        });
    }

    requestAsync(op: string, args: any[] = []): Promise<any> {
        this.socket.emit('rpc-request', { op, args, id: ++this.id });

        return new Promise((resolve, reject) => {
            this.cbs[this.id] = {
                cb: (err, data) => {
                    if (err) {
                        reject(data);
                        this.checkLicenseExceeded(data);

                        if (this.inDebugMode) {
                            this.requestFailed.next(data);
                        }

                        return;
                    }

                    resolve(data);
                },
            };
        });
    }

    get browserInactive(): boolean {
        const inactive = this.browserActive + 20000 < Date.now();
        return inactive && window.navigator.onLine;
    }

    private get inDebugMode(): boolean {
        return !!localStorage.getItem('debug');
    }

    /* This code will fire up an error dialog
       We subscribe to this in app.components */
    private checkLicenseExceeded(data: any) {
        if (data?.code === 402) {
            this.licenseExceeded.next(true);
        }
        if (data?.code === 418) {
            this.licenseExceeded.next(false);
        }
    }
}
