<div class="file-uploader">
    <div class="uploader" *ngFor="let uploader of uploaders">
        <div data-cy="file-uploader-details" class="details">
            <div class="progress-ripple" [ngClass]="{'progress-ripple-animate': uploader.done}"></div>
            <div class="file-info">
                <div class="file-preview-container">
                    <img class="image-preview" [src]="uploader.previewUrl ?? 'assets/icons/document-icons/' + uploader.fileType + '.svg'">
                </div>
                <div class="flexbox-column">
                    <span class="filename two-line-ellipsis">{{uploader.name}}</span>
                    <span class="filesize">{{uploader.length}}</span>
                </div>
            </div>
            <mat-icon inline="true" [ngClass]="{'progress-done-icon': uploader.done && !uploader.error}" class="progress-done-icon-hidden" svgIcon="hailer-tick-fill"></mat-icon>

            <div class="progress" [style.width.%]="uploader.progress" [ngClass]="{'progress-done': uploader.done && !uploader.error}"></div>
            
            <mat-icon *ngIf="uploader.error" tippy="{{'shared.file-uploader.error_with_upload' | transloco}}" svgIcon="hailer-warning-fill" class="warning-icon"></mat-icon>
            <span class="cancel-upload">
                <button mat-icon-button *ngIf="showTagSelector() && uploader.done && !uploader.error && uploader.fileId"
                    [color]="getUploaderErrorState(uploader.fileId) ? 'warn' : 'primary'" [matMenuTriggerFor]="tagMenu"
                    (menuOpened)="focusTagSelector()">
                    <mat-icon>local_offer_outline</mat-icon>
                </button>
    
                <mat-menu #tagMenu="matMenu">
                    <ng-container *ngIf="(tagSelectorOptions | async) && uploader.fileId">
                        <app-tag-selector (error)="setErrors($event, uploader.fileId)" (tagged)="fileTagged($event, uploader.fileId)"
                            (untagged)="fileUntagged($event, uploader.fileId)" [selectorOptions]="tagSelectorOptions"
                            [focusSelector]="tagSelectorFocus">
                        </app-tag-selector>
                    </ng-container>
                </mat-menu>
    
                <button mat-icon-button (click)="removeFile(uploader)">
                    <mat-icon svgIcon="hailer-x"></mat-icon>
                </button>
            </span>
        </div>
    </div>
</div>

<input data-cy="file-uploader-input" #fileInput hidden type="file" [accept]="getFileTypes().join(', ')" (change)="uploadFiles($event)" multiple />