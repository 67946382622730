/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

import { ExtendedMarkdownDirective } from './extended-markdown.directive';
import { ExtendedMarkdownService } from './extended-markdown.service';
import { MentionComponent } from './mention/mention.component';

@NgModule({
    declarations: [ExtendedMarkdownDirective, MentionComponent],
    providers: [ExtendedMarkdownService],
    imports: [CommonModule, TranslocoModule],
    exports: [ExtendedMarkdownDirective],
})
export class ExtendedMarkdownModule {}
