/** @format */

import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Optional, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, takeUntil } from 'rxjs';

import { PermissionListMember } from 'app/_models/member.model';
import { RPCService } from 'app/_services/rpc.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { InsightDoc, InsightService } from '../insight.service';

@Component({
    selector: 'app-insight-permission',
    templateUrl: './insight-permission.component.html',
    styleUrls: ['./insight-permission.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightPermissionComponent implements OnInit, OnDestroy {
    /** Insight to be edited, or null to create a new */
    @Input() insight: InsightDoc | null;

    selectorMembers: PermissionListMember[] = [];

    form = new FormGroup({
        public: new FormControl(false, Validators.required),
    });

    private onDestroy = new Subject();

    constructor(
        public sideNav: SideNavService,
        public translate: TranslocoService,
        public service: InsightService,
        public snackbar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private rpc: RPCService,
        @Optional() private dialogRef?: MatDialogRef<InsightPermissionComponent>,
        @Inject(MAT_DIALOG_DATA) @Optional() public data?: InsightDoc
    ) {}

    ngOnDestroy(): void {
        this.onDestroy.complete();
    }

    ngOnInit(): void {
        if (this.data) {
            // Opened in dialog, use data as insight
            this.insight = this.data;
        }

        if (!this.insight) {
            return;
        }

        this.form.setValue({
            public: !!this.insight.public,
        });

        this.form.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe({
            next: async data => {
                if (!(data.public ?? true) || data.public) {
                    await this.save(data);
                }
            },
        });

        this.selectorMembers = this.insight.members.map(member => ({ ...member, allowRemove: true }));
    }

    async save(insight: typeof this.form.value): Promise<void> {
        return this.rpc.requestAsync('v3.insight.update', [this.insight?._id, insight]);
    }

    close(): void {
        console.log('Close call...');
        if (this.dialogRef) {
            this.dialogRef.close();
        } else {
            this.sideNav.clear();
        }
    }

    async updatePermissions(insightId: string): Promise<void> {
        console.log('Update permissions...');
        const workspaceId = this.insight?.cid;

        const insights = (await this.rpc.requestAsync('v3.insight.list', [workspaceId])) as InsightDoc[];

        const insight = insights.find(insight => insight._id === insightId) as InsightDoc | null;

        if (!insight || !this.insight) {
            return;
        }

        this.insight.members = insight.members;
        this.selectorMembers = this.insight.members.map(member => ({ ...member, allowRemove: true }));
    }

    async memberAdd(insightId: string, memberId: string): Promise<void> {
        await this.rpc.requestAsync('v3.insight.member.add', [insightId, memberId]);
        await this.updatePermissions(insightId);
    }

    async memberRemove(insightId: string, memberId: string): Promise<void> {
        await this.rpc.requestAsync('v3.insight.member.remove', [insightId, memberId]);
        await this.updatePermissions(insightId);
    }

    error(message: string): void {
        this.snackbar.open(message, '×', {
            duration: 2000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
        });
    }
}
