<!-- @format -->

<div class="dialog-header">
    <h1 mat-dialog-title>{{ 'poll.view-results.title' | transloco }}</h1>
    <app-button
        type="icon"
        hailerIconName="hailer-x"
        color="icon-white-all-themes"
        tippyText="{{ 'shared.close-popup' | transloco }}"
        ariaLabel="{{ 'shared.close-popup' | transloco }}"
        (clickEvent)="close()"></app-button>
</div>

<div class="dialog-content-container">
    <h2 class="voters-menu-header">{{ data.pollName }}</h2>
    <mat-accordion multi="true">
        <mat-expansion-panel
            *ngFor="let option of data.pollOptions; let i = index"
            class="mat-elevation-z0"
            [hideToggle]="data.optionUserIds(option._id).length === 0"
            [disabled]="data.optionUserIds(option._id).length === 0">
            <mat-expansion-panel-header (click)="$event.stopPropagation()" class="voters-menu-panel-header">
                <p class="voters-menu-subheader">
                    {{ option.name }}
                    <span class="answer-count voters-menu-count"
                        >{{ data.optionUserIds(option._id).length }}
                        {{
                            (data.optionUserIds(option._id).length === 1 ? 'poll.vote-count-prefix-1' : 'poll.vote-count-prefix-2')
                                | transloco
                                | transloco
                        }}</span
                    >
                </p>
            </mat-expansion-panel-header>
            <button
                mat-menu-item
                (click)="data.openUserSidenav(userId)"
                *ngFor="let userId of data.optionUserIds(option._id.toString()); let i = index"
                class="view-votes-menu-item">
                <img width="32" height="32" [src]="data.profileImageBaseUrl + userId" aria-hidden="true" />
                <span class="one-line-ellipsis">{{ data.userNames[userId] }}</span>
            </button>
        </mat-expansion-panel>
    </mat-accordion>
</div>
