/** @format */

import { Location } from './activity.model';
import { User } from './user.model';
import { environment } from '@app/env';
import { GDrawable } from './gdrawable.model';

export class GMarker extends GDrawable<google.maps.Marker> {
    // Used only if this marker is displaying a users location.
    userData?: User;

    constructor(marker: google.maps.Marker, infoWindow: google.maps.InfoWindow, editable: boolean, customId?: string) {
        super(marker, infoWindow, editable, customId);
        this.data.setDraggable(editable);
    }

    static fromHailerToGoogle(location: Location): google.maps.Marker {
        if (location.type !== 'point') {
            console.error(new Error("gmap-component error: You can't create a marker from a polygon"));
            return null;
        }

        if (location.data) {
            const mark = new google.maps.Marker({
                position: { lat: location.data[0].lat, lng: location.data[0].lng },
                label: location.label,
            });
            return mark;
        }
        return null;
    }

    static fromHailerLocation(location: Location, draggable = false): GMarker {
        const marker = GMarker.fromHailerToGoogle(location);
        return new GMarker(marker, null, draggable);
    }

    static fromHailerLocationWithImage(location: Location, image: string): GMarker {
        const marker = GMarker.fromHailerToGoogle(location);
        const content = '';
        const infoWindow = new google.maps.InfoWindow({
            content,
        });
        return new GMarker(marker, infoWindow, false);
    }

    static fromLatLng(position: google.maps.LatLng): GMarker {
        const marker = new google.maps.Marker({
            position,
        });

        return new GMarker(marker, null, false);
    }

    static fromUser(user: User): GMarker {
        // ?Should this line thorw an error that is then caught outside the GMarker class?
        if (!user.lastLocation) {
            console.error('gmap-component error: Provided User lastLocation value is not truthy. ABORT! PANIC! DEATH!');
            return null;
        }

        const fullname = `${user.firstname} ${user.lastname}`;

        const marker = new google.maps.Marker({
            position: { lat: user.lastLocation.lat, lng: user.lastLocation.lng },
            title: fullname,
            draggable: false,
        });

        let content = '<div style="display:flex;justify-content:center;align-items:center;flex-direction:column">';
        content += `<img crossorigin style="width: 160px;" class="img-circle" src="${environment.wsUrl}/profileimage/square100/${user.default_profilepic}" alt="${fullname}">`;
        content += '<p style="font-weight:700;font-size:20px;margin:0px;margin-top:4px;text-transform: capitalize;">';
        content += fullname;
        content += '</p></div>';

        const infoWindow = new google.maps.InfoWindow({
            content,
        });

        const gmark = new GMarker(marker, infoWindow, false, user._id);
        gmark.userData = user;
        return gmark;
    }

    static fromGoogleMarker(mark: google.maps.Marker): GMarker {
        return new GMarker(mark, null, true);
    }

    equals(obj: any): boolean {
        if (obj instanceof GMarker) {
            return obj.data.getPosition().equals(this.data.getPosition());
        }
        return false;
    }
}
