/** @format */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hailerColor',
})
export class HailerColorPipe implements PipeTransform {
    private bodyStyles: CSSStyleDeclaration;

    transform(propertyName: string): string {
        if (!this.bodyStyles) {
            this.bodyStyles = window.getComputedStyle(document.body);
        }

        return this.bodyStyles.getPropertyValue(propertyName);
    }
}
