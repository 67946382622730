<div>
    <mat-form-field floatLabel="never">
        <textarea
            data-cy="comment-post-input"
            matInput
            placeholder="{{ 'feed.feed_comment_write.write' | transloco }}"
            [(ngModel)]="newComment.text"
            cdkTextareaAutosize
            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" (focus)="onFocus()" (blur)="onBlur()"></textarea>
    </mat-form-field>
    <app-file-uploader data-cy="comment-file-uploader" class="file-uploader" #uploader></app-file-uploader>
    <ng-container *ngIf="isFocused()">
        <div class="flex-row--space-between-centered margin-bottom-medium">
            <app-button
                dataCy="comment-write-attachments-button"
                type="icon"
                color="secondary"
                size="small"
                hailerIconName="hailer-attach"
                (clickEvent)="uploader.open(); onFocus(true)"
                tippyText="{{ 'feed.feed_comment_write.attach_files' | transloco }}"
                ariaLabel="{{ 'feed.feed_comment_write.attach_files' | transloco }}"></app-button>
            <app-button
                dataCy="post-send-comment-button"
                [isDisabled]="uploadInProgress || sending || !newComment.text.trim()"
                (clickEvent)="addComment()"
                size="small"
                label="{{ 'feed.feed_comment_write.send' | transloco }}"
                ariaLabel="{{ 'feed.feed_comment_write.send' | transloco }}"></app-button>
        </div>
    </ng-container>
</div>
