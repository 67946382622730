/** @format */

import { UntypedFormControl, Validators } from '@angular/forms';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { Company, PersonalSettings, Team, User } from '@app/models';
import { PeopleService } from 'app/people/people.service';
import { TeamService } from 'app/_services/team.service';
import { CoreService } from 'app/_services/core.service';
import { environment } from '@app/env';
import { TeamUserSelectorOptions } from 'app/_models/teamUserSelectorOptions.model';
import { DropdownSelectedItems } from 'app/_models/dropdown-selector.model';
import { TeamUserSelectorComponent } from '../user-team-selector/team-user-selector.component';
import { PermissionService } from 'app/_services/permission.service';

@Component({
    selector: 'app-user-invite-dialog',
    templateUrl: './user-invite-dialog.component.html',
    styleUrls: ['./user-invite-dialog.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class UserInviteDialogComponent implements OnInit, OnDestroy {
    @ViewChild('teamUserSelector', { static: true }) teamUserSelector: TeamUserSelectorComponent;

    userSelectorOptions = new BehaviorSubject<TeamUserSelectorOptions>(new TeamUserSelectorOptions());
    selectorHiddenItems = new BehaviorSubject<string[]>([]);
    selectorPredefinedItems = new BehaviorSubject<string[]>([]);
    invites: string[] = [];
    users: User[] = [];
    teams: Team[] = [];
    url: string = environment.wsUrl;
    user: BehaviorSubject<PersonalSettings>;
    guestEmail = new UntypedFormControl(null, Validators.email);

    private network: Company;
    private onDestroy = new Subject<void>();

    constructor(
        private core: CoreService,
        private team: TeamService,
        private people: PeopleService,
        public permissions: PermissionService,
        private dialog: MatDialogRef<UserInviteDialogComponent>,
        private translocoService: TranslocoService,
        @Inject(MAT_DIALOG_DATA)
        public options: {
            allowAllUsers: boolean;
            hiddenParticipants?: string[];
            predefinedParticipants?: string[];
            networkId?: string;
            allowGuests?: boolean;
            hideGuests?: boolean;
            discussionId?: string;
        }
    ) {}

    ngOnInit(): void {
        this.network = this.core.network.value;
        this.user = this.core.user;
        this.setSelectorOptions();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    setSelectorOptions() {
        const networkId = this.options.networkId || this.network._id;
        const showUsers = this.options.allowAllUsers ? 'all' : networkId;
        const hideTeams = this.options.allowAllUsers || (this.options.networkId && this.options.networkId !== this.network._id);

        const currentOptions = this.userSelectorOptions.value;

        this.userSelectorOptions.next({
            ...currentOptions,
            appearance: 'fill',
            multiple: true,
            placeholder: this.translocoService.translate('shared.user-invite-dialog.select_users_placeholder'),
            required: false,
            showTeams: hideTeams ? undefined : 'all',
            showUsers,
            openAutomatically: true,
            closeOnSelection: false,
        });

        let hiddenParticipants = this.options?.hiddenParticipants || [];
        const predefinedParticipants = this.options?.predefinedParticipants || [];

        if (!this.options.allowGuests) {
            hiddenParticipants = hiddenParticipants.concat(this.people.getNetworkGuestIds(networkId));
        }

        this.selectorPredefinedItems.next(predefinedParticipants);
        this.selectorHiddenItems.next(hiddenParticipants);
    }

    addFollower(results: DropdownSelectedItems): void {
        this.users = [];
        this.teams = [];
        const inviteUids: string[] = [];
        Object.keys(results).forEach(key => {
            results[key].forEach(_id => {
                if (key === 'team') {
                    const teamUsers = this.team.getTeam(_id).members || [];
                    this.teams.push(this.team.getTeam(_id));

                    teamUsers.forEach(teamUserId => {
                        const invited = inviteUids.includes(teamUserId) || this.options.hiddenParticipants?.includes(teamUserId);
                        if (!invited) {
                            inviteUids.push(teamUserId);
                        }
                    });
                } else {
                    const invited = inviteUids.includes(_id) || this.options.hiddenParticipants?.includes(_id);
                    if (!invited) {
                        inviteUids.push(_id);
                    }

                    const alreadyListed = this.users.find(user => user._id === _id);
                    if (!alreadyListed) {
                        this.users.push(this.people.getUser(_id));
                    }
                }
            });
        });

        this.invites = inviteUids;
    }

    sendGuestInvites() {
        if (this.guestEmail.pristine || !this.guestEmail.value || !this.guestEmail.valid || !this.options.discussionId) {
            return;
        }

        this.people.inviteGuestsToDiscussion([this.guestEmail.value], this.options.discussionId, this.options.networkId);
    }

    closeModal(): void {
        this.dialog.close([]);
    }

    removeSelection(removeId: string) {
        const selected = this.teamUserSelector.selectedItems.value;
        const filtered: DropdownSelectedItems = {};

        Object.keys(selected).forEach(key => {
            selected[key].forEach(_id => {
                if (_id === removeId) {
                    return;
                }

                if (!filtered[key]) {
                    filtered[key] = [_id];
                    return;
                }

                filtered[key].push(_id);
            });
        });

        this.teamUserSelector.selectedItems.next(filtered);
    }

    get disableSendButton(): boolean {
        const invalidEmail = this.guestEmail.invalid;
        const email = this.guestEmail.value;

        const selectedItems = !!this.users.length || !!this.teams.length;

        if (!selectedItems && !email) {
            return true;
        }

        if (email && invalidEmail) {
            return true;
        }

        return false;
    }

    get canInviteGuests(): boolean {
        const isAdmin = this.permissions.isNetworkInviter;
        return this.user.value.emailVerified && isAdmin;
    }
}
