<ng-template #defaultItemTemplate let-item="item">
    <app-user-profile-picture [size]="32" [userId]="item['_id']" style="margin-right: 8px"></app-user-profile-picture>
    {{ item[labelKey] }}
</ng-template>
<ul
    #list
    [hidden]="hidden"
    class="dropdown-menu scrollable-menu"
    [class.mention-menu]="!styleOff"
    [class.mention-dropdown]="!styleOff && dropUp"
>
    <li
        *ngFor="let item of items; let i = index"
        [class.active]="activeIndex == i"
        [class.mention-active]="!styleOff && activeIndex == i"
    >
        <a
            class="dropdown-item"
            [class.mention-item]="!styleOff"
            (mousedown)="activeIndex = i; itemClick.emit(); $event.preventDefault()"
        >
            <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-template>
        </a>
    </li>
</ul>