/** @format */

import { Component, Input, OnChanges, OnInit } from '@angular/core';
/** Creates an icon with a bubble. Bubble by default includes a number, is red and has white text.
 *
 * `badgeColor` is either `warn` (default), `primary` or `accent` and sets the bubble color
 *
 * `iconColor` sets the class of the icon, which sets the color of the icon.
 * Should be an existing icon class e.g. warning, primary, accent, white etc.
 *
 * `indicatorIcon` is where this icon is used to display pertinent information to the user but it is not clickable.
 * It is assumed that all interactive icons are contained within buttons or `<a></a>`
 *
 * If indicatorIcon is true, badgeDescription is required and will throw errors if not implemented.
 * `badgeDescription` sets the accessibility description.
 * Only necessary for indicator icons.
 * */

@Component({
    selector: 'app-icon-with-badge',
    templateUrl: './icon-with-badge.component.html',
    styleUrls: ['./icon-with-badge.component.scss'],
})
export class IconWithBadgeComponent implements OnInit, OnChanges {
    @Input() badgeNumber: number;
    @Input() badgeColor: 'accent' | 'primary' | 'warn' = 'warn';
    @Input() iconName: string;
    @Input() iconColor: string | null;
    @Input() hailerIconName: string;
    @Input() badgeDescription: string;
    @Input() indicatorIcon = false;
    @Input() bubbleOnly = false;

    constructor() {}

    ngOnInit() {
        if (this.indicatorIcon) {
            this.checkRequiredFields(this.badgeDescription);
        }
    }

    ngOnChanges(): void {
        if (this.indicatorIcon) {
            this.checkRequiredFields(this.badgeDescription);
        }
    }

    checkRequiredFields(input: string) {
        if (input === null) {
            throw new Error(`Attribute ${input} is required`);
        }
    }
}
