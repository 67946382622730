/** @format */

import { ChangeDetectionStrategy, Component, Input, Output, ViewChild, EventEmitter, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Team, User } from '@app/models';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Group } from 'app/_models/group.model';
import { GroupService } from 'app/_services/group.service';
import { SearchService } from 'app/_services/search.service';
import { TeamService } from 'app/_services/team.service';
import { PeopleService } from 'app/people/people.service';
import { QuillEditorComponent } from 'ngx-quill';
import { Subject, takeUntil } from 'rxjs';
import { NewLink } from '../../../../test/deps/hailer-api/shared/link-types';
import TurndownService from 'turndown';
import { environment } from '@app/env';
import 'quill-mention';
import { ActivitiesGlobalSearch } from 'app/_models/globalSearch.model';
import { CoreService } from 'app/_services/core.service';
import rgb2hex from 'rgb2hex';

@Component({
    selector: 'app-text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEditorComponent implements OnInit, OnDestroy {
    @Input() labelKey = 'label';
    /**
     * FormControl<string> for storing the text with any validators, e.g.
     * new FormControl<string>('', { validators: [Validators.required], nonNullable: true });
     */
    @Input() textForm: FormControl;
    /**
     * Any additional Quill modules, mentions and toolbar are added by text-editor component
     */
    @Input() modules;
    /**
     * Placeholder text to display in input before user inputs anything
     */
    @Input() placeholder: string;
    /**
     * Custom styles for the Quill editor, e.g.
     * { background: '#0000001a', 'min-height': '150px' }
     */
    @Input() styles;
    /**
     * Users, teams and groups to filter with the users in the @ user menu [user_id, team_id, group_id]
     */
    @Input() recipients: string[];

    @Output() readonly outputText = new EventEmitter<string>();

    @Input() dataCy: string;
    @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent | undefined;

    insertedMentions: DOMStringMap[] = [];
    private baseUrl = `${environment.wsUrl}/image/square25/`;
    private onDestroy = new Subject<void>();
    private defaultPictureId = '55ed7103a6aab38b40ca1b38';

    constructor(
        private teamService: TeamService,
        private groupService: GroupService,
        private searchService: SearchService,
        private peopleService: PeopleService,
        private cdr: ChangeDetectorRef,
        private core: CoreService,
    ) {}

    ngOnInit(): void {
        if (this.textForm === undefined) {
            this.textForm = new FormControl<string>('');
        }

        if (this.placeholder === undefined) {
            this.placeholder = 'No placeholder given!';
        }

        this.textForm.statusChanges.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.cdr.detectChanges(),
        });

        const modules = {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'], // Toggled buttons
                [{ 'header': 1 }, { 'header': 2 }], // Custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'color': [] }],
            ],
            mention: {
                mentionDenotationChars: ['@', '#'],
                showDenotationChar: true,
                dataAttributes: ['_id', 'display_name', 'name', 'type', 'denotationChar'],
                onSelect: (item: any, insertItem: any) => {
                    const editor = this.editor?.quillEditor;
                    item.value = item.display_name ? item.display_name : item.name;
                    this.insertedMentions.push(item);
                    insertItem(item);
                    // Necessary because quill-mention triggers changes as 'api' instead of 'user'
                    editor?.insertText(editor.getLength() - 1, '', 'user');
                },
                source: async (searchTerm, renderList, mentionChar) => {
                    let userMatches: any[] = [];
                    let activityMatches: any[] = [];
                    // Render a list of user if @, activities if #
                    if (mentionChar === '@') {
                        userMatches = (await this.suggestPeople(searchTerm)).map(user => ({ ...user, type: 'user' }));
                    } else {
                        activityMatches = (await this.suggestActivities(searchTerm))?.map(user => ({ ...user, type: 'activity' })) || [];
                    }
                    renderList([...userMatches, ...activityMatches]);
                },
                renderItem: (item, searchTerm) => {
                    // Display name of users, name of activities
                    item.name = item.display_name ? item.display_name : item.name;

                    const mainDiv = document.createElement('div');
                    mainDiv.classList.add('flex-column');

                    const nameSpan = document.createElement('span');
                    nameSpan.classList.add('flex-align-center');
                    nameSpan.classList.add('text-editor-link-name');
                    nameSpan.innerText = item.name;

                    mainDiv.append(nameSpan);

                    if (item.workflowName) {
                        const subheaderDiv = document.createElement('div');
                        subheaderDiv.classList.add('flex-align-center');
                        subheaderDiv.classList.add('text-editor-link-subheader');
                        subheaderDiv.innerText = `${item.workflowName} - ${item.phaseName}`;
                        mainDiv.append(subheaderDiv);
                    }

                    // Add profile picture to users
                    if (item.display_name) {
                        const img = document.createElement('img');
                        img.classList.add('mention-profile-img');
                        img.src = `${this.baseUrl}${item.default_profilepic ? item.default_profilepic : this.defaultPictureId}`;
                        nameSpan.prepend(img);
                    }

                    return mainDiv;
                },
            },
        };

        this.modules = { ...modules, ...this.modules };
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    /**
     * Returns text with links and HTML tags modified for the backend
     * [ modifiedText: string, links: NewLink[] ]
     */
    get modifiedTextAndLinks(): [string, NewLink[]] {
        const links: NewLink[] = [];

        // Loops thru the inserted mentions and checks if they still exist in the text, replaces them with hailerTag and adds them to links
        this.insertedMentions.forEach((mention: DOMStringMap) => {
            if (
                mention._id?.length !== 24 ||
                mention.denotationChar?.length !== 1 ||
                !mention.type ||
                !mention.value ||
                !this.textForm.value
            ) {
                return;
            }
            const searchStr = `<span contenteditable="false">${mention.denotationChar}${mention.value}</span>`;
            const replace = `[hailerTag|${mention.value}](${mention._id})`;
            if (this.textForm.value.indexOf(searchStr) > -1) {
                const newText = this.textForm.value.replace(searchStr, replace);
                this.textForm.setValue(newText);
                links.push({ target: mention._id, targetType: mention.type, type: 'linked-to' });
            }
        });

        const turndownService = new TurndownService();

        // Doesn't escape any manually typed syntaxes
        TurndownService.prototype.escape = (value: any) => {
            return value;
        };

        turndownService.addRule('strikethrough', {
            filter: ['del', 's', 'strike'],
            replacement: function (content) {
                return `~${content}~`;
            },
        });

        // Keeps the color span tag, expect on hailer tags
        turndownService.addRule('color', {
            filter: function (node, options) {
                return node?.getAttribute('style')?.includes('color') && !node.innerHTML.includes('[hailerTag|');
            },
            replacement: function (content, node, options) {
                const color = node?.getAttribute('style').slice(7);
                // Color is already a hex, from e.g. editing a feed post
                if (color.length < 8) {
                    return `[coloredText|${content}](${color})`;
                }
                // Color is rgba and needs to be turned into hex
                const hex = rgb2hex(color.slice(0, -1)).hex;
                return `[coloredText|${content}](${hex})`;
            },
        });

        // Underlining text is not a Markdown standard syntax, but it needs to be kept
        turndownService.keep(['u']);

        const turndown = turndownService.turndown(this.textForm.value || '');

        this.outputText.emit(turndown);
        return [turndown, links];
    }

    async suggestActivities(searchTerm: string): Promise<ActivitiesGlobalSearch[]> {
        const shortSearchTerm = searchTerm.length < 3;

        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, prettier/prettier
        let { activities }: {activities: ActivitiesGlobalSearch[]} = (
            await this.searchService.globalSearchAsync(!shortSearchTerm ? searchTerm : '', ['activities'])
        ) || {
            activities: [],
        };

        activities = activities.map(activity => {
            const workflow = this.core.processById(activity.process);
            const phase = workflow.phases[activity.currentPhase];

            return { ...activity, workflowName: workflow.name, phaseName: phase?.name };
        });

        if (shortSearchTerm) {
            return activities.filter(activity => activity.name.toLocaleLowerCase().trim().includes(searchTerm.toLocaleLowerCase().trim()));
        }

        return activities;
    }

    async suggestPeople(searchTerm) {
        const allUsers = this.peopleService.usersByNetwork.value;

        if (!this.recipients || this.recipients.length < 1) {
            return allUsers.filter(user => user.display_name.includes(searchTerm));
        }
        const selectableUsers: any[] = [];
        for (const id of this.recipients || []) {
            let teamId: string;
            let groupId: string;
            let team: Team | undefined;
            let user: User | undefined;
            let group: Group | undefined;
            switch (id.substring(0, 5)) {
                case 'user_':
                    if (selectableUsers.find(user => user._id === id.substring(5))) {
                        break;
                    }
                    user = allUsers.find(user => user._id === id.substring(5));
                    if (!user) {
                        break;
                    }
                    selectableUsers.push(user);
                    break;
                case 'team_':
                    teamId = id.substring(5);
                    team = (await this.teamService.loadTeam(teamId)) || undefined;
                    if (!team) {
                        break;
                    }
                    for (const userId of team.members) {
                        if (selectableUsers.find(user => user._id === userId)) {
                            break;
                        }
                        user = allUsers.find(user => user._id === userId);
                        if (!user) {
                            break;
                        }
                        selectableUsers.push(user);
                    }
                    break;
                case 'group':
                    groupId = id.substring(6);
                    group = (await this.groupService.loadGroup(groupId)) || undefined;
                    if (!group) {
                        break;
                    }
                    for (const userId of group.users) {
                        if (selectableUsers.find(user => user._id === userId)) {
                            break;
                        }
                        user = allUsers.find(user => user._id === userId);
                        if (!user) {
                            break;
                        }
                        selectableUsers.push(user);
                    }
                    break;
                default:
                    break;
            }
        }
        return selectableUsers.filter(user => user.display_name.includes(searchTerm));
    }

    get requiredError(): boolean {
        return this.textForm.touched && this.textForm.invalid;
    }
}
