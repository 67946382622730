/** @format */

import { Injectable } from '@angular/core';
import * as _moment from 'moment';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import {
    ActivityFieldMap,
    ActivityLinkField,
    ContactField,
    CountryField,
    DateField,
    DateRangeField,
    NumericField,
    NumericUnitField,
    Process,
    ProcessField,
    TeamsField,
    TextAreaField,
    TextField,
    TextPredefinedOptionsField,
    TextUnitField,
    TimeField,
    TimeRangeField,
    UserField,
} from '@app/models';
import { CoreService } from './core.service';

@Injectable()
export class ActivityFieldService {
    constructor(private core: CoreService) {}

    getDefaultToValue(field: ProcessField): any {
        // Round to closest 5 minutes
        let currentTime = _moment();
        const remainder = currentTime.minute() % 5;
        if (remainder < 3) {
            currentTime = _moment(currentTime).subtract(remainder, 'minutes');
        } else {
            currentTime = _moment(currentTime).add(5 - remainder, 'minutes');
        }

        switch (field.type) {
            case 'date':
            case 'datetime':
                if (field.defaultTo) {
                    return _moment().toISOString();
                }
                return null;
            case 'daterange':
                if (field.defaultTo) {
                    return [_moment().startOf('day').toISOString(), _moment().add(1, 'days').startOf('day').toISOString()];
                }
                return null;
            case 'datetimerange':
                if (field.defaultTo) {
                    return [currentTime.toISOString(), _moment(currentTime).add(2, 'hours').toISOString()];
                }
                return { end: null, start: null };
            case 'time':
                if (field.defaultTo) {
                    return currentTime.toISOString();
                }
                return null;
            case 'timerange':
                if (field.defaultTo) {
                    return {
                        end: _moment(currentTime).add(2, 'hours').toISOString(),
                        start: currentTime.toISOString(),
                    };
                }
                return { end: null, start: null };
            case 'users':
                if (field.defaultTo) {
                    return this.core.user.value.id;
                }
                return null;
            default:
                if (field.defaultTo) {
                    return field.defaultValue;
                }
        }
    }

    fieldHasValue(fieldValue: any): boolean | null {
        if (Array.isArray(fieldValue)) {
            let notNulls = 0;
            fieldValue.forEach(value => {
                if (value) {
                    notNulls++;
                }
            });
            return notNulls > 0;
        }

        if (fieldValue === null || fieldValue === undefined) {
            return false;
        } else if (
            fieldValue.hasOwnProperty('end') &&
            fieldValue.hasOwnProperty('start') &&
            fieldValue.end === null &&
            fieldValue.start === null
        ) {
            return false;
        } else if (
            fieldValue.hasOwnProperty('end') &&
            fieldValue.hasOwnProperty('start') &&
            isNaN(fieldValue.end) &&
            isNaN(fieldValue.start)
        ) {
            return false;
        } else if (fieldValue.hasOwnProperty('code') && fieldValue.code === null) {
            return false;
        } else if (
            fieldValue.hasOwnProperty('_id') &&
            fieldValue.hasOwnProperty('name') &&
            fieldValue.name === null &&
            fieldValue._id === null
        ) {
            return false;
        }
        return true;
    }

    getFieldValue(fieldValue: any): { value: any } {
        if (fieldValue === null || fieldValue === undefined) {
            return { value: null };
        } else if (
            fieldValue.hasOwnProperty('end') &&
            fieldValue.hasOwnProperty('start') &&
            fieldValue.end === null &&
            fieldValue.start === null
        ) {
            return { value: null };
        } else if (fieldValue.hasOwnProperty('code') && fieldValue.code === null) {
            return { value: null };
        } else if (
            fieldValue.hasOwnProperty('_id') &&
            fieldValue.hasOwnProperty('name') &&
            fieldValue.name === null &&
            fieldValue._id === null
        ) {
            return { value: null };
        }
        return { value: fieldValue };
    }

    initActivityFields(process: Process): ActivityFieldMap {
        const fields = {};

        for (const fieldId of Object.keys(process.fields)) {
            fields[fieldId] = this.initActivityField(process.fields[fieldId]);
        }

        return fields;
    }

    addControl(
        process: Process,
        fieldId: string,
        fields: UntypedFormGroup,
        value: any,
        formBuilder: UntypedFormBuilder,
        editMultiple?: boolean
    ) {
        if (process.fields[fieldId].type === 'timerange') {
            fields.addControl(
                `${fieldId}`,
                formBuilder.group({
                    value: formBuilder.group(
                        {
                            end: new UntypedFormControl(value ? value.end : null),
                            start: new UntypedFormControl(value ? value.start : null),
                        },
                        process.fields[fieldId].required ? Validators.required : []
                    ),
                })
            );
        } else {
            fields.addControl(
                `${fieldId}`,
                formBuilder.group({
                    value: new UntypedFormControl(value, process.fields[fieldId].required && !editMultiple ? Validators.required : []),
                })
            );
        }

        fields.markAllAsTouched();
        // Fields.get(fieldId).markAsTouched();
    }

    private initActivityField(field: ProcessField): any {
        switch (field.type) {
            case 'activitylink':
                return new ActivityLinkField({ name: null, _id: null });
            case 'contacts':
                return new ContactField(null);
            case 'country':
                // eslint-disable-next-line @typescript-eslint/naming-convention
                return new CountryField({ code: null, 'country-code': null, name: null });
            case 'date':
                return new DateField(null);
            case 'daterange':
                return new DateRangeField({
                    start: null,
                    end: null,
                });
            case 'numeric':
                return new NumericField(null);
            case 'numericunit':
                return new NumericUnitField(null);
            case 'teams':
                return new TeamsField(null);
            case 'textarea':
                return new TextAreaField('');
            case 'text':
                return new TextField('');
            case 'textpredefinedoptions':
                return new TextPredefinedOptionsField(null);
            case 'textunit':
                return new TextUnitField('');
            case 'time':
                return new TimeField(null);
            case 'timerange':
                return new TimeRangeField({
                    start: null,
                    end: null,
                });
            case 'users':
                return new UserField(null);
            default:
                return { value: null };
        }
    }
}
