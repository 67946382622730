<!-- @format -->

<div class="discussion-participating">
    <mat-icon class="participation-icon" color="primary" svgIcon="hailer-meeting"></mat-icon>
    <div>
        {{ 'discussion.message_meet.started_by' | transloco }}
        <app-user-link [userId]="message.uid" [color]="participantColorMap[message.uid] || 'var(--user-link-default)'"> </app-user-link>
        .
    </div>
</div>
<!--

If Jitsi if re-enabled, see the external jisti angular project in /projects/jitsi and link there
    
<div>
	<a (click)="openMeeting()">
		{{ "discussion.message_meet.click" | transloco }}
	</a>
</div>
-->
