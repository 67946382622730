<div [ngSwitch]="field.type" [ngClass]="discussionMode" class="activity-field-container">
    <div class="field-label">
        {{field.label}}
        <span *ngIf="field.unit">({{field.unit}})</span>
    </div>

    <div *ngSwitchCase="'users'" class="container align-items-center">
        <ng-container *ngIf="data.value">
            <app-user-image-link (click)="openUserSidenav(data.value)" [userId]="data.value" [disableTooltip]="true"
                class="profile-image">
            </app-user-image-link>
            {{getUser(data.value)?.display_name || ''}}
        </ng-container>
    </div>

    <div *ngSwitchCase="'numeric'">
        <div class="center-container" *ngIf="!field.modifier?.checkbox">
            {{data?.value | thousandSeparator}}
        </div>
    
        <div class="checkbox-div" *ngIf="field.modifier?.checkbox">
            <mat-checkbox [checked]="!!data.value" (click)="$event.preventDefault()"></mat-checkbox>
        </div>
    </div>

    <div class="center-container" *ngSwitchCase="'numericunit'">
        {{data?.value | thousandSeparator}}
    </div>

    <div class="center-container" *ngSwitchCase="'country'">
        <span class="flag-icon fi" [ngClass]="flagClass"></span>
        {{data.value?.name || ''}}
    </div>

    <div class="center-container" *ngSwitchCase="'teams'">
        {{teamName}}
    </div>

    <div class="center-container" *ngSwitchCase="'text'">
        <span *ngIf="field.modifier?.file">{{getFieldFileCount(data.value)}} <mat-icon class="fileinfo" >insert_drive_file</mat-icon></span>
        <span *ngIf="!field.modifier?.file" class="text-field" [innerHTML]="data?.value | htmlEscape | hashtag | urlify | safe:'html'"></span>
    </div>

    <div class="center-container" *ngSwitchCase="'textpredefinedoptions'">
        <span class="text-field" [innerHTML]="data?.value | htmlEscape | hashtag | urlify | safe:'html'"></span>
    </div>

    <!-- Forgotten in old frontend? -->
    <div class="center-container" *ngSwitchCase="'textunit'">
        <p class="text-field">{{data?.value}}</p>
    </div>

    <div class="center-container text-area" *ngSwitchCase="'textarea'">
        <span class="text-area" [innerHTML]="data?.value | htmlEscape | hashtag | urlify | safe:'html'"></span>
    </div>

    <div class="center-container" *ngSwitchCase="'date'">
        {{data?.value | date:'MMM d, y': '+0000': ('misc.date.locale' | transloco)}}
    </div>

    <div class="center-container" *ngSwitchCase="'datetime'">
        {{data?.value | date:'MMMM d, HH:mm': undefined: ('misc.date.locale' | transloco)}}
    </div>

    <div class="center-container" *ngSwitchCase="'daterange'">
        <span *ngIf="data.value?.start">{{data?.value?.start || '' | date:'MMM d, y': '+0000': ('misc.date.locale' | transloco)}}</span>
        <span *ngIf="data.value?.end"> - {{data?.value?.end || '' | date:'MMM d, y': '+0000': ('misc.date.locale' | transloco)}}</span>
    </div>

    <div class="center-container" *ngSwitchCase="'datetimerange'">
        <span *ngIf="data.value?.start">{{data?.value?.start || '' | date:'MMMM d, HH:mm': undefined: ('misc.date.locale' | transloco)}}&nbsp;</span>
        <span *ngIf="data.value?.end"> - {{data?.value?.end || '' | date:'MMMM d, HH:mm': undefined: ('misc.date.locale' | transloco)}}</span>
    </div>

    <div class="center-container" *ngSwitchCase="'time'">
        {{data?.value | date:'HH:mm'}}
    </div>

    <div class="center-container" *ngSwitchCase="'timerange'">
        <span *ngIf="data.value?.start">{{data?.value?.start || '' | date:'HH:mm'}}</span>
        <span *ngIf="data.value?.end"> - {{data?.value?.end || '' | date:'HH:mm'}}</span>
    </div>

    <!-- Open activity by clicking -->
    <!-- Check if in same network or display message -->
    <div *ngSwitchCase="'activitylink'">
        <span class="center-container" *ngIf="data.value && data.value.name">{{data?.value?.name || ''}}</span>
    </div>

    <div class="center-container" *ngSwitchDefault>
        {{data?.value}}
    </div>

</div>