/** @format */

export class ProcessFieldIcons {
    activitylink = { type: 'custom', icon: 'hailer-activity' };
    country = { type: 'custom', icon: 'earth' };
    date = { type: 'ng', icon: 'today' };
    datetime = { type: 'ng', icon: 'today' };
    daterange = { type: 'ng', icon: 'date_range' };
    datetimerange = { type: 'ng', icon: 'date_range' };
    numeric = { type: 'custom', icon: 'numeric' };
    numericunit = { type: 'custom', icon: 'numeric' };
    teams = { type: 'custom', icon: 'account-multiple' };
    textarea = { type: 'custom', icon: 'card-text-outline' };
    text = { type: 'custom', icon: 'format-text' };
    textpredefinedoptions = { type: 'ng', icon: 'list' };
    textunit = { type: 'custom', icon: 'format-text' };
    time = { type: 'ng', icon: 'access_time' };
    timerange = { type: 'ng', icon: 'link' };
    users = { type: 'ng', icon: 'link' };
    linkedfrom = { type: 'custom', icon: 'hailer-linked-activity' };
    subheader = { type: 'ng', icon: 'title' };
}
