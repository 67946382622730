/** @format */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { PeopleService } from 'app/people/people.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { WindowListenerService } from '../../_services/window-listener.service';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';

@Component({
    selector: 'app-user-link-list',
    templateUrl: './user-link-list.component.html',
    styleUrls: ['./user-link-list.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class UserLinkListComponent implements OnInit, OnDestroy {
    @Input() userIds: string[];

    limit: number;
    innerWidth = window.innerWidth;
    private onDestroy = new Subject<void>();

    constructor(
        private peopleService: PeopleService,
        private sideNavService: SideNavService,
        private windowListener: WindowListenerService
    ) {}

    ngOnInit() {
        this.setLimit();
        this.windowListener.size.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.setLimit(),
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    setLimit() {
        this.innerWidth = window.innerWidth;
        this.limit = Math.floor(this.innerWidth / 400);
        this.limit = this.limit < 1 ? 1 : this.limit > 4 ? 4 : this.limit;
    }

    getDisplayName(userId: string) {
        return this.peopleService.getUser(userId).display_name;
    }

    openUserSidebar(userId: string) {
        this.sideNavService.clear();
        this.sideNavService.create(UserDetailComponent, {
            userId,
        });
    }
}
