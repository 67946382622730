/** @format */

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { DropdownItem } from 'app/_models/dropdown-selector.model';
import { CoreService } from 'app/_services/core.service';
import { StylingHelperService } from 'app/_services/styling-helper.service';

@Component({
    selector: 'app-dropdown-workspace-item',
    templateUrl: './dropdown-workspace-item.component.html',
    styleUrls: ['./dropdown-workspace-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownWorkspaceItemComponent implements OnInit {
    @Input() item: DropdownItem;

    constructor(public core: CoreService, public stylingHelperService: StylingHelperService) {}

    ngOnInit(): void {}

    get networkLogo(): string {
        return this.item?.title?.substring(0, 1).toUpperCase();
    }
}
