/** @format */

import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { concatMap, map, switchMap, take, takeUntil, throttleTime } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

import { Calendar, Event, EventInviteResult, GenericUpdate, PersonalSettings } from '@app/models';
import { EventHelperService } from '../_services/event-helper.service';
import { RPCService } from '../_services/rpc.service';
import { ICalLink, ICalLinkResult } from '../_models/iCalLink.model';
import { CoreService } from '../_services/core.service';
import { AllowedCalendarViews } from 'app/_services/event-helper.service';
import { EventLoadOptions } from 'app/_models/eventLoadOptions.models';
import { HelperService } from 'app/_services/helper.service';
import { TranslateService } from 'app/_services/translate.service';

@Injectable({
    providedIn: 'root',
})
export class EventsService {
    eventRemoved = new Subject<string>();
    calendars = new BehaviorSubject<Calendar[]>([]);
    eventsReload = new Subject<string[]>();
    reloadCalendars = new Subject<any[]>();
    reloadAsObservable = new Subject<any[]>();
    eventReload = new Subject<string>();
    iCalLinks = new BehaviorSubject<any[]>([]);
    upcomingEvents = new BehaviorSubject<Event[]>([]);
    calendarView = new BehaviorSubject<any>({});
    closeCreateMenu = new Subject<void>();
    openCreateMenu = new Subject<void>();
    calendarStart = new BehaviorSubject<number>(null);
    calendarEnd = new BehaviorSubject<number>(null);
    resetActivityFilter = new Subject<boolean>();

    private generatedUrl: any = {};
    private networkId: string;
    private personalSettings: PersonalSettings;

    constructor(
        private core: CoreService,
        private eventHelperService: EventHelperService,
        private rpc: RPCService,
        private helperService: HelperService,
        private translocoServivce: TranslocoService,
        private translateService: TranslateService
    ) {
        this.init();
    }

    createCalendar(newCalendar: Calendar): Observable<Calendar> {
        const result = new Subject<Calendar>();

        this.rpc
            .request('calendar.create_calendar', [newCalendar])
            .pipe(take(1))
            .subscribe({
                next: (calendar: Calendar) => result.next(calendar),
                error: (error: any) => result.error(error),
            });

        return result.asObservable();
    }

    getCalendar(calendarId: string): Calendar {
        const result = this.calendars.value.find((calendar: Calendar) => calendar._id === calendarId);
        return result;
    }

    getCalendarName(calendarId: string): string {
        const result: Calendar = this.calendars.value.find((calendar: Calendar) => calendar._id === calendarId);
        return result.name;
    }

    getCalendars(): Calendar[] {
        const calendars: Calendar[] = Object.values(this.core.calendars[this.core.network.value._id]);
        return calendars.sort(this.helperService.sortAlphabetically('name'));
    }

    getLocalTimezone(timezone?: string): string {
        if (!moment || !moment.tz) {
            return '';
        }

        if (!timezone) {
            return '';
        }

        if (timezone !== 'local') {
            return timezone;
        }

        return moment.tz.guess();
    }

    inviteRespond(response: any, userId: string): Observable<EventInviteResult> {
        return this.rpc.request('calendar.invite_respond', [response, userId]);
    }

    removeAttender(eventId: string, userId: string): Observable<any> {
        return this.rpc.request('calendar.remove_invite', [eventId, userId]);
    }

    repeatSummary(
        repeatType: string,
        repeatDensity: number,
        endOfOccurrences: string,
        endAfterNumber: number,
        endAfterDate: string
    ): string {
        let summaryType = '';
        let summaryDensity = '';
        let summaryOccurrences = '';
        let summaryDate = '';
        let returnValue = '';

        if (repeatDensity === 1) {
            summaryType = repeatType;
            summaryDensity = '';
        } else {
            let repeatTypeFormatted: string;
            if (repeatType === 'Daily') {
                repeatTypeFormatted = 'days';
            } else if (repeatType === 'Weekly') {
                repeatTypeFormatted = 'weeks';
            } else if (repeatType === 'Monthly') {
                repeatTypeFormatted = 'months';
            }
            summaryDensity = `Every ${repeatDensity} ${repeatTypeFormatted}`;
        }

        if (endOfOccurrences === 'never') {
            // EndOfOccurrences = '';
        } else if (endOfOccurrences === 'after') {
            summaryOccurrences = ` for ${endAfterNumber} occurrences`;
        } else if (endOfOccurrences === 'date') {
            if (moment(endAfterDate).isValid()) {
                summaryDate = moment(endAfterDate).format('MMMM D, YYYY');
                summaryOccurrences = `, until ${summaryDate}`;
            } else {
                summaryOccurrences = ``;
            }
        }
        returnValue = `${summaryType}${summaryDensity}${summaryOccurrences}`;
        return returnValue;
    }

    createEvent(event: Event): Observable<any> {
        event = this.eventHelperService.processOutgoingDates([event])[0];
        return this.rpc.request('calendar.create_event', [event]);
    }

    updateEvent(event: Event, changeType: string, repeatId: string): Observable<Event> {
        event = this.eventHelperService.processOutgoingDates([event])[0];
        const updateAbleEvent = this.eventHelperService.cleanEvent(event);
        let updateOptions = {};
        if (repeatId) {
            updateOptions = {
                repeat_id: repeatId,
                update: changeType,
            };
        }
        return this.rpc.request('calendar.update_event', [updateAbleEvent, updateOptions]);
    }

    addAttendees(eventId: string, userIds: string[]): Observable<any> {
        const addResult = new Subject<any>();

        this.loadEvent(eventId)
            .pipe(
                map((event: Event) => {
                    userIds.forEach(userId => {
                        if (event.attendees[userId] === undefined) {
                            event.attendees[userId] = null;
                        }
                    });

                    return event;
                }),
                concatMap((event: Event) => this.updateEvent(event, 'all', event.repeat_id))
            )
            .subscribe({
                next: () => {
                    addResult.next(null);
                    addResult.complete();
                },
                error: (error: any) => addResult.error(error),
            });

        return addResult.asObservable();
    }

    // Returns the _id of removed event
    removeEvent(eventId: string, changeType: string, repeatId: string): Observable<string> {
        const options = {
            remove: changeType,
            repeat_id: repeatId,
        };

        return this.rpc.request('calendar.remove_event', [eventId, options]);
    }

    removeCalendar(calendarId: string): Observable<boolean> {
        return this.rpc.request('calendar.remove_calendar', [calendarId]);
    }

    updateCalendar(calendar: Calendar): Observable<GenericUpdate> {
        const result = new Subject<GenericUpdate>();

        this.rpc.request('calendar.update_calendar', [calendar]).subscribe({
            next: (updateResult: GenericUpdate) => {
                result.next(updateResult);
            },
            error: (error: any) => {
                console.error(error);
                result.error(error);
            },
        });

        return result.asObservable();
    }

    loadEvent(eventId: string): Observable<Event> {
        const results = new Subject<Event>();

        this.rpc.request('calendar.load_event', [eventId]).subscribe({
            next: (event: Event) => {
                event = this.eventHelperService.processIncomingDates([event])[0];

                results.next(event);
                results.complete();
            },
            error: (error: any) => results.error('Invalid event'),
        });

        return results.asObservable();
    }

    loadTimelineEvents(args: any): Observable<any> {
        args[0] = moment(args[0]).startOf('month').valueOf();
        args[1] = moment(args[1]).add(14, 'days').valueOf();
        const results = new Subject<any>();
        this.rpc.request('v3.activity.timeline', args).subscribe({
            next: (data: any) => {
                // Needs backend update so duplicate resources aren't sent when asking with name
                const resourceMap = {};
                for (const resource of data.resources) {
                    if (!resourceMap[resource.groupBy + resource.title]) {
                        resourceMap[resource.groupBy + resource.title] = resource;
                    }
                }
                const activities: any[] = [];
                for (const event of data.activities) {
                    if (event.allDay && event.end) {
                        event.end = moment(event.end).add(1, 'days').toISOString();
                    }
                    activities.push(event);
                }
                results.next({ activities, resources: Object.values(resourceMap) });
                results.complete();
            },
            error: () => results.error({}),
        });
        return results.asObservable();
    }

    loadEvents(options: EventLoadOptions, nameFilter?: string): Observable<Event[]> {
        return new Observable<Event[]>(observer => {
            this.calendars
                .pipe(
                    switchMap(calendars => {
                        if (!options.calendars || options.calendars.length === 0) {
                            options.calendars = calendars.map(calendar => calendar._id);
                        }
                        return this.rpc.request('calendar.load_events', [options]);
                    }),
                    take(1)
                )
                .subscribe({
                    next: (_events: Event[]) => {
                        _events = this.eventHelperService.processIncomingDates(_events);
                        const events: Event[] = [];
                        for (const event of _events) {
                            if (
                                nameFilter &&
                                !event?.title?.toLowerCase().replace(/\s/g, '').includes(nameFilter.toLowerCase().replace(/\s/g, ''))
                            ) {
                                continue;
                            }
                            event.start = moment(event.start).toISOString();
                            event.end = moment(event.end).toISOString();
                            event.color = event.calendar ? event.calendar.color : '#000';
                            event.resourceId = event._id;
                            events.push(new Event(event));
                        }

                        observer.next(events);
                        observer.complete();
                    },
                    error: (error: any) => {
                        console.error('load_events error:', error);
                        observer.error('Unable to load events');
                    },
                });
        });
    }

    listIcalLinks(): void {
        this.rpc.request('calendar.list_ical_links', []).subscribe({
            next: (iCalLinks: ICalLinkResult) => {
                for (const i of Object.keys(iCalLinks)) {
                    const iCal: ICalLink = iCalLinks[i];
                    this.generatedUrl[iCal.calendar] = iCal;
                }
                this.iCalLinks.next(this.generatedUrl);
            },
            error: (error: any) => {
                console.error('Unable to list iCal Links: ', error);
                this.iCalLinks.error('Unable to list iCal Links');
            },
        });
    }
    // TODO: what the hell does this return?
    removePersonalLink(): void {
        this.rpc.request('calendar.remove_own_ical', []).subscribe({
            next: (_removedPersonalICal: any) => (this.personalSettings.personalIcalLink = undefined),
            error: (error: any) => console.error('Unable to remove iCal link: ', error),
        });
    }

    /* Sooooooooo.... Do we really need 3 functions to remove an ical link?
       And two to create them?
       Some refactoring shenanigans? */
    createOwnIcal(): Observable<ICalLink> {
        return this.rpc.request('calendar.create_own_ical', []);
    }

    removeOwnIcal() {
        return this.rpc.request('calendar.remove_own_ical', []);
    }

    removeIcalLink(calendarId: string, generatedUrl: any): Observable<string> {
        const url = generatedUrl[calendarId].url;
        return this.rpc.request('calendar.remove_ical_link', [calendarId, url]);
    }

    removePersonalIcalLink() {
        return this.rpc.request('calendar.remove_own_ical', []);
    }

    // TODO: what does this return?
    generateIcalLink(calendarId: string): Observable<any> {
        return this.rpc.request('calendar.generate_ical_link', [calendarId]);
    }

    getUpcomingEvents(calendarId?: string): void {
        this.calendars.pipe(switchMap(() => this.rpc.request('calendar.upcoming_events', calendarId ? [calendarId] : []))).subscribe({
            next: (events: Event[]) => {
                const returnValue: Event[] = [];
                events.forEach(event => {
                    const upcomingEvent = event;
                    const calendar = this.calendars.value.find(_calendar => _calendar._id === event.calendar_id);

                    this.translateService.translationLoaded('events').then(() => {
                        upcomingEvent.calendarName = calendar ? calendar.name : this.translocoServivce.translate('events.unknown_calendar');
                        upcomingEvent.color = calendar ? calendar.color : '#000';
                        upcomingEvent.id = upcomingEvent._id;
                    });

                    returnValue.push(upcomingEvent);
                });
                this.upcomingEvents.next(returnValue);
            },
            error: (error: any) => this.upcomingEvents.error(error),
        });
    }

    joinEventDiscussion(discussionId: string): Observable<any> {
        return this.rpc.request('messenger.join_event_discussion', [discussionId]);
    }

    saveCalendarView(view: AllowedCalendarViews) {
        this.rpc
            .request('user.save_settings', ['calendarView', view])
            .pipe(take(1))
            .subscribe({
                error: error => console.error('Error saving calendar view', error),
            });
    }

    saveUserFilter(userIds: string[]) {
        return this.saveCalendarFilters('calendarUsers', userIds);
    }

    saveCalendarsFilter(calendarIds: string[]) {
        return this.saveCalendarFilters('calendarsSelected', calendarIds);
    }

    saveFieldFilter(fieldIds: string[]) {
        return this.saveCalendarFilters('calendarFields', fieldIds);
    }

    saveCalendarUnassigned(value: boolean) {
        return this.saveCalendarFilters('calendarHideUnassigned', value);
    }

    grantCalendarPermission(calendarId: string, memberId: string, permission: string) {
        this.rpc.request('v2.calendar.permissions.grant', [calendarId, memberId, permission]).subscribe();
    }

    denyCalendarPermission(calendarId: string, memberId: string, permission: string) {
        this.rpc.request('v2.calendar.permissions.deny', [calendarId, memberId, permission]).subscribe();
    }

    addCalendarMember(calendarId: string, memberId: string) {
        this.rpc.request('v2.calendar.member.add', [calendarId, memberId]).subscribe();
    }

    removeCalendarMember(calendarId: string, memberId: string) {
        this.rpc.request('v2.calendar.member.remove', [calendarId, memberId]).subscribe();
    }

    async addCalendarMemberAsync(calendarId: string, memberId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.rpc.request('v2.calendar.member.add', [calendarId, memberId]).subscribe({
                next: (data: any) => {
                    resolve(data);
                },
                error: (err: any) => reject(err),
            });
        });
    }

    async grantCalendarPermissionAsync(calendarId: string, memberId: string, permission: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.rpc.request('v2.calendar.permissions.grant', [calendarId, memberId, permission]).subscribe({
                next: (data: any) => {
                    resolve(data);
                },
                error: (err: any) => reject(err),
            });
        });
    }

    handleStartDate(
        currentDates: { start: string; end: string },
        previousDates: { start: string; end: string }
    ): { start: Date; end: Date } {
        const start = new Date(currentDates.start);
        const end = new Date(currentDates.end);
        const eventLength = new Date(previousDates.end).getTime() - new Date(previousDates.start).getTime();
        const startMovedBack = start.getTime() <= new Date(previousDates.start).getTime();
        const startMovedForward = start.getTime() >= new Date(previousDates.start).getTime();
        const endSmaller = end.getTime() <= start.getTime();

        if (endSmaller || startMovedBack || startMovedForward) {
            const newEnd = new Date(start.getTime() + eventLength);

            return { end: newEnd, start };
        }

        return { start, end };
    }

    handleEndDate(currentDates: { start: string; end: string }, previousDates: { start: string; end: string }): { start: Date; end: Date } {
        const start = new Date(currentDates.start);
        const end = new Date(currentDates.end);
        const endSmaller = end.getTime() <= start.getTime();
        const eventLength = new Date(previousDates.end).getTime() - new Date(previousDates.start).getTime();

        if (endSmaller) {
            const newStart = new Date(end.getTime() - eventLength);
            return { end, start: newStart };
        }

        return { end, start };
    }

    private init() {
        this.subscribeToCalendars();

        this.core.network.subscribe(network => {
            this.personalSettings = this.core.user.value;
            if (!network || network._id === this.networkId) {
                return;
            }

            this.networkId = network._id;
            this.getUpcomingEvents();
        });

        this.rpc.subscribe('event.removed', meta => {
            this.eventRemoved.next(meta.event);
        });

        this.rpc.subscribe('event.reload_upcoming', (meta: any) => {
            this.eventReload.next(meta);
        });

        this.rpc.subscribe('event.reload', (meta: { events: string[] }) => {
            this.eventsReload.next(meta.events);
        });
    }

    private subscribeToCalendars(): void {
        this.core.calendars.subscribe(calendars => {
            const workspaceId = this.core.network.value?._id;
            if (!workspaceId) {
                this.calendars.next([]);
                return;
            }

            if (!calendars[workspaceId]) {
                this.calendars.next([]);
                return;
            }
            const networkCalendars: Calendar[] = Object.values(calendars[workspaceId] || {});
            networkCalendars.sort(this.helperService.sortAlphabetically('name'));

            this.calendars.next(networkCalendars);
        });
    }

    private saveCalendarFilters(type: string, args: any): Observable<boolean> {
        return this.rpc.request('user.save_settings', [type, args]);
    }
}
