<div class="event-detail-div" *ngIf="event.title">{{ 'discussion.event_data.subject' | transloco }}<p class="event-detail-text one-line-ellipsis">{{event.title}}</p></div>

<div class="event-detail-div" *ngIf="event.start && !event.allDay">{{ 'discussion.event_data.starts' | transloco }}<p class="event-detail-text">{{event.start | date:('MMMM d, yyyy HH:mm'):undefined:('misc.date.locale' | transloco)}}</p></div>
<div class="event-detail-div" *ngIf="event.end && !event.allDay">{{ 'discussion.event_data.ends' | transloco }}<p class="event-detail-text">{{event.end | date:('MMMM d, yyyy HH:mm'):undefined:('misc.date.locale' | transloco) }}</p></div>
<div class="event-detail-div" *ngIf="event.start && event.allDay">{{ 'discussion.event_data.starts' | transloco }}<p class="event-detail-text">{{event.start | date:('MMMM d, yyyy'):'UTC':('misc.date.locale' | transloco)}}</p></div>
<div class="event-detail-div" *ngIf="event.end && event.allDay">{{ 'discussion.event_data.ends' | transloco }}<p class="event-detail-text">{{event.end | date:('MMMM d, yyyy'):'UTC':('misc.date.locale' | transloco)}}</p></div>
<div class="event-detail-div" *ngIf="event.allDay">{{ 'discussion.event_data.all_day' | transloco }}<p class="event-detail-text">{{event.allDay ? ('discussion.event_data.yes' | transloco) : ('discussion.event_data.no' | transloco)}}</p></div>
<div class="event-detail-div" *ngIf="event.notes">{{ 'discussion.event_data.notes' | transloco }}<p class="event-notes event-detail-text" [innerHTML]="event.notes | htmlEscape | hashtag | urlify | safe:'html'"></p></div>
<div class="event-detail-div" *ngIf="event.location">{{ 'discussion.event_data.location' | transloco }}<p class="event-detail-text">{{event.location}}</p></div>
<div *ngIf="event.attendees.length">
    <!-- repeat user link -->
    {{ 'discussion.event_data.attendees' | transloco }}{{event.attendees.length}}
</div>