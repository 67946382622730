/** @format */

import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import zxcvbn from 'zxcvbn';

export const passwordConfig = {
    minStrength: 2,
    minLength: 8,
};

export const passwordComplexityValidator =
    (passwordScore: BehaviorSubject<number>): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            // There's nothing in the input field so set complexity score as 0
            passwordScore.next(0);
            return { passwordComplexityNotSufficient: true };
        }

        const score = zxcvbn(control.value).score;

        if (control.value.length < passwordConfig.minLength) {
            // Don't tell the user the password is strong if it's not long enough
            if (score > passwordConfig.minStrength - 1) {
                passwordScore.next(passwordConfig.minStrength - 1);
            } else {
                passwordScore.next(score);
            }
            return { passwordTooShort: true };
        }
        passwordScore.next(score);

        // Remove validation errors if strong enough
        if (zxcvbn(control.value).score >= passwordConfig.minStrength) {
            return null;
        }
        return { passwordComplexityNotSufficient: true };
    };

export const passwordMatchingValidator = (newPassword: string, confirmPassword: string) => (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[newPassword];
    const matchingControl = formGroup.controls[confirmPassword];

    // Set error on matchingControl if validation fails
    if (control?.value !== matchingControl?.value) {
        matchingControl?.setErrors({ passwordsDoNotMatch: true });
    } else {
        matchingControl?.setErrors(null);
    }
};
