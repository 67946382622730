import { useState } from 'react';
import { useInsight } from './use-insight';
import useClickOutside from './useClickOutside';

const ExportMenu = () => {
    const { api } = useInsight();
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useClickOutside(() => setIsOpen(false));

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            setIsOpen(false);
        } 
    }

    const handleExportButtonClick = () => {
        setIsOpen(!isOpen);
    }

    const handleExportPivot = () => {
        api.data.export.pivot();
        setIsOpen(!isOpen);
      };

    const handleExportTable = () => {
        api.data.export.table();
        setIsOpen(!isOpen);
      };

    const handleExportData = () => {
        api.data.export.data();
        setIsOpen(false);
    }

    return (
        <div role="menu" className="insight-export-menu-container" aria-expanded={isOpen} onKeyDown={handleKeyDown} ref={menuRef}>
        <button className="insight-button" onClick={handleExportButtonClick} aria-haspopup="true" tabIndex={0}>Export</button>
        {isOpen && (
            <div className='insight-export-menu-options-container' tabIndex={-1}>
                <button role="menuitem" onClick={handleExportPivot}>Pivot</button>
                <button role="menuitem" onClick={handleExportTable}>Table</button>
                <button role="menuitem" onClick={handleExportData}>Data</button>
            </div>
        )}
        </div>
    );
};

export default ExportMenu;