/** @format */

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import moment from 'moment';

import {
    Activity,
    ActivityCreateModel,
    ActivityFieldMap,
    ActivitySetUpdate,
    ActivityUpdateData,
    CompleteAction,
    DateField,
    DateRangeField,
    DateTimeField,
    DateTimeRangeField,
    Location,
    Process,
    Team,
    TeamAccountGroup,
    TimeField,
    TimeRangeField,
} from '@app/models';
import { RPCService } from './rpc.service';
import { TeamService } from './team.service';
import { ActivityFieldService } from './activity-field.service';
import { CoreService } from './core.service';
import { PermissionService } from './permission.service';
import { CalendarActivity } from 'app/activities/activities.service';

/* BackendActivity -> FrontendActivity
   - To Form
   - make all fields formGroup with single key 'value' --> direct patch
   FrontendActivity -> ActivityUpdate
   - Form to object
   NewActivity -> ActivityCreation */

// Activity edit/create from _values inside their form group

interface PrefixResponse {
    cid: string;
    latestSuffix: number;
    process: string;
    updated: string;
    updatedBy: string;
    _id: string;
}

@Injectable()
// Helper class for re-shaping activity data
export class ActivitiesHelperService {
    constructor(
        private core: CoreService,
        private activityFieldService: ActivityFieldService,
        private rpcService: RPCService,
        private teamService: TeamService,
        private permissions: PermissionService
    ) {}

    // Construct update model for activities
    constructActivityUpdateData(activities: Activity[], completeAction: CompleteAction | boolean): ActivityUpdateData {
        if (activities.length === 0) {
            return null;
        }

        const updateData = this.parseActivityDataArray(activities);
        if (completeAction) {
            updateData.completeAction = completeAction;
        }

        return updateData;
    }

    constructEditMultipleUpdate(activities: Activity[], completeAction: CompleteAction | boolean): ActivityUpdateData {
        const commonValues = this.parseValidUpdateFields(activities.shift().fields, true);
        const updateData = this.parseActivityDataArray(activities);

        Object.keys(updateData.activities).forEach((actId: string) => {
            for (const key of Object.keys(commonValues)) {
                if (key !== 'name') {
                    updateData.activities[actId].fields[key] = commonValues[key];
                }
            }
        });

        if (completeAction) {
            updateData.completeAction = completeAction;
        }

        return updateData;
    }

    parseValidUpdateFields(activityFields: any, multiple?: boolean): ActivityFieldMap {
        const fields = {};

        for (const key of Object.keys(activityFields || {})) {
            if (
                (multiple && this.activityFieldService.fieldHasValue(activityFields[key].value) && activityFields[key].value !== '') ||
                !multiple
            ) {
                fields[key] = this.activityFieldService.getFieldValue(activityFields[key].value);
            }
        }

        return fields;
    }
    // TODO: fix this logic and do not send any nulls
    constructActivityCreateData(activityForm: Activity, process: Process): ActivityCreateModel {
        // Sets any time/daterange fields with no start or end value to null
        for (const fieldId of Object.keys(activityForm.fields)) {
            if (activityForm.fields[fieldId] && activityForm.fields[fieldId].start === null && activityForm.fields[fieldId].end === null) {
                activityForm.fields[fieldId] = { value: null };
            }
        }

        const newActivity: ActivityCreateModel = {
            files: activityForm.files,
            followers: activityForm.followers,
            history: [{}],
            name: activityForm.name,
            process: activityForm.process,
            team_account: activityForm.team_account,
        };

        if (activityForm.location) {
            newActivity.location = activityForm.location;
        }

        // Connect fields field values to history[0] field values!
        for (const key of Object.keys(activityForm.fields)) {
            if (this.activityFieldService.fieldHasValue(activityForm.fields[key].value)) {
                // Only take fields relevant to this phase
                if (process.phases[activityForm.currentPhase].fields.indexOf(key) > -1) {
                    if (process.fields[key]?.type === 'activitylink') {
                        newActivity.history[0][key] = { value: activityForm.fields[key]?.value?._id };
                    } else {
                        newActivity.history[0][key] = activityForm.fields[key];
                    }
                }
                // Backend requires { value: null } for date/timerange if there is no start or end
            } else if (this.activityFieldService.fieldHasValue(activityForm.fields[key].value) === null) {
                if (process.phases[activityForm.currentPhase].fields.indexOf(key) > -1) {
                    newActivity.history[0][key] = {
                        value: null,
                    };
                }
            } else if (newActivity.history[0][key] && typeof newActivity.history[0][key].value === 'number') {
                newActivity.history[0][key].value = activityForm.fields.value ? parseFloat(activityForm.fields.value) : null;
            }
        }

        newActivity.history[0].phase = activityForm.currentPhase;

        return newActivity;
    }

    /**
     * Translate date and daterange from local time to UTC time but keep time at 00:00
     */
    processOutgoingDates(activities: Activity[], process: Process) {
        activities.forEach(activity => {
            Object.keys(activity.fields || {}).forEach((fieldId: string) => {
                const field = activity.fields[fieldId];

                if (!process || !process.fields[fieldId]) {
                    return;
                }

                if (process.fields[fieldId].type === 'date') {
                    // This is just a rude style to cast it as DateField
                    activity.fields[fieldId] = { value: this.translateLocalToUtcTimestamp(field.value, true) };
                } else if (process.fields[fieldId].type === 'daterange') {
                    // This is just a rude style to cast it as DateRangeField
                    if (field.value && Array.isArray(field.value)) {
                        if (!field.value[0] || !field.value[1]) {
                            activity.fields[fieldId] = { value: null };
                            return;
                        }

                        activity.fields[fieldId] = {
                            value: {
                                start: this.translateLocalToUtcTimestamp(field.value[0], true),
                                end: this.translateLocalToUtcTimestamp(field.value[1], true),
                            },
                        };
                    } else if (field.value) {
                        // This adds an exception for edit multiple, where the fields aren't actually altered

                        if (!field.value?.start || !field.value?.end) {
                            activity.fields[fieldId] = { value: null };
                            return;
                        }

                        activity.fields[fieldId] = {
                            value: {
                                start: this.translateLocalToUtcTimestamp(field.value.start, true),
                                end: this.translateLocalToUtcTimestamp(field.value.end, true),
                            },
                        };
                    }
                } else if (process.fields[fieldId].type === 'time') {
                    const time = moment(field.value).seconds(0).milliseconds(0).valueOf();
                    activity.fields[fieldId] = { value: isNaN(time) ? null : time };
                } else if (process.fields[fieldId].type === 'datetime') {
                    activity.fields[fieldId] = { value: this.translateLocalToUtcTimestamp(field.value, false) };
                } else if (process.fields[fieldId].type === 'timerange') {
                    if (!field.value?.start || !field.value?.end) {
                        activity.fields[fieldId] = { value: null };
                        return;
                    }

                    activity.fields[fieldId] = {
                        value: {
                            start: moment(field.value.start).seconds(0).milliseconds(0).valueOf(),
                            end: moment(field.value.end).seconds(0).milliseconds(0).valueOf(),
                        },
                    };
                } else if (process && process.fields[fieldId] && process.fields[fieldId].type === 'datetimerange') {
                    // This is just a rude style to cast it as DateRangeField

                    if (field.value && Array.isArray(field.value)) {
                        if (!field.value[0] || !field.value[1]) {
                            activity.fields[fieldId] = { value: null };
                            return;
                        }

                        activity.fields[fieldId] = {
                            value: {
                                start: this.translateLocalToUtcTimestamp(field.value[0], false),
                                end: this.translateLocalToUtcTimestamp(field.value[1], false),
                            },
                        };
                    } else if (field.value) {
                        if (!field.value?.start || !field.value?.end) {
                            activity.fields[fieldId] = { value: null };
                            return;
                        }

                        // This adds an exception for edit multiple, where the fields aren't actually altered
                        activity.fields[fieldId] = {
                            value: {
                                start: this.translateLocalToUtcTimestamp(field.value.start, false),
                                end: this.translateLocalToUtcTimestamp(field.value.end, false),
                            },
                        };
                    }
                }
            });
        });
    }
    processIncomingDates(activities: Activity[], process: Process): Activity[] {
        activities.forEach(activity => {
            Object.keys(activity.fields).forEach((fieldId: string) => {
                const field = activity.fields[fieldId];
                if (field && this.activityFieldService.fieldHasValue(field.value) && field.value !== '') {
                    if (process && process.fields[fieldId] && process.fields[fieldId].type === 'date') {
                        // This is just a rude style to cast it as DateField
                        activity.fields[fieldId] = new DateField(this.translateMillisecondsToLocalDate(field.value, true));
                    } else if (process && process.fields[fieldId] && process.fields[fieldId].type === 'daterange') {
                        // This is just a rude style to cast it as DateRangeField
                        activity.fields[fieldId] = new DateRangeField({
                            start: this.translateMillisecondsToLocalDate(field.value.start, true),
                            end: this.translateMillisecondsToLocalDate(field.value.end, true),
                        });
                    } else if (process && process.fields[fieldId] && process.fields[fieldId].type === 'datetime') {
                        activity.fields[fieldId] = new DateTimeField(this.translateMillisecondsToLocalDate(field.value, false));
                    } else if (process && process.fields[fieldId] && process.fields[fieldId].type === 'datetimerange') {
                        activity.fields[fieldId] = new DateTimeRangeField({
                            start: this.translateMillisecondsToLocalDate(field.value.start, false),
                            end: this.translateMillisecondsToLocalDate(field.value.end, false),
                        });
                    } else if (process && process.fields[fieldId] && process.fields[fieldId].type === 'time') {
                        activity.fields[fieldId] = new TimeField(this.translateMillisecondsToLocalDate(field.value, false));
                    } else if (process && process.fields[fieldId] && process.fields[fieldId].type === 'timerange') {
                        activity.fields[fieldId] = new TimeRangeField({
                            start: this.translateMillisecondsToLocalDate(field.value.start, false),
                            end: this.translateMillisecondsToLocalDate(field.value.end, false),
                        });
                    }
                }
            });
        });
        return activities;
    }

    processIncomingActivityEvents(activities: CalendarActivity[], process: Process, dateFieldId: string): CalendarActivity[] {
        const calendarActivities: CalendarActivity[] = [];
        const dateFieldType = process.fields[dateFieldId].type;
        let allDay = false;

        switch (dateFieldType) {
            case 'date':
            case 'daterange':
                allDay = true;
                break;
            default:
                allDay = false;
                break;
        }

        activities.forEach(activity => {
            let end = activity.end;

            if (activity.end) {
                end = this.translateMillisecondsToLocalDate(activity.end, allDay);

                if (allDay) {
                    end = moment(end).add(1, 'day').toISOString();
                }
            }

            calendarActivities.push({
                ...activity,
                start: this.translateMillisecondsToLocalDate(activity.start, allDay),
                end,
                classNames: ['activity-event'],
            });
        });

        return calendarActivities;
    }

    translateMillisecondsToLocalDate(value: number, allDay: boolean) {
        if (allDay) {
            const utcDateString: string = moment.utc(value).format('YYYY-MM-DD');
            return moment(`${utcDateString}T00:00:00.000`).toISOString();
        }
        return moment(value).toISOString();
    }

    initActivity(process: Process, initPhaseId?: string): Activity {
        const teamAccount = null;

        const activity = new Activity({
            followers: [],
            history: [],
            location: undefined,
            name: '',
            process: process._id,
            team_account: teamAccount,
        });

        activity.fields = this.activityFieldService.initActivityFields(process);
        activity.files = [];

        if (process.enablePreselectedTeam && process.preselectedTeam && process.preselectedTeam.team && process.preselectedTeam.account) {
            activity.team_account.team = process.preselectedTeam.team;
            activity.team_account.account = process.preselectedTeam.account;
        }

        const phaseId = initPhaseId || this.permissions.accessiblePhase(this.core.user.value.id, process._id);

        if (phaseId) {
            activity.currentPhase = phaseId;
        } else {
            activity.currentPhase = this.permissions.accessiblePhase(this.core.user.value.id, process._id);
        }

        return activity;
    }

    generatePredefinedName(process: Process): Observable<string> {
        const returnValue = new Subject<string>();

        let prefix = process.predefinedNamePrefix;
        if (!prefix || prefix === '') {
            prefix = 'HLR';
        }

        // TODO: Change this when there is a process service made!
        this.rpcService.request('process.get_next_prefix_id', [process._id]).subscribe(
            (data: PrefixResponse) => {
                returnValue.next(`${prefix}-${data.latestSuffix}`);
                returnValue.complete();
            },
            (error: any) => {
                returnValue.error(error);
            }
        );

        return returnValue.asObservable();
    }

    async generatePredefinedNameAsync(process: Process): Promise<string> {
        return new Promise(resolve => {
            let prefix = process.predefinedNamePrefix;
            if (!prefix || prefix === '') {
                prefix = 'HLR';
            }

            this.rpcService.request('process.get_next_prefix_id', [process._id]).subscribe({
                next: (data: PrefixResponse) => {
                    resolve(`${prefix}-${data.latestSuffix}`);
                },
                error: (error: any) => {
                    resolve(`${prefix}-err`);
                },
            });
        });
    }

    checkForOnlyAccount(processId: string): TeamAccountGroup {
        const user = this.core.user.value;

        if (!user.id) {
            return null;
        }

        const userTeams: Team[] = this.teamService.getUserTeams(user.id);

        if (!userTeams.length) {
            return null;
        }

        const filteredTeams: any[] = userTeams.filter(team => this.permissions.isTeamMember(team, user.id));

        if (filteredTeams?.length === 1 && filteredTeams[0].accounts?.length === 1) {
            return {
                accounts: [filteredTeams[0].accounts[0]],
                name: filteredTeams[0].name,
                _id: filteredTeams[0]._id,
            };
        }

        return null;
    }

    constructSetUpdateData(activityIds: string[], setForm: any): ActivitySetUpdate {
        const toBeSaved: ActivitySetUpdate = {
            activities: activityIds,
            completedOn: setForm.controls ? this.localDateToUtc(setForm.controls.completedOn.value) : null,
            created: setForm.controls ? this.localDateToUtc(setForm.controls.created.value) : null,
            owner: setForm.controls ? setForm.controls.uid.value || null : setForm.owner || null,
            team_account: setForm.controls ? setForm.controls.team_account.value || null : setForm.team_account || null,
        };

        if (setForm.controls && setForm.controls.openAndMoveToPhase) {
            toBeSaved.openAndMoveToPhase = setForm.controls.openAndMoveToPhase.value;
        }

        return toBeSaved;
    }

    private localDateToUtc(date?: any): number {
        if (!date) {
            return null;
        }
        const timeRemoved: string = moment(date).format('YYYY-MM-DD, H:mm:ss');
        const dateAsMs: number = moment.utc(`${timeRemoved}`).valueOf();
        return dateAsMs;
    }

    private parseActivityDataArray(activities: Activity[]): ActivityUpdateData {
        const updateData: ActivityUpdateData = new ActivityUpdateData({}, false);

        activities.forEach(activity => {
            const update = this.parseActivityData(activity);

            if (update) {
                updateData.activities[activity._id] = update;
            }
        });

        return updateData;
    }

    private parseActivityData(activity: Activity): { fields: ActivityFieldMap; files: string[]; location: Location; name: string } {
        return {
            fields: this.parseValidUpdateFields(activity.fields),
            files: activity.files || [],
            location: activity.location,
            name: activity.name,
        };
    }

    private translateLocalToUtcTimestamp(value: string, allDay: boolean): number {
        if (value === null) {
            return null;
        }

        if (allDay) {
            const localDateString: string = moment(value).format('YYYY-MM-DD');
            return moment.utc(`${localDateString}T00:00:00.000`).valueOf();
        }
        return moment(value).valueOf();
    }
}
