/** @format */

export class DateUtil {
    static diff(date1: Date, date2: Date = new Date()): DateDiff {
        const milliDiff = Math.abs(date1.getTime() - date2.getTime());
        const milliseconds = milliDiff % 1000;

        const secDiff = Math.floor(milliDiff / 1000);
        const seconds = secDiff % 60;

        const minDiff = Math.floor(secDiff / 60);
        const minutes = minDiff % 60;

        const hourDiff = Math.floor(minDiff / 60);
        const hours = hourDiff % 24;

        const dayDiff = Math.floor(hourDiff / 24);
        const days = dayDiff % 7;

        const weekDiff = Math.floor(dayDiff / 7);
        const weeks = weekDiff % 4;

        const monthDiff = Math.floor(weekDiff / 4);
        const months = monthDiff % 12;

        const years = Math.floor(monthDiff / 12);

        const diff = {
            milliseconds,
            seconds,
            minutes,
            hours,
            days,
            weeks,
            months,
            years,
            absolute: {
                milliseconds: milliDiff,
                seconds: secDiff,
                minutes: minDiff,
                hours: hourDiff,
                days: dayDiff,
                weeks: weekDiff,
                months: monthDiff,
                years,
            },
        };

        const result: DateDiff = {
            later: DateUtil.later(date1, date2),
            earlier: DateUtil.earlier(date1, date2),
            equal: milliDiff === 0,
            sameYear: DateUtil.sameYear(date1, date2),
            sameMonth: DateUtil.sameMonth(date1, date2),
            sameWeek: DateUtil.sameWeek(date1, date2),
            sameDay: DateUtil.sameDay(date1, date2),
            diffInDates: DateUtil.diffInDates(date1, date2),
            diff,
        };

        return result;
    }

    static sameYear(date1: Date, date2: Date = new Date()): boolean {
        return date1.getFullYear() === date2.getFullYear();
    }

    static sameMonth(date1: Date, date2: Date = new Date()): boolean {
        return DateUtil.sameYear(date1, date2) && date1.getMonth() === date2.getMonth();
    }

    static sameWeek(date1: Date, date2: Date = new Date()): boolean {
        const d1Start = DateUtil.startOfWeek(date1);
        const d2Start = DateUtil.startOfWeek(date2);

        return DateUtil.sameMonth(date1, date2) && d1Start.getDate() === d2Start.getDate();
    }

    static sameDay(date1: Date, date2: Date = new Date()): boolean {
        return DateUtil.sameWeek(date1, date2) && date1.getUTCDate() === date2.getUTCDate();
    }

    static startOfWeek(date: Date): Date {
        const newDate = new Date(date);
        const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
        return new Date(newDate.setDate(diff));
    }

    static later(date1: Date, date2: Date = new Date()): Date | null {
        if (date1.getTime() > date2.getTime()) {
            return date1;
        } else if (date1.getTime() === date2.getTime()) {
            return null;
        }

        return date2;
    }

    static earlier(date1: Date, date2: Date = new Date()): Date | null {
        if (date1.getTime() > date2.getTime()) {
            return date2;
        } else if (date1.getTime() === date2.getTime()) {
            return null;
        }

        return date1;
    }

    static getDayStr(date: Date, type: 'long' | 'short' = 'long', locale = 'en-US') {
        // TODO: We want to be smarter than DateUtil...
        return date.toLocaleDateString(locale, { weekday: type });
    }

    static getMonthStr(date: Date, type: 'long' | 'short' = 'long', locale = 'en-US') {
        // TODO: We want to be smarter than DateUtil...
        return date.toLocaleDateString(locale, { month: type });
    }

    static getDoY(date: Date): number {
        const firstOfYear = Date.UTC(date.getUTCFullYear(), 0, 0);
        const normalizedDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
        const diff = normalizedDate - firstOfYear;
        const days = diff / 1000 / 60 / 60 / 24;
        return days;
    }

    static diffInDates(d1: Date, d2: Date): number {
        return Math.abs(DateUtil.getDoY(d1) - DateUtil.getDoY(d2));
    }
}

export interface DateDiff {
    earlier: Date;
    later: Date;
    equal: boolean;
    sameDay: boolean;
    sameWeek: boolean;
    sameMonth: boolean;
    sameYear: boolean;
    diffInDates: number;
    diff: {
        milliseconds: number;
        seconds: number;
        minutes: number;
        hours: number;
        days: number;
        weeks: number;
        months: number;
        years: number;
        absolute: {
            milliseconds: number;
            seconds: number;
            minutes: number;
            hours: number;
            days: number;
            weeks: number;
            months: number;
            years: number;
        };
    };
}
