/** @format */

// This is examples how the login URL looks like
// To run this locally, you need an Microsoft Entra Enterprise application in Microsoft Azure
// https://localhost:9443/#/entralogin/clientId/tenantId
// clientId = Enterprise application ID
// tenantId = Tenant ID in Microsoft Entra ID 
// https://localhost:9443/#/entralogin/bdb3cb25-db3a-4da0-bde9-169c65ff30bb/78fa02b8-023a-48ea-ad04-66965b1e30bd
// https://testapp.hailer.biz/#/entralogin/bdb3cb25-db3a-4da0-bde9-169c65ff30bb/78fa02b8-023a-48ea-ad04-66965b1e30bd
// https://app.hailer.com/#/entralogin/bdb3cb25-db3a-4da0-bde9-169c65ff30bb/78fa02b8-023a-48ea-ad04-66965b1e30bd

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AuthService } from 'app/_services/auth.service';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { AuthenticationResult } from '@azure/msal-browser';

@Component({
    selector: 'app-entra-login',
    templateUrl: './entra-login-page.component.html',
    styleUrls: ['./entra-login-page.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
                alias: 'public',
            },
        },
    ],
})
export class EntraLoginPageComponent implements OnInit, OnDestroy {
    private onDestroy = new Subject<void>();
    clientId: string | null;
    tenantId: string | null;
    saving = new BehaviorSubject<boolean>(false);
    entraLoginError = '';

    logInInProgress = false;
    private statusSubscription: Subscription;

    constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) {}

    async ngOnInit(): Promise<void> {
        // Subscribe to status changes
        this.statusSubscription = this.authService.status$.subscribe(status => {
            this.logInInProgress = status;
            this.cdr.detectChanges();
        });

        if (this.isLoggedInEntra()) {
            // Show button to start over the login progress
            this.authService.updateStatus(true);
        }

        this.handleRouteParams();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    logInDisabled(): boolean {
        return !(this.clientId && this.tenantId);
    }

    handleRouteParams(): void {
        this.route.paramMap.subscribe(async params => {
            this.clientId = params.get('clientId');
            this.tenantId = params.get('tenantId');

            if (this.clientId && this.tenantId) {
                try {
                    // Set clientId & tenantId for msal instance
                    await this.authService.configureMsalInstance(this.clientId, this.tenantId);

                    this.saving.next(false);
                } catch (error) {
                    console.error('Error configuring MSAL instance:', error);
                    this.authService.clearLocalStorage();
                    this.saving.next(false);
                }
            } else {
                this.entraLoginError = 'Client ID or Tenant ID is missing in URL';
                this.saving.next(false);
            }
        });
    }

    async loginEntra(): Promise<void> {
        // Show loading state
        this.saving.next(true);
        this.entraLoginError = '';
    
        try {
            // Trigger the popup login and await the result
            const result: AuthenticationResult | null = await this.authService.loginPopup();
    
            if (result) {
                // Handle the login result (store tokens, etc.) and await processLogin
                await this.authService.processLogin(result);
            }

        } catch (error) {
            // Handle error
            this.entraLoginError = `Login failed: ${error}`;
        } finally {
            // Stop loading state
            this.saving.next(false);
        }
    }

    logoutEntra(): void {
        this.authService.clearLocalStorage();
        this.authService.logout();
    }

    isLoggedInEntra(): boolean {
        return this.authService.isLoggedIn();
    }

    clearLocalStorage() {
        localStorage.clear();
        this.logInInProgress = false;
    }
}
