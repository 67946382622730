<div *ngIf="discussion" style="overflow-x: hidden;" class="flexbox-column">
    <ng-container *ngIf="discussion">
        <div class="hlr-panel-heading" [ngStyle]="headerBackground">
            <div class="small-header-bg"></div>
            <input #fileInput hidden type="file" accept="image/*" (change)="uploadGroupDiscussionCover($event)" />
            <div class="hlr-panel-inner" (click)="openUploader()">
                <div *ngIf="!isNetworkOwnedDiscussion || !permissions.checkIfNetworkGuest(user.id, discussion.cid)" class="upload-panel">
                    <mat-icon class="upload-icon">add_a_photo</mat-icon>
                </div>
                <button mat-icon-button (click)="clearSidenav()" class="clear-button">
                    <mat-icon aria-label="Close Group Discussion Detail Sidenav">close</mat-icon>
                </button>
                <button mat-icon-button (click)="popSidenav()" class="header-button back-button"
                    *ngIf="(stackSize$ | async) > 1">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <span class="hlr-discussion-title-font">
                    <h3 class="hlr-discussion-subject two-line-ellipsis">
                        {{discussion.subject}}
                    </h3>
                    <div>
                        <span class="network-tag"
                            [ngStyle]="{'background-color': discussionBadgeColor }">{{ discussionBadge }}</span>
                    </div>
                </span>
            </div>
        </div>
    </ng-container>
    <mat-tab-group color="accent" mat-align-tabs="center" animationDuration="0" style="flex: 1;" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="Overview">
            <ng-template mat-tab-label>
                <mat-icon tippy="{{ 'discussion.group_discussion.overview' | transloco }}">group</mat-icon>
            </ng-template>
            <ng-container *ngIf="!upgradeToActivity">
                <div class="sidenav-body" [class.edit-mode]="subjectEdit">
                    <button *ngIf="!permissions.isNetworkGuest" mat-stroked-button color="accent" (click)="upgradeToActivity = true" style="margin: 16px auto 8px; display: block;">
                        <mat-icon svgIcon="hailer-activity" style="margin-right: 4px;"></mat-icon>{{ 'discussion.group_discussion.upgrade_to_activity' | transloco }}
                    </button>
                    <div>
                        <div class="info-field">
                            <span class="info-field-title">{{ 'discussion.group_discussion.subject' | transloco }}</span>
                            <span class="info-field-content">
                                <span data-cy="group-title-field" *ngIf="!subjectEdit" class="info-field-discussion-subject two-line-ellipsis" (click)="toggleEdit()">{{discussion.subject}}</span>
                                <mat-form-field appearance="outline" *ngIf="subjectEdit">
                                    <input data-cy="group-title-input" type="text" matInput [formControl]="subjectInput">
                                    <button matSuffix color="accent" mat-icon-button class="edit-button"
                                        [disabled]="subjectInput.invalid || (subjectInput.value === discussion.subject)" (click)="updateDiscussion()">
                                        <mat-icon>done</mat-icon>
                                    </button>
                                    <button matSuffix color="warn" mat-icon-button (click)="toggleEdit()">
                                        <mat-icon>undo</mat-icon>
                                    </button>
                                </mat-form-field>
                            </span>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="info-field">
                            <span class="info-field-title">{{ 'discussion.group_discussion.starter' | transloco }}</span><span class="info-field-content">{{getUserName(discussion.uid)}}</span>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="info-field">
                            <span class="info-field-title">{{ 'discussion.group_discussion.last_active' | transloco }}</span><span class="info-field-content">{{discussion.last_active | date: 'MMM d, y, HH:mm': undefined: ('misc.date.locale' | transloco)}}</span>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="title-field">
                        <span class="info-field-title">{{ 'discussion.group_discussion.participants' | transloco }}</span>
                    </div>
                    <div class="permission-container">
                            <app-team-user-selector *ngIf="!isNetworkOwnedDiscussion || !permissions.checkIfNetworkGuest(user.id, discussion.cid)" [options]="userSelectorOptions" [hideItems]="userSelectorHiddenItems"
                            (results)="addPermission($event)" class="user-selector"></app-team-user-selector>
                        <div *ngFor="let userId of discussion.participants" class="allowed-item-container">
                            <div class="container align-items-center">
                                <app-user-image-link (click)="openUserSidenav(userId)" [userId]="userId" [size]="40"
                                    class="allowed-user-image" style="height: 40px"></app-user-image-link>
                                <span class="permission-name">
                                    <app-user-link [userId]="userId" [clearStack]="false"></app-user-link>
                                </span>
                            </div>
                            <button [disabled]="isNetworkOwnedDiscussion && permissions.checkIfNetworkGuest(user.id, discussion.cid)" mat-icon-button (click)="removePermission(userId)" color="warn">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="upgradeToActivity">
                <div class="new-activity-header">
                    <button mat-icon-button (click)="upgradeToActivity = false"  tippy="Back">
                        <mat-icon>arrow_back</mat-icon>
                    </button>
                    <span>
                        {{ 'discussion.group_discussion.choose_workflow' | transloco }}
                    </span>
                </div>
                <app-process-list class="container flexbox-column" [justMessengerEnabled]="true" [discussionId]="discussion._id" [upgrade]="true"></app-process-list>
            </ng-container>
        </mat-tab>
        <mat-tab label="Files">
            <ng-template mat-tab-label>
                <mat-icon tippy="{{ 'discussion.group_discussion.files' | transloco }}" class="attachments-icon">attach_file</mat-icon>
            </ng-template>
            <ng-template matTabContent>
                <div class="default-search-bar" [formGroup]="searchAttachmentsGroup">
                    <mat-form-field appearance="outline" class="search-bar">
                        <div matPrefix>
                            <mat-icon>search</mat-icon>
                        </div>
                        <input placeholder="{{ 'discussion.group_discussion.search_attachments_placeholder' | transloco }}" matInput formControlName="searchInput" [appAutofocus]="false">
                    </mat-form-field>
                </div>
                <div class="sidenav-body">
                    <mat-accordion multi="true" displayMode="flat">
                        <mat-expansion-panel *ngIf="images[0]">
                            <mat-expansion-panel-header>
                                <div class="chat-media-header">
                                    <h2>{{ 'discussion.group_discussion.images' | transloco }}</h2>
                                    <div class="chat-media-counter">
                                        <mat-icon color="accent">list</mat-icon>
                                        <p>{{filteredImages.length}}</p>
                                    </div>

                                </div>
                            </mat-expansion-panel-header>
                            <div class="media-container">
                                <ng-container *ngFor="let image of filteredImages">
                                    <div class="media-object">
                                        <img (click)="openFileSideNav(image._id)" src="{{environmentUrl}}/image/lores/{{image._id}}" width="100%"
                                            height="100%"  tippy="{{image.name}}" style="object-fit: cover"
                                            alt="User uploaded picture">
                                        <a (click)="$event.stopPropagation(); filesService.download(image)" style="color: #20A250;" class="image-dl-button">
                                                <mat-icon>file_download</mat-icon>
                                        </a>
                                    </div>
                                </ng-container>
                                <div *ngIf="images.length === 0" class="nothing-found">{{ 'discussion.group_discussion.no_media' | transloco }}</div>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel *ngIf="files[0]">
                            <mat-expansion-panel-header>
                                <div class="chat-media-header">
                                    <h2>{{ 'discussion.group_discussion.files' | transloco }}</h2>
                                    <div class="chat-media-counter">
                                        <mat-icon color="accent">list</mat-icon>
                                        <p>{{filteredFiles.length}}</p>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <ng-container class="file-background" *ngFor="let file of filteredFiles">
                                <div class="file-object" (click)="openFileSideNav(file._id)">
                                    <div class="file-name-container">
                                        <mat-icon>folder</mat-icon>
                                        <span class="file-name">{{file.name}}</span>
                                    </div>
                                    <div class="file-options-container">
                                        <span class="file-buttons-container">
                                            <button (click)="$event.stopPropagation()" mat-icon-button color="accent" class="download-button">
                                                <a (click)="filesService.download(file)">
                                                    <mat-icon color="accent">file_download</mat-icon>
                                                </a>
                                            </button>
                                        </span>
                                        <span style="font-size: 12px;" class="FileBites">{{file.length | filesize}}</span>
                                    </div>
                                </div>
                                <div *ngIf="file.length === 0" class="nothing-found">{{ 'discussion.group_discussion.no_files' | transloco }}</div>
                            </ng-container>
                        </mat-expansion-panel>
                        <mat-expansion-panel *ngIf="links[0]">
                            <mat-expansion-panel-header>
                                <div class="chat-media-header">
                                    <h2>{{ 'discussion.group_discussion.links' | transloco }}</h2>
                                    <div class="chat-media-counter">
                                        <mat-icon color="accent">list</mat-icon>
                                        <p>{{filteredLinks.length}}</p>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <div *ngFor="let link of filteredLinks" class="link-container"
                                [ngClass]="link.from === user.id ? 'my-message' : 'other-message'">
                                <p>{{getUserDisplayName(link.from)}}</p>
                                <a target="_blank" href="{{link.link}}"
                                    title="{{link.date | date: 'dd.MM.yyyy, HH:mm': undefined: ('misc.date.locale' | transloco)}}">{{link.link}}</a>
                                <p>{{link.date | date: 'dd.MM.yyyy': undefined: ('misc.date.locale' | transloco)}}</p>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
<div *ngIf="!discussion">
    <div class="sidenav-error-container">
        <h4>{{ 'discussion.group_discussion.error' | transloco }}</h4>
        <div class="close-error-buttons">
            <button mat-icon-button (click)="popSidenav()" *ngIf="(stackSize$ | async) > 1">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <button mat-icon-button (click)="clearSidenav()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</div>