<!-- @format -->

<div class="new-network-window">
	<div class="modal-header">
		<h1>{{ 'shared.new-network-dialog.create_new_workspace' | transloco }}</h1>
		<div class="x-button-wrapper">
			<app-button
				type="icon"
				color="icon-white-all-themes"
				size="medium"
				[ariaLabel]="'shared.close-popup' | transloco"
				hailerIconName="hailer-x"
				(clickEvent)="closeNewNetworkDialog()"></app-button>
		</div>
	</div>
	<div class="flex-column">
		<div class="textbox-flex">
			<mat-form-field class="text-box" appearance="outline" floatLabel="always">
				<mat-label>{{ 'shared.new-network-dialog.name' | transloco }}</mat-label>
				<input
					data-cy="new-workspace-name-input"
					type="text"
					matInput
					maxlength="128"
					minlength="3"
					#newNetworkNameInput="matInput"
					[(ngModel)]="newHailerName"
					[appAutofocus]="true"
					[appAutofocusDelay]="250" />

				<mat-hint *ngIf="newNetworkNameInput.value.length > 50" align="start" class="flex-space-between-align-center full-width">
					<span>{{ 'shared.new-network-dialog.max-length-hint' | transloco }}</span>
					<span>{{ newNetworkNameInput.value.length || 0 }} / 128</span>
				</mat-hint>

				<mat-hint *ngIf="newNetworkNameInput.value.length < 3" align="start" class="flex-space-between-align-center full-width">
					{{ 'shared.new-network-dialog.min-length-hint' | transloco }}
				</mat-hint>
			</mat-form-field>
		</div>
		<div class="network-text">
			<h2 class="text-margins ns-title-bold-2">{{ 'shared.new-network-dialog.make_it_your_own' | transloco }}</h2>
			<h3 class="description-margins ns-title-bold-3">{{ 'shared.new-network-dialog.customize_new_workspace' | transloco }}</h3>
		</div>
		<div class="create-buttons">
			<mat-checkbox [(ngModel)]="defaultToNetwork">{{ 'shared.new-network-dialog.set_as_default_workspace' | transloco }}</mat-checkbox>

			<app-button
				dataCy="create-new-network-button"
				[isDisabled]="!newHailerName || creating || isManagedUser || newNetworkNameInput.value.length < 3"
				type="text"
				color="tertiary-default-background"
				size="medium"
				[label]="'shared.new-network-dialog.create_workspace' | transloco"
				[ariaLabel]="'shared.new-network-dialog.create_workspace' | transloco"
				(clickEvent)="createNewNetwork(newHailerName, false)"></app-button>

			<app-button
				dataCy="create-new-network-and-switch-button"
				[isDisabled]="!newHailerName || creating || isManagedUser || newNetworkNameInput.value.length < 3"
				type="text"
				color="primary"
				size="medium"
				[label]="'shared.new-network-dialog.create_and_switch_workspace' | transloco"
				[ariaLabel]="'shared.new-network-dialog.create_and_switch_workspace' | transloco"
				(clickEvent)="createNewNetwork(newHailerName, true)"></app-button>
		</div>
	</div>
</div>
