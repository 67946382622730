/** @format */

import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { File } from '../../_models/file.model';
import { environment } from '@app/env';
import { CoreService } from '../../_services/core.service';
import { PersonalSettings } from '../../_models/personal-settings.model';
import { DiscussionLink } from '../../_models/discussionLink.model';
import { Company } from '../../_models/company.model';
import { Discussion, DiscussionFileResponse } from '../../_models/discussion.model';
import { FilesService, UploadState } from '../../_services/files.service';
import { PeopleService } from 'app/people/people.service';
import { TeamService } from 'app/_services/team.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { TeamUserSelectorOptions } from 'app/_models/teamUserSelectorOptions.model';
import { DropdownSelectedItems } from 'app/_models/dropdown-selector.model';
import { PermissionService } from 'app/_services/permission.service';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';
import { FileViewComponent } from 'app/files-shared/file-view/file-view.component';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';
import { TranslateService } from 'app/_services/translate.service';
import { SelectedDiscussionService } from 'app/_services/selected-discussion.service';

@Component({
    selector: 'app-group-discussion-detail-sidenav',
    templateUrl: './group-discussion-detail-sidenav.component.html',
    styleUrls: ['./group-discussion-detail-sidenav.component.scss'],
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'discussion', alias: 'discussion' } }],
})
export class GroupDiscussionDetailSidenavComponent implements OnInit, OnDestroy, OnChanges {
    @Input() discussion: Discussion = null;

    @ViewChild('fileInput', { static: false }) fileInput;

    imgPerRow = 4;
    network: Company | undefined = undefined;
    links: DiscussionLink[] = [];
    filteredLinks: DiscussionLink[] = [];
    images: File[] = [];
    filteredImages: File[] = [];
    files: File[] = [];
    filteredFiles: File[] = [];
    filteredFilesArray: any[] = [];
    fileData: any;
    user: PersonalSettings;
    userSelectorOptions = new BehaviorSubject<TeamUserSelectorOptions>(new TeamUserSelectorOptions());
    userSelectorHiddenItems = new BehaviorSubject<string[]>([]);
    upgradeToActivity = false;
    discussionBadge: string;
    discussionBadgeColor: string;
    subjectEdit = false;
    environmentUrl: string;
    stackSize$: BehaviorSubject<number> = this.sideNavService.stackSize$;
    searchAttachmentsGroup = new UntypedFormGroup({
        searchInput: new UntypedFormControl(),
    });
    subjectInput = new UntypedFormControl('', Validators.required);
    private onDestroy = new Subject<void>();

    constructor(
        public filesService: FilesService,
        public permissions: PermissionService,
        private core: CoreService,
        private peopleService: PeopleService,
        private v3Discussion: V3DiscussionService,
        private teamService: TeamService,
        private sideNavService: SideNavService,
        private cdr: ChangeDetectorRef,
        private translocoService: TranslocoService,
        private translateService: TranslateService,
        private selectedDiscussion: SelectedDiscussionService
    ) {}

    ngOnInit() {
        if (!this.discussion) {
            return;
        }

        this.environmentUrl = environment.wsUrl;
        this.user = this.core.user.value;

        if (this.isNetworkOwnedDiscussion) {
            this.network = this.core.networks.value[this.discussion.cid];

            this.discussionBadge = this.network?.name || 'Unknown workspace';
            this.discussionBadgeColor = this.network?.settings?.color || '#405560';
        } else {
            // Assume group discussion (private discussion cannot be opened here)
            this.discussionBadge = 'Group discussion';
            this.discussionBadgeColor = '#306cee';
        }

        this.searchAttachmentsGroup.controls.searchInput.valueChanges.pipe(takeUntil(this.onDestroy), debounceTime(500)).subscribe({
            next: (searchString: string) => {
                if (this.images) {
                    const filtered = this.images.filter(file => this.checkMatch(searchString, file.name));
                    this.filteredImages = this.sortAttachmentsByDate(filtered);
                }

                if (this.files) {
                    const filtered = this.files.filter(file => this.checkMatch(searchString, file.name));
                    this.filteredFiles = this.sortAttachmentsByDate(filtered);
                }

                if (this.links) {
                    this.filteredLinks = this.links.filter(link => this.checkMatch(searchString, link.link));
                }
            },
        });

        this.v3Discussion.v3Message.newMessages.pipe(takeUntil(this.onDestroy)).subscribe({
            next: newMessages => {
                if (newMessages.filter(({ type }) => type === 'user')) {
                    return;
                }

                this.getDiscussionAttachments();
                this.getDiscussionLinks();
            },
        });

        this.sideNavService.activityUpgraded.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.upgradeToActivity = false;
                this.sideNavService.subtract();
            },
        });

        this.v3Discussion.discussionsUpdated
            .pipe(
                takeUntil(this.onDestroy),
                filter(discussionIds => discussionIds.includes(this.discussion._id))
            )
            .subscribe({
                next: async () => {
                    this.discussion = await lastValueFrom(this.v3Discussion.get(this.discussion._id));
                    this.cdr.detectChanges();
                },
            });

        this.translateService.translationLoaded('discussion').then(() => {
            this.setUserSelectorOptions();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.discussion && changes.discussion.currentValue) {
            this.setUserSelectorOptions();
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    tabChanged(event: MatTabChangeEvent) {
        if (event.tab.textLabel === 'Files') {
            this.getDiscussionAttachments();
            this.getDiscussionLinks();
        }
    }

    toggleEdit() {
        if (!this.canEditSubject) {
            return;
        }

        this.subjectEdit = !this.subjectEdit;
        this.subjectInput.setValue(this.discussion.subject);
    }

    getUserName(uid: string): string {
        return this.peopleService.getUser(uid).display_name;
    }

    get canEditSubject(): boolean {
        const isNetworkGuest = this.permissions.checkIfNetworkGuest(this.user.id, this.discussion.cid);
        return !this.isNetworkOwnedDiscussion && !isNetworkGuest;
    }

    get headerBackground(): any {
        if (this.discussion.cover_picture) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            return { 'background-image': `url(${environment.wsUrl}/image/lores/${this.discussion.cover_picture})` };
        }
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { 'background-color': '#34495e' };
    }

    get isNetworkOwnedDiscussion(): boolean {
        return !!(this.discussion.linked_activity || this.discussion.linked_event);
    }

    addPermission(results: DropdownSelectedItems) {
        const newInvites: string[] = [];

        Object.keys(results).forEach(key => {
            results[key].forEach(_id => {
                if (key === 'user') {
                    if (!this.discussion.participants.includes(_id)) {
                        newInvites.push(_id);
                    }
                } else {
                    const teamUsers: string[] = this.teamService.getTeam(_id).members;

                    teamUsers.forEach((userId: string) => {
                        if (!this.discussion.participants.includes(userId)) {
                            newInvites.push(userId);
                        }
                    });
                }
            });
        });

        if (newInvites.length > 0) {
            this.addDiscussionParticipants(newInvites);
        }
    }

    removePermission(uid: string) {
        const index = this.discussion.participants.indexOf(uid);
        const participants = [...this.discussion.participants];
        participants.splice(index, 1);
        this.discussion = { ...this.discussion, participants };
        this.updateDiscussion();
        this.setUserSelectorOptions();
    }

    addDiscussionParticipants(userIds: string[]) {
        this.v3Discussion
            .invite(this.discussion._id, userIds)
            .pipe(take(1))
            .subscribe({
                next: (discussion: Discussion) => {
                    this.discussion = discussion;
                    this.setUserSelectorOptions();
                },
                error: (error: any) => console.error('error: ', error),
            });
    }

    updateDiscussion(coverId?: string) {
        const discussionUpdate = {
            _id: this.discussion._id,
            // Dont update subject if updating cover picture
            subject: coverId ? this.discussion.subject : this.subjectInput.value || this.discussion.subject,
            participants: this.discussion.participants,
            cover_picture: coverId || this.discussion.cover_picture,
        };

        this.v3Discussion
            .saveSettings(discussionUpdate)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: () => {
                    // Keep editing the subject if only the cover was updated
                    this.subjectEdit = this.subjectEdit && !!coverId;
                    this.cdr.detectChanges();
                },
                error: (error: any) => console.error('error: ', error),
            });
    }

    openUploader() {
        if (this.isNetworkOwnedDiscussion && this.permissions.checkIfNetworkGuest(this.user.id)) {
            return;
        }

        this.fileInput.nativeElement.click();
    }

    uploadGroupDiscussionCover(event) {
        const subscription = this.filesService.upload(event.target.files[0]).subscribe((state: UploadState) => {
            if (state.finished) {
                this.updateDiscussion(state.fileId);
                subscription.unsubscribe();
            }
        });
    }

    getUserDisplayName(userId?: string): string {
        if (userId) {
            return this.peopleService.getUser(userId).display_name;
        }
    }

    openFileSideNav(fileId: string) {
        this.sideNavService.create(FileViewComponent, {
            fileId,
        });
    }

    openUserSidenav(userId: string): void {
        this.sideNavService.create(UserDetailComponent, {
            userId,
        });
    }

    popSidenav() {
        this.sideNavService.pop();
    }

    clearSidenav() {
        this.sideNavService.clear();
    }

    sortAttachmentsByDate(attachments: File[]): File[] {
        if (!attachments?.length) {
            return [];
        }

        return attachments.sort((a, b) => (a.created < b.created ? 1 : -1));
    }

    focusToMessage(message: any) {
        this.selectedDiscussion.focusToMessage(message);
    }

    private setUserSelectorOptions() {
        const currentOptions = this.userSelectorOptions.value;

        this.userSelectorOptions.next({
            ...currentOptions,
            placeholder: this.translocoService.translate('discussion.group_discussion.ts_invite_user_to_discussion_placeholder'),
            multiple: false,
            required: false,
            showUsers: 'all',
            appearance: 'outline',
            closeOnSelection: true,
            selectLastItem: false,
        });

        this.userSelectorHiddenItems.next(this.discussion.participants);
    }

    private checkMatch(search: string, input: string): boolean {
        return input.toLowerCase().replace(' ', '').indexOf(search.toLowerCase().replace(' ', '')) > -1;
    }

    private getDiscussionAttachments() {
        if (!this.discussion) {
            return;
        }

        this.v3Discussion
            .loadFiles(this.discussion._id)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: (fileResponse: DiscussionFileResponse) => {
                    this.images = fileResponse.files.filter(file => file.isPicture);
                    this.files = fileResponse.files.filter(file => !file.isPicture);

                    if (this.searchAttachmentsGroup.value.searchInput) {
                        const searchString = this.searchAttachmentsGroup.value.searchInput;

                        if (this.images) {
                            const filtered = this.images.filter(file => this.checkMatch(searchString, file.name));
                            this.filteredImages = this.sortAttachmentsByDate(filtered);
                        }
                        if (this.files) {
                            const filtered = this.files.filter(file => this.checkMatch(searchString, file.name));
                            this.filteredFiles = this.sortAttachmentsByDate(filtered);
                        }
                    } else {
                        this.filteredFiles = this.sortAttachmentsByDate(this.files);
                        this.filteredImages = this.sortAttachmentsByDate(this.images);
                    }
                },
                error: error => console.error('Unable to get discussion files for group-chat', error),
            });
    }

    private getDiscussionLinks(): void {
        if (!this.discussion) {
            return;
        }

        this.v3Discussion
            .links(this.discussion._id)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: (links: DiscussionLink[]) => {
                    this.links = links;

                    if (this.searchAttachmentsGroup.value.searchInput && this.links) {
                        const searchString = this.searchAttachmentsGroup.value.searchInput;

                        this.filteredLinks = this.links.filter(link => this.checkMatch(searchString, link.link));
                    } else {
                        this.filteredLinks = this.links;
                    }
                },
                error: (error: any) => console.error(error),
            });
    }
}
