<!-- @format -->

<button
	class="list-item no-button"
	data-cy="discussion-list-item"
	[class.selected]="selectedDiscussion.value === discussion._id"
	(click)="navigateToDiscussion.emit(discussion._id)">
	<!--Show when the last message was sent-->
	<small
		class="last-active"
		attr.aria-label="{{ 'discussion.message-view.last_active' | transloco }} {{ discussion.last_active | todayDate | async }}">
		{{ discussion.last_active | todayDate | async }}
	</small>

	<!--Show discussion icons-->
	<button
		data-cy="discussion-list-item-icon-button"
		class="no-button discussion-list-icon {{ getDiscussionType(discussion) }}"
		[matMenuTriggerFor]="discussionIconMenu"
		[matMenuTriggerData]="{ discussion }"
		(click)="$event.stopPropagation()">
		<ng-container *ngTemplateOutlet="discussionIcon"></ng-container>
	</button>

    <!--Discussions subjects and tags-->
    <div class="flexbox-column subject-tags-container">
        <span data-cy="discussion-subject" class="two-line-ellipsis">{{ getDiscussionSubject(discussion) }}</span>
        <div class="discussion-tag-container">
            <!-- Workspace name -->
            <span
                data-cy="workspace-name-discussion-tag"
                class="discussion-tag one-line-ellipsis"
                *ngIf="
                    (getNetwork(discussion.cid) | async) &&
                    !discussion.private &&
                    (discussion.linked_activity || discussion.linked_event || discussion.linked_tasklist)
                "
                [style.background-color]="stylingHelperService.getNetworkBackgroundColor(getNetwork(discussion.cid) | async)"
                [style.color]="stylingHelperService.getNetworkTextColor(getNetwork(discussion.cid) | async)"
                >{{ (getNetwork(discussion.cid) | async)?.name }}
            </span>

            <!-- Workflow name-->
            <span
                *ngIf="getWorkflow(discussion?.process)"
                class="discussion-tag process-tag one-line-ellipsis"
                [style.background-color]="stylingHelperService.getWorkflowBackgroundColor(getWorkflow(discussion.process))"
                [style.color]="stylingHelperService.getWorkflowTextColor(getWorkflow(discussion.process))">
                {{ getWorkflow(discussion.process)?.name || '' }}
            </span>
        </div>
    </div>
</button>

<ng-template #discussionIcon>
	<!--If unread, show unread dot-->
	<div
		*ngIf="discussionUnread(discussion._id)"
		class="unread"
		[attr.aria-label]="getUnreadCount(discussion._id) + ('discussion.list.unread-messages' | transloco)">
		{{ getUnreadCount(discussion._id) }}
	</div>

	<app-user-picture
		*ngIf="discussion?.private"
		[diameter]="40"
		[url]="getProfilePictureURL(discussion)"
		attr.aria-label="{{ 'discussion.list.private-discussion' | transloco }}"></app-user-picture>

	<ng-container *ngIf="!discussion.private && !discussion.linked_tasklist">
		<mat-icon
			svgIcon="hailer-{{ getDiscussionType(discussion) }}"
			class="{{ getDiscussionType(discussion) }}-icon"
			aria-hidden="false"
			[attr.aria-label]="'discussion.list.' + getDiscussionType(discussion) + '-discussion' | transloco"></mat-icon>
	</ng-container>
	<mat-icon
		*ngIf="discussion.isMuted"
		svgIcon="hailer-notification-off"
		class="mute-icon"
		aria-hidden="false"
		attr.aria-label="{{ 'discussion.list.muted-discussion' | transloco }}"></mat-icon>
	<mat-icon
		*ngIf="discussion.isStarred"
		svgIcon="hailer-star-small-fill"
		class="star-icon"
		aria-hidden="false"
		attr.aria-label="{{ 'discussion.list.starred-discussion' | transloco }}"></mat-icon>
</ng-template>
