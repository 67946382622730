/** @format */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'parseIds',
})
export class ParseIdsPipe implements PipeTransform {
    transform(calendars: any, args?: any): string[] {
        if (calendars) {
            const ids = [];

            for (const calendar of calendars) {
                calendar.selected = true;
                ids.push(calendar._id);
            }
            return ids;
        }
        return [];
    }
}
