/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { escape } from 'html-escaper';

@Pipe({
    name: 'htmlEscape',
})
export class HtmlEscapePipe implements PipeTransform {
    transform(text: any, args?: any): any {
        if (!text || typeof text !== 'string') {
            return '';
        }
        if (text.length < 1) {
            return '';
        }

        return escape(text);
    }
}
