/** @format */

import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { Country, CountrySelectOptions } from '@app/models';
import { CoreService } from 'app/_services/core.service';

@Component({
    selector: 'app-country-selector',
    templateUrl: './country-selector.component.html',
    styleUrls: ['./country-selector.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class CountrySelectorComponent implements OnInit, OnDestroy, OnChanges {
    @Input() options: CountrySelectOptions;
    @Input() activity?: any;
    @Input() focused = false;
    @Input() selectAll: MatCheckboxChange;
    @Input() showDropDown = true;
    @Input() clearSelection: boolean;
    @Output() results = new EventEmitter<Country | Country[]>();
    @Output() resultsWhenClosed = new EventEmitter<any>();
    @Output() clear = new EventEmitter<boolean>();

    @ViewChild('countrySelect', { static: true }) countrySelect: MatSelect;

    countryCtrl: UntypedFormControl = new UntypedFormControl();
    countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
    countries: Country[] = [];
    selectedCountries: Country[] = [];
    filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);

    private onDestroy = new Subject<void>();

    constructor(private core: CoreService, private cdr: ChangeDetectorRef, private translocoService: TranslocoService) {}

    ngOnInit() {
        if (this.options.required) {
            this.countryCtrl.setValidators(Validators.required);
        }

        this.countryCtrl.markAsTouched();

        this.countries = this.core.countries.value;
        this.initCountrySelector();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.clearSelection && changes.clearSelection.currentValue) {
            this.resetSelector();
        }
        if (changes.activity && changes.activity.currentValue) {
            this.countryCtrl = new UntypedFormControl();

            if (this.options.required) {
                this.countryCtrl.setValidators(Validators.required);
            }

            this.countryCtrl.markAsTouched();

            this.countries = this.core.countries.value;
            this.initCountrySelector();
        }
        if (changes.selectAll && changes.selectAll.currentValue) {
            this.selectAllCountries();
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    selectCountry(event: any) {
        const selected = event.options[0].selected;
        const selectedCountry = event.options[0].value;
        if (selected) {
            this.selectedCountries.push(selectedCountry);
        } else {
            this.selectedCountries.splice(
                this.selectedCountries.findIndex(country => country.code === selectedCountry.code),
                1
            );
        }
        this.results.emit(this.selectedCountries);
    }

    countrySelected(code: string): boolean {
        return !!this.selectedCountries?.find(country => country?.code === code);
    }

    selectAllCountries() {
        if (this.selectAll.checked) {
            this.countryCtrl.setValue(this.countries);
            this.selectedCountries = [...this.countries];
            this.results.emit(this.countries);
        } else {
            this.resetSelector();
        }
        this.cdr.detectChanges();
    }

    /* Doesnt make checkboxes checked & doesnt make amount bubble count */
    onlyItem(country: Country) {
        this.selectedCountries = [country];
        this.countryCtrl.setValue([country]);
        this.results.emit([country]);
    }

    resetSelector() {
        this.selectedCountries = [];
        this.countryCtrl.reset();
        this.countryCtrl.markAsTouched();
        this.clear.emit(true);
    }

    flagClass(country: Country): string {
        return `fi-${country.code.toLowerCase()}`;
    }

    emitWhenClosed(state: boolean) {
        if (!state) {
            this.resultsWhenClosed.emit(this.countryCtrl.value);
        }
    }

    trackByFn(index: number, item: Country) {
        return item.code;
    }

    private initCountrySelector() {
        if (!this.options.hasOwnProperty('placeholderLabel')) {
            this.options.placeholderLabel = this.translocoService.translate('shared.country_selector.options_placeholder');
        }

        if (!this.options.hasOwnProperty('noEntriesFoundLabel')) {
            this.options.noEntriesFoundLabel = this.translocoService.translate('shared.country_selector.options_no_entries');
        }
        this.filteredCountries.next(this.countries.slice());

        if (this.options.hasOwnProperty('initValue') && this.options.initValue.name) {
            const initCountryIndex = this.countries.findIndex(country => country.name === this.options.initValue.name);
            setTimeout(() => this.countryCtrl.setValue(this.countries[initCountryIndex]), 0);
        }

        if (this.options.initValueArray) {
            this.countryCtrl.setValue(this.options.initValueArray);
            this.selectedCountries = this.options.initValueArray as any;
            this.results.emit(this.countryCtrl.value);
        }

        this.countryFilterCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.searchCountries();
        });
        if (this.focused) {
            this.countrySelect.open();
        }

        this.countryCtrl.markAsPristine();
        this.countryCtrl.markAsTouched();
    }

    private searchCountries() {
        if (!this.countries) {
            return;
        }
        let search = this.countryFilterCtrl.value;
        if (!search) {
            this.filteredCountries.next(this.countries.slice());
            return;
        }
        search = search.toLowerCase();

        this.filteredCountries.next(
            this.countries.filter(country => {
                if (country.name.toLowerCase().indexOf(search) > -1 || country.code.toLowerCase().indexOf(search) > -1) {
                    return true;
                }
                return false;
            })
        );
    }
}
