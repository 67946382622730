/** @format */

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { PermissionService } from 'app/_services/permission.service';

@Component({
    selector: 'app-license-exceeded-dialog',
    templateUrl: './license-exceeded-dialog.component.html',
    styleUrls: ['./license-exceeded-dialog.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class LicenseExceededDialogComponent {
    constructor(
        private router: Router,
        public dialogRef: MatDialogRef<LicenseExceededDialogComponent>,
        public permissions: PermissionService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    close(): void {
        this.dialogRef.close();
    }

    onBuy(): void {
        this.router.navigate(['/settings/payment'], { queryParams: { tab: 'payment' } });
    }
}
