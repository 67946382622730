/** @format */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { App } from 'app/_models/app.model';
import { FormControl } from '@angular/forms';
import { PermissionService } from 'app/_services/permission.service';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { WindowListenerService } from 'app/_services/window-listener.service';
import { environment } from '@app/env';
import { AppEditorComponent } from '../app-editor/app-editor.component';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'app/_services/app.service';
import { AppConfigComponent } from '../app-config/app-config.component';
import { AppPermissionsComponent } from '../app-permissions/app-permissions.component';
import { ConfirmDialogComponent } from 'app/_dialogs/confirm-dialog/confirm-dialog.component';
import { InsightDoc } from '../insight.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-app-list',
    templateUrl: './app-list.component.html',
    styleUrls: ['./app-list.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'apps', alias: 'apps' },
        },
    ],
})
export class AppListComponent implements OnInit, OnDestroy, OnChanges {
    @Input() listItems: App[];

    @Output() readonly selectedApp = new EventEmitter<App | null>();

    isSearching = false;
    visibleItems: App[];
    insights: InsightDoc[];
    screenWidth = window.innerWidth;
    isMobile = false;
    listClosed = false;
    defaultImage = '../../../assets/img/hailerlogo2.svg';
    baseUrl = `${environment.wsUrl}/image/square100`;

    nameSearchControl = new FormControl('');
    private onDestroy = new Subject<void>();

    constructor(
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        private windowListener: WindowListenerService,
        public permissions: PermissionService,
        public appService: AppService,
    ) {}

    ngOnInit(): void {
        this.nameSearchControl.valueChanges.pipe(takeUntil(this.onDestroy), debounceTime(350)).subscribe({
            next: (value: string) => {
                this.visibleItems = this.changeVisibleListItems(value);

                this.cdr.detectChanges();
            },
        });

        this.windowListener.size.pipe(takeUntil(this.onDestroy)).subscribe({
            next: ({ x }) => {
                this.screenWidth = x;
                this.isMobile = this.screenWidth <= 900;
                this.cdr.detectChanges();
            },
        });
    }

    ngOnChanges(): void {
        this.visibleItems = this.listItems;
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    /** Filter list according to search string */
    changeVisibleListItems(searchValue: string): App[] {
        let visibleItems = this.visibleItems;

        if (searchValue === '' || !searchValue) {
            return this.listItems;
        }
        const search = searchValue.toLowerCase();

        visibleItems = this.listItems.filter(item => {
            const name = item?.name.toLocaleLowerCase();
            // We know this is App
            const description = item.description.toLocaleLowerCase();

            return name.includes(search) || description.includes(search);
        });

        return visibleItems;
    }

    closeList(): void {
        this.listClosed = true;
        this.visibleItems = [];
    }

    openList(): void {
        this.listClosed = false;
        this.visibleItems = this.listItems;
    }

    removeApp(app: App): void {
        this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    cancel: 'Cancel',
                    confirm: 'Remove',
                    content: app.name,
                    title: 'Are you sure you want to delete this App',
                },
                width: '300px',
            })
            .afterClosed()
            .subscribe({
                next: async (confirmed: boolean) => {
                    if (!confirmed) {
                        return;
                    }

                    await this.appService.remove(app._id);
                },
            });
    }

    createApp(): void {
        this.dialog.open<AppEditorComponent, App>(AppEditorComponent, {
            data: null,
            width: '800px',
        });
    }

    editApp(app: App): void {
        this.dialog.open<AppEditorComponent, App>(AppEditorComponent, {
            data: app,
            width: '800px',
        });
    }

    updateApp(app: App): void {
        return;
    }

    configureApp(app: App): void {
        this.dialog.open<AppConfigComponent, App>(AppConfigComponent, {
            data: app,
            width: '800px',
        });
    }

    permissionEditor(app: App): void {
        this.dialog.open<AppPermissionsComponent, App>(AppPermissionsComponent, {
            data: app,
            width: '800px',
        });
    }
}
