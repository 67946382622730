<!-- @format -->

<div id="appContainer" class="container" [class.body-scrollable]="screenWidth <= 600" [class.safari-ios]="platform.IOS && platform.SAFARI">
	<app-top-toolbar
		#topToolbar
		[style.display]="screenWidth <= 600 ? 'block' : 'none'"
		class="toolbar"
		*ngIf="(state | async) === 'authenticated' && !(hideNavigation | async)"></app-top-toolbar>
	<mat-sidenav-container autosize class="sidenav-container" [ngClass]="(hideNavigation | async) ? '' : 'navigation-visible'" hasBackdrop="false">
		<mat-sidenav
			[style.marginTop.px]="marginTop"
			[style.background-color]="adaptiveBackground"
			#theOneSidenav
			position="end"
			autoFocus="false"
			[style.width]="screenWidth <= 600 ? '100vw' : '400px'"
			[fixedInViewport]="screenWidth <= 600"
			[fixedTopGap]="(hideNavigation | async) ? 0 : 48"
			[class.mobile-sidenav]="screenWidth <= 600"
			class="the-one-sidenav">
			<app-ngx-stack-view #stack (depth)="changeDepth($event)"></app-ngx-stack-view>
		</mat-sidenav>
		<mat-sidenav
			#sidebar
			*ngIf="screenWidth > 600 && (state | async) === 'authenticated' && !(hideNavigation | async)"
			[disableClose]="true"
			opened
			mode="side"
			class="sidenav sidenav-small"
			fixedInViewport
			style="overflow: hidden">
			<app-side-nav></app-side-nav>
		</mat-sidenav>
		<mat-sidenav-content>
			<ng-content #content></ng-content>
		</mat-sidenav-content>
	</mat-sidenav-container>
	<app-side-nav *ngIf="screenWidth <= 600 && (state | async) === 'authenticated' && !sidenavStatus && !(hideNavigation | async)" class="mobile-navigation">
	</app-side-nav>
</div>
