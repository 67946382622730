/** @format */

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, filter, merge, takeUntil } from 'rxjs';

import { Activity, GMarker, GPolygon, GPolyline, Location } from '@app/models';
import { GMapConf, GmapComponent } from '@app/shared/gmap/gmap.component';
import { V3ActivityViewContextService } from 'app/v3-activity/v3-activity-view-context.service';
import { ActivityLocation } from 'app/_models/v3-activity.model';

@Component({
    selector: 'app-v3-activity-map',
    templateUrl: './v3-activity-map.component.html',
    styleUrls: ['./v3-activity-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class V3ActivityMapComponent implements OnInit, OnDestroy {
    @ViewChild('gmap', { static: false }) gmap: GmapComponent;

    mapConfig: GMapConf = {
        mapOptions: {
            center: { lat: 60.394068, lng: 25.659631 },
            zoom: 14,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
        },
        allowCreate: false,
        allowSearch: true,
    };

    permissionsLoaded = false;

    searchLocationForm = new FormControl<string>('');
    private onDestroy = new Subject<void>();

    constructor(public viewContext: V3ActivityViewContextService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.viewContext.activity.pipe(takeUntil(this.onDestroy)).subscribe({
            next: activity => {
                if (!activity) {
                    return;
                }

                this.setMarkers(activity);
            },
        });

        if (Object.values(this.viewContext.v3Permissions.value || {}).length) {
            this.mapConfig.allowCreate = this.viewContext.canBeEdited;
            this.permissionsLoaded = true;
            this.cdr.detectChanges();
            this.setMapListeners();
        }

        this.viewContext.v3Permissions.pipe(takeUntil(this.onDestroy)).subscribe({
            next: permissions => {
                if (!Object.keys(permissions || {}).length) {
                    return;
                }

                if (!this.permissionsLoaded) {
                    this.mapConfig.allowCreate = this.viewContext.canBeEdited;
                    this.permissionsLoaded = true;
                    this.cdr.detectChanges();
                    this.setMapListeners();
                    return;
                }

                this.gmap.setDrawState(false);
                this.gmap.setDrawState(this.viewContext.canBeEdited);
                this.cdr.detectChanges();
            },
        });

        this.viewContext.editing.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.setErrors(),
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    setMapListeners() {
        if (this.viewContext.activity.value) {
            this.setMarkers(this.viewContext.activity.value);
        }

        this.gmap.markerClicks$.pipe(takeUntil(this.onDestroy), debounceTime(50)).subscribe({
            next: () => this.clearLocation(),
        });

        merge(
            this.gmap.markerAdded$,
            this.gmap.markerDragDone$,
            this.gmap.polylineAdded$,
            this.gmap.polylineDragDone$,
            this.gmap.polygonAdded$,
            this.gmap.polygonDragDone$
        )
            .pipe(takeUntil(this.onDestroy), debounceTime(50))
            .subscribe({
                next: marker => {
                    this.setLocation(marker);
                },
            });
    }

    setLocation(markerData: GMarker | GPolygon | GPolyline) {
        if (!this.viewContext.canBeEdited) {
            return;
        }

        this.viewContext.editingLocation.next(true);

        let locationOptions: ActivityLocation | null = this.viewContext.toSave.options.location || { data: [] };

        if (markerData instanceof GPolygon || markerData instanceof GPolyline) {
            const polygonCoords = markerData.data.getPath().getArray();
            locationOptions.data = polygonCoords.map(element => ({
                lat: element.lat(),
                lng: element.lng(),
            }));
            locationOptions.type = markerData instanceof GPolygon ? 'area' : 'polyline';
        } else if (markerData instanceof GMarker) {
            const lat = markerData?.data?.getPosition()?.lat() || 0;
            const lng = markerData?.data?.getPosition()?.lng() || 0;

            locationOptions.data = [
                {
                    lat,
                    lng,
                },
            ];
            locationOptions.type = 'point';
        } else {
            locationOptions = null;
        }

        if (this.viewContext.action === 'create') {
            this.viewContext.toSave.activity.location = locationOptions;
        } else {
            this.viewContext.toSave.options.location = locationOptions;
        }

        this.setErrors();
    }

    clearLocation() {
        if (!this.viewContext.canBeEdited) {
            return;
        }

        this.gmap.removeAllMarkers();

        this.viewContext.toSave.options.location = null;
        this.viewContext.editingLocation.next(true);
        this.setErrors();
    }

    setErrors() {
        const currentErrors = this.viewContext.errorsInSidenav.value;
        currentErrors.invalidMap = this.hasErrors;
        this.viewContext.errorsInSidenav.next(currentErrors);
    }

    get hasErrors(): boolean {
        const workflow = this.viewContext.workflow.value;
        const activity = this.viewContext.activity.value;
        const requireLocation = !!workflow?.locationRequired;

        if (this.viewContext.action === 'create') {
            return requireLocation && !this.viewContext.toSave.activity.location;
        }

        if (!requireLocation || !this.viewContext.editingLocation.value || this.viewContext.action === 'editMultiple') {
            return false;
        }

        const locationToSave = this.viewContext.toSave.options.location;
        const setToBeRemoved = locationToSave === null;
        return setToBeRemoved || (!activity?.location?.data && !locationToSave?.data);
    }

    private setMarkers(activity: Activity) {
        if (!this.gmap) {
            return;
        }

        if (!activity?.location?.data) {
            this.gmap.removeAllMarkers();
            return;
        }

        let mark: GMarker | GPolygon | GPolyline;

        switch (activity.location.type) {
            case 'point':
                mark = GMarker.fromHailerLocation(activity.location, true);
                break;
            case 'area':
                mark = GPolygon.fromGooglePolygon(this.getPolygon(activity.location));
                break;
            case 'polyline':
                mark = GPolyline.fromGooglePolyline(this.getPolyline(activity.location));
                break;
        }

        if (!mark) {
            return;
        }

        if (this.gmap.mapLoaded) {
            this.gmap.addNewMarker(mark);
            this.gmap.fitMarkers();
            return;
        }

        if (mark instanceof GMarker) {
            this.mapConfig.markers = [mark];
        } else if (mark instanceof GPolygon) {
            this.mapConfig.polygons = [mark];
        } else if (mark instanceof GPolyline) {
            this.mapConfig.polylines = [mark];
        }
    }

    private getPolygon(location: Location): google.maps.Polygon {
        return new google.maps.Polygon({
            paths: location.data,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
        });
    }

    private getPolyline(location: Location): google.maps.Polyline {
        return new google.maps.Polyline({
            path: location.data,
            strokeColor: '#F39C12',
            strokeOpacity: 1,
            strokeWeight: 2,
        });
    }
}
