/** @format */

import { Platform } from '@angular/cdk/platform';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, pluck, switchMap, take, takeUntil, throttleTime } from 'rxjs/operators';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { Discussion, Message, MessageDraft, MessageDraftMap, PersonalSettings, Process, ProcessField, User } from '@app/models';
import { FileUploaderComponent } from '@app/shared/file-uploader/file-uploader.component';
import { HtmlEscapePipe } from 'app/pipes/src/html-escape.pipe';
import { HtmlUnescapePipe } from 'app/pipes/src/html-unescape.pipe';
import { ActivitiesService } from 'app/activities/activities.service';
import { MentionConfig } from 'app/angular-mentions/mention-config';
import { GroupDiscussionDetailSidenavComponent } from 'app/discussion/group-discussion-detail-sidenav/group-discussion-detail-sidenav.component';
import { EventSidenavComponent } from 'app/events-shared/event-sidenav/event-sidenav.component';
import { PeopleService } from 'app/people/people.service';
import { setMessageDraft } from 'app/redux/actions/message-draft.actions';
import { setGlobalSettings } from 'app/redux/actions/personal-settings.actions';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';
import { CoreService, CoreStore } from 'app/_services/core.service';
import { WindowListenerService } from '../../_services/window-listener.service';
import { PermissionService } from 'app/_services/permission.service';
import { SelectedDiscussionService } from 'app/_services/selected-discussion.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { V3ActivitySidenavComponent } from 'app/v3-activity/v3-activity-sidenav/v3-activity-sidenav.component';
import { TranslateService } from 'app/_services/translate.service';
import { RoutingService } from 'app/_services/routing.service';
import { stripUndefined } from '../../../../test/deps/hailer-api/shared/util';
import { LinkingInfo, NewLink } from '../../../../test/deps/hailer-api/shared/link-types';
import { PollCreateOptions } from '../../../../test/deps/hailer-api/shared/poll-types';
import { RPCService } from 'app/_services/rpc.service';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-discussion-input',
    templateUrl: './discussion-input.component.html',
    styleUrls: ['./discussion-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: TRANSLOCO_SCOPE, useValue: { scope: 'discussion', alias: 'discussion' } },
        { provide: TRANSLOCO_SCOPE, useValue: 'poll' },
    ],
})
export class DiscussionInputComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Output() newDiscussion = new EventEmitter<string>();
    @Output() jumpToBottom = new EventEmitter();
    @Output() jumpToSentMessage = new EventEmitter();

    @Input() containerMode = false;
    @Input() showJumpToBottom = false;

    @ViewChild('textarea', { static: true }) textarea: ElementRef;
    @ViewChild('uploader', { static: true }) uploader: FileUploaderComponent;

    canBeLinkedFromProcesses = this.selectedDiscussion.canBeLinkedFromProcesses;
    newContact: string;
    personalSettings: Observable<PersonalSettings> = this.store.select(state => state.personalSettings);
    enterToSend: boolean;
    discussion = this.selectedDiscussion.discussion;
    usersBase: User[];
    users: User[];
    messageCtrl = new UntypedFormControl('');
    messageDraft: MessageDraft | undefined;
    replyMessage: Message;
    forwardedMessage: Message;
    forwardedMessageUser: string;
    screenWidth = window.innerWidth;
    uploadInProgress = false;

    mentionConfig: MentionConfig = {
        dropUp: true,
        labelKey: 'display_name',
        allowSpace: true,
        disableSearch: false,
        triggerChar: '@',
        mentionSelect: user => {
            setTimeout(() => this.createTag(user), 0);
            return '';
        },
    };

    linkableProcesses: { [processId: string]: { field: ProcessField; process: Process }[] } = {};
    userTyping = new Subject<void>();

    linkingInfo?: LinkingInfo;

    createPoll = false;
    pollToCreate?: PollCreateOptions;
    pollValid: boolean;

    private messageDraftMap: Observable<MessageDraftMap> = this.store.select(state => state.messageDrafts);
    private replyMessageListener = new Subscription();
    private forwardMessageListener = new Subscription();
    private onDestroy = new Subject<void>();
    private textareaElement: Element;
    private mentionOpen = false;
    private me = this.core.user;
    private sendingMessage = false;
    private discussionId: string;

    constructor(
        public platform: Platform,
        public selectedDiscussion: SelectedDiscussionService,
        private renderer: Renderer2,
        private store: Store<CoreStore>,
        private people: PeopleService,
        private cdr: ChangeDetectorRef,
        private v3Discussion: V3DiscussionService,
        private core: CoreService,
        private windowListener: WindowListenerService,
        private sideNav: SideNavService,
        private activities: ActivitiesService,
        private permission: PermissionService,
        private translocoService: TranslocoService,
        private translateService: TranslateService,
        private router: RoutingService,
        private rpc: RPCService,
    ) {}

    // Saving message drafts if user exits/reloads page
    @HostListener('window:beforeunload')
    beforeUnload() {
        this.updateMessageDraft();
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        // Firefox doesn't support "read-write-plaintext-only" css rule so here's a polyfill
        const htmlEscapePipe = new HtmlEscapePipe();
        document.execCommand('insertHTML', false, htmlEscapePipe.transform(event.clipboardData.getData('text')));
        event.preventDefault();

        const files = event.clipboardData.files;
        if (!files?.length) {
            return;
        }

        for (const file of files) {
            this.uploader.uploadFile(file);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.discussionId) {
            return;
        }

        if (!this.inMobileDiscussionView) {
            this.textarea.nativeElement.focus();
        }
    }

    ngOnInit(): void {
        this.selectedDiscussion.newContact.pipe(takeUntil(this.onDestroy)).subscribe({
            next: newContact => {
                this.newContact = newContact;
                if (newContact) {
                    this.updateForwardedMessageUser();
                    void this.parseLinks();
                }
            },
        });

        this.uploader.uploadInProgress.pipe(takeUntil(this.onDestroy)).subscribe({
            next: value => {
                this.uploadInProgress = value;
                this.cdr.detectChanges();
            },
        });

        this.canBeLinkedFromProcesses.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.setActivityLinks(),
        });

        this.selectedDiscussion.discussionChanged.pipe(takeUntil(this.onDestroy)).subscribe({
            next: discussionId => {
                // If we are in a new contact, we want to close any possible forwards
                if (this.newContact) {
                    this.closeDiscussionInputMenu('forward');
                }

                const oldDiscussionMapId = this.discussionId;
                // Setting draft for exited discussion
                if (!this.newContact && oldDiscussionMapId) {
                    // We clear out files for now, since its hard to recreate them in the file uploader with just ids :(
                    this.updateMessageDraft(oldDiscussionMapId, undefined, []);
                    // Clearing old file uploader
                    this.uploader.reset();
                }

                this.discussionId = discussionId;
                // Adding draft into state if newly opened discussion doesn't contain one
                this.messageDraftMap.pipe(take(1)).subscribe({
                    next: discussionMap => {
                        if (!discussionMap[discussionId]) {
                            const messageDraft: MessageDraft = {
                                files: [],
                                msg: '',
                                replyTo: null,
                            };
                            this.store.dispatch(setMessageDraft({ discussionId, messageDraft }));
                        } else {
                            // Loading message draft into messageCtrl if one exists
                            this.messageDraft = discussionMap[discussionId];
                            this.messageCtrl.setValue(this.messageDraft?.msg);
                        }
                    },
                    error: (error: any) => console.error(`failed getting message draft map: ${error}`),
                });

                // Clearing/Creating listeners for only our discussion, so we can listen only to changes to them
                this.replyMessageListener.unsubscribe();
                this.replyMessageListener = this.v3Discussion.replyMessageMap
                    .pipe(takeUntil(this.onDestroy), pluck(discussionId))
                    .subscribe({
                        next: data => {
                            this.replyMessage = data;
                            this.cdr.detectChanges();
                        },
                    });
                // Does the same as for above replyMessageListener
                this.updateForwardedMessageUser();

                if (!this.inMobileDiscussionView) {
                    this.textarea.nativeElement.focus();
                }
            },
        });

        this.discussion.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.updateTaggableUsers();
            },
        });

        // Listening to personal settings, and setting enterToSend
        this.personalSettings?.pipe(takeUntil(this.onDestroy)).subscribe(personalSettings => {
            this.enterToSend = personalSettings.globalSettings?.enterToSend;
        });

        // Changes to message draft of current discussion
        this.v3Discussion.messageDraftUpdated.pipe(takeUntil(this.onDestroy)).subscribe({
            next: data => {
                if (data.discussionId === this.discussion.value?._id) {
                    this.messageDraft = data.messageDraft;
                    this.messageCtrl.setValue(this.messageDraft?.msg);
                }
            },
        });

        this.windowListener.size.pipe(takeUntil(this.onDestroy), debounceTime(100)).subscribe({
            next: ({ x }) => {
                this.screenWidth = x;
                this.cdr.detectChanges();
            },
        });

        this.userTyping.pipe(takeUntil(this.onDestroy), throttleTime(5000)).subscribe({
            next: () => {
                this.v3Discussion
                    .setTypingState(this.discussion?.value?._id, true)
                    .pipe(takeUntil(this.onDestroy))
                    .subscribe({
                        error: error => console.error('Failed to set typing state', error),
                    });
            },
        });

        this.selectedDiscussion.focusMessageInput.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.textarea.nativeElement.focus(),
        });

        void this.parseLinks();
    }

    ngAfterViewInit() {
        this.textareaElement = this.textarea.nativeElement as Element;
        // Insert br tags instead of divs on enter press in Firefox
        document.execCommand('DefaultParagraphSeparator', false, 'br');
        // Firefox doesn't support "read-write-plaintext-only" css rule so here's a polyfill
        this.textareaElement.addEventListener('drop', (event: DragEvent) => {
            event.preventDefault();
        });

        this.textarea.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
            const enterKey = 'Enter';

            this.userTyping.next();

            if (event.code === enterKey) {
                this.onEnter(event);
            }
            event.stopPropagation();
        });

        if (!this.inMobileDiscussionView) {
            this.textarea.nativeElement.focus();
        }
    }

    ngOnDestroy() {
        // Setting mecreateLinkedActivityssage draft when exiting messenger view
        this.updateMessageDraft();
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    togglePollCreator() {
        this.createPoll = !this.createPoll;

        if (!this.createPoll) {
            this.pollToCreate = undefined;
        }
    }

    pollChanged(poll: PollCreateOptions): void {
        this.pollToCreate = poll;
    }

    pollValidityChanged(valid: boolean): void {
        this.pollValid = valid;
    }

    async parseLinks(): Promise<void> {
        if (!this.newContact && !this.discussion.value) {
            await new Promise<void>(resolve => {
                this.discussion
                    .pipe(
                        filter(discussion => !!discussion),
                        takeUntil(this.onDestroy),
                        take(1)
                    )
                    .subscribe({
                        next: () => resolve(),
                    });
            });
        }

        const id = this.newContact || (
            this.discussion.value?.private ? this.getParticipantsWithoutMe(this.discussion.value)[0] : undefined
        );

        if (!id) {
            return;
        }

        const linkingInfoString = localStorage.getItem(`linkingInfo/${id}`);
        if (!linkingInfoString) {
            return;
        }

        this.linkingInfo = JSON.parse(linkingInfoString);
        this.cdr.detectChanges();
    }

    setEnterToSend(state: boolean): void {
        this.personalSettings.pipe(take(1)).subscribe(personalSettings => {
            // Making a new global setting object with enterToSend changed and sending it to redux
            if (!personalSettings.globalSettings) {
                console.error('User personal settings are undefined');
                return;
            }

            const newGlobalSettings: any = {};
            Object.assign(newGlobalSettings, personalSettings.globalSettings);

            newGlobalSettings.enterToSend = state;
            this.store.dispatch(setGlobalSettings({ globalSettings: newGlobalSettings }));
        });
    }

    // Updates forwardedMessage data and sets the user information of the message for forwarding
    updateForwardedMessageUser() {
        const currentDiscussionId = this.newContact || this.discussion.value?._id;
        this.forwardMessageListener.unsubscribe();
        this.forwardMessageListener = this.v3Discussion.forwardMessageMap
            .pipe(takeUntil(this.onDestroy), pluck(currentDiscussionId))
            .subscribe({
                next: data => {
                    this.forwardedMessage = data;

                    if (!this.forwardedMessage) {
                        return;
                    }

                    // We check if the message being forwarded is layered or not and add the corresponding user id to the messageUserId variable
                    const isLayeredForward = this.forwardedMessage.forwardMessage?._id;
                    const messageUserId = isLayeredForward ? this.forwardedMessage.forwardMessage?.uid : this.forwardedMessage?.uid;

                    this.forwardedMessageUser = this.people.getUser(messageUserId)?.display_name;
                    this.people.unknownUsers.pipe(takeUntil(this.onDestroy)).subscribe({
                        next: () => {
                            this.forwardedMessageUser = this.people.getUser(messageUserId)?.display_name;
                            this.cdr.detectChanges();
                        },
                    });

                    this.cdr.detectChanges();
                },
            });
    }

    getDiscussionSubject(discussionId: string): Observable<string> {
        return this.v3Discussion.get(discussionId).pipe(
            map(discussion => {
                if (!discussion) {
                    return '';
                }

                let subject = discussion?.subject;

                if (discussion.private) {
                    const filteredParticipants = this.getParticipantsWithoutMe(discussion);
                    subject = filteredParticipants[0] ? this.people.getUser(filteredParticipants[0]).display_name : 'Unknown User';
                }

                return subject || '';
            })
        );
    }

    /**
     * Check if multiple phases in dataset have activity link fields enabled
     *
     * Returns whether multiple phases in a dataset have activity link fields enabled and first phase with them enabled
     *
     * @param process: Process
     */
    multiplePhasesHaveActivityLinkFields(process: Process) {
        let phasesWithActivityLinkFields = 0;
        let phaseIdWithActivityLinkFields = '';
        for (const phaseId of process.phasesOrder) {
            if (phasesWithActivityLinkFields > 1) {
                break;
            }
            if (this.phaseHasActivityLinkFields(process, phaseId)) {
                phaseIdWithActivityLinkFields = phaseId;
                phasesWithActivityLinkFields++;
            }
        }

        return { multiple: phasesWithActivityLinkFields > 1, phaseId: phaseIdWithActivityLinkFields };
    }

    setActivityLinks() {
        const processes: { [processId: string]: { field: ProcessField; process: Process }[] } = {};
        for (const linkedFromProcess of this.canBeLinkedFromProcesses.value) {
            const processId = linkedFromProcess.process._id;
            if (!processes[processId]) {
                processes[processId] = [];
            }
            processes[processId].push({ field: linkedFromProcess.field, process: linkedFromProcess.process });
        }

        this.linkableProcesses = processes;
    }

    phaseHasActivityLinkFields(process: Process, phaseId: string) {
        let hasLinkFields = false;
        for (const fieldId of process.phases[phaseId].fields) {
            if (process.fields[fieldId].type === 'activitylink') {
                hasLinkFields = true;
                break;
            }
        }
        return hasLinkFields;
    }

    canSendMessage(): boolean {
        if (this.createPoll && !this.pollValid) {
            return false;
        }

        // Conditions that always rule out sending a message
        if (this.uploadInProgress) {
            return false;
        }

        // Conditions that fullfill minimum requirement for sending a message
        if (this.messageHasContent || this.uploader.fileIds?.value.length || this.forwardedMessage?._id) {
            return true;
        }

        return false;
    }

    async sendMessage(): Promise<void> {
        if (this.sendingMessage) {
            return;
        }

        // Checking if message has meaningful content
        if (!this.canSendMessage()) {
            return;
        }

        this.sendingMessage = true;
        this.textarea.nativeElement.focus();

        const links: NewLink[] = [];
        if (this.linkingInfo?.link) {
            links.push(this.linkingInfo.link);
        }

        if (this.pollToCreate && this.pollValid) {
            const poll = await this.rpc.requestAsync('poll.create', [this.pollToCreate]);
            links.push({ target: poll._id, targetType: 'poll', type: 'linked-to' });

            this.createPoll = false;
            this.pollToCreate = undefined;
            this.cdr.detectChanges();
        }

        // If using newContact, start a new private discussion
        if (this.newContact) {
            const message = stripUndefined({
                msg: this.parsedMessage,
                files: this.uploader.fileIds.value,
                forwardMessageId: this.forwardedMessage?._id,
                replyPrivately: this.forwardedMessage?.replyPrivately,
                links: links.length ? links : undefined,
            });

            let newDiscussion: string;

            this.v3Discussion
                .findUserDiscussion(this.newContact)
                .pipe(
                    switchMap(userDiscussion => {
                        newDiscussion = userDiscussion._id;
                        return this.v3Discussion.v3Message.send(message, userDiscussion._id);
                    }),
                    takeUntil(this.onDestroy)
                )
                .subscribe({
                    next: () => {
                        this.messageCtrl.reset();
                        this.uploader.reset();
                        this.sendingMessage = false;
                        this.updateMessageDraft();
                        this.newDiscussion.emit(newDiscussion);
                    },
                });
            // When sending the message we close any of the input context menus that might be open
            this.closeDiscussionInputMenu('forward');
            this.closeDiscussionInputMenu('reply');
            this.closeDiscussionInputMenu('link');
            return;
        }

        /* Updating message draft to portray what we have written, since we directly use it
           when sending the message */
        this.updateMessageDraft();
        this.messageDraft = { ...this.messageDraft, ...stripUndefined({ links: links.length ? links : undefined }) };
        this.messageDraft.msg = this.parsedMessage;

        this.v3Discussion.v3Message
            .send(this.messageDraft, this.discussion.value?._id)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: (message: Message) => {
                    this.messageCtrl.reset();
                    this.uploader.reset();
                    this.jumpToSentMessage.next(message);
                    this.sendingMessage = false;
                    this.updateMessageDraft();
                },
            });
        // When sending the message we close any of the input context menus that might be open
        this.closeDiscussionInputMenu('forward');
        this.closeDiscussionInputMenu('reply');
        this.closeDiscussionInputMenu('link');
    }

    openUploader() {
        this.uploader.open();
    }

    uploadFile(file: File) {
        this.uploader.uploadFile(file);
    }

    /* If receives 'forward', clicked onReply and closes forward context
       If receives 'reply, clicked onForward and closes reply context
       Path: message-container -> message-view -> discussion-input */
    closeDiscussionInputMenu(closeContext: 'reply' | 'forward' | 'link') {
        const discussionContextId = this.newContact || this.discussion.value?._id;
        const id = this.newContact || (
            this.discussion.value?.private ? this.getParticipantsWithoutMe(this.discussion.value)[0] : undefined
        );
        switch (closeContext) {
            case 'reply':
                this.v3Discussion.removeReply(discussionContextId);
                break;
            case 'forward':
                this.v3Discussion.removeForward(discussionContextId);
                break;
            case 'link':
                this.linkingInfo = undefined;
                localStorage.removeItem(`linkingInfo/${id}`);
                break;
            default:
                break;
        }
    }

    mentionsOpened() {
        this.mentionOpen = true;
        // By default show every taggable user if no search term has been entered yet
        this.users = this.usersBase;
    }

    mentionsClosed() {
        this.mentionOpen = false;
        this.renderer.setStyle(this.textarea.nativeElement, 'pointer-events', 'auto');
    }

    get discussionType(): string {
        return this.v3Discussion.getDiscussionType(this.discussion.value);
    }

    openActivity() {
        if (!this.selectedDiscussion.allowActivitySidenav) {
            return;
        }

        this.sideNav.create(V3ActivitySidenavComponent, {
            activityId: this.discussion.value.linked_activity,
        });
    }

    openEvent() {
        this.sideNav.create(EventSidenavComponent, {
            eventId: this.discussion.value.linked_event,
        });
    }

    openGroup() {
        this.sideNav.create(GroupDiscussionDetailSidenavComponent, {
            discussion: this.discussion.value,
        });
    }

    createLinkedActivity(processToLinkTo: Process, fieldId: string, phaseId?: string) {
        this.activities
            .loadActivity(this.discussion.value.linked_activity)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: activity => {
                    const initFieldValues = {
                        [fieldId]: {
                            value: {
                                name: activity.name,
                                _id: activity._id,
                            },
                        },
                    };

                    this.sideNav.create(V3ActivitySidenavComponent, {
                        action: 'create',
                        processId: processToLinkTo._id,
                        initFieldValues,
                        phaseId: phaseId ? phaseId : undefined,
                        created: () => this.sideNav.pop(),
                    });
                    this.cdr.detectChanges();
                },
                error: (error: any) => console.error('error: ', error),
            });
    }

    /**
     * Determines if the messages has any content
     *
     * Checks the input control value and user mention.
     */
    get messageHasContent(): boolean {
        const controlHasValue = !!this.firefoxPolyfills(this.messageCtrl?.value)?.trim();
        const hasUserMention = !!this.textareaElement?.getElementsByClassName('user-mention')?.length;

        return controlHasValue || hasUserMention;
    }

    get discussionSubject(): string {
        if (!this.discussion.value && !this.newContact) {
            return '';
        }
        this.translateService.translationLoaded('discussion').then(() => {
            if (!this.discussion.value) {
                const username = this.people.getUser(this.newContact).display_name;
                return this.translocoService.translate('discussion.input.discussion_subject_user', { username });
            }
            if (this.discussion.value.private) {
                const filteredParticipants = this.getParticipantsWithoutMe(this.discussion.value);
                const username = filteredParticipants[0] ? this.people.getUser(filteredParticipants[0]).display_name : 'Unknown User';
                return this.translocoService.translate('discussion.input.discussion_subject_user', { username });
            }
            return this.translocoService.translate('discussion.input.discussion_subject_generic');
        });
    }

    get isUserGuest(): boolean {
        return this.permission.isNetworkGuest;
    }

    get isCapacitor(): boolean {
        return Capacitor.isNativePlatform();
    }

    private linkableProcessIndex(process: Process): number {
        return this.canBeLinkedFromProcesses.value.findIndex(object => object.process._id === process._id);
    }

    private getParticipantsWithoutMe(discussion: Discussion): string[] {
        return discussion.participants.filter(uid => uid !== this.me.value._id);
    }

    /** Update message draft in redux */
    private updateMessageDraft(
        discussionId: string = this.discussion.value?._id,
        msg: string = this.textareaElement?.innerHTML,
        files: string[] = this.uploader.fileIds.value,
        replyTo: string = this.replyMessage?._id,
        forwardMessageId: string = this.forwardedMessage?._id,
        // When true, we change the forwardMessage data to match message layer 2 in the backend instead of the original forwardedMessage
        replyPrivately: boolean = this.forwardedMessage?.replyPrivately
    ) {
        const draft = {
            files,
            msg,
            replyTo,
            forwardMessageId,
            replyPrivately,
        };
        this.store.dispatch(setMessageDraft({ discussionId, messageDraft: draft }));
    }

    private onEnter(event: KeyboardEvent) {
        if (this.enterToSend && !event.shiftKey && !this.mentionOpen && this.screenWidth > 600) {
            this.sendMessage();
            event.preventDefault();
        } else if (event.ctrlKey && !this.mentionOpen) {
            this.sendMessage();
            event.preventDefault();
        }
    }

    /**
     * Updates the base set of users we can tag in the discussion
     */
    private updateTaggableUsers() {
        if (!this.discussion.value?.participants) {
            return;
        }

        const participants = this.discussion.value.participants;
        this.usersBase = [];
        participants.forEach(participant => {
            // We don't want to tag ourselves
            if (participant === this.me.value.id) {
                return;
            }
            this.usersBase.push(this.people.getUser(participant));
        });

        this.cdr.detectChanges();
    }

    /**
     * Creates tag element of user
     *
     * @param user User to create tag of
     *
     * Rework if you dare
     */
    private createTag(user: User) {
        // Get the node in which the caret is at to know where to insert the tag
        const nodeIndex = this.getCurrentChildNodeIndex();

        // Create tag element using renderer
        const tagElement = this.renderer.createElement('input');
        this.renderer.setAttribute(tagElement, 'disabled', 'disabled');
        this.renderer.setAttribute(tagElement, 'type', 'button');
        this.renderer.setAttribute(tagElement, 'value', `@${user.display_name}`);
        this.renderer.setAttribute(tagElement, 'data-userid', user._id);
        this.renderer.addClass(tagElement, 'user-mention');

        // Insert the tag in correct position in the child node tree
        const childNodes = this.textareaElement.childNodes;
        if (childNodes[nodeIndex] && childNodes[nodeIndex].nextSibling) {
            this.renderer.insertBefore(this.textarea.nativeElement, tagElement, childNodes[nodeIndex].nextSibling);

            // Edge case if caret is at the start of textarea with succeeding node
        } else if (nodeIndex === 0 && !childNodes[nodeIndex].nextSibling) {
            if (!this.textareaElement.firstElementChild) {
                this.renderer.appendChild(this.textarea.nativeElement, tagElement);
            } else if (this.textareaElement.firstElementChild) {
                this.renderer.insertBefore(this.textarea.nativeElement, tagElement, this.textareaElement.firstElementChild);
            }
        }

        // Add space after inserted tag
        const space = this.renderer.createText(' ');
        this.renderer.insertBefore(this.textarea.nativeElement, space, tagElement.nextSibling);

        // Move caret to the empty space
        const selection = window.getSelection();
        const range = document.createRange();
        range.setStart(space, 1);
        selection.removeAllRanges();
        selection.addRange(range);
        this.cdr.detectChanges();
    }

    private getCurrentChildNodeIndex(): number {
        const selection = window.getSelection();
        const node = selection.focusNode;
        if (node.parentNode === this.textarea.nativeElement) {
            const nodeIndex = Array.from(node.parentNode.childNodes).indexOf(node as ChildNode);
            // If caret is at the start of the node, return the preceding node index since the text inside the node won't get split
            const range = window.getSelection().getRangeAt(0);
            const preRange = document.createRange();
            preRange.selectNodeContents(node);
            preRange.setEnd(range.startContainer, range.startOffset);
            const thisText = preRange.cloneContents();
            const atStart = thisText.textContent?.length === 0;
            if (atStart && nodeIndex > 0) {
                return nodeIndex - 1;
            }
            return nodeIndex;
        }
    }

    get inMobileDiscussionView(): boolean {
        return this.screenWidth <= 600;
    }

    private firefoxPolyfills(input: string): string {
        if (!input) {
            return '';
        }
        const brToNewline = input.replace(/<br\s*\/?>/gm, '\n');
        return brToNewline.replace(/&nbsp;/gm, ' ');
    }

    get parsedMessage(): string {
        const messageWithMentions = this.parsedMentionsMessage;
        return new HtmlUnescapePipe().transform(this.firefoxPolyfills(messageWithMentions));
    }

    private get parsedMentionsMessage(): string {
        const textarea = this.textareaElement.cloneNode(true) as Element;
        const mentions = textarea.getElementsByClassName('user-mention');
        const ids = [];

        this.collectionToArray(mentions)
            /* Pick out just the user ids
               as HTMLElement caused defaultValue to throw an error */
            .map((mention: HTMLInputElement) => ({ elem: mention, uid: mention.dataset.userid, defaultValue: mention.defaultValue }))
            // Unique values
            .filter((val, index, self) => self.indexOf(val) === index)
            // Replace the user-mention tag with a placeholder
            .forEach(mention => {
                ids.push(mention.uid);
                const mentionTag = `[user|${mention.uid}|${mention.defaultValue}]`;
                const textNode = document.createTextNode(mentionTag);
                textarea.replaceChild(textNode, mention.elem);
            });

        return textarea.innerHTML;
    }

    private collectionToArray(collection) {
        const array = [];
        for (const element of collection) {
            array.push(element);
        }
        return array;
    }
}
