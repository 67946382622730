/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { Calendar, Company, PersonalSettings } from '@app/models';
import { EventsService } from 'app/events/events.service';
import { CoreService } from 'app/_services/core.service';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { PermissionService } from 'app/_services/permission.service';
import { calendarPermissionTypes } from 'app/_models/permission-type.model';
import { PermissionListMember } from 'app/_models/member.model';
import { TranslateService } from 'app/_services/translate.service';
import { DropdownItems, DropdownOptions, DropdownSelectedItems } from 'app/_models/dropdown-selector.model';

@Component({
    selector: 'app-edit-calendar',
    templateUrl: './edit-calendar.component.html',
    styleUrls: ['./edit-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'events', alias: 'events' } }],
})
export class EditCalendarComponent implements OnInit, OnDestroy {
    calendar: Calendar;
    editCalendarForm: UntypedFormGroup;
    user: PersonalSettings;
    calendarPermissionTypes = calendarPermissionTypes;
    calendarMembers: PermissionListMember[] = [];
    calendarPermissions: { memberId: string; permission: string }[] = [];
    colorPickerCancelButton: string;
    colorPickerConfirmButton: string;
    colorPickerTitle: string;
    members: PermissionListMember[] = [];

    timezoneSelectorItems = new BehaviorSubject<DropdownItems>({});
    timezoneSelectorSelectedItems = new BehaviorSubject<DropdownSelectedItems>({});
    timezoneSelectorOptions = new BehaviorSubject<DropdownOptions>(new DropdownOptions());

    private onDestroy = new Subject<void>();
    private network: Company;
    private defaultTimezone = 'Europe/Helsinki';

    constructor(
        @Inject(MAT_DIALOG_DATA) public calendarId: string,
        private formBuilder: UntypedFormBuilder,
        public eventsService: EventsService,
        public dialogRef: MatDialogRef<EditCalendarComponent>,
        private core: CoreService,
        private dialogHelperService: DialogHelperService,
        public permissions: PermissionService,
        public cdr: ChangeDetectorRef,
        private translocoService: TranslocoService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.user = this.core.user.value;
        this.network = this.core.network.value;
        this.setTimezoneSelector();

        if (!this.calendarId) {
            this.calendarMembers.push({ id: `network_${this.network._id}`, allowRemove: true, info: {}, permissions: [] });
            this.calendarMembers.push({ id: `user_${this.user.id}`, allowRemove: true, info: {}, permissions: ['admin'] });
            this.calendarPermissions.push({ memberId: `user_${this.user.id}`, permission: 'admin' });
        }

        this.setPermissionTypes();

        this.editCalendarForm = this.formBuilder.group({
            color: [null, Validators.required],
            description: [null],
            name: [null, Validators.required],
            _id: [null, this.calendar ? Validators.required : undefined],
            allowGuests: [false],
            timezone: [null],
        });

        this.core.calendars.pipe(takeUntil(this.onDestroy)).subscribe({
            next: calendars => {
                this.calendar = calendars?.[this.network._id]?.[this.calendarId];
                if (!this.calendar) {
                    this.editCalendarForm.controls.name.setValue(
                        this.translocoService.translate('events.components.edit_calendar.ts_new_calendar')
                    );
                    this.editCalendarForm.controls.color.setValue('#438EB9');
                    this.timezoneSelectorSelectedItems.next({ other: [this.formValue.timezone || this.defaultTimezone] });
                    this.cdr.detectChanges();
                    return;
                }

                this.timezoneSelectorSelectedItems.next({
                    other: [this.formValue.timezone || this.calendar.timezone || this.defaultTimezone],
                });

                if (this.formValue.name) {
                    this.calendar.name = this.formValue.name;
                }
                if (this.formValue.color) {
                    this.calendar.color = this.formValue.color;
                }
                if (this.formValue.description) {
                    this.calendar.description = this.formValue.description;
                }
                if (this.formValue.allowGuests) {
                    this.calendar.allowGuests = this.formValue.allowGuests;
                }

                this.getMembers();
                this.editCalendarForm.patchValue(this.calendar);
                this.cdr.detectChanges();
            },
            error: error => console.error('Error loading calendars', error),
        });

        this.timezoneSelectorSelectedItems.pipe(takeUntil(this.onDestroy)).subscribe({
            next: ({ other }) => {
                this.editCalendarForm.controls.timezone?.setValue(other?.[0]);
            },
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    get momentTimezones(): string[] {
        const moment = require('moment-timezone');
        return moment.tz.names();
    }

    setTimezoneSelector() {
        const currentOptions = this.timezoneSelectorOptions.value;
        currentOptions.closeOnSelection = true;
        currentOptions.multiple = false;
        this.timezoneSelectorOptions.next(currentOptions);

        const items: DropdownItems = {};
        for (const timezone of this.momentTimezones) {
            items[timezone] = {
                _id: timezone,
                type: 'other',
                title: timezone,
            };
        }

        this.timezoneSelectorItems.next(items);
    }

    async setPermissionTypes() {
        try {
            await this.translateService.translationLoaded('activities');
            this.calendarPermissionTypes = [{ label: this.translocoService.translate('activities.edit-calendar.admin'), value: 'admin' }];
        } catch (error) {
            console.error('Failed to set calendar permission types');
        }
    }

    private get formValue() {
        return this.editCalendarForm.value;
    }

    closeModal(): void {
        this.dialogRef.close();
    }

    getMembers() {
        this.members = this.permissions.getMemberPermissions(this.calendar?.members);
        this.cdr.detectChanges();
    }

    saveCalendar(): void {
        const newCalendar = {
            name: this.editCalendarForm.value.name,
            color: this.editCalendarForm.value.color,
            timezone: this.editCalendarForm.value.timezone,
        } as Calendar;
        let newCalendarId: string;

        this.eventsService
            .createCalendar(newCalendar)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: async calendar => {
                    newCalendarId = calendar._id;
                    this.calendarMembers.forEach(async member => {
                        await this.eventsService.addCalendarMemberAsync(newCalendarId, member.id);
                        const data = this.calendarPermissions.find(permission => permission.memberId === member.id);
                        if (data) {
                            this.eventsService.grantCalendarPermissionAsync(newCalendarId, data.memberId, data.permission);
                        }
                    });
                    if (this.editCalendarForm.value.description) {
                        newCalendar.description = this.editCalendarForm.value.description;
                        this.eventsService.updateCalendar(newCalendar).subscribe({
                            error: error => console.log('Could not update calendar', error),
                        });
                    }
                    this.dialogRef.close();
                },
                error: error => console.error('Could not create calendar', error),
            });
    }

    addMember(memberId: string): void {
        const member: PermissionListMember = {
            allowRemove: true,
            id: memberId,
            info: {},
            permissions: [],
        };
        this.calendarMembers = [...this.calendarMembers, member];
        this.cdr.detectChanges();
    }

    addPermission(memberId: string, permission: any): void {
        this.calendarPermissions = [...this.calendarPermissions, { memberId, permission }];
        this.cdr.detectChanges();
    }

    removeMember(memberId: string): void {
        this.calendarMembers.splice(
            this.calendarMembers.findIndex(member => member.id === memberId),
            1
        );
        this.calendarMembers = this.calendarMembers.slice();
        this.cdr.detectChanges();
    }

    removePermission(memberId: string, permission: any): void {
        this.calendarPermissions.splice(this.calendarPermissions.indexOf({ memberId, permission }), 1);
        this.calendarPermissions = this.calendarPermissions.slice();
        this.cdr.detectChanges();
    }

    updateCalendar(): void {
        const calendar = {
            name: this.formValue.name,
            color: this.formValue.color,
            description: this.formValue.description,
            _id: this.formValue._id,
            allowGuests: this.formValue.allowGuests,
            timezone: this.formValue.timezone,
        } as Calendar;

        this.eventsService
            .updateCalendar(calendar)
            .pipe(take(1))
            .subscribe({
                next: () => this.dialogRef.close(),
                error: (error: any) => console.error(error),
            });
    }

    removeCalendar() {
        const confirm = this.translocoService.translate('events.components.edit_calendar.delete_button');
        const content = `${this.translocoService.translate('events.components.edit_calendar.confirm_delete')}'${
            this.calendar.name
        }'${this.translocoService.translate('events.components.edit_calendar.confirm_delete_part2')}`;
        const title = this.translocoService.translate('events.components.edit_calendar.delete_calendar');

        this.dialogHelperService
            .showConfirm(confirm, content, title)
            .pipe(
                take(1),
                filter(confirmed => !!confirmed),
                switchMap(() => this.eventsService.removeCalendar(this.calendar._id))
            )
            .subscribe({
                next: () => {
                    this.closeModal();
                },
                error: err => console.error('Error deleting calendar: ', err),
            });
    }
}
