/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@leeviviertola/date-time-picker';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';

import { V3ActivityDetailComponent } from './v3-activity-sidenav/v3-activity-detail/v3-activity-detail.component';
import { V3ActivityInputFieldComponent } from './v3-activity-input-field/v3-activity-input-field.component';
import { SharedModule } from '@app/shared/shared.module';
import { ActivitiesSharedModule } from 'app/activities-shared/activities-shared.module';
import { V3ActivityFilesComponent } from './v3-activity-sidenav/v3-activity-files/v3-activity-files.component';
import { V3ActivitySidenavComponent } from './v3-activity-sidenav/v3-activity-sidenav.component';
import { V3ActivityDiscussionComponent } from './v3-activity-sidenav/v3-activity-discussion/v3-activity-discussion.component';
import { DiscussionSharedModule } from 'app/discussion-shared/discussion-shared.module';
import { V3ActivityFieldComponent } from './v3-activity-field/v3-activity-field.component';
import { V3ActivityLinkedComponent } from './v3-activity-sidenav/v3-activity-linked/v3-activity-linked.component';
import { V3ActivityOptionsComponent } from './v3-activity-sidenav/v3-activity-options/v3-activity-options.component';
import { V3ActivityMapComponent } from './v3-activity-sidenav/v3-activity-map/v3-activity-map.component';
import { V3LinkedFromProcessComponent } from './v3-activity-sidenav/v3-activity-linked/v3-linked-from-process/v3-linked-from-process.component';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { ButtonsModule } from 'app/buttons/buttons.module';
import { V3ActivityExportComponent } from './v3-activity-export/v3-activity-export.component';
import { V3ActivityImportComponent } from './v3-activity-import/v3-activity-import.component';
import { V3ActivityImportEditDialogComponent } from './v3-activity-import-edit-dialog/v3-activity-import-edit-dialog.component';
import { V3ActivityImportExportComponent } from './v3-activity-import-export/v3-activity-import-export.component';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
    declarations: [
        V3ActivityDetailComponent,
        V3ActivityInputFieldComponent,
        V3ActivityFilesComponent,
        V3ActivitySidenavComponent,
        V3ActivityDiscussionComponent,
        V3ActivityFieldComponent,
        V3ActivityLinkedComponent,
        V3ActivityOptionsComponent,
        V3ActivityMapComponent,
        V3LinkedFromProcessComponent,
        V3ActivityExportComponent,
        V3ActivityImportComponent,
        V3ActivityImportEditDialogComponent,
        V3ActivityImportExportComponent,
    ],
    exports: [V3ActivitySidenavComponent, V3ActivityDetailComponent, V3ActivityInputFieldComponent, V3ActivityFieldComponent],
    imports: [
        CommonModule,
        SharedModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        ActivitiesSharedModule,
        SatPopoverModule,
        DiscussionSharedModule,
        TranslocoRootModule,
        ButtonsModule,
        MonacoEditorModule,
        NgxPaginationModule,
    ],
})
export class V3ActivityModule {}
