/** @format */

import { CompleteAction } from './completeAction.model';
import { ActivityFieldMap } from './activityHistory.model';

export class ActivityUpdateData {
    // Public activities: {[key: string]: Activity}

    constructor(
        public activities: {
            [key: string]: {
                fields: ActivityFieldMap;
                files: string[];
                location: any;
                name: string;
            };
        },
        public completeAction: CompleteAction | boolean
    ) {}
}

export interface ActivityUpdateItem {
    fields: ActivityFieldMap;
    files: string[];
    location: any;
    name: string;
}
