/** @format */

import { createReducer, on } from '@ngrx/store';

import { Discussion } from '@app/models';
import { addDiscussion, reset, syncDiscussionsSuccess } from '../actions/discussion.actions';

export const initialState: Discussion[] = [];

const reducer = createReducer(
    initialState,
    on(addDiscussion, (state, { discussion }) => [...state, discussion]),
    on(reset, state => []),
    on(syncDiscussionsSuccess, (state, { discussions, removed }) => {
        const newState = { ...state };

        for (const removedDiscussionId of removed) {
            delete newState[removedDiscussionId];
        }

        return { ...newState, ...discussions };
    })
);

export const discussionReducer = (state, action) => reducer(state, action);
