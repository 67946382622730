/** @format */

import { Deserializable } from './deserializable.model';

export class Team implements Deserializable {
    accounts?: string[];
    cid: string;
    cids?: string[];
    description?: string;
    name: string;
    selected?: boolean;
    timestamp?: string;
    uid?: string;
    updated?: string;
    members: string[];
    _id: string;
    public?: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    constructor(teamData?: any) {
        if (teamData) {
            this.deserialize(teamData);
        }
    }
}

export interface TeamMap {
    [cid: string]: {
        [id: string]: Team;
    };
}
