/** @format */

import { Action } from './action.enum';
import { Deserializable } from './deserializable.model';
import { Activity } from './activity.model';
import { Discussion } from './discussion.model';
import { LinkData, NewLink } from '../../../test/deps/hailer-api/shared/link-types';

export class Message implements Deserializable {
    uid?: string;
    content?: any;
    action?: Action;
    files?: any[];
    meta?: {
        activity: Activity;
        /** Attending status for  */
        attending: boolean | null;
        startdate?: any;
        duedate?: any;
        assignedTo?: any;
        priority?: any;
        text?: any;
        added?: any;
        removed?: any;
        title?: any;
        start?: any;
        end?: any;
        allDay?: any;
        notes?: any;
        location?: any;
        attendees?: any[];
        data?: any[];
        time?: any;
        work?: any;
        name?: any;
        phase?: any;
        _process?: any;
        process?: any;
        loc?: any;
        fields?: any;
        uid?: string;
        length?: number;
        movedFrom: string;
        movedTo: string;
        event?: {
            repeating: boolean;
            start: number;
        };
    };
    _metas?: any;
    type?: any;
    discussion?: string;
    created?: number;
    updated?: number;
    edited?: number;
    loc?: any;
    msg?: any;
    tagged?: any[];
    _id?: string;
    isConsecutive?: boolean;
    dateDivider?: boolean;
    justInitiated?: boolean;
    removed?: number;
    reactions?: Reactions;
    isStarred?: boolean;
    replyTo?: string;
    replyMessage?: any;
    forwardMessage?: Message;
    forwardMessageId?: string;
    replyPrivately?: boolean;
    mentions?: string[];
    focusedMessage?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _discussion?: Discussion;
    linkedData?: LinkData[];
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export interface Reactions {
    like: string[];
    dislike: string[];
    funny: string[];
    thinking: string[];
    cookie: string[];
}

export type ReactionTypes = 'cookie' | 'dislike' | 'funny' | 'like' | 'thinking';

export interface MessageDraft {
    files?: string[];
    msg: string;
    replyTo?: string;
    forwardMessageId?: string;
    links?: NewLink[];
}

export interface MessageDraftMap {
    [discussionId: string]: MessageDraft;
}

export interface MessageMap {
    [discussionId: string]: Message[];
}
