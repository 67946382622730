/** @format */

import { Deserializable } from './deserializable.model';

export class User implements Deserializable {
    // Default values for loading / unknown user ?
    _id: string;
    firstname: string;
    lastname: string;
    display_name = '';
    email: string;
    emailVerified?: boolean;
    notificationEmail?: string;
    noPassword?: boolean;
    password?: string;
    default_profilepic?: string;
    username?: string;
    online?: boolean;
    // TODO: fix the lastSeen!
    lastSeen?: any;
    selected?: boolean;
    short_name?: string;
    networks: string[];
    status: string;
    lastLocation: {
        lat: number;
        lng: number;
        name: string;
        time?: string;
    };
    profilepics: string[];
    default_company: string;
    notifications: any; // TODO: what the is the model for this?
    guisettings?: any;
    calendars?: string[];
    settings?: any;
    companies?: string[];
    preferredLanguages?: string[];
    managedUser?: boolean;

    constructor(userData?: any) {
        if (userData) {
            this.deserialize(userData);
        }
    }

    deserialize(userData: any): this {
        Object.assign(this, userData);
        if (this.firstname && this.lastname) {
            this.display_name = `${this.firstname} ${this.lastname}`;
        } else if (!this.firstname && this.lastname) {
            this.display_name = this.lastname;
        } else if (this.firstname && !this.lastname) {
            this.display_name = this.firstname;
        }

        if (this.firstname && this.lastname) {
            this.short_name = `${this.firstname[0]}.${this.lastname}`;
        } else if (!this.firstname && this.lastname) {
            this.short_name = this.lastname;
        } else if (this.firstname && !this.lastname) {
            this.short_name = this.firstname[0];
        }

        this.networks = this.companies || [];

        return this;
    }
}

export interface UserMap {
    [id: string]: User;
}


export interface UpdateUserData {
    section: string;
    uid: string;
    value: boolean | string;
}
