<div style="display: flex; flex-direction: column;">
    <div data-cy="crop-image-dialog-label" class="topbar-container">
        <h3> {{'dialogs.crop_image_dialog.crop_image_header' | transloco}}</h3>
        <button data-cy="crop-image-close-button" class="close-button" (click)="cancel()" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <button data-cy="crop-image-file-uploader" type="button" mat-raised-button (click)="fileInput.click()">{{'dialogs.crop_image_dialog.choose_file_button' | transloco}}</button>
    <input data-cy="crop-image-file-uploader-input" #fileInput hidden type="file" (change)="fileChangeEvent($event)" />

    <image-cropper data-cy="image-cropper" [style.display]="fileInput.files?.length ? 'inherit' : 'none'" class="cropper" #cropper [imageChangedEvent]="imageChangedEvent" [aspectRatio]="options.aspectRatio" [maintainAspectRatio]="true" [containWithinAspectRatio]="false"
        [alignImage]="'center'" [autoCrop]="false" [format]="'png'" [transform]="transformCropper" (imageCropped)="onCropped($event)"
        (imageLoaded)="onImageLoaded()" [resizeToWidth]=1024 (loadImageFailed)="onError($event)" (cropperReady)="enableCropping = true">
    </image-cropper>
</div>

<div class="actions-container" style="justify-content: space-between;" *ngIf="!uploading else uploadBar">
    <button class="cancel-btn" mat-button color="primary" (click)="cancel()">{{'dialogs.crop_image_dialog.cancel_button' | transloco}}</button>

    <div *ngIf="enableCropping" class="edit-buttons-container">
        <button data-cy="image-rotate-left" mat-icon-button (click)="flipImage('left')">
            <mat-icon>rotate_left</mat-icon>
        </button>

        <button data-cy="image-rotate-right" mat-icon-button (click)="flipImage('right')">
            <mat-icon>rotate_right</mat-icon>
        </button>

        <button data-cy="image-zoom-out" mat-icon-button (click)="zoomImage('out')">
            <mat-icon>zoom_out</mat-icon>
        </button>

        <mat-slider min="1" max="10" step="0.01" (change)="sliderChange($event)" [value]="transformCropper.scale"></mat-slider>

        <button data-cy="image-zoom-in" mat-icon-button (click)="zoomImage('in')">
            <mat-icon>zoom_in</mat-icon>
        </button>
    </div>

    <button data-cy="image-crop-and-save" class="save-btn" mat-flat-button color="accent" [disabled]="!enableCropping" (click)="cropAndSave()">
        {{'dialogs.crop_image_dialog.crop_and_save_button' | transloco}}
    </button>
</div>

<ng-template #uploadBar>
    <div class="actions-container" style="justify-content: flex-end;">
        <div *ngIf="uploading" class="upload-bar" [ngStyle]="{'width': getPercentage()}"></div>
        <button class="save-btn" mat-flat-button color="accent" [disabled]="!finishedId" (click)="close()">
            {{'dialogs.crop_image_dialog.save_button' | transloco}}
        </button>
    </div>
</ng-template>