/** @format */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    Output,
    Renderer2,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface StackItem {
    component: any;
    element: any;
    scrollTop: number;
    ready: boolean;
}

@Component({
    selector: 'app-ngx-stack-view',
    templateUrl: './ngx-stack-view.component.html',
    styleUrls: ['./ngx-stack-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxStackViewComponent implements OnDestroy {
    @Output() depth = new EventEmitter<number>();

    ready = true;
    stack: StackItem[] = [];
    private onDestroy = new Subject<void>();

    constructor(private renderer: Renderer2, private el: ElementRef, private view: ViewContainerRef, private cdr: ChangeDetectorRef) {
        this.depth.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                if (this.stack.length === 0) {
                    this.ready = true;
                    this.cdr.detectChanges();
                } else {
                    this.updateReadyStatus();
                }
            },
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    /**
     * Instantiate a component on top of the stack
     */
    create(template: any, props: { [property: string]: any } = {}) {
        props.pop = this.pop.bind(this);
        props.clear = this.clear.bind(this);
        props.ready = this.setItemReadyStatus.bind(this, this.stack.length);
        this.ready = false;

        // Store the scroll position of this element, so it can be restored on pop
        const scrollTop = this.stack.length && this.stack[this.stack.length - 1].element.scrollTop;
        const component = this.view.createComponent(template);
        this.attachProps(component.instance, props);

        const element = this.renderer.createElement('div');
        element.className = 'item';
        element.insertBefore(component.location.nativeElement, element.firstChild);

        this.renderer.appendChild(this.el.nativeElement, element);
        this.stack.push({ component, element, scrollTop, ready: false });
        this.depth.emit(this.stack.length);

        setTimeout(() => {
            if (!this.stack[this.stack.length - 1]) {
                return;
            }
            this.stack[this.stack.length - 1].ready = true;
            this.updateReadyStatus();
        }, 500);

        return component;
    }

    pop() {
        if (!this.stack.length) {
            return;
        }

        const { component, element, scrollTop } = this.stack.pop();
        component.destroy();
        element.remove();

        this.depth.emit(this.stack.length);

        if (this.stack.length) {
            console.log('there is still an element on the stack, trying to restore scrollTop', scrollTop);
            this.stack[this.stack.length - 1].element.scrollTop = scrollTop;
        }
    }

    clear(): void {
        while (this.stack.length) {
            this.pop();
        }
    }

    private setItemReadyStatus(stackIndex: number, ready: boolean) {
        if (this.stack[stackIndex]) {
            this.stack[stackIndex].ready = ready;
            this.updateReadyStatus();
        }
    }

    private attachProps(instance, props) {
        for (const key in props) {
            if (typeof props[key] === 'function') {
                if (!instance[key]) {
                    continue;
                }

                // TODO: Are we causing a memory leak?
                instance[key].subscribe({
                    next: (event: any) => {
                        props[key](event);
                        this.cdr.detectChanges();
                    },
                });
            } else {
                // Assume @Input
                instance[key] = props[key];
            }
        }
    }

    private updateReadyStatus() {
        this.ready = this.stack[this.stack.length - 1]?.ready || false;
        this.cdr.detectChanges();
    }
}
