/** @format */

import mergeOptions from 'merge-options';

import { FeedPostProcess, Process, ProcessMap } from '@app/models';
import { stripUndefined } from 'app/_helpers/util';

export const translateProcess = <T extends Translatable>(workflow: T, userLanguages?: string[], networkLanguages?: string[]) => {
    if (!workflow?.translations || !userLanguages?.length || !networkLanguages?.length) {
        return workflow;
    }

    // If process translations don't include default network language
    if (networkLanguages[0] && !Object.keys(workflow.translations).includes(networkLanguages[0])) {
        return workflow;
    }

    for (const language of userLanguages) {
        const processTranslation = workflow.translations[language];
        if (!processTranslation || !networkLanguages.includes(language)) {
            continue;
        }

        return mergeOptions(workflow, stripUndefined(processTranslation));
    }

    return workflow;
};

export const updateProcesses = (processMap: ProcessMap, userLanguages: string[], networkLanguages: string[]): ProcessMap => {
    for (const workspaceId of Object.keys(processMap)) {
        for (const processId of Object.keys(processMap[workspaceId])) {
            const translatedProcess = translateProcess(processMap[workspaceId][processId], userLanguages, networkLanguages);
            processMap[workspaceId][processId] = translatedProcess;
        }
    }

    return processMap;
};

// Add translatable process-types here as needed
export type Translatable = FeedPostProcess | Process;
