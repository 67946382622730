/** @format */

import { Theme } from './types';

export const darkTheme: Theme = {
    name: 'dark',
    properties: {
        '--transparent-accent-500': '#20a25015',
        '--accent-400': '#20A250',
        '--accent-500': '#20a250',
        '--accent-800': '#27ff67',
        '--accent-text': '#2ed76d',
        '--activity-name-weight': '700',
        '--background-primary': '#2c3035',
        '--background-primary-alt': '#272b30',
        '--background-secondary': '#25282d',
        '--background-secondary-alt': '#22252a',
        '--background-tertiary': '#202328',
        '--discussion-background': '#272b30',
        '--messenger-bg': 'invert(1)',
        '--messenger-bg-opacity': '0.03',

        '--border-color': 'rgba(255, 255, 255, 0.12)',
        '--container-background': '#0a0e13',
        '--date-divider': '#43474c',
        '--form-clear-button': 'rgba(255, 255, 255, 0.1)',
        '--hover-background': 'rgba(255, 255, 255, 0.04)',
        '--hover-background-light': 'rgba(255, 255, 255, 0.02)',
        '--icon-color': '#ffffff',
        '--icon-badge-content': '#fff',
        '--kanban-background': '#080f17',
        '--loading-overlay': 'rgba(0, 0, 0, 0.5)',
        '--own-message': '#364150',
        '--primary-500': '#2e86ff',
        '--primary-A400': '#1478ff',
        '--primary-A700': '#8ac3ff',
        '--primary-text': '#2e86ff',
        '--text-high': 'rgba(255, 255, 255, 0.87)',
        '--text-low': 'rgba(255, 255, 255, 0.38)',
        '--text-medium': 'rgba(255, 255, 255, 0.60)',
        '--user-message': '#34373F',
        '--warn-text': '#ec6b4d',
        '--tooltip-background': '#4b6281',

        '--mat-card': '#353a40',

        '--filter-active-text': '#EDEFF3',
        '--filter-inactive-text': '#EDEFF3',
        '--filter-active-bg': '#34373F',
        '--filter-inactive-bg': '#292C33',
        '--filter-hover': '#34373F',
        '--bubble-text': '#EDEFF3',
        '--bubble-bg': '#17873F',
        '--remove-icon': '#365D8C',
        '--radio-button-inset': '#1A2A3C',
        '--active-icon': '#fff',
        '--inactive-icon': '#898E99',

        '--icon-invert': '#EDEFF3',

        '--grey-400': '#C7CAD1',
        '--grey-100': '#fbfbfb',
        '--activity-filter-hover': '#34373F',
        '--blue-700': '#20344A',
        '--blue-100': '#3c444e',

        '--side-nav-bg': '#202328',
        '--size-nav-cards': '#25282d',

        '--sidenav-back-button-bg': '#1A2A3C',

        '--background': '#202124',
        '--note-background': '#4C4A2B',

        '--subtitles': '#898E99',
        '--dividers': '#53565F',

        '--large-emoji-background': '#b9c3e129',
        '--darken': '#00000024',
        '--darken-strong': '#00000040',
        '--lighten': '#ffffff1a',
        '--warn-500': '#e42b00',

        '--password-meter-background': '#272b30',
        '--shadow-first': '#2021241E',
        '--shadow-second': '#202124',

        // Activity sidenav use only:
        '--sidenav-header-gradient-top': 'rgba(41, 44, 51, 0.3)',
        '--sidenav-header-img-shadow': '#202124',

        /* Updated / correct colors & names */
        '--white': '#EDEFF3',
        '--black': '#000000',
        '--starred-primary': '#FFC224',
        '--reaction-container': '#45566D',
        '--user-reaction-container': '#53565F',
        '--user-mention-default': '#A8DBFF',
        '--user-mention-own-message': '#A8DBFF',
        '--links-default': '#A8DBFF',
        '--links-light': '#A8DBFF',
        '--hover': '#25272E',
        '--accent-100': '#EDEFF3',
        '--add-button': '#20a250',

        '--selected-item': '#364150',
        '--user-link-default': '#A8DBFF',
        '--message-input-border-default': '#53565F',
        '--input-border-active': '#17873F',
        '--message-input-bg': '#34373F',
        '--more-messages-note': '#202124',
        '--small-text-gray': '#898E99',
        '--small-text-darker-gray': '#898E99',
        '--timestamp-own-message': '#898E99',
        '--message-deleted-own-message': '#898E99',
        '--selected-discussion': '#364150',
        '--focused-message-bg': '#6c7f98',
        '--highlight-message-bg': '#6c7f9880',
        '--edit-textarea-bg': '#34373F',
        '--function-btn-admin-bg': '#365D8C',
        '--function-btn-admin-icon': '#EDEFF3',
        '--function-btn-user-bg': '#202124',
        '--function-btn-user-icon': '#EDEFF3',

        '--checkbox-bg': '#20A250',
        '--menu-text': '#EDEFF3',

        '--input-default-bg': '#292C33',
        '--input-highlight-bg': '#202124',
        '--input-highlight-bg-light-theme-only': '#292C33',
        '--input-error-background': '#3F3434',
        '--input-placeholder': '#898E99',
        '--input-placeholder-highlight-bg': '#898E99',

        '--dialog-card-bg': '#292C33',
        '--dialog-bg-highlight': '#151618',
        '--tag-bg': '#253C56',
        '--activity-mention-tag': '#96ECB3',
        '--activity-mention-tag-light': '#96ECB3',
        '--feed-card-bg': '#202124',
        '--feed-card-section-bg': '#292C33',

        /* Public pages start */
        '--text-black-public-pages': '#212121',
        '--input-default-bg-public-pages': '#ffffff',
        '--input-error-background-public-pages': '#FEF0F0',
        '--placeholder-public-pages': '#737478',
        '--tertiary-btn-text-public-pages': '#253C56',
        '--primary-btn-bg-public-pages': '#17873F',
        '--input-border-active-public-pages': '#17873F',
        /* Public pages end */

        /* Icons start */
        '--icon-default': '#EDEFF3',
        '--icon-destructive': '#E42B00',
        '--icon-accent': '#4C71A0',
        '--icon-success': '#17873F',
        /* Icons end */

        /* Buttons start */
        '--button-primary-background-default': '#17873F',
        '--button-primary-soft-background-default': '#17873F',
        '--button-secondary-background-default': '#253C56',
        '--button-tertiary-default-background': '#292C33',
        '--button-tertiary-highlight-background': '#202124',
        '--button-tertiary-soft-background': '#292C33',
        '--button-destructive-default-background': '#E42B00',
        '--button-destructive-toned-down-background': '#202124',

        '--button-text-primary-soft': '#EDEFF3',
        '--button-text-all-white': '#EDEFF3',
        '--button-text-default': '#EDEFF3',
        '--button-text-tertiary': '#EDEFF3',
        '--button-text-tertiary-soft': '#EDEFF3',
        '--button-text-destructive-toned-down': '#E42B00',
        '--focus-outline': '#A8DBFF',
        '--focus-outline-disabled-opacity': '#8FD0FF4D',
        '--focus-outline-light': '#A8DBFF',

        '--button-primary-background-hover': '#006C32',
        '--button-primary-soft-background-hover': '#006C32',
        '--button-secondary-background-hover': '#1D334B',
        '--button-tertiary-default-hover': '#25272E',
        '--button-tertiary-highlight-hover': '#151618',
        '--button-tertiary-soft-hover': '#25272E',
        '--button-destructive-default-hover': '#9A1D00',
        '--button-destructive-toned-down-hover': '#151618',

        '--button-text-primary-soft-hover-active': '#EDEFF3',
        '--button-text-tertiary-soft-hover-active': '#EDEFF3',

        '--button-primary-background-active': '#003217',
        '--button-primary-soft-background-active': '#003217',
        '--button-secondary-background-active': '#081826',
        '--button-tertiary-default-background-active': '#151618',
        '--button-tertiary-highlight-background-active': '#0E0F12',
        '--button-tertiary-soft-active': '#151618',
        '--button-destructive-default-background-active': '#681400',
        '--button-destructive-toned-down-background-active': '#0E0F12',

        '--button-text-tertiary-highlight-background-disabled': '#EDEFF34D',
        '--button-text-destructive-toned-down-background-disabled': '#E42B004D',

        '--button-icon-color-default': '#EDEFF3',
        '--button-icon-color-destructive': '#E42B00',
        '--button-icon-color-success': '#17873F',
        '--button-icon-color-accent': '#4C71A0',
        '--button-icon-color-all-white': '#EDEFF3',
        '--button-icon-default-background-hover': '#1A1B1E',
        '--button-icon-default-background-active': '#0E0F12',
        '--button-icon-all-white-background-hover': '#1A1B1E',
        '--button-icon-all-white-background-active': '#0E0F12',
        /* Buttons end */
    },
    linkColors: [
        '#FFC2F2',
        '#FFC7D4',
        '#E2CCFF',
        '#C2D5FF',
        '#A8D8FF',
        '#8CF8FA',
        '#9BF9AF',
        '#FFED89',
        '#FFCB84',
        '#FFC6BD',
        '#FCFF68',
        '#BCDCAC',
        '#FFE5C7',
        '#C3FEE2',
        '#94FFE4',
    ],
    calendarLinkColors: [
        '#FF7676',
        '#88B2F3',
        '#FF53E9',
        '#4EFF15',

        '#FF5959',
        '#34D0FF',
        '#C871FE',
        '#C38848',

        '#F791BB',
        '#0CFBFD',
        '#CBC8E6',
        '#D4BF68',

        '#E265BB',
        '#7CCB9D',
        '#653CFF',
        '#D8A99A',
    ],
};
