/** @format */

import { Sort } from '@angular/material/sort';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as _moment from 'moment';

import { Activity, ActivityListArgument, ProcessViewType } from '@app/models';
import { ProcessService } from './process.service';

@Injectable()
export class ActivitiesFilterService {
    activityCalendarSelectedFilter = new Subject<{ _id: string; label: string }>();
    activityCalendarSelectedColorResource = new Subject<{ _id: string; label: string; type: string }>();
    activityCalendarResources = new Subject<any[]>();
    activityCalendarSelectedResources = new Subject<{ id: string; title: string }[]>();
    activityCalendarFilterResources = new Subject<boolean>();
    activityFilters = new BehaviorSubject<Sort>(undefined);
    argsSubject: BehaviorSubject<ActivityListArgument>;
    addUser$: Observable<{ userId: string; fieldId: string }[]>;
    filterReset$: Observable<void>;

    private args: ActivityListArgument = {
        filter: {},
        limit: 20,
        search: null,
        skip: 0,
        sortBy: 'created',
        sortOrder: 'desc',
    };
    private addUserSubject = new Subject<{ userId: string; fieldId: string }[]>();
    private filterResetSubject = new Subject<void>();

    constructor(private process: ProcessService, private route: ActivatedRoute, private router: Router) {
        this.init();
    }

    set teamFilter(teamId: string) {
        this.args.filter.team = teamId;
        this.updateArguments(this.args, false);
    }

    set datesFilter(datesFilter: { end?: string; start?: string }) {
        if (datesFilter?.end && !datesFilter.end.endsWith('Z')) {
            datesFilter.end = `${_moment(parseInt(datesFilter.end, 10)).format('YYYY-MM-DDTHH:mm:ss')}Z`;
            datesFilter.start = `${_moment(parseInt(datesFilter.start, 10)).format('YYYY-MM-DDTHH:mm:ss')}Z`;
        }
        this.args.filter.dates = datesFilter;
        this.updateArguments(this.args, false);
    }

    set userFilter(userFilter: { field?: string; uid?: string }) {
        this.args.filter.user = userFilter;
        this.updateArguments(this.args, false);
    }

    set calendarFieldFilter(calendarFieldFilter: string) {
        this.args.filter.calendarField = calendarFieldFilter;
        this.updateArguments(this.args, false);
    }

    setSort(sortBy: string, sortOrder: '' | 'asc' | 'desc'): void {
        this.args.sortOrder = sortOrder || 'desc';

        switch (sortBy) {
            case 'Name':
            case 'created':
            case 'updated':
            case 'Owner':
                this.args.sortBy = sortBy.toLowerCase();
                break;
            case 'completedOn':
                this.args.sortBy = 'completedOn';
                break;
            case 'Owner_Team':
                this.args.sortBy = 'team';
                break;
            case 'messenger':
                this.args.sortBy = 'following';
                break;
            default:
                this.args.sortBy = sortBy;
                break;
        }

        this.updateArguments(this.args, false);
    }

    set searchTerm(searchTerm: string) {
        this.args.search = searchTerm || null;
        this.updateArguments(this.args, true);
    }

    resetFilters(view: ProcessViewType) {
        if (view === 'calendar' || view === 'timeline') {
            delete this.args.filter.team;
            delete this.args.filter.user;
        } else {
            this.args.filter = {};
        }
        this.args.search = null;
        this.args.sortBy = 'created';
        this.args.sortOrder = 'desc';
        this.filterResetSubject.next();
        this.updateArguments(this.args, false);
    }

    activeFilters(view: string): boolean {
        if (view === 'calendar') {
            if (this.args.filter.hasOwnProperty('user') || this.args.filter.hasOwnProperty('team')) {
                return true;
            }
        } else if (this.args.search !== null) {
            return true;
        } else {
            return Object.keys(this.args.filter).length > 0;
        }
    }

    updateArguments(args: ActivityListArgument, persist: boolean): void {
        let filter = {};
        if (persist) {
            filter = JSON.parse(JSON.stringify(this.args.filter));
        }

        if (args) {
            for (const arg of Object.keys(args)) {
                if (arg === 'filter') {
                    for (const key of Object.keys(args.filter)) {
                        if (args.filter[key]) {
                            filter[key] = args[arg][key];
                        }

                        if (args.filter[key] === null) {
                            delete filter[key];
                        }
                    }
                } else if (arg !== undefined) {
                    this.args[arg] = args[arg];
                }
            }
        }

        this.args.filter = filter;
        this.setQueryParams();
        this.argsSubject.next(this.args);
    }

    getFilter(filter: string) {
        return this.args.filter[filter];
    }

    // Adds filter if activity includes new values
    addFilters(activity: Activity) {
        const fields = Object.keys(activity.fields)
            .filter(fieldId => activity.fields[fieldId].type === 'users' && activity.fields[fieldId].value)
            .map(fieldId => {
                const filter = {
                    userId: activity.fields[fieldId].value,
                    fieldId,
                };
                return filter;
            });

        this.addUserSubject.next(fields);
    }

    // Test an activity against current filters
    validActivity(activity: Activity) {
        const filter = this.args.filter;
        if (!filter && !this.args.search) {
            return true;
        }

        const proc = this.process.getProcess(activity.process);
        const phase = proc.phases[activity.currentPhase];

        if (this.args.search) {
            if (!activity.name.includes(this.args.search)) {
                return false;
            }
        }
        if (filter.team) {
            if (!activity.team_account || activity.team_account.team !== filter.team) {
                return false;
            }
        }
        if (filter.dates) {
            const start = new Date(filter.dates.start);
            const end = new Date(filter.dates.end);
            const type = proc.fields[phase.primaryDateField].type;
            const field = activity.fields[phase.primaryDateField];

            if (!field || !field.value) {
                return false;
            }
            if (type === 'date') {
                const value = new Date(field.value);
                if (value < start || value > end) {
                    return false;
                }
            } else if (type === 'daterange') {
                if (new Date(field.value.end) < start || new Date(field.value.start) > end) {
                    return false;
                }
            }
        }
        if (filter.user) {
            const field = activity.fields[filter.user.field];

            if (!field || field.value !== filter.user.uid) {
                return false;
            }
        }

        return true;
    }

    private setQueryParams() {
        const filter = this.args.filter;
        const params: any = {};
        if (filter.user) {
            params.user = `${filter.user.uid}_${filter.user.field}`;
        }
        if (filter.team) {
            params.team = filter.team;
        }
        if (filter.dates) {
            params.dates = `${filter.dates.start}_${filter.dates.end}`;
        }

        if (this.router.url.includes('kanban') && filter.dates?.field) {
            params.field = filter.dates.field;
        }

        if (filter.calendarField) {
            params.calendarField = `${filter.calendarField}`;
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
        });
    }

    private init() {
        this.argsSubject = new BehaviorSubject<ActivityListArgument>(this.args);
        this.addUser$ = this.addUserSubject.asObservable();
        this.filterReset$ = this.filterResetSubject.asObservable();
    }
}
