/** @format */

import { AppConfigComponent } from './app-config/app-config.component';
import { AppEditorComponent } from './app-editor/app-editor.component';
import { AppListComponent } from './app-list/app-list.component';
import { AppManagementComponent } from './app-management/app-management.component';
import { AppPageComponent } from './app-page/app-page.component';
import { AppPermissionsComponent } from './app-permissions/app-permissions.component';
import { AppRoutingModule } from './app-routing.module';
import { AppTopbarComponent } from './app-topbar/app-topbar.component';
import { ButtonsModule } from '../buttons/buttons.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { TranslocoModule } from '@ngneat/transloco';
import { InsightPermissionComponent } from './insight-permission/insight-permission.component';
import { InsightEditorComponent } from './insight-editor/insight-editor.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { NgChartsModule } from 'ng2-charts';
import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
    declarations: [
        AppManagementComponent,
        AppPageComponent,
        AppPermissionsComponent,
        AppConfigComponent,
        AppListComponent,
        AppEditorComponent,
        AppTopbarComponent,
        InsightEditorComponent,
        InsightPermissionComponent,
    ],
    exports: [AppPermissionsComponent],
    imports: [
        CommonModule,
        AppRoutingModule,
        SharedModule,
        TranslocoModule,
        ButtonsModule,
        SatPopoverModule,
        NgChartsModule,
        MonacoEditorModule,
        A11yModule
    ],
})
export class AppModule {}
