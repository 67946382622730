<!-- @format -->

<div class="login-background">
    <div class="bubbles"></div>
    <div class="language-change-container">
        <button
            data-cy="language-change-button"
            class="no-button for-text-and-icon"
            [ngClass]="languageMenuOpen ? 'menu-open' : 'menu-closed'"
            (menuOpened)="languageMenuOpen = true"
            (menuClosed)="languageMenuOpen = false"
            [matMenuTriggerFor]="menu">
            <span class="language-button-text">{{ selectedLanguage }}</span>
            <mat-icon svgIcon="hailer-chevron"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" aria-selected="selectedLanguage" class="multilanguage-menu">
            <button mat-menu-item (click)="changeLanguage('en')">English</button>
            <button mat-menu-item (click)="changeLanguage('fi')">Suomi</button>
            <button mat-menu-item (click)="changeLanguage('sv')">Svenska</button>
        </mat-menu>
    </div>

    <mat-card class="login-card">
        <mat-card-content>
            <app-hailer-logo></app-hailer-logo>

            <!-- Register form  -->
            <div *ngIf="page === 'registerform'">
                <div class="flex-column--centered">
                    <h1 *ngIf="showRegistration" class="login-header">{{ 'public.register-page.create_account' | transloco }}</h1>
                </div>
                <app-register-form
                    *ngIf="showRegistration"
                    [errorCode]="errorCode"
                    [hasBackButton]="true"
                    [cid]="copyNetworkId"
                    (submitClick)="register($event)"
                    (formDataChanged)="getFormData($event)"></app-register-form>

                <div class="flex-column--centered">
                    <div *ngIf="errorMsgRegister" class="login-error">
                        <label>{{ errorMsgRegister }}</label>
                    </div>
                    <div *ngIf="googleLoginError" class="login-error">
                        <label>{{ googleLoginError }}</label>
                    </div>
                    <div *ngIf="showRegistration" id="googleBtn" class="login-button-container-google-button"></div>
                    <div *ngIf="isMobile && showRegistration" class="login__button-container--multiple-buttons">
                        <button mat-flat-button class="single-row-button g-sign-in-button-mobile" (click)="signInGoogleCapacitor()">
                            <div class="content-container">
                                <img src="/assets/icons/g-logo.png" />
                                <span>{{ 'public.login-form.sign_in_google' | transloco }}</span>
                            </div>
                        </button>
                    </div>
                </div>

                <div *ngIf="showRegistration" class="hailer-help-form">
                    <p class="login-text">
                        <span> {{ 'public.need-help' | transloco }} </span>
                        <a
                            class="login-link"
                            [href]="
                                'https://form.hailer.com/eb945c01280f16d32b26b619161b8497/?language=' + getLanguage() +
                                '&62d7d5c71438bb0840e97b02=' +
                                this.email +
                                '&62d7d5681438bbb1f9e97879=' +
                                this.displayName
                            "
                            target="_blank"
                            referrerpolicy="no-referrer"
                            rel="nofollow help">
                            {{ 'public.contact-form' | transloco }}
                        </a>
                    </p>
                </div>

                <div *ngIf="!showRegistration" class="flex-column--centered">
                    <label data-cy="create-workspace-text" class="create-workspace-text">
                        {{ 'public.register-page.create_workspace' | transloco }}
                    </label>
                    <br />
                    <div class="login-button-container-two-buttons-column">
                        <button
                            data-cy="create-workspace-copy-button"
                            *ngIf="errorCode !== 501"
                            mat-flat-button
                            class="primary-public-pages"
                            (click)="createWorkspaceCopy()"
                            [disabled]="saving">
                            <span>{{ 'public.register-page.button_create_workspace' | transloco }}</span>
                        </button>
                        <button data-cy="back-to-hailer-button" mat-flat-button class="tertiary-highlight-public-pages" (click)="routeToDefaultView()" [disabled]="saving">
                            <span>{{ 'public.register-page.button_back' | transloco }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Validation email sent -->
            <div class="flex-column--centered" *ngIf="page === 'emailsent'">
                <mat-card-title>
                    {{ 'public.register-page.validation_sent' | transloco }}
                </mat-card-title>
                <p class="login-text">
                    {{ 'public.register-page.validation_sent_to' | transloco }}
                    <strong>{{ emailSentToAddress }}</strong>
                    <br />
                </p>

                <div class="flex-column--centered">
                    <div *ngIf="errorMsgRegister" class="login-error">
                        <label>{{ errorMsgRegister }}</label>
                    </div>
                    <div *ngIf="googleLoginError" class="login-error">
                        <label>{{ googleLoginError }}</label>
                    </div>
                    <div [hidden]="!showRegistration" id="googleBtn" class="login-button-container-google-button"></div>
                    <div *ngIf="isMobile && showRegistration" class="login__button-container--multiple-buttons">
                        <button mat-flat-button class="single-row-button g-sign-in-button-mobile" (click)="signInGoogleCapacitor()">
                            <div class="content-container">
                                <img src="/assets/icons/g-logo.png" />
                                <span>{{ 'public.login-form.sign_in_google' | transloco }}</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div *ngIf="!showRegistration" class="flex-column--centered">
                    <p class="login-text">
                        {{ 'public.register-page.create_workspace' | transloco }}
                    </p>
                    <div class="login-button-container-two-buttons-column">
                        <button
                            *ngIf="errorCode !== 501"
                            mat-flat-button
                            class="primary-public-pages"
                            (click)="createWorkspaceCopy()"
                            [disabled]="saving">
                            <span>{{ 'public.register-page.button_create_workspace' | transloco }}</span>
                        </button>
                        <button mat-flat-button class="tertiary-highlight-public-pages" (click)="routeToDefaultView()" [disabled]="saving">
                            <span>{{ 'public.register-page.button_back' | transloco }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
