/** @format */

import { Pipe, PipeTransform } from '@angular/core';

import { CoreService } from 'app/_services/core.service';
import { CompanyMap } from '@app/models';

@Pipe({
    name: 'workspaceIdsToWorkspaces',
})
export class WorkspaceIdsToWorkspaces implements PipeTransform {
    constructor(private core: CoreService) {}

    transform(workspaceIds: string[]): CompanyMap {
        const workspaces: CompanyMap = {};
        for (const workspaceId of workspaceIds) {
            const workspace = this.core.networks.value[workspaceId];
            if (!workspace) {
                continue;
            }

            workspaces[workspaceId] = workspace;
        }

        return workspaces;
    }
}
