/** @format */

export interface SignalMeta {
    modules: MetaModule[];
    notification_id?: string;
    uid?: string;
}

export enum MetaModule {
    Accounts = 'accounts',
    Calendars = 'calendars',
    Gui = 'gui',
    Network = 'network',
    Networks = 'networks',
    Processes = 'processes',
    Tags = 'tags',
    Teams = 'teams',
    User = 'user',
    Users = 'users',
    Groups = 'groups',
    Apps = 'apps',
}

export interface CacheInvalidateMeta {
    cid: string;
    caches: MetaModule[];
    value: {
        user?: string;
        users?: string;
        network?: string;
        networks?: string;
        processes?: string;
        teams?: string;
        tags?: string;
        accounts?: string;
        groups?: string;
        apps?: string;
    };
}
