/** @format */

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

import { RPCService } from 'app/_services/rpc.service';

@Injectable({ providedIn: 'root' })
export class ValidateEmailResolver implements Resolve<any> {
    constructor(private rpc: RPCService, private snackbar: MatSnackBar, private translocoService: TranslocoService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const observer = new Observable(subscriber => {
            const queryParams = state.root.queryParams;
            const token = queryParams?.token;
            const check = queryParams?.check;

            if (!token || !check) {
                console.warn(`Validation failed! ${!token ? 'Token' : 'Check'} was not provided!`);
                subscriber.next();
                subscriber.complete();
                return;
            }

            this.rpc.request('v2.user.verify_email', [token, check]).subscribe({
                next: val => {
                    if (!this.snackbar._openedSnackBarRef) {
                        this.snackbar.open(
                            this.translocoService.translate('misc.models.validate-email-resolver.snackbar.email_validated_successfully'),
                            this.translocoService.translate('misc.models.validate-email-resolver.snackbar.close'),
                            { duration: 3000, panelClass: ['email-verified-succeeded-snackbar'] }
                        );
                    }
                    subscriber.next(val);
                    subscriber.complete();
                },
                error: error => {
                    console.error('Error happened while validating email', error);
                    if (!this.snackbar._openedSnackBarRef) {
                        this.snackbar.open(
                            this.translocoService.translate('misc.models.validate-email-resolver.snackbar.failed_to_validate_email'),
                            this.translocoService.translate('misc.models.validate-email-resolver.snackbar.close'),
                            { duration: 3000, panelClass: ['email-verified-error-snackbar'] }
                        );
                    }
                    subscriber.next();
                    subscriber.complete();
                },
            });
        });
        return observer;
    }
}
