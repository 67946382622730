/** @format */

import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { FeedService } from '../feed.service';
import { CoreService } from 'app/_services/core.service';
import { FeedPost, PersonalSettings } from '@app/models';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { RoutingService } from 'app/_services/routing.service';
import { RPCService } from 'app/_services/rpc.service';
import { LinkingInfo } from '../../../../test/deps/hailer-api/shared/link-types';
import { TeamService } from 'app/_services/team.service';
import { PeopleService } from 'app/people/people.service';

@Component({
    selector: 'app-feed-post-options-button',
    templateUrl: './feed-post-options-button.component.html',
    styleUrls: ['./feed-post-options-button.component.scss'],
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'feed', alias: 'feed' } }],
})
export class FeedPostOptionsButtonComponent implements OnInit, OnDestroy {
    @Input() feedPost: FeedPost;
    @Output() openEdit = new EventEmitter<any>();
    @Output() postPinned = new EventEmitter<any>();

    followStatus = false;
    user: BehaviorSubject<PersonalSettings>;

    private onDestroy = new Subject<void>();

    constructor(
        public core: CoreService,
        public feed: FeedService,
        public team: TeamService,
        public people: PeopleService,
        private dialogHelperService: DialogHelperService,
        private snackBar: MatSnackBar,
        private translocoService: TranslocoService,
        private cdr: ChangeDetectorRef,
        private router: RoutingService,
        private rpc: RPCService,
    ) {}

    ngOnInit() {
        this.user = this.core.user;
        this.core.permission.changed
            .pipe(
                takeUntil(this.onDestroy),
                filter(workspaceIds => workspaceIds.includes(this.feedPost.cid))
            )
            .subscribe(() => this.cdr.detectChanges());

        this.user.pipe(takeUntil(this.onDestroy)).subscribe(me => {
            if (!me) {
                return false;
            }
            return (this.followStatus = this.feedPost.followers.includes(me.id));
        });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    async copyPostURL(): Promise<void> {
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        try {
            await navigator.clipboard.writeText(`${baseUrl}/#/feed/${this.feedPost._id}`);
            this.snackBar.open(
                this.translocoService.translateObject('feed.ts_feed_post_url_copied'),
                this.translocoService.translateObject('feed.ts_close'),
                { duration: 2500 }
            );
        } catch (error) {
            console.error('Failed to copy feed post url', error);
        }
    }

    pinPost() {
        this.postPinned.emit();
    }

    async starPost() {
        this.feedPost.isStarred = !this.feedPost.isStarred; 
        await this.rpc.requestAsync('v3.feed.post.toggle.star', [this.feedPost._id]);
    }

    async removePost(): Promise<void> {
        const confirm = this.translocoService.translateObject('feed.remove_post.confirm');
        const content = this.translocoService.translateObject('feed.remove_post.content');
        const title = this.translocoService.translateObject('feed.remove_post.title');

        const confirmed = await this.dialogHelperService.showConfirmAsync(confirm, content, title)
        if (!confirmed) {
            return;
        }

        try {
            await this.rpc.requestAsync('wall2.remove_post', [this.feedPost._id]);
            this.feed.onDeletedPost({ post_id: this.feedPost._id });
        } catch (error) {
            console.error('Failed to remove feed post', error);
        }
    }

    replyPrivately(): void {
        let header = this.feedPost.initMessage?.subject;
        let content = this.feedPost.initMessage?.text;
        if (this.feedPost.linked_activity) {
            header =
                this.feedPost.initMessage?.meta?.process?.translations?.[this.translocoService.getActiveLang()]?.initPhaseDescription ||
                this.feedPost.initMessage?.meta?.process?.initPhaseDescription ||
                this.translocoService.translateObject('feed.activity_fallback_subject');

            content = this.feedPost.initMessage?.meta?.activity?.name;
        }

        const linkingInfo: LinkingInfo = {
            link: {
                type: 'linked-to',
                targetType: 'feedPost',
                target: this.feedPost._id,
            },
            data: {
                header,
                content,
            },
            linkedData: this.feedPost.linkedData,
        };

        localStorage.setItem(`linkingInfo/${this.feedPost.uid}`, JSON.stringify(linkingInfo));

        void this.router.navigate(['ui', 'user', 'open', this.feedPost.uid, 'discussions']);
    }

    async toggleHiddenState(): Promise<void> {
        const hiddenFeedPosts = new Set(this.user.value.globalSettings?.hiddenFeedPosts);

        if (this.postHidden) {
            hiddenFeedPosts.delete(this.feedPost._id);
        } else {
            hiddenFeedPosts.add(this.feedPost._id);
        }

        try {
            await this.rpc.requestAsync('v2.user.settings.global.set', [{ hiddenFeedPosts: Array.from(hiddenFeedPosts) }]);
        } catch (error) {
            console.error('Failed to toggle hidden state of feed post', error);
        }

        this.feed.reload.next({ clearPosts: true });
    }

    get postHidden(): boolean {
        return !!this.user.value.globalSettings?.hiddenFeedPosts?.includes(this.feedPost._id);
    }
}
