/**
 * Eslint-disable dot-notation
 *
 * @format
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, debounceTime, takeUntil } from 'rxjs';

import { CoreService } from 'app/_services/core.service';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    selector: 'app-development-page',
    templateUrl: './development-page.component.html',
    styleUrls: ['./development-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevelopmentPageComponent implements OnInit, OnDestroy {
    users: string[];
    filteredUsers: string[];
    userFilter = new FormControl<string>('');

    private onDestroy = new Subject();

    constructor(public core: CoreService, public router: Router, public rpc: RPCService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (!window.core) {
            window.core = this.core;
        }
        if (!window.rpc) {
            window.rpc = this.rpc;
        }

        this.userFilter.valueChanges.pipe(takeUntil(this.onDestroy), debounceTime(100)).subscribe({
            next: filter => {
                this.filteredUsers = this.users.filter(user => !!RegExp(filter.toLocaleLowerCase()).exec(user.toLocaleLowerCase()));
                this.cdr.detectChanges();
            },
        });

        this.start();
    }

    ngOnDestroy(): void {
        this.onDestroy.complete();
    }

    login(user: string) {
        this.core.login(user, 'thispasswordshouldbestrongenough').subscribe({
            next: () => this.router.navigate(['/activities']),
        });
    }

    private async start() {
        this.users = (await this.rpc.requestAsync('core.users', [])) as string[];
        this.cdr.markForCheck();
    }
}
