/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Subject, debounceTime, take, takeUntil } from 'rxjs';

import { CoreService } from 'app/_services/core.service';
import { RPCService } from 'app/_services/rpc.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { FieldDependency, InsightDoc, InsightService, InsightSource, Source } from '../insight.service';
import { V3ActivitySidenavComponent } from 'app/v3-activity/v3-activity-sidenav/v3-activity-sidenav.component';
import { Insight, InsightData, InsightOptions } from 'assets/insight/insight';
import { UserService } from 'app/_services/user.service';
import { ThemeService } from 'app/theme/theme.service';

/**
 * Camelizes strings allowing diacritics, and appends underscore if result starts with a number
 *
 * Example:
 *
 *     Field name (fi: kenttänimi)
 *
 * will become
 *
 *     fieldNameFiKenttänimi
 *
 * Example 2:
 *
 *     7. Workflow name (fi: Työnkulku)
 *
 * will become
 *
 *     _7WorkflowNameFiTyönkulku
 */
const camelize = (input: string): string => {
    if (!input) {
        return input;
    }

    // Drop all but letterns and numbers and underscore
    input = input.replace(/([^A-ZÀ-Ö0-9_])/gi, ' ');

    input = input.trim();

    input = input
        .split(/\s+/)
        .map((s, index) => {
            const firstChar = index === 0 ? s.charAt(0).toLocaleLowerCase() : s.charAt(0).toLocaleUpperCase();
            return firstChar + s.slice(1);
        })
        .join('');

    if (input.charCodeAt(0) >= 48 && input.charCodeAt(0) <= 57) {
        // Prepend underscore if first character is a number
        input = `_${input}`;
    }

    return input;
};

@Component({
    selector: 'app-insight-editor',
    templateUrl: './insight-editor.component.html',
    styleUrls: ['./insight-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightEditorComponent implements OnInit, OnDestroy {
    /** Insight to be edited, or null to create a new */
    @Input() insight: InsightDoc | null;

    insightLib: Insight | undefined;

    insightForm = new FormGroup({
        name: new FormControl('', Validators.required),
        public: new FormControl(false, Validators.required),
        sources: new FormControl<InsightSource[]>([], [this.isValidName.bind(this)]),
        query: new FormControl('SELECT * FROM ... -- SQLite3 query'),
    });

    fieldNames: string[] = [];
    availableSources: Source[] = [];
    filteredSources: Source[] = [];
    available: {
        [workflowId: string]: {
            workflowId: string;
            name: string;
            fields: {
                [fieldId: string]: {
                    fieldId: string;
                    name: string;
                };
            };
        };
    } = {};

    metaFields = [
        { meta: '_id', name: 'Activity Id' },
        { meta: 'name', name: 'Activity Name' },
        { meta: 'createdBy', name: 'Created by User Id' },
        { meta: 'created', name: 'Created Time' },
        { meta: 'updated', name: 'Updated Time' },
        { meta: 'priority', name: 'Priority' },
        { meta: 'phaseId', name: 'Phase Id' },
        { meta: 'phaseName', name: 'Phase Name' },
        { meta: 'workflowId', name: 'Workflow Id' },
        { meta: 'workflowName', name: 'Workflow Name' },
    ];

    selectedSources = new BehaviorSubject<Source[]>([]);

    sourceFilter = new FormControl<string>('');

    previewData: InsightData | null;
    previewError: string | null;
    showAddSourcesPopup = false;

    editorOptions = {
        theme: 'vs-light',
        language: 'sql',
        wordWrap: 'on',
        fontSize: '14px',
        fontFamily: 'monospace',
        scrollBeyondLastLine: false,
        minimap: {
            enabled: false,
        },
        inlayHints: {
            enabled: true,
        },
        renderWhitespace: 'none',
    };

    themes = {
        light: 'vs-light',
        dark: 'vs-dark',
    };

    darkTheme = false;

    private onDestroy = new Subject();

    constructor(
        public sideNav: SideNavService,
        public translate: TranslocoService,
        public service: InsightService,
        public snackbar: MatSnackBar,
        public transloco: TranslocoService,
        public theme: ThemeService,
        private cdr: ChangeDetectorRef,
        private core: CoreService,
        private rpc: RPCService,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) @Optional() public data?: InsightDoc,
        @Optional() private dialogRef?: MatDialogRef<InsightEditorComponent>
    ) {}

    ngOnDestroy(): void {
        this.onDestroy.complete();
    }

    ngOnInit(): void {
        if (this.data) {
            console.log('Opened in dialog with:', this.data);
            this.insight = this.data;
        }
        if (this.insight) {
            this.insightForm.setValue({
                name: this.insight.name,
                public: !!this.insight.public,
                sources: this.insight.sources,
                query: this.insight.query,
            });

            this.selectedSources.next(this.insight.sources || []);
            void this.updatePreview();
        }

        this.insightForm.valueChanges.subscribe({
            next: () => {
                void this.updatePreview();
            },
        });

        const processes = Object.values(this.core.processes.value[this.core.network.value._id] || {});

        const getFields = (workflowId: string): FieldDependency[] => {
            const workflow = this.core.processById(workflowId);

            if (!workflow) {
                this.error('Workflow not found when getting fields');
                return [];
            }

            const workflowFields: FieldDependency[] =
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                Object.values(workflow.fields || {})
                    .map(({ _id, label }) => ({ fieldId: _id, name: label }))
                    .sort((a, b) => a.name.localeCompare(b.name)) || [];

            const allFields = workflowFields.concat(
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                Object.values(this.core.processById(workflowId)?.phases || {})
                    .map(({ _id, name }) => ({ name: `Moved to: ${name}`, phaseId: _id, meta: 'phaseLastMove' }))
                    .sort((a, b) => a.name.localeCompare(b.name)) || []
            );

            return allFields;
        };

        this.availableSources = processes
            .map(({ _id: workflowId, name }) => ({
                workflowId,
                name,
                fields: getFields(workflowId),
            }))
            .sort((a, b) => a.name.localeCompare(b.name));

        this.available = {};

        processes.forEach(({ _id: workflowId, name }) => {
            const workflowMap = this.core.processes.value?.[this.core.network.value._id];
            const workflowFields = Object.values(workflowMap?.[workflowId]?.fields || {}) || [];
            const fields = workflowFields.map(({ _id, label }) => ({ fieldId: _id, name: label }));
            const fieldMap = fields.reduce((map, field) => {
                map[field.fieldId] = field;
                return map;
            }, {});

            this.available[workflowId] = {
                workflowId,
                name,
                fields: fieldMap,
            };
        });

        this.sourceFilter.valueChanges.pipe(takeUntil(this.onDestroy), debounceTime(200)).subscribe({
            next: filter => {
                this.filteredSources = this.availableSources.filter(source =>
                    RegExp(filter?.toLowerCase() || '').exec(source.name.toLowerCase())
                );
                this.cdr.detectChanges();
            },
        });

        this.core.user.pipe(take(1)).subscribe({
            next: data => {
                if (data?.globalSettings && data.globalSettings.theme) {
                    const theme = this.themes[data.globalSettings.theme] || 'vs-light';
                    this.editorOptions = { ...this.editorOptions, theme };
                    this.darkTheme = theme === 'vs-dark';
                    this.cdr.detectChanges();
                }
            },
        });

        this.cdr.detectChanges();
    }

    close(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        } else {
            this.sideNav.clear();
        }
    }

    async updatePreview(): Promise<void> {
        const args = this.insightForm.value;

        const preview: InsightData = await this.rpc
            .requestAsync('v3.insight.preview', [this.core.network.value?._id, { sources: args.sources, query: args.query }])
            .catch(error => {
                const previewError = error.msg.split('SQLITE_ERROR: ')[1] || error.msg;
                // This.error(error.msg.split('SQLITE_ERROR: ')[1] || error);
                this.previewError = previewError;
                this.previewData = null;
                this.cdr.detectChanges();
            });

        if (!preview) {
            return;
        }

        this.previewData = preview;
        this.previewError = null;


        this.cdr.detectChanges();

        try {
            const storageKey = `insight-editor-config-${this.insight?._id}`;

            let options: Partial<InsightOptions> = {};

            try {
                options = JSON.parse(localStorage.getItem(storageKey) || '{}');
            } catch (error) {
                // Ignore failure to read options
            }

            options.show = { topbar: false, chart: false, pivot: false, table: true };

            if (this.theme.getActiveTheme().name === 'light') {
                options.theme = 'light';
            } else {
                options.theme = 'dark';
            }

            this.insightLib?.destroy();

            if (this.previewData === null) {
                return;
            }

            this.insightLib = new Insight('insight-preview', this.previewData, {
                ...options,
                event: {
                    rowSelected: row => {
                        const activityIdIndex = this.previewData?.headers.findIndex(header => header === 'activityId') ?? -1;

                        if (activityIdIndex !== -1) {
                            const activityId = row[activityIdIndex];

                            if (typeof activityId !== 'string') {
                                return;
                            }

                            if (activityId.length !== 24) {
                                return;
                            }

                            this.sideNav.create(V3ActivitySidenavComponent, { activityId });
                        }
                    },
                    change: options => {
                        localStorage.setItem(storageKey, JSON.stringify(options));
                    },
                },
            });
        } catch (error) {
            console.log('This did not work out...', error);
        }
    }

    workflowName(workflowId: string): string {
        return this.core.processById(workflowId)?.name || 'n/a';
    }

    addWorkflow(workflowSource: Source): void {
        if (this.selectedSources.value.find(({ workflowId: _id }) => workflowSource.workflowId === _id)) {
            return;
        }

        /*
        Const fields = Object.values(
            this.core.processes.value?.[this.core.network.value._id]?.[workflowSource.workflowId].fields || {}
        ).map(({ _id, label }) => ({ fieldId: _id, name: camelize(label) })) || [];
        */

        this.selectedSources.value.push({ ...workflowSource, name: camelize(workflowSource.name), fields: [] });

        this.selectedSources.next(this.selectedSources.value);
        this.insightForm.controls.sources.setValue(this.selectedSources.value, { emitEvent: false });
    }

    removeWorkflow(workflow: Source): void {
        this.selectedSources.next(this.selectedSources.value.filter(({ workflowId: _id }) => _id !== workflow.workflowId));
        this.insightForm.controls.sources.setValue(this.selectedSources.value, { emitEvent: false });
        void this.updatePreview();
    }

    sourceSelected(workflow: Source): boolean {
        return !!this.selectedSources.value.find(source => source.workflowId === workflow.workflowId);
    }

    setWorkflowVariable({ workflowId: _id }: Source, value: string): void {
        const formData = this.selectedSources.value.find(workflow => workflow.workflowId === _id);

        if (!formData) {
            return;
        }

        formData.name = value;

        this.insightForm.controls.sources.setValue(this.selectedSources.value, { emitEvent: false });
    }

    /** Add a field dependency: field, static meta field or phaseLastMove */
    addField(workflowId: string, field: FieldDependency): void {
        let fieldToAdd: FieldDependency | null = null;

        const selectedFields = this.insightForm.controls.sources.value?.find(source => source.workflowId === workflowId)?.fields || [];

        if (field.phaseId) {
            const phase = this.core.processes.value?.[this.core.network.value._id]?.[workflowId]?.phases[field.phaseId];

            if (!phase) {
                this.error('Phase not found');
                return;
            }

            const fieldFound = selectedFields.find(selectedField => selectedField.phaseId && selectedField.phaseId === fieldToAdd?.phaseId);

            if (fieldFound) {
                // Do not add multiple times
                this.error(`Phase move already present with name: ${fieldFound.name}`);
                return;
            }

            fieldToAdd = { name: camelize(field.name), meta: field.meta, phaseId: field.phaseId };
        }

        if (field.fieldId) {
            const workflowField = this.core.processes.value?.[this.core.network.value._id]?.[workflowId]?.fields[field.fieldId || ''];

            if (!workflowField) {
                return;
            }

            const fieldFound = selectedFields.find(
                selectedField =>
                    selectedField.name === fieldToAdd?.name || (selectedField.fieldId && selectedField.fieldId === fieldToAdd?.fieldId)
            );

            if (fieldFound) {
                // Do not add multiple times
                this.error(`Field already present with name: ${fieldFound.name}`);
                return;
            }

            fieldToAdd = { name: camelize(workflowField?.label), fieldId: field.fieldId };
        }

        if (field.meta && field.meta !== 'phaseLastMove') {
            const fieldFound = selectedFields.find(selectedField => selectedField.meta && selectedField.meta === fieldToAdd?.meta);

            if (fieldFound) {
                // Do not add multiple times
                this.error(`Field already present with name: ${fieldFound.name}`);
                return;
            }

            fieldToAdd = { name: camelize(field.name), meta: field.meta };
        }

        if (!fieldToAdd) {
            this.error('No field to add');
            return;
        }

        selectedFields.push(fieldToAdd);

        void this.updatePreview();
        this.insightForm.markAsDirty();
    }

    removeField(workflowId: string, field: FieldDependency): void {
        const selectedFields = this.insightForm.controls.sources.value?.find(source => source.workflowId === workflowId)?.fields;

        if (!selectedFields) {
            return;
        }

        const indexOfSelectedField = selectedFields.findIndex(selectedField => this.areFieldsEqual(selectedField, field));

        if (indexOfSelectedField !== -1) {
            selectedFields.splice(indexOfSelectedField, 1);
        }

        // Update the form control
        const updatedSources = this.insightForm.controls.sources.value?.map(source => {
            if (source.workflowId === workflowId) {
                return { ...source, fields: selectedFields };
            }
            return source;
        });

        this.insightForm.patchValue({ sources: updatedSources });
        this.insightForm.markAsDirty();
        void this.updatePreview();
    }

    toggleField(workflowId: string, field: FieldDependency): void {
        const selectedFields = this.insightForm.controls.sources.value?.find(source => source.workflowId === workflowId)?.fields;

        if (!selectedFields) {
            return;
        }

        const isFieldSelected = this.isFieldSelected(workflowId, field);

        if (isFieldSelected) {
            this.removeField(workflowId, field);
        } else {
            this.addField(workflowId, field);
        }

        void this.updatePreview();
        this.insightForm.markAsDirty();
    }

    isFieldSelected(workflowId: string, field: FieldDependency): boolean {
        const selectedFields = this.insightForm.controls.sources.value?.find(source => source.workflowId === workflowId)?.fields;

        if (!selectedFields) {
            return false;
        }

        return selectedFields.some(selectedField => this.areFieldsEqual(selectedField, field));
    }

    areFieldsEqual(field1: FieldDependency, field2: FieldDependency): boolean {
        return field1.fieldId === field2.fieldId && field1.phaseId === field2.phaseId && field1.meta === field2.meta;
    }

    hasWorkflowFields(fields: FieldDependency[]): boolean {
        return fields.some(field => !field.phaseId && field.fieldId);
    }

    hasPhaseMovedFields(fields: FieldDependency[]): boolean {
        return fields.some(field => !!field.phaseId);
    }

    getWorkflowFields(fields: FieldDependency[]): FieldDependency[] {
        return fields.filter(field => !field.phaseId && field.fieldId);
    }

    getPhaseMovedFields(fields: FieldDependency[]): FieldDependency[] {
        return fields.filter(field => !!field.phaseId);
    }

    isBadDependency(name: string): boolean {
        if (typeof name !== 'string') {
            return true;
        }

        const regex = '^([a-zà-öA-ZÀ-Ö_$][a-zà-öA-ZÀ-Ö0-9_$]*)$';

        if (RegExp(regex).exec(name)) {
            return false;
        }
        return true;
    }

    isValidName(control: AbstractControl): ValidationErrors | null {
        const sources: InsightSource[] = control.value || [];

        if (sources.length === 0) {
            return { noSources: true };
        }

        for (const source of sources) {
            if (this.isBadDependency(source.name)) {
                return { invalidSourceName: true };
            }
        }

        return null;
    }

    async save(insight: typeof this.insightForm.value): Promise<void> {
        if (this.insightForm.pristine) {
            this.close();
            return;
        }

        if (this.insight) {
            // Update existing Insight
            try {
                await this.rpc.requestAsync('v3.insight.update', [this.insight._id, insight]);
            } catch (error) {
                this.error(error.msg);
                return;
            }
        } else {
            // Create new Insight
            try {
                await this.rpc.requestAsync('v3.insight.create', [this.core.network?.value?._id, insight]);
            } catch (error) {
                this.error(error.msg);
                return;
            }
        }

        this.close();
    }

    error(message: string): void {
        this.snackbar.open(message, '×', {
            duration: 2000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
        });
    }

    openAddSourcesPopup(): void {
        this.showAddSourcesPopup = true;
    }

    closeAddSourcesPopup(): void {
        this.showAddSourcesPopup = false;
        this.clearSearch();
    }

    // Monaco Editor theme setting
    setTheme(theme: 'vs-dark' | 'vs-light'): void {
        this.darkTheme = theme === 'vs-dark';
        this.userService.setFunctionEditorTheme(theme);
    }

    clearSearch(): void {
        this.sourceFilter.setValue('');
    }

    trackByMeta(index: number, field: { meta: string, name: string }): string {
        return field.meta;
    }

    trackByFieldId(index: number, field: FieldDependency | undefined): string | undefined {
        return field?.fieldId;
    }

    trackByPhaseId(index: number, field: FieldDependency | undefined): string | undefined {
        return field?.phaseId;
    }
}
