/** @format */

import { Pipe, PipeTransform } from '@angular/core';

import { PeopleService } from 'app/people/people.service';
import { User } from '@app/models';

@Pipe({
    name: 'uidSorter',
})
export class UidSorterPipe implements PipeTransform {
    constructor(private peopleService: PeopleService) {}

    transform(value: any, args?: any): User[] {
        let out: User[] = [];

        if (Array.isArray(value)) {
            value.map(uid => {
                const u = this.peopleService.getUser(uid);
                if (u && u._id) {
                    out.push(u);
                }
            });

            if (args) {
                if (args.search) {
                    out = out.filter(u => this.generateSearchData(u, args.search));
                }
            }

            out.sort((u1, u2) => u1.display_name.localeCompare(u2.display_name));
        }

        return out;
    }

    private generateSearchData(user: User, search: string): boolean {
        const text: string[] = [];

        if (user.display_name) {
            if (user.display_name.toLowerCase().includes(search.toLowerCase())) {
                text.push(user.firstname);
            }
        } else {
            if (user.firstname.toLowerCase().includes(search.toLowerCase())) {
                text.push(user.firstname);
            }

            if (user.lastname.toLowerCase().includes(search.toLowerCase())) {
                text.push(user.lastname);
            }
        }

        return text.length > 0;
    }
}
