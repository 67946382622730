/** @format */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { FileUploaderComponent } from '../../shared/file-uploader/file-uploader.component';
import { FeedService } from '../feed.service';
import { Company, FeedPost } from '@app/models';
import { CoreService } from 'app/_services/core.service';
import { TeamUserSelectorOptions } from 'app/_models/teamUserSelectorOptions.model';
import { DropdownItems, DropdownOptions, DropdownSelectedItems } from 'app/_models/dropdown-selector.model';
import { RPCService } from 'app/_services/rpc.service';
import { PollCreateOptions, PollDoc } from '../../../../test/deps/hailer-api/shared/poll-types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { stripUndefined } from '../../../../test/deps/hailer-api/shared/util';
import { LinkPollData, NewLink } from '../../../../test/deps/hailer-api/shared/link-types';
import { FeedPostCreateOptions } from '../../../../test/deps/hailer-api/shared/feed-types';
import { TranslateService } from 'app/_services/translate.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MarkdownPipe } from 'app/pipes/src/markdown.pipe';
import { MatDialog } from '@angular/material/dialog';
import { FeedPostScheduleDialogComponent } from '../feed-post-schedule-dialog/feed-post-schedule-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TextEditorComponent } from 'app/text-editor/editor/text-editor.component';
import { WindowListenerService } from 'app/_services/window-listener.service';
import { FeedPostVisibilityDialogComponent } from 'app/_dialogs/feed-post-visibility-dialog/feed-post-visibility-dialog.component';
import { FeedStaticService } from '../feed-static.service';

@Component({
    selector: 'app-feed-post-editor',
    templateUrl: './feed-post-editor.component.html',
    styleUrls: ['./feed-post-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: ['feed', 'poll', 'buttons'] }],
})
export class FeedPostEditorComponent implements OnInit, OnDestroy {
    /** Post to be edited. Leave undefined to create a new one */
    @Input() post?: FeedPost;

    @ViewChild('uploader', { static: true }) uploader: FileUploaderComponent;
    @ViewChild('subject', { static: true }) subject: ElementRef;
    @ViewChild(TextEditorComponent, { static: false }) editor: TextEditorComponent | undefined;

    selectedWorkspaceId = this.core.network.value?._id;

    saving = false;
    dragging = false;
    setPoll = false;
    pollValid = false;

    focusState = new BehaviorSubject<boolean>(false);

    insertedMentions: DOMStringMap[] = [];
    draft = new FormControl<boolean>(false);

    postForm = new FormGroup({
        subject: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
        text: new FormControl<string>('', { validators: [Validators.required] }),
        files: new FormControl<string[] | undefined>(undefined, { nonNullable: true }),
        allowReply: new FormControl<boolean>(true, { nonNullable: true }),
        draft: new FormControl<boolean | undefined>(undefined, { nonNullable: true }),
        scheduled: new FormControl<boolean | undefined>(undefined, { nonNullable: true }),
        scheduleTime: new FormControl<number | null | undefined>(undefined, { nonNullable: true }),
        recipients: new FormControl<string[] | undefined | null>(undefined, { nonNullable: true }),
    });

    translationsLoaded = false;
    screenWidth = window.innerWidth;

    recipientNames: string[] = [];

    private uploadInProgress = false;
    private pollToSet?: PollCreateOptions;
    private onDestroy = new Subject<void>();

    constructor(
        private feed: FeedService,
        private feedStatic: FeedStaticService,
        private cdr: ChangeDetectorRef,
        private core: CoreService,
        private rpc: RPCService,
        private translate: TranslateService,
        private transloco: TranslocoService,
        private sanitizer: DomSanitizer,
        private matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private windowListener: WindowListenerService
    ) {}

    @HostListener('dragover', ['$event'])
    onDragOver(event) {
        if (this.post) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = 'copy';
        this.dragging = true;
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(event) {
        if (this.post) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        this.dragging = false;
    }

    @HostListener('drop', ['$event'])
    onDrop(event) {
        if (this.post) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer.files;
        for (let i = 0; i < files.length; i++) {
            void this.uploader.uploadFile(files[i]);
        }
        this.dragging = false;
    }

    ngOnInit() {
        void this.waitForTranslations();

        if (this.post && !!this.post.draft) {
            this.draft.setValue(true);
        }

        void this.initFormData();
        if (!this.post) {
            this.feed.focusCreatePost.pipe(takeUntil(this.onDestroy)).subscribe({
                next: () => this.subject.nativeElement.focus(),
            });
        }

        this.uploader.fileIds.pipe(takeUntil(this.onDestroy)).subscribe({
            next: fileIds => {
                this.postForm.patchValue({
                    ...this.postForm.value,
                    files: fileIds?.length ? fileIds : undefined,
                });
            },
        });

        this.draft.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe({
            next: value => {
                if (!value) {
                    this.postForm.controls.draft.reset();
                    this.cdr.detectChanges();
                }
            },
        });

        this.uploader.uploadInProgress.pipe(takeUntil(this.onDestroy)).subscribe({
            next: status => {
                const valueChanged = this.uploadInProgress !== status;
                if (!valueChanged) {
                    return;
                }

                this.uploadInProgress = status;
                this.cdr.detectChanges();
            },
        });

        this.windowListener.size.pipe(takeUntil(this.onDestroy)).subscribe({
            next: ({ x }) => {
                this.screenWidth = x;
                this.cdr.detectChanges();
            },
        });

        this.setRecipientNames(this.post?.recipients || []);

        if (!this.post) {
            this.feedStatic.focusCreate.pipe(takeUntil(this.onDestroy)).subscribe({
                next: focus => {
                    if (!focus) {
                        return;
                    }

                    this.subject.nativeElement.focus();
                },
            });
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    async waitForTranslations(): Promise<void> {
        await this.translate.translationLoaded('feed');
        await this.translate.translationLoaded('poll');
        await this.translate.translationLoaded('buttons');
        this.translationsLoaded = true;
        this.cdr.detectChanges();
    }

    allowReply(event: MatSlideToggleChange) {
        this.postForm.controls.allowReply.setValue(event.checked);
    }

    /** Sets form data based on the provided post */
    async initFormData(): Promise<void> {
        this.postForm.reset();

        const markdownPipe = new MarkdownPipe(this.sanitizer, this.core, this.transloco, this.translate);
        const safeHtml = (await markdownPipe.transform(this.post?.initMessage?.text || '', this.post?.linkedData, true)) as any;
        this.postForm.patchValue({
            subject: this.post?.initMessage?.subject,
            text: safeHtml.changingThisBreaksApplicationSecurity,
            files: this.post?.files,
            allowReply: this.post ? this.post?.allowReply : true,
            recipients: this.post?.recipients,
            scheduled: this.post?.scheduled,
            scheduleTime: this.post?.scheduled ? this.post.created : undefined,
        });

        this.setPoll = !!this.poll;
    }

    pollChanged(poll: PollCreateOptions): void {
        this.pollToSet = poll;
    }

    pollValidityChanged(valid: boolean): void {
        this.pollValid = valid;
        this.cdr.detectChanges();
    }

    cancel(): void {
        this.reset();
        this.feed.editingPost.next(undefined);
    }

    toggleSetPoll(): void {
        this.setPoll = !this.setPoll;
    }

    get poll(): LinkPollData | undefined {
        return this.post?.linkedData?.find(({ targetType }) => targetType === 'poll')?.data;
    }

    get oneThousandYears(): number {
        const minute = 60000;
        const hour = 60 * minute;
        const day = 24 * hour;
        const year = 365 * day;
        const oneThousandYears = 1000 * year;
        return oneThousandYears;
    }

    get saveButtonTranslation(): string {
        if (this.postForm.value.scheduled) {
            return 'feed.feed_create.schedule';
        }

        if (this.post && !this.post?.scheduled) {
            return 'feed.feed_edit.save';
        }

        return 'feed.feed_create.post';
    }

    openDateTimePickerDialog(): void {
        const scheduleTime =
            this.postForm.value.scheduleTime || this.postForm.value.scheduleTime === null
                ? this.postForm.value.scheduleTime
                : this.post?.created;

        this.matDialog
            .open(FeedPostScheduleDialogComponent, {
                width: '450px',
                data: { value: scheduleTime },
            })
            .afterClosed()
            .subscribe((result: number | null | undefined) => {
                if (result === undefined) {
                    // Dialog was closed without making any changes
                    return;
                }

                this.postForm.patchValue({
                    ...this.postForm.value,
                    scheduled: !!result,
                    scheduleTime: result,
                });
            });
    }

    openVisibilityDialog(): void {
        this.matDialog
            .open(FeedPostVisibilityDialogComponent, {
                width: '450px',
                data: {
                    recipients: this.postForm.value.recipients || this.post?.recipients || [],
                    workspaceId: this.workspaceId,
                    post: this.post,
                },
            })
            .afterClosed()
            .subscribe({
                next: (result: { recipients: string[], workspaceId?: string }) => {
                    if (!result) {
                        return;
                    }

                    if (result.workspaceId) {
                        this.selectedWorkspaceId = result.workspaceId;
                    }

                    this.postForm.patchValue({
                        ...this.postForm.value,
                        recipients: result.recipients,
                    });

                    this.setRecipientNames(result.recipients);
                    this.cdr.detectChanges();
                },
            });
    }

    async setPost(options?: { draft?: boolean }): Promise<void> {
        if (this.saveButtonDisabled) {
            return;
        }

        try {
            this.saving = true;

            const poll = await this.configurePoll();
            let links: NewLink[] = [];
            if (!this.poll && !!poll) {
                // New poll was created so add a link
                links.push({ target: poll._id, targetType: 'poll', type: 'linked-to' });
            }

            this.postForm.controls.draft.setValue(options?.draft !== undefined ? !!options?.draft : this.post?.draft);

            if (this.editor) {
                const [modifiedText, textLinks] = this.editor.modifiedTextAndLinks;
                links = links.concat(textLinks);
                this.postForm.controls.text.setValue(modifiedText);
            }

            for (const control of Object.keys(this.postForm.controls)) {
                if (this.postForm.controls[control].value === null) {
                    this.postForm.controls[control].setValue(undefined);
                }
            }

            if (this.post?.initMessage?.files?.length && this.postForm.value.files?.length) {
                // Combine already saved files to the new ones
                const files = Array.from(new Set([...this.post.initMessage.files, ...this.postForm.value.files]));
                this.postForm.controls.files.setValue(files);
            }

            let savedPost: FeedPost | undefined;
            if (!this.post) {
                savedPost = await this.rpc.requestAsync('v3.feed.post.create', [
                    this.workspaceId,
                    stripUndefined(this.postForm.value),
                    stripUndefined({
                        links: links.length ? links : undefined,
                    } as FeedPostCreateOptions),
                ]);
            } else {
                const postMap = await this.rpc.requestAsync('v3.feed.post.edit', [
                    {
                        [this.post._id]: stripUndefined({
                            ...stripUndefined(this.postForm.value),
                            links: links.length ? links : undefined,
                        }),
                    },
                ]);
                savedPost = postMap[this.post._id];
            }

            if (!savedPost) {
                console.error('Saved post is undefined');
                return;
            }

            if (this.postForm.value.scheduled) {
                await this.translate.translationLoaded('feed');
                this.snackBar.open(this.transloco.translate('feed.scheduled-snackbar'), 'OK', {
                    duration: 2000,
                });
            }

            this.feed.updatePostItems([savedPost]);
            this.feed.editingPost.next(undefined);
            // Reset fields and uploader on success
            this.reset();
        } catch (error) {
            console.error('Failed to set post', error);
        }
    }

    async configurePoll(): Promise<PollDoc | undefined> {
        const { scheduleTime } = this.postForm.value;
        const scheduleTimeChanged = scheduleTime === this.post?.created;
        if (!this.pollToSet && !scheduleTimeChanged || (this.poll && this.post && !this.post.draft && !this.post.scheduled)) {
            return;
        }

        const scheduleOptions: { scheduled: boolean; scheduleTime?: number } | undefined = scheduleTime
            ? stripUndefined({ scheduled: !!scheduleTime, scheduleTime: scheduleTime || undefined })
            : undefined;

        try {
            if (this.poll) {
                // Edit poll
                return await this.rpc.requestAsync('poll.edit', [
                    this.poll._id,
                    {
                        ...(this.pollToSet ? stripUndefined(this.pollToSet) : undefined),
                        ...scheduleOptions,
                    },
                ]);
            }

            if (!this.setPoll) {
                return;
            }

            return await this.rpc.requestAsync('poll.create', [{ ...this.pollToSet, ...scheduleOptions }]);
        } catch (error) {
            console.error('Failed to poll to feed post', error);
        }
    }

    reset(): void {
        void this.initFormData();
        this.insertedMentions = [];
        this.saving = false;
        this.focusState.next(false);
        this.uploader.reset();
        this.selectedWorkspaceId = undefined;
        this.setPoll = false;
        this.pollToSet = undefined;
        this.postForm.markAsUntouched();
        this.postForm.markAsPristine();
        this.cdr.detectChanges();
    }

    onFocus() {
        this.focusState.next(true);
    }

    onBlur() {
        this.focusState.next(false);
    }

    get saveButtonDisabled(): boolean {
        if (this.uploadInProgress) {
            return true;
        }

        if (this.saving) {
            return true;
        }

        if (this.setPoll && !this.pollValid) {
            return true;
        }

        if (!this.postForm.valid) {
            return true;
        }

        if (!this.postForm.value.subject?.trim().length || !this.postForm.value.text?.trim().length) {
            return true;
        }

        return false;
    }

    isFocused(): boolean {
        if (!this.postForm.value.text && !this.postForm.value.subject) {
            this.postForm.controls.subject.markAsUntouched();
        }

        const isFocused =
            !!this.post ||
            this.focusState.value ||
            !!this.postForm.value.subject?.length ||
            !!this.postForm.value.text?.length ||
            !!this.uploader.fileIds.value?.length;

        if (!this.post && !isFocused && this.feed.editingPost.value === 'create') {
            this.feed.editingPost.next(undefined);
        }

        if (!this.post && isFocused && !this.feed.editingPost.value) {
            this.feed.editingPost.next('create');
        }

        return isFocused;
    }

    recipientChange(selectedItems: DropdownSelectedItems): void {
        let recipients: string[] = [];
        if (!selectedItems) {
            return;
        }

        const allowedKeys = new Set(['user', 'team', 'group']);
        for (const key in selectedItems) {
            if (!allowedKeys.has(key)) {
                continue;
            }

            const recipientIds = selectedItems[key];
            const recipientPermissionStrings = recipientIds.map(_id => `${key}_${_id}`);
            recipients = recipients.concat(recipientPermissionStrings);
        }

        this.postForm.patchValue({
            ...this.postForm.value,
            recipients: recipients || [],
        });
    }

    get workspaceId(): string | undefined {
        return this.post?.cid || this.selectedWorkspaceId || this.core.network.value?._id;
    }

    get workspace(): Company | undefined {
        if (!this.workspaceId) {
            return;
        }

        return this.core.networks.value[this.workspaceId];
    }

    setRecipientNames(recipients: string[]): void {

        const recipientNames = new Set<string>();
        for (const recipient of recipients) {
            const [type, id] = recipient.split('_');
            if (!type || !id) {
                continue;
            }

            let name: string | undefined;
            switch (type) {
                case 'user':
                    name = this.core.users.value[id]?.display_name;
                    break;
                case 'team':
                    if (!this.selectedWorkspaceId) {
                        break;
                    }

                    name = this.core.teams.value[this.selectedWorkspaceId]?.[id]?.name;
                    break;
                case 'group':
                    if (!this.selectedWorkspaceId) {
                        break;
                    }

                    name = this.core.groups.value[this.selectedWorkspaceId]?.[id]?.name;
                    break;
                default:
                    break;
            }

            if (!name) {
                return;
            }

            recipientNames.add(name);
        }

        this.recipientNames = Array.from(recipientNames);
    }
}
