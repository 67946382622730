<div class="text-overflow">
    <mat-list-item (click)="notificationClick(notification)" *ngIf="notification.type === 'hailer.invite'" [style.box-shadow]="!notification.clicked ? '0 0 2px 0px inset #00000059' : 'none'" [style.background]="!notification.clicked ? '#41ff6926' : 'none'">
        <!-- Invite requires own section due to not seeing the network and user details directly, but from notification meta -->
        <app-user-profile-picture matListAvatar [userId]="notification.meta.user._id" [size]="50"></app-user-profile-picture>
        <div class="text-container">
            <div class="container" style="justify-content: space-between">
                <p class="notification-time" matLine>{{notification.created | date :'dd/MM/yy HH:mm'}}</p>
				<div
					class="network-tag"
					[style.background-color]="stylingHelperService.getNetworkBackgroundColor(notification.meta.company)"
					[style.color]="stylingHelperService.getNetworkTextColor(notification.meta.company)">
					<p>{{ notification.meta.company.name }}</p>
				</div>
            </div>
            <p matLine><strong>{{notification.meta.user.firstname}} {{notification.meta.user.lastname}}</strong>{{'shared.notification.is_inviting_to_join' | transloco}}</p>
            <p matLine><strong>{{notification.meta.company.name}}</strong></p>
        </div>
    </mat-list-item>

    <mat-list-item (click)="notificationClick(notification)" *ngIf="notification.type !== 'hailer.invite' && isKnownNotificationType" [style.box-shadow]="!notification.clicked ? '0 0 2px 0px inset #00000059' : 'none'" [style.background]="!notification.clicked ? '#41ff6926' : 'none'">
        <app-user-profile-picture matListAvatar *ngIf="notification.type !== 'activity.remind'" [userId]="notification.from" [size]="50"></app-user-profile-picture>
        <mat-icon *ngIf="notification.type == 'activity.remind'">
            alarm
        </mat-icon>

        <div class="text-container">
            <div class="container" style="justify-content: space-between">
                <p class="notification-time" matLine>{{notification.created | date :'dd/MM/yy HH:mm'}}</p>
				<div
					*ngIf="network?.name"
					class="network-tag"
					[style.background-color]="stylingHelperService.getNetworkBackgroundColor(network)"
					[style.color]="stylingHelperService.getNetworkTextColor(network)">
					<p>{{ network && network.name }}</p>
				</div>

            </div>
            <ng-container [ngSwitch]="notification.type">
                <ng-container *ngSwitchCase="'activity.created'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.created_activity' | transloco}}
                        <strong>{{notification.meta.activity.name}}</strong></p>
                    <p matLine>{{'shared.notification.to' | transloco}}<strong>{{phaseName}}</strong></p>
                </ng-container>

                <ng-container *ngSwitchCase="'activity.moved'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.moved_activity' | transloco}}
                        <strong>{{notification.meta.activity.name}}</strong></p>
                    <p matLine>{{'shared.notification.to' | transloco}}<strong>{{phaseName}}</strong></p>
                </ng-container>

                <ng-container *ngSwitchCase="'activity.update'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.updated_activity' | transloco}}</p>
                    <p matLine><strong>{{notification.meta.activity.name}}</strong></p>
                </ng-container>

                <ng-container *ngSwitchCase="'activity.completed'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.completed_activity' | transloco}}
                        <strong>{{notification.meta.activity.name}}</strong></p>
                    <p matLine>{{'shared.notification.to' | transloco}}<strong>{{phaseName}}</strong></p>
                </ng-container>

                <ng-container *ngSwitchCase="'activity.assigned'">
                    <p matLine>
                        <strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.assigned_you' | transloco}}
                        <span *ngIf="notification.meta.fieldCount > 1">
                            {{'shared.notification.in' | transloco}}<strong>{{notification.meta?.fieldCount}}</strong>{{'shared.notification.different_fields' | transloco}}
                        </span>
                        <span *ngIf="notification.meta.fieldCount === 1">
                            {{'shared.notification.as' | transloco}}<strong>{{notification.meta?.field}}</strong>
                        </span>
                        <span *ngIf="notification.meta.activityCount > 1">
                            {{'shared.notification.in' | transloco}}<strong>{{notification.meta?.activityCount}}</strong>{{'shared.notification.activities' | transloco}}
                        </span>
                        <span *ngIf="notification.meta.activityCount === 1">
                            {{'shared.notification.in' | transloco}}<strong>{{notification.meta?.activity?.name}}</strong>
                        </span>
                    </p>
                </ng-container>

                <ng-container *ngSwitchCase="'activities.moved'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.moved' | transloco}}<strong>{{notification.meta.count}}</strong>
                        {{'shared.notification.activities' | transloco}}</p>
                    <p matLine>{{'shared.notification.to' | transloco}}<strong>{{phaseName}}</strong></p>
                </ng-container>

                <ng-container *ngSwitchCase="'activities.update'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.updated' | transloco}}
                        <strong>{{notification.meta.count}}</strong>{{'shared.notification.activities' | transloco}}</p>
                    <p matLine>{{'shared.notification.in' | transloco}}<strong>{{phaseName}}</strong></p>
                </ng-container>

                <ng-container *ngSwitchCase="'activities.completed'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.completed' | transloco}}
                        <strong>{{notification.meta.count}}</strong>{{'shared.notification.activities' | transloco}}</p>
                    <p matLine>{{'shared.notification.to' | transloco}}<strong>{{phaseName}}</strong></p>
                </ng-container>

                <ng-container *ngSwitchCase="'wall.comment'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.commented_on_post' | transloco}}</p>
                    <p matLine><strong>{{notification.meta.comment.text}}</strong></p>
                </ng-container>

                <ng-container *ngSwitchCase="'event.invitation'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.is_inviting_you_to' | transloco}}
                        <strong>{{notification.meta.title}}</strong></p>
                    <span *ngIf="notification.meta.allDay; else fullTimestampTemplate">
                        <p matLine>{{'shared.notification.on' | transloco}}<strong>{{notification.meta.start | date :'dd/MM/yy'}}</strong></p>
                    </span>
                </ng-container>

                <ng-container *ngSwitchCase="'task.created'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.created_new_task_in' | transloco}}
                        <strong>{{notification.meta.task.tasklist}}.</strong></p>
                    <p matLine><strong>"{{notification.meta.task.text}}"</strong></p>
                </ng-container>

                <ng-container *ngSwitchCase="'task.completed'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.completed_task_in' | transloco}}
                        <strong>{{notification.meta.task.tasklist}}.</strong></p>
                    <p matLine><strong>"{{notification.meta.task.text}}"</strong></p>
                </ng-container>

                <ng-container *ngSwitchCase="'hailer.verifyEmail'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.email_not_validated' | transloco}}
                        {{'shared.notification.press_to_get_validation' | transloco}}</p>
                    <p matLine>{{'shared.notification.check_email' | transloco}}</p>
                    <p matLine><strong>{{notification.meta.company.description}}</strong></p>
                </ng-container>

                <ng-container *ngSwitchCase="'messenger.user_mentioned'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.mentioned_you_in' | transloco}}
                    <strong>{{getDiscussionSubject(notification.meta.discussion)}}</strong></p>
                </ng-container>

                <ng-container *ngSwitchCase="'feed.user_mentioned'">
                    <p matLine><strong>{{getUserDisplayName(notification.from)}}</strong>{{'shared.notification.mentioned_you_in' | transloco}}
                    <strong>{{getPostSubject(notification.meta.post)}}</strong>
                </p>
                </ng-container>

                <ng-container *ngSwitchCase="'activity.remind'">
                    <p matLine>Reminder for <strong>{{notification.meta.activity.name}}</strong></p>
                    <p matLine>{{generateReminderMessage(notification.meta.reminderText)}}</p>
                </ng-container>

                <ng-container *ngSwitchCase="'company.invite_accepted'">
                    <p matLine><strong>{{notification.meta.user.firstname}} {{notification.meta.user.lastname}}</strong>{{'shared.notification.accepted_your_invitation' | transloco}}
                    <strong>{{network?.name}}</strong></p>
                </ng-container>
            </ng-container>
        </div>
    </mat-list-item>
</div>

<ng-template #fullTimestampTemplate>
    <p matLine>{{'shared.notification.on' | transloco}}<strong>{{notification.meta.start | date :'dd/MM/yy HH:mm'}}</strong></p>
</ng-template>
