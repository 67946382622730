/** @format */

import { ValidatorFn } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { TranslocoService } from '@ngneat/transloco';

export type DropdownItemTypes = 'activity' | 'calendar' | 'group' | 'other' | 'team' | 'user' | 'workspace';
export type DropdownErrorStates = 'required';

export interface DropdownItems {
    [id: string]: DropdownItem;
}

export interface DropdownSelectedItems {
    user?: string[];
    team?: string[];
    group?: string[];
    activity?: string[];
    calendar?: string[];
    workspace?: string[];
    other?: string[];
}

export interface DropdownItem {
    _id: string;
    type: DropdownItemTypes;
    title?: string;
    disabled?: boolean;
    meta?: DropdownItemMeta;
}

export interface DropdownItemMeta {
    processTitle?: string;
    phaseTitle?: string;
    color?: string;
    memberCount?: number;
}
export class DropdownOptions {
    constructor(
        public appearance: MatFormFieldAppearance = 'outline',

        public placeholder = '',
        public multiple = true,
        public required = false,
        public errorMessage = '',
        public closeOnSelection = false,
        public selectLastItem = true,
        public openAutomatically = false,
        public customValidator: ValidatorFn | null = null,
        public onlySelector = false,
        public sortAlphabetically = true,
        public customColors: {
            selectedItems?: string;
        } = {},

        /** TODO: Figure out a cleaner way to do this */
        public hideClearButton = false
    ) {}
}
