import React, { useEffect, useState } from "react";
import { InsightDataValue } from "./insight";
import { distinctValueLimit, useInsight } from "./use-insight";
import { formatValue } from "./util";
import Select from 'react-select';
import Tooltip from "./tooltip";

const tableLimitDefault = 200;

interface DistinctMap {
  [column: number]: InsightDataValue[];
}

const checkLink = (value) => {
  if (typeof value === 'string') {
    if (value.startsWith('[')) {
      /* Match only links that are fully qualified with https */
      const fullLinkOnlyRegex = /^\[([\w\s\d]+)\]\((https?:\/\/[\w\d./?=#]+)\)$/;

      /* Match full links and relative paths */
      const regex = /^\[([\w\s\d]+)\]\(((?:\/|https?:\/\/)[\w\d./?=#]+)\)$/;

      const match = value.match(regex);

      if (!match) {
        return value;
      }

      // de-structure the array
      const [full, text, url] = match;

      if (!text || !url) {
        return value;
      }

      return <a className="insight-table-link" target="_blank" href={url}>{text}</a>;
    }
  }
  return value;
};

export default function Table() {
  const { api, options, data, filters, drillDown, table } = useInsight();

  const [renderTable, setRenderTable] = useState<any>({ headers: [], rows: [], data: [] });
  const [renderRows, setRenderRows] = useState<any>([]);
  const [renderData, setRenderData] = useState<any>([]);
  const [skip, setSkip] = useState(0);
  const [originalIndexMap, setOriginalIndexMap] = useState({});
  const [columnDistinct, setColumnDistinct] = useState<DistinctMap>();
  const [showFilter, setShowFilter] = useState(false);

  const menuPortalTargetRef = React.useRef(null);

  useEffect(() => {
    const tableRender = { headers: [], rows: [], data: [] };

    const distinct: { [key: number]: { [value: InsightDataValue]: true } } = {};

    // * Contains the indexes from the original data accessed from the rendering table
    // * 
    // * TODO: Figure out a better simpler solution
    const originalIndexMap = {};

    for (let i = 0; i < data.data.headers.length; i++) {
      if (!table.hidden?.[i]) {
        originalIndexMap[tableRender.headers.length] = i;
        tableRender.headers.push(data.data.headers[i]);
      }

      distinct[i] = {};
    }

    for (let rowIndex = 0; rowIndex < data.table.rows.length; rowIndex++) {
      const cols = Object.values(data.table.rows[rowIndex]);

      const tableRow = [];

      let push = true;

      for (let columnIndex = 0; columnIndex < cols.length; columnIndex++) {
        const value = cols[columnIndex];
        distinct[columnIndex][value] = true

        if (drillDown?.[columnIndex]) {
          if (drillDown[columnIndex] != value) {
            push = false;
            continue;
          }
        }

        if (table.filters?.distinct?.[columnIndex]) {
          if (!table.filters?.distinct[columnIndex].includes(value + '')) {
            push = false;
          }
        }
 
        if (!table.hidden?.[columnIndex]) {
          tableRow.push(value);
        }
      }

      if (push) {
        tableRender.rows.push(tableRow);
        tableRender.data.push(cols);
      }
    }

    const columnDistinct = Object.values(distinct).reduce<{ [column: number]: InsightDataValue[] | null }>((map, distinctValuesMap, index) => {
      const list = Object.keys(distinctValuesMap);

      if (list.length > distinctValueLimit) {
        map[index] = null;
      } else {
        map[index] = list.sort();
      }

      return map;
    }, {});

    setColumnDistinct(columnDistinct);

    if (tableRender.rows.length === 0) {
      // no rows
    }

    let limit = table.limit || tableLimitDefault;
    const skip = 0;

    setRenderRows(tableRender.rows.slice(skip, skip + limit));
    api.data.setRenderedTable(tableRender);
    setRenderData(tableRender.data.slice(skip, skip + limit));
    setSkip(skip);
    setOriginalIndexMap(originalIndexMap);

    // options?.event?.data?.table?.(tableRender.headers, tableRender.rows);
    // data.setTable({ headers: tableRender.headers, rows: tableRender.rows, data: tableRender.data });
    setRenderTable({ headers: tableRender.headers, rows: tableRender.rows, data: tableRender.data });
  }, [drillDown?.key, table.filters?.distinct, filters?.columns, data.data.headers, data.table.rows, table.limit, table?.hidden]);

  const tableHeader = (index: number) => {
    const header = renderTable.headers[index];
    const originalIndex = originalIndexMap[index];

    if (table.sort) {
      if (table.sort[0] == originalIndex) {
        if (table.sort[1] === true) {
          return (<span className="material-symbols-outlined small insight-table-header-arrow">arrow_upward_alt</span>);
        } else {
          return (<span className="material-symbols-outlined small insight-table-header-arrow">arrow_downward_alt</span>);
        }
      }
    }
  };

  const filterActive = (): boolean => {
    return Object.keys(table?.filters?.distinct || {}).length !== 0;
  };

  const clearFilters = () => {
    api.table.filter.clear();
  };

  const setTableLimit = (limit: any) => {
    api.table.set({ ...table, limit });
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'var(--insight-button-bg-secondary)',
      borderColor: 'transparent',
      boxShadow: state.isFocused ? '0 0 0 2px var(--insight-input-focus-outline)' : null,
      '&:hover': {
        borderColor: 'transparent',
      },
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: 'var(--insight-menu-bg)',
      '&:hover': {
        backgroundColor: 'var(--insight-menu-hover)',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'var(--insight-menu-bg)',
    }),
    input: (provided) => ({
      ...provided,
      color: 'var(--insight-text)',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: 'var(--insight-text)',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'var(--insight-tooltip-bg)',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'var(--insight-text)',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'var(--insight-hint)', 
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'var(--insight-text)' : 'var(--insight-text)',
      '&:hover': {
        color: 'var(--insight-hint)',
      },
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'var(--insight-text)' : 'var(--insight-text)',
      '&:hover': {
        color: 'var(--insight-hint)',
      },
    }),

  };

  const customSelectHighlightStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'var(--insight-highlight-select-bg)',
      borderColor: 'transparent',
      boxShadow: state.isFocused ? '0 0 0 2px var(--insight-input-focus-outline)' : null,
      '&:hover': {
        borderColor: 'transparent',
      },
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: 'var(--insight-menu-bg)',
      '&:hover': {
        backgroundColor: 'var(--insight-menu-hover)',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'var(--insight-menu-bg)',
    }),
    input: (provided) => ({
      ...provided,
      color: 'var(--insight-text)',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: 'var(--insight-text)',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'var(--insight-tooltip-bg)',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'var(--insight-text)',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'var(--insight-hint)', 
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'var(--insight-text)' : 'var(--insight-text)',
      '&:hover': {
        color: 'var(--insight-hint)',
      },
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'var(--insight-text)' : 'var(--insight-text)',
      '&:hover': {
        color: 'var(--insight-hint)', 
      },
    }),
  }

  return (
    <div className="table">
      <div className="insight-table-topbar">
        <form>
          <label className="insight-label">Max num of rows:</label>
          <input className="insight-input sm-insight-input" type="number" min="1" onChange={(event: any) => { setTableLimit(event.target.value) }} value={table.limit} />
        </form>
     
        <label className="insight-label columns-label">Columns:</label>
        <Select
          isMulti
          className="basic-multi-select"
          classNamePrefix="select"
          value={data.data.headers.map((header, columnIndex) => ({ value: columnIndex, label: header })).filter(option => !table.hidden?.[option.value])}
          onChange={api.table.setColumns}
          options={data.data.headers.map((header, columnIndex) => ({ value: columnIndex, label: header }))}
          styles={customSelectStyles}
          menuPosition="fixed"
          menuPortalTarget={menuPortalTargetRef.current}
        />

        {/* <button className={showFilter ? "insight-button insight-button-with-icon insight-button-toggled" : "insight-button insight-button-with-icon"} onClick={() => setShowFilter(!showFilter)}><span className="material-symbols-outlined">filter_alt</span>Select</button> */}

        <button className={`insight-button insight-button-with-icon table-filter-button`} onClick={() => setShowFilter(!showFilter)}>
          <span className={filterActive() ? "material-symbols-outlined icon bubble": "material-symbols-outlined icon"}>filter_alt</span>

          {showFilter ? 'Hide Filters' : 'Show Filters'}
         
          <Tooltip text="Clear filters" position="bottom">
            {filterActive() && 
            <span onClick={(event) => { event.stopPropagation(); clearFilters(); }} className="material-symbols-outlined icon insight-clear-filters-icon">close</span>}
          </Tooltip>
        </button>

        {/* Hiding button that triggers comparison filters for now: */}
        {/* <button className={showComparisonFilters ? "insight-button insight-button-with-icon insight-button-toggled" : "insight-button insight-button-with-icon"} onClick={() => setShowComparisonFilters(!showComparisonFilters)}><span className="material-symbols-outlined">balance</span>Filter</button> */}

      </div>
      <table className="list" ref={menuPortalTargetRef}>
        <thead>
          <tr>
            {renderTable.headers.map((header, columnIndex) => (
              <th key={columnIndex} className={`${showFilter ? "filter-on-th": ""}`}>
                <section className="pointer-cursor insight-table-header-arrow-wrapper" onClick={() => api.table.sort(originalIndexMap[columnIndex])}>
                  {header}
                  {tableHeader(columnIndex)}
                  {/* <pre>{JSON.stringify(columnType[originalIndexMap[columnIndex]], null, 2)}</pre> */}
                </section>
      
                {showFilter && 
                <section className="insight-filter-wrapper">
                  { columnDistinct[originalIndexMap[columnIndex]] === null && <span className="insight-no-filter-text">Too many options</span>}
                  { Array.isArray(columnDistinct[originalIndexMap[columnIndex]]) &&
                    <Select
                      value={table.filters?.distinct?.[originalIndexMap[columnIndex]]?.map(item => ({ label: item, value: item })) || []}
                      isMulti
                      options={columnDistinct[originalIndexMap[columnIndex]].map(item => ({ label: item, value: item }))}
                      onChange={(values) => api.table.filter.setDistinct(originalIndexMap[columnIndex], values)}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      menuPortalTarget={menuPortalTargetRef.current}
                      menuPosition="fixed"
                      styles={customSelectHighlightStyles}
                    />
                  }
                </section>
                }
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {!renderTable.rows.length && (<tr><td colSpan={renderTable.headers.length} className="insight-table-empty">No matching records found</td></tr>)}
          {!!renderTable.rows.length && renderRows.map((columns, index) => (
            <tr
              key={index}
              className="table-row"
              onClick={() => options.event?.rowSelected?.(renderData[index + skip])}
            >
              {columns.map((column, columnIndex) => <td key={columnIndex} className={'cell-value cell-value-type-' + typeof column}>{checkLink(formatValue(column))}</td>)}
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <th colSpan={renderTable.headers.length}>
              <div className="insight-rows-and-drill-down-flex">
                <span>{formatValue(renderRows.length)}/{formatValue(renderTable.rows.length)} rows</span>
                {drillDown?.key && 
                <button className="insight-button insight-button-with-icon close-drilldown-btn" onClick={() => api.unsetDrillDown()}>
                  <span className="material-symbols-outlined icon">close</span>
                  Close Drill-down</button>}
              </div>
            </th>
          </tr>
        </tfoot>

      </table>

    </div>
  )
}
