/** @format */

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
    selector: 'app-remove-dialog',
    templateUrl: './remove-dialog.component.html',
    styleUrls: ['./remove-dialog.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'dialogs', alias: 'dialogs' },
        },
    ],
})
export class RemoveDialogComponent implements OnInit {
    deleteInput = new UntypedFormControl();

    @ViewChild('deleteBtn', { static: true }) private deleteBtn: MatButton;

    constructor(@Inject(MAT_DIALOG_DATA) public data: string) {}

    ngOnInit() {
        this.deleteInput.valueChanges.subscribe((value: string) => {
            this.deleteBtn.disabled = !(value.toLowerCase() === 'delete');
        });
    }
}
