/** @format */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { RPCService } from 'app/_services/rpc.service';
import { Activity } from '@app/models';

@Component({
    selector: 'app-copy-activities-dialog',
    templateUrl: './copy-activities-dialog.component.html',
    styleUrls: ['./copy-activities-dialog.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'dialogs', alias: 'dialogs' },
        },
    ],
})
export class CopyActivitiesDialogComponent implements OnInit {
    copyableActivitiesForm: UntypedFormGroup = new UntypedFormGroup({}, { updateOn: 'blur' });
    selectedCreationMode: string;
    formGroupObject = {};
    advanced = false;

    constructor(
        private rpc: RPCService,
        public dialogRef: MatDialogRef<CopyActivitiesDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            enableUniqueName: boolean;
            activities: {
                name: string;
                _id: string;
            }[];
            processId: string;
            disableNameEditing: boolean;
            linkedActivities: boolean;
            isDataset: boolean;
        }
    ) {}

    ngOnInit() {
        this.data.activities.forEach(activity => {
            const ctrl = new UntypedFormControl(`Copy of ${activity.name}`, Validators.required, this.createValidator());
            this.copyableActivitiesForm.addControl(activity._id, ctrl);
        });

        this.copyableActivitiesForm.markAllAsTouched();

        if (this.data.disableNameEditing && !this.data.linkedActivities) {
            this.copyActivities();
        }
    }

    onCancel() {
        this.dialogRef.close();
    }

    createValidator(): AsyncValidatorFn {
        if (this.data.enableUniqueName) {
            return (control: AbstractControl): Observable<ValidationErrors> =>
                this.rpc
                    .request('v2.activities.nameIsUnique', [this.data?.processId, control.value])
                    .pipe(map((result: boolean) => (result ? null : { invalidAsync: true })));
        }
    }

    copyActivities() {
        const renamed: {
            advanced: boolean;
            rename: { [activityId: string]: string };
        } = {
            advanced: this.advanced,
            rename: this.copyableActivitiesForm.value,
        };

        const activityIds = Object.keys(this.copyableActivitiesForm.value);

        this.dialogRef.close({ activities: activityIds, renamed });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    trackById(index: number, item: Activity) {
        return item._id;
    }
}
