<!-- @format -->

<!-- {{ redraw() }} -->
<div class="drag-and-drop-area" [class.fullscreen]="sideNav.fullscreen | async">
	<app-file-uploader
		#uploader
		[origin]="'activityView'"
		[tagSelectorOptions]="tagSelectorOptions"
		(tagError)="tagError = !!$event"
		(tagged)="fileTagged($event)"
		(untagged)="fileUntagged($event)"
		(uploadInProgress)="uploadInProgress($event)"></app-file-uploader>

	<ng-template #uploadButton>
		<button
			class="upload-button"
			*ngIf="viewContext.action === 'view'; else uploadButtonWithText"
			[disabled]="!viewContext.canBeEdited"
			mat-icon-button
			(click)="uploader.open()">
			<mat-icon svgIcon="hailer-plus"></mat-icon>
		</button>

		<ng-template #uploadButtonWithText>
			<div class="upload-button-with-text-container">
				<button class="upload-button-with-text" mat-flat-button (click)="uploader.open()">
					<mat-icon class="no-button for-icon margin-right-small" svgIcon="hailer-plus"></mat-icon>
					<span>{{ 'activity-sidenav.files.upload_files' | transloco }}</span>
				</button>
			</div>
			<ng-container [ngTemplateOutlet]="emptyStateView"></ng-container>
		</ng-template>
	</ng-template>

	<ng-template #emptyStateView>
		<div class="empty-state-files"></div>
		<h3 class="empty-state-text">
			{{ 'activity-sidenav.files.no-attachments.no' | transloco }}
			<ng-container *ngIf="(viewContext.workflow | async)?.enableAttachments">
				{{ 'activity-sidenav.files.no-attachments.files' | transloco }}
			</ng-container>
			<ng-container *ngIf="(viewContext.workflow | async)?.enableAttachments && (viewContext.workflow | async)?.enableMessenger">
				{{ 'activity-sidenav.files.no-attachments.or' | transloco }}
			</ng-container>
			<ng-container *ngIf="(viewContext.workflow | async)?.enableMessenger">
				{{ 'activity-sidenav.files.no-attachments.discussion_attachments' | transloco }}
			</ng-container>
		</h3>
	</ng-template>

	<div class="file-upload-overlay" [class.overlay-show]="draggingFile">
		<mat-icon svgIcon="hailer-upload"></mat-icon>
	</div>

	<div *ngIf="viewContext.action === 'view'; else uploadButton">
		<div class="flex-row--centered search-header">
			<ng-template *ngIf="enableAttachments" [ngTemplateOutlet]="uploadButton"></ng-template>
			<div *ngIf="!noAttachments || enableAttachments" class="default-search-bar full-width">
				<mat-form-field [appearance]="'outline'" class="search-bar full-width files-search-bar">
					<span matPrefix>
						<mat-icon svgIcon="hailer-search"></mat-icon>
					</span>
					<input
						matInput
						type="search"
						[formControl]="searchFilesControl"
						[autocomplete]="'off'"
						[placeholder]="'activity-sidenav.files.search_placeholder' | transloco" />
					<button *ngIf="searchFilesControl.value" matSuffix class="no-button for-icon" (click)="searchFilesControl.setValue('')">
						<mat-icon svgIcon="hailer-x"></mat-icon>
					</button>
				</mat-form-field>
			</div>

			<ng-container *ngIf="enableAttachments || (viewContext.activity | async)?.files?.length">
				<button
					class="zip-files-button no-button large-round"
					[ngClass]="{ disabled: !(viewContext.activity | async)?.files?.length }"
					*ngIf="!loadingZip; else loadingZipSpinner"
					(click)="downloadFilesAsZip(); $event.stopPropagation()"
					tippy="{{ 'activity-sidenav.files.download_zip_tooltip' | transloco }}">
					<mat-icon svgIcon="hailer-download-all"></mat-icon>
				</button>

				<ng-template #loadingZipSpinner>
					<mat-spinner [diameter]="24"></mat-spinner>
				</ng-template>
			</ng-container>
		</div>

		<ng-container *ngIf="noAttachments" [ngTemplateOutlet]="emptyStateView"></ng-container>

		<mat-accordion #fileAccordion [multi]="true" [hideToggle]="false">
			<!-- Activity files -->
			<mat-expansion-panel *ngIf="filteredActivityFiles.length" [expanded]="true" class="files-expansion-panel">
				<mat-expansion-panel-header class="panel-header">
					<div class="flexbox-row--center-centered">
						<h3 class="panel-header-title">
							{{ 'activity-sidenav.files.panel-header.files' | transloco }}
						</h3>
						<div class="amount-bubble">
							<p>{{ filteredActivityFiles?.length }}</p>
						</div>
					</div>
				</mat-expansion-panel-header>

				<ng-container *ngIf="showTagFilterList; else normalFileList">
					<app-tag-filter-object-list
						*ngIf="fileFilterListOptions"
						(objectSelected)="openFile($event)"
						(objectToDelete)="setFileToBeRemoved($event)"
						[objectsFilterOptions]="fileFilterListOptions"
						[searchString]="tagObjectListSearch"
						[resetDeletableObjects]="resetTagFilerDeletableObjects">
					</app-tag-filter-object-list>
				</ng-container>

				<ng-template #normalFileList>
					<mat-list class="file-list" data-cy="activity-file-list" *ngIf="filteredActivityFiles?.length">
						<ng-container *ngFor="let file of filteredActivityFiles; let i = index; trackBy: trackFileById">
							<ng-container
								[ngTemplateOutlet]="fileItem"
								[ngTemplateOutletContext]="{ file, index: i, hideRemove: !viewContext.canBeEdited, fileArray: filteredActivityFiles }"></ng-container>
						</ng-container>
					</mat-list>
				</ng-template>
			</mat-expansion-panel>

			<!-- Discussion attachments -->
			<mat-expansion-panel *ngIf="totalFiles" [expanded]="true" class="files-expansion-panel">
				<mat-expansion-panel-header class="panel-header">
					<div class="flexbox-row--center-centered">
						<h3 class="panel-header-title">
							{{ 'activity-sidenav.files.panel-header.discussion_files' | transloco }}
						</h3>
						<div class="amount-bubble">
							<p>{{ totalFiles }}</p>
						</div>
					</div>
				</mat-expansion-panel-header>
				<mat-list class="file-list" data-cy="discussion-file-list">
					<ng-container *ngFor="let file of (discussionFiles | async); let i = index; trackBy: trackFileById">
						<ng-container
							[ngTemplateOutlet]="fileItem"
							[ngTemplateOutletContext]="{ file, index: i, hideRemove: true, fileArray: (discussionFiles | async)}"></ng-container>
					</ng-container>
				</mat-list>
				<button
					class="load-more-button"
					data-cy="discussion-file-list-load-more"
					[style.display]="allFilesLoaded ? 'none' : 'unset'"
					mat-button
					(click)="loadMoreFiles()">
					{{ 'activity-sidenav.files.load_more' | transloco }}
				</button>
			</mat-expansion-panel>

			<!-- Discussion links -->
			<mat-expansion-panel *ngIf="filteredDiscussionLinks.length" [expanded]="true" class="files-expansion-panel">
				<mat-expansion-panel-header class="panel-header">
					<div class="flexbox-row--center-centered">
						<h3 class="panel-header-title">
							{{ 'activity-sidenav.files.panel-header.discussion_links' | transloco }}
						</h3>
						<div class="amount-bubble">
							<p>{{ filteredDiscussionLinks.length }}</p>
						</div>
					</div>
				</mat-expansion-panel-header>
				<mat-list>
					<ng-container *ngFor="let link of filteredDiscussionLinks">
						<mat-list-item class="discussion-link-item">
							<div class="link-item">
								<a class="link two-line-ellipsis" [href]="link.link" target="_blank" [tippy]="link.link">{{ link.link }}</a>
								<div class="link-details">
									<p class="link-user">{{ viewContext.getUserName(link.uid) }}</p>
									<p class="link-date">{{ link.date | date : 'dd.MM.yyyy' }}</p>
								</div>
							</div>
						</mat-list-item>
					</ng-container>
				</mat-list>
			</mat-expansion-panel>
		</mat-accordion>
	</div>

    <ng-template #fileItem let-file="file" let-fileArray="fileArray" let-index="index" let-hideRemove="hideRemove">
        <mat-list-item class="file-item border-bottom">
            <div class="file-content">
                <button
                    class="file-item-content-left no-button"
                    (click)="viewImages(fileArray, index)"
                    [tippy]="'activity-sidenav.files.preview-file' | transloco"
                    [ariaLabel]="'activity-sidenav.files.preview-file' | transloco">
                    <div class="file-preview">
                        <img [src]="files.getFileThumbnail(file)" data-cy="preview-image" />
                        <div class="preview-icon">
                            <mat-icon svgIcon="hailer-expand-view"></mat-icon>
                        </div>
                    </div>

                    <div class="flex-column-align-start file-content-fullscreen">
                        <h3>
                            <span class="one-line-ellipsis">{{ file.name.substring(0, file.name.lastIndexOf('.')) || file.name }}</span>
                            <span class="file-extension">{{ file.name.substring(file.name.lastIndexOf('.')) }}</span>
                        </h3>
                        <p class="two-line-ellipsis">
                            {{ 'activity-sidenav.files.added_by' | transloco }}
                            {{ viewContext.getUserName(file.uid) }}
                        </p>
                        <p class="one-line-ellipsis">{{ 'activity-sidenav.files.date' | transloco }}
                            {{ file.created | date : 'short' }}
                        </p>
                    </div>
                </button>

                <div class="file-item-content-right">
                    <div class="flex">
                        <app-button
                            type="icon"
                            color="icon-success"
                            size="small"
                            [ariaLabel]="'activity-sidenav.files.download-file' | transloco"
                            hailerIconName="hailer-download"
                            (click)="$event.stopPropagation()"
                            (clickEvent)="files.download(file)"
                            [tippyText]="'activity-sidenav.files.download-file' | transloco"></app-button>

                        <app-button
                            *ngIf="!hideRemove"
                            type="icon"
                            color="icon-destructive"
                            size="small"
                            [ariaLabel]="'activity-sidenav.files.remove-file' | transloco"
                            [hailerIconName]="fileToBeRemoved(file._id) ? 'hailer-trash-fill' : 'hailer-trash'"
                            (click)="$event.stopPropagation()"
                            (clickEvent)="setFileToBeRemoved(file._id)"
                            [tippyText]="'activity-sidenav.files.remove-file' | transloco"></app-button>
                    </div>
                    <p class="file-size">{{ file.length | filesize }}</p>
                </div>
            </div>
        </mat-list-item>
    </ng-template>
</div>
