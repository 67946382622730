/** @format */

import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { App } from 'app/_models/app.model';
import { AppService } from 'app/_services/app.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-app-config',
    templateUrl: './app-config.component.html',
    styleUrls: ['./app-config.component.scss'],
})
export class AppConfigComponent implements OnInit {
    @Input() app: App;
    @Output() readonly submitEvent = new EventEmitter<boolean>();
    @Output() readonly closeEvent = new EventEmitter<boolean>();

    appPermissions: UntypedFormGroup;
    fields: string[];
    fieldControls: {
        [field: string]: UntypedFormControl;
    };
    permissionControls: {
        [field: string]: {
            write: FormControl<boolean>;
            read: FormControl<boolean>;
        };
    };

    constructor(
        private appService: AppService,
        @Inject(MAT_DIALOG_DATA) @Optional() public data?: App,
        @Optional() private dialogRef?: MatDialogRef<AppConfigComponent>
    ) {
        if (data && dialogRef) {
            this.app = data;
        }

        this.appPermissions = new UntypedFormGroup({});
        this.fieldControls = {};
        this.permissionControls = {};
    }

    close(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    ngOnInit(): void {
        const manifestFields = Object.keys(this.app.manifest?.config?.fields || {});
        const configFields = Object.keys(this.app.config || {}).filter(configField => !!this.app.manifest?.config?.fields?.[configField]);

        this.fields = Array.from(new Set([...configFields, ...manifestFields]));
        this.fields.forEach(name => (this.fieldControls[name] = new UntypedFormControl(this.app.config?.fields?.[name]?.value ?? '')));
    }

    async onSubmitConfig(): Promise<void> {
        const fields = {};

        this.fields.forEach(field => {
            fields[field] = this.fieldControls[field]?.value;
        });

        await this.appService.updateConfig(this.app._id, fields);
        this.close();
    }
}
