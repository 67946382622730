/** @format */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { filter, take } from 'rxjs/operators';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { Process } from '@app/models';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { Member } from 'app/_models/member.model';
import { PermissionType, PermissionValueType } from 'app/_models/permission-type.model';
import { CoreService } from 'app/_services/core.service';
import { PermissionService } from 'app/_services/permission.service';

@Component({
    selector: 'app-permission-type-selector',
    templateUrl: './permission-type-selector.component.html',
    styleUrls: ['./permission-type-selector.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class PermissionTypeSelectorComponent implements OnInit {
    @Input() permissionTypes: PermissionType[] = [];
    @Input() member: Member;

    @Output() permissionGrant = new EventEmitter<PermissionValueType>();
    @Output() permissionDeny = new EventEmitter<PermissionValueType>();

    constructor(
        private core: CoreService,
        private dialogHelperService: DialogHelperService,
        private permissions: PermissionService,
        private translocoService: TranslocoService
    ) {}

    ngOnInit(): void {}

    private get userIsMember(): boolean {
        return this.member.id.slice(5) === this.core.user.value.id;
    }

    get permissionType(): PermissionValueType {
        return this.member.permissions[0];
    }

    set permissionType(permission: PermissionValueType) {
        this.member.permissions[0] = permission;
    }

    changePermission(event: MatSelectChange): void {
        const previousPermission = this.permissionType as PermissionValueType;

        if (event.value === previousPermission) {
            return;
        }

        if (event.value !== 'admin' && previousPermission === 'admin' && this.userIsMember && !this.permissions.isNetworkAdmin) {
            const confirm = this.translocoService.translate('shared.permission-type.remove_confirm');
            const content = this.translocoService.translate('shared.permission-type.remove_content');
            const title = this.translocoService.translate('shared.permission-type.remove_title');

            this.dialogHelperService
                .showConfirm(confirm, content, title)
                .pipe(
                    filter(confirmed => !!confirmed),
                    take(1)
                )
                .subscribe({
                    next: () => {
                        this.permissionGrant.emit(event.value);

                        if (!previousPermission) {
                            return;
                        }

                        this.permissionDeny.emit(previousPermission);
                    },
                });
        } else {
            this.permissionGrant.emit(event.value);

            if (!previousPermission) {
                return;
            }

            this.permissionDeny.emit(previousPermission);
        }
    }

    setPermission(grant: boolean, key: PermissionValueType): void {
        if (grant) {
            this.permissionGrant.emit(key);
        } else {
            this.permissionDeny.emit(key);
        }
    }
}
