<div class="process-tag-field" (click)="$event.stopPropagation()">
    <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{'shared.tag-selector.add_tags' | transloco}}</mat-label>
        <mat-chip-list #selectedTagsList>

            <mat-chip class="process-chip" *ngFor="let tagId of (selectedTags | async)" [removable]="allowTagRemoving()"
                [selectable]="false" (removed)="untag(tagId)" [ngStyle]="{'color': tagSearchControl.value ? 'warn' : 'inherit'}">
                {{ getTag(tagId)?.value }}
                <mat-icon *ngIf="allowTagRemoving()" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <input matInput #addTagInput type="text" [matAutocomplete]="globalTagComplete" [formControl]="tagSearchControl"
                [matChipInputFor]="selectedTagsList" (focus)="tagSearchControl.setValue('')">
            <mat-error *ngIf="errorMessage">
                {{errorMessage}}
            </mat-error>
            <mat-autocomplete autoActiveFirstOption #globalTagComplete="matAutocomplete" (optionSelected)="addTag($event.option.value)">

                <mat-option *ngIf="checkForDublicateTags(tagSearchControl.value)" disabled>
                    {{'shared.tag-selector.already_in_use' | transloco}}
                </mat-option>

                <mat-option *ngFor="let tagId of (selectableTags | async)" [value]="{newTag: false, value: tagId}">
                    {{ getTag(tagId)?.value }}
                </mat-option>

                <mat-option *ngIf="hasCreateNewPermission" [value]="{newTag: true, value: tagSearchControl.value}">
                    <mat-icon color="accent">add</mat-icon>
                    {{'shared.tag-selector.create_new_tag' | transloco}}
                </mat-option>
                <!--
                    <mat-optgroup *ngFor="let tagGroup of (filteredSelectableTags | async)"
                    [disabled]="tagGroup.label === globalTagsLabel && (disableGlobalTags | async)" [label]="tagGroup.label">
                    <mat-option *ngFor="let tagId of tagGroup.tags" [value]="getTag(tagId)?.value"
                        [ngClass]="{'require-optgroup': tagGroup?.requireBeforeGlobal && (disableGlobalTags | async)}">
                        {{getTag(tagId)?.value}}
                    </mat-option>
                    </mat-optgroup>
                -->
            </mat-autocomplete>

        </mat-chip-list>
    </mat-form-field>
</div>