<div *ngIf="show" class="modal-header flex-row--space-between">
    <h1 mat-dialog-title>{{'profiledialog.edit_profile' | transloco}}</h1>
    <div style="float: right;" class="pull-right">
        <button data-cy="close-profile-dialog-button" mat-icon-button (click)="closeProfileDialog()">
            <mat-icon color="primary" aria-label="Close create calendar" style="color: white;">clear</mat-icon>
        </button>
    </div>
</div>

<mat-tab-group dynamicHeight="true" *ngIf="user && show" animationDuration="0">
    <!--User Info tab-->
    <mat-tab>
        <ng-template mat-tab-label>
            <span>{{'profiledialog.user_info' | transloco}}</span>
            <mat-icon>info</mat-icon>
        </ng-template>
        <div mat-dialog-content>
            <div class="container">
                <div class="form-div">
                    <form class="user-info-container" [formGroup]="userForm">
                        <div class="right-container">
                            
                            <div class="fill-field-container formfield-left">
                                <p>{{'profiledialog.last_name' | transloco}}</p>
                                <mat-form-field appearance="fill" floatLabel="never">
                                    <input matInput placeholder="{{'profiledialog.last_name' | transloco}}" formControlName="lastname"
                                        (keyup.enter)="changeUserInfo('lastname')" on-focusout="changeUserInfo('lastname')">
                                </mat-form-field>
                            </div>

                            <div class="fill-field-container">
                                <p>{{'profiledialog.email' | transloco}}</p>
                                <mat-form-field appearance="fill" floatLabel="never">
                                    <input *ngIf="!isManagedUser" matInput placeholder="{{'profiledialog.email' | transloco}}" formControlName="email" (keyup.enter)="changeUserInfo('email')"
                                        on-focusout="changeUserInfo('email')">
                                    <input *ngIf="isManagedUser" matInput placeholder="{{'profiledialog.email' | transloco}}" formControlName="notificationEmail" (keyup.enter)="changeUserInfo('notificationEmail')"
                                        on-focusout="changeUserInfo('email')">
                                    <mat-error *ngIf="emailInput?.hasError('email')">
                                        <p class="enter-valid-email">{{'profiledialog.valid_email_error' | transloco}}</p>
                                    </mat-error>
                                    <mat-error *ngIf="emailInput?.hasError('required')">
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="left-container">
                            <div class="fill-field-container">
                                <p>{{'profiledialog.first_name' | transloco}}</p>
                                <mat-form-field appearance="fill" class="formfield-left" floatLabel="never">
                                    <input matInput placeholder="{{'profiledialog.first_name' | transloco}}" formControlName="firstname"
                                        (keyup.enter)="changeUserInfo('firstname')" on-focusout="changeUserInfo('firstname')">
                                </mat-form-field>
                            </div>

                            <div class="fill-field-container formfield-left">
                                <p>{{'profiledialog.status' | transloco}}</p>
                                <mat-form-field appearance="fill" floatLabel="never">
                                    <input matInput placeholder="{{'profiledialog.status' | transloco}}" formControlName="status" (keyup.enter)="changeUserInfo('status')"
                                        on-focusout="changeUserInfo('status')">
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                <div *ngIf="!isManagedUser">    
                    <p class="location-title">{{'profiledialog.location' | transloco}}</p>
                    <div class="fill-field-container" *ngIf="user.lastLocation && !updatingLocation">
                        <mat-form-field appearance="fill" class="location-form" floatLabel="never">
                            <div class="location-flex">
                                <input matInput formControlName="lastLocation" value="{{user.lastLocation.name}}">
                                <button class="location-buttons" mat-icon-button color="accent" (click)="updateMyLocation()">
                                    <mat-icon class="location-icons" svgIcon="hailer-refresh"></mat-icon>
                                </button>
                                <button class="location-buttons" mat-icon-button color="warn"
                                    (click)="changeUserInfo('lastLocation', null)">
                                    <mat-icon class="location-icons">clear</mat-icon>
                                </button>
                            </div>
                        </mat-form-field>
                    </div>
                    <div *ngIf="!user.lastLocation && !updatingLocation">
                        <button class="update-location-button" mat-icon-button color="accent" (click)="updateMyLocation()">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="updatingLocation">
                        <mat-spinner diameter="20"></mat-spinner>
                        <p class="location-title">{{'profiledialog.updating_location' | transloco}}</p>
                    </div>
                </div>
                    <div class="custom-fields-container" *ngIf="network.propertyFields">
                        <div *ngFor="let field of userFields">
                            <div class="fill-field-container">
                                <p>{{field.label}}</p>
                                <ng-container [ngSwitch]="field.type">
                                    <ng-container *ngSwitchCase="'date'">
                                        <mat-form-field appearance="fill" floatLabel="never">
                                            <input matInput [formControl]="field.formControl"
                                                [matDatepicker]="picker" placeholder="{{'profiledialog.choose_date_placeholder' | transloco}}"
                                                (dateChange)="changeNetworkPropertyValueDate(field._id, $event.value)">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'optionlist'">
                                        <mat-form-field appearance="fill" floatLabel="never">
                                            <mat-select placeholder="{{field.formControl?.value}}"
                                                [formControl]="field.formControl"
                                                (selectionChange)="changeNetworkPropertyValue(field._id, $event.value)"
                                                [multiple]="field.enableMultiple">
                                                <mat-option *ngFor="let fieldValue of field.data" [value]="fieldValue">{{fieldValue}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'team'">
                                        <mat-form-field appearance="fill" floatLabel="never">
                                            <mat-select
                                                placeholder="{{getTeamName(field.formControl?.value)}}" [formControl]="field.formControl"
                                                (selectionChange)="changeNetworkPropertyValue(field._id, $event.value)"
                                                [multiple]="field.enableMultiple">
                                                <mat-option *ngFor="let fieldValue of field.data" [value]="fieldValue">
                                                    {{getTeamName(fieldValue)}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'country'">
                                        <div *ngIf="countryArray[0]">
                                            <mat-form-field appearance="fill" floatLabel="never">
                                                <mat-select
                                                    placeholder="{{field.formControl?.value}}" [formControl]="field.formControl"
                                                    (selectionChange)="changeNetworkPropertyValue(field._id,$event.value)"
                                                    [multiple]="field.enableMultiple">
                                                    <mat-option *ngFor="let country of countryArray" [value]="country.name">
                                                        {{country.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <mat-form-field appearance="fill" floatLabel="never">
                                            <input matInput placeholder="{{field.label}}"
                                                [formControl]="field.formControl"
                                                (keyup.enter)="changeNetworkPropertyValue(field._id, field.formControl.value)"
                                                on-focusout="changeNetworkPropertyValue(field._id, field.formControl.value)">
                                            <mat-error *ngIf="field.formControl?.hasError('required')">
                                            </mat-error>
                                        </mat-form-field>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <p class="language-title">{{'profiledialog.user_language' | transloco}}
                        <span class="language-info-tooltip"
                        matTooltipClass="default-tooltip" matTooltip="{{'profiledialog.user_language_tippy' | transloco}}">
                        <mat-icon>info</mat-icon>
                        </span>  
                    </p>
                    <div class="user-language-container">
                        <app-language-drag-and-drop class="add-language-container" [preferredLanguages]="preferredLanguages"
                        [allLanguages]="allLanguages" (setPreferredLanguages)='setPreferredLanguages($event)' (reloadTranslations)='reloadComponent()'></app-language-drag-and-drop>
                    </div>
                    <div class="delete-user">
                        <button data-cy="delete-user-button"
                            class="single-row-button" 
                            mat-raised-button color="warn" 
                            style="margin-top: 16px;" 
                            (click)="deleteUser()">
                            {{'profiledialog.delete_user' | transloco}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>

    <!--Password tab-->
    <mat-tab [disabled]="isManagedUser">
        <ng-template mat-tab-label>
            <span>{{'profiledialog.password' | transloco}}</span>
            <mat-icon>lock</mat-icon>
        </ng-template>
        <div mat-dialog-content>
            <h3>{{'profiledialog.change_password' | transloco}}</h3>
            <div *ngIf="changePasswordSuccess" style="color: var(--accent-500);">
                {{'profiledialog.password_change_success' | transloco}}
            </div>
            <ng-container *ngIf="!changePasswordSuccess && !user.noPassword">
                <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
                    <div class="form-div">
                        <div class="fill-field-container change-password">
                            <p class="first-p">{{'profiledialog.current_password' | transloco}}</p>
                            <mat-form-field appearance="fill" floatLabel="never" class="password-field">
                                <input autocomplete="current-password" matInput placeholder="{{'profiledialog.current_password' | transloco}}" [type]="visiblePasswords.old ? 'text' : 'password'" formControlName="old">
                                <mat-icon matSuffix class="password-visibility-button"
                                [svgIcon]="visiblePasswords.old ? 'hailer-show' : 'hailer-hide'"
                                (click)="visiblePasswords.old = !visiblePasswords.old">
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="fill-field-container change-password">
                            <p>{{'profiledialog.new_password' | transloco}}</p>
                            <mat-form-field appearance="fill" floatLabel="never" class="password-field" [ngClass]="{'mat-form-field-invalid': passwordFieldInvalid()}">
                                <app-password-strength-meter [passwordScore]="(passwordScore | async) ?? 0" [passwordInputFocused]="passwordInputFocused"
                                    id="password-strength-meter-tooltip" aria-hidden=“false”></app-password-strength-meter>
                                <input autocomplete="new-password" matInput placeholder="{{'profiledialog.new_password' | transloco}}" formControlName="new"
                                    (focusout)="setPasswordInputFocus(false, $event)" (focus)="setPasswordInputFocus(true)"
                                    [type]="visiblePasswords.new ? 'text' : 'password'" aria-describedby="password-strength-meter-tooltip">
                                <mat-icon matSuffix id="newPasswordInputVisibilityIcon" tabindex="0" class="password-visibility-button"
                                    [svgIcon]="visiblePasswords.new ? 'hailer-show' : 'hailer-hide'"
                                    (click)="visiblePasswords.new = !visiblePasswords.new"></mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="fill-field-container change-password">
                            <p>{{'profiledialog.confirm_new_password' | transloco}}</p>
                            <mat-form-field appearance="fill" floatLabel="never" class="password-field"
                                [ngClass]="{'mat-form-field-invalid': passwordConfirmFieldInvalid()}">
                                <input autocomplete="new-password" matInput placeholder="{{'profiledialog.confirm_new_password' | transloco}}"
                                    [type]="visiblePasswords.newConfirm ? 'text' : 'password'" formControlName="newConfirm">
                                <mat-icon matSuffix class="password-visibility-button"
                                    [svgIcon]="visiblePasswords.newConfirm ? 'hailer-show' : 'hailer-hide'"
                                    (click)="visiblePasswords.newConfirm = !visiblePasswords.newConfirm"></mat-icon>
                                <mat-hint *ngIf="checkforPasswordMatchingError()">
                                    <p class="enter-valid-email">{{'profiledialog.does_not_match_password' | transloco}}</p>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <button type="submit" class="new-password-button" mat-flat-button color="accent"
                        [disabled]="!changePasswordForm?.valid">{{'profiledialog.set_new_password' | transloco}}
                    </button>
                </form>
            </ng-container>
            <ng-container *ngIf="user.noPassword">
                <p>{{'profiledialog.no_password_set' | transloco}}</p>
                <p *ngIf="passwordRecoveryStatus === 'sent'">{{'profiledialog.email_sent_success' | transloco}}</p>
                <p *ngIf="passwordRecoveryStatus === 'error'">{{'profiledialog.email_sent_error' | transloco}}</p>
                <button type="button" class="new-password-button" (click)="sendPasswordRecovery()" mat-flat-button color="accent">
                    {{'profiledialog.set_new_password' | transloco}}
                </button>
            </ng-container>
        </div>
    </mat-tab>

    <!--Pictures tab-->
    <mat-tab [disabled]="isManagedUser">
        <ng-template mat-tab-label>
            <span>{{'profiledialog.pictures' | transloco}}</span>
            <mat-icon>photo_library</mat-icon>
        </ng-template>
        <div mat-dialog-content>
            <div class="pictures-header">
                <div class="add-photo-button-container">
                    <button class="add-photo" mat-stroked-button color="accent" (click)="uploader.click()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>
            <input hidden type="file" #uploader accept="image/*" (change)="uploadProfilePicture($event)" />
            <div class="profile-pictures">
                <div class="image-container" *ngFor="let pfp of user.profilepics; let i = index">
                    <div *ngIf="user.default_profilepic !== pfp" class="default-pic-container">
                        <img crossorigin="use-credentials" (error)="onError(pfp)" draggable="false" class="profile-picture" [src]="getUserProfilePicture(pfp)" alt="User profile picture">
                        <div class="profile-actions-container">
                            <div class="delete-pic-btn-container">
                                <button class="delete-pic-btn no-button for-icon warn" (click)="confirmDeletion(pfp, i)">
                                    <mat-icon svgIcon="hailer-trash"></mat-icon>
                                </button>
                            </div>
                            <div class="default-pic-icon">
                                <button mat-icon-button (click)="setProfilePicture(pfp)">
                                    <mat-icon>favorite_border</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="default-profile-container" *ngIf="user.default_profilepic === pfp">
                        <img crossorigin="use-credentials" (error)="onError(pfp)" draggable="false" [src]="getUserProfilePicture(pfp)"
                            alt="User default profile picture"
                            class="profile-picture current-profile-picture mat-elevation-z4">
                        <div class="default-pic-icon selected-pic">
                            <mat-icon>favorite</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>

    <!--Notifications tab-->
    <mat-tab>
        <ng-template mat-tab-label>
            <span>{{'profiledialog.notifications' | transloco}}</span>
            <mat-icon>notifications</mat-icon>
        </ng-template>
        <div mat-dialog-content class="mobile-and-notifications-container">
            <div class="mobile-and-notifications-item">
                <h4>
                    <mat-icon>devices</mat-icon>{{'profiledialog.devices' | transloco}}
                </h4>
                <div class="mobile-name" *ngFor="let device of deviceList; let i = index">
                    <div class="devices-flex" style="height: 65px">
                        <div style="display: flex; flex-direction: column;">
                            <!-- We there's already a branch that would include some metadata to the device object on the api side -->
                            <div>{{getDeviceName(device.name)}}</div>
                            <div style="font-size: smaller; font-weight: 400;">{{getDeviceTimestamp(device.name)}}</div>
                        </div>
                        <button class="no-button for-icon warn" (click)="removeDevice(device.token, i)">
                            <mat-icon svgIcon="hailer-trash"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="mobile-and-notifications-item">
                <h4>
                    <mat-icon>notifications</mat-icon>{{'profiledialog.email' | transloco}}
                </h4>
                <div class="notification-list" [formGroup]="notificationForm">
                    <mat-checkbox *ngIf="!permissions.isNetworkGuest" formControlName="feedPostEmail">{{'profiledialog.feed_posts' | transloco}}</mat-checkbox>
                    <mat-checkbox formControlName="discussionMessageEmail">{{'profiledialog.discussion_messages' | transloco}}</mat-checkbox>
                </div>
            </div>
        </div>
    </mat-tab>

    <!--Workspaces tab-->
    <mat-tab [disabled]="isManagedUser">
        <ng-template mat-tab-label>
            <span>{{'profiledialog.workspaces' | transloco}}</span>
            <mat-icon>wifi_tethering</mat-icon>
        </ng-template>
        <div mat-dialog-content>
            <h3>{{'profiledialog.set_default_workspaces' | transloco}}</h3>
            <div class="network-name" *ngFor="let network of networks | async">
                <p *ngIf="user.default_company !== network._id" (click)="changeUserInfo('default_company', network._id)">
                    <mat-icon svgIcon="hailer-star"></mat-icon>
                    <span class="network-title">{{network.name}}</span>

                    <app-button
                    [isDisabled]="isManagedUser"
                    type="icon"
                    size="medium"
                    color="icon-destructive"
                    (click)="$event.stopPropagation()"
                    (clickEvent)="leaveNetwork(network)"
                    hailerIconName="hailer-sign-out"
                    tippyText="{{'profiledialog.leave-workspace' | transloco}}"
                    ariaLabel="{{'profiledialog.leave-workspace' | transloco}}"
                    class="exit-network"
                    ></app-button>

                </p>
                <p class="default-network-name" *ngIf="user.default_company === network._id">
                    <mat-icon svgIcon="hailer-star-fill" class="starred-icon"></mat-icon>
                    {{network.name}}
                </p>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
