/** @format */

import { Deserializable } from './deserializable.model';

export class Group implements Deserializable {
    cid?: string;
    description?: string;
    name: string;
    groups: string[];
    teams: string[];
    users: string[];
    _id: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    constructor(groupData?: any) {
        if (groupData) {
            this.deserialize(groupData);
        }
    }
}

export interface GroupMap {
    [workspaceId: string]: {
        [roleId: string]: Group;
    };
}
