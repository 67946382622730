/** @format */

import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { PermissionService } from 'app/_services/permission.service';
import { RPCService } from './../../_services/rpc.service';
import { FilesService, UploadState } from './../../_services/files.service';
import { PeopleService } from './../people.service';
import { CoreService } from 'app/_services/core.service';
import { Company, CompanyMap, Country, PropertyField, User } from '@app/models';
import { environment } from '@app/env';
import { LocationResponse } from 'app/_models/newLocationResponse.model';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { TeamService } from 'app/_services/team.service';
import { HelperService } from 'app/_services/helper.service';
import { UserService } from 'app/_services/user.service';
import { TranslateService } from 'app/_services/translate.service';

import { RemoveDialogComponent } from 'app/_dialogs/remove-dialog/remove-dialog.component';
import { passwordComplexityValidator, passwordConfig, passwordMatchingValidator } from 'app/_helpers/password-helper';
import { updateProcesses } from 'app/_helpers/process-translation-helper';

@Component({
    selector: 'app-profile-dialog',
    templateUrl: './profile-dialog.component.html',
    styleUrls: ['./profile-dialog.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'people/profile-dialog', alias: 'profiledialog' },
        },
    ],
})
export class ProfileDialogComponent implements OnInit, OnDestroy {
    user: User = null;
    deviceList: any[] = [];
    networks: Observable<Company[]>;
    userFields: PropertyField[] = [];
    countryArray: Country[] = [];
    updatingLocation: boolean;
    changePasswordForm: UntypedFormGroup = new UntypedFormGroup({});
    changePasswordSuccess = false;
    userForm: UntypedFormGroup = new UntypedFormGroup({});
    passwordRecoveryStatus = 'unsent';
    notificationForm: UntypedFormGroup = new UntypedFormGroup({});
    preferredLanguages: string[] = ['en'];
    allLanguages: string[] = [];
    isManagedUser: boolean;
    show = true;

    // For the password strength meter
    passwordInputFocused = false;
    visiblePasswords = {
        old: false,
        new: false,
        newConfirm: false,
    };
    passwordScore: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    private failedImages: string[] = [];
    private uploadUrl = `${environment.wsUrl}/upload`;
    private onDestroy = new Subject<void>();

    // TODO: clean up this network copy object
    private copyOfNetwork;

    constructor(
        private zone: NgZone,
        public dialogRef: MatDialogRef<ProfileDialogComponent>,
        public permissions: PermissionService,
        public translocopipe: TranslocoService,
        public filesService: FilesService,
        private peopleService: PeopleService,
        private coreService: CoreService,
        private rpc: RPCService,
        private formBuilder: UntypedFormBuilder,
        private core: CoreService,
        private snackbar: MatSnackBar,
        private dialogHelperService: DialogHelperService,
        private teamService: TeamService,
        private helperService: HelperService,
        private rpcService: RPCService,
        private cdr: ChangeDetectorRef,
        private userService: UserService,
        private translocoService: TranslocoService,
        private translateService: TranslateService,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        // Double negation casts to either true or false, used in .html
        this.isManagedUser = !!this.core.user.value.managedUser;
        // Make a copy of the network object from core
        this.copyOfNetwork = JSON.parse(JSON.stringify(this.core.network.value));
        this.getDeviceList();
        this.getNetworkFields();
        this.countryArray = this.core.countries.value;
        this.initForms();
        this.getAllLanguages();
        this.getPreferredLanguages();

        this.getUser()
            .pipe(take(1))
            .subscribe({
                next: () => this.addNetworkSpecificFields(),
            });

        this.networks = this.core.networks.pipe(
            map((networks: CompanyMap) => Object.values(networks).sort(this.helperService.sortAlphabetically('name'))),
            takeUntil(this.onDestroy)
        );

        this.notificationForm?.valueChanges.pipe(takeUntil(this.onDestroy), debounceTime(500)).subscribe({
            next: change => this.userService.setNotifications(change),
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    get emailInput(): UntypedFormControl {
        return this.userForm?.get('email') as UntypedFormControl;
    }

    updateMyLocation() {
        this.updatingLocation = true;

        this.peopleService
            .getGeoLocation()
            .pipe(
                take(1),
                switchMap(loc => this.peopleService.getGeoAddress(loc.coords.latitude, loc.coords.longitude))
            )
            .subscribe({
                next: (locationResponse: LocationResponse) => {
                    const value = {
                        lat: locationResponse.results[0].geometry.location.lat,
                        lng: locationResponse.results[0].geometry.location.lng,
                        name: locationResponse.results[0].formatted_address,
                    };
                    // Finally sends the coordinates and users address to be updated on the backend
                    this.changeUserInfo('lastLocation', value);
                    this.updatingLocation = false;
                },
                error: (error: any) => {
                    this.updatingLocation = false;
                    this.showErrorSnackbar(error);
                },
            });
    }

    setPasswordInputFocus(state: boolean, event?: any) {
        // Don't change the focus if password visibility button is clicked
        if (!(!state && event.relatedTarget?.id === 'newPasswordInputVisibilityIcon')) {
            this.passwordInputFocused = state;
        }
    }

    checkforPasswordMatchingError(): boolean {
        return (
            this.changePasswordForm?.controls?.newConfirm &&
            this.changePasswordForm?.controls?.newConfirm?.errors?.passwordsDoNotMatch &&
            this.changePasswordForm?.controls?.newConfirm?.dirty
        );
    }

    passwordFieldInvalid(): boolean | null | undefined {
        return (
            this.changePasswordForm?.controls?.new?.errors &&
            !this.changePasswordForm.controls.new.pristine &&
            this.changePasswordForm.controls.new.dirty
        );
    }

    passwordConfirmFieldInvalid(): boolean {
        return (
            this.changePasswordForm.controls?.newConfirm?.errors?.passwordsDoNotMatch &&
            !this.changePasswordForm.controls.newConfirm?.pristine &&
            this.changePasswordForm.controls.newConfirm?.dirty
        );
    }

    getEnvUrl(): string {
        return environment.wsUrl;
    }

    get network(): Company {
        // Return a copy of the network object as we have manipulated it here
        return this.copyOfNetwork; // This.core.network.value;
    }

    getUser() {
        return this.peopleService.getProfileInfo().pipe(
            tap((user: User) => {
                this.user = user;
                this.userForm.patchValue(user);
            })
        );
    }

    getNetworkFields() {
        if (this.network.propertyFields) {
            this.userFields = Object.keys(this.network.propertyFields.users).map(i => this.network.propertyFields.users[i]);
        } else {
            // If network has no property fields the ngIf on line 65 of profile-dialog html will not draw anything.
        }
    }

    uploadProfilePicture(event: any) {
        this.filesService
            .upload(event.target.files[0])
            .pipe(takeUntil(this.onDestroy))
            .subscribe((state: UploadState) => {
                if (state.finished) {
                    this.peopleService
                        .addProfilePicture(state.fileId)
                        .pipe(take(1))
                        .subscribe({
                            next: () => {
                                this.user.profilepics.push(state.fileId);
                                this.setProfilePicture(state.fileId);
                            },
                            error: (error: any) => console.error('Error adding profile picture', error),
                        });
                }
            });
    }

    confirmDeletion(profilePictureId: string, index: number) {
        const confirm = this.translocoService.translate('profiledialog.confirm_delete_picture');
        const content = this.translocoService.translate('profiledialog.content_delete_picture');
        const title = this.translocoService.translate('profiledialog.title_delete_picture');

        this.dialogHelperService
            .showConfirm(confirm, content, title)
            .pipe(
                take(1),
                filter(confirmed => !!confirmed),
                switchMap(() => this.peopleService.deleteProfilePicture(profilePictureId))
            )
            .subscribe({
                next: () => this.user.profilepics.splice(index, 1),
                error: (error: any) => console.error('Error deleting profile picture', error),
            });
    }

    getDeviceList() {
        this.peopleService
            .getDeviceList()
            .pipe(take(1))
            .subscribe({
                next: (result: any) => (this.deviceList = result),
                error: (error: any) => console.error('Error getting device list', error),
            });
    }

    getPreferredLanguages() {
        this.translateService.translationLoaded('misc').then(() => {
            this.coreService.user.pipe(takeUntil(this.onDestroy)).subscribe({
                next: user => {
                    if (user.preferredLanguages) {
                        this.preferredLanguages = user.preferredLanguages;
                    } else {
                        this.preferredLanguages = ['en'];
                        this.setPreferredLanguages(this.preferredLanguages);
                    }
                },
            });
        });
    }

    getAllLanguages() {
        this.core
            .getAllLanguages()
            .pipe(take(1))
            .subscribe({
                next: (result: any) => (this.allLanguages = result),
                error: (error: any) => console.error('getUserLanguages error:', error),
            });
    }

    removeDevice(deviceId: any, index: number) {
        this.peopleService
            .removeDevice(deviceId)
            .pipe(take(1))
            .subscribe({
                next: () => this.deviceList.splice(index, 1),
                error: (error: any) => console.error('Error removing device', error),
            });
    }

    leaveNetwork(network: Company) {
        const confirm = this.translocoService.translate('profiledialog.confirm_leave');
        const content = `${this.translocoService.translate('profiledialog.content_leave')}${network.name}?`;
        const title = this.translocoService.translate('profiledialog.title_leave');

        this.dialogHelperService
            .showConfirm(confirm, content, title)
            .pipe(
                take(1),
                filter(confirmed => !!confirmed),
                switchMap(() => this.rpc.request('company.leave', [network._id]))
            )
            .subscribe({
                next: () => this.core.fetchAndUpdateNetworks(),
                error: (error: any) => {
                    this.showError(error.msg);
                    console.error('Error leaving workspace', error);
                },
            });
    }

    setProfilePicture(pfp: string) {
        this.showSnackbar();
        this.peopleService
            .setProfilePicture(pfp)
            .pipe(take(1))
            .subscribe({
                next: () => (this.user.default_profilepic = pfp),
                error: (error: any) => {
                    this.showError(error.msg);
                    console.error('Error setting profile picture', error);
                },
            });
    }

    setPreferredLanguages(userLanguages: string[]) {
        this.translocoService.load(`people/profile-dialog/${userLanguages[0]}`).subscribe();
        this.peopleService
            .changeUserInfo('preferredLanguages', userLanguages)
            .pipe(take(1))
            .subscribe({
                error: (error: any) => {
                    console.error('Error changing user info: ', error);
                },
            });
        this.translocoService.setActiveLang(userLanguages[0] || 'en');
        localStorage.setItem('selectedLanguage', userLanguages[0] || 'en');
        updateProcesses(this.core.processes.value, userLanguages, this.core.network.value.settings.languages);
    }

    reloadComponent() {
        // ProdMode disables unnecessary translation console warnings that occur before we reload the page
        this.translocoService.config.prodMode = true;

        this.show = false;
        setTimeout(() => {
            this.show = true;
            this.translocoService.config.prodMode = environment.production;
        });
    }

    setNotifications() {
        this.peopleService
            .setNotifications(this.user.notifications)
            .pipe(take(1))
            .subscribe({
                next: () => this.showSnackbar(),
                error: (error: any) => console.error('Error setting notifications', error),
            });
    }

    changePassword() {
        this.changePasswordForm.setErrors({ passwordChangeFailed: false });
        this.changePasswordSuccess = false;

        const updateData = {
            new: this.changePasswordForm.value.new,
            old: this.changePasswordForm.value.old,
        };

        this.peopleService
            .changePassword(updateData)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: () => {
                    this.showSnackbar();
                    this.changePasswordSuccess = true;
                    this.changePasswordForm.reset();

                    setTimeout(() => {
                        this.changePasswordSuccess = false;
                    }, 2000);
                },
                error: (error: any) => {
                    this.changePasswordForm.setErrors({ passwordChangeFailed: true });
                    this.showErrorSnackbar(this.translocoService.translate('profiledialog.ts_snackbar.password_error'));
                    console.error('Password change error', error);
                },
            });
    }

    changeUserInfo(field: string, value?: any) {
        let updateData = null;

        if (value === undefined) {
            updateData = field === 'email' ? this.userForm.value[field].trim().toLowerCase() : this.userForm.value[field];
        } else {
            updateData = field === 'email' ? value.trim().toLowerCase() : value;
        }

        if (field === 'email' && updateData === this.user.email) {
            return;
        }

        this.userForm.controls[field].setValue(updateData ? updateData : '');

        this.peopleService
            .changeUserInfo(field, updateData)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.showSnackbar();
                    if (field === 'default_company') {
                        this.user.default_company = value;
                    }
                    this.cdr.detectChanges();
                },
                error: (error: any) => {
                    this.showError(error.msg);
                    console.error('Error changing user info', error);
                },
            });
    }

    // Checks if option, team or country list has "enableMultiple" selected
    enableMultiple(array): boolean {
        if (array.enableMultiple === true) {
            return true;
        }
        return false;
    }

    changeNetworkPropertyValue(fieldId: string, data: any) {
        const field = this.userFields.find(({ _id }) => _id === fieldId);

        if (field.formControl.pristine) {
            return;
        }

        this.peopleService
            .changeNetworkPropertyValue(fieldId, data)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.showSnackbar();
                    field.formControl.markAsPristine();
                },
                error: (error: any) => {
                    this.showError(error.msg);
                    console.error('Error changing value', error);
                },
            });
    }

    /* This is still waiting for backend date changes
       Changes will be made to date formatting, will send data in format 31-12-1970 after update */

    changeNetworkPropertyValueDate(fieldId: string, date: Moment) {
        const data = date.utc(true).format();

        this.peopleService
            .changeNetworkPropertyValueDate(fieldId, data)
            .pipe(take(1))
            .subscribe({
                next: () => this.showSnackbar(),
                error: (error: any) => {
                    this.showError(error.msg);
                    console.error('Error changing date value', error);
                },
            });
    }

    getUserDisplayName(uid: string): string {
        const user = this.peopleService.getUser(uid);
        return `${user.firstname} ${user.lastname}`;
    }

    getUserProfilePicture(profilePicId: string): string {
        const profilePicUrl = `${this.getEnvUrl()}/image/square200/${profilePicId}`;
        const fallbackImg = '../assets/img/default-profile-picture.jpeg';
        return this.failedImages.includes(profilePicId) ? fallbackImg : profilePicUrl;
    }

    closeProfileDialog() {
        this.dialogRef.close();
    }

    getDeviceName(deviceStr: string): string {
        // Just some shenanigans to make sure we don't mess with iOS strings
        if (deviceStr.includes('Last login')) {
            const match = deviceStr.split('- ');
            if (match.length) {
                return match[0];
            }
        }
        return deviceStr;
    }

    getDeviceTimestamp(deviceStr: string) {
        const regex = /(Last login:[a-zA-Z 0-9,:]+)/;
        if (deviceStr.includes('Last login')) {
            const match = regex.exec(deviceStr);
            if (match && match.length >= 1) {
                return match[1];
            }
        }
    }

    sendPasswordRecovery() {
        this.rpcService.request('core.prepare_password_recovery', [this.user.email]).subscribe(
            (data: any) => {
                this.passwordRecoveryStatus = 'sent';
            },
            (error: any) => {
                this.passwordRecoveryStatus = 'error';
            }
        );
    }

    getTeamName(teamId: string): string {
        if (Array.isArray(teamId)) {
            teamId = teamId[0];
        }
        return teamId ? this.teamService.getTeam(teamId).name : this.translocoService.translate('profiledialog.ts_select_team');
    }

    onError(profilePicId: string) {
        this.failedImages.push(profilePicId);
        this.cdr.detectChanges();
    }

    deleteUser() {
        this.zone.run(() => {
            const dialog = this.dialog.open(RemoveDialogComponent, {
                width: '700px',
                data: this.translocoService.translate('profiledialog.user_deletion_confirmation_dialog'),
            });

            dialog
                .afterClosed()
                .pipe(
                    filter(res => !!res),
                    take(1)
                )
                .subscribe({
                    next: () => {
                        this.userService.deleteUser().subscribe({
                            next: res => {
                                if (!res) {
                                    console.error('User deletion failed: ', res);
                                    this.showErrorSnackbar(
                                        this.translocoService.translate('profiledialog.ts_snackbar.failed_to_delete_user_error')
                                    );
                                    return;
                                }

                                const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
                                this.core.clearCache();

                                localStorage.setItem('selectedLanguage', selectedLanguage === 'null' ? 'en' : selectedLanguage);

                                location.reload();
                            },
                            error: error => {
                                console.error(error);
                                this.showErrorSnackbar(
                                    this.translocoService.translate('profiledialog.ts_snackbar.failed_to_delete_user_error')
                                );
                            },
                        });
                    },
                });
        });
    }

    private initForms() {
        this.changePasswordForm = this.formBuilder.group(
            {
                old: [null, [Validators.required]],
                new: [
                    null,
                    [Validators.required, Validators.minLength(passwordConfig.minLength), passwordComplexityValidator(this.passwordScore)],
                ],
                newConfirm: [null, [Validators.required, Validators.minLength(passwordConfig.minLength)]],
            },
            { validators: passwordMatchingValidator('new', 'newConfirm') }
        );

        this.userForm = this.formBuilder.group({
            firstname: [''],
            lastname: [''],
            email: ['', [Validators.email, Validators.required]],
            notificationEmail: [''],
            status: [''],
            lastLocation: [{ value: '', disabled: true }],
            default_company: [''],
        });

        if (this.isManagedUser) {
            this.userForm.disable();
        }

        const me = this.userService.me.getValue();
        this.notificationForm = this.formBuilder.group({
            feedPostEmail: [me?.globalSettings?.feedPostEmail || false],
            discussionMessageEmail: [me?.globalSettings?.discussionMessageEmail || false],
        });
    }

    private addNetworkSpecificFields() {
        this.userFields.forEach(field => {
            const member = this.core.network.value?.members?.find(({ uid }) => uid === this.user._id);
            const propertyField = member?.fields?.[field._id];

            if (!propertyField) {
                field.formControl = new UntypedFormControl(null, [Validators.required]);

                if (this.isManagedUser) {
                    field.formControl.disable();
                }
                return;
            }

            field.formControl = new UntypedFormControl(propertyField, [Validators.required]);

            if (field.filledBy === 'admin' || this.isManagedUser) {
                field.formControl.disable();
            }
        });
    }

    private showError(errorMsg: string) {
        const confirm = this.translocoService.translate('profiledialog.show_error.ok');
        const content = errorMsg;
        const title = this.translocoService.translate('profiledialog.show_error.error');

        this.dialogHelperService.showError(confirm, content, title).pipe(take(1)).subscribe();
    }

    private showSnackbar() {
        this.snackbar.open(
            this.translocoService.translate('profiledialog.ts_snackbar.changes_saved'),
            this.translocoService.translate('profiledialog.ts_snackbar.close'),
            { duration: 2500 }
        );
    }

    private showErrorSnackbar(message: string) {
        this.snackbar.open(message, this.translocoService.translate('profiledialog.ts_snackbar.close'), { duration: 5500 });
    }
}
