/** @format */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

import { Company, FeedPost } from '@app/models';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { CoreService } from 'app/_services/core.service';
import { RPCService } from './rpc.service';

@Injectable()

export class FeedPostDetailResolverService {
    private post: FeedPost;

    constructor(
        private router: Router,
        private core: CoreService,
        private dialogHelperService: DialogHelperService,
        private translocoService: TranslocoService,
        private rpc: RPCService,
    ) {}

    async resolve(route: ActivatedRouteSnapshot): Promise<FeedPost | undefined> {
        const postId = route.paramMap.get('postId');
        if (!postId) {
            await this.router.navigate(['/feed']);
            return;
        }

        let post: FeedPost | undefined;
        try {
            const edited = await this.rpc.requestAsync('v3.feed.post.load', [{ postIds: [postId] }]);
            [post] = edited.posts;
        } catch (error) {
            console.error('Failed to fetch feed post');
        }

        if (!post) {
            await this.router.navigate(['/feed']);
            return;
        }

        const networks = this.core.networks.value;
        const currentNetwork = this.core.network.value;
        const postNetwork = networks[post.cid];
        if (!postNetwork || !currentNetwork) {
            await this.router.navigate(['/feed']);
            return;
        }

        this.post = post;
        if (post.cid !== currentNetwork._id) {
            this.showError(postNetwork);
            await this.router.navigate(['/feed']);
            return;
        }

        return post;
    }

    private showError(network: Company) {
        const confirm = this.translocoService.translate('misc.services.feed-post-detail-resolver.switch_confirm');
        const content = `${this.translocoService.translate('misc.services.feed-post-detail-resolver.switch_content') + network.name}.`;
        const title = this.translocoService.translate('misc.services.feed-post-detail-resolver.switch_title');
        const confirmColor = 'primary';

        this.dialogHelperService
            .showConfirm(confirm, content, title, confirmColor)
            .pipe(take(1))
            .subscribe({
                next: confirmed => {
                    if (confirmed) {
                        this.core.switchNetwork(network._id).subscribe({
                            next: async () => {
                                await this.router.navigate(['/feed/', this.post._id]);
                            },
                        });
                    }
                },
            });
    }
}
