/** @format */

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { Activity, Company, Discussion, Notification, Phase } from '@app/models';
import { ApiNotificationService } from 'app/_services/notification.service';
import { CoreService } from 'app/_services/core.service';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { FeedPostDialogComponent } from '../../core/feed-post-dialog/feed-post-dialog.component';
import { JoinNetworkDialogComponent } from '../../core/join-network-dialog/join-network-dialog.component';
import { PeopleService } from 'app/people/people.service';
import { PermissionService } from 'app/_services/permission.service';
import { RPCService } from 'app/_services/rpc.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { StylingHelperService } from 'app/_services/styling-helper.service';
import { SupportedNotificationTypes } from 'app/_models/supportedNotificationTypes.model';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';
import { environment } from '@app/env';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class NotificationComponent implements OnInit, OnDestroy {
    @Input() notification: Notification;

    phase: Phase;
    network: Company;
    phaseName = '';
    fieldName = '';
    activityName = '';
    isKnownNotificationType = false;

    private hailerUrl: string = environment.wsUrl;
    private onDestroy = new Subject<void>();

    constructor(
        public stylingHelperService: StylingHelperService,
        private permissions: PermissionService,
        private notificationService: ApiNotificationService,
        private peopleService: PeopleService,
        private matDialog: MatDialog,
        private core: CoreService,
        private dialogHelperService: DialogHelperService,
        private rpc: RPCService,
        private sideNavService: SideNavService,
        private translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        if (this.notification.type === 'hailer.invite') {
            this.network = this.notification.meta.company;
            /* TODO add if statement to check if the invite has been accepted, which then updates the info i.e. color */
        } else {
            this.network = this.core.networks.value[this.notification.cid];
        }

        if (!this.notification.meta) {
            console.error('Notification without meta?', this.notification);
            return;
        }

        const activity = this.notification.meta.activity;
        const processId = (activity && activity.process) || this.notification.meta.process_id;
        const phaseId = (activity && activity.currentPhase) || this.notification.meta.phase_id;
        const fieldId = this.notification.meta.field;

        if (SupportedNotificationTypes[this.notification.type]) {
            this.isKnownNotificationType = true;
        } else {
            console.error('Unsupported notification:', this.notification.type);
        }

        this.activityName = activity?.name;

        if (!processId || !phaseId) {
            return;
        }

        const process = this.core.processById(processId);

        if (process) {
            this.phaseName = process.phases[phaseId]?.name;
            this.fieldName = process.fields[fieldId]?.label ?? '[unknown or deleted field]';
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    getUserDisplayName(uid: string): string {
        const user = this.peopleService.getUser(uid);
        return `${user.firstname} ${user.lastname}`;
    }

    notificationClick(notification: Notification) {
        if (
            this.core.network.value._id !== notification.cid &&
            notification.type !== 'hailer.invite' &&
            notification.type !== 'messenger.user_mentioned'
        ) {
            const confirm = this.translocoService.translate('shared.notification.switch');
            const content = `${this.translocoService.translate('shared.notification.notification_viewable_in')}${
                this.core.networks.value[notification.cid].name
            }.`;
            const title = this.translocoService.translate('shared.notification.switch_workspace');
            const confirmColor = 'primary';

            this.dialogHelperService
                .showConfirm(confirm, content, title, confirmColor)
                .pipe(
                    filter(confirmed => !!confirmed),
                    switchMap(() => this.core.switchNetwork(notification.cid))
                )
                .subscribe();
        } else {
            switch (notification.type) {
                case 'activity.created':
                case 'activity.moved':
                case 'activity.update':
                case 'activity.completed':
                case 'activity.assigned':
                case 'activity.remind':
                    this.notificationService.notificationActivity(notification.meta.activity, this.permissions.isNetworkGuest);
                    break;
                case 'activities.moved':
                case 'activities.update':
                case 'activities.completed':
                    this.notificationService.notificationPhase(notification.meta.process_id, notification.meta.phase_id);
                    break;
                case 'wall.comment':
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.data = notification.meta.post_id;
                    dialogConfig.panelClass = 'feed-post-dialog';
                    this.matDialog.open(FeedPostDialogComponent, dialogConfig);
                    break;
                case 'event.invitation':
                    this.notificationService.notificationEvent(notification.meta._id);
                    break;
                case 'hailer.invite':
                    this.openInviteDialog(notification.meta);
                    break;
                case 'hailer.verifyEmail':
                    this.resendEmail()
                        .pipe(takeUntil(this.onDestroy))
                        .subscribe({
                            next: () => (notification.clicked = true),
                            error: error => console.error('Error happened while sending out email', error),
                        });
                    break;
                case 'messenger.user_mentioned':
                    this.notificationService.notificationMessenger(notification.meta.discussion._id);
                    break;
                case 'feed.user_mentioned':
                    this.notificationService.notificationFeed(notification.meta.post._id);
                    break;
                case 'company.invite_accepted':
                    this.openUserDetails(notification.meta.user._id);
                    break;
                default:
                    console.error('invalid type');
                    break;
            }

            if (!notification.clicked && notification.type !== 'hailer.verifyEmail') {
                this.notificationService.markNotificationAsRead(notification._id).subscribe({
                    next: () => this.notificationService.getUnreadNotificationCount(),
                    error: (err: any) => console.error('error marking notification as read ', err),
                });
                notification.clicked = true;
            }
        }
    }

    /**
     * Returns given discussion's subject or string 'private chat'
     */
    getDiscussionSubject(discussion: Discussion): string {
        try {
            if (!discussion) {
                return '';
            }
            if (discussion.private) {
                return 'private chat';
            }
            return discussion.subject;
        } catch (error) {
            if (localStorage.getItem('debug')) {
                console.warn("Couldn't get discussion", error);
            }
            return '';
        }
    }

    /**
     * Returns given posts subject
     */
    getPostSubject(post: any): string {
        return post.subject;
    }

    generateReminderMessage(message: string) {
        const regex = /\(\([a-zA-Z\-\n]+\)\)/;
        let match;
        while ((match = regex.exec(message)) !== null) {
            // Replacing variables with dynamic values
            message = message.replace(match[0], () => {
                switch (match[0]) {
                    case '((field-name))':
                        return this.fieldName;
                    case '((activity-name))':
                        return this.activityName;
                    case '((relative-time))':
                        // TODO: Add translations once Transloco is merged
                        return moment(this.notification.meta.fieldTime).calendar({
                            sameDay: '[Today] [at] HH:mm',
                            nextDay: '[Tomorrow] [at] HH:mm',
                            nextWeek: '[Next] dddd [at] HH:mm',
                            lastDay: '[Yesterday] [at] HH:mm',
                            lastWeek: '[Last] dddd [at] HH:mm',
                            sameElse: 'DD/MM/YYYY HH:mm',
                        });
                    default:
                        return '[unknown variable]';
                }
            });
        }
        return message;
    }

    markAsRead() {
        this.notification.clicked = true;
    }

    private resendEmail(): Observable<any> {
        return this.rpc.request('v2.user.send_verification_email', []);
    }

    private openInviteDialog(notificationMeta: any) {
        const dialogData = new MatDialogConfig();
        dialogData.data = notificationMeta;
        dialogData.panelClass = 'join-network-dialog';
        this.matDialog.open(JoinNetworkDialogComponent, dialogData);
    }

    private openUserDetails(userId: string) {
        this.sideNavService.create(UserDetailComponent, {
            userId,
        });
    }
}
