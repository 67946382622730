/** @format */

import { Injectable } from '@angular/core';

import { CoreService } from './core.service';
import { LicenseLimit, LicenseLimits } from '../../../test/deps/hailer-api/shared/license-limitations.model';

@Injectable({
    providedIn: 'root',
})
export class LicenseService {
    constructor(private core: CoreService) {}

    hasIsolatedTeams(): boolean {
        return this.override.isolatedTeams ?? this.default.isolatedTeams;
    }

    isPublic(): boolean {
        return this.override.public ?? this.default.public;
    }

    maxUsers(): number {
        return this.override.users ?? this.default.users;
    }

    maxModules(): number {
        return this.override.modules ?? this.default.modules;
    }

    maxActivities(): number {
        return this.override.activities ?? this.default.activities;
    }

    maxCalendars(): number {
        return this.override.calendars ?? this.default.calendars;
    }

    hasFileTagging(): boolean {
        return this.override.filesTagging ?? this.default.filesTagging;
    }

    get hasGroups(): boolean {
        return this.override.groups ?? this.default.groups;
    }

    hasInsight(): boolean {
        return this.override.insight ?? this.default.insight;
    }

    hasWebhooks(): boolean {
        return this.type === 'enterprise';
    }

    hasCustomRoles(): boolean {
        return this.override.customRoles ?? this.default.customRoles;
    }

    isRestricted(): boolean {
        return this.override.restricted ?? this.default.restricted;
    }

    get type(): string {
        return this.core.network.value.license?.type || 'free';
    }

    private get default(): LicenseLimit {
        const limits = new LicenseLimits();
        return limits[this.type] || limits.free;
    }

    private get override(): LicenseLimit {
        return this.core.network.value.license?.override || {};
    }
}
