<div class="modal-header flex-row--space-between">
    <h1 mat-dialog-title>{{calendar ? ('events.components.edit_calendar.Calendar_settings' | transloco) : ('events.components.edit_calendar.new_calendar' | transloco)}}</h1>
    <div class="pull-right">
        <button mat-icon-button (click)="closeModal()">
            <mat-icon style="color: white;" aria-label="Close update calendar">clear</mat-icon>
        </button>
    </div>
</div>
<div mat-dialog-content class="editCalendarForm">
    <div style="display: flex; flex-direction: column">
        <div class="calendar-top-settings">
            <div class="calendar-settings">
                <form [formGroup]="editCalendarForm" class="calendar-settings-form">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'events.components.edit_calendar.name' | transloco }}</mat-label>
                        <input data-cy="calendar-name-input" matInput formControlName="name">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'events.components.edit_calendar.description' | transloco }}</mat-label>
                        <textarea matInput placeholder= "{{'events.components.edit_calendar.calendar_description_placeholder' | transloco }}" formControlName="description"></textarea>
                    </mat-form-field>
                    <div>
                        <mat-form-field appearance="outline" class="width-40">
                            <mat-label>{{ 'events.components.edit_calendar.color' | transloco }}</mat-label>
                            <input matInput mccColorPickerOrigin #trigger="mccColorPickerOrigin" formControlName="color" />
                        </mat-form-field>
                        <mcc-color-picker mccConnectedColorPicker
                        btnCancelLabel="{{'shared.mcc-color-picker.btn_cancel_label' | transloco}}"
                        btnConfirmLabel="{{'shared.mcc-color-picker.btn_confirm_label' | transloco}}"
                        usedColorLabel="{{'shared.mcc-color-picker.used_color_label' | transloco}}"
                        [hideTransparentUsedColors]="true" [mccConnectedColorPickerOrigin]="trigger">
                        </mcc-color-picker>
                    </div>

                    <app-dropdown-selector data-cy="calendar-timezone-selector" [selectedItems]="timezoneSelectorSelectedItems" [items]="timezoneSelectorItems"
                        [options]="timezoneSelectorOptions">
                    </app-dropdown-selector>
                </form>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="permissions">
                <div>
                    <ng-container *ngIf="calendar; else createCalendarPermissionSelector">
                        <app-permission-selector data-cy="calendar-editor-permission-drop-down" [members]="members" [permissionTypes]="calendarPermissionTypes"
                        (memberAdd)="eventsService.addCalendarMember(calendar?._id, $event); cdr.detectChanges()"
                        (memberRemove)="eventsService.removeCalendarMember(calendar?._id, $event); cdr.detectChanges()"
                        (permissionDeny)="eventsService.denyCalendarPermission(calendar?._id, $event.memberId, $event.permission); cdr.detectChanges()"
                        (permissionGrant)="eventsService.grantCalendarPermission(calendar?._id, $event.memberId, $event.permission); cdr.detectChanges()">
                        </app-permission-selector>
                    </ng-container>
                    <ng-template #createCalendarPermissionSelector>
                        <app-permission-selector [members]="calendarMembers" [permissionTypes]="calendarPermissionTypes"
                        (memberAdd)="addMember($event); cdr.detectChanges()"
                        (memberRemove)="removeMember($event); cdr.detectChanges()"
                        (permissionDeny)="removePermission($event.memberId, $event.permission); cdr.detectChanges()"
                        (permissionGrant)="addPermission($event.memberId, $event.permission); cdr.detectChanges()">
                        </app-permission-selector>
                    </ng-template>
                <div class="edit-options">
                    <button *ngIf="(permissions.isCalendarAdmin(user.id, calendar?._id) || calendar?.uid === user.id) && calendar" class="remove-button" type="button"
                        (click)="removeCalendar()" mat-stroked-button color="warn">{{ 'events.components.edit_calendar.delete_button' | transloco }}</button>
                    <button data-cy="save-calendar-button" class="update-button" type="button" (click)="calendar ? updateCalendar() : saveCalendar()" mat-stroked-button [disabled]="!editCalendarForm.valid"
                        color="accent">{{ 'events.components.edit_calendar.save_button' | transloco }}</button>
                </div>
            </div>
        </div>

    </div>
</div>