/** @format */

export function stripUndefined<T>(doc: T): T {
    return JSON.parse(JSON.stringify(doc));
}

export const hexColorRemoveAlpha = (color: string): string => {
    if (color === 'transparent') {
        return color;
    }
    const hasAlphaValue = color.length > 7;
    return hasAlphaValue ? color.slice(0, color.length - 2) : color;
};

/** Persistent capped log in LocalStorage */
export class PersistentLog {
    constructor(
        /** Log name */
        private name: string
    ) {}

    read() {
        let log: string[] = [];

        try {
            const updateLog = localStorage.getItem(this.name);
            log = JSON.parse(updateLog);

            if (!Array.isArray(log)) {
                log = [];
            }
        } catch {
            log = [];
        }

        return log;
    }

    write(row: string) {
        let log = this.read();

        log.push(`${new Date().toISOString()} ${row}`);

        if (log.length > 100) {
            log = log.slice(-100);
        }

        localStorage.setItem(this.name, JSON.stringify(log));
    }
}
