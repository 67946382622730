<!-- @format -->

<div class="dialog-header">
    <h1 data-cy="create-new-insight-header" mat-dialog-title>{{ insight ? 'Edit Insight' : 'Create a new Insight' }}</h1>
    <app-button
        dataCy="close-activity-button"
        type="icon"
        hailerIconName="hailer-x"
        color="icon-white-all-themes"
        tippyText="Close"
        ariaLabel="Close popup window"
        (clickEvent)="close()"></app-button>
</div>

<div style="height: 100%; overflow-y: scroll">
    <form [formGroup]="insightForm">
        <div class="top-line">
            <div class="top-line-item-wrapper">
                <mat-form-field color="accent" floatLabel="always" appearance="outline" class="form-field full-width">
                    <mat-label>Insight name</mat-label>
                    <input
                        data-cy="insight-name-input"
                        matInput
                        cdkFocusInitial
                        placeholder="Insight name"
                        formControlName="name"
                        (keydown.enter)="$event.preventDefault()" />
                    <mat-error data-cy="insight-name-field-error" *ngIf="insightForm.get('name')?.hasError('required')">Name is required</mat-error>
                </mat-form-field>
            </div>
            <!-- TODO: Save button should be disabled also when no changes have been made -->
            <div class="popup-two-buttons-wrapper top-line-item-wrapper">
                <app-button
                    type="text"
                    color="tertiary-highlight-background"
                    label="Cancel"
                    ariaLabel="Cancel"
                    (clickEvent)="close()"
                    [dataCy]="'insight-cancel-button'"
                    width="popup-two-buttons"></app-button>
                <app-button
                    type="text-with-icon"
                    [label]="insight ? 'Save changes' : 'Save'"
                    ariaLabel="Save"
                    hailerIconName="hailer-tick"
                    [dataCy]="'insight-save-button'"
                    (clickEvent)="save(insightForm.value)"
                    [isDisabled]="!insightForm.valid"
                    width="popup-two-buttons"></app-button>
            </div>
        </div>

        <div class="flex-row--space-around-wrap">
            <div data-cy="insight-tables-block" class="card sources-card">
                <div class="flex-row--space-between-centered card-header">
                    <h4>
                        Tables
                        <mat-icon
                            svgIcon="hailer-tooltip"
                            class="tooltip-icon-small clickable"
                            [satPopoverAnchor]="descriptionPopover"
                            (click)="descriptionPopover.toggle(); $event.stopPropagation()">
                        </mat-icon>
                        <sat-popover hasBackdrop #descriptionPopover verticalAlign="below">
                            <div class="popover-container mat-elevation-z8">
                                Workflows and datasets are translated into tables in SQLite
                            </div>
                        </sat-popover>
                    </h4>
                    <app-button
                        type="icon"
                        hailerIconName="hailer-plus"
                        color="icon-success"
                        (clickEvent)="openAddSourcesPopup()"
                        tippyText="Add tables"
                        ariaLabel="Add tables"></app-button>
                </div>

                <div class="workflows-wrapper">
                    <div *ngIf="(selectedSources | async)?.length === 0" class="empty-state-wrapper">
                        <p data-cy="insight-tables-block-empty-state-text" class="empty-state-text">
                            Start building your Insight by selecting tables <br />
                            and columns for the tables
                        </p>

                        <app-button
                            type="text-with-icon"
                            hailerIconName="hailer-plus"
                            label="Add tables"
                            [dataCy]="'add-tables-button'"
                            ariaLabel="Add tables"
                            (clickEvent)="openAddSourcesPopup()"></app-button>
                    </div>

                    <div class="workflow-card" *ngFor="let dep of selectedSources | async; let last = last">
                        <div class="flex-row--space-between-centered padding-left-small">
                            <p data-cy="table-name-label" class="bold">{{ available[dep.workflowId]?.name }}</p>
                            <app-button
                                type="icon"
                                color="icon-destructive"
                                (clickEvent)="removeWorkflow(dep)"
                                hailerIconName="hailer-x"
                                [dataCy]="'remove-table-button'"
                                tippyText="Remove table"
                                ariaLabel="Remove table"></app-button>
                        </div>

                        <mat-form-field color="accent" floatLabel="always" appearance="outline" class="form-field highlight-bg full-width">
                            <mat-label>Table name</mat-label>
                            <input
                                data-cy="table-name-input"
                                matInput
                                type="text"
                                [value]="dep.name"
                                name="dependencyName"
                                required
                                (keydown.enter)="$event.preventDefault()"
                                (keyup.enter)="setWorkflowVariable(dep, $event.target.value)"
                                (blur)="setWorkflowVariable(dep, $any($event.target)?.value)" />
                            <mat-hint *ngIf="isBadDependency(dep.name)" class="warn"
                                >Name does not match the required pattern: /^[a-zà-öA-ZÀ-Ö_][a-zà-öA-ZÀ-Ö0-9_]*$/</mat-hint
                            >
                        </mat-form-field>

                        <div class="field-group">
                            <span data-cy="table-column-header" *ngIf="dep.fields.length > 0" class="field-name header">Columns:</span>
                            <div *ngIf="dep.fields.length === 0" class="note-container no-margin-top">
                                <p data-cy="columns-error-tables-block" class="note-content increased-font-size">Columns need to be added to the table before writing a query</p>
                            </div>
                            <span data-cy="table-column-name" *ngFor="let field of dep.fields" class="field-name">
                                {{ field.name }}
                                <mat-icon
                                    svgIcon="hailer-x"
                                    class="small clickable"
                                    (click)="removeField(dep.workflowId, field)"></mat-icon>
                            </span>
                        </div>
                        <mat-divider *ngIf="!last" class="workflow-divider"></mat-divider>
                    </div>
                </div>
            </div>

            <div data-cy="insight-query-block" class="card query-card">
                <div class="flex-row--space-between-centered card-header">
                    <h4>Query</h4>
                </div>

                <div class="query-editor">
                    <ngx-monaco-editor formControlName="query" [options]="editorOptions"></ngx-monaco-editor>
                </div>
            </div>
        </div>

        <div data-cy="insight-preview-block" class="card preview-card">
            <div class="flex-row--space-between-centered card-header">
                <h4>Preview</h4>
            </div>
            <ng-container *ngIf="!previewError && !previewData">
                <div class="empty-state-wrapper">
                    <p class="empty-state-text">Preview of the Insight will be shown here</p>
                </div>
            </ng-container>

            <ng-container *ngIf="previewError">
                <div data-cy="preview-unavailable-error" class="empty-state-wrapper note-container">
                    <p class="empty-state-text no-margin">Preview is unavailable due to:</p>
                    <span class="bold">{{ previewError }}</span>
                </div>
            </ng-container>
            <ng-container *ngIf="previewData">
                <div data-cy="preview-insight-grid" class="insight" id="insight-preview"></div>
            </ng-container>
        </div>

        <div *ngIf="showAddSourcesPopup" class="insight-editor-overlay">
            <div class="card overlay-card">
                <div class="card-header flex-row--space-between-centered insight-editor-overlay-header">
                    <h4>Add tables</h4>

                    <app-button
                        type="icon"
                        color="tertiary-default-background"
                        size="small"
                        [dataCy]="'close-button-add-tables-pop-up'"
                        hailerIconName="hailer-x"
                        (clickEvent)="closeAddSourcesPopup()"
                        tippyText="Close"
                        ariaLabel="Close popup window"></app-button>
                </div>

                <div class="sources-wrapper">
                    <mat-form-field color="accent" appearance="outline" class="form-field search-field highlight-bg full-width">
                        <mat-icon matPrefix svgIcon="hailer-search" class="dimmed-prefix"></mat-icon>
                        <input
                            data-cy="add-tables-search-field"
                            type="text"
                            [formControl]="sourceFilter"
                            matInput
                            autocomplete="off"
                            placeholder="Search..."
                            (keydown.enter)="$event.preventDefault()" />
                        <button matSuffix mat-icon-button aria-label="Clear search" *ngIf="sourceFilter.value" (click)="clearSearch()">
                            <mat-icon svgIcon="hailer-x"></mat-icon>
                        </button>
                    </mat-form-field>

                    <div>
                        <div data-cy="table-option-in-the-list"
                            mat-menu-item
                            *ngFor="let workflow of sourceFilter.value ? filteredSources : availableSources"
                            (click)="addWorkflow(workflow)"
                            [matMenuTriggerFor]="fieldsMenu"
                            [class.bold]="sourceSelected(workflow)">
                            <div class="selection-tick" *ngIf="sourceSelected(workflow)">
                                <mat-icon
                                    (click)="removeWorkflow(workflow); $event.stopPropagation()"
                                    svgIcon="hailer-tick"
                                    class="success"></mat-icon>
                                <mat-icon
                                    (click)="removeWorkflow(workflow); $event.stopPropagation()"
                                    svgIcon="hailer-x"
                                    class="warn"></mat-icon>
                            </div>

                            {{ workflow.name }}

                            <mat-menu #fieldsMenu="matMenu" yPosition="below" xPosition="before">
                                <button mat-menu-item disabled="true">--- Metadata ---</button>
                                <button data-cy="metadata-option-in-the-list"
                                    mat-menu-item
                                    *ngFor="let field of metaFields; trackBy: trackByMeta"
                                    (click)="toggleField(workflow.workflowId, field); $event.stopPropagation()"
                                    [class.bold]="isFieldSelected(workflow.workflowId, field)">
                                    <div class="selection-tick" *ngIf="isFieldSelected(workflow.workflowId, field)">
                                        <mat-icon
                                            (click)="removeField(workflow.workflowId, field); $event.stopPropagation()"
                                            svgIcon="hailer-tick"
                                            class="success"></mat-icon>
                                        <mat-icon
                                            (click)="removeField(workflow.workflowId, field); $event.stopPropagation()"
                                            svgIcon="hailer-x"
                                            class="warn"></mat-icon>
                                    </div>
                                    {{ field.name }}
                                </button>

                                <ng-container *ngIf="hasWorkflowFields(workflow.fields)">
                                    <button mat-menu-item disabled="true">--- Custom fields ---</button>
                                    <button data-cy="custom-fields-option-in-the-list"
                                        mat-menu-item
                                        *ngFor="let field of getWorkflowFields(workflow.fields); trackBy: trackByFieldId"
                                        (click)="toggleField(workflow.workflowId, field); $event.stopPropagation()"
                                        [class.bold]="isFieldSelected(workflow.workflowId, field)">
                                        <div class="selection-tick" *ngIf="isFieldSelected(workflow.workflowId, field)">
                                            <mat-icon
                                                (click)="removeField(workflow.workflowId, field); $event.stopPropagation()"
                                                svgIcon="hailer-tick"
                                                class="success"></mat-icon>
                                            <mat-icon
                                                (click)="removeField(workflow.workflowId, field); $event.stopPropagation()"
                                                svgIcon="hailer-x"
                                                class="warn"></mat-icon>
                                        </div>
                                        {{ field.name }}
                                    </button>
                                </ng-container>

                                <ng-container *ngIf="hasPhaseMovedFields(workflow.fields)">
                                    <button mat-menu-item disabled="true">--- Phase/category changes ---</button>
                                    <button data-cy="phase-category-changes-option-in-the-list"
                                        mat-menu-item
                                        *ngFor="let field of getPhaseMovedFields(workflow.fields); trackBy: trackByPhaseId"
                                        (click)="toggleField(workflow.workflowId, field); $event.stopPropagation()"
                                        [class.bold]="isFieldSelected(workflow.workflowId, field)">
                                        <div class="selection-tick" *ngIf="isFieldSelected(workflow.workflowId, field)">
                                            <mat-icon
                                                (click)="removeField(workflow.workflowId, field); $event.stopPropagation()"
                                                svgIcon="hailer-tick"
                                                class="success"></mat-icon>
                                            <mat-icon
                                                (click)="removeField(workflow.workflowId, field); $event.stopPropagation()"
                                                svgIcon="hailer-x"
                                                class="warn"></mat-icon>
                                        </div>
                                        {{ field.name }}
                                    </button>
                                </ng-container>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <!--
<div>Valid: {{ insightForm.valid }}</div>
<div>Dirty: {{ insightForm.dirty }}</div>
<div>Pristine: {{ insightForm.pristine }}</div>
-->

    <!--
<div>
    Available sources
    <pre>{{ available | json }}</pre>
</div>

<div>
    Form
    <pre>{{ insightForm.getRawValue() | json }}</pre>
</div>

<div>
    Insight
    <pre>{{ insight | json }}</pre>
</div>
--></div>
