/** @format */

import { Company, PersonalSettings, Process } from '@app/models';
import { Server } from '@wishcore/wish-rpc';
import { App, AppConfig } from 'app/_models/app.model';
import { AppService } from 'app/_services/app.service';
import { CoreService } from 'app/_services/core.service';
import { RPCService } from 'app/_services/rpc.service';
import { InsightDoc, InsightService } from '../insight.service';
import { V3ActivitySidenavComponent } from 'app/v3-activity/v3-activity-sidenav/v3-activity-sidenav.component';
import { Subject, takeUntil } from 'rxjs';
import { UserThemes } from 'app/_models/user-theme.type';
import { SideNavService } from 'app/_services/side-nav.service';
import { MatDialog } from '@angular/material/dialog';
import { InsightEditorComponent } from '../insight-editor/insight-editor.component';
import { ConfirmDialogComponent } from 'app/_dialogs/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilesService } from 'app/_services/files.service';
import { base64ToFile } from 'ngx-image-cropper';
import { InsightPermissionComponent } from '../insight-permission/insight-permission.component';

// NOTE: these may change still
const hailerSignals = {
    activity: {
        create: 'activity.create',
        import: 'activity.import',
        remove: 'activity.remove',
        update: 'activity.update',
    },
    process: {
        create: 'process.create',
        remove: 'process.remove',
        update: 'process.update',
    },
    user: {
        create: 'user.create',
    },
    workspace: {
        invitation: {
            new: 'workspace.invitation.new',
            remove: 'workspace.invitation.remove',
        },
        role: {
            create: 'workspace.role.create',
            update: 'workspace.role.update',
            remove: 'workspace.role.remove',
        },
        reload: 'workspace.reload',
    },
    feed: {
        post: {
            new: 'feed.post.new',
        },
    },
    app: {
        refresh: 'app.refresh',
    },
    insight: {
        create: 'insight.create',
        update: 'insight.update',
        remove: 'insight.remove',
    },
};

interface Signal {
    sig: string;
    meta: any;
}

interface SignalsSubscriber {
    res: { emit: (signal: Signal) => boolean };
    filter: string[];
}

export class AppApiV1 {
    currentNetworkId: string | undefined;
    userFilter = ['_id', 'firstname', 'lastname', 'lastSeen', 'status', 'default_profilepic'];
    signalsSubscriber: SignalsSubscriber | undefined;

    constructor(
        private app: App,
        private core: CoreService,
        private server: Server,
        private rpc: RPCService,
        private appService: AppService,
        private insightService: InsightService,
        private snackbar: MatSnackBar,
        private files: FilesService,
        private aside: SideNavService,
        private dialog: MatDialog,
        private destroy: Subject<void>
    ) {
        this.currentNetworkId = this.core.network.value?._id || undefined;

        // If signals are wanted in the app, whitelist these signals
        const signalWhitelist = new Set<string>([
            hailerSignals.activity.create,
            hailerSignals.activity.import,
            hailerSignals.activity.update,
            hailerSignals.activity.remove,
            hailerSignals.app.refresh,
            hailerSignals.insight.create,
            hailerSignals.insight.update,
            hailerSignals.insight.remove,
        ]);

        // Subscribe to signals in the frontend, to be able to pass them into the app
        this.rpc.signals.pipe(takeUntil(this.destroy)).subscribe({
            next: signal => {
                if (!this.signalsSubscriber) {
                    return;
                }

                const signalName = this.convertSignal(signal.sig);

                if (!signalWhitelist.has(signalName)) {
                    return;
                }

                const filter = this.signalsSubscriber.filter;

                // If filter exists but is empty array, pass all signals to the app
                if (filter.includes(signalName) || !filter.length) {
                    this.signalsSubscriber.res.emit({ ...signal, sig: signalName });
                }
            },
        });

        // Detect when workspace is changed, and signal the app
        this.core.network.pipe(takeUntil(this.destroy)).subscribe({
            next: (workspace: Company) => {
                this.signalsSubscriber?.res.emit({ sig: 'workspace.current', meta: { workspaceId: workspace._id } });
            },
        });

        this.registerMethods();
    }

    /**
     * Converts old hailer api style signals to endpoint name style new form
     *
     * @param oldSignal old form signal such as 'activities.created'
     * @returns the signal in new form or oldSignal as provided if it doesn't match any old values
     */
    convertSignal(oldSignal: string): string {
        switch (oldSignal) {
            case 'activities.created':
                return hailerSignals.activity.create;
            case 'activities.updated':
                return hailerSignals.activity.update;
            case 'activities.removed':
                return hailerSignals.activity.remove;
            case 'company.new_invitation':
                return hailerSignals.workspace.invitation.new;
            case 'company.reload':
                return hailerSignals.workspace.reload;
            case 'company.remove_invitation':
                return hailerSignals.workspace.invitation.remove;
            case 'wall2.new_post':
                return hailerSignals.feed.post.new;
            case 'insight.removed':
                return hailerSignals.insight.remove;
            case 'workspace.role.updated':
                return hailerSignals.workspace.role.update;
            case 'workspace.role.created':
                return hailerSignals.workspace.role.create;
            case 'workspace.role.removed':
                return hailerSignals.workspace.role.remove;
            default:
                return oldSignal;
        }
    }

    /** Subscribe to changes in Hailer user settings */
    private settingsSubscribe(apiVersion: number): void {
        // Send settings to app if user changes them
        this.core.user.pipe(takeUntil(this.destroy)).subscribe(user => {
            this.server.emit(`v${apiVersion}.ui.settings`, this.getSettingsFromUser(user));
        });
    }

    private getSettingsFromUser(user: PersonalSettings): { theme?: UserThemes; preferredLanguages: string[] } {
        const theme = user.globalSettings?.theme;
        return { theme, preferredLanguages: user.preferredLanguages };
    }

    /** Returns object with only fields specified in keys, also turns snake_case keys to camelCase */
    private filterObject(object: any, keys: string[]): any {
        return Object.fromEntries(
            Object.entries(object)
                .filter(([key]) => keys.includes(key))
                .map(([key, value]) => [
                    key === '_id' ? key : key.replace(/([_][a-z])/g, string => string.toUpperCase().replace('_', '')),
                    value,
                ])
        );
    }

    /**
     * Prepare field values for sidenav
     *
     * TODO: this might be moved to sidenav or its service at some point
     *
     * Sidenav wants the field values to be in a certain format, while SDK wants to allow a cleaner
     * more simple way of providing the values. This requires conversion, and for activity links even
     * pulling activity names from the backend. This function is a workaround which does that.
     *
     * We want to call with fields:
     *
     * ```ts
     * { [fieldId: string]: activityId }
     * ```
     *
     * while sidenav expects:
     *
     * ```ts
     * { [fieldId: string]: { value: { name: 'Activity Name', _id: activityId } } }
     * ```
     */
    private async convertFieldValuesForSideNav(
        workflowId: string,
        fields: { [fieldId: string]: string }
    ): Promise<{ [fieldId: string]: { value: any } }> {
        const activityLinkFieldMap: { [fieldId: string]: true } = {};

        const workflow = this.core.processById(workflowId);

        if (!workflow) {
            return {};
        }

        for (const [fieldId, field] of Object.entries(workflow.fields)) {
            if (field.type === 'activitylink') {
                activityLinkFieldMap[fieldId] = true;
            }
        }

        const missingActivityNames: { fieldId: string; activityId: string }[] = [];

        Object.entries(fields ?? {}).forEach(([fieldId, activityId]) => {
            missingActivityNames.push({ fieldId, activityId });
        });

        const activityMap: { [activityId: string]: { name: string } } = {};

        // Fetch activity name to fill it in when linked activities are set (this will fetch the same activiy twice if it is linked in separate fields, could be improved)
        await Promise.allSettled(
            missingActivityNames.map(async ({ fieldId, activityId }) => {
                activityMap[activityId] = (await this.rpc.requestAsync('activities.load', [activityId])) as { name: string };
            })
        );

        const initFieldValues = Object.entries(fields ?? {}).reduce((map, [fieldId, fieldValue]) => {
            const isActivityLink = this.core.processById(workflowId).fields[fieldId]?.type === 'activitylink';

            if (isActivityLink) {
                const activityId = fieldValue;

                map[fieldId] = {
                    value: { name: activityMap[activityId]?.name, _id: activityId },
                };
            } else {
                map[fieldId] = {
                    value: fieldValue,
                };
            }
            return map;
        }, {});

        return initFieldValues;
    }

    registerMethods(): void {
        this.server.insertMethods({
            _v1: {},
            v1: {
                _app: {},
                app: {
                    _config: {},
                    config: {
                        _update: {},
                        update: async (req, res): Promise<void> => {
                            const appId = req.args[0] || this.app._id;
                            const config: AppConfig = req.args[1];

                            if (appId !== this.app._id) {
                                return res.error({ msg: 'Only current app config can be modified', code: 7 });
                            }

                            const configMap = {};

                            for (const [key, value] of Object.entries(config.fields)) {
                                configMap[key] = value.value;
                            }

                            try {
                                await this.appService.updateConfig(appId, configMap);
                                res.send(true);
                            } catch (error) {
                                res.error(error);
                            }
                        },
                    },
                    _product: {},
                    product: {
                        _isProductInstalled: {},
                        isProductInstalled: async (req, res) => {
                            try {
                                const isProductInstalled = await this.rpc.requestAsync('v3.app.product.isProductInstalled', req.args);
                                return res.send(isProductInstalled);
                            } catch (error) {
                                return res.error(error);
                            }
                        },
                        _get: {},
                        get: async (req, res) => {
                            try {
                                // Get version from AppProduct
                                const response = await this.rpc.requestAsync('v3.app.product.get', req.args);
                                return res.send(response);
                            } catch (error) {
                                return res.error(error);
                            }
                        },
                        _install: {},
                        install: async (req, res) => {
                            try {
                                // Install app in current workspace
                                const response = await this.rpc.requestAsync('v3.app.product.install', req.args);
                                return res.send(response);
                            } catch (error) {
                                return res.error(error);
                            }
                        },
                        _getInstalledVersion: {},
                        getInstalledVersion: async (req, res) => {
                            try {
                                // Get installed version
                                const response = await this.rpc.requestAsync('v3.app.product.getInstalledVersion', req.args);
                                return res.send(response);
                            } catch (error) {
                                return res.error(error);
                            }
                        },
                        _getManifest: {},
                        getManifest: async (req, res) => {
                            const versionId = req.args[0];

                            try {
                                const manifest = await this.rpc.requestAsync('v3.app.product.getManifest', [versionId]);
                                return res.send(manifest);
                            } catch (error) {
                                return res.error(error);
                            }
                        },
                    },
                },
                _activity: {},
                activity: {
                    _create: {},
                    create: (req, res) => {
                        this.rpc
                            .requestAsync('v3.activity.createMany', req.args)
                            .then(response => res.send(response))
                            .catch(error => res.error(error));
                    },
                    _update: {},
                    update: (req, res) => {
                        this.rpc
                            .requestAsync('v3.activity.updateMany', req.args)
                            .then(response => res.send(response))
                            .catch(error => res.error(error));
                    },
                    _get: {},
                    get: async (req, res) => {
                        const activityId = req.args[0];

                        try {
                            const activity = await this.rpc.requestAsync('activities.load', [activityId]);
                            return res.send(activity);
                        } catch (error) {
                            return res.error(error);
                        }
                    },
                    _list: {},
                    list: (req, res) => {
                        this.rpc
                            .requestAsync('v3.activity.list', [{ processId: req.args[0], phaseId: req.args[1] }, req.args[2] ?? {}])
                            .then(response => res.send(response))
                            .catch(error => {
                                console.log('Error calling v3.activity.list:', error, error.msg, error.details);
                                res.error(error);
                            });
                    },
                    _remove: {},
                    remove: (req, res) => {
                        this.rpc
                            .requestAsync('activities.remove', req.args)
                            .then(response => res.send(response))
                            .catch(error => res.error(error));
                    },
                    _import: {},
                    import: (req, res) => {
                        this.rpc
                            .requestAsync('v3.activity.import', req.args)
                            .then(response => res.send(response))
                            .catch(error => res.error(error));
                    },
                    _kanban: {},
                    kanban: {
                        _list: {},
                        list: (req, res) => {
                            this.rpc
                                .requestAsync('v2.activities.kanban.list', req.args)
                                .then(response => res.send(response))
                                .catch(error => res.error(error));
                        },
                        _load: {},
                        load: (req, res) => {
                            this.rpc
                                .requestAsync('v2.activities.kanban.load', req.args)
                                .then(response => res.send(response))
                                .catch(error => res.error(error));
                        },
                        _updatePriority: {},
                        updatePriority: (req, res) => {
                            this.rpc
                                .requestAsync('v2.activities.updatePriority', req.args)
                                .then(response => res.send(response))
                                .catch(error => res.error(error));
                        },
                    },
                },
                _user: {},
                user: {
                    _list: {},
                    list: (req, res) => {
                        res.send(
                            Object.fromEntries(
                                Object.entries(this.core.users.value)
                                    .filter(([id, user]) => user.companies?.includes(this.currentNetworkId || ''))
                                    .map(([id, user]) => [id, this.filterObject(user, this.userFilter)])
                            )
                        );
                    },
                    _current: {},
                    current: (req, res) => {
                        res.send(this.filterObject(this.core.user.value, this.userFilter));
                    },
                    _get: {},
                    get: (req, res) => {
                        const id = req.args[0];
                        const foundUser = Object.values(this.core.users.value).find(user => user._id === id);

                        if (foundUser?.companies?.includes(this.currentNetworkId || '') ?? false) {
                            const filteredUser = this.filterObject(foundUser, this.userFilter);
                            res.send(filteredUser);
                        } else {
                            res.send(null);
                        }
                    },
                },
                _process: {},
                process: {
                    _list: {},
                    list: (req, res) => {
                        // Only return the processes of the current network
                        res.send(this.core.processes.value[this.app.cid]);
                    },
                    _get: {},
                    get: (req, res) => {
                        const id = req.args[0];
                        res.send(this.core.processes.value[this.app.cid]?.[id]);
                    },
                },
                _workspace: {},
                workspace: {
                    _list: {},
                    list: (req, res) => {
                        if (this.app.isPrivate) {
                            // Personal app
                            const workspaces = Object.values(this.core.networks.value);

                            // Sort the workspaces array ascending by name
                            workspaces.sort((a, b) => a.name.localeCompare(b.name));

                            res.send(workspaces.map(workspace => ({ _id: workspace._id, name: workspace.name })));
                        }

                        // Workspace app
                        const workspace = this.core.network.value;

                        if (!workspace) {
                            res.send([]);
                        } else {
                            res.send([{ _id: workspace._id, name: workspace.name }]);
                        }
                    },
                    _current: {},
                    current: (req, res) => {
                        const workspace = this.core.network.value;

                        if (!workspace) {
                            res.send(null);
                        } else {
                            res.send({ _id: workspace._id, name: workspace.name });
                        }
                    },
                    _product: {},
                    product: {
                        _publishTemplate: {},
                        publishTemplate: async (req, res) => {
                            try {
                                const version = await this.rpc.requestAsync('v2.network.product.publishTemplate', req.args);
                                return res.send(version);
                            } catch (error) {
                                return res.error(error);
                            }
                        },
                        _isProductInstalled: {},
                        isProductInstalled: async (req, res) => {
                            try {
                                const isProductInstalled = await this.rpc.requestAsync('v2.network.product.isProductInstalled', req.args);
                                return res.send(isProductInstalled);
                            } catch (error) {
                                return res.error(error);
                            }
                        },
                        _get: {},
                        get: async (req, res) => {
                            try {
                                // Get version from WorkspaceProduct
                                const response = await this.rpc.requestAsync('v2.network.product.get', req.args);
                                return res.send(response);
                            } catch (error) {
                                return res.error(error);
                            }
                        },
                        _install: {},
                        install: async (req, res) => {
                            try {
                                // Install template in current workspace
                                const response = await this.rpc.requestAsync('v2.network.product.install', req.args);
                                return res.send(response);
                            } catch (error) {
                                return res.error(error);
                            }
                        },
                        _getManifest: {},
                        getManifest: async (req, res) => {
                            const targetId = req.args[0];

                            try {
                                const manifest = await this.rpc.requestAsync('v2.network.product.getManifest', [targetId]);
                                return res.send(manifest);
                            } catch (error) {
                                return res.error(error);
                            }
                        },
                    },
                },
                _workflow: {},
                workflow: {
                    _list: {},
                    list: (req, res) => {
                        if (this.app.isPrivate) {
                            const workflows: Process[] = [];

                            Object.values(this.core.processes.value).forEach(workspaceProcessMap =>
                                workflows.push(...Object.values(workspaceProcessMap))
                            );

                            return res.send(
                                workflows.map(({ _id, name, cid, productInstalledId }) => ({ _id, name, cid, productInstalledId }))
                            );
                        }
                        // Only return the processes of the current network
                        res.send(Object.values(this.core.processes.value[this.app.cid] || {}));
                    },
                    _get: {},
                    get: (req, res) => {
                        const id = req.args[0];

                        if (this.app.isPrivate) {
                            const workflows: Process[] = [];

                            Object.values(this.core.processes.value).forEach(workspaceProcessMap =>
                                workflows.push(...Object.values(workspaceProcessMap))
                            );

                            const requestedProcess = workflows.find(process => process._id === id);
                            res.send(requestedProcess);
                        }

                        res.send(this.core.processes.value[this.app.cid]?.[id]);
                    },
                },
                _insight: {},
                insight: {
                    _data: {},
                    data: async (req, res) => {
                        const insightId = req.args[0];
                        const options = req.args[1] ? [req.args[1]] : [];

                        try {
                            const data = await this.rpc.requestAsync('v3.insight.data', [insightId, ...options]);

                            res.send(data);
                        } catch (error) {
                            console.log('Error in apps RPC', error);
                            res.error(error);
                        }
                    },
                    _list: {},
                    list: async (req, res) => {
                        try {
                            let insights: InsightDoc[] | undefined;

                            if (this.app.isPrivate) {
                                // Personal app
                                insights = await this.rpc.requestAsync('v3.insight.list', [this.core.network.value?._id]);
                            } else {
                                insights = await this.rpc.requestAsync('v3.insight.list', [this.app.cid]);
                            }

                            if (!Array.isArray(insights)) {
                                return res.send([]);
                            }

                            res.send(insights.map(({ _id, name, publicKey, presets }) => ({ _id, name, publicKey, presets })));
                        } catch (error) {
                            console.log('Error in apps RPC', error);
                            res.error(error);
                        }
                    },
                    _update: {},
                    update: async (req, res) => {
                        const insightId = req.args[0];
                        const update = req.args[1];

                        try {
                            const data = await this.rpc.requestAsync('v3.insight.update', [insightId, update]);

                            res.send(data);
                        } catch (error) {
                            console.log('Error in apps RPC', error);
                            res.error(error);
                        }
                    },
                },
                _product: {},
                product: {
                    _create: {},
                    create: (req, res) => {
                        this.rpc
                            .requestAsync('v3.product.create', req.args)
                            .then(response => res.send(response))
                            .catch(error => res.error(error));
                    },
                    _update: {},
                    update: (req, res) => {
                        this.rpc
                            .requestAsync('v3.product.update', req.args)
                            .then(response => res.send(response))
                            .catch(error => res.error(error));
                    },
                    _get: {},
                    get: async (req, res) => {
                        const productId = req.args[0];

                        try {
                            const product = await this.rpc.requestAsync('v3.product.get', [productId]);
                            return res.send(product);
                        } catch (error) {
                            return res.error(error);
                        }
                    },
                    _list: {},
                    list: (req, res) => {
                        console.log('Product list.');
                        this.rpc
                            .requestAsync('v3.product.list', [req.args[0] ?? {}, req.args[1] ?? {}])
                            .then(response => res.send(response))
                            .catch(error => res.error(error));
                    },
                    _remove: {},
                    remove: (req, res) => {
                        this.rpc
                            .requestAsync('v3.product.remove', req.args)
                            .then(response => res.send(response))
                            .catch(error => res.error(error));
                    },
                    _setPublic: {},
                    setPublic: (req, res) => {
                        this.rpc
                            .requestAsync('v3.product.setPublic', [req.args[0], req.args[1]])
                            .then(response => res.send(response))
                            .catch(error => res.error(error));
                    },
                },
                _ui: {},
                ui: {
                    _settings: {},
                    settings: (req, res) => {
                        this.settingsSubscribe(1);
                    },
                    _activity: {},
                    activity: {
                        _open: {},
                        open: (req, res) => {
                            this.aside.create(V3ActivitySidenavComponent, { activityId: req.args[0], initTab: req.args[1]?.tab });

                            res.send();
                        },
                        _create: {},
                        create: async (req, res) => {
                            const workflowId = req.args[0];
                            const options = req.args[1] as { name?: string; fields: { [fieldId: string]: string } } | undefined;

                            const workflow = this.core.processById(workflowId);

                            if (!workflow) {
                                res.error({ msg: 'Workflow not found, or permission denied.', code: 500 });
                                return;
                            }

                            const initFieldValues = await this.convertFieldValuesForSideNav(workflowId, options?.fields || {});

                            if (options?.name) {
                                initFieldValues.nameField = { value: options.name || '' };
                            }

                            console.log('Trying to open create sidenav with for workflowId:', workflowId, 'with options:', initFieldValues);

                            this.aside.create(V3ActivitySidenavComponent, {
                                action: 'create',
                                processId: workflowId,
                                initFieldValues,
                                created: activity => {
                                    this.aside.pop();
                                    res.send(activity);
                                },
                            });
                        },
                        _editMultiple: {},
                        editMultiple: async (req, res) => {
                            const activityIds = req.args[0];
                            const activity = await this.rpc.requestAsync('activities.load', [activityIds[0]]);
                            this.aside.create(V3ActivitySidenavComponent, {
                                action: 'editMultiple',
                                activities: activityIds.map(activityId => ({ _id: activityId })),
                                processId: activity?.process,
                                phaseId: activity?.currentPhase,
                                updatedMultiple: activities => {
                                    this.aside.pop();
                                    res.send(activities);
                                },
                            });
                        },
                    },
                    _insight: {},
                    insight: {
                        _create: {},
                        create: (req, res) => {
                            console.log('Open insight editor...?', req.args);
                            this.dialog.open<InsightEditorComponent>(InsightEditorComponent, {
                                disableClose: true,
                                panelClass: 'insight-editor-dialog',
                                width: '100vw',
                                height: '95vh',
                            });
                        },
                        _edit: {},
                        edit: async (req, res): Promise<void> => {
                            const insightId = req.args[0];

                            let insights: InsightDoc[] = [];
                            let insight: InsightDoc | undefined;

                            try {
                                if (this.app.isPrivate) {
                                    // Personal app
                                    insights = await this.rpc.requestAsync('v3.insight.list', [this.core.network.value._id]);
                                } else {
                                    insights = await this.rpc.requestAsync('v3.insight.list', [this.app.cid]);
                                }

                                if (!Array.isArray(insights)) {
                                    res.error(new Error('App SDK Error: Insight not found'));
                                    return;
                                }

                                insight = insights.find(insight => insight._id === insightId);

                                if (!insight) {
                                    res.error(new Error('App SDK Error: Insight not found'));
                                    return;
                                }
                            } catch (error) {
                                console.log('App SDK Error:', error);
                                res.error(error);
                                return;
                            }

                            console.log('Open insight editor...?', req.args);
                            this.dialog.open<InsightEditorComponent, InsightDoc>(InsightEditorComponent, {
                                data: insight,
                                disableClose: true,
                                panelClass: 'insight-editor-dialog',
                                width: '100vw',
                                height: '95vh',
                            });
                        },
                        _delete: {},
                        delete: async (req, res): Promise<void> => {
                            const insightId = req.args[0];

                            let insights: InsightDoc[] = [];
                            let insight: InsightDoc | undefined;

                            try {
                                if (this.app.isPrivate) {
                                    // Personal app
                                    insights = await this.rpc.requestAsync('v3.insight.list', [this.core.network.value._id]);
                                } else {
                                    insights = await this.rpc.requestAsync('v3.insight.list', [this.app.cid]);
                                }

                                if (!Array.isArray(insights)) {
                                    res.error(new Error('App SDK Error: Insight not found'));
                                    return;
                                }

                                insight = insights.find(insight => insight._id === insightId);

                                if (!insight) {
                                    res.error(new Error('App SDK Error: Insight not found'));
                                    return;
                                }
                            } catch (error) {
                                console.log('App SDK Error:', error);
                                res.error(error);
                                return;
                            }

                            this.dialog
                                .open(ConfirmDialogComponent, {
                                    data: {
                                        cancel: 'Cancel',
                                        confirm: 'Delete',
                                        content: `Are you sure you want to delete Insight '${insight.name}'?`,
                                        title: 'Delete Insight?',
                                    },
                                    width: '300px',
                                })
                                .afterClosed()
                                .subscribe({
                                    next: async (confirmed: boolean) => {
                                        if (!confirmed) {
                                            return;
                                        }

                                        await this.insightService.remove(insightId);
                                    },
                                });
                        },
                        _permission: {},
                        permission: async (req, res): Promise<void> => {
                            const insightId = req.args[0];

                            let insights: InsightDoc[] = [];
                            let insight: InsightDoc | undefined;

                            try {
                                if (this.app.isPrivate) {
                                    // Personal app
                                    insights = await this.rpc.requestAsync('v3.insight.list', [this.core.network.value._id]);
                                } else {
                                    insights = await this.rpc.requestAsync('v3.insight.list', [this.app.cid]);
                                }

                                if (!Array.isArray(insights)) {
                                    res.error(new Error('App SDK Error: Insight not found'));
                                    return;
                                }

                                insight = insights.find(insight => insight._id === insightId);

                                if (!insight) {
                                    res.error(new Error('App SDK Error: Insight not found'));
                                    return;
                                }
                            } catch (error) {
                                console.log('App SDK Error:', error);
                                res.error(error);
                                return;
                            }

                            this.dialog.open<InsightPermissionComponent, InsightDoc>(InsightPermissionComponent, {
                                data: insight,
                                width: '800px',
                            });
                        },
                    },
                    _snackbar: {},
                    snackbar: {
                        _open: {},
                        open: (req, res) => {
                            const text = req.args[0];
                            const buttonLabel = req.args[1];
                            const duration: number = req.args[2] ?? 3000;

                            this.snackbar.open(text, buttonLabel, { duration });

                            res.send();
                        },
                    },
                    _file: {},
                    file: {
                        _upload: {},
                        upload: async (req, res) => {
                            /** File content */
                            const data = base64ToFile(req.args[0]);
                            const filename: string = req.args[1];
                            const isPublic: boolean = req.args[2];

                            const file = new File([data], filename, { type: data.type });
                            const fileId = await new Promise(resolve => {
                                this.files
                                    .upload(file, { isPublic })
                                    .pipe(takeUntil(this.destroy))
                                    .subscribe(uploadedFile => {
                                        if (uploadedFile.finished) {
                                            resolve(uploadedFile.fileId);
                                        }
                                    });
                            });
                            res.send(fileId);
                        },
                    },
                },
                _permission: {},
                permission: {
                    _map: {},
                    map: (req, res) => {
                        try {
                            if (this.app.isPrivate) {
                                // Personal app
                                res.send(this.core.permission.map);
                            } else {
                                // Workspace app, only sees user permisisons in app workspace
                                res.send({ [this.app.cid]: this.core.permission.map[this.app.cid] });
                            }
                        } catch (error) {
                            console.log('App SDK Error:', error);
                            res.error(error);
                            return;
                        }
                    },
                },
                _config: {},
                config: (req, res) => {
                    const config = {
                        fields: Object.fromEntries(
                            Object.entries(this.app.config?.fields || {}).map(
                                ([key, value]) => [key, { value: value.value, type: 'string' }]
                            )
                        ),
                    };

                    res.emit(config);
                },
                _signals: {},
                signals: (req, res) => {
                    const filter = req.args[0];

                    this.signalsSubscriber = { res, filter };
                },
            },
        });
    }
}
