/** @format */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { flatMap, take } from 'rxjs/operators';

import { Discussion, User } from '@app/models';
import { V3DiscussionService } from './v3-discussion.service';
import { CoreService } from './core.service';

@Injectable()
export class DiscussionDetailResolverService {
    constructor(private v3Discussion: V3DiscussionService, private router: Router, private core: CoreService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<{ discussion?: Discussion; user?: User; unread?: number }> | Observable<never> {
        const discussionId = route.paramMap.get('discussionId');
        return this.v3Discussion.get(discussionId).pipe(
            take(1),
            flatMap((discussion: Discussion) => {
                if (discussion) {
                    const ret = { discussion };
                    return of(ret);
                } // Discussion not found, check for user
                const user = this.core.getUser(discussionId);
                if (user) {
                    const ret = { user };
                    return of(ret);
                }
                this.router.navigate(['/discussions']);
                return EMPTY;
            })
        );
    }
}
