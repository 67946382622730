<div [@passwordMeterTrigger]="{value: passwordInputFocused ? 'inactive' : 'active'}" *ngIf="passwordInputFocused" class="password-strength-meter">
    <mat-label>
        <strong>
            {{getPasswordStrengthLabel(passwordScore) | transloco}} {{'shared.password-strength-meter.password' | transloco}}
        </strong>
    </mat-label>

    <div class="arrow-container">
        <div class="arrow"></div>
    </div>
    
    <div class="graphic-meter-container">
        <div class="meter-color-indicator" [ngClass]="colors[passwordScore]" ></div>
        <div class="meter-color-indicator" [ngClass]="passwordScore > 0 ? colors[passwordScore] : ''"></div>
        <div class="meter-color-indicator" [ngClass]="passwordScore > 1 ? colors[passwordScore] : ''"></div>
        <div class="meter-color-indicator" [ngClass]="passwordScore > 2 ? colors[passwordScore] : ''"></div>
    </div>

    <mat-hint class="hint">{{'shared.password-strength-meter.how_to_improve_password' | transloco}}</mat-hint>
           
    <mat-hint class="hint">{{'shared.password-strength-meter.password_length_requirement' | transloco}} {{minLength}} {{'shared.password-strength-meter.characters' | transloco}}</mat-hint>
</div>