/** @format */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DropdownItem } from 'app/_models/dropdown-selector.model';

@Component({
    selector: 'app-dropdown-option-content',
    templateUrl: './dropdown-option-content.component.html',
    styleUrls: ['./dropdown-option-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownOptionContentComponent implements OnInit {
    @Input() item: DropdownItem;
    @Input() onlySelector: boolean;

    @Output() onlyItem = new EventEmitter<DropdownItem>();

    constructor() {}

    ngOnInit(): void {}
}
