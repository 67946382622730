<!-- @format -->

<button
	class="user-link-button no-button"
	[class.white-color]="whiteLink"
	[class.light-background-link]="darkLink"
	[class.user-link-inherit-size]="inheritLinkSize"
	[class.own-message-user-link-focus]="lightFocus"
	[style.color]="color || 'var(--user-link-default)'"
	(click)="openUserSidebar()">
	{{ ownName ? ('shared.user-link.you' | transloco) : user.display_name }}
</button>
