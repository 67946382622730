<!-- @format -->

<ng-container *ngIf="!menuMode; else menuButtonMode">
	<app-button
		*ngIf="!discussion?.isMuted; else discussionMuted"
		type="icon"
		color="icon-default"
		hailerIconName="hailer-notification"
		[ariaLabel]="'discussion.mute_discussion.mute_discussion' | transloco"
		[tippyText]="'discussion.mute_discussion.mute_discussion' | transloco"
		[menuTriggerFor]="muteMenu">
	</app-button>

	<ng-template #discussionMuted>
		<app-button
			type="icon"
			color="icon-default"
			hailerIconName="hailer-notification-off"
			(clickEvent)="toggleMuted(0)"
			[tippyText]="'discussion.mute_discussion.unmute_discussion' | transloco"
			[ariaLabel]="'discussion.mute_discussion.unmute_discussion' | transloco">
		</app-button>
	</ng-template>
</ng-container>

<ng-template #menuButtonMode>
	<button *ngIf="!discussion?.isMuted; else unmuteButton" mat-menu-item (click)="$event.stopPropagation()" [matMenuTriggerFor]="muteMenu">
		<mat-icon svgIcon="hailer-notification"></mat-icon>
		<span>{{ "discussion.mute_discussion.mute" | transloco }}</span>
	</button>
	<ng-template #unmuteButton>
		<button mat-menu-item (click)="toggleMuted(0)">
			<mat-icon svgIcon="hailer-notification-off"></mat-icon>
			<span>{{ "discussion.mute_discussion.unmute" | transloco }}</span>
		</button>
	</ng-template>
</ng-template>

<mat-menu #muteMenu="matMenu">
	<button (click)="toggleMuted(60)" mat-menu-item>{{ "discussion.mute_discussion.1_hour" | transloco }}</button>
	<button (click)="toggleMuted(120)" mat-menu-item>{{ "discussion.mute_discussion.3_hour" | transloco }}</button>
	<button (click)="toggleMuted(480)" mat-menu-item>{{ "discussion.mute_discussion.8_hour" | transloco }}</button>
	<button (click)="toggleMuted(veryLongTime)" mat-menu-item>{{ "discussion.mute_discussion.until_unmuted" | transloco }}</button>
</mat-menu>
