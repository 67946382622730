/** @format */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, Subject, merge, takeUntil } from 'rxjs';

import { Discussion } from '@app/models';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';
import { CoreService } from 'app/_services/core.service';

@Component({
    selector: 'app-discussion-filter-header',
    templateUrl: './discussion-filter-header.component.html',
    styleUrls: ['./discussion-filter-header.component.scss'],
})
export class DiscussionFilterHeaderComponent implements OnInit, OnDestroy, OnChanges {
    @Input() inputDiscussions: Discussion[];
    @Output() selectedFilter = new EventEmitter<{ viewValue: string; value: string | null }>();
    @Output() outputDiscussions = new EventEmitter<Observable<Discussion[]>>();

    filters = [
        { viewValue: 'Starred', value: 'starred' },
        { viewValue: 'Unread', value: 'unread' },
        { viewValue: 'Private', value: 'private' },
        { viewValue: 'Group', value: 'groups' },
        { viewValue: 'Activity', value: 'activities' },
        { viewValue: 'Event', value: 'events' },
    ];

    selectedFilterLocal = new BehaviorSubject({ viewValue: 'Filter Discussions', value: null });
    private onDestroy = new Subject<void>();
    private discussionsChanged = new Subject<void>();

    constructor(private v3Discussion: V3DiscussionService, private core: CoreService) {}

    ngOnInit() {
        const returnObservable = new Observable<Discussion[]>(subscriber => {
            subscriber.next(this.inputDiscussions);

            merge(this.selectedFilterLocal, this.discussionsChanged)
                .pipe(takeUntil(this.onDestroy))
                .subscribe({
                    next: () => {
                        subscriber.next(this.filterDiscussions(this.inputDiscussions));
                    },
                });
        });

        // Managed users dont need to see group discussion filter
        if (this.core.user.value?.managedUser) {
            this.filters = this.filters.filter(element => element.viewValue !== 'Group');
        }

        this.outputDiscussions.emit(returnObservable);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.inputDiscussions && changes.inputDiscussions.currentValue !== changes.inputDiscussions.previousValue) {
            this.discussionsChanged.next();
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
        this.discussionsChanged.complete();
    }

    setFilter(discussionFilter: { viewValue: string; value: string }) {
        if (this.selectedFilterLocal.value === discussionFilter) {
            this.setSelectedFilter({ viewValue: 'Filter Discussions', value: null });
        } else {
            this.setSelectedFilter(discussionFilter);
        }
    }

    filterDiscussions(discussions: Discussion[]): Discussion[] {
        switch (this.selectedFilterLocal.value.value) {
            case 'starred':
                return discussions.filter(discussion => discussion.isStarred);
            case 'private':
                return discussions.filter(discussion => discussion.private);
            case 'groups':
                return discussions.filter(
                    discussion =>
                        !discussion.private && !discussion.linked_activity && !discussion.linked_event && !discussion.linked_tasklist
                );
            case 'activities':
                return discussions.filter(discussion => !discussion.private && discussion.linked_activity);
            case 'events':
                return discussions.filter(discussion => !discussion.private && discussion.linked_event);
            case 'unread':
                return discussions.filter(discussion => this.discussionUnread(discussion._id));
            default:
                return discussions;
        }
    }

    discussionUnread(discussionId: string): boolean {
        return Object.keys(this.v3Discussion.unreadDiscussions.value)?.includes(discussionId);
    }

    private setSelectedFilter(filter: { viewValue: string; value: string | null }) {
        this.selectedFilterLocal.next(filter);
        this.selectedFilter.emit(filter);
    }
}
