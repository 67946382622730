<!-- @format -->

<div class="view-attachments">
	<div
		*ngFor="let attachment of supportedFiles; let i = index"
		(click)="viewImages(supportedFiles, i)"
		style="position: relative; max-width: 100%">
		<mat-icon *ngIf="attachment.isPicture || attachment.isGif" class="image-skeleton" svgIcon="hailer-image-fill"></mat-icon>
		<button
			*ngIf="attachment.isPicture || attachment.isGif"
			class="no-button image-container"
			[class.square-container]="supportedFiles.length > 1"
			attr.aria-label="{{ 'shared.file-viewer.preview' | transloco }}">
			<img
				[src]="getImageUrl(attachment)"
				height="{{ attachment.aspectRatio > 1 ? maxImageDimension / attachment.aspectRatio : maxImageDimension }}"
				width="{{ attachment.aspectRatio >= 1 ? maxImageDimension : maxImageDimension * attachment.aspectRatio }}" />
			<app-button
				type="icon"
				size="small"
				color="tertiary-default-background"
				hailerIconName="hailer-download"
				focusOutline="inner"
				(clickEvent)="filesService.download(attachment.file)"
				(click)="$event.stopPropagation()"
				class="attachment-download-button"
				tippyText="{{ 'shared.file-viewer.download' | transloco }}"
				ariaLabel="{{ 'shared.file-viewer.download' | transloco }}"></app-button>
		</button>

		<video
			*ngIf="attachment.isVideo"
			[src]="attachment.url"
			controls
			loop
			aria-describedby="Video Attachment"
			controlsList="nodownload"></video>
	</div>
</div>

<button
	*ngFor="let attachment of otherFiles; let i = index"
	(click)="openFileSideNav(attachment.file._id)"
	class="no-button other-files"
	[style.margin-top]="supportedFiles.length > 0 ? '16px' : '0'"
	attr.aria-label="{{ 'shared.file-viewer.attachment' | transloco }} {{ attachment.name }} {{
		'shared.file-viewer.open-info' | transloco
	}}">
	<app-button
		type="icon"
		size="small"
		color="tertiary-highlight-background"
		hailerIconName="hailer-download"
		(clickEvent)="filesService.download(attachment.file)"
		(click)="$event.stopPropagation()"
		tippyText="{{ 'shared.file-viewer.download' | transloco }}"
		ariaLabel="{{ 'shared.file-viewer.download' | transloco }} {{ attachment.name }}"></app-button>
	<h3 class="attachment-name">{{ attachment.name }}</h3>
</button>
