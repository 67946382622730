/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */

import { Company, PersonalSettings, Process } from '@app/models';
import { Server } from '@wishcore/wish-rpc';
import { App } from 'app/_models/app.model';
import { AppService } from 'app/_services/app.service';
import { CoreService } from 'app/_services/core.service';
import { RPCService } from 'app/_services/rpc.service';
import { InsightDoc, InsightService } from '../insight.service';
import { V3ActivitySidenavComponent } from 'app/v3-activity/v3-activity-sidenav/v3-activity-sidenav.component';
import { Subject, takeUntil } from 'rxjs';
import { UserThemes } from 'app/_models/user-theme.type';
import { SideNavService } from 'app/_services/side-nav.service';
import { MatDialog } from '@angular/material/dialog';
import { InsightEditorComponent } from '../insight-editor/insight-editor.component';
import { ConfirmDialogComponent } from 'app/_dialogs/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilesService } from 'app/_services/files.service';
import { base64ToFile } from 'ngx-image-cropper';
import { activityConvert, hailerArgs, userConvert } from './app-page.component';
import Joi, { AnySchema } from 'joi';
import { validProcessId, validUser, validWorkflow } from '../validation';
import { InsightPermissionComponent } from '../insight-permission/insight-permission.component';
import { permissionMapSchema } from '../validation-permission';

/** Coversion and whitelist of signal names from backend to AppApi */
const signalMap: { [from: string]: string } = {
    'activities.created': 'activity.create',
    'activities.updated': 'activity.update',
    'company.new_invitation': 'workspace.invitation.new',
    // 'company.reload': 'workspace.reload',
    'company.remove_invitation': 'workspace.invitation.remove',
    // 'wall2.new_post': 'feed.post.new',
    'insight.create': 'insight.create',
    'insight.update': 'insight.update',
    'insight.removed': 'insight.remove',
    'workspace.role.updated': 'workspace.role.update',
    'workspace.role.created': 'workspace.role.create',
    'workspace.role.removed': 'workspace.role.remove',
};

const signalConvertMap: { [from: string]: (data: any) => { name?: string; data?: any } } = {
    'activities.created': (data) => {
        return {
            data: {
                activityIds: data.activity_id,
                workflowId: data.process ?? data.processId,
                phaseId: data.phase ?? data.phaseId,
            },
        };
    },
    'activities.updated': (data) => {
        return {
            data: {
                activityIds: data.activity_id,
                workflowId: data.process ?? data.processId,
                phaseId: data.phase ?? data.phaseId,
            },
            name: data.removed === true ? 'activity.remove' : 'activity.update',
        };
    },
};

interface Signal {
    name: string;
    data: any;
}

interface AppConfig {
    [fieldName: string]: string;
}

const permissionProjection = (permission): any => {
    const validation = permissionMapSchema.validate(permission, { stripUnknown: true, allowUnknown: true });

    if (validation.error) {
        return { error: validation.error.toString(), details: validation.error.details, original: permission };
    }

    return validation.value;
};

const permissionConvert = (permission): any => permissionProjection(permission);

const workflowProjection = (workflow): any => {
    const validation = validWorkflow.validate(workflow, { stripUnknown: true, allowUnknown: true });

    if (validation.error) {
        console.log('Validation error on data', workflow);
        return { error: validation.error.toString(), details: validation.error.details };
    }

    return validation.value;
};

const workflowConvert = ({ /* fieldsOrder, */ cid, ...workflow }): any => {
    return workflowProjection({
        ...workflow,
        workspaceId: cid,
        // repair phases missing _id, by using the key
        phases: Object.fromEntries(
            Object.entries(workflow.phases).map(([phaseId, phase]) => {
                return [phaseId, { _id: phaseId, ...(phase as any) }];
            })
        ),
        // remove fields without type
        fields: Object.fromEntries(
            Object.entries(workflow.fields as { [fieldId: string]: any }).filter(([fieldId, field]) => !!field.type)
        ),
    });
};

export class AppApiV2 {
    currentNetworkId: string | undefined;

    constructor(
        private app: App,
        private core: CoreService,
        private server: Server,
        private rpc: RPCService,
        private appService: AppService,
        private insightService: InsightService,
        private snackbar: MatSnackBar,
        private files: FilesService,
        private aside: SideNavService,
        private dialog: MatDialog,
        private destroy: Subject<void>
    ) {
        this.currentNetworkId = this.core.network.value?._id || undefined;

        // Subscribe to signals in the frontend, to be able to pass them into the app
        this.rpc.signals.pipe(takeUntil(this.destroy)).subscribe({
            next: signal => {
                const name = signalMap[signal.sig];

                if (!name) {
                    return;
                }

                let converted: Signal = { name, data: signal.meta };

                // if there is a convertion function run it and replace returned keys, can be both name and data
                if (signalConvertMap[signal.sig]) {
                    converted = { ...converted, ...signalConvertMap[signal.sig]?.(signal.meta) };
                }

                this.server.emit('signal', converted);
            },
        });

        // Detect when workspace is changed, and signal the app
        this.core.network.pipe(takeUntil(this.destroy)).subscribe({
            next: (workspace: Company) => {
                this.server.emit('signal', { name: 'workspace.current', data: { workspaceId: workspace._id } } as Signal);
            },
        });

        this.settingsSubscribe();
        this.registerMethods();
    }

    /** Subscribe to changes in Hailer user settings */
    private settingsSubscribe(): void {
        // Send settings to app if user changes them
        this.core.user.pipe(takeUntil(this.destroy)).subscribe(user => {
            this.server.emit(`ui.settings`, this.getSettingsFromUser(user));
        });
    }

    private getSettingsFromUser(user: PersonalSettings | null): { theme?: UserThemes; preferredLanguages: string[] } {
        const theme = user?.globalSettings?.theme || 'light';
        const preferredLanguages = user?.preferredLanguages || ['en'];

        return { theme, preferredLanguages };
    }

    private getWorkflowById(workflowId: string, option?: { workspaceId?: string }): Process | null {
        if (option?.workspaceId) {
            return this.core.processes.value?.[option.workspaceId]?.[workflowId] || null;
        }

        const workspaceWorkflowMap = Object.values(this.core.processes.value)?.find(workflowMap => workflowMap[workflowId]);

        return workspaceWorkflowMap?.[workflowId] || null;
    }

    /**
     * Prepare field values for sidenav
     *
     * TODO: this might be moved to sidenav or its service at some point
     *
     * Sidenav wants the field values to be in a certain format, while SDK wants to allow a cleaner
     * more simple way of providing the values. This requires conversion, and for activity links even
     * pulling activity names from the backend. This function is a workaround which does that.
     *
     * We want to call with fields:
     *
     * ```ts
     * { [fieldId: string]: activityId }
     * ```
     *
     * while sidenav expects:
     *
     * ```ts
     * { [fieldId: string]: { value: { name: 'Activity Name', _id: activityId } } }
     * ```
     */
    private async convertFieldValuesForSideNav(
        workflowId: string,
        fields: { [fieldId: string]: string }
    ): Promise<{ [fieldId: string]: { value: any } }> {
        const activityLinkFieldMap: { [fieldId: string]: true } = {};

        const workflow = this.core.processById(workflowId);

        if (!workflow) {
            return {};
        }

        for (const [fieldId, field] of Object.entries(workflow.fields)) {
            if (field.type === 'activitylink') {
                activityLinkFieldMap[fieldId] = true;
            }
        }

        const missingActivityNames: { fieldId: string; activityId: string }[] = [];

        Object.entries(fields ?? {}).forEach(([fieldId, activityId]) => {
            missingActivityNames.push({ fieldId, activityId });
        });

        const activityMap: { [activityId: string]: { name: string } } = {};

        // Fetch activity name to fill it in when linked activities are set (this will fetch the same activiy twice if it is linked in separate fields, could be improved)
        await Promise.allSettled(
            missingActivityNames.map(async ({ fieldId, activityId }) => {
                activityMap[activityId] = (await this.rpc.requestAsync('activities.load', [activityId])) as { name: string };
            })
        );

        const initFieldValues = Object.entries(fields ?? {}).reduce((map, [fieldId, fieldValue]) => {
            const isActivityLink = this.core.processById(workflowId).fields[fieldId]?.type === 'activitylink';

            if (isActivityLink) {
                const activityId = fieldValue;

                map[fieldId] = {
                    value: { name: activityMap[activityId]?.name, _id: activityId },
                };
            } else {
                map[fieldId] = {
                    value: fieldValue,
                };
            }
            return map;
        }, {});

        return initFieldValues;
    }

    registerMethods(): void {
        this.server.insertMethods({
            _app: {},
            app: {
                _config: {},
                config: {
                    _update: {
                        argumentValidation: (req, context): AnySchema => {
                            const configKeys = Object.keys(this.app.manifest?.config?.fields || {});

                            const configKeyMap = configKeys.reduce((map, key) => {
                                map[key] = Joi.string().max(4096);
                                return map;
                            }, {});
                            // eslint-disable-next-line prettier/prettier
                            const schema = hailerArgs(
                                Joi.object(configKeyMap),
                                Joi.object({ appId: Joi.string().hex().length(24) }),
                            );
                            return schema; // hailerArgs(...validActivityListArguments);
                        },
                        resultValidation: (req, context): AnySchema => {
                            return Joi.boolean().required();
                        },
                    },
                    update: async (req, res): Promise<void> => {

                        const config: AppConfig = req.args[0];
                        const { appId } = req.args[1] || { appId: this.app._id };

                        if (appId !== this.app._id && !!this.app.cid) {
                            return res.error({ msg: 'Only current app config can be modified', code: 7 });
                        }

                        try {
                            await this.appService.updateConfig(appId, config);
                            // TODO: Remove once there is a proper app.refresh / app.config update handling
                            this.server.emit('config', config);
                            res.send(true);
                        } catch (error) {
                            res.error(error);
                        }
                    },
                },
                _product: {},
                product: {
                    _isProductInstalled: {},
                    isProductInstalled: async (req, res) => {
                        try {
                            const isProductInstalled = await this.rpc.requestAsync('v3.app.product.isProductInstalled', req.args);
                            return res.send(isProductInstalled);
                        } catch (error) {
                            return res.error(error);
                        }
                    },
                    _get: {},
                    get: async (req, res) => {
                        try {
                            // Get version from AppProduct
                            const response = await this.rpc.requestAsync('v3.app.product.get', req.args);
                            return res.send(response);
                        } catch (error) {
                            return res.error(error);
                        }
                    },
                    _install: {},
                    install: async (req, res) => {
                        try {
                            // Install app in current workspace
                            const response = await this.rpc.requestAsync('v3.app.product.install', req.args);
                            return res.send(response);
                        } catch (error) {
                            return res.error(error);
                        }
                    },
                    _getInstalledVersion: {},
                    getInstalledVersion: async (req, res) => {
                        try {
                            // Get installed version
                            const response = await this.rpc.requestAsync('v3.app.product.getInstalledVersion', req.args);
                            return res.send(response);
                        } catch (error) {
                            return res.error(error);
                        }
                    },
                    _getManifest: {},
                    getManifest: async (req, res) => {
                        const versionId = req.args[0];

                        try {
                            const manifest = await this.rpc.requestAsync('v3.app.product.getManifest', [versionId]);
                            return res.send(manifest);
                        } catch (error) {
                            return res.error(error);
                        }
                    },
                },
            },
            _activity: {},
            activity: {
                _create: {},
                create: (req, res) => {
                    this.rpc
                        .requestAsync('v3.activity.createMany', req.args)
                        .then(response => {
                            const create = response.map(activityConvert);
                            res.send(create);
                        })
                        .catch(error => {
                            res.error(error);
                            console.log('error in frontend', error.details);
                        });
                },
                _update: {},
                update: (req, res) => {
                    this.rpc
                        .requestAsync('v3.activity.updateMany', req.args)
                        .then(response => res.send(response))
                        .catch(error => res.error(error));
                },
                _get: {},
                get: async (req, res) => {
                    const activityId = req.args[0];

                    try {
                        const activity = activityConvert(await this.rpc.requestAsync('activities.load', [activityId]));
                        return res.send(activity);
                    } catch (error) {
                        return res.error(error);
                    }
                },
                _list: {
                    argumentValidation: (req, context): AnySchema => {
                        return Joi.any(); // hailerArgs(...validActivityListArguments);
                    },
                    resultValidation: (req, context): AnySchema => {
                        // todo
                        // eslint-disable-next-line prettier/prettier
                        return Joi.array().items(
                            Joi.any() // validActivity.required(),
                        );
                    },
                },
                list: async (req, res) => {
                    const processId = req.args[0];
                    const phaseId = req.args[1];
                    const options = req.args[2] ?? {};

                    const response = await this.rpc.requestAsync('v3.activity.list', [{ processId, phaseId }, options]);

                    const list = response.activities.map(activityConvert);

                    res.send(list || []);
                },
                _remove: {},
                remove: (req, res) => {
                    this.rpc
                        .requestAsync('activities.remove', req.args)
                        .then(response => res.send(response))
                        .catch(error => res.error(error));
                },
                _import: {},
                import: (req, res) => {
                    this.rpc
                        .requestAsync('v3.activity.import', req.args)
                        .then(response => res.send(response))
                        .catch(error => res.error(error));
                },
                _kanban: {},
                kanban: {
                    _list: {},
                    list: (req, res) => {
                        this.rpc
                            .requestAsync('v2.activities.kanban.list', req.args)
                            .then(response => res.send(response))
                            .catch(error => res.error(error));
                    },
                    _load: {},
                    load: (req, res) => {
                        this.rpc
                            .requestAsync('v2.activities.kanban.load', req.args)
                            .then(response => res.send(response))
                            .catch(error => res.error(error));
                    },
                    _updatePriority: {},
                    updatePriority: (req, res) => {
                        this.rpc
                            .requestAsync('v2.activities.updatePriority', req.args)
                            .then(response => res.send(response))
                            .catch(error => res.error(error));
                    },
                },
            },
            _user: {},
            user: {
                _list: {
                    argumentValidation: (req, context): AnySchema => {
                        // eslint-disable-next-line prettier/prettier
                        return hailerArgs();
                    },
                    resultValidation: (req, context): AnySchema => {
                        // todo
                        // eslint-disable-next-line prettier/prettier
                        return Joi.array().items(
                            validUser.required(),
                        );
                    },
                },
                list: (req, res) => {
                    res.send(
                        Object.entries(this.core.users.value)
                            .filter(([id, user]) => user.companies?.includes(this.currentNetworkId || ''))
                            .map(([id, user]) => userConvert(user))
                            .sort((a, b) => `${a.firstname} ${a.lastname}`.localeCompare(`${b.firstname} ${b.lastname}`))
                    );
                },
                _current: {
                    argumentValidation: (req, context): AnySchema => {
                        // eslint-disable-next-line prettier/prettier
                        return hailerArgs();
                    },
                    resultValidation: (req, context): AnySchema => {
                        // todo
                        // eslint-disable-next-line prettier/prettier
                        return validUser.required();
                    },
                },
                current: (req, res) => {
                    res.send(userConvert(this.core.user.value));
                },
                _get: {
                    argumentValidation: (req, context): AnySchema => {
                        // eslint-disable-next-line prettier/prettier
                        return hailerArgs();
                    },
                    resultValidation: (req, context): AnySchema => {
                        // todo
                        // eslint-disable-next-line prettier/prettier
                        return validUser.allow(null).required();
                    },
                },
                get: (req, res) => {
                    const id = req.args[0];
                    const foundUser = Object.values(this.core.users.value).find(user => user._id === id);
                    if (!foundUser) {
                        res.send(null);
                    }
                    if (this.app.isPrivate || foundUser?.companies?.includes(this.currentNetworkId || '')) {
                        res.send(userConvert(foundUser));
                    } else {
                        res.send(null);
                    }
                },
            },
            _workspace: {},
            workspace: {
                _list: {},
                list: (req, res) => {
                    if (this.app.isPrivate) {
                        // Personal app
                        const workspaces = Object.values(this.core.networks.value);

                        // Sort the workspaces array ascending by name
                        workspaces.sort((a, b) => a.name.localeCompare(b.name));

                        return res.send(workspaces.map(workspace => ({ _id: workspace._id, name: workspace.name })));
                    }

                    // Workspace app
                    const workspace = this.core.network.value;

                    if (!workspace) {
                        return res.send([]);
                    }

                    res.send([{ _id: workspace._id, name: workspace.name }]);
                },
                _current: {},
                current: (req, res) => {
                    const workspace = this.core.network.value;

                    if (!workspace) {
                        res.send(null);
                    } else {
                        res.send({ _id: workspace._id, name: workspace.name });
                    }
                },
                _product: {},
                product: {
                    _publishTemplate: {},
                    publishTemplate: async (req, res) => {
                        try {
                            const version = await this.rpc.requestAsync('v2.network.product.publishTemplate', req.args);
                            return res.send(version);
                        } catch (error) {
                            return res.error(error);
                        }
                    },
                    _isProductInstalled: {},
                    isProductInstalled: async (req, res) => {
                        try {
                            const isProductInstalled = await this.rpc.requestAsync('v2.network.product.isProductInstalled', req.args);
                            return res.send(isProductInstalled);
                        } catch (error) {
                            return res.error(error);
                        }
                    },
                    _get: {},
                    get: async (req, res) => {
                        try {
                            // Get version from WorkspaceProduct
                            const response = await this.rpc.requestAsync('v2.network.product.get', req.args);
                            return res.send(response);
                        } catch (error) {
                            return res.error(error);
                        }
                    },
                    _install: {},
                    install: async (req, res) => {
                        try {
                            // Install template in current workspace
                            const response = await this.rpc.requestAsync('v2.network.product.install', req.args);
                            return res.send(response);
                        } catch (error) {
                            return res.error(error);
                        }
                    },
                    _getManifest: {},
                    getManifest: async (req, res) => {
                        const targetId = req.args[0];

                        try {
                            const manifest = await this.rpc.requestAsync('v2.network.product.getManifest', [targetId]);
                            return res.send(manifest);
                        } catch (error) {
                            return res.error(error);
                        }
                    },
                },
            },
            _workflow: {},
            workflow: {
                _list: {
                    argumentValidation: (req, context): AnySchema => {
                        // eslint-disable-next-line prettier/prettier
                        return hailerArgs();
                    },
                    resultValidation: (req, context): AnySchema => {
                        // todo
                        // eslint-disable-next-line prettier/prettier
                        return Joi.array().items(
                            validWorkflow.required(),
                        );
                    },
                },
                list: (req, res) => {
                    if (this.app.isPrivate) {
                        const workflows: Process[] = [];

                        Object.values(this.core.processes.value).forEach(workspaceProcessMap =>
                            workflows.push(...Object.values(workspaceProcessMap))
                        );

                        res.send(workflows.map(workflowConvert));

                        return;
                    }

                    // Only return the processes of the current network
                    res.send(Object.values(this.core.processes.value[this.app.cid] || {}).map(workflowConvert));
                },
                _get: {
                    argumentValidation: (req, context): AnySchema => {
                        // eslint-disable-next-line prettier/prettier
                        return hailerArgs(
                            validProcessId,
                        );
                    },
                    resultValidation: (req, context): AnySchema => {
                        return validWorkflow.allow(null).required();
                    },
                },
                get: (req, res) => {
                    const workflowId = req.args[0];

                    const workflow = this.getWorkflowById(workflowId, this.app.isPrivate ? undefined : { workspaceId: this.app.cid });

                    if (!workflow) {
                        return res.error({ msg: 'Workflow not found or permission denied', code: 404 });
                    }

                    res.send(workflowConvert(workflow));
                },
                _install: {},
                install: async (req, res) => {
                    const workflowSpec = req.args[0];
                    const option: { workspaceId?: string } = req.args[1];

                    const workspaceId = option?.workspaceId || this.app.cid;

                    if (!workspaceId) {
                        return res.error({ msg: 'WorkspaceId option is required.', code: 191 });
                    }

                    try {
                        const idsMap: { [key: string]: string } = await this.rpc.requestAsync(
                            'v3.workflow.install', [workspaceId, workflowSpec]
                        );
                        return res.send(idsMap);
                    } catch (error) {
                        return res.error(error);
                    }
                },
            },
            _insight: {},
            insight: {
                _data: {},
                data: async (req, res) => {
                    const insightId = req.args[0];
                    const options = req.args[1] ? [req.args[1]] : [];

                    try {
                        const data = await this.rpc.requestAsync('v3.insight.data', [insightId, ...options]);

                        res.send(data);
                    } catch (error) {
                        console.log('Error in apps RPC', error);
                        res.error(error);
                    }
                },
                _list: {},
                list: async (req, res) => {
                    try {
                        let insights: InsightDoc[] | undefined;

                        if (this.app.isPrivate) {
                            // Personal app
                            insights = await this.rpc.requestAsync('v3.insight.list', [this.core.network.value._id]);
                        } else {
                            insights = await this.rpc.requestAsync('v3.insight.list', [this.app.cid]);
                        }

                        if (!Array.isArray(insights)) {
                            return res.send([]);
                        }

                        res.send(insights.map(({ _id, name, publicKey, presets }) => ({ _id, name, publicKey, presets })));
                    } catch (error) {
                        console.log('Error in apps RPC', error);
                        res.error(error);
                    }
                },
                _update: {},
                update: async (req, res) => {
                    const insightId = req.args[0];
                    const update = req.args[1];

                    try {
                        const data = await this.rpc.requestAsync('v3.insight.update', [insightId, update]);

                        res.send(data);
                    } catch (error) {
                        console.log('Error in apps RPC', error);
                        res.error(error);
                    }
                },
            },
            _product: {},
            product: {
                _create: {},
                create: (req, res) => {
                    this.rpc
                        .requestAsync('v3.product.create', req.args)
                        .then(response => res.send(response))
                        .catch(error => res.error(error));
                },
                _update: {},
                update: (req, res) => {
                    this.rpc
                        .requestAsync('v3.product.update', req.args)
                        .then(response => res.send(response))
                        .catch(error => res.error(error));
                },
                _get: {},
                get: async (req, res) => {
                    const productId = req.args[0];

                    try {
                        const product = await this.rpc.requestAsync('v3.product.get', [productId]);
                        return res.send(product);
                    } catch (error) {
                        return res.error(error);
                    }
                },
                _list: {},
                list: (req, res) => {
                    console.log('Product list.');
                    this.rpc
                        .requestAsync('v3.product.list', [req.args[0] ?? {}, req.args[1] ?? {}])
                        .then(response => res.send(response))
                        .catch(error => res.error(error));
                },
                _remove: {},
                remove: (req, res) => {
                    this.rpc
                        .requestAsync('v3.product.remove', req.args)
                        .then(response => res.send(response))
                        .catch(error => res.error(error));
                },
                _setPublic: {},
                setPublic: (req, res) => {
                    this.rpc
                        .requestAsync('v3.product.setPublic', [req.args[0], req.args[1]])
                        .then(response => res.send(response))
                        .catch(error => res.error(error));
                },
            },
            _ui: {},
            ui: {
                _settings: {},
                settings: (req, res) => {
                    // emits using server.emit('signal', ...)
                    res.emit(this.getSettingsFromUser(this.core.user.value));
                },
                _activity: {},
                activity: {
                    _open: {},
                    open: (req, res) => {
                        this.aside.create(V3ActivitySidenavComponent, { activityId: req.args[0], initTab: req.args[1]?.tab });

                        res.send();
                    },
                    _create: {},
                    create: async (req, res) => {
                        const workflowId = req.args[0];
                        const options = req.args[1] as { name?: string; fields: { [fieldId: string]: string } } | undefined;

                        const workflow = this.core.processById(workflowId);

                        if (!workflow) {
                            res.error({ msg: 'Workflow not found, or permission denied.', code: 500 });
                            return;
                        }

                        const initFieldValues = await this.convertFieldValuesForSideNav(workflowId, options?.fields || {});

                        if (options?.name) {
                            initFieldValues.nameField = { value: options.name || '' };
                        }

                        console.log('Trying to open create sidenav with for workflowId:', workflowId, 'with options:', initFieldValues);

                        this.aside.create(V3ActivitySidenavComponent, {
                            action: 'create',
                            processId: workflowId,
                            initFieldValues,
                            created: activity => {
                                this.aside.pop();
                                res.send(activity);
                            },
                        });
                    },
                    _editMultiple: {},
                    editMultiple: async (req, res) => {
                        const activityIds = req.args[0];
                        const activity = await this.rpc.requestAsync('activities.load', [activityIds[0]]);
                        this.aside.create(V3ActivitySidenavComponent, {
                            action: 'editMultiple',
                            activities: activityIds.map(activityId => ({ _id: activityId })),
                            processId: activity?.process,
                            phaseId: activity?.currentPhase,
                            updatedMultiple: activities => {
                                this.aside.pop();
                                res.send(activities);
                            },
                        });
                    },
                },
                _insight: {},
                insight: {
                    _create: {},
                    create: (req, res) => {
                        console.log('Open insight editor...?', req.args);
                        this.dialog.open<InsightEditorComponent>(InsightEditorComponent, {
                            disableClose: true,
                            panelClass: 'insight-editor-dialog',
                            width: '100vw',
                            height: '95vh',
                        });
                    },
                    _edit: {},
                    edit: async (req, res): Promise<void> => {
                        const insightId = req.args[0];

                        let insights: InsightDoc[] = [];
                        let insight: InsightDoc | undefined;

                        try {
                            if (this.app.isPrivate) {
                                // Personal app
                                insights = await this.rpc.requestAsync('v3.insight.list', [this.core.network.value._id]);
                            } else {
                                insights = await this.rpc.requestAsync('v3.insight.list', [this.app.cid]);
                            }

                            if (!Array.isArray(insights)) {
                                res.error(new Error('App SDK Error: Insight not found'));
                                return;
                            }

                            insight = insights.find(insight => insight._id === insightId);

                            if (!insight) {
                                res.error(new Error('App SDK Error: Insight not found'));
                                return;
                            }
                        } catch (error) {
                            console.log('App SDK Error:', error);
                            res.error(error);
                            return;
                        }

                        console.log('Open insight editor...?', req.args);
                        this.dialog.open<InsightEditorComponent, InsightDoc>(InsightEditorComponent, {
                            data: insight,
                            disableClose: true,
                            panelClass: 'insight-editor-dialog',
                            width: '100vw',
                            height: '95vh',
                        });
                    },
                    _delete: {},
                    delete: async (req, res): Promise<void> => {
                        const insightId = req.args[0];

                        let insights: InsightDoc[] = [];
                        let insight: InsightDoc | undefined;

                        try {
                            if (this.app.isPrivate) {
                                // Personal app
                                insights = await this.rpc.requestAsync('v3.insight.list', [this.core.network.value._id]);
                            } else {
                                insights = await this.rpc.requestAsync('v3.insight.list', [this.app.cid]);
                            }

                            if (!Array.isArray(insights)) {
                                res.error(new Error('App SDK Error: Insight not found'));
                                return;
                            }

                            insight = insights.find(insight => insight._id === insightId);

                            if (!insight) {
                                res.error(new Error('App SDK Error: Insight not found'));
                                return;
                            }
                        } catch (error) {
                            console.log('App SDK Error:', error);
                            res.error(error);
                            return;
                        }

                        this.dialog
                            .open(ConfirmDialogComponent, {
                                data: {
                                    cancel: 'Cancel',
                                    confirm: 'Delete',
                                    content: `Are you sure you want to delete Insight '${insight.name}'?`,
                                    title: 'Delete Insight?',
                                },
                                width: '300px',
                            })
                            .afterClosed()
                            .subscribe({
                                next: async (confirmed: boolean) => {
                                    if (!confirmed) {
                                        return;
                                    }

                                    await this.insightService.remove(insightId);
                                },
                            });
                    },
                    _permission: {},
                    permission: async (req, res): Promise<void> => {
                        const insightId = req.args[0];

                        let insights: InsightDoc[] = [];
                        let insight: InsightDoc | undefined;

                        try {
                            if (this.app.isPrivate) {
                                // Personal app
                                insights = await this.rpc.requestAsync('v3.insight.list', [this.core.network.value._id]);
                            } else {
                                insights = await this.rpc.requestAsync('v3.insight.list', [this.app.cid]);
                            }

                            if (!Array.isArray(insights)) {
                                res.error(new Error('App SDK Error: Insight not found'));
                                return;
                            }

                            insight = insights.find(insight => insight._id === insightId);

                            if (!insight) {
                                res.error(new Error('App SDK Error: Insight not found'));
                                return;
                            }
                        } catch (error) {
                            console.log('App SDK Error:', error);
                            res.error(error);
                            return;
                        }

                        this.dialog.open<InsightPermissionComponent, InsightDoc>(InsightPermissionComponent, {
                            data: insight,
                            width: '800px',
                        });
                    },
                },
                _snackbar: {},
                snackbar: {
                    _open: {},
                    open: (req, res) => {
                        const text = req.args[0];
                        const buttonLabel = req.args[1];
                        const duration: number = req.args[2] ?? 3000;

                        this.snackbar.open(text, buttonLabel, { duration });

                        res.send();
                    },
                },
                _file: {},
                file: {
                    _upload: {},
                    upload: async (req, res) => {
                        /** File content */
                        const data = base64ToFile(req.args[0]);
                        const filename: string = req.args[1];
                        const isPublic: boolean = req.args[2];

                        const file = new File([data], filename, { type: data.type });
                        const fileId = await new Promise(resolve => {
                            this.files
                                .upload(file, { isPublic })
                                .pipe(takeUntil(this.destroy))
                                .subscribe(uploadedFile => {
                                    if (uploadedFile.finished) {
                                        resolve(uploadedFile.fileId);
                                    }
                                });
                        });
                        res.send(fileId);
                    },
                },
            },
            _permission: {},
            permission: {
                _map: {},
                map: (req, res) => {
                    try {
                        // permissionMapSchema

                        if (this.app.isPrivate) {
                            // Personal app
                            res.send(permissionConvert(this.core.permission.map));
                        } else {
                            // Workspace app, only sees user permisisons in app workspace
                            res.send(permissionConvert({ [this.app.cid]: this.core.permission.map[this.app.cid] }));
                        }
                    } catch (error) {
                        console.log('App SDK Error:', error);
                        res.error(error);
                        return;
                    }
                },
            },
            _config: {},
            config: (req, res) => {
                res.emit(Object.fromEntries(Object.entries(this.app.config?.fields || {}).map(([key, value]) => [key, value.value])));
            },
            _signal: {},
            signal: (req, res) => {
                // emits using server.emit('signal', ...)
            },
        });
    }
}
