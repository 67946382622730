<!-- @format -->

<!-- If link is not provided, render a button with custom click handling -->
<button *ngIf="!link || link.length === 0"
	class="button-base {{ type }} {{ color }} {{ size }} {{ width }} {{ focusOutline }} {{ borderRadius }}"
	[attr.aria-label]="ariaLabel"
	[attr.aria-label]="type === 'save' ? saveButton : ariaLabel"
	[attr.aria-label]="type === 'cancel' ? cancelButton : ariaLabel"
	[attr.aria-label]="type === 'delete' ? deleteButton : ariaLabel"
	[attr.aria-label]="type === 'ok' ? okButton : ariaLabel"
	[attr.aria-label]="type === 'count' ? countButtonAria : ariaLabel"
	[attr.aria-disabled]="isDisabled"
	[disabled]="isLoading"
	[ngClass]="{ 'loading': isLoading }"
	[tippy]="tippyText"
	[matMenuTriggerFor]="menuTriggerFor"
	[matMenuTriggerData]="menuTriggerData"
	[attr.data-cy]="dataCy"
	(click)="handleClick($event)">
	<ng-container [ngSwitch]="type">
		<ng-container *ngSwitchCase="'text'">{{ label }}</ng-container>
		<ng-container *ngSwitchCase="'text-with-icon'">
			<mat-icon svgIcon="{{ hailerIconName }}" class="no-flex-shrink" [ngClass]="matIconClass"></mat-icon>
			{{ label }}
		</ng-container>
		<ng-container *ngSwitchCase="'icon'">
			<mat-icon svgIcon="{{ hailerIconName }}" class="no-flex-shrink" [ngClass]="matIconClass"></mat-icon>
		</ng-container>
		<ng-container *ngSwitchCase="'save'">{{ saveButton }}</ng-container>
		<ng-container *ngSwitchCase="'cancel'">{{ cancelButton }}</ng-container>
		<ng-container *ngSwitchCase="'delete'">{{ deleteButton }}</ng-container>
		<ng-container *ngSwitchCase="'ok'">{{ okButton }}</ng-container>
		<ng-container *ngSwitchCase="'count'">{{ countButton }}</ng-container>
	</ng-container>

	<span *ngIf="isLoading" class="loading-spinner-wrapper">
		<mat-spinner diameter="16" class="loading-spinner-button"></mat-spinner>
	</span>
</button>

<!-- If link is provided, render the anchor element -->
<a *ngIf="link && link.length > 0"
    class="button-base button-link {{ type }} {{ color }} {{ size }} {{ width }} {{ focusOutline }}"
    [href]="link"
    [target]="target"
    [attr.rel]="rel"
    [attr.aria-label]="ariaLabel"
    [tippy]="tippyText"
    [attr.data-cy]="dataCy">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'text'">{{ label }}</ng-container>
        <ng-container *ngSwitchCase="'text-with-icon'">
            <mat-icon svgIcon="{{ hailerIconName }}" class="no-flex-shrink" [ngClass]="matIconClass"></mat-icon>
            {{ label }}
        </ng-container>
        <ng-container *ngSwitchCase="'icon'">
            <mat-icon svgIcon="{{ hailerIconName }}" class="no-flex-shrink" [ngClass]="matIconClass"></mat-icon>
        </ng-container>
    </ng-container>
</a>
