/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { CoreService } from './core.service';
import { Tag } from 'app/_models/tag.model';
import { RPCService } from './rpc.service';

export interface ObjectTaggingArgs {
    cid: string;
    objectId: string;
    objectType: TagTypes;
    tagId?: string;
    tagValue?: string;
}

export interface ObjectTagsResponse {
    [objectId: string]: string[];
}

export interface TagObjectArgs {
    tag: string;
    target: string;
    type: TagTypes;
}

export type TagTypes =
    /* 'account' |
       'activity' |
       'calendar' |
       'network' |
       'discussion' |
       'event' | */
    | 'file'
    /* 'fileset' |
       'invite' |
       'message' |
       'notification' | */
    | 'process';
/* 'tag' |
       'team' |
       'user' |
       'wall_comment' |
       'wall_post' */

@Injectable({
    providedIn: 'root',
})
export class TagService {
    networkTags = new BehaviorSubject<Tag[]>([]);

    constructor(private core: CoreService, private rpc: RPCService) {
        combineLatest([this.core.network, this.core.tags])
            .pipe(
                map(values => {
                    const network = values[0];
                    const tags = values[1];

                    return tags[network._id];
                })
            )
            .subscribe({
                next: tags => {
                    this.networkTags.next(tags ? Object.values(tags) : []);
                },
            });
    }

    create(workspaceId: string, tagName: string): Observable<string> {
        return this.rpc.request('v2.tags.manage.add', [workspaceId, { value: tagName }]);
    }

    tag(workspaceId: string, tag: string, target: string, type: TagTypes): Observable<boolean> {
        return this.rpc.request('v2.tags.tag', [
            workspaceId,
            {
                tag,
                target,
                type,
            },
        ]);
    }

    untag(workspaceId: string, tag: string, target: string): void {
        this.rpc.request('v2.tags.untag', [workspaceId, { tag, target }]).subscribe({
            error: console.error,
        });
    }

    exists(networkId: string, tagValue: string): Observable<string> {
        return this.rpc.request('v2.tags.exists', [networkId, tagValue]);
    }

    get(tagId: string): Tag | undefined {
        if (!tagId) {
            console.warn('No tagId provided');
            return;
        }

        for (const workspaceTags of Object.values(this.core.tags.value)) {
            const tag = workspaceTags[tagId];
            if (tag) {
                return tag;
            }
        }
    }
}
