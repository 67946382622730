<mat-form-field class="full-width" [ngClass]="options?.class" appearance="outline" floatLabel="never">
    <mat-select panelClass="user-select-panel" [formControl]="userCtrl" placeholder="{{options?.placeholderLabel}}"
        (selectionChange)="addMember($event.value)" #userSelect disableOptionCentering
        [compareWith]="compareFn">
        <mat-select-trigger style="color: rgba(0, 0, 0, 0.42)">
            {{options.placeholderLabel}}
        </mat-select-trigger>
        <mat-option>
            <ngx-mat-select-search data-cy="user-select-panel-search-field" [formControl]="userFilterCtrl"
                [placeholderLabel]="options.placeholderLabel" [noEntriesFoundLabel]="options.noEntriesFoundLabel"
                #userSelectorSearch></ngx-mat-select-search>
        </mat-option>
        <div *ngIf="!workspaceIsSelected; else workspaceSelected">
            <span class="subheader">{{'shared.permission-selector.workspace' | transloco}}</span>
            <mat-option data-cy="all-workspace-members" [value]="'network_' + (network | async)._id">
                <mat-icon class="img-circle team-icon" svgIcon="hailer-logo">
                </mat-icon>
                {{(network | async).name}} <small style="margin-left: 8px;">- {{'shared.permission-selector.workspace' | transloco}}</small>
            </mat-option>
        </div>
        <ng-template #workspaceSelected>
            <span class="subheader">{{'shared.permission-selector.workspace_already_selected' | transloco}}</span>
        </ng-template>
        <div *ngIf="filteredUsers.length > 0">
            <span class="subheader">{{'shared.permission-selector.users' | transloco}}</span>
            <cdk-virtual-scroll-viewport data-cy="permissions-user-element" [itemSize]="48" [minBufferPx]="48*4" [maxBufferPx]="48*6" [style.height.px]="140">
                <mat-option data-cy="user-option" *ngFor="let user of filteredUsers; trackBy: trackByFn" [value]="'user_' + user._id">
                    <app-user-profile-picture matListAvatar [userId]="user._id" [size]="32" style="margin:0 8px;"></app-user-profile-picture>
                    {{peopleService.getUser(user._id)?.display_name}}
                </mat-option>
            </cdk-virtual-scroll-viewport>
        </div>
        <div *ngIf="filteredUsers.length === 0 && this.searchString === ''">
            <span class="subheader">{{'shared.permission-selector.teams' | transloco}}</span>
        </div>
        <div *ngIf="filteredTeams.length > 0">
            <span class="subheader">{{'shared.permission-selector.workspace' | transloco}}</span>
            <cdk-virtual-scroll-viewport data-cy="permissions-team-element" [itemSize]="48" [minBufferPx]="48*4" [maxBufferPx]="48*6" [style.height.px]="140">
                <mat-option data-cy="team-option" class="team-options" *ngFor="let team of filteredTeams; trackBy: trackByFn" [value]="'team_' + team._id">
                    <span data-cy="team-name" class="team-name">{{team.name}} </span><span class="user-count"><span
                            class="bracket">(</span>{{team.members.length}}<span class="bracket">)</span>
                        <mat-icon color="accent"></mat-icon>
                    </span>
                </mat-option>
            </cdk-virtual-scroll-viewport>
        </div>
        <div *ngIf="filteredTeams.length === 0 && this.searchString === ''">
            <span class="subheader">{{'shared.permission-selector.all_teams_already_selected' | transloco}}</span>
        </div>
        <div *ngIf="!!filteredGroups?.length">
            <span class="subheader">{{'shared.permission-selector.groups' | transloco}}</span>
            <cdk-virtual-scroll-viewport data-cy="permissions-group-element" [itemSize]="48" [minBufferPx]="48*4" [maxBufferPx]="48*6" [style.height.px]="140">
                <mat-option  data-cy="group-option" class="team-options" *ngFor="let group of filteredGroups; trackBy: trackByFn" [value]="'group_' + group._id">
                    <span data-cy="group-name" class="team-name">{{group.name}} </span><span class="user-count"><span
                            class="bracket">(</span>{{group?.users?.length}}<span class="bracket">)</span>
                        <mat-icon color="accent"></mat-icon>
                    </span>
                </mat-option>
            </cdk-virtual-scroll-viewport>
        </div>
        <div *ngIf="filteredGroups?.length === 0 && this.searchString === ''">
            <span class="subheader">{{'shared.permission-selector.all_teams_already_selected' | transloco}}</span>
        </div>
    </mat-select>
</mat-form-field>
<div>
    <app-permission-list 
        data-cy="permission-list"
        [members]="members"
        [permissionTypes]="permissionTypes"
        (memberRemove)="memberRemove.next($event)"
        (permissionDeny)="permissionDeny.next($event)"
        (permissionGrant)="permissionGrant.next($event)"
    ></app-permission-list>
</div>