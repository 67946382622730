/** @format */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';

import { CoreService } from 'app/_services/core.service';
import { PermissionService } from 'app/_services/permission.service';
import { RoutingService } from 'app/_services/routing.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private permission: PermissionService,
        private core: CoreService,
        private router: Router,
        private routingService: RoutingService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const authenticated = this.core.state.pipe(
            filter(appState => ['authenticated', 'login'].includes(appState)),
            map(async appState => {
                this.routingService.userRedirect = state.url;

                if (appState === 'login') {
                    await this.router.navigate(['/login']);
                    return true;
                }

                const isUserGuest = this.permission.isNetworkGuest;
                const path = route.routeConfig?.path;
                const [canAccessFeedError] = this.core.permission.feed.load(this.core.network.value._id);

                if (path === 'feed' && canAccessFeedError) {
                    await this.router.navigate(['/discussions']);
                    return true;
                }

                const guestAccessiblePaths = ['discussions', 'development', 'login', 'files'];

                if (!isUserGuest || guestAccessiblePaths.includes(path)) {
                    return true;
                }

                await this.router.navigate(['/discussions']);
                return true;
            }),
            take(1)
        );

        return authenticated.pipe(map(() => true));
    }
}
