/** @format */

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { environment } from '@app/env';
import { File, Process, User } from '@app/models';
import { FileService } from 'app/files-shared/file.service';
import { PeopleService } from 'app/people/people.service';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { Fileset } from 'app/_models/fileset.model';
import { SideNavService } from 'app/_services/side-nav.service';
import { CoreService } from 'app/_services/core.service';
import { TagSelectorOptions } from 'app/_models/tag-selector-options.model';
import { LicenseService } from 'app/_services/license.service';
import { FilesService } from 'app/_services/files.service';
import { RPCService } from 'app/_services/rpc.service';
import { TagObjectArgs } from 'app/_services/tag.service';

@Component({
    selector: 'app-file-view',
    templateUrl: './file-view.component.html',
    styleUrls: ['./file-view.component.scss'],
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'files', alias: 'files' } }],
})
export class FileViewComponent implements OnInit, OnDestroy {
    @Input() fileId: string;
    @Input() fileset?: Fileset;
    @Input() personalFilesSelected?: boolean;
    @Input() process?: Process = null;

    sourceUrl: any;
    type: any;
    hiresUrl: any;
    originalUrl: any;
    downloadUrl: any;
    fileType: any;
    file: File;
    error: any;
    stackSize$: BehaviorSubject<number> = this.sideNavService.stackSize$;
    tagSelectorOptions = new BehaviorSubject<TagSelectorOptions>(null);
    showSaveButtons: boolean;
    loading = true;

    private onDestroy = new Subject<void>();

    constructor(
        public filesService: FilesService,
        private peopleService: PeopleService,
        private sanitizer: DomSanitizer,
        private fileService: FileService,
        private dialogHelperService: DialogHelperService,
        private snackBar: MatSnackBar,
        private sideNavService: SideNavService,
        private core: CoreService,
        private rpc: RPCService,
        private licenseService: LicenseService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.fileService
            .getFileProperties(this.fileId)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: (file: File) => {
                    this.file = file;
                    this.type = null;
                    this.getFileData();
                    this.updateDownloadUrl();
                    this.cdr.detectChanges();
                },
                error: (error: any) => {
                    console.error('error while fetching file data', error);
                    this.error = error.toString();
                },
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    updateDownloadUrl() {
        this.downloadUrl = `${this.getEnvUrl()}/file/${this.file._id}` + '?dl=1';
        this.cdr.detectChanges();
    }

    deleteFile() {
        this.dialogHelperService
            .showConfirm('Confirm', `Are you sure you want to delete ${this.file.name}`, 'Delete file?')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((confirmed: boolean) => {
                if (confirmed) {
                    this.popSidenav();
                }
            });
    }

    updatePdfUrl() {
        this.sourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.getEnvUrl()}/file/${this.file._id}`);
        this.loading = false;
        this.cdr.detectChanges();
    }

    setImageUrl(pictureId: string) {
        const isGif = this.type === 'image/gif';
        const gifUrl = `${this.getEnvUrl()}/file/${pictureId}`;
        this.hiresUrl = isGif ? gifUrl : `${this.getEnvUrl()}/image/hires/${pictureId}`;
        this.originalUrl = isGif ? gifUrl : `${this.getEnvUrl()}/image/original/${pictureId}`;
        this.loading = false;
        this.cdr.detectChanges();
    }

    updateVideoUrl() {
        this.sourceUrl = `${this.getEnvUrl()}/file/${this.file._id}`;
        this.loading = false;
        this.cdr.detectChanges();
    }

    getFileData() {
        this.getUserName(this.file.uid);
        this.getContentType(this.file.type);
        if (this.type === 'image/png' || this.type === 'image/jpeg' || this.type === 'image/jpg' || this.type === 'image/gif') {
            this.setImageUrl(this.file._id);
        } else if (this.file.type === 'application/pdf') {
            this.updatePdfUrl();
        } else if (this.file.type === 'video/mp4') {
            this.updateVideoUrl();
        }
        this.setTagSelectorData();
    }

    getContentType(type: string) {
        if (this.msOfficeMIME(type, false)) {
            this.fileType = this.msOfficeMIME(type, true);
            this.type = this.fileType;
        } else {
            const regExp = new RegExp('/[^/]*$');
            if (type) {
                const unfilteredFileType = regExp.exec(type)[0];
                this.fileType = unfilteredFileType.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
                this.type = type;
            } else {
                this.fileType = 'Unknown';
            }
        }
    }

    msOfficeMIME(type: string, check: boolean): boolean | string {
        switch (type) {
            case 'application/msword': {
                if (check) {
                    return '.doc';
                }
                return true;
            }
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
                if (check) {
                    return '.docx';
                }
                return true;
            }
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template': {
                if (check) {
                    return '.dotx';
                }
                return true;
            }
            case 'application/vnd.ms-word.document.macroEnabled.12': {
                if (check) {
                    return '.docm';
                }
                return true;
            }
            case 'application/vnd.ms-word.template.macroEnabled.12': {
                if (check) {
                    return '.dotm';
                }
                return true;
            }
            case 'application/vnd.ms-excel': {
                if (check) {
                    return 'Excel';
                }
                return true;
            }
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
                if (check) {
                    return '.xlsx';
                }
                return true;
            }
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template': {
                if (check) {
                    return '.xltx';
                }
                return true;
            }
            case 'application/vnd.ms-excel.sheet.macroEnabled.12': {
                if (check) {
                    return '.xlsm';
                }
                return true;
            }
            case 'application/vnd.ms-excel.template.macroEnabled.12': {
                if (check) {
                    return '.xltm';
                }
                return true;
            }
            case 'application/vnd.ms-excel.addin.macroEnabled.12': {
                if (check) {
                    return '.xlam';
                }
                return true;
            }
            case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12': {
                if (check) {
                    return '.xlsb';
                }
                return true;
            }
            case 'application/vnd.ms-powerpointd': {
                if (check) {
                    return 'PowerPoint';
                }
                return true;
            }
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
                if (check) {
                    return '.pptx';
                }
                return true;
            }
            case 'application/vnd.openxmlformats-officedocument.presentationml.template': {
                if (check) {
                    return '.potx';
                }
                return true;
            }
            case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow': {
                if (check) {
                    return '.ppsx';
                }
                return true;
            }
            case 'application/vnd.ms-powerpoint.addin.macroEnabled.12': {
                if (check) {
                    return '.ppam';
                }
                return true;
            }
            case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12': {
                if (check) {
                    return '.pptm';
                }
                return true;
            }
            case 'application/vnd.ms-powerpoint.template.macroEnabled.12': {
                if (check) {
                    return '.potm';
                }
                return true;
            }
            case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': {
                if (check) {
                    return '.ppsm';
                }
                return true;
            }
            case 'application/vnd.ms-access': {
                if (check) {
                    return '.mdb';
                }
                return true;
            }

            default: {
                return false;
            }
        }
    }

    editFileProperties() {
        // Edits the file so backend takes it back
        const fileToSend = this.file;
        if (typeof fileToSend.metadata.tags === 'string') {
            fileToSend.metadata.tags = fileToSend.metadata.tags.split(',');
        }
        delete fileToSend.metadata.fileSets;
        if (fileToSend.aliases) {
            delete fileToSend.aliases;
        }
        if (fileToSend.chunkSize) {
            delete fileToSend.chunkSize;
        }
        if (fileToSend.md5) {
            delete fileToSend.md5;
        }
        this.fileService
            .editFileProperties([fileToSend])
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: () => {
                    this.snackBar.open('Changes saved', 'Close', {
                        duration: 2000,
                    });
                },
                error: (error: any) => {
                    console.error('Error editing file properties', error);
                },
            });
    }

    getUserName(userId: string) {
        const user: User = this.peopleService.getUser(userId);
    }

    getEnvUrl(): string {
        return environment.wsUrl;
    }

    openUserSidenav(userId: string): void {
        /* TODO: fix the circular dependency
               this.sideNavService.create(
                   UserDetailComponent,
                   {
                       userId: userId,
                       otherPage: true,
                       pop: () => { this.sideNavService.pop(); },
                       clear: () => { this.sideNavService.clear(); }
                   }
               ); */
    }

    popSidenav() {
        this.sideNavService.pop();
    }

    clearSidenav() {
        this.sideNavService.clear();
    }

    setTagSelectorData(): void {
        let tagSelectorOpts: TagSelectorOptions;
        const workspaceId = this.file.cid ? this.file.cid : this.core.network.value._id;

        if (this.process) {
            // Turn process tag ids to tag objects
            this.rpc.request('v2.tags.check', [workspaceId, [this.process._id, this.fileId]])
                .pipe(takeUntil(this.onDestroy))
                .subscribe({
                    next: response => {
                        const processTags = response[this.process._id];
                        const fileTags = response[this.fileId];
                        // Populate tag selector options
                        tagSelectorOpts = {
                            cid: workspaceId,
                            tagPool: processTags,
                            initialTags: fileTags,
                            allowNewTag: this.process.allowCustomTags,
                            multiple: this.process.allowMultipleTags,
                            required: this.process.requireFileTag,
                        };

                        // Save the options
                        this.tagSelectorOptions.next(tagSelectorOpts);
                    },
                });
        }
    }

    enableSaveButton() {
        if (this.process) {
            const tagsAvailable = this.process.requireFileTag && this.tagSelectorOptions.value;
            if (tagsAvailable) {
                const initialTags = this.tagSelectorOptions.value.initialTags;

                return initialTags ? initialTags.length <= 1 : false;
            }
            return true;
        }
        return true;
    }

    untagFile(tagId: string) {
        if (tagId) {
            this.rpc
                .request('v2.tags.untag', [this.core.network.value._id, { tag: tagId, target: this.file._id }])
                .pipe(takeUntil(this.onDestroy))
                .subscribe({
                    next: () => {},
                    error: error => console.error('unable to untag file', error),
                });
        } else {
            console.warn('no tagId provided');
        }
    }

    tagFileOrProcess(tagId: string) {
        if (this.process) {
            const inProcess = this.tagSelectorOptions.value.tagPool ? this.tagSelectorOptions.value.tagPool.includes(tagId) : false;

            if (!inProcess && tagId) {
                const processTaggingArgs: TagObjectArgs = {
                    tag: tagId,
                    target: this.process._id,
                    type: 'process',
                };
                this.rpc
                    .request('v2.tags.tag', [this.process.cid, processTaggingArgs])
                    .pipe(takeUntil(this.onDestroy))
                    .subscribe({
                        next: () => this.tagFile(tagId, this.process.cid),
                        error: error => console.error('error happened while tagging a process', error),
                    });
            } else {
                this.tagFile(tagId, this.process.cid);
            }
        }
    }

    showTagSelector(): boolean {
        const hasSelectorAndProcessValues = this.tagSelectorOptions.value && this.process;

        if (hasSelectorAndProcessValues) {
            const tagPool = this.tagSelectorOptions.value.tagPool;
            const allowCustomTags = this.process.allowCustomTags;

            return this.licenseService.hasFileTagging() && (tagPool ? tagPool.length > 0 || allowCustomTags : allowCustomTags);
        } else if (!this.process) {
            return false;
        }
        this.licenseService.hasFileTagging();
    }

    private tagFile(tagId: string, cid: string): void {
        if (tagId) {
            const args: TagObjectArgs = {
                tag: tagId,
                target: this.fileId,
                type: 'file',
            };
            this.rpc
                .request('v2.tags.tag', [cid, args])
                .pipe(takeUntil(this.onDestroy))
                .subscribe({
                    error: error => console.error('error happened while tagging a file', error),
                });
        } else {
            console.warn('no tagId provided');
        }
    }
}
