/** @format */

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { Subject, filter, takeUntil } from 'rxjs';

import { V3ActivityViewContextService } from 'app/v3-activity/v3-activity-view-context.service';
import { ActivityInitFieldValues, LinkedFromGroup } from 'app/_models/v3-activity.model';
import { CoreService } from 'app/_services/core.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { V3ActivitySidenavComponent } from '../../v3-activity-sidenav.component';

@Component({
    selector: 'app-v3-linked-from-process',
    templateUrl: './v3-linked-from-process.component.html',
    styleUrls: ['./v3-linked-from-process.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class V3LinkedFromProcessComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatAccordion) phaseAccordion: MatAccordion;
    @ViewChild('workflowPanel', { static: false }) workflowPanel: MatExpansionPanel;

    @Input() linkedFromWorkflowId: string;
    @Input() description: string;
    @Input() activityLimit: number;

    @Output() expanded = new EventEmitter<void>();
    @Output() collapsed = new EventEmitter<void>();

    phasesInitiallyInView: { [phaseId: string]: boolean } = {};
    linkedFromWorkflow: LinkedFromGroup;
    highlightedPhaseId: string;
    editableLinkedFromPhaseIds: string[];
    oneCreatePossibility: boolean;

    private onDestroy = new Subject<void>();
    constructor(
        public viewContext: V3ActivityViewContextService,
        public core: CoreService,
        private cdr: ChangeDetectorRef,
        private sideNav: SideNavService
    ) {}

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static redrawCount = 0;
    redraw() {
        V3LinkedFromProcessComponent.redrawCount += 1;
        // Console.log('V3LinkedFromProcessComponent view redrawed: ' + V3LinkedFromProcessComponent.redrawCount + ' times');
    }

    ngOnInit(): void {
        this.viewContext.linkedFromGroup
            .pipe(
                takeUntil(this.onDestroy),
                filter(linkedFromGroupMap => !!Object.keys(linkedFromGroupMap || {}).length)
            )
            .subscribe({
                next: async () => {
                    this.setLinkedFromWorkflow(this.linkedFromWorkflowId);
                },
            });
    }

    ngAfterViewInit(): void {
        this.viewContext.toggleLinkedPhasePanels.pipe(takeUntil(this.onDestroy)).subscribe({
            next: state => {
                switch (state) {
                    case 'collapse':
                        this.phaseAccordion?.closeAll();
                        break;
                    case 'expand':
                        this.phaseAccordion?.closeAll();
                        break;
                    default:
                        break;
                }
            },
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    get linkedFromPhaseIds(): string[] {
        return Object.keys(this.linkedFromWorkflow?.phases || {});
    }

    getEditableLinkedFromPhaseIds(): string[] {
        if (this.linkedFromWorkflow.dataset) {
            return this.linkedFromPhaseIds.filter(phaseId => this.viewContext.canPhaseBeEdited(this.linkedFromWorkflowId, phaseId));
        }

        const canCreateInFirstPhase = this.viewContext.canCreateLinkedActivityInFirstPhase(this.linkedFromWorkflowId);
        if (!canCreateInFirstPhase) {
            return [];
        }

        const firstPhaseId = this.viewContext.getWorkflow(this.linkedFromWorkflowId)?.phasesOrder?.[0];
        return firstPhaseId ? [firstPhaseId] : [];
    }

    getOneCreatePossibility(): boolean {
        const isDataset = this.linkedFromWorkflow.dataset;
        const creatableLinkableFields = this.viewContext.creatableLinkableFields(this.linkedFromWorkflowId);

        if (isDataset) {
            return this.editableLinkedFromPhaseIds.length <= 1 && creatableLinkableFields.length <= 1;
        }
        return creatableLinkableFields.length <= 1;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    trackById(index: number, item: any) {
        if (item.processId) {
            return item.processId;
        }

        if (item.phaseId) {
            return item.phaseId;
        }

        return item._id;
    }

    openActivityCreate(workflowId: string, phaseId?: string, fieldId?: string) {
        if (!fieldId) {
            const firstPhaseLinkedFields = this.linkedFromWorkflow.linkableFields.filter(id =>
                this.viewContext.isFieldInPhase(this.linkedFromWorkflowId, id, phaseId)
            );

            fieldId = firstPhaseLinkedFields[0];
        }

        if (fieldId && this.linkedFromWorkflow.dataset && !this.viewContext.isFieldInPhase(this.linkedFromWorkflowId, fieldId, phaseId)) {
            phaseId = this.viewContext.getFirstPhaseFieldAppearsIn(this.linkedFromWorkflowId, fieldId);
        }

        const initFieldValues: ActivityInitFieldValues | undefined = fieldId
            ? {
                  [fieldId]: {
                      value: {
                          name: this.viewContext.activity.value?.name,
                          _id: this.viewContext.activity.value?._id,
                      },
                  },
              }
            : undefined;

        
        this.sideNav.create(V3ActivitySidenavComponent, {
            action: 'create',
            processId: workflowId,
            phaseId,
            initFieldValues,
            created: () => this.sideNav.pop(),
        });
    }

    openActivity(activityId: string, workflowId?: string) {
        if (this.viewContext.isWorkspaceGuest) {
            return;
        }

        this.sideNav.create(V3ActivitySidenavComponent, {
            activityId,
            processId: workflowId,
        });
    }

    showLoadMore(phaseId: string): boolean {
        if (this.activityLimit) {
            return false;
        }

        const phase = this.linkedFromWorkflow.phases?.[phaseId];
        if (!phase) {
            return false;
        }

        const loadedActivityCount = phase.activities.length;

        const moreActivitiesThanLoaded = loadedActivityCount < phase.activityCount;
        const skip = this.viewContext.linkedPhaseLoadOptions[phaseId]?.skip || 0;
        const oldestActivitiesLoaded = phase.activityCount === skip + loadedActivityCount;

        return moreActivitiesThanLoaded && !oldestActivitiesLoaded;
    }

    async loadNextLinkedActivities(phaseId: string) {
        const linkedActivities = this.linkedFromWorkflow.phases?.[phaseId]?.activities || [];
        const lastActivityId = linkedActivities[linkedActivities.length - 1]?._id;

        if (!lastActivityId) {
            return void console.error('cannot load next activities without last ones id');
        }

        await this.viewContext.loadNextLinkedActivities(this.linkedFromWorkflowId, phaseId, lastActivityId);
    }

    getPrimaryFieldData(phaseId: string, data: 'label' | 'unit') {
        const process = this.core.processFromPhaseId(phaseId);
        const fieldId = process?.phases[phaseId]?.primaryNumericField;
        if (!fieldId) {
            return null;
        }

        return process?.fields[fieldId]?.[data] || null;
    }

    private async setLinkedFromWorkflow(workflowId: string) {
        try {
            if (!workflowId) {
                console.warn('No linked from workflow id was given!');
                return;
            }

            this.linkedFromWorkflow = this.viewContext.linkedFromGroup.value[workflowId] || {
                _id: workflowId,
                name: 'n/a',
                phases: {},
                linkableFields: [],
                activityCount: 0,
            };

            this.editableLinkedFromPhaseIds = this.getEditableLinkedFromPhaseIds();
            this.oneCreatePossibility = this.getOneCreatePossibility();
            this.cdr.detectChanges();

            if (this.workflowPanel?.expanded) {
                this.expanded.emit();
            }
        } catch (error) {
            console.error('Failed to set linked from workflow!', error);
        }
    }
}
