<div cdkDropList class="language-drag-and-add" (cdkDropListDropped)="drop($event)">
    <!--add language button/box-->
    <div class="add-language-box">
        <h3 class="add-language-text">{{'shared.language-drag-and-drop.languages' | transloco}}
        </h3>  
            <button data-cy="open-language-menu-button" color="accent" class="no-button for-icon" [matMenuTriggerFor]="menu" *ngIf="(preferredLanguages.length < allLanguages.length)">
                <mat-icon aria-label="Add a new language to this workspace" tippy="{{'shared.language-drag-and-drop.add-new-language-tippy' | transloco}}" svgIcon="hailer-plus"></mat-icon>  
            </button>  
        <mat-menu #menu="matMenu" [overlapTrigger]="false">   
            <div *ngFor="let language of allLanguages">
                <div *ngIf="!preferredLanguages.includes(language)">
                    <button data-cy="language-submenu-item" mat-menu-item (click)="addLanguage(language)">
                        {{'misc.languages.'+language | transloco}}
                    </button>
                </div>
            </div>
        </mat-menu>
    </div>
    <!--selected and dragable languages-->
    <div>
        <div class="primary-language-container"></div>
        <div class="drag-language-item" [ngClass]="{'first-child' : index===0}" *ngFor="let language of preferredLanguages; let index=index" cdkDrag>
            <div data-cy="language-reorder-container" class="language-list-item-container">
                <div data-cy="language-reorder-element" class="reorder-button-container">
                    <mat-icon class="reorder-button-icon" cdkDragHandle svgIcon="hailer-reorder"></mat-icon>
                </div>
                <div>
                    <div class="native-language-header">
                    <h3 *ngIf="language === 'en'">English</h3>
                    <h3 *ngIf="language === 'fi'">Suomi</h3>
                    <h3 *ngIf="language === 'sv'">Svenska</h3>
                    </div>
                    <div class="language-header">
                        <p>{{'misc.languages.'+language | transloco}}</p>
                        <p *ngIf="index===0">&nbsp;- {{'misc.languages.primary' | transloco}}</p>
                    </div>
                </div>
            </div>
            <button data-cy="remove-language-button" class="remove-language-button no-button for-icon" (click)="removeLanguage(index)" *ngIf="preferredLanguages.length > 1">
                <mat-icon class="figma-icons-line" svgIcon="hailer-trash" aria-label="Remove this language from workspace"></mat-icon>    
            </button>
        </div>
    </div>
</div>