/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProfileDialogComponent } from 'app/people/profile-dialog/profile-dialog.component';
import { PublicModule } from 'app/public/public.module';
import { SharedModule } from '../shared/shared.module';
import { CoreRoutingModule } from './core-routing.module';
import { InviteReviewPageComponent } from './invite-review-page/invite-review-page.component';
import { JoinNetworkDialogComponent } from './join-network-dialog/join-network-dialog.component';
import { FeedPostDialogComponent } from './feed-post-dialog/feed-post-dialog.component';
import { FeedModule } from 'app/feed/feed.module';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { ButtonsModule } from 'app/buttons/buttons.module';

@NgModule({
    imports: [CoreRoutingModule, CommonModule, SharedModule, PublicModule, TranslocoRootModule, FeedModule, ButtonsModule],
    declarations: [ProfileDialogComponent, JoinNetworkDialogComponent, InviteReviewPageComponent, FeedPostDialogComponent],
    exports: [ProfileDialogComponent, FeedPostDialogComponent],
})
export class CoreModule {}
