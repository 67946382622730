/** @format */

import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { QuillConfigModule } from 'ngx-quill/config';
import { TextEditorComponent } from './editor/text-editor.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/material.module';

@NgModule({
    declarations: [
        TextEditorComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        QuillModule,
        QuillConfigModule.forRoot({
            theme: 'bubble',
        }),
    ],
    exports: [
        TextEditorComponent,
    ],
})
export class TextEditorModule {}
