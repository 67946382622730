/** @format */

export class EventUpdate {
    constructor(
        public allDay: boolean,
        public attendees: {
            [key: string]: boolean;
        },
        public calendar_id: string,
        public end: number,
        public location: string,
        public notes: string,
        public repeat_id: string,
        public start: number,
        public title: string,
        public _id: string
    ) {}
}
