/** @format */

import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'dialogs', alias: 'dialogs' },
        },
    ],
})
export class ErrorDialogComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {}

    onConfirm(): void {
        this.dialogRef.close();
    }
}
