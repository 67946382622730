/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';

import { TranslateService } from 'app/_services/translate.service';

@Pipe({
    name: 'todayDate',
})
export class TodayDatePipe implements PipeTransform {
    constructor(private translocoservice: TranslocoService, private translateService: TranslateService) {}

    async transform(dateStr: any, args?: any): Promise<string> {
        await this.translateService.translationLoaded('misc');
        moment.locale(this.translocoservice.getActiveLang());
        const now = moment();
        const date = moment(dateStr);

        const today = now.clone();
        const yesterday = now.clone().subtract(1, 'days');

        if (date.isSame(today, 'day')) {
            return `${this.translocoservice.translate('misc.date.today_at')} ${date.format('HH:mm')}`;
        } else if (date.isSame(yesterday, 'day')) {
            return `${this.translocoservice.translate('misc.date.yesterday_at')} ${date.format('HH:mm')}`;
        }
        return date.format('MMM D, YYYY HH:mm');
    }
}
