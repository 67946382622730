/** @format */

import { App } from 'app/_models/app.model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, map, takeUntil } from 'rxjs';
import { AppService } from 'app/_services/app.service';
import { CoreService } from 'app/_services/core.service';
import { FormControl } from '@angular/forms';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-app-management',
    templateUrl: './app-management.component.html',
    styleUrls: ['./app-management.component.scss'],
})
export class AppManagementComponent implements OnInit, OnDestroy {
    selectedApp: App | null;
    apps = new BehaviorSubject<App[]>([]);
    nameSearchControl = new FormControl();

    private onDestroy = new Subject<void>();

    constructor(private rpc: RPCService, private core: CoreService, public appService: AppService, private cdr: ChangeDetectorRef) {
        combineLatest([this.appService.getWorkspaceApps()])
            .pipe(
                map(([workspaceApps]) => {
                    return [...workspaceApps];
                })
            )
            .subscribe(this.apps);
    }

    ngOnInit(): void {
        this.apps.pipe(takeUntil(this.onDestroy)).subscribe({
            next: async apps => {
                await Promise.all(
                    apps.map(async app => {
                        const manifest = await this.appService.fetchManifest(app.url);

                        app.manifest = manifest;

                        if (!manifest) {
                            return;
                        }

                        console.log('Found manifest for app:', app.name, 'manifest:', manifest);
                    })
                );

                this.cdr.detectChanges();
            },
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    userAppsEnabled(): boolean {
        return localStorage.getItem('user-apps-beta') === 'true';
    }
}
