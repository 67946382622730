/**
 * Eslint-disable @typescript-eslint/naming-convention
 *
 * @format
 */

import { App, AppEditValue, AppManifest, AppMap } from 'app/_models/app.model';
import { CoreService } from './core.service';
import { Injectable } from '@angular/core';
import { RPCService } from './rpc.service';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@app/env';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    constructor(private rpc: RPCService, private core: CoreService, private router: Router) { }

    getWorkspaceApps(): BehaviorSubject<App[]> {
        const subject = new BehaviorSubject<App[]>([]);

        combineLatest([this.core.network, this.core.apps])
            .pipe(
                map(([workspace, apps]) => {
                    return Object.values(apps[workspace._id] || []);
                })
            )
            .subscribe(subject);

        return subject;
    }

    getUserApps(): BehaviorSubject<App[]> {
        const subject = new BehaviorSubject<App[]>([]);

        combineLatest([this.core.user, this.core.apps])
            .pipe(
                map(([user, apps]) => {
                    const list = Object.values(apps[user?._id || ''] || {});

                    if (!!localStorage.getItem('market') && environment.production) {
                        // Add marketplace as an personal app
                        const memberId = this.core.user.value?._id;
                        const marketApp = {
                            _id: '6620d1850271340451e65bad',
                            uid: memberId,
                            name: 'Marketplace',
                            description: 'Download Business Apps in Marketplace',
                            url: 'https://apps.hailer.com/55703b133131611a0def6221/6620d1850271340451e65bad/',
                            // isPrivate: true,
                            allowedUrls: [],
                            members: [],
                            config: {
                                fields: {},
                            },
                            updated: 7,
                            created: 7,
                        } as App;
                        list.push(marketApp);
                    }

                    list.sort((a, b) => b.name.localeCompare(a.name));
                    return list;
                })
            )
            .subscribe(subject);

        return subject;
    }

    getAppMap(): BehaviorSubject<AppMap> {
        return this.core.apps as unknown as BehaviorSubject<AppMap>;
    }

    async routeToApp(app: App): Promise<boolean> {
        // eslint-disable-next-line @typescript-eslint/return-await
        return await this.router.navigate([`apps/${app._id}`]);
    }

    async routeToAppManager(): Promise<boolean> {
        // eslint-disable-next-line @typescript-eslint/return-await
        return await this.router.navigate([`apps`]);
    }

    async fetchManifest(url: string): Promise<AppManifest | null> {
        try {
            const manifestFile = await fetch(`${url}manifest.json`);
            return await manifestFile.json();
        } catch (error) {
            console.log('Failed to fetch manifest file from', url);
            return null;
        }
    }

    async create(input: AppEditValue): Promise<void> {
        await this.rpc.requestAsync('v3.app.create', [input]);
    }

    async remove(id: string): Promise<void> {
        await this.rpc.requestAsync('v3.app.remove', [id]);
    }

    async update(id: string, update: AppEditValue): Promise<void> {
        await this.rpc.requestAsync('v3.app.update', [id, update]);
    }

    async updateConfig(id: string, update: { [key: string]: string }): Promise<void> {
        // App update config internal function got update (should be key => values) in update
        const config = { fields: Object.fromEntries(Object.entries(update).map(([key, value]) => [key, { value, type: 'string' }])) };

        await this.rpc.requestAsync('v3.app.config.update', [id, config]);
    }

    async memberAdd(app: App, a: any): Promise<void> {
        await this.rpc.requestAsync('v3.app.member.add', [app._id, a]);
    }

    async memberRemove(app: App, a: any): Promise<void> {
        await this.rpc.requestAsync('v3.app.member.remove', [app._id, a]);
    }
}
