/**
 * Eslint-disable indent
 *
 * @format
 */

/** @format */

import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { Company, PersonalSettings } from '@app/models';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { PermissionValueType } from 'app/_models/permission-type.model';
import { PermissionService } from 'app/_services/permission.service';
import { CoreService } from 'app/_services/core.service';
import { RoleSettingsService } from '../role-settings/role-settings.service';
import { Role, WorkspaceRoleTypes } from '../../../../test/deps/hailer-api/src/api/v3/permissions/permission-models';
import { RPCService } from 'app/_services/rpc.service';

export interface WorkspaceRoleSelection {
    roleType?: WorkspaceRoleTypes;
    customRoleId?: string;
}

@Component({
    selector: 'app-workspace-permission-selector',
    templateUrl: './workspace-permission-selector.component.html',
    styleUrls: ['./workspace-permission-selector.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'settings', alias: 'settings' },
        },
    ],
})
export class WorkspacePermissionSelectorComponent implements OnInit, OnDestroy {
    @ViewChild('optionSelect', { static: false }) optionSelect: MatSelect;

    /**
     * Updates current network member role when 'userId' is defined
     * Leave empty if you only want 'selectedRole' to emit the role selection
     */
    @Input() userId?: string;
    @Input() preselectedRole?: WorkspaceRoleTypes | string;

    @Output() selectedRole = new EventEmitter<WorkspaceRoleSelection>();

    ownerAmount = 0;
    network: Company;
    me: PersonalSettings;

    private onDestroy = new Subject<void>();

    constructor(
        public roles: RoleSettingsService,
        public permissions: PermissionService,
        public core: CoreService,
        private rpc: RPCService,
        private dialogHelperService: DialogHelperService,
        private translocoService: TranslocoService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.core.network.pipe(takeUntil(this.onDestroy)).subscribe(async network => {
            this.network = network;
            this.ownerAmount = await this.getOwnerAmount();
        });
        this.core.user.pipe(takeUntil(this.onDestroy)).subscribe(user => {
            this.me = user;
        });

        this.roles.mapUpdated.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.cdr.detectChanges(),
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    disableSelector() {
        const isWorkspaceOwner = this.permissions.isNetworkOwner;

        const cantChangeOwnerPermission = !isWorkspaceOwner && this.permissionType === 'owner';
        const cantChangeAdminPermission = !this.permissions.isNetworkAdmin && this.permissionType === 'admin';

        const isMe = this.userId === this.me._id;
        const onlyWorkspaceOwner = isMe && isWorkspaceOwner && this.ownerAmount === 1;

        return cantChangeOwnerPermission || cantChangeAdminPermission || onlyWorkspaceOwner;
    }

    get permissionType(): PermissionValueType | string {
        if (!this.userId) {
            return 'user';
        }

        const member = this.core.network.value.members?.find(({ uid }) => uid === this.userId);
        if (member?.customRole) {
            return this.roles.map[member.customRole]?._id || 'user';
        }

        if (this.permissions.checkIfNetworkOwner(this.userId)) {
            return 'owner';
        }

        if (this.permissions.checkIfNetworkAdmin(this.userId)) {
            return 'admin';
        }

        if (this.permissions.checkIfNetworkGuest(this.userId)) {
            return 'guest';
        }

        if (this.permissions.checkIfNetworkInviter(this.userId)) {
            return 'inviter';
        }

        return 'user';
    }

    async getOwnerAmount(): Promise<number> {
        let amount = 0;
        for await (const member of this.network.members || []) {
            if (member.owner) {
                amount++;
            }
        }
        return amount;
    }

    confirmChanges() {
        return new Promise(resolve => {
            this.dialogHelperService
                .showConfirm(
                    this.translocoService.translate('settings.workspace-permission-selector.confirm_ok'),
                    this.translocoService.translate('settings.workspace-permission-selector.change_permissions_content'),
                    this.translocoService.translate('settings.workspace-permission-selector.change_permissions_title')
                )
                .pipe(takeUntil(this.onDestroy))
                .subscribe((confirmed: boolean) => {
                    resolve(confirmed);
                });
        });
    }

    async changePermission(event: MatSelectChange): Promise<void> {
        if (!this.userId) {
            // Only emit the changes when inviting user
            switch (event.value) {
                case 'admin':
                case 'owner':
                case 'guest':
                case 'inviter':
                case 'user':
                    this.selectedRole.emit({ roleType: event.value });
                    break;
                default:
                    if (!event.value) {
                        return;
                    }

                    this.selectedRole.emit({ customRoleId: event.value });
                    break;
            }
            return;
        }

        if (!this.userId) {
            console.warn('User not defined!');
            return;
        }

        if (this.userId === this.me._id) {
            const confirmed = await this.confirmChanges();
            if (!confirmed) {
                this.optionSelect.writeValue(this.permissionType);
                return;
            }
        }

        const section = event.value;
        const denyRoles = ['admin', 'owner', 'guest', 'inviter'].filter(role => section !== role);
        switch (section) {
            case 'admin':
            case 'owner':
            case 'guest':
            case 'inviter':
                this.rpc.request('company.set_user_data', [{ uid: this.userId, section, value: true }]).subscribe({
                    next: async () => {
                        await this.denyRoles(denyRoles);
                    },
                    error: console.error,
                });

                break;
            case 'user':
                await this.denyRoles(denyRoles);
                break;
            default:
                if (!event.value) {
                    break;
                }

                // Custom role
                await this.roles.updateUsers(event.value, this.userId);
                break;
        }
    }

    get customRoles(): Role[] {
        return Object.values(this.roles.map);
    }

    private async denyRoles(rolesToDeny: string[]): Promise<boolean> {
        return new Promise(resolve => {
            if (!this.userId) {
                console.warn('User id is not defined!');
                resolve(false);
                return;
            }

            for (const role of rolesToDeny) {
                switch (role) {
                    case 'admin':
                        if (!this.permissions.checkIfNetworkAdmin(this.core.user.value._id)) {
                            resolve(true);
                            break;
                        }

                        this.rpc.request('company.set_user_data', [{ uid: this.userId, section: 'admin', value: false }]).subscribe({
                            next: value => resolve(value),
                            error: error => console.error('Failed to remove permission', error),
                        });
                        break;
                    case 'owner':
                        if (!this.permissions.checkIfNetworkOwner(this.core.user.value._id)) {
                            resolve(true);
                            break;
                        }

                        this.rpc.request('company.set_user_data', [{ uid: this.userId, section: 'owner', value: false }]).subscribe({
                            next: value => resolve(value),
                            error: error => this.ownershipRemovalError(error),
                        });
                        break;
                    case 'guest':
                        if (!this.permissions.checkIfNetworkGuest(this.core.user.value._id)) {
                            resolve(true);
                            break;
                        }

                        this.rpc.request('company.set_user_data', [{ uid: this.userId, section: 'guest', value: false }]).subscribe({
                            next: value => resolve(value),
                            error: error => console.error('Failed to remove permission', error),
                        });
                        break;
                    case 'inviter':
                        if (!this.permissions.checkIfNetworkInviter(this.core.user.value._id)) {
                            resolve(true);
                            break;
                        }

                        this.rpc.request('company.set_user_data', [{ uid: this.userId, section: 'inviter', value: false }]).subscribe({
                            next: value => resolve(value),
                            error: error => console.error('Failed to remove permission', error),
                        });
                        break;
                    default:
                        resolve(false);
                        break;
                }
            }
        });
    }

    private ownershipRemovalError(error: { msg: string; code: number }) {
        let content = this.translocoService.translate('settings.workspace-permission-selector.removing_network_ownership_error');
        if (error.code === 127) {
            content = error.msg;
        }
        const confirm = this.translocoService.translate('settings.workspace-permission-selector.confirm_ok');
        const title = this.translocoService.translate('settings.workspace-permission-selector.title_error');

        this.dialogHelperService.showError(confirm, content, title).pipe(take(1)).subscribe();
    }
}
