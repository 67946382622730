/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { PeopleService } from 'app/people/people.service';
import { User } from '@app/models';
import { SideNavService } from 'app/_services/side-nav.service';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';

@Component({
    selector: 'app-mention',
    templateUrl: './mention.component.html',
    styleUrls: ['./mention.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class MentionComponent implements OnInit {
    @Input() uid: string = null;
    user: User = null;
    username = '';

    constructor(private users: PeopleService, private sidenav: SideNavService) {}

    ngOnInit(): void {
        this.setUser(this.uid);
    }

    openUser() {
        this.sidenav.create(UserDetailComponent, { userId: this.uid });
    }

    // Enable instanciating within a script
    setUser(uid: string, username?: string) {
        this.uid = uid;
        this.user = this.users.getUser(this.uid);

        if (this.user.firstname === 'Unknown' && username) {
            this.username = username;
        } else {
            this.username = `${this.user.firstname} ${this.user.lastname}`;
        }
    }
}
