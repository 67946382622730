/** @format */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { syncDiscussions, syncDiscussionsSuccess } from '../actions/discussion.actions';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';

@Injectable()
export class DiscussionEffects {
    syncDiscussions = createEffect(() =>
        this.actions.pipe(
            ofType(syncDiscussions),
            mergeMap(() =>
                from(this.v3Discussion.sync()).pipe(
                    map(discussions => syncDiscussionsSuccess(discussions)),
                    catchError(error => {
                        console.error(error);
                        return EMPTY;
                    })
                )
            )
        )
    );

    constructor(private actions: Actions, private v3Discussion: V3DiscussionService) {}
}
