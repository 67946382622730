<mat-menu #advancedInfoMenu>
    <button mat-menu-item (click)="copyToClipboard(viewContext.activity.value?._id)">
        {{'activity-sidenav.settings.advanced-menu.copy_id' | transloco}}
    </button>
    <button mat-menu-item (click)="copyToClipboard(viewContext.activity.value?.followers)">
        {{'activity-sidenav.settings.advanced-menu.copy_follower_ids' | transloco}}
    </button>
    <button mat-menu-item (click)="copyToClipboard(viewContext.activity.value)">
        {{'activity-sidenav.settings.advanced-menu.copy_activity' | transloco}}
    </button>
</mat-menu>

<div data-cy="settings-tab-fields-container" *ngIf="isAdmin" class="activity-field-container content-block">
    <div class="flex-row--centered activity-field">
        <p class="advanced-activity-info-header">{{'activity-sidenav.settings.advanced-menu.header' | transloco}}</p>
        <button *ngIf="viewContext.action === 'view'" class="info-button" mat-icon-button [matMenuTriggerFor]="advancedInfoMenu"
            tippy="{{'activity-sidenav.settings.advanced-menu.tooltip' | transloco}}">
          <mat-icon svgIcon="hailer-more"></mat-icon>
        </button>
    </div>
    <ng-container *ngFor="let field of optionFields">
        <div data-cy="activity-option-field" [ngClass]="allowEditing(field.id) ? 'no-border-bottom' : 'border-bottom'" *ngIf="field" class="flexbox-column--start-centered activity-field">
            <p data-cy="activity-option-field-label" class="field-label">{{field.label}}</p>
            <div class="temporary-field fill-field-container"
                *ngIf="allowEditing(field.id); else fieldInfo" [ngSwitch]="field.id">
                <ng-container *ngSwitchCase="'ownerTeam'">
                    <app-dropdown-selector data-cy="activity-owner-team-dropdown" *ngIf="ownerTeamField; else fieldInfo" [items]="ownerTeamField.items" [options]="ownerTeamField.options" [selectedItems]="ownerTeamField.selectedItems">
                    </app-dropdown-selector>
                </ng-container>
                <div *ngSwitchDefault>
                    <app-v3-activity-input-field (errors)="onErrors(field.id, $event)" (valueChange)="optionChanged(field.id, $event)"
                    [field]="field"></app-v3-activity-input-field>
                </div>
            </div>
            <ng-template #fieldInfo>
                <div (click)="viewContext.enableEditMode(field.id)" class="temporary-field ">
                    <app-v3-activity-field [field]="field"></app-v3-activity-field>
                </div>
            </ng-template>
        </div>
    </ng-container>
</div>

<div *ngIf="viewContext?.toSave?.activities?.length" class="selected-activities-container content-block">
    <div class="flex-row--centered activity-field">
        <div class="selected-activity-header">
            {{'activity-sidenav.settings.selected_activities' | transloco}}
        </div>
        <div class="amount-bubble">
            <p>{{viewContext.toSave.activities.length}}</p>
        </div>
    </div>
    <div class="missing-required-fields" *ngIf="(viewContext.errorsInSidenav | async)?.activityFieldErrors">
        {{'activity-sidenav.settings.missing_required_fields' | transloco}}
    </div>
    <mat-selection-list data-cy="settings-tab-selected-activities-list" class="activity-field" [multiple]="false">
        <mat-list-option data-cy="settings-tab-selected-activities-list-option" class="selected-activity-list-item border-bottom" *ngFor="let activity of viewContext.toSave.activities" (click)="openActivity(activity._id)">
            <div class="flex-row--centered full-width">
                <mat-icon *ngIf="!hasFieldErrors(activity._id); else onErrorIcon" class="selected-activity-icon" svgIcon="hailer-activity"></mat-icon>

                <ng-template #onErrorIcon>
                    <mat-icon class="on-error-activity-icon" svgIcon="hailer-warning"></mat-icon>
                </ng-template>

                <p class="selected-activity-name two-line-ellipsis">{{activity.name}}</p>
                <button data-cy="unselect-activity-button" class="no-button for-icon" (click)="v3EditMultipleHelper.unSelectActivities([activity._id])">
                    <mat-icon class="figma-icons-fill-blue" svgIcon="hailer-remove">
                    </mat-icon>
                </button>
            </div>
        </mat-list-option>
    </mat-selection-list>
</div>

<div class="followers-list-container content-block" *ngIf="isAdmin && (viewContext.activity | async)?.followers?.length">
    <p class="remove-followers-title flex-row--centered">
        {{'activity-sidenav.settings.followers' | transloco}}
        <span class="amount-bubble">
        <p>{{(viewContext.activity | async)?.followers?.length}}</p>
        </span>
    </p>
    <mat-list data-cy="remove-followers-list" class="remove-followers-list">
        <mat-list-item data-cy="remove-follower-list-item" *ngFor="let followerId of (viewContext.activity | async)?.followers" class="border-bottom">
            <div class="follower-wrapper">
                <div class="follower-item">
                    <app-user-profile-picture [userId]="followerId" [size]="30"></app-user-profile-picture>
                    {{viewContext.getUserName(followerId)}}
                </div>
                <button data-cy="remove-follower-button" class="no-button hailer-x-warn" (click)="removeFollower(followerId)">
                    <mat-icon svgIcon="hailer-x"></mat-icon>
                </button>
            </div>
        </mat-list-item>
    </mat-list>
</div>

<div *ngIf="isAdmin" class="flexbox-row--center-centered delete-button-container">
    <button mat-flat-button (click)="deleteActivity()" color="warn" class="delete-activity-button">
        {{
            viewContext.action !== 'editMultiple' ?
            ('activity-sidenav.settings.delete.activity' | transloco) :
            ('activity-sidenav.settings.delete.activities' | transloco) + '(' + viewContext.toSave.activities.length + ')'
        }}
    </button>
</div>