/** @format */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PersonalSettings } from '@app/models';
import { GlobalSettings } from 'app/_models/globalSettings.model';
import { RPCService } from './rpc.service';

@Injectable({
    providedIn: 'root',
})
export class PersonalSettingsService {
    constructor(private rpc: RPCService) {}

    loadPersonalSettings(): Observable<PersonalSettings> {
        return this.rpc.request('v2.core.init', [['user']]).pipe(map(({ user }) => user));
    }

    setGlobalSettings(globalSettings: GlobalSettings) {
        return this.rpc.request('v2.user.settings.global.set', [globalSettings]);
    }
}
