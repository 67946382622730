/** @format */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InviteReviewPageComponent } from './invite-review-page/invite-review-page.component';

const routes: Routes = [
    {
        path: '',
        component: InviteReviewPageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CoreRoutingModule {}
