/** @format */

import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';
import { SideNavService } from 'app/_services/side-nav.service';
import { Group } from 'app/_models/group.model';
import { Team } from '@app/models';
import { CoreService } from 'app/_services/core.service';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class UserMenuComponent implements OnChanges {
    @Input() userIds: string[];
    @Input() recipients: string[] = [];
    @Input() enableSearch = false;

    groupsTeams: { groups: Group[]; teams: Team[] } = { groups: [], teams: [] };
    users: string[] = [];
    searchForm = new FormControl<string>('');

    constructor(private core: CoreService, private sideNavService: SideNavService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userIds && changes.userIds.currentValue) {
            this.users = this.userIds;
        }

        if (changes.recipients && changes.recipients.currentValue) {
            this.getRecipients();
        }
    }

    getRecipients(): void {
        this.groupsTeams = { groups: [], teams: [] };
        for (const recipient of this.recipients) {
            if (recipient.includes('user_')) {
                continue;
            }

            const recipientType = recipient.includes('group_') ? 'groups' : 'teams';
            const recipientId = recipient.substring(recipientType === 'groups' ? 6 : 5);

            for (const workspaceId in this.core[recipientType].value) {
                if (!workspaceId) {
                    continue;
                }

                const value: any = this.core[recipientType].value?.[workspaceId]?.[recipientId];
                if (!value) {
                    continue;
                }

                this.groupsTeams[recipientType].push(value);
            }
        }
    }

    openUserSidebar(userId: string) {
        this.sideNavService.clear();
        this.sideNavService.create(UserDetailComponent, {
            userId,
        });
    }
}
