<div class="flexbox-column full-height">
    <div *ngIf="!googleDefined;">
        <h3 style="color: #34495c; font-family: Helvetica; margin: 2rem;">
            {{'shared.gmap.maps_not_loaded' | transloco}}
        </h3>
    </div>
    
    <ng-container *ngIf="config.allowSearch">
        <mat-form-field appearance="fill">
            <input data-cy="search-location-input" #searchPlacesInput matInput type="search">
        </mat-form-field>
    </ng-container>
    
    <div data-cy="gmap-div" #mapDiv style="width: 100%; height: 100%;"></div>
</div>