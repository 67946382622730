<div class="attend-message">
    <mat-icon *ngIf="message.meta.attending === null">help_outline</mat-icon>
    <mat-icon class="thumbs-up" *ngIf="message.meta.attending === true" svgIcon="hailer-thumbs-up-fill"></mat-icon>
    <mat-icon class="thumbs-down" *ngIf="message.meta.attending === false" svgIcon="hailer-thumbs-down-fill"></mat-icon>
    <div class="attend-message-text">
        <app-user-link [userId]="message.meta.uid"></app-user-link>

        <ng-container *ngIf="message.meta.attending">
            {{ 'discussion.message_event_attend.is_attending' | transloco }}
        </ng-container>
        <ng-container *ngIf="message.meta.attending === false">
            {{ 'discussion.message_event_attend.is_not_attending' | transloco }}
        </ng-container>
        <ng-container *ngIf="message.meta.attending === null">
            {{ 'discussion.message_event_attend.is_undecided' | transloco }}
        </ng-container>
        <ng-container *ngIf="message.meta?.event?.repeating">
            <!-- ng-click="fn.showEvent(message.meta.data[0].event._id)" -->
            {{ 'discussion.message_event_attend.on' | transloco }}
            {{ message.meta.event.start | date : 'MMMM d, y' : undefined : ('misc.date.locale' | transloco) }}
        </ng-container>
        <ng-container *ngIf="message.meta.uid !== message.uid">
            ({{ 'discussion.message_event_attend.set' | transloco }}
            <app-user-link [userId]="message.uid"></app-user-link>
            )
        </ng-container>
    </div>
</div>
