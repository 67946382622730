/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

import { CoreService } from './core.service';
import { RPCService } from './rpc.service';
import { Group, GroupMap } from 'app/_models/group.model';

@Injectable({
    providedIn: 'root',
})
export class GroupService {
    allGroups: BehaviorSubject<GroupMap>;
    groups: Observable<{ [id: string]: Group }>;
    sortedGroups: Observable<Group[]>;
    constructor(private core: CoreService, private rpc: RPCService, private transloco: TranslocoService) {
        this.allGroups = this.core.groups;
        this.groups = this.core.networkFilter<Group>(this.core.groups);
        this.sortedGroups = this.groups.pipe(
            map(group => Object.values(group).sort((a, b) => (a.name || '').toLowerCase().localeCompare((b.name || '').toLowerCase())))
        );
    }

    getGroup(groupId: string, options?: { workspaceId?: string }): Group {
        const workspaceId = options?.workspaceId || this.core.network.value._id;

        if (groupId && groupId.startsWith('group_')) {
            groupId = groupId.slice(6);
        }

        const allGroups = this.allGroups?.value;
        if (Object.keys(allGroups || {}).length) {
            const networkGroups = allGroups[workspaceId];
            if (!networkGroups) {
                return new Group().deserialize({
                    name: this.transloco.translate('misc.services.group.unknown_group1'),
                    _id: '0000000',
                });
            }
            const foundGroup = networkGroups[groupId];
            if (foundGroup) {
                return foundGroup;
            }
        }

        return new Group().deserialize({
            name: this.transloco.translate('misc.services.group.unknown_group1'),
            _id: '0000000',
        });
    }

    async loadGroup(groupId: string, options?: { workspaceId?: string }): Promise<Group | null> {
        if (!groupId) {
            return null;
        }

        const workspaceId = options?.workspaceId || this.core.network.value._id;

        if (groupId.startsWith('group_')) {
            groupId = groupId.slice(6);
        }

        const allGroups = this.allGroups?.value;
        if (Object.keys(allGroups || {}).length) {
            const networkGroups = allGroups[workspaceId];
            if (!networkGroups) {
                return (await this.rpc.requestAsync('network.group.get', [groupId])) as Group;
            }
            const foundGroup = networkGroups[groupId];
            if (foundGroup) {
                return foundGroup;
            }
        }

        return (await this.rpc.requestAsync('network.group.get', [groupId])) as Group;
    }

    getGroups(options?: { workspaceId?: string }): Group[] {
        const workspaceId = options?.workspaceId || this.core.network.value._id;
        const groups = this.core.groups.getValue();

        if (!Object.keys(groups)?.length || !groups[workspaceId]) {
            return [];
        }

        return Object.values(groups[workspaceId]).sort((t1, t2) => {
            if (!t1.name) {
                t1.name = this.transloco.translate('misc.services.group.unknown_group2');
            }

            if (!t2.name) {
                t2.name = this.transloco.translate('misc.services.group.unknown_group2');
            }

            return t1.name.toLowerCase().localeCompare(t2.name.toLowerCase());
        });
    }

    getGroupByName(groupName: string): Group {
        const foundGroup = Object.values(this.allGroups.value[this.core.network.value?._id]).find(
            group => group.name.toLocaleLowerCase() === groupName.toLocaleLowerCase()
        );
        if (foundGroup) {
            return foundGroup;
        }
        return new Group().deserialize({
            name: this.transloco.translate('misc.services.group.unknown_group1'),
            _id: '0000000',
        });
    }

    getUserGroups(uid: string, options?: { workspaceId?: string }): Group[] {
        const userGroups: Group[] = [];
        const workspaceId = options?.workspaceId || this.core.network.value?._id;

        if (!this.allGroups?.value || !this.allGroups.value[workspaceId]) {
            return [];
        }

        for (const group of Object.values(this.allGroups.value[workspaceId])) {
            if (group?.users?.includes(uid)) {
                userGroups.push(group);
            }
        }

        return userGroups;
    }
}
