<mat-menu #canBeLinkedFromMenu>
    <ng-container *ngFor="let workflowId of canCreateInWorkflowIds">
        <button data-cy="linkable-workflow-option"
            *ngIf="((viewContext.creatableLinkableFields(workflowId)?.length || 0) > 1); else menuItemWithoutSubmenu" mat-menu-item
            [matMenuTriggerFor]="canBeLinkedFromFieldMenu">
            {{(viewContext.linkedFromGroup | async)?.[workflowId]?.name || 'n/a'}}
        </button>

        <ng-template #menuItemWithoutSubmenu>
            <button data-cy="linkable-workflow-option" *ngIf="(viewContext.linkedFromGroup | async)?.[workflowId]" mat-menu-item
                (click)="openActivityCreate(viewContext.linkedFromGroup.value[workflowId]!)">
                {{(viewContext.linkedFromGroup | async)?.[workflowId]?.name || 'n/a'}}
            </button>
        </ng-template>

        <mat-menu #canBeLinkedFromFieldMenu>
            <ng-container *ngFor="let fieldId of viewContext.creatableLinkableFields(workflowId)">
                <button data-cy="linkable-field-item" *ngIf="(viewContext.linkedFromGroup | async)?.[workflowId]" mat-menu-item
                    (click)="openActivityCreate(viewContext.linkedFromGroup.value[workflowId]!, fieldId)">
                    {{viewContext.getFieldLabel(workflowId, fieldId) || 'n/a'}}
                </button>
            </ng-container>
        </mat-menu>
    </ng-container>
</mat-menu>

{{redraw()}}

<div class="linked-from-header" *ngIf="viewContext.hasLinkedActivities; else addButtonWithText">
    <button data-cy="linked-from-tab-header-add-button" class="main-add-activities-button" [matMenuTriggerFor]="canBeLinkedFromMenu" [disabled]="!canCreateInWorkflowIds?.length" mat-icon-button>
        <mat-icon svgIcon="hailer-plus"></mat-icon>
    </button>
    <div class="default-search-bar linked-activity-search-bar">
        <mat-form-field [appearance]="'outline'" class="search-bar">
            <span matPrefix>
                <mat-icon svgIcon="hailer-search"></mat-icon>
            </span>
            <input matInput type="search" [formControl]="searchForm" [autocomplete]="'off'"
                [placeholder]="'activity-sidenav.linked.search_placeholder' | transloco">
            <button *ngIf="searchForm.value" matSuffix mat-icon-button (click)="searchForm.setValue('')">
                <mat-icon svgIcon="hailer-x"></mat-icon>
            </button>
        </mat-form-field>
    </div>
    <button class="collapse-all-button" (click)="!!expandedPanels.length ? collapseAll() : expandAll()" color="primary" mat-button>
        <mat-icon class="collapse-icon" [class.rotate-180-deg]="!expandedPanels.length" svgIcon="hailer-chevron"></mat-icon>
    </button>
</div>

<ng-template #addButtonWithText>
    <div class="upload-button-with-text-container">
        <button data-cy="linked-from-tab-link-activity-button" class="upload-button-with-text" mat-flat-button [matMenuTriggerFor]="canBeLinkedFromMenu" [disabled]="!canCreateInWorkflowIds?.length">
            <mat-icon class="no-button for-icon margin-right-small" svgIcon="hailer-plus"></mat-icon>
            <span>{{ 'activity-sidenav.linked.activity_link_button' | transloco }}</span>
        </button>
    </div> 
    <ng-container [ngTemplateOutlet]="emptyStateView"></ng-container>
</ng-template>

<ng-template #emptyStateView>
    <div class="empty-state-linked-activities"></div>
    <h3 class="empty-state-text">
        {{ 'activity-sidenav.linked.no_linked_activities_empty_state' | transloco }}
    </h3>
</ng-template>

<mat-accordion *ngIf="!!linkedFromWorkflowIds?.length" [multi]="true" [displayMode]="'flat'">
    <!-- Process -->
    <ng-container *ngFor="let workflowId of linkedFromWorkflowIds; trackBy: trackById">
        <app-v3-linked-from-process *ngIf="hasActivities(workflowId)" (expanded)="processExpanded(workflowId)" (collapsed)="processCollapsed(workflowId)"
            [linkedFromWorkflowId]="workflowId">
        </app-v3-linked-from-process>
    </ng-container>
</mat-accordion>
